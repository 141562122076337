<template>
  <div id="import-invoice">
    <div class="line">
      <div class="title">选择文件:</div>
      <div class="value">
        <el-upload
          class="avatar-uploader"
          :action="value.action"
          :show-file-list="false"
          :headers="value.headers"
          :data="value.uploadParameter"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <div class="upload_content_value_but-upload">
            <i class="el-icon-upload2"></i>
            <span>选择文件</span>
          </div>
        </el-upload>
      </div>
      <div class="name">{{ value.text }}</div>
    </div>
    <div class="line">
      <div class="title"></div>
      <div
        class="link"
        @click="downloadTemplate(value.modelType)"
      >
        下载导入模板
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["importInvoiceData"],
  data() {
    return {
      value: this.importInvoiceData,
    };
  },
  methods: {
    handleAvatarSuccess(response, file, fileList) {
      this.value.url = response.result;
      this.value.text = file.name;
    },
    beforeAvatarUpload(file) {
      console.log(file.type);
      let isJPG =
        file.type == "application/vnd.ms-excel" ||
        file.type ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      let isLt2M = file.size / 1024 / 1024 < 10;
      if (!isJPG) {
        this.$message.error("上传文件只能是 Excel格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 10MB!");
      }
      return isJPG && isLt2M;
    },
    downloadTemplate(type) {
      window.location.href = this.$baseurl + "/downResourcesMode?type=" + type;
    },
    created() {},
  },
};
</script>

<style lang="less" scoped>
#import-invoice {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  > .line {
    width: 100%;
    height: 55px;
    display: flex;
    align-items: center;
    > .title {
      width: 20%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 5px;
      box-sizing: border-box;
      font-size: 13px;
    }
    > .value {
      width: auto;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    > .name {
      width: auto;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 5px;
      font-size: 13px;
    }
    > .link {
      width: auto;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 5px;
      color: @activeColor;
      transition: 0.3s;
      font-size: 12px;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: @activeColor;
      }
    }
    .upload_content_value_but-upload {
      > i {
        font-size: 22px;
      }
      font-size: 13px;
      border: 1px solid @boderColor;
      width: auto;
      height: auto;
      padding: 5px 10px;
      border-radius: 3px;
      box-sizing: border-box;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        color: @activeColor;
      }
    }
  }
}
</style>