<template>
  <div id="expend">
    <div class="expend_top">
      <div class="expend_title">成本总金额:{{ value.money }}元</div>
      <div class="expend_add" v-if="value.button_add == 1" @click="addExpend">
        添加支出
      </div>
    </div>
    <div class="expend_table" v-for="(item, index) in value.data" :key="index">
      <div class="expend_table_line is_head">
        <!-- <div class="expend_table_line_cell_tip">
          收款单号:<span>{{
            item.expendNum
          }}</span>
        </div> -->
        <div class="expend_table_line_cell_tip">
          收款单号: {{ item.receiptNumber }}
        </div>
        <div class="expend_table_line_cell_tip">
          付款金额: {{ item.paymentAmount }}
        </div>
        <div class="expend_table_line_cell_tip">
          付款方式: {{ item.paymentMethod }}
        </div>
        <div class="expend_table_line_cell_tip">
          支出状态:
          <span :style="{ color: item.reimburse.color }">
            {{ item.reimburse.status }}</span
          >
        </div>
        <div class="expend_table_line_cell_tip">
          付款日期: {{ item.paymentDate }}
        </div>
        <div class="expend_table_line_cell_tip">
          收款人全称: {{ item.payeeName }}
        </div>
        <div class="expend_table_line_cell_tip">
          <span @click="showExpendDetails(item)">查看详情</span>
        </div>
      </div>
      <!-- <div class="expend_table_line is_head">
        <div class="expend_table_line_cell">关联服务项</div>
        <div class="expend_table_line_cell">支出金额</div>
        <div class="expend_table_line_cell">备注</div>
      </div>
      <div
        class="expend_table_line"
        v-for="(it, ii) in item.services"
        :key="ii"
      >
        <div class="expend_table_line_cell">
          {{ it.name }}
        </div>
        <div class="expend_table_line_cell">
          {{ it.expendMoney }}
        </div>
        <div class="expend_table_line_cell">
          {{ it.des }}
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: ["expendData"],
  data() {
    return {
      value: this.expendData,
    };
  },
  watch: {
    expendData() {
      //父组件值变化->>子组件也变化
      this.value = this.expendData;
    },
    value(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("showExpend", newvalue);
    },
  },
  methods: {
    showExpendDetails(data) {
      this.$emit("showExpendDetails", data);
    },
    addExpend() {
      this.$emit("addExpend");
    },
  },
  created() {},
};
</script>

<style lang="less" scoped>
#expend {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  padding: 10px;
  box-sizing: border-box;
  .expend_top {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .expend_add {
      width: auto;
      padding: 0 8px;
      box-sizing: border-box;
      height: 30px;
      background-color: @activeColor;
      border-radius: 4px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  .expend_table {
    margin: 5px 0;
    box-sizing: border-box;
    border: 1px solid @boderColor;
    width: 100%;
    height: auto;
    // padding: 3px;
    display: flex;
    flex-direction: column;
    .expend_table_line {
      height: auto;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      border-top: 1px solid @boderColor;
      padding: 10px 0;
      box-sizing: border-box;
      .expend_table_line_cell_tip {
        // flex: 1;
        overflow: hidden;
        white-space: nowrap;
        width: 23%;
        margin: 7px 5px;
        display: flex;
        align-items: center;
        span {
          color: @activeColor;
          &:hover {
            cursor: pointer;
            text-decoration: solid;
          }
        }
      }
      .expend_table_line_cell {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &:nth-last-child(1) {
          flex: 2;
        }
      }
    }
    .is_head {
      background-color: @contentBackground;
      border: none;
    }
  }
}
</style>
