<template>
  <div id="re-new-order">
    <!-- 新建合同 -->
    <div class="re-new-order-title">客户名称: {{ value.clientName }}</div>

    <div class="content">
      <!-- <div class="list">
        <div class="title">产品类型：{{ serviceType.typeName }}</div>
        <div class="con">
          <div
            :class="{ line: true, 'left-active': serviceType == item }"
            v-for="(item, index) in serviceTtypeOptions"
            :key="index"
            @click="changeServiceType(item)"
          >
            {{ item.typeName }}
          </div>
        </div>
      </div>
      <div class="split"></div> -->
      <div class="list">
        <div class="title">请选择具体产品：</div>
        <div class="con">
          <div
            class="line"
            v-for="(item, index) in productList"
            :key="index"
            @click="choseProductTipOne(item)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <!-- <div class="address">
        <div class="title">请选择具体位置：</div>
        <div class="value">
          <div>
            <el-select></el-select>
          </div>
          <div>
            <el-select></el-select>
          </div>
          <div>
            <el-select></el-select>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: ["newOrder"],
  data() {
    return {
      baseInfo: {},
      value: this.newOrder,
      //   value: {
      //     clientName: "",
      //   },
      // serviceType: {},
      // serviceTtypeOptions: [],
      productList: [],
    };
  },
  watch: {
    newOrder() {
      //父组件值变化->>子组件也变化
      this.value = this.newOrder;
    },
    value(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {
    /**
     * 选择服务类别,显示具体合同模板
     */
    changeServiceType() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        contractId: this.value.contractId,
      };
      this.$request(
        "post",
        "/profession/getAllProfessionByServiceType",
        requestData,
        (data) => {
          this.productList = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 选择合同模板单击事件
     */
    choseProductTipOne(data) {
      this.$emit("choseProductTipOne", data);
    },
  },
  mounted() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.changeServiceType();
    // let requestData = {
    //   companyId: this.baseInfo.company.companyId,
    // };
    // this.$request(
    //   "post",
    //   "/serveType/getAllServeType",
    //   requestData,
    //   (data) => {
    //     this.serviceTtypeOptions = data;
    //     if (data.length > 0) {
    //       this.serviceType = data[0];
    //       this.changeServiceType(data[0]);
    //     }
    //   },
    //   (errorMessage) => {
    //     this.$message.error(errorMessage);
    //   }
    // );
  },
};
</script>

<style lang="less" scoped>
#re-new-order {
  width: 100%;
  height: 400px;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  .re-new-order-title {
    padding-bottom: 10px;
    position: sticky;
    left: 0;
    top: 0;
    background-color: #fff;
  }

  > .content {
    width: 100%;
    height: 50vh;
    max-height: 50vh;
    display: flex;
    flex-direction: column;
    > .split {
      width: 1px;
      height: 100%;
      background-color: @boderColor;
      margin-right: 10px;
    }
    > .list {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      > .title {
        width: 100%;
        height: 50px;
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 5px;
        box-sizing: border-box;
        font-size: 13px;
        color: @activeColor;
      }

      > .con {
        width: 100%;
        overflow: auto;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        > .line {
          width: auto;
          height: 50px;
          padding: 0 20px;
          border: 1px solid @boderColor;
          box-sizing: border-box;
          margin: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          border-radius: 3px;
          transition: 0.3s;
          &:hover {
            cursor: pointer;
            border-color: @activeColor;
            color: @activeColor;
            transform: scale(1.03);
          }
        }
        > .left-active {
          border-color: @activeColor;
          color: @activeColor;
        }
      }
    }
    // > .address {
    //   width: 100%;
    //   display: flex;
    //   flex-direction: column;
    //   align-items: start;
    //   > .title {
    //     padding-left: 5px;
    //     box-sizing: border-box;
    //     font-size: 13px;
    //     color: @activeColor;
    //     margin: 20px 0 25px 0;
    //   }
    //   > .value {
    //     display: flex;
    //     > div {
    //       width: 150px;
    //       margin-right: 10px;
    //     }
    //   }
    // }
  }

  // .re-new-order-choose {
  //   width: 100%;
  //   height: 0;
  //   flex: 1;
  //   overflow-y: auto;
  //   display: flex;
  //   flex-wrap: wrap;
  //   // flex-flow: row wrap;
  //   align-content: flex-start;
  //   &::-webkit-scrollbar {
  //     width: 1px;
  //   }
  //   .div-border {
  //     width: 170px;
  //     height: 100px;
  //     box-sizing: border-box;
  //     border: 1px solid @boderColor;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     font-size: 14px;
  //     margin: 5px;
  //     transition: 0.3s;
  //     &:hover {
  //       background-color: @editGrayColor;
  //       color: @activeColor;
  //       cursor: pointer;
  //       border-color: @activeColor3;
  //     }
  //   }
  // }
  // .select-box span:focus-within {
  //   border: none;
  //   outline: none;
  // }
}
</style>
