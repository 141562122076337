<template>
  <div id="invoice-account">
    <!-- 数电账号 -->
    <div class="screen">
      <div class="screen-line">
        <div class="invoice-account-screen-search">
          <el-input
            placeholder="请输入纳税人名称"
            v-model="searchData.clientName"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            >
            </el-button>
          </el-input>
        </div>
        <div class="invoice-account-screen-search">
          <el-input
            placeholder="请输入纳税人识别号"
            v-model="searchData.taxpayerNumber"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            >
            </el-button>
          </el-input>
        </div>
        <div class="invoice-account-screen-search">
          <el-input
            placeholder="请输入电子税务局账号"
            v-model="searchData.username"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            >
            </el-button>
          </el-input>
        </div>
        <div class="invoice-account-screen-search">
          <el-cascader
            v-model="searchData.department"
            placeholder="请选择部门"
            :options="searchData.departTreeData"
            @change="initTableData"
            clearable
            :show-all-levels="false"
            :props="{ checkStrictly: true, value: 'id' }"
            filterable
          ></el-cascader>
        </div>
        <div class="invoice-account-screen-search">
          <el-select
            v-model="searchData.userId"
            placeholder="请选择员工"
            filterable
            @change="selectInitTableData"
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="invoice-account-screen-active"></div>
        <div
          class="invoice-account-screen-but"
          @click="handleClick('', 2)"
          v-showFunction="{
            active: 'editBatch',
            array: allFunctionButtons,
          }"
        >
          批量修改
        </div>
        <div
          class="invoice-account-screen-but"
          @click="handleClick('', 4)"
          v-showFunction="{
            active: 'deleteBatch',
            array: allFunctionButtons,
          }"
        >
          批量清除
        </div>
      </div>
    </div>
    <div class="table-content">
      <div class="invoice-account-table-card">
        <div class="invoice-account-content">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            height="98%"
            @selection-change="handleSelectionChange"
            v-loading="loading"
          >
            <el-table-column type="selection" width="55" fixed>
            </el-table-column>
            <el-table-column
              type="index"
              width="55"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="taxpayerNumber"
              label="纳税人识别号"
            ></el-table-column>
            <el-table-column
              prop="clientName"
              label="纳税人名称"
            ></el-table-column>
            <el-table-column
              prop="username"
              label="电子税务局账号"
            ></el-table-column>
            <el-table-column
              prop="position"
              label="电子税务局身份"
            ></el-table-column>
            <el-table-column label="操作" width="150">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="handleClick(scope.row, 0)"
                  v-showFunction="{
                    active: 'edit',
                    array: allFunctionButtons,
                  }"
                >
                  修改
                </el-button>
                <el-button
                  type="text"
                  @click="handleClick(scope.row, 3)"
                  v-showFunction="{
                    active: 'delete',
                    array: allFunctionButtons,
                  }"
                >
                  清除
                </el-button>
                <!-- <el-button type="text" @click="handleClick(scope.row, 1)">
                  忘记密码
                </el-button> -->
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="invoice-account-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @showCustomer="showCustomer"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import * as invoiceData from "@/assets/js/invoiceData.js";
import my_dialog from "@/components/dialog.vue";
export default {
  components: {
    my_dialog,
  },
  data() {
    return {
      curInvoiceTypeQuota: [], // 当前发票类型信息

      templateData: {},
      baseInfo: {},
      loading: true,
      whetherAgencyHead: false,
      searchData: {
        input: "",
        username: "",
        clientName: "",
        taxpayerNumber: "",
        tempUserId: "",
        userId: "", // 员工id
        userList: [], // 员工列表
        userIdList: [], // 员工id列表
        department: "",
        departTreeData: [],
      },
      tableData: [],
      buttonAuth: [],
      multipleSelection: [],
      tempClient: {},
      dialogData: {
        flag: false,
        width: "30%",
        title: "添加跟进记录",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "invoiceAccount"
      ),
      tempData: {},
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "invoiceAccount",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          console.log("当前显示的按钮权限", data);
          this.isCharge();
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getDepartmentTreeByUserId();
      });
    },
    /**
     * 获取部门
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
          this.getEmployeeList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.employeeList = [];
          this.searchData.userList = [];
          data.forEach((element) => {
            let dd = {
              name: element.userName,
              id: element.userId,
            };
            this.searchData.userIdList.push(element.userId);
            this.employeeList.push(dd);
            this.searchData.userList.push(dd);
          });
          this.searchData.userList.splice(0, 0, {
            id: "all",
            name: "全部人员",
          });
          this.searchData.userId = this.baseInfo.userId;
          this.selectInitTableData(this.baseInfo.userId);
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      this.loading = true;
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        clientName: this.searchData.clientName, // 客户名称
        taxpayerNumber: this.searchData.taxpayerNumber, // 纳税人识别号
        username: this.searchData.username, // 电子税务局账号
        userIdList: this.searchData.tempUserId, // 员工id
        department:
          this.searchData.department.length > 0
            ? this.searchData.department[this.searchData.department.length - 1]
            : "",
        pageSize: this.pageData.pageSize, // 每页条数
        currentPage: this.pageData.currentPage, // 当前页数
      };
      this.$request(
        "post",
        "/invoiceTaxAccount/page",
        requestData,
        (data) => {
          let taxPosition = invoiceData.taxPosition();
          this.pageData.total = data.total;
          data.records.forEach((item) => {
            taxPosition.forEach((item1) => {
              if (item.position == item1.value) {
                item.position = item1.label;
              }
            });
          });
          this.tableData = data.records;
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * dialog点击事件
     */
    drawerOper(active, data) {
      let requestData = {};
      switch (active) {
        case "sure_edit":
          requestData = {
            id: this.tempData.id, // 数电账号 id
            taxpayerNumber: this.tempData.taxpayerNumber, // 纳税人识别号
            username: this.dialogData.data[3].value, // 电子税务局账号
            password: this.dialogData.data[4].value, // 电子税务局密码
            position: this.dialogData.data[2].value, // 电子税务局身份 '01'-法定代表人；'02'-财务负责人；'03'-办税员；'05'-管理员；'08'-社保经办人；'09'-开票员；'10'-销售人员
          };
          if (!requestData.position) {
            this.$message.error("电子税务局身份为必填项!");
            return;
          }
          if (!requestData.username) {
            this.$message.error("电子税务局账号为必填项!");
            return;
          }
          if (!requestData.password) {
            this.$message.error("电子税务局密码为必填项!");
            return;
          }
          this.$request(
            "post",
            "/invoiceTaxAccount/setUpTaxAccount",
            requestData,
            (data) => {
              this.$message.success("修改成功");
              this.colseDialog();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "sure_batch_edit":
          if (!this.dialogData.data[0].value) {
            this.$message.error("电子税务局身份为必填项!");
            return;
          }
          if (!this.dialogData.data[1].value) {
            this.$message.error("电子税务局账号为必填项!");
            return;
          }
          if (!this.dialogData.data[2].value) {
            this.$message.error("电子税务局密码为必填项!");
            return;
          }
          this.multipleSelection.forEach((item) => {
            item.position = this.dialogData.data[0].value;
            item.username = this.dialogData.data[1].value;
            item.password = this.dialogData.data[2].value;
          });
          requestData = this.multipleSelection;
          this.$request(
            "post",
            "/invoiceTaxAccount/setUpTaxAccountBatch",
            requestData,
            (data) => {
              this.$message.success("修改成功");
              this.colseDialog();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );

          break;
        case "cancel":
          this.dialogData.flag = false;
          break;
        default:
          break;
      }
    },
    /**
     * 界面点击事件
     */
    handleClick(data, flag) {
      let requestData = {};
      this.tempData = data;
      let that = this;
      switch (flag) {
        case 0:
          // 修改
          this.dialogData.width = "550px";
          this.dialogData.title = "数电账号信息设置";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "sure_edit",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              type: "input",
              title: "纳税人识别号",
              value: this.tempData.taxpayerNumber,
              flag: true,
            },
            {
              type: "input",
              title: "纳税人名称",
              value: this.tempData.clientName,
              flag: true,
            },
            {
              type: "select",
              title: "电子税务局身份",
              value: this.tempData.position,
              options: invoiceData.taxPosition(),
            },
            {
              type: "input",
              title: "电子税务局账号",
              value: this.tempData.username,
            },
            {
              type: "input",
              title: "电子税务局密码",
              value: this.tempData.password,
            },
          ];
          this.dialogData.flag = true;
          break;
        case 1:
          // 忘记密码
          break;
        case 2:
          // 批量删除
          if (this.multipleSelection.length <= 0) {
            this.$message.error("请选择要操作的数据!");
            return;
          }
          this.dialogData.width = "550px";
          this.dialogData.title = "数电账号信息设置";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "sure_batch_edit",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              type: "select",
              title: "电子税务局身份",
              value: this.tempData.position,
              options: invoiceData.taxPosition(),
            },
            {
              type: "input",
              title: "电子税务局账号",
              value: "",
            },
            {
              type: "input",
              title: "电子税务局密码",
              value: "",
            },
          ];
          this.dialogData.flag = true;
          break;
        case 3:
          // 清除
          this.$confirm("确认清除该数电账号信息吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              requestData = {
                ids: [this.tempData.id],
              };
              this.$request(
                "post",
                "/invoiceTaxAccount/delInvoiceTaxAccount",
                requestData,
                (data) => {
                  this.$message.success("清除成功");
                  this.initTableData();
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {});
          break;
        case 4:
          // 批量清除
          if (this.multipleSelection.length <= 0) {
            this.$message.error("请选择要操作的数据!");
            return;
          }
          requestData = {
            ids: [],
          };
          requestData.ids = this.multipleSelection.map((item) => {
            return item.id;
          });
          this.$request(
            "post",
            "/invoiceTaxAccount/delInvoiceTaxAccount",
            requestData,
            (data) => {
              this.$message.success("清除成功");
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        default:
          break;
      }
    },
    /**
     * 获取不同发票类型限额
     */
    getConfByClient(type) {
      let that = this;
      let p = new Promise(function(resolve, reject) {
        let requestData = {
          clientId: that.tempClient.id, // 客户id
          type: type ? type : "026", // 发票类型
        };
        that.$request(
          "post",
          "/invoiceConf/getConfByClient",
          requestData,
          (data) => {
            data.forEach((item) => {
              item.flag = true;
            });
            that.curInvoiceTypeQuota = data;
            resolve(true);
          },
          (errorMessage) => {
            reject(errorMessage);
            that.$message.error(errorMessage);
          }
        );
      });
      return p;
    },
    /**
     * 获取企业工商信息  调企查查
     */
    getStatisticsInvoice() {
      let requestData = {
        name: this.dialogData.data[0].value,
      };
      this.$request(
        "post",
        "/invoice/getStatisticsInvoice",
        requestData,
        (data) => {
          this.dialogData.data[1].value = data.taxpayerNumber; // 企业税号
          this.tempClient.addedValueTaxType = data.addedValueTaxType; // 增值税类型
          this.tempClient.industryType = data.industryType; // 行业分类
          this.tempClient.area = data.area; // 所在地区
          this.tempClient.address = data.address; // 详细地址
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 多选选择事件
     */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
      this.initTableData();
    },
    showCustomer(data) {
      this.dialogData.data[0].data = data;
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    /**
     * 员工选择
     */
    selectInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.searchData.userIdList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.initTableData();
    },
  },
  mounted() {
    this.loading = true;
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#invoice-account {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .invoice-account-screen-fiftrate {
    width: 65px;
    min-width: 65px;
    height: 30px;
    border: 1px solid @boderColor;
    box-sizing: border-box;
    color: @boderActiveColor;
    font-size: @contentFontSize;
    margin: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    transition: 0.3s;
    i {
      margin: 0 4px;
    }
  }
  .invoice-account-screen-fiftrate:hover {
    cursor: pointer;
    border-color: @boderActiveColor;
  }
  .invoice-account-screen-search {
    width: 210px;
    min-width: 210px;
    height: 30px;
    margin-left: 10px;
  }
  .invoice-account-screen-checked {
    width: 140px;
    height: 30px;
    min-width: 140px;
    font-size: @contentFontSize;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .invoice-account-screen-active {
    flex: 1;
  }
  .invoice-account-screen-but-select {
    width: 140px;
    min-width: 160px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @activeColor;
    font-size: @contentFontSize;
    color: #fff;
    margin: 0 5px;
    border-radius: 4px;
    transition: 0.5s;
    cursor: pointer;
    .invoice-account-screen-but-select_but {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.5s;
    }
    .invoice-account-screen-but-select_but:hover {
      background-color: @activeColor2;
    }
    .invoice-account-screen-but-select_select {
      width: 30px;
      height: 100%;
      border-left: 1px solid @activeColor3;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .invoice-account-screen-but {
    width: 100px;
    min-width: 100px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @activeColor;
    font-size: @contentFontSize;
    color: #fff;
    margin: 0 5px;
    border-radius: 4px;
    transition: 0.5s;
  }
  .invoice-account-screen-but:hover {
    cursor: pointer;
    background-color: @activeColor2;
  }
  .invoice-account-screen-but-boder {
    width: 50px;
    min-width: 50px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid @activeColor;
    font-size: @contentFontSize;
    background-color: @activeBackground;
    color: @activeColor;
    margin: 0 5px;
    margin-right: 10px;
    border-radius: 4px;
    transition: 0.5s;
  }
  .invoice-account-screen-but-boder:hover {
    cursor: pointer;
    background-color: @activeBackground2;
  }
  .table-content {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    .invoice-account-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .invoice-account-content {
        width: 100%;
        height: 95%;
        max-width: 90vw;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        .line-cell {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          cursor: pointer;
          i {
            margin: 5px;
          }
        }
        .gray {
          color: rgb(146, 146, 146, 0.637);
        }
        .orange {
          color: rgba(255, 115, 0, 0.637);
        }
        .green {
          color: rgb(9, 255, 0, 0.637);
        }
      }
      .invoice-account-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }
}
</style>
