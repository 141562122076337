<template>
  <div id="new-drager">
    <div class="drager-model">
      <div class="drager-model-span">
        已选
        <div>可拖动调整显示顺序(右击可设置为隐藏)</div>
      </div>
      <div class="drager-model-tips">
        <!-- :class="{ 'drager-model-tip': index > 0 }" -->
        <div
          class="drager-model-tip"
          v-for="(item, index) in selectMenuData"
          :key="index"
          @contextmenu="changeStatus(0, item, index)"
          v-dragging="{
            list: selectMenuData,
            item: item,
            group: 'key',
          }"
        >
          {{ item.name }}
          <!-- {{ index > 0 ? item.title : "" }} -->
        </div>
      </div>
    </div>
    <div class="drager-model">
      <div class="drager-model-span">
        可选择展示的字段
        <div>右击可设置为显示</div>
      </div>

      <div class="drager-model-tips">
        <div
          class="drager-model-tip"
          v-for="(item, index) in chooseableMenuData"
          :key="index"
          @contextmenu="changeStatus(1, item, index)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="buts">
      <div class="but" @click="markSureUpdateTableMenu">确定</div>
      <div class="but" @click="closeUpdateTableMenu">取消</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["dragerData"],
  data() {
    return {
      data: this.dragerData,
      // data: JSON.parse(JSON.stringify(this.dragerData)),
      chooseableMenuData: [],
      selectMenuData: [],
      disableMenuData: [],
    };
  },

  watch: {
    dragerData() {
      //父组件值变化->>子组件也变化
      this.data = this.dragerData;
    },
    data(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {
    /**
     * 初始化数据
     */
    initData() {
      this.selectMenuData = [];
      this.chooseableMenuData = [];
      this.disableMenuData = [];
      for (let index = 0; index < this.data.length; index++) {
        let element = this.data[index];
        if (element.authority == 1) {
          // 显示
          this.selectMenuData.push(element);
        } else if (element.authority == 2) {
          // 不显示
          this.chooseableMenuData.push(element);
        } else {
          // 没有
          this.disableMenuData.push(element);
        }
      }
    },
    changeStatus(index, item, i) {
      let that = this;
      document.oncontextmenu = function () {
        //   关闭浏览器右击事件
        return false;
      };
      if (index == 0) {
        item.authority = 2;
        this.selectMenuData.splice(i, 1);
        this.chooseableMenuData.push(item);
      } else {
        item.authority = 1;
        this.chooseableMenuData.splice(i, 1);
        this.selectMenuData.push(item);
      }
    },
    markSureUpdateTableMenu() {
      let resultData = [];
      for (let index = 0; index < this.selectMenuData.length; index++) {
        let element = this.selectMenuData[index];
        resultData.push(element);
      }
      for (let index = 0; index < this.chooseableMenuData.length; index++) {
        let element = this.chooseableMenuData[index];
        resultData.push(element);
      }
      for (let index = 0; index < this.disableMenuData.length; index++) {
        let element = this.disableMenuData[index];
        resultData.push(element);
      }
      this.$emit("markSureUpdateTableMenu", resultData);
    },
    closeUpdateTableMenu() {
      this.$emit("closeUpdateTableMenu");
    },
  },
  mounted() {
    this.initData();
    // 自定义导航顺序
    this.$dragging.$on("dragged", ({ value }) => {
      this.$emit("dragging", value);
    });
  },
};
</script>

<style lang="less" scoped>
#new-drager {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .drager-model {
    flex: 1;
    width: 100%;
    margin: 10px;
    display: flex;
    flex-direction: column;
    .drager-model-span {
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      font-size: @contentFontSize2;
      > div {
        font-size: @contentFontSize;
        margin-left: 10px;
        color: @tipColor;
      }
    }
    .drager-model-tips {
      height: auto;
      width: 96%;
      display: flex;
      flex-wrap: wrap;
      .drager-model-tip {
        width: auto;
        height: 25px;
        margin: 10px;
        padding: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @boderActiveColor;
        transition: 0.3s;
        font-size: @contentFontSize;
      }
      .drager-model-tip:hover {
        cursor: pointer;
        border: 1px dashed @activeColor;
        background-color: @activeBackground;
        color: @activeColor;
      }
    }
  }
  > .buts {
    width: 100%;
    height: 50px;
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    > .but {
      width: 120px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 20px;
      transition: 0.3s;
      cursor: pointer;
      color: #ffffff;
      &:nth-child(1) {
        background-color: #0281f8;
        &:hover {
          background-color: #035eb3;
        }
      }
      &:nth-child(2) {
        background-color: #ff0e06;
        &:hover {
          background-color: #a50500;
        }
      }
    }
  }
}
</style>