<template>
  <div id="contract">
    <!-- 合同界面 -->
    <div class="contract-menu">
      <div v-for="(item, index) in menuData" :key="index">
        <div
          class="contract-menu-lines"
          v-if="item.children.length > 0 && item.flag == 1"
        >
          <div class="contract-menu-line">
            <i :class="item.icon"></i> {{ item.title }}
          </div>
          <div
            :class="{
              'contract-menu-line-item': true,
              'is-active': menu_index == it.path,
            }"
            v-for="(it, ii) in item.children"
            :key="ii"
            v-show="it.flag == 1"
            @click="changeMenu(it)"
          >
            {{ it.title }}
          </div>
        </div>
        <div
          :class="{
            'contract-menu-line': true,
            'is-active': menu_index == item.path,
          }"
          @click="changeMenu(item)"
          v-else-if="item.flag == 1"
        >
          <i :class="item.icon"></i>{{ item.title }}
        </div>
      </div>
    </div>
    <div class="contract-content">
      <router-view v-if="isRouterAlive" />
    </div>
  </div>
</template>

<script>
export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      baseInfo: {},
      isRouterAlive: true,
      menu_index: "contract-info",
      pathList: [],
      menuData: [
        {
          title: "合同",
          icon: "el-icon-s-help",
          path: "1",
          flag: 1,
          children: [
            {
              title: "全部合同",
              path: "contract-info",
              active: "contractInfo",
              flag: 1,
            },
            {
              title: "合同审核",
              path: "contract-check",
              active: "contractCheck",
              flag: 1,
            },
            // {
            //   title: "记单合同",
            //   path: "contract-note",
            //   active: "ContractNote",
            //   flag: 1,
            // },
            {
              title: "财务收款",
              path: "contract-payment",
              active: "ContractPayment",
              flag: 1,
            },
            {
              title: "合同派工",
              path: "contract-work",
              active: "contractWork",
              flag: 1,
            },
            {
              title: "续签管理",
              path: "contract-renew",
              active: "contractRenew",
              flag: 1,
            },
            {
              title: "合同发票",
              path: "contract-invoice",
              active: "contractInvoice",
              flag: 1,
            },
          ],
        },
        {
          title: "设置",
          icon: "el-icon-s-tools",
          path: "contract-setting",
          active: "contractSetting",
          flag: 1,
          children: [],
        },
      ],
    };
  },
  methods: {
    /**
     * 获取初始菜单设置的按钮权限
     */
    getUserAuthorityByModels() {
      this.$authority.getUserAuthorityByModels(
        this,
        this.menuData,
        (data, pathList) => {
          this.menuData = data;
          this.pathList = pathList;
          if (this.pathList.length > 0) {
            this.menu_index =
              sessionStorage.getItem("menuSecondActiveIndex") &&
              this.pathList.indexOf(
                sessionStorage.getItem("menuSecondActiveIndex")
              ) > -1
                ? sessionStorage.getItem("menuSecondActiveIndex")
                : this.pathList[0];
            sessionStorage.setItem("menuSecondActiveIndex", this.menu_index);

            this.$router.push("/main/contract/" + this.menu_index);
          } else {
            this.$router.push("/main/index");
          }
        }
      );
    },
    changeMenu(data) {
      this.menu_index = data.path;
      sessionStorage.setItem("menuSecondActiveIndex", this.menu_index);
      this.$router.push("/main/contract/" + data.path);
    },
  },
  mounted() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getUserAuthorityByModels();
  },
};
</script>

<style lang="less" scope>
#contract {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #fff;
  .contract-menu {
    width: 180px;
    height: 100%;
    // min-width: 180px;
    min-width: 9vw;
    border-right: 1px solid @boderColor;
    overflow: hidden;
    font-size: @contentFontSize;
    .contract-menu-lines {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .contract-menu-line-item,
    .contract-menu-line {
      width: 100%;
      height: 40px;
      display: flex;
      padding-left: 10px;
      transition: 0.3s;
      align-items: center;
      border-left: 4px solid #fff;
      box-sizing: border-box;
      i {
        margin-right: 10px;
      }
    }
    .contract-menu-line-item {
      padding-left: 53px;
    }
    .contract-menu-line {
      font-weight: 600;
    }
    .contract-menu-line-item:hover,
    .contract-menu-line:hover {
      cursor: pointer;
      background-color: @activeBackground;
    }
    .is-active {
      background-color: @activeBackground;
      border-left: 4px solid @activeColor;
      box-sizing: border-box;
      color: @activeColor;
    }
  }
  .contract-content {
    flex: 1;
    height: 100%;
    position: relative;
  }
}
</style>
