<template>
  <div id="financial-audit">
    <div class="financial-audit-line">
      <div class="financial-audit-line-title">合同总金额:</div>
      <div class="financial-audit-line-value">{{ value.money }}元</div>
    </div>
    <div class="financial-audit-line">
      <div class="financial-audit-line-title">合同已审核金额:</div>
      <div class="financial-audit-line-value">{{ value.approvedAmount }}元</div>
    </div>
    <div class="financial-audit-line">
      <el-table :data="value.tableData" border style="width: 99%" height="40vh">
        <el-table-column align="center" type="index" width="55">
        </el-table-column>
        <el-table-column
          prop="name"
          label="服务项"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column prop="payee" label="收款人" align="center" width="100">
        </el-table-column>
        <el-table-column
          prop="addTime"
          label="收款时间"
          show-overflow-tooltip
          align="center"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="account"
          label="收款账号"
          show-overflow-tooltip
          align="center"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="voucherNo"
          label="交易单号"
          show-overflow-tooltip
          align="center"
          width="200"
        >
          <template slot-scope="scope">
            <div class="line" @click="openVoucherNo(scope.row)">
              {{ scope.row.voucherNo }}
              <!-- aaa -->
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="money"
          label="总应收(元)"
          align="center"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="approv"
          label="已审核(元)"
          align="center"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="approvalIncome"
          label="待审核(元)"
          align="center"
          width="100"
        >
        </el-table-column>
        <!-- <el-table-column label="本次审核(元)" align="center" width="180">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.reviewIncome"
              type="number"
              :disabled="scope.row.flag"
              placeholder="0.00"
              @change="changeInput(scope.row)"
            ></el-input>
          </template>
        </el-table-column> -->
        <el-table-column prop="file" label="附件" align="center" width="120">
          <template slot-scope="scope">
            <div
              class="financial-audit-line-link"
              @click="showReceivableSheet(scope.row.file)"
            >
              查看附件
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="file" label="操作" align="center" width="120">
          <template slot-scope="scope">
            <el-button
              type="danger"
              plain
              @click="rejectA(scope.row)"
              size="mini"
              >驳回</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div id="my-dialog" v-if="dialogData3.flag">
      <my_dialog7
        :width="dialogData3.width"
        :title="dialogData3.title"
        :buttonArray="dialogData3.buttonArray"
        :data="dialogData3.data"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @voucherFor="voucherFor"
      ></my_dialog7>
    </div>
  </div>
</template>

<script>
// import mydialog7 from "@/components/dialog.vue";
export default {
  // components: { kkll: mydialog7 },
  props: ["financialAuditData"],
  data() {
    return {
      value: this.financialAuditData,
      // value: {
      //   money: "500.00",
      //   approvedAmount: "134.00",
      //   tableData: [
      //     {
      //       name: "dsgd", //服务项
      //       payee: "张三",
      //       addTime: "2021-07-30 12:32:12",
      //       money: "500.00", // 总应收
      //       approv: "", // 已审核收入
      //       approvalIncome: "321.89", // 待审核收入
      //       reviewIncome: "", // 本次审核收入
      //       file: "",
      //     },
      //   ],
      // },
      dialogData3: {
        flag: false,
        width: "30%",
        title: "查看付款凭证",
        buttonArray: [
          {
            text: "取消",
            active: "cancel",
          },
        ],
        data: [
          {
            title: "",
            type: "show_img",
            value: "",
          },
        ],
      },
      tempMoney: "",
    };
  },
  watch: {
    financialAuditData() {
      //父组件值变化->>子组件也变化
      this.value = this.financialAuditData;
    },
    value(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {
    changeInput(data) {
      if (parseFloat(data.reviewIncome) > parseFloat(data.approvalIncome)) {
        data.reviewIncome = data.approvalIncome;
      }
      if (parseFloat(data.reviewIncome) < 0) {
        data.reviewIncome = 0.0;
      }
    },
    /**
     * 查看回款单
     */
    showReceivableSheet(data) {
      if (data == "") {
        this.$message({
          message: "暂无付款凭证!!",
          type: "error",
          duration: 1000,
        });
        return;
      }
      this.dialogData3.title = "查看付款凭证";
      this.dialogData3.width = "30%";
      this.dialogData3.buttonArray = [
        {
          text: "取消",
          active: "cancel",
        },
      ];
      this.dialogData3.data = [
        {
          title: "",
          type: "show_img",
          value: data,
        },
      ];
      this.dialogData3.flag = true;
    },
    /**
     * dialog 关闭
     */
    colseDialog() {
      this.dialogData3.flag = false;
    },
    drawerOper(active) {
      switch (active) {
        case "cancel":
          this.dialogData3.flag = false;
          break;

        default:
          break;
      }
    },
    // 驳回单个收款
    rejectA(data) {
      this.$emit("rejectA", data);
    },
    // 领取回款单
    openVoucherNo(data) {
      console.log("data", data);
      this.tempMoney = data; // 本次审核金额
      let requestData = {
        voucherNo: data.voucherNo,
      };
      // this.tempMoney.flag = true;
      if (!data.flag) {
        this.$request(
          "post",
          "/voucher/searchByVoucherNo",
          requestData,
          (redata) => {
            if (redata.length > 0) {
              this.dialogData3.title = "收款单领取";
              this.dialogData3.width = "70%";
              this.dialogData3.buttonArray = [
                {
                  text: "取消",
                  active: "cancel",
                },
              ];
              this.dialogData3.data = [
                {
                  title: "",
                  type: "voucher_for",
                  value: redata,
                },
              ];
              this.dialogData3.flag = true;
            } else {
              this.$message({
                message: "没有查找到对应回款单!",
                type: "error",
                duration: 1000,
              });
            }
          },
          (errorMessage) => {
            this.$message.error(errorMessage);
          }
        );
      } else {
        this.$message({
          message: "该记录已被领取,不可重复操作!",
          type: "error",
          duration: 1000,
        });
      }
    },

    /**
     * 财务领取回款
     */
    voucherFor(data) {
      let requestData = {
        id: data.id,
        money: this.tempMoney.reviewIncome, // 本次领取金额
        contractId: this.tempMoney.contractId, // 该合同ID
        addTime: this.tempMoney.addTime, // 收款时间
      };
      this.$request(
        "post",
        "/voucher/toReceiveVoucher",
        requestData,
        (redata) => {
          this.$message({
            message: "领取成功!",
            type: "success",
            duration: 1000,
          });
          this.tempMoney.flag = true;
          this.dialogData3.flag = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
#financial-audit {
  width: 99%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .financial-audit-line {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    font-size: 14px;
    .financial-audit-line-title {
      width: 140px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-right: 10px;
    }
    .financial-audit-line-value {
      flex: 1;
      width: 0;
      height: 40px;
      display: flex;
      align-items: center;
    }
    .financial-audit-line-link {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 11px;
      color: #019afc;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
  .line {
    color: #019afc;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
