<template>
  <div id="reimbursement_item">
    <div class="top">
      <div class="priority">优先级:{{ value.priority }}</div>
      <div class="buts">
        <div class="but" @click="itemClick('edit')">
          <i class="el-icon-edit-outline"></i>编辑
        </div>
        <div class="but" @click="itemClick('delete')">
          <i class="el-icon-delete"></i>删除
        </div>
      </div>
    </div>
    <div class="line">
      <div class="title">部门条件:</div>
      <div class="value" v-if="value.isDefault == '0'">默认部门</div>
      <div class="value" v-else>
        <div class="item" v-for="(item, index) in value.deCon" :key="index">
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="line">
      <div class="title">金额范围:</div>
      <div class="value" v-if="value.isDefault == '0'">默认范围</div>
      <div class="value" v-else>
        {{ value.moneyCon[0] }}~{{ value.moneyCon[1] }}元
      </div>
    </div>
    <div class="line">
      <div class="title">审核人:</div>
      <div class="value">
        <div class="item" v-for="(item, index) in value.approvers" :key="index">
          {{ item.name }}
        </div>
      </div>
    </div>
    <div
      class="line"
      v-if="value.twoApprovers && value.twoApprovers.length > 0"
    >
      <div class="title">审核人2:</div>
      <div class="value">
        <div
          class="item"
          v-for="(item, index) in value.twoApprovers"
          :key="index"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="line">
      <div class="title">付款人:</div>
      <div class="value">
        <div class="item" v-for="(item, index) in value.payer" :key="index">
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  //   props: ["reimbursementItemData", "index"],
  props: {
    reimbursementItemData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    itemIndex: {
      type: Number,
      default: function () {
        return -1;
      },
    },
  },
  data() {
    return {
      value: this.reimbursementItemData,
      index: this.itemIndex,
    };
  },
  watch: {
    reimbursementItemData() {
      //父组件值变化->>子组件也变化
      this.value = this.reimbursementItemData;
    },
    value(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {
    itemClick(active) {
      this.$emit("itemClick", {
        data: this.value,
        index: this.index,
        active: active,
      });
    },
  },
};
</script>

<style lang="less" scoped>
#reimbursement_item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  > .top {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > .priority {
      width: auto;
      height: auto;
      padding: 0 10px;
      box-sizing: border-box;
      font-weight: bolder;
      font-size: 14px;
    }
    > .buts {
      width: auto;
      height: 100%;
      display: flex;
      align-items: center;
      > .but {
        width: auto;
        height: auto;
        display: flex;
        align-content: center;
        justify-content: center;
        font-size: 13px;
        padding: 0 10px;
        color: #397eff;
        transition: 0.3s;
        &:hover {
          cursor: pointer;
          color: #da3e0e;
        }
        i {
          margin-right: 5px;
          margin-top: 3px;
        }
      }
    }
  }
  > .line {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    > .title {
      width: 20%;
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      font-size: 14px;
      font-weight: bolder;
    }
    > .value {
      width: 0;
      flex: 1;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      padding: 0 3px;
      box-sizing: border-box;
      font-size: 13px;
      > .item {
        width: auto;
        height: auto;
        padding: 2px 5px;
        box-sizing: border-box;
        color: #397eff;
        background-color: #a4c0fa4b;
        margin: 0 8px;
        font-size: 12px;
        margin-top: 6px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
      }
    }
  }
}
</style>