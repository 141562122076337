import * as Array2Obj from "./array2obj.js"
/**
 * 拼接抽屉数据权限
 * @param {*} drawerData 
 * @param {*} authorityData 所有权限数据
 * @param {*} isHead 是否是机构负责人
 */
export function initDrawerAuthorityData(drawerData, authorityData, isHead) {
    let dData = JSON.parse(JSON.stringify(drawerData))
    let authorityList = Array2Obj.Array2Obj(authorityData)

    for (let index = 0; index < dData.data.modelList.length; index++) {
        let modelItem = dData.data.modelList[index];
        for (let i = 0; i < modelItem.line.length; i++) {
            let line = modelItem.line[i];
            if (line.key) {
                line = changeAuthority(authorityList[line.key], line)
                if (isHead) {
                    // 是机构负责人  所有都可以修改
                    line.isShow = true
                    line.isDisable = false
                }
            }

        }

    }
    return dData
}

function changeAuthority(authority, line) {
    switch (authority) {
        case 1:
        case "1":
            line.isShow = true
            line.isDisable = false
            break;
        case 2:
        case "2":
            line.isShow = true
            line.isDisable = true
            break;
        case 3:
        case "3":
            line.isShow = false
            line.isDisable = true
            break;

        default:
            line.isShow = false
            line.isDisable = true
            break;
    }
    return line
}

/**
 * 填充抽屉数据
 * @param {*} drawerData 
 * @param {*} data  数据库拿到的数据
 */
export function initDrawerData(drawerData, data) {
    let dData = JSON.parse(JSON.stringify(drawerData))
    for (let index = 0; index < dData.data.modelList.length; index++) {
        let modelItem = dData.data.modelList[index];
        for (let i = 0; i < modelItem.line.length; i++) {
            let line = modelItem.line[i];
            if (line.key) {
                line.value = data[line.key]
            }
        }
    }
    return dData
}

/**
 * 获取当前属性的所有key数组
 * @param {*} authorityList 
 */
export function getKeysByAuthorityList(authorityList) {
    let keys = []
    for (let index = 0; index < authorityList.length; index++) {
        let element = authorityList[index];
        keys.push(element.key)
    }
    return keys
}