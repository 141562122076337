<template>
  <div id="table-human">
    <div class="table-human-search">
      <div class="table-human-search-line">
        <el-input
          v-model="clientName"
          placeholder="请输入客户名称"
          @keyup.enter.native="resetPage"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click.native.stop="resetPage"
          ></el-button>
        </el-input>
      </div>
    </div>
    <div>
      <el-table
        ref="tableData"
        class="tableData-content"
        :data="tableData"
        :header-cell-style="{ background: '#F5F7FA', color: '#606266' }"
        highlight-current-row
        style="width:1300px"
        height="500"
        border
        :row-style="{ height: '60px' }"
      >
        <el-table-column type="index" width="60" fixed> </el-table-column>
        <template v-for="(item, index) in tableMenuData">
          <el-table-column
            :key="index"
            v-if="item.prop !== 'status'"
            :property="item.prop"
            :fixed="index == 0"
            :label="item.title"
            :width="item.prop == 'clientName' ? 250 : item.width"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <div v-if="item.prop == 'serviceItem'" class="hri">
                <!-- 服务项 -->
                <div
                  v-for="(ddd, dd) in scope.row[item.prop]"
                  :key="dd"
                  class="table-tip"
                  :style="{
                    borderColor: ddd.color,
                    color: ddd.color,
                  }"
                >
                  <el-tooltip :content="ddd.tip" placement="top">
                    <div>{{ ddd.text }}</div>
                  </el-tooltip>
                </div>
              </div>
              <span v-else>
                {{ scope.row[item.prop] }}
              </span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
  </div>
</template>
<script>
import * as humanData from "../js/humanData";
export default {
  props: {
    data: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      clientName: "",
      date: "",
      tableMenuData: humanData.getHumanDispatchMenuData(),
      tableData: [],
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
    };
  },
  watch: {
    data() {
      //父组件值变化->>子组件也变化
      this.tableData = this.data.records;
      this.pageData.total = this.data.total;
      this.pageData.pageSize = this.data.size;
      this.pageData.currentPage = this.data.current;
    },
  },
  methods: {
    initTableData() {
      let requestData = {
        clientName: this.clientName,
        department: "",
        userIds: [this.data.userId],
        allStepTime: this.data.date,
        status: "",
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
      };
      this.$request(
        "post",
        this.data.url,
        requestData,
        (data) => {
          this.tableData = data.records;
          this.pageData.total = data.total; // 获取总条目数
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    resetPage() {
      this.pageData.currentPage = 1;
      this.initTableData();
    },
    lazyLoading() {
      // 获取目标滚动元素
      let dom = this.$refs.tableData.bodyWrapper;
      dom.addEventListener("scroll", () => {
        const scrollDistance =
          dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        console.log("鼠标滑动-scrollDistance", scrollDistance);
        // if (scrollDistance <= 0) {  //分辨率问题，如果设置 100% ，滑倒最底部，scrollDistance的值 可能为 0.201 到 -0.201
        if (scrollDistance < 1) {
          if (this.tableData.length >= this.pageData.total) {
            // 到达底部 数据加载完毕
            return;
          }
          // 滑动到表格底部 可以请求接口
          let requestData = {
            clientName: this.clientName,
            department: "",
            userIds: [this.data.userId],
            allStepTime: this.data.date,
            status: "",
            currentPage: ++this.pageData.currentPage, // 当前页数
            pageSize: this.pageData.pageSize, // 每页条数
          };
          this.$request(
            "post",
            this.data.url,
            requestData,
            (data) => {
              // 获取的数据追加到原来的数据中
              this.tableData = [...this.tableData, ...data.records];
              // this.waiteDataList = data.records;
              this.pageData.total = data.total;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
        }
      });
    },
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.tableData.doLayout();
    });
  },
  created() {
    this.tableData = this.data.records;
    this.pageData.total = this.data.total;
    this.pageData.pageSize = this.data.size;
    this.pageData.currentPage = this.data.current;
  },
  mounted() {
    this.lazyLoading();
  },
};
</script>

<style lang="less" scoped>
#table-human {
  height: 80%;
  display: flex;
  flex-direction: column;
  .table-human-search {
    height: 40px;
    display: flex;
    .table-human-search-line {
      width: 250px;
      margin-right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .tableData-content {
    /deep/ .el-table {
      overflow: visible !important;
    }

    /deep/ .el-table__body-wrapper {
      &::-webkit-scrollbar {
        height: 8px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.2);
      }
    }
    .table-tip {
      height: 30px;
      width: auto;
      padding: 2px 5px;
      margin: 0 3px;
      border: 1px solid #333;
      border-radius: 5px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .social-security-table-card-bottom {
    width: 95%;
    height: 40px;
    border-top: 1px solid @boderColor;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
