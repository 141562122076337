<template>
  <div id="waite">
    <!-- 待办 -->
    <div class="waite-menu">
      <div v-for="(item, index) in menuData" :key="index">
        <div
          class="waite-menu-lines"
          v-if="item.children.length > 0 && item.flag == 1"
        >
          <div class="waite-menu-line">
            <i :class="item.icon"></i> {{ item.title }}
          </div>
          <div
            :class="{
              'waite-menu-line-item': true,
              'is-active': menu_index == it.path,
            }"
            v-for="(it, ii) in item.children"
            :key="ii"
            v-show="it.flag == 1"
            @click="changeMenu(it.path, it.type)"
          >
            {{ it.title }}
          </div>
        </div>
        <div
          :class="{
            'waite-menu-line': true,
            'is-active': menu_index == item.path,
          }"
          @click="changeMenu(item.path, item.type)"
          v-else-if="item.flag == 1"
        >
          <i :class="item.icon"></i>{{ item.title }}
        </div>
      </div>
    </div>
    <div class="waite-content">
      <router-view v-if="isRouterAlive" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      baseInfo: {},
      isDisable: false, // 是否可以对当前模块的子模块进行操作
      isRouterAlive: true,
      menu_index: "waite-all",
      pathList: ["waite-all"],
      menuData: [
        {
          title: "待办",
          icon: "el-icon-s-operation",
          path: "1",
          flag: 1,
          children: [
            {
              title: "全部",
              path: "waite-all",
              type: "all",
              flag: 1,
            },
          ],
        },
      ],
    };
  },
  methods: {
    /**
     * 获取初始菜单设置的按钮权限
     */
    getUserAuthorityByModels() {
      // this.$authority.getUserAuthorityByModels(
      //   this,
      //   this.menuData,
      //   (data, pathList) => {
      //     this.menuData = data;
      //     this.pathList = pathList;
      if (this.pathList.length > 0) {
        this.menu_index =
          sessionStorage.getItem("menuSecondActiveIndex") &&
          this.pathList.indexOf(
            sessionStorage.getItem("menuSecondActiveIndex")
          ) > -1
            ? sessionStorage.getItem("menuSecondActiveIndex")
            : this.pathList[0];
        sessionStorage.setItem("menuSecondActiveIndex", this.menu_index);

        this.$router.push("/main/waite/" + this.menu_index);
      } else {
        this.$router.push("/main/index");
      }
      // }
      // );
    },
    changeMenu(path, type) {
      this.menu_index = path;
      sessionStorage.setItem("menuSecondActiveIndex", this.menu_index);
      this.$router.push("/main/waite/" + path);
    },
  },
  mounted() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getUserAuthorityByModels();
  },
};
</script>

<style  lang="less" scope>
#waite {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #fff;
  .waite-menu {
    width: 180px;
    height: 100%;
    min-width: 9vw;
    border-right: 1px solid @boderColor;
    overflow: hidden;
    font-size: @contentFontSize;
    .waite-menu-lines {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .waite-menu-line-item,
    .waite-menu-line {
      width: 100%;
      height: 40px;
      display: flex;
      padding-left: 10px;
      transition: 0.3s;
      align-items: center;
      border-left: 4px solid #fff;
      box-sizing: border-box;
      i {
        margin-right: 10px;
      }
    }
    .waite-menu-line-item {
      padding-left: 53px;
    }
    .waite-menu-line {
      font-weight: 600;
    }
    .waite-menu-line-item:hover,
    .waite-menu-line:hover {
      cursor: pointer;
      background-color: @activeBackground;
    }
    .is-active {
      background-color: @activeBackground;
      border-left: 4px solid @activeColor;
      box-sizing: border-box;
      color: @activeColor;
    }
  }
  .waite-content {
    flex: 1;
    height: 100%;
    position: relative;
  }
}
</style>