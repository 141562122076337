<template>
  <div id="dispatch-setting">
    <div class="dispatch-table-content">
      <div class="dispatch-table-card">
        <div class="dispatch-table-card-content">
          <div class="dispatch-table-card-content-tags-setting">
            <div class="dispatch-table-card-content-tags-setting-left">
              <div
                :class="{
                  'dispatch-table-card-content-tags-setting-left-line': true,
                  'is-active': index == curIndex,
                }"
                v-for="(item, index) in menuData"
                :key="index"
                @click="changeTab(index)"
              >
                {{ item.name }}
              </div>
            </div>
            <div class="dispatch-table-card-content-tags-setting-right">
              <div class="dispatch-table-card-content-tags-setting-right-add">
                <div
                  class="butt"
                  @click="addTags"
                  v-showFunction="{
                    active: 'add',
                    array: allFunctionButtons,
                  }"
                >
                  添加选项
                </div>
              </div>
              <div class="dispatch-table-card-content-tags-setting-right-tree">
                <el-tree
                  :data="list"
                  node-key="id"
                  :props="defaultProps"
                  :expand-on-click-node="false"
                  draggable
                  :default-expand-all="true"
                  @node-drop="handleDrop"
                >
                  <template #default="{ data, node }">
                    <!-- <span>{{ data.name }}</span> -->
                    <el-row
                      class="dispatch-table-card-content-tags-setting-right-tree-line"
                      type="flex"
                      align="middle"
                    >
                      <el-col
                        :span="21"
                        style="text-align: left;"
                        @click.native.stop="handleNodeClick(data, node)"
                      >
                        <el-input
                          v-model="curInput"
                          v-focus
                          v-if="data.flag"
                          @blur="updateInput"
                          @keyup.enter.native="$event.target.blur"
                        ></el-input>
                        <span v-else>
                          <span>{{ data.name }}</span>
                        </span>
                      </el-col>
                      <el-col :span="3">
                        <el-row type="flex" justify="end">
                          <el-col>{{ data.manager }}</el-col>
                          <el-col>
                            <el-dropdown trigger="click">
                              <span>
                                操作<i class="el-icon-arrow-down"></i>
                              </span>
                              <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item
                                  v-if="data.children"
                                  @click.native="addClick(data, node)"
                                  v-showFunction="{
                                    active: 'addChildren',
                                    array: allFunctionButtons,
                                  }"
                                >
                                  添加子节点
                                </el-dropdown-item>
                                <el-dropdown-item
                                  @click.native="locationDetail(data, node)"
                                  v-showFunction="{
                                    active: 'detail',
                                    array: allFunctionButtons,
                                  }"
                                >
                                  地址详情
                                </el-dropdown-item>
                              </el-dropdown-menu>
                            </el-dropdown>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </template></el-tree
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import my_dialog from "@/components/dialog.vue";
import { waitDealLocation } from "@/minixs.js";
export default {
  components: {
    my_dialog,
  },
  data() {
    return {
      isAdd: false,
      curIndex: 0,
      curInput: "", // input框输入内容
      menuData: [
        {
          name: "地址设置",
        },
      ],
      tempDispatch: {},
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "dispatchSetting"
      ),
      whetherAgencyHead: "",
      list: [
        {
          id: 1,
          name: "印章",
          flag: false,
          children: [
            {
              id: 2,
              name: "发票章",
              flag: false,
            },
            {
              id: 3,
              name: "公章",
              flag: false,
            },
            {
              id: 4,
              name: "发票章",
              flag: false,
            },
            {
              id: 8,
              name: "",
              flag: false,
            },
          ],
        },
        {
          id: 5,
          name: "资格证",
          flag: false,
          children: [
            {
              id: 6,
              name: "bbb",
              flag: false,
            },
          ],
        },
        {
          id: 7,
          name: "发票",
          flag: false,
        },
      ],
      defaultProps: {
        children: "children",
        label: "name",
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "运单详情",
        buttonArray: [
          {
            text: "确认",
            active: "confirm",
          },
        ],
        data: [],
      },
    };
  },
  mixins: [waitDealLocation],
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "dispatchSetting",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          // this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
      });
    },
    /**
     * 侧边栏切换
     */
    changeTab(index) {
      this.curIndex = index;
      if (this.curIndex == 0) {
        this.waitDealLocation();
      }
    },

    addClick(data, node) {
      console.log(data, node.level);
      this.isAdd = true;
      this.tempDispatch = data;
      this.list.forEach((item) => {
        switch (node.level) {
          case 1:
          case "1":
            // 一级
            if (item.id == data.id) {
              if (!item.children) {
                item.children = [];
              }
              item.children.push({
                flag: true,
                name: "",
              });
            }
            break;
          case 2:
          case "2":
            if (item.id == data.parentId) {
              item.children.forEach((ele) => {
                if (ele.id == data.id) {
                  if (!ele.children) {
                    ele.children = [];
                  }
                  ele.children.push({
                    flag: true,
                    name: "",
                  });
                }
              });
            }
            // 二级
            break;
          case 3:
          case "3":
            // 三级
            break;

          default:
            break;
        }
      });
    },

    /**
     * 点击当前行
     */
    handleNodeClick(data, node) {
      console.log(data, node.level);
      this.tempDispatch = data;
      this.list.forEach((item) => {
        // 一级
        if (node.level == 1 && item.id == data.id) {
          item.flag = true;
          this.curInput = item.name;
        }
        // 二级
        if (node.level == 2) {
          item.children.forEach((ele) => {
            if (ele.id == data.id) {
              ele.flag = true;
              this.curInput = ele.name;
            }
          });
        }
        // 三级
        if (node.level == 3) {
          item.children.forEach((ele) => {
            ele.children.forEach((e) => {
              if (e.id == data.id) {
                e.flag = true;
                this.curInput = e.name;
              }
            });
          });
        }
      });
    },

    /**
     * 更新 删除位置
     */
    updateInput() {
      switch (this.curIndex) {
        case 0:
          if (this.isAdd) {
            // 添加物品

            this.addLocation();
          } else {
            // 删除,修改物品

            this.updateLocation();
          }
          break;
        case 1:
          if (this.isAdd) {
            // 添加位置
            this.addLocation();
          } else {
            // 删除,修改位置
            this.updateLocation();
          }
          break;
        default:
          break;
      }
      this.curInput = "";

      // this.tempDispatch = {};
    },
    /**
     * 添加位置
     */
    addLocation() {
      let requestData = {
        parentId: this.tempDispatch.id,
        name: this.curInput,
      };
      if (requestData.name == "") {
        this.waitDealLocation();
        return;
      }
      this.$request(
        "post",
        "/waitDealLocation/add",
        requestData,
        (redata) => {
          this.$message.success("添加成功");

          this.tempDispatch.flag = false;
          this.tempDispatch = {};
          this.waitDealLocation();
        },
        (errormessage) => {
          this.$message.error(errormessage);
        }
      );
    },
    /**
     * 删除,修改位置
     */
    updateLocation() {
      let requestData = {
        id: this.tempDispatch.id,
        name: this.curInput,
      };
      if (this.curInput == "") {
        // 删除物品
        this.$request(
          "post",
          "/waitDealLocation/del",
          requestData,
          (redata) => {
            this.$message.success("删除成功");
            this.tempDispatch = {};
            this.waitDealLocation();
          },
          (errormessage) => {
            this.$message.error(errormessage);
          }
        );
      } else {
        // 修改物品
        this.$request(
          "post",
          "/waitDealLocation/edit",
          requestData,
          (redata) => {
            this.$message.success("修改成功");
            this.tempDispatch.flag = false;
            this.tempDispatch = {};
            this.waitDealLocation();
          },
          (errormessage) => {
            this.$message.error(errormessage);
          }
        );
      }
    },
    /**
     * 添加一级数据
     */
    addTags() {
      this.isAdd = true;
      this.list.push({
        flag: true,
        name: "",
      });
    },
    /**
     * 拖拽事件 参数依次为：被拖拽节点对应的 Node、结束拖拽时最后进入的节点、被拖拽节点的放置位置（before、after、inner）、event
     */
    handleDrop(draggingNode, dropNode, dropType) {
      console.log("sss", draggingNode, dropNode, dropType);
      let endWeight = "";
      if (dropNode.level == 1) {
        dropNode.parent.data.forEach((item, index) => {
          if (item.id == draggingNode.data.id) {
            endWeight = index;
          }
        });
      } else {
        dropNode.parent.data.children.forEach((item, index) => {
          if (item.id == draggingNode.data.id) {
            endWeight = index;
          }
        });
      }

      let requestData = {
        parentId: draggingNode.data.parentId, // 父级 id 没有不传
        begin: draggingNode.data.weight, // 开始序号
        end: endWeight, // 结束序号
      };

      if (
        draggingNode.data.parentId == dropNode.data.parentId &&
        dropType !== "inner"
      ) {
        if (this.curIndex == 0) {
          this.$request(
            "post",
            "/waitDealLocation/move",
            requestData,
            (data) => {
              this.$message.success("移动成功");
              this.waitDealLocation();
            },
            (errormessage) => {
              this.$message.error(errormessage);
            }
          );
        }
      } else {
        this.$message.error("只能进行同级拖动!");
        if (this.curIndex == 0) {
          this.waitDealLocation();
        } else {
        }
        return;
      }
      // var paramData = [];
      // // 当拖拽类型不为inner,说明只是同级或者跨级排序，只需要寻找目标节点的父ID，获取其对象以及所有的子节点，并为子节点设置当前对象的ID为父ID即可
      // // 当拖拽类型为inner，说明拖拽节点成为了目标节点的子节点，只需要获取目标节点对象即可
      // var data = dropType != "inner" ? dropNode.parent.data : dropNode.data;
      // var nodeData =
      //   dropNode.level == 1 && dropType != "inner" ? data : data.children;
      // // 设置父ID,当level为1说明在第一级，pid为空
      // console.log("ccc", data, nodeData);
      // nodeData.forEach((element) => {
      //   element.pid = data.id;
      // });
      // nodeData.forEach((element, i) => {
      //   var dept = {
      //     deptId: element.id,
      //     parentDeptId: element.pid,
      //     order: i,
      //   };
      //   paramData.push(dept);
      // });
      // // this.loading = true;
      // // 得到这次操作需要变更的数据范围，请求后台批量处理即可...
    },
    /**
     * 抽屉数据或dialog数据操作事件
     */
    drawerOper(active) {
      switch (active) {
        case "sure_edit":
          let requestData = {
            id: this.tempDispatch.id,
            name: this.tempDispatch.name, // 位置
            detail: this.dialogData.data[0].value, // 详细说明
          };
          this.$request(
            "post",
            "/waitDealLocation/edit",
            requestData,
            (data) => {
              this.$message.success("修改成功!");
              this.dialogData.flag = false;
              this.waitDealLocation();
            },
            (errormessage) => {
              this.$message.error(errormessage);
            }
          );
          break;
        case "cancel":
          this.colseDialog();
          break;
        default:
          break;
      }
    },
    locationDetail(data, node) {
      console.log("当前数据", data);
      this.tempDispatch = data;
      this.dialogData.width = "600px";
      this.dialogData.title = "地址详情";
      this.dialogData.buttonArray = [
        {
          text: "确定",
          active: "sure_edit",
        },
        {
          text: "取消",
          active: "cancel",
        },
      ];
      this.dialogData.data = [
        {
          title: "地址详情",
          type: "textarea",
          rows: 5,
          value: this.tempDispatch.detail,
        },
      ];
      this.dialogData.flag = true;
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
      this.waitDealLocation();
    },
  },
  mounted() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
    this.changeTab(0);
  },
};
</script>

<style lang="less" scoped>
#dispatch-setting {
  width: 100%;
  height: 100%;
  /deep/ .el-input__inner {
    pointer-events: none;
  }
  display: flex;
  flex-direction: column;
  .dispatch-table-content {
    flex: 1;
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #efefef;
    .dispatch-table-card {
      width: 98%;
      height: 95%;
      max-height: 830px;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .dispatch-table-card-content {
        width: 100%;
        height: 0;
        flex: 1;
        display: flex;
        flex-direction: column;
        .dispatch-table-card-content-tags-setting {
          width: 100%;
          height: 100%;
          display: flex;
          .dispatch-table-card-content-tags-setting-left {
            width: 8vw;
            height: 100%;
            display: flex;
            flex-direction: column;
            border-right: 1px solid @activeColor3;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12),
              0 0 6px rgba(0, 0, 0, 0.04);
            .dispatch-table-card-content-tags-setting-left-line {
              width: 100%;
              height: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: @contentFontSize;
              transition: 0.3s;
              color: #000;
              &:hover {
                cursor: pointer;
                color: @activeColor;
              }
            }
            .is-active {
              color: @activeColor;
            }
          }
          .dispatch-table-card-content-tags-setting-right {
            flex: 1;
            width: 0;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .dispatch-table-card-content-tags-setting-right-add {
              width: 100%;
              height: 8vh;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              padding-left: 20px;
              .butt {
                width: 100px;
                height: 40px;
                border: 1px solid @activeColor;
                background-color: @activeColor;
                color: #fff;
                font-size: @contentFontSize;
                display: flex;
                align-items: center;
                border-radius: 10px;
                justify-content: center;
                transition: 0.3s;
                &:hover {
                  cursor: pointer;
                  background-color: @activeColor2;
                }
              }
            }
            .dispatch-table-card-content-tags-setting-right-tree {
              flex: 1;
              height: 0;
              width: 97%;
              display: flex;
              flex-direction: column;
              overflow-y: auto;

              &::-webkit-scrollbar {
                width: 1px;
              }
              .dispatch-table-card-content-tags-setting-right-tree-line {
                display: flex;
                justify-content: space-between;
                height: 40px;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
