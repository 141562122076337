export function createCompanyDialogData() {
    let data = [{
            title: "机构名称",
            type: "input",
            value: "",
            key: "companyName"
        },
        {
            title: "机构负责人",
            type: "add-tip-select-search",
            placeholder: "选择机构负责人",
            options: [{
                label: "张三",
                value: "111"
            }],
            value: {},
            key: "companyAdminId"
        },
        {
            title: "",
            type: "tips",
            placeholder: "选择机构负责人",
            value: [],
            key: "companyAdminId"
        },
        {
            title: "",
            type: "add-but",
            text: "新增机构负责人",
            active: "add-companyAdmin"
        }
    ]
    return data
}

export function createCompanyAdminDialogData() {
    let data = [{
        title: "姓名",
        type: "input",
        value: "",
        key: ""
    }, {
        title: "手机号",
        type: "input",
        value: "",
        key: ""
    }, {
        title: "入职时间",
        type: "time",
        value: "",
        key: ""
    }, {
        title: "身份证号",
        type: "input",
        value: "",
        key: ""
    }, {
        title: "当前状态",
        type: "select",
        value: "1",
        options: [{ label: "在职", value: "1" }, { label: "离职", value: "0" }, ],
        key: ""
    }, ]
    return data
}