<template>
  <div id="waite-all">
    <div class="screen">
      <div class="screen-line">
        <div class="client_info-screen-but" @click="handlClick({}, 'add')">
          自定义待办
        </div>
        <div class="client_info-screen-active"></div>
      </div>
    </div>
    <div class="table-content">
      <div class="waite-all_table-card">
        <div class="waite-all-table-card-tabs">
          <div
            :class="{
              'waite-all-table-card-tab': true,
              'is-active': tabindex == 0,
            }"
            @click="changeTab(0)"
          >
            我的待办
          </div>
          <div
            :class="{
              'waite-all-table-card-tab': true,
              'is-active': tabindex == 1,
            }"
            @click="changeTab(1)"
          >
            历史待办
          </div>
          <div
            :class="{
              'waite-all-table-card-tab': true,
              'is-active': tabindex == 2,
            }"
            @click="changeTab(2)"
          >
            我的派工
          </div>
          <div class="waite-all-table-card-active"></div>
          <!-- <div class="waite-all-table-card-edit">
            <i class="el-icon-edit-outline" @click="updateTableMenu"></i>
          </div> -->
        </div>
        <div class="waite-all-table-card-content">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            @row-click="rowClick"
            style="width: 100%"
            height="98%"
            v-loading="loading"
          >
            <el-table-column type="index" width="55" fixed> </el-table-column>
            <el-table-column label="客户名称" prop="clientName" width="270">
              <template slot-scope="scope">
                <div class="hri">
                  <i
                    class="el-icon-notebook-2"
                    @click.stop="showGenjin(scope.row)"
                  ></i>
                  {{ scope.row.clientName }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="待办事项" prop="name"> </el-table-column>
            <el-table-column label="状态" prop="status" width="120">
            </el-table-column>
            <el-table-column label="经办人" width="280">
              <template slot-scope="scope">
                <div
                  class="hri"
                  v-for="(item, index) in scope.row.person"
                  :key="index"
                >
                  {{ item }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="时间" prop="des" width="180">
            </el-table-column>
            <el-table-column label="操作" width="120" v-if="tabindex != 2">
              <template slot-scope="scope">
                <div class="hri">
                  <el-button
                    type="primary"
                    size="mini"
                    v-if="tabindex == 0"
                    @click.native.stop="handlClick(scope.row, 'finish')"
                    >完成</el-button
                  >
                  <el-button
                    type="primary"
                    size="mini"
                    v-if="tabindex == 1"
                    @click.native.stop="handlClick(scope.row, 'reduction')"
                    >还原</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="waite-all-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog7
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @showCustomToDoData="showCustomToDoData"
        @buttonOper="drawerOper"
        @choseProductTipOne="choseProductTipOne"
        @handleAvatarSuccess="handleAvatarSuccess"
        @changeFlag="changeFlag"
        @addFollowRecord="followRecord"
      ></my_dialog7>
    </div>

    <!-- 右边抽屉 -->

    <div id="my-drawer" v-if="contractDrawerData.flag">
      <my_drawer
        :drawerData="contractDrawerData"
        @show="showDrawer"
        @closeUpdateTableDrawer="closeUpdateTableDrawer"
        @saveDrawerData="saveDrawerData"
        @tagsHandleClose="tagsHandleClose"
        @addTags="addTags"
        @drawerOper="drawerOper"
        @showExpendDetails="showExpendDetails"
        @addExpend="addExpend"
        @showExpend="showExpend"
        @radioChange="radioChange"
        @cancelServiceItems="cancelServiceItems"
        @deleteService="deleteService"
        @showAttachment="showAttachment"
        @lineOpers="lineOper"
        @linkNow="linkNow"
      ></my_drawer>
    </div>
  </div>
</template>

<script>
import * as WaiteData from "@/assets/js/waiteData.js";
import * as contractDrawData from "@/assets/js/contractDraw.js";
import * as clientInfoData from "@/assets/js/clientInfoData.js";
export default {
  data() {
    return {
      loading: false,
      baseInfo: {},
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      tabindex: 0,
      searchData: {
        lossReasonOptions: [],
      },
      tableData: [],
      type: "all",
      employeeList: [],
      dialogData: {
        flag: false,
        width: "30%",
        title: "自定义待办事项",
        buttonArray: [
          {
            text: "提交",
            active: "submit",
          },
          {
            text: "取消",
            active: "cancel",
          },
        ],
        data: [],
      },
      tempClient: { id: "", name: "" },

      tempContract: { id: "", name: "" }, // 临时合同
      tempServices: [], // 该合同所包含的所有服务
      buttonAuth: [],
      productList: [], // 所有合同模板/产品数据
      professionList: [], // 所有合同执行模板
      serviceItemList: [], // 所有服务项数据
      serviceTypeList: [], // 所有服务类别数据
      employeeList: [], // 所有员工
      departmentList: [], // 所有员工
      clientList: [],
      contractSettingButtonList: [],
      accountList: [], // 收款账号
      tempTags: [],

      tipsData: {
        clientTipsList: [], // 客户标签
        pianquList: [], // 片区标签
        clientLevelList: [], // 客户等级
        sourceList: [], // 客户来源
      },

      contractDrawerData: contractDrawData.contractInfoDrawerData(
        "",
        [],
        [],
        {
          text: "",
          active: "",
        },
        []
      ),
      ttempClient: {},
    };
  },
  methods: {
    getGlobalVariable() {
      let employeeList = JSON.parse(
        JSON.stringify(this.$store.getters.getAllEmployeeList)
      );
      this.employeeList = [];
      employeeList.forEach((element) => {
        let dd = {
          label: element.userName,
          value: element.userId,
        };
        this.employeeList.push(dd);
      });

      let productList = JSON.parse(
        JSON.stringify(this.$store.getters.getAllProductList)
      );
      this.productList = productList;

      let departmentList = JSON.parse(
        JSON.stringify(this.$store.getters.getAllDepartmentList)
      );
      this.departmentList = [];
      departmentList.forEach((element) => {
        let dd = {
          label: element.departmentName,
          value: element.departmentId,
        };
        this.departmentList.push(dd);
      });

      let serviceTypeList = JSON.parse(
        JSON.stringify(this.$store.getters.getAllServiceTypeList)
      );
      this.serviceTypeList = serviceTypeList;

      let serviceItemList = JSON.parse(
        JSON.stringify(this.$store.getters.getAllServicesItemList)
      );
      this.serviceItemList = serviceItemList;

      let clientList = JSON.parse(
        JSON.stringify(this.$store.getters.getAllClientByUserId)
      );
      this.clientList = [];
      clientList.forEach((element) => {
        let dd = {
          id: element.clientId,
          clientName: element.clientName,
        };
        this.clientList.push(dd);
      });

      let professionList = JSON.parse(
        JSON.stringify(this.$store.getters.getAllProfessionList)
      );
      this.professionList = professionList;

      contractDrawData.getTagList(
        this.tipsData,
        ["客户标签", "片区", "客户来源", "客户等级"],
        this.baseInfo.company.companyId,
        this
      );
      this.getTagList();
    },
    /**
     * 初始化数据
     */
    initData() {
      let requestData = {
        type: this.type,
        userId: this.baseInfo.userId,
        flag: this.tabindex,
        companyId: this.baseInfo.company.companyId,
        currentPage: this.pageData.currentPage, // 当前页数  20210903 加
        pageSize: this.pageData.pageSize, // 每页条数  20210903 加
      };
      this.$request(
        "post",
        "/waitDeal/getAllWaitDealByUserId",
        requestData,
        (data) => {
          this.tableData = data.tableData;
          this.pageData.total = data.total;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    // 获取当前所需标签
    getTagList() {
      let requestData = {
        type: ["报销类型", "付款方式", "流失原因"],
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/getTagList",
        requestData,
        (data) => {
          console.log("当前获取到的标签组:", data);
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            if (element.type == "流失原因") {
              this.searchData.lossReasonOptions = element.value;
            }
          }
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * tab切换事件
     */
    changeTab(index) {
      this.tabindex = index;
      this.initData();
    },
    /**
     * 表格行点击事件
     */
    rowClick(row, colum, event) {
      if (this.tabindex == 0) {
        // 待办事项的点击事件
        // let requestData = {
        //   userId: this.baseInfo.userId,
        //   id: row.id,
        // };
        // this.$request(
        //   "post",
        //   "/waitDeal/finishWaitDeal",
        //   requestData,
        //   (data) => {
        this.$router.push(WaiteData.routingHop(row.type, 1));
        // },
        // (errorMessage) => {
        //   this.$message.error(errorMessage);
        // }
        // );
      } else {
        // 历史待办的点击事件
        // this.revokeWaitDeal(row.id);
      }
    },
    /**
     * 撤销历史待办事项
     */
    revokeWaitDeal(id) {
      let requestData = {
        userId: this.baseInfo.userId,
        id: id,
      };
      this.$request(
        "post",
        "/waitDeal/revokeWaitDeal",
        requestData,
        (data) => {
          this.initData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 具体事项操作事件
     */
    handlClick(data, flag) {
      let requestData = {};
      switch (flag) {
        case "finish":
          // 手动完成自定义事项
          // this.finishWaitDeal(data.id);
          let requestData = {
            userId: this.baseInfo.userId,
            id: data.id,
          };
          this.$request(
            "post",
            "/waitDeal/finishWaitDeal",
            requestData,
            (data) => {
              this.$message({
                message: "完成!",
                type: "success",
                duration: 1000,
              });
              this.initData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );

          break;
        case "reduction":
          // 还原历史事项
          this.revokeWaitDeal(data.id);
          break;
        case "add":
          // 添加自定义待办
          this.dialogData.title = "自定义待办事项";
          this.dialogData.width = "65%";
          this.dialogData.buttonArray = [
            {
              text: "提交",
              active: "submit_waite_to_do",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              type: "custom-to-do",
              title: "",
              value: {
                name: "",
                data: [],
                clientId: "",
              },
            },
          ];
          this.dialogData.flag = true;
          break;

        default:
          break;
      }
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initData();
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 自定义待办父子组件连锁事件
     */
    showCustomToDoData(val) {
      this.dialogData.data = val;
    },

    /**
     * 点击客户
     */
    showClient(row) {
      this.tempClient.name = row.clientName;
      this.tempClient.id = row.clientId;
      if (this.tempClient.id != "") {
        this.showClientByID();
      }
    },
    /**
     * 根据客户ID,查看客户信息
     */
    showClientByID() {
      this.$authority.getModelAuthority(
        ["client", "clientInfo"],
        "explicitData",
        this,
        false,
        (authorityData) => {
          this.$authority.getModelAuthority(
            ["client", "clientInfo"],
            "modelChildMenu",
            this,
            false,
            (buttonAuth) => {
              let requestData = {
                clientId: this.tempClient.id,
                userId: this.baseInfo.userId,
                companyId: this.baseInfo.company.companyId,
                authorityData: authorityData, // 客户的具体模块权限
                buttonAuth: JSON.parse(JSON.stringify(buttonAuth)),
              };
              this.$request(
                "post",
                "/client/showClientInfoByID",
                requestData,
                (data) => {
                  let serviceList = {
                    pianquList: this.tipsData.pianquList, // 片区
                    sourceList: this.tipsData.sourceList, // 客户来源
                    tipList: this.tipsData.clientTipsList, // 客户标签
                    levelList: this.tipsData.clientLevelList, // 客户等级
                    departmentList: this.departmentList, // 部门
                    employeeList: this.employeeList, // 员工
                  };

                  let employeeList = [];
                  this.employeeList.forEach((element) => {
                    let ll = {
                      id: element.value,
                      name: element.label,
                    };
                    employeeList.push(ll);
                  });
                  this.contractDrawerData = clientInfoData.clientInfoDrawerData(
                    data.data[5].value,
                    data.data,
                    // clientInfoData.getClientInfoData(),
                    employeeList,
                    data.buttonAuth,
                    serviceList
                  );
                  if (
                    this.contractDrawerData.data.modelList[2].line[6].value !=
                      "" &&
                    (this.contractDrawerData.data.modelList[2].line[5].value ==
                      [] ||
                      this.contractDrawerData.data.modelList[2].line[5].value ==
                        "" ||
                      this.contractDrawerData.data.modelList[2].line[5].value ==
                        "[]")
                  ) {
                    this.contractDrawerData.data.modelList[2].line[5].value = [];
                    this.contractDrawerData.data.modelList[2].line[5].value = this.$addressAutomaticallyMatch.addressAutomaticallyMatch(
                      this.contractDrawerData.data.modelList[2].line[6].value
                    );

                    if (
                      this.contractDrawerData.data.modelList[2].line[5].value ==
                        null ||
                      this.contractDrawerData.data.modelList[2].line[5].value
                        .length < 3
                    ) {
                      this.contractDrawerData.data.modelList[2].line[5].value = [];
                    }
                  }
                  this.contractDrawerData.flag = true;
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            }
          );
        }
      );
    },
    /**
     * 获取合同信息具体数据
     */
    showContractInfoByID() {
      this.$authority.getModelAuthority(
        ["contract", "contractInfo"],
        "explicitData",
        this,
        false,
        (authorityData) => {
          this.$authority.getModelAuthority(
            ["contract", "contractInfo"],
            "modelChildMenu",
            this,
            false,
            (buttonAuth) => {
              let requestData = {
                contractId: this.tempContract.id,
                userId: this.baseInfo.userId,
                companyId: this.baseInfo.company.companyId,
                authorityData: authorityData, // 合同的具体模块权限
                buttonAuth: buttonAuth,
              };
              this.$request(
                "post",
                "/contract/showContractByID",
                requestData,
                (data) => {
                  this.tempServices = [];
                  this.contractSettingButtonList = data.buttonAuth;
                  data.data[17].value.forEach((element) => {
                    let serves = {
                      name: element.tableData.name.name,
                      money: element.item[5].value,
                      expendMoney: "",
                      des: "",
                    };
                    this.tempServices.push(serves);
                  });
                  this.contractDrawerData = contractDrawData.contractInfoDrawerData(
                    this.tempClient.name,
                    data.data,
                    // contractDrawData.getContractInfoData(),
                    data.buttonAuth,
                    { text: "提交", active: "submit" },
                    this.serviceItemList
                  );
                  this.contractDrawerData.data.isLink = false;
                  this.contractDrawerData.data.modelList[0].line[1].options = this.employeeList;
                  this.contractDrawerData.data.modelList[0].line[4].options = this.tipsData.sourceList;
                  this.contractDrawerData.flag = true;
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            }
          );
        }
      );
    },
    /**
     * 获取合同附件数据
     */
    getAttachmentByContractId() {
      let requestData = {
        contractId: this.tempContract.id,
      };
      this.$request(
        "post",
        "/contract/getAttachmentByContractId",
        requestData,
        (data) => {
          this.contractDrawerData = contractDrawData.contractEttachmentDrawerData(
            this.tempClient.name,
            data,
            this.buttonAuth,
            this
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 更新/修改/添加客户联系人
     */
    updateClientContactByID() {
      if (
        !clientInfoData.formatClientContactDrawerData(
          this.contractDrawerData.data.modelList
        ).flag
      ) {
        this.$message({
          message: "联系人手机号格式不正确,请确认填入正确的手机号!",
          type: "error",
          duration: 1000,
        });
        // this.getMyThreadDetailsByThreadId();
        return;
      }
      let requestData = {
        clientId: this.tempClient.id,
        data: clientInfoData.formatClientContactDrawerData(
          this.contractDrawerData.data.modelList
        ).list,
      };
      this.$request(
        "post",
        "/client/updateClientContactByID",
        requestData,
        (data) => {
          this.$message({
            message: "客户联系人操作成功!",
            type: "success",
            duration: 1000,
          });
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },

    /**
     * 保存抽屉数据
     */
    saveDrawerData(drawerTitle) {
      let requestData = {};
      console.log("保存数据:", this.contractDrawerData);
      if (this.contractDrawerData.title == "合同") {
        switch (this.contractDrawerData.cardIndex) {
          case 0:
            // 合同信息的保存
            if (
              contractDrawData.formatContractInfoDrawerData(
                this.contractDrawerData.data.modelList
              )
            ) {
              requestData = {
                contractId: this.tempContract.id,
                userId: this.baseInfo.userId,
                clientId: this.tempClient.id,
                companyId: this.baseInfo.company.companyId,
                data: contractDrawData.formatContractInfoDrawerData(
                  this.contractDrawerData.data.modelList
                ),
                isRenew: false,
              };
              this.$request(
                "post",
                "/contract/updateContractInfoByID",
                requestData,
                (data) => {
                  this.$message({
                    message: "保存成功!",
                    type: "success",
                    duration: 1000,
                  });
                  this.showContractInfoByID();
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            } else {
              this.$message.error("服务项名称不能为空!");
            }
            break;
          case 4:
            // 合同附件的上传
            this.contractDrawerData.data.modelList[0].line[0].value.data.forEach(
              (element) => {
                if (this.$keyType.keyType(element.url) != "Object") {
                  let uu = {
                    path: element.url,
                    title: "合同附件",
                  };
                  element.url = uu;
                }
              }
            );
            requestData = {
              contractId: this.tempContract.id,
              userId: this.baseInfo.userId,
              clientId: this.tempClient.id,
              companyId: this.baseInfo.company.companyId,
              data: this.contractDrawerData.data.modelList[0].line[0].value
                .data,
            };
            this.$request(
              "post",
              "/contract/saveAttachmentByContractId",
              requestData,
              (data) => {
                this.$message({
                  message: "附件操作成功!",
                  type: "success",
                  duration: 1000,
                });
                this.getAttachmentByContractId();
              },
              (errorMessage) => {
                this.$message.error(errorMessage);
              }
            );
            break;

          default:
            break;
        }
      }
      if (this.contractDrawerData.title == "客户") {
        requestData = {
          clientId: this.tempClient.id,
          companyId: this.baseInfo.company.companyId,
          data: [],
        };
        switch (this.contractDrawerData.cardIndex) {
          case 0:
            // 更新客户信息
            requestData.data = clientInfoData.formatClientInfoDrawerData(
              this.contractDrawerData.data.modelList
            );
            this.$request(
              "post",
              "/client/updateClient",
              requestData,
              (data) => {
                this.$message({
                  message: "修改成功!",
                  type: "success",
                  duration: 1000,
                });
              },
              (errorMessage) => {
                this.$message.error(errorMessage);
              }
            );
            break;
          case 1:
            // 更新客户联系人
            this.updateClientContactByID();
            break;

          default:
            break;
        }
      }
    },

    /**
     * 显示支出详情
     */
    showExpendDetails(data) {
      console.log("显示支出详情:", data, this.tempClient.name);
      let dd = {
        client: this.tempContract.name,
        contractNum: this.tempContract.name,
        contractId: this.tempContract.id,
        expendStatus: data.expendStatus,
        expendNum: data.expendNum,
        time: data.time,
        expendType: data.expendType,
        user: data.user,
        title: data.title,
        file: data.file, // 附件
        services: data.services,
      };
      this.dialogData.title = "支出";
      this.dialogData.width = "40%";
      this.dialogData.buttonArray = [];
      this.dialogData.buttonArray.push({
        text: "取消",
        active: "cancel_expend",
      });
      this.dialogData.data = [
        {
          type: "expend_details",
          title: "",
          value: dd,
        },
      ];
      this.dialogData.flag = true;
    },
    /**
     * 添加支出
     */
    addExpend() {
      let options = [
        {
          id: "",
          name: "刻章",
          flag: false,
        },
        {
          name: "地址费",
          flag: false,
        },
        {
          name: "材料费",
          flag: false,
        },
        {
          name: "外包成本",
          flag: false,
        },
        {
          name: "其他成本",
          flag: false,
        },
        {
          name: "业务招待费",
          flag: false,
        },
      ];
      this.dialogData.title = "新建支出";
      this.dialogData.buttonArray = [];
      this.dialogData.buttonArray.push({
        text: "取消",
        active: "cancel",
      });
      this.dialogData.data = [
        {
          type: "text",
          title: "",
          value: "请选择一个支出类型",
        },
        {
          type: "select_array_one",
          title: "",
          value: options,
        },
      ];
      this.dialogData.flag = true;
    },
    showExpend(value) {
      this.dialogData.data[0] = value;
    },
    /**
     * 获取收款记录
     */
    getReceiptRecord() {
      console.log("获取回款记录");
      let requestData = {
        contractId: this.tempContract.id,
      };
      this.$request(
        "post",
        "/contract/getReceiptRecord",
        requestData,
        (data) => {
          this.contractDrawerData = contractDrawData.contractReturnedMoneyDrawerData(
            this.tempClient.name,
            data,
            this.contractSettingButtonList
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 查看具体附件内容
     */
    linkNowFile(data) {
      if (data.item.fileType == "img") {
        // 附件是图片
        this.dialogData.title = "查看收款附件";
        this.dialogData.width = "30%";
        this.dialogData.data = [
          {
            type: "show_img",
            value: data.item.file,
            title: "",
          },
        ];
        this.dialogData.buttonArray = [];
        this.dialogData.flag = true;
      } else if (data.item.file != "") {
        // 附件是文件
        // window.open(data.item.file, "_blank");

        if (this.$keyType.keyType(data.item.file) == "Object") {
          window.open(data.item.file.path, "_blank");
        } else if (this.$keyType.keyType(data.item.file) == "String") {
          window.open(data.item.file, "_blank");
        } else {
          this.$message.error("附件格式错误,请联系管理员!");
        }
      }
    },
    /**
     * 获取收费情况
     */
    getCharge() {
      let requestData = {
        contractId: this.tempContract.id,
      };
      this.$request(
        "post",
        "/contract/getCharge",
        requestData,
        (data) => {
          this.contractDrawerData = contractDrawData.contractShouFeiDrawerData(
            this.tempClient.name,
            data,
            this.contractSettingButtonList
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取合同支出情况
     */
    getExpendByContractId() {
      let requestData = {
        contractId: this.tempContract.id,
      };
      this.$request(
        "post",
        "/contract/getExpendByContractId",
        requestData,
        (data) => {
          this.contractDrawerData = contractDrawData.contractExpendDrawerData(
            this.tempClient.name,
            // contractDrawData.getContractExpendData()
            data,
            this.buttonAuth
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 合同提交
     */
    submitContract() {
      if (this.contractDrawerData.data.modelList[0].line[5].value == "") {
        this.$message({
          message: "合同成本不能为空!",
          duration: 1000,
          type: "error",
        });
        return;
      }
      let requestData = {
        userId: this.baseInfo.userId,
        id: this.tempContract.id,
      };
      this.$request(
        "post",
        "/contract/submitContract",
        requestData,
        (data) => {
          this.dialogData.flag = false;
          this.contractDrawerData.flag = false;
          this.$message({
            message: "合同提交成功!",
            duration: 1000,
            type: "success",
          });
          this.initData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 合同删除
     */
    deleteContract() {
      let requestData = {
        contractId: this.tempContract.id,
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$confirm("此操作将永久删除该合同, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$request(
            "post",
            "/contract/deleteContract",
            requestData,
            (data) => {
              this.$message({
                type: "success",
                message: "删除成功",
                duration: 1000,
              });
              this.contractDrawerData.flag = false;
              this.initData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    /**
     * 显示合同列表
     */
    getAllContractByClientId() {
      this.$authority.getModelAuthority(
        ["client", "clientInfo"],
        "modelChildMenu",
        this,
        false,
        (buttonAuth) => {
          let requestData = {
            clientId: this.tempClient.id,
            companyId: this.baseInfo.company.companyId,
            userId: this.baseInfo.userId,
          };
          this.$request(
            "post",
            "/contract/getAllContractByClientId",
            requestData,
            (data) => {
              this.contractDrawerData = clientInfoData.clientContractDrawerData(
                this.tempClient.name,
                data,
                buttonAuth
              );
              this.contractDrawerData.flag = true;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
        }
      );
    },
    /**
     * 根据客户ID,查看客户联系人
     */
    showClientContactDataByID() {
      this.$authority.getModelAuthority(
        ["client", "clientInfo"],
        "explicitData",
        this,
        true,
        (authorityData) => {
          this.$authority.getModelAuthority(
            ["client", "clientInfo"],
            "modelChildMenu",
            this,
            false,
            (buttonAuth) => {
              let requestData = {
                clientId: this.tempClient.id,
                userId: this.baseInfo.userId,
                companyId: this.baseInfo.company.companyId,
                authorityData: authorityData, // 客户的具体模块权限
              };
              this.$request(
                "post",
                "/client/showClientContactByID",
                requestData,
                (data) => {
                  this.contractDrawerData = clientInfoData.clientContactDrawerData(
                    this.tempClient.name,
                    // data.data[5].value,
                    data,
                    buttonAuth
                  );
                  this.contractDrawerData.flag = true;
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            }
          );
        }
      );
    },
    /**
     * 申请客户流失
     */ toApplyForCustomerChurn() {
      let requestData = {
        clientId: this.tempClient.id,
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/client/getAllServiceTypeListByClientId",
        requestData,
        (data) => {
          this.dialogData.title = "选择流失类型";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push({
            text: "确定",
            active: "confirm_that_client_loss",
          }, {
            text: "取消",
            active: "cancel",
          });
          let options = [];
          data.forEach((element) => {
            let dd = {
              label: element.typeName,
              value: element.id,
            };
            options.push(dd);
          });
          let options2 = [];
          this.searchData.lossReasonOptions.forEach((element) => {
            let dd = {
              label: element.name,
              value: element.value,
            };
            options2.push(dd);
          });
          this.dialogData.data = [
            {
              title: "流失类型",
              type: "select-obj",
              value: { label: "全部", value: "all" },
              options: options,
            },
            {
              title: "流失原因",
              type: "select",
              value: "",
              options: options2,
            },
          ];
          this.dialogData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取工商信息
     * 根据企业全称调用企查查接口,获取企业税号、增值税类型、行业分类、所在地址、详细地址
     */ getCompanyInfo() {
      let requestData = {
        name: this.contractDrawerData.data.modelList[2].line[0].value,
      };
      this.$request(
        "post",
        "/client/getCreditCode",
        requestData,
        (data) => {
          this.contractDrawerData.data.modelList[2].line[2].value =
            data.taxNum == ""
              ? this.contractDrawerData.data.modelList[2].line[2].value
              : data.taxNum;
          this.contractDrawerData.data.modelList[2].line[1].value =
            data.taxNum == ""
              ? this.contractDrawerData.data.modelList[2].line[1].value
              : data.taxNum;
          this.contractDrawerData.data.modelList[2].line[8].value =
            data.bank == ""
              ? this.contractDrawerData.data.modelList[2].line[8].value
              : data.bank;
          (this.contractDrawerData.data.modelList[2].line[9].value =
            data.bankAccount == ""
              ? this.contractDrawerData.data.modelList[2].line[9].value
              : data),
            bankAccount;
          this.contractDrawerData.data.modelList[2].line[7].value =
            data.tel == ""
              ? this.contractDrawerData.data.modelList[2].line[7].value
              : data.tel;
          this.contractDrawerData.data.modelList[2].line[5].value =
            data.address == ""
              ? []
              : this.$addressAutomaticallyMatch.addressAutomaticallyMatch(
                  data.address
                );

          if (
            this.contractDrawerData.data.modelList[2].line[5].value == null ||
            this.contractDrawerData.data.modelList[2].line[5].value.length < 3
          ) {
            this.contractDrawerData.data.modelList[2].line[5].value = [];
          }
          this.contractDrawerData.data.modelList[2].line[6].value =
            data.address == ""
              ? this.contractDrawerData.data.modelList[2].line[6].value
              : data.address;
          this.saveDrawerData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 确定添加跟进记录
     */
    followRecord(recordData) {
      console.log("跟进记录:", recordData);
      let requestData = {
        clientId: this.ttempClient.clientId, // 商机ID
        threadId: "", // 商机ID
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId, // 操作人ID
        content: recordData.content, // 当前操作
        time: recordData.time, // 下次跟进时间
        stage: "",
      };
      if (requestData.content == "") {
        this.$message.error("跟进内容不能为空!");
        return;
      }
      this.$request(
        "post",
        "/followRecord/addFollowRecord",
        requestData,
        (data) => {
          this.$message({
            message: "跟进记录添加成功!",
            type: "success",
            duration: 1000,
          });
          this.$operationRecords.saveOperationRecords(
            this.ttempClient.clientId,
            "添加跟进记录:" + recordData.content + "!",
            this.baseInfo,
            "client",
            this,
            (data2) => {
              this.showGenjin(this.ttempClient);
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },

    /**
     * 是否赠送服务项单选框选择事件
     */
    radioChange(data) {
      console.log("是否赠送服务项单选框选择事件", data);
      if (data.item.title == "是否赠送服务") {
        if (data.val == "Y") {
          this.contractDrawerData.data.modelList[3].line[0].isShow = true;
        } else {
          this.contractDrawerData.data.modelList[3].line[0].isShow = false;
          this.contractDrawerData.data.modelList[3].line[0].value = [];
        }
      }
      if (data.item.title == "是否优惠") {
        if (data.val == "Y") {
          this.contractDrawerData.data.modelList[0].line[8].isShow = true;
          this.clientInfoDrawerData.data.modelList[0].line[8].value = "0.00";
        } else {
          this.contractDrawerData.data.modelList[0].line[8].isShow = false;
          this.contractDrawerData.data.modelList[0].line[8].value = "";
        }
      }
      this.saveDrawerData();
    },

    /**
     * 取消赠送服务项修改
     */
    cancelServiceItems() {
      this.showContractInfoByID();
    },

    /**
     * 删除具体赠送的服务项
     */
    deleteService(index) {
      this.contractDrawerData.data.modelList[3].line[0].value.splice(index, 1);
      this.saveDrawerData();
    },
    /**
     * 抽屉中删除当前联系人
     */
    lineOper(data) {
      switch (data.active) {
        case "gongkai":
          break;
        case "shanchu":
          this.$confirm("此操作将永久删除该联系人, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.contractDrawerData.data.modelList.splice(data.i, 1);
              this.updateClientContactByID();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
          break;
        default:
          break;
      }
    },

    linkNow(dd) {
      this.tempContract.id = dd.item.id;
      this.showContractInfoByID();
    },

    /**
     * 抽屉组件中按钮操作事件(主要是显示弹出框事件)
     */
    drawerOper(active) {
      let dd = {};
      let requestData = {};
      switch (active) {
        case "info":
          // 合同信息
          if (this.contractDrawerData.title == "客户") {
            this.showClientByID();
          }
          if (this.contractDrawerData.title == "合同") {
            this.showContractInfoByID();
          }
          break;
        case "huikuan":
          // 收款记录
          this.getReceiptRecord();
          break;
        case "shoufei":
          // 收费情况
          this.getCharge();
          break;
        case "zhichu":
          // 合同支出
          this.getExpendByContractId();
          break;
        case "fujian":
          // 合同附件
          this.getAttachmentByContractId();
          break;
        case "add_huikuan":
          // 添加回款
          console.log("该合同所有服务项:", this.tempServices);
          let options = [];
          let accountList = [];
          this.tempServices.forEach((element) => {
            let ll = {
              label: element.name,
              value: element.name,
            };
            options.push(ll);
          });
          this.accountList.forEach((element) => {
            let accountList1 = "";
            element.accountList.forEach((account) => {
              accountList1 =
                accountList1 == ""
                  ? account.account
                  : accountList1 + "," + account.account;
            });

            let dd = {
              label: element.owner,
              value: accountList1, // 拿到该收款商户对应的收款账号组
            };
            accountList.push(dd);
          });
          this.dialogData.title = "添加回款信息";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push(
            {
              text: "确定",
              active: "sure_huikuan",
            },
            {
              text: "取消",
              active: "cancel",
            }
          );
          this.dialogData.data = [
            {
              type: "input_disable",
              title: "客户名称",
              value: this.tempClient.name,
            },
            {
              type: "input",
              title: "回款金额",
              value: "",
            },
            {
              type: "select",
              title: "回款服务项",
              value: "",
              options: options,
            },
            {
              type: "select",
              title: "收款账户",
              value: "",
              options: accountList,
            },
            {
              type: "input",
              title: "交易单号",
              value: "",
            },
            {
              type: "radio",
              title: "上传位置",
              value: "0",
              options: [
                {
                  label: "本地",
                  value: "0",
                },
                {
                  label: "云盘",
                  value: "1",
                },
              ],
            },
            {
              type: "upload",
              title: "付款凭证",
              value: {
                value: "", // 回款单附件
                action: this.$fileBaseurl + "/uploadImageByMultipartFile",
                headers: {
                  token: "95931b371eb64f4a9c862983ca8a5d15",
                },
                uploadParameter: {
                  systemName: "finance",
                  title: "付款凭证附件",
                },
              },
            },
          ];
          this.dialogData.flag = true;

          break;
        case "sure_huikuan":
          // 确认添加回款信息
          requestData = {
            contractId: this.tempContract.id, // 合同ID
            addTime: this.$formatTime(new Date(), "YYYY-MM-DD HH:mm:ss"), // 回款时间
            money: this.dialogData.data[1].value, // 回款金额
            service: this.dialogData.data[2].value, // 回款服务项
            receivedId: this.dialogData.data[4].value, // 回款账号
            file: this.dialogData.data[6].value.value, // 回款单附件
            account: this.dialogData.data[3].value, // 回款账号
            userId: this.baseInfo.userId, // 当前操作人
            status: "待审核", // 回款状态
            companyId: this.baseInfo.company.companyId,
          };

          if (requestData.money == "") {
            this.$message({
              message: "回款金额不能为空!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          if (requestData.service == "") {
            this.$message({
              message: "回款服务项不能为空!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          if (requestData.account == "") {
            this.$message({
              message: "回款账号不能为空!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          if (requestData.receivedId == "") {
            this.$message({
              message: "回款单号不能为空!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          if (requestData.file == "") {
            this.$message({
              message: "请上传付款凭证!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          console.log("确认添加回款记录:", requestData);
          this.$request(
            "post",
            "/contract/chargeContract",
            requestData,
            (data) => {
              this.$message({
                message: "操作成功!",
                type: "success",
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.showContractInfoByID();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "create-contract":
          // 新建合同
          this.dialogData.title = "选择产品";
          this.dialogData.width = "50%";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push({
            text: "取消",
            active: "cancel",
          });
          this.dialogData.data = [
            {
              type: "new-contract",
              title: "",
              value: { clientName: this.tempClient.name },
            },
          ];
          this.dialogData.flag = true;
          break;
        case "submit":
          // 合同提交
          this.$confirm("确定合同提交?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.submitContract();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消提交",
                duration: 1000,
              });
            });

          break;
        case "add_serves":
          // 添加服务项
          dd = {
            flag: true,
            tableData: {},
            item: [
              {
                horizontal: false,
                isShow: true,
                isDisable: false,
                title: "服务名称",
                type: "select",
                value: "",
                options: this.serviceItemList,
              },
              {
                horizontal: false,
                isShow: true,
                isDisable: false,
                title: "付费周期",
                type: "select",
                value: "每年",
                options: [
                  {
                    label: "每年",
                    value: "每年",
                    img: "",
                  },
                  {
                    label: "每月",
                    value: "每月",
                    img: "",
                  },
                  {
                    label: "每季度",
                    value: "每季度",
                    img: "",
                  },
                ],
              },
              {
                horizontal: false,
                isShow: true,
                isDisable: true,
                title: "催费时间",
                type: "收费提醒选择",
                radio: "提前",
                beforeDay: "",
                lateDay: "",
                value: "",
              },
              {
                horizontal: false,
                isShow: true,
                isDisable: false,
                title: "优惠",
                type: "discounts",
                value: {
                  discount: "",
                  flag: "",
                  mitigate: "",
                  totalPrice: "",
                },
              },
              {
                horizontal: false,
                isShow: true,
                isDisable: false,
                title: "首次催费时间",
                type: "time",
                value: "",
              },
              {
                horizontal: false,
                isShow: true,
                isDisable: false,
                title: "服务总价",
                type: "input",
                value: "",
              },
              {
                horizontal: false,
                isShow: true,
                isDisable: false,
                title: "服务时间",
                type: "timeToTime",
                value: [
                  {
                    discounts: "",
                    money: "",
                    price: "",
                    time1: "2021-07",
                    time2: "2022-07",
                  },
                ],
              },
            ],
          };
          this.contractDrawerData.data.modelList[2].line[0].value.push(dd);
          break;
        case "add_free_serves":
          // 添加赠送的服务项
          if (!this.contractDrawerData.data.modelList[3].line[0].isDisable) {
            dd = {
              isEdit: true, // 是否是编辑状态
              flag: true, // 是否是新增
              serviceItem: {}, //服务项
              serviceTime: "", // 服务时间
            };
            this.contractDrawerData.data.modelList[3].line[0].value.push(dd);
          } else {
            this.$message({
              message: "当前合同状态不可编辑!",
              duration: 1000,
              type: "error",
            });
          }
          break;
        case "expend_submit":
          // 合同支出提交
          requestData = {
            contractId: this.tempContract.id, // 合同ID
            expendNum: this.dialogData.data[0].value.expendNum, // 支出编号
            expendType: this.dialogData.data[0].value.expendType, // 支出类型
            expendStatus: "完成", // 支出状态  待提交  待审核  完成  审核不通过
            time: this.$formatTime(new Date(), "YYYY-MM-DD HH:mm:ss"), // 确认支出时间  当状态为完成时自动填充
            title: this.dialogData.data[0].value.title, //收款账户
            file: this.dialogData.data[0].value.file, // 附件
            services: this.dialogData.data[0].value.services,
            user: this.baseInfo.userId,
          };
          if (requestData.title == "") {
            this.$message.error("支出标题不能为空!");
            return;
          }
          if (requestData.file == "") {
            this.$message.error("支出附件不能为空!");
            return;
          }
          if (requestData.services[0].expendMoney == "") {
            this.$message.error("支出金额不能为空!");
            return;
          }
          this.$request(
            "post",
            "/contract/updateExpend",
            requestData,
            (data) => {
              this.$message({
                message: "操作成功!",
                type: "success",
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.getExpendByContractId();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "expend_echeck":
          // 合同支出审核
          requestData = {
            contractId: this.tempContract.id, // 合同ID
            expendNum: this.dialogData.data[0].value.expendNum, // 支出编号
            expendType: this.dialogData.data[0].value.expendType, // 支出类型
            expendStatus: "完成", // 支出状态  待提交  待审核  完成  审核不通过
            time: this.dialogData.data[0].value.time, // 确认支出时间  当状态为完成时自动填充
            receiptAccount: this.dialogData.data[0].value.receiptAccount, //收款账户
            receiptName: this.dialogData.data[0].value.receiptName, // 收款人姓名
            blank: this.dialogData.data[0].value.blank, // 开户银行  如徽商银行等
            des: this.dialogData.data[0].value.des, // 支出备注
            file: this.dialogData.data[0].value.file, // 附件
            services: this.dialogData.data[0].value.services,
          };
          this.$request(
            "post",
            "/contract/updateExpend",
            requestData,
            (data) => {
              this.$message({
                message: "操作成功!",
                type: "success",
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.getExpendByContractId();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;

        case "delete_contract":
          // 删除合同
          this.$request(
            "post",
            "/contract/beforeDelContract",
            { contractId: this.tempContract.id },
            (data) => {
              if (data.flag) {
                // 正常删除  当前合同没有收款记录
                this.deleteContract();
              } else {
                // 有收款记录,提示删除
                this.$confirm("该合同已有收款记录,是否继续删除?", "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                })
                  .then(() => {
                    this.deleteContract();
                  })
                  .catch(() => {});
              }
            }
          );
          break;
        /**
         * 客户抽屉操作
         */
        case "hetong":
          this.getAllContractByClientId();
          break;
        case "lianxiren":
          this.showClientContactDataByID();
          break;

        case "newContact":
          let temp = {
            line: [
              {
                horizontal: true,
                isShow: true,
                isDisable: false,
                title: "姓名",
                type: "input-but",
                value: "",
                buts: [
                  {
                    text: "公开",
                    active: "gongkai",
                  },
                  {
                    text: "删除",
                    active: "shanchu",
                  },
                ],
              },
              {
                horizontal: true,
                isShow: true,
                isDisable: false,
                title: "职位",
                type: "select",
                value: "老板",
                options: [
                  {
                    label: "老板",
                    value: "老板",
                  },
                  {
                    label: "法人",
                    value: "法人",
                  },
                  {
                    label: "股东",
                    value: "股东",
                  },
                  {
                    label: "会计",
                    value: "会计",
                  },
                  {
                    label: "其他",
                    value: "其他",
                  },
                ],
              },
              {
                horizontal: true,
                isShow: true,
                isDisable: false,
                title: "电话",
                type: "input",
                value: "",
              },
              {
                horizontal: true,
                isShow: true,
                isDisable: false,
                title: "手机",
                type: "input",
                value: "",
              },
              {
                horizontal: true,
                isShow: true,
                isDisable: false,
                title: "邮件",
                type: "input",
                value: "",
              },
              {
                horizontal: true,
                isShow: true,
                isDisable: false,
                title: "备注",
                type: "input",
                value: "",
              },
            ],
          };
          this.contractDrawerData.data.modelList.push(temp);
          break;
        case "create_contract":
          // 新建合同
          this.drawerOper("create-contract");
          break;
        case "delete_client":
          // 删除客户
          this.dialogData.title = "提示";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push({
            text: "确定",
            active: "makesure_delete_client",
          });
          this.dialogData.buttonArray.push({
            text: "取消",
            active: "cancel",
          });
          this.dialogData.data = [];
          this.dialogData.data.push({
            type: "reminder",
            text: "删除,同时删除其对应商机",
            value: this.tempClient.name,
          });
          this.dialogData.flag = true;
          break;
        case "makesure_delete_client":
          // 确定删除
          requestData = {
            clientId: this.tempClient.id,
            status: 0,
            userId: this.baseInfo.userId,
            companyId: this.baseInfo.company.companyId,
          };
          this.$request(
            "post",
            "/client/deleteClientStatus",
            requestData,
            (data) => {
              this.dialogData.flag = false;
              this.contractDrawerData.flag = false;
              this.$message({
                message: "客户删除成功,删除的客户可在客户回收站中还原!",
                duration: 1000,
                type: "success",
              });
              this.initData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "run_off":
          // 申请代账流失
          this.toApplyForCustomerChurn();
          break;
        case "confirm_that_client_loss":
          // 确定客户流失
          dd = {
            typeName: this.dialogData.data[0].value.label, // 服务类型名称
            id: this.dialogData.data[0].value.value, // 服务类型ID
          };
          requestData = {
            clientId: this.tempClient.id,
            serviceType: dd,
            lossReason: this.dialogData.data[1].value,
            userId: this.baseInfo.userId,
            companyId: this.baseInfo.company.companyId,
          };
          this.$request(
            "post",
            "/client/clientChurnByClientId",
            requestData,
            (data) => {
              this.dialogData.flag = false;
              this.contractDrawerData.flag = false;
              this.$message({
                message: "成功申请客户流失!",
                duration: 1000,
                type: "success",
              });
              this.initData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;

        case "pause_serve":
          // 暂停代账服务

          break;
        case "zhuanweifeidaizhang":
          // 转为非代账
          break;
        case "get_companyInfo":
          // 获取工商信息
          this.getCompanyInfo();
          break;
        case "genjin":
          // 添加跟进记录
          this.dialogData.title = "添加跟进记录";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push({
            text: "提交",
            active: "submit_gnjinjilu",
          });
          this.dialogData.data = [
            {
              type: "textarea",
              placeholder: "请输入跟进记录",
              value: "",
            },
            {
              type: "time",
              placeholder: "下次联系时间",
              title: "下次联系时间",
              value: "",
            },
          ];
          this.dialogData.flag = true;
          break;
        case "submit_gnjinjilu":
          // 确定添加跟进记录
          this.addFollowRecord();
          break;
        case "cancel":
          this.dialogData.flag = false;
          // this.contractDrawerData.flag = false;
          break;

        case "submit_waite_to_do":
          // 确定添加自定义事项
          console.log("确定添加自定义待办:", this.dialogData.data);
          let data = [];
          let dd = {};
          this.dialogData.data[0].value.data.forEach((element) => {
            if (element.name == "") {
              this.$message.error("步骤描述不能为空!");
              return;
            }
            if (element.time == "") {
              this.$message.error("步骤预计执行时间不能为空!");
              return;
            }
            if (element.person.length == 0) {
              this.$message.error("步骤执行人不能为空!");
              return;
            }
            dd = {
              name: "", // 任务名称
              executor: [], // 执行人id列表
              expectTime: "",
            };
            dd.name = this.dialogData.data[0].value.name + "-" + element.name;
            // dd.executor.push(this.baseInfo.userId);
            element.person.forEach((el) => {
              dd.executor.push(el);
            });
            dd.expectTime = element.time;
            data.push(dd);
          });

          requestData = {
            userId: this.baseInfo.userId, // 发起人id
            companyId: this.baseInfo.company.companyId,
            data: data,
            clientId: this.dialogData.data[0].value.clientId,
          };
          if (requestData.name == "") {
            this.$message.error("任务名称不能为空!");
            return;
          }
          this.$request(
            "post",
            "/waitDeal/addWaitDeal",
            requestData,
            (data) => {
              this.$message({
                showClose: true,
                message: "创建成功!",
                type: "success",
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.initData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;

        case "cancel_expend":
          this.dialogData.flag = false;
          this.getExpendByContractId();
          break;
        default:
          break;
      }
    },
    /**
     * 抽屉中添加tag事件
     * data(i,j):i为第几个model,j为第几line
     */
    addTags(data) {
      if (this.contractDrawerData.title == "合同") {
        this.tempTags = this.contractDrawerData.data.modelList[data.i].line[
          data.j
        ].value;
        let v = "2";
        this.tempTags.push(v);
        this.saveDrawerData();
      }
      if (this.contractDrawerData.title == "客户") {
        if (
          this.contractDrawerData.data.modelList[data.i].line[data.j].isSelect
        ) {
          let value = this.contractDrawerData.data.modelList[data.i].line[
            data.j
          ].tempValue;
          if (
            value &&
            this.contractDrawerData.data.modelList[data.i].line[
              data.j
            ].value.indexOf(value) < 0
          ) {
            // 排除重复选项
            this.contractDrawerData.data.modelList[data.i].line[
              data.j
            ].value.push(value);
            this.saveDrawerData();
          }
          this.contractDrawerData.data.modelList[data.i].line[
            data.j
          ].isSelect = false;
          this.contractDrawerData.data.modelList[data.i].line[
            data.j
          ].tempValue = "";
        } else {
          this.contractDrawerData.data.modelList[data.i].line[
            data.j
          ].isSelect = true;
        }
      }
    },
    /**
     * 抽屉中tag移除事件
     * data(tag,i,j):tag->移除的tag,i为第几个model,j为第几line
     */
    tagsHandleClose(data) {
      this.contractDrawerData.data.modelList[data.i].line[data.j].value.splice(
        this.contractDrawerData.data.modelList[data.i].line[
          data.j
        ].value.indexOf(data.tag),
        1
      );
      this.saveDrawerData();
    },
    /**
     * 右边抽屉父子组件连锁事件
     */
    showDrawer(value) {
      this.contractDrawerData = value;
    },
    /**
     * 右边抽屉关闭事件
     */
    closeUpdateTableDrawer() {
      this.contractDrawerData.flag = false;
      this.initData();
      // this.saveDrawerData();
    },
    /**
     * 合同附件父子组件连锁事件
     */
    showAttachment(data) {},

    /**
     * 改变上传位置
     */
    changeFlag(val) {},
    /**
     * 附件上传成功
     */
    handleAvatarSuccess(data) {
      // console.log("付款凭证上传成功", data);
      this.$message({
        message: "付款凭证上传成功!",
        type: "success",
        duration: 1000,
      });
      this.dialogData.data[5].value.value = data.result;
    },

    /**
     * 确认创建合同
     */
    choseProductTipOne(data) {
      // 新建合同
      if (this.dialogData.title == "选择产品") {
        let requestData = {
          userId: this.baseInfo.userId,
          companyId: this.baseInfo.company.companyId,
          productId: data.id, // 合同模板ID
          clientId: this.tempClient.id, // 客户ID
          isRenew: false,
          oldContractId: "", // 原合同ID
          expenses: "0", // 原合同费用(续签才有,可能为空)
        };
        this.$request(
          "post",
          "/contract/addContract",
          requestData,
          (data) => {
            this.dialogData.flag = false;
            this.contractDrawerData.flag = false;
            this.tempContract.id = data.contractId;
            this.$message({
              message: "合同创建成功!",
              duration: 1000,
              type: "success",
            });
            // this.initData();
            this.showContractInfoByID();
          },
          (errorMessage) => {
            this.$message.error(errorMessage);
          }
        );
      }

      // 确认下单
      if (this.dialogData.title == "选择执行流程") {
        let requestData = {
          userId: this.baseInfo.userId,
          companyId: this.baseInfo.company.companyId,
          professionId: data.id, // 合同模板ID
          contractId: this.tempContract.id, // 原合同ID
        };
        this.$request(
          "post",
          "/contract/placeOrder",
          requestData,
          (data) => {
            this.dialogData.flag = false;
            this.contractDrawerData.flag = false;
            this.$message({
              message: "操作成功!",
              duration: 1000,
              type: "success",
            });
            this.initData();
          },
          (errorMessage) => {
            this.$message.error(errorMessage);
          }
        );
      }

      // 新建支出
      if (this.dialogData.title == "新建支出") {
        let dd = {
          client: this.tempClient.label,
          contractNum: this.tempContract.name,
          contractId: this.tempContract.id,
          expendStatus: "待提交",
          expendNum: "",
          time: "",
          expendType: data.name,
          user: this.baseInfo.userId,
          title: "",
          file: "", // 附件
          services: this.tempServices,
        };
        this.$request(
          "post",
          "/contract/addExpend",
          dd,
          (data) => {
            this.$message({
              message: "支出添加成功!",
              type: "success",
              duration: 1000,
            });
            this.dialogData.flag = false;
            this.getExpendByContractId();
          },
          (errorMessage) => {
            this.$message.error(errorMessage);
          }
        );
      }
    },

    /**
     * 查看跟进记录
     */
    showGenjin(clientData) {
      this.ttempClient = clientData;
      let requestData = {
        clientId: clientData.clientId,
        threadId: "",
      };
      this.$request(
        "post",
        "/followRecord/showFollowRecord",
        requestData,
        (data) => {
          this.dialogData.title = "跟进记录-" + clientData.clientName;
          this.dialogData.buttonArray = [];
          this.dialogData.data = [
            {
              type: "genjinjilu",
              value: {
                hasContent: true,
                content: "",
                time: "",
                list: data,
                hasTarget: false,
                target: "",
                hasPhase: false,
                phase: "",
              },
            },
          ];
          this.dialogData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
  },
  mounted() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.$store.dispatch("getCompantAccountList", this);
    let accountList = JSON.parse(
      JSON.stringify(this.$store.getters.getCompantAccountList)
    );
    this.accountList = accountList;
    this.initData();
    this.getGlobalVariable();
  },
};
</script>

<style lang="less" scoped>
#waite-all {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .client_info-screen-active {
    flex: 1;
  }
  .client_info-screen-but {
    width: 100px;
    min-width: 100px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @activeColor;
    font-size: @contentFontSize;
    color: #fff;
    margin: 0 5px;
    border-radius: 4px;
    transition: 0.5s;
  }
  .client_info-screen-but:hover {
    cursor: pointer;
    background-color: @activeColor2;
  }
  .table-content {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    .waite-all_table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .waite-all-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .waite-all-table-card-tab {
          width: auto;
          min-width: 100px;
          height: 100%;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .waite-all-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .waite-all-table-card-active {
          flex: 1;
        }
        .waite-all-table-card-edit {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: @editColor;
          cursor: pointer;
        }
      }
      .waite-all-table-card-content {
        flex: 1;
        width: 98%;
      }
      .waite-all-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }

  .hri {
    display: flex;
    i {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 3px;
      font-size: 14px;
      color: @activeColor;
    }
  }
}
</style>
