import { render, staticRenderFns } from "./get_archives.vue?vue&type=template&id=1108125a&scoped=true&"
import script from "./get_archives.vue?vue&type=script&lang=js&"
export * from "./get_archives.vue?vue&type=script&lang=js&"
import style0 from "./get_archives.vue?vue&type=style&index=0&id=1108125a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1108125a",
  null
  
)

export default component.exports