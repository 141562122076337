<template>
  <div id="tax-income">
    <!-- 企业成本 -->
    <div class="tax-income-screen">
      <div class="tax-income-screen-line">
        <div class="tax-income-screen-search">
          <el-input
            v-model="searchData.input"
            placeholder="请输入客户全称"
            @keyup.enter.native="resetPage"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="resetPage"
            ></el-button>
          </el-input>
        </div>
        <div class="tax-income-screen-search">
          <el-cascader
            v-model="searchData.department"
            placeholder="请选择部门"
            :options="searchData.departTreeData"
            @change="resetPage"
            clearable
            :show-all-levels="false"
            :props="{ checkStrictly: true, value: 'id' }"
            filterable
          ></el-cascader>
        </div>
        <div class="tax-income-screen-search">
          <el-select
            v-model="searchData.userId"
            @change="selectInitTableData"
            filterable
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="tax-income-screen-search">
          <el-date-picker
            v-model="searchData.date"
            type="month"
            placeholder="选择年月"
            :clearable="false"
            value-format="yyyy-MM"
            @change="initTableData"
          >
          </el-date-picker>
        </div>
        <div class="tax-income-screen-search">
          <el-select
            v-model="searchData.companyType"
            @change="resetPage"
            filterable
            clearable
            placeholder="请选择公司类型"
          >
            <el-option label="小规模纳税人" value="小规模纳税人"></el-option>
            <el-option label="一般纳税人" value="一般纳税人"></el-option>
          </el-select>
        </div>
        <div class="tax-income-screen-active"></div>
        <el-button
          class="tax-income-screen-line-button"
          type="primary"
          plain
          size="small"
          @click="handleClick('', 0)"
          :loading="pictLoading"
          >导入</el-button
        >
      </div>
      <div class="tax-income-screen-line">
        <div class="tax-income-screen-search">
          <el-select
            v-model="searchData.chargeUpStatus"
            @change="resetPage"
            filterable
            clearable
            placeholder="请选择记账状态"
          >
            <el-option label="未完成" value="1"></el-option>
            <el-option label="已完成" value="2"></el-option>
          </el-select>
        </div>
        <div class="tax-income-screen-search">
          <el-select
            v-model="searchData.checkStatus"
            @change="resetPage"
            filterable
            clearable
            placeholder="请选择审核状态"
          >
            <el-option label="未审核" value="1"></el-option>
            <el-option label="未通过" value="2"></el-option>
            <el-option label="已通过" value="3"></el-option>
          </el-select>
        </div>
        <div class="tax-income-screen-search">
          <el-checkbox v-model="searchData.nearlyYearWarn" @change="resetPage"
            >年营业成本占比50%以上</el-checkbox
          >
          <el-checkbox
            v-model="searchData.annualOperatingCostWarn"
            @change="resetPage"
            >年营业费用占比30%以上</el-checkbox
          >
          <el-checkbox
            v-model="searchData.tentativeCostIsNull"
            @change="resetPage"
            >暂估成本未填</el-checkbox
          >
          <el-checkbox
            v-model="searchData.annualOperatingExpenseWarn"
            :true-label="4000000"
            :false-label="0"
            @change="resetPage"
            >500万预警</el-checkbox
          >
        </div>
        <div class="tax-income-screen-active"></div>
        <!-- <div class="tax-income-screen-but-boder" @click="handleClick('', 0)">
          导入
        </div> -->
      </div>
    </div>
    <div class="table-content">
      <div class="tax-income-table-card">
        <div class="tax-income-content">
          <el-table
            ref="table"
            :data="tableData"
            :header-cell-style="{ background: '#F5F7FA', color: '#606266' }"
            highlight-current-row
            style="width: 100%"
            height="98%"
            border
            v-loading="loading"
            :row-style="{ height: '60px' }"
            :cell-style="columnStyle"
            @sort-change="sortChange"
          >
            <el-table-column type="index" width="40" fixed></el-table-column>
            <el-table-column
              v-for="(item, index) in tableMenu"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              :fixed="item.fixed"
              :width="item.width"
              align="center"
              show-overflow-tooltip
              :sortable="item.sortable ? 'custom' : false"
            >
              <template slot-scope="scope">
                <el-input
                  v-if="item.type == 'input'"
                  v-model="scope.row[item.prop]"
                  :disabled="editFlag"
                  @keyup.enter.native="
                    changeItem(scope.row[item.prop], scope.row, item.prop)
                  "
                ></el-input>
                <el-select
                  v-else-if="item.type == 'select'"
                  v-model="scope.row[item.prop]"
                  :disabled="editFlag"
                  :name="scope.row[item.prop]"
                  @change="
                    changeItem(scope.row[item.prop], scope.row, item.prop)
                  "
                >
                  <el-option
                    v-for="it in item.options"
                    :key="it.value"
                    :label="it.label"
                    :value="it.value"
                  ></el-option
                ></el-select>
                <span v-else>{{ scope.row[item.prop] }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="tax-income-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-sizes="[20, 50, 100, 200]"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :clientName="dialogData.clientName"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import * as taxData from "@/views/tax/js/tax.js";
import my_dialog from "@/components/dialog.vue";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

export default {
  components: {
    my_dialog,
  },
  data() {
    return {
      pictLoading: false,
      tabIndex: 0,
      loading: false,
      baseInfo: {},
      tableData: [],
      searchData: {
        input: "",
        department: "",
        departTreeData: [],
        userId: "",
        userList: [],
        userIdList: [],
        tempUserId: [],
        date: this.$formatTime(new Date() - 31 * 24 * 3600 * 1000, "YYYY-MM"),
        companyType: "",
        nearlyYearWarn: false,
        annualOperatingCostWarn: false,
        tentativeCostIsNull: false,
        annualOperatingExpenseWarn: false,
        chargeUpStatus: "",
        checkStatus: "",
      },
      allFunctionButtons: this.$featureData.getAllFunctionButtons("taxCost"),
      tableMenu: taxData.taxIncomeTableMenu(),
      tableData: [],
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 20, // 每页条数
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "运单详情",
        buttonArray: [
          {
            text: "确认",
            active: "confirm",
          },
        ],
        data: [],
      },
      tempData: {},
      whetherAgencyHead: false,
      editFlag: true,
      value: [],
      options: [],
      defaultSort: {
        prop: "",
        order: "",
      },
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "taxCost",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.editFlag = !this.allFunctionButtons.some(
            (item) => item.key == "edit" && item.authority == "1"
          );
          this.isCharge();
          console.log("当前显示的按钮权限", data);
          console.log("this.allFunctionButtonscc", this.allFunctionButtons);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getDepartmentTreeByUserId();
      });
    },
    /**
     * 获取部门
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
          this.getEmployeeList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.searchData.userList = [];
          data.forEach((element) => {
            let dd = {
              name: element.userName,
              id: element.userId,
            };
            this.searchData.userIdList.push(element.userId);
            this.searchData.userList.push(dd);
          });
          this.searchData.userList.splice(0, 0, {
            id: "all",
            name: "全部人员",
          });
          this.searchData.userId = this.baseInfo.userId;
          this.selectInitTableData(this.baseInfo.userId);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      this.tableData = [];
      this.loading = true;
      let requestData = {
        clientName: this.searchData.input, // 客户简称
        date: this.searchData.date, // 日期
        chargeUpStatus: this.searchData.chargeUpStatus, // 记账状态
        checkStatus: this.searchData.checkStatus, // 审核状态
        companyType: this.searchData.companyType, // 公司类型
        department:
          this.searchData.department.length > 0
            ? this.searchData.department[this.searchData.department.length - 1]
            : "", // 部门 id
        userIdList: this.searchData.tempUserId, // 员工 id 集合
        nearlyYearWarn: this.searchData.nearlyYearWarn, // 年营业成本（年营业成本除以年营业收入）占比至少 50%
        annualOperatingCostWarn: this.searchData.annualOperatingCostWarn, // 年营业费用（年营业费用除以年营业收入）超过30%
        tentativeCostIsNull: this.searchData.tentativeCostIsNull, // 暂估成本未填
        annualOperatingExpenseWarn: this.searchData.annualOperatingExpenseWarn, // 近12月营业收入（超过400w）500万预警
        sortProp: this.defaultSort.prop, // 手动排序项
        sortOrder: this.defaultSort.order, // 手动排序规则 ascending descending ""
        pageSize: this.pageData.pageSize, // 每页条数
        currentPage: this.pageData.currentPage, // 当前页数
      };
      this.$request(
        "post",
        "/enterpriseCost/page",
        requestData,
        (data) => {
          this.tableData = data.records;
          this.tableData.forEach((item) => {
            item.host = item.host.join(",");
            item.consultant = item.consultant.join(",");
          });
          this.pageData.total = data.total; // 获取总条目数
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    /**
     * 界面操作
     */
    handleClick(data, flag) {
      this.tempData = data;
      let requestData = {};
      switch (flag) {
        case 0:
          // 导入
          if (this.pictLoading == true) {
            this.$message.error("正在导入资源,请耐心等待");
            return;
          }
          this.dialogData.width = "576px";
          this.dialogData.title = "导入";
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "makeSure_import_taxCost",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = taxData.importTaxCostData(this);
          this.dialogData.flag = true;
          break;
        default:
          break;
      }
    },
    /**
     * 编辑银行授权状态 和 流水状态
     */
    changeItem(value, row, prop) {
      let requestData = {
        id: row.id, // id
        year: this.searchData.year, // 年份
      };
      requestData[prop] = value;
      let flag = this.$numberReg(value);
      if (!flag) {
        this.$message.error("请填写数字再进行编辑");
        return;
      }
      this.$request(
        "post",
        "/enterpriseCost/update",
        requestData,
        (data) => {
          this.$message.success("编辑成功!");
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 抽屉数据或dialog数据操作事件
     */
    drawerOper(active, data) {
      let requestData = {};
      switch (active) {
        case "makeSure_import_taxCost":
          // 确定导入
          requestData = {
            url: this.dialogData.data[0].value.url, // 文件路径
          };
          if (requestData.url == "") {
            this.$message.error("请先选择要导入的文件!");
            return;
          }
          console.log("导入资源数据:", requestData);
          NProgress.start();
          this.pictLoading = true;
          this.$message.success("正在导入请耐心等待!");
          this.$request(
            "post",
            "/enterpriseCost/importExcel",
            requestData,
            (data) => {
              this.$operationRecords.saveOperationRecords(
                this.tempData.clientId,
                "企业成本导入!",
                this.baseInfo,
                "tax",
                this,
                (data2) => {
                  this.$message.success("导入成功!");
                  this.pictLoading = false;
                  NProgress.done();
                  this.dialogData.flag = false;
                  this.initTableData();
                }
              );
            },
            (err) => {
              this.$message({
                message: err,
                type: "error",
              });
              this.dialogData.flag = false;
              this.pictLoading = false;
              NProgress.done();
              this.initTableData();
            }
          );
          this.dialogData.flag = false;
          break;
        case "cancel":
          this.colseDialog();
          break;
        default:
          break;
      }
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    /**
     * 员工选择
     */
    selectInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.searchData.userIdList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.resetPage();
    },
    columnStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 10) {
        return "background:	#FEF0F0;";
      }
    },
    resetPage() {
      this.pageData.currentPage = 1;
      this.initTableData();
    },
    /**
     * 表格排序事件
     */
    sortChange(data) {
      const prop = this.$toLowerCase(data.prop);
      this.defaultSort.prop = data.order == null ? "" : prop;
      this.defaultSort.order = data.order == null ? "" : data.order;
      this.initTableData();
    },
    /**
     *  el-table滚动到表格某一列
     *  @param {object} tableElement  表格元素(this.$refs['表格ref值'])
     *  @param {Number} columnIndex   滚动到第几列(从0开始)
     *  @param {Number} fixedNum   表格左侧固定列个数(没有固定列,可不传)
     */
    // tableScrollToColumn(tableElement, columnIndex, fixedNum = 0) {
    //   const theTableRow = tableElement.bodyWrapper.querySelector(
    //     ".el-table__body tbody .el-table__row"
    //   );
    //   let scrollLeft = 0;
    //   for (let i = fixedNum; i < theTableRow.children.length; i++) {
    //     if (i == columnIndex) {
    //       break;
    //     }
    //     scrollLeft += theTableRow.children[i].offsetWidth;
    //   }
    //   tableElement.bodyWrapper.scrollLeft = scrollLeft;
    // },
    // 设置表格横向滚动条位置
    // setScrollLeft() {
    //   const quarterStartIndexes = [4, 13, 22, 31];
    //   const month = new Date().getMonth() + 1; // 获取月份
    //   const quarterIndex = Math.floor((month - 1) / 3); // 获月份 对应 取下标
    //   const columnIndex = quarterStartIndexes[quarterIndex];
    //   setTimeout(() => {
    //     this.tableScrollToColumn(this.$refs.table, columnIndex, 4);
    //   }, 500);
    // },
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
  mounted() {
    NProgress.configure({
      showSpinner: false,
      parent: "#tax-income .table-content",
    });
  },
};
</script>

<style lang="less" scoped>
#tax-income {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .tax-income-screen {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    .tax-income-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      > .title {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 0 10px;
      }
      > .el-tag {
        margin: 0 5px;
      }
      .tax-income-screen-fiftrate {
        width: 65px;
        min-width: 65px;
        height: 30px;
        border: 1px solid @boderColor;
        box-sizing: border-box;
        color: @boderActiveColor;
        font-size: @contentFontSize;
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: 0.3s;
        i {
          margin: 0 4px;
        }
      }
      .tax-income-screen-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }
      .tax-income-screen-search {
        width: 210px;
        min-width: 210px;
        height: 30px;
        margin-left: 10px;
        display: flex;
        align-items: center;
      }
      .tax-income-screen-active {
        flex: 1;
      }
      .drop-button {
        background-color: #ecf5ff;
        color: #409eff;
        &:hover {
          background-color: #abbed3;
        }
      }
      .tax-income-screen-but {
        width: 100px;
        min-width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .tax-income-screen-but:hover {
        cursor: pointer;
        background-color: @activeColor2;
      }
      .tax-income-screen-but-boder {
        min-width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        font-size: @contentFontSize;
        background-color: @activeBackground;
        color: @activeColor;
        margin: 0 5px;
        margin-right: 10px;
        border-radius: 4px;
        transition: 0.5s;
        padding: 0 15px;
      }
      .tax-income-screen-but-boder:hover {
        cursor: pointer;
        background-color: @activeBackground2;
      }
      .tax-income-screen-line-button {
        width: 100px !important;
        margin: 0 5px;
      }
    }
  }

  .table-content {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;

    .tax-income-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .tax-income-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .tax-income-table-card-tab {
          width: 120px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .tax-income-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
      }
      .tax-income-content {
        width: 100%;
        height: 95%;
        max-width: 90vw;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        /deep/ .el-select [name="未完成"] {
          color: #f56c6c;
        }
        /deep/ .el-select [name="已完成"] {
          color: #67c23a;
        }
        /deep/ .el-select [name="未审核"] {
          color: #f56c6c;
        }
        /deep/ .el-select [name="未通过"] {
          color: #e6a23c;
        }
        /deep/ .el-select [name="已通过"] {
          color: #67c23a;
        }
        /deep/ .red .el-input__inner {
          color: #f56c6c;
        }
        /deep/ .el-input__inner {
          border: 0;
          background-color: #fff;
        }
        /deep/ .el-input__suffix {
          display: none;
        }
        /deep/ .el-table--scrollable-y .el-table__body-wrapper {
          z-index: 1;
        }
      }
      .tax-income-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
