<template>
  <div id="invoice-model">
    <!-- 发票模板 -->
    <div class="invoice-model-top">
      <div class="top-left">
        <div class="qrCode">
          <img :src="value.qrCode" v-if="value.qrCode != ''" />
          <div v-else></div>
        </div>
      </div>
      <div class="top-center">
        <div class="top-center-title">
          {{ value.title }}
        </div>
      </div>
      <div class="top-right">
        <div class="top-right-line">
          <div class="top-right-title">发票号码:</div>
          <div class="top-right-value">{{ value.invoiceNumber }}</div>
        </div>
        <div class="top-right-line">
          <div class="top-right-title">开票日期:</div>
          <div class="top-right-value">{{ value.invoiceDate }}</div>
        </div>
      </div>
    </div>
    <div class="invoice-model-table">
      <div class="invoice-model-table-line1">
        <div class="line1-cell1">购买方</div>
        <div class="line1-cell2">
          <div class="line1-cell2-line">
            <div class="line1-cell2-line-title">
              <pre>名        称:</pre>
            </div>
            <div class="line1-cell2-line-value color">
              {{ value.buyerName }}
            </div>
          </div>
          <div class="line1-cell2-line">
            <div class="line1-cell2-line-title">
              统一社会信用代码/纳税人识别号:
            </div>
            <div class="line1-cell2-line-value color">
              {{ value.buyerTaxpayerNum }}
            </div>
          </div>
        </div>
        <div class="line1-cell1">销售方</div>
        <div class="line1-cell2">
          <div class="line1-cell2-line">
            <div class="line1-cell2-line-title">
              <pre>名        称:</pre>
            </div>
            <div class="line1-cell2-line-value">{{ value.sellerName }}</div>
          </div>
          <div class="line1-cell2-line">
            <div class="line1-cell2-line-title">
              统一社会信用代码/纳税人识别号:
            </div>
            <div class="line1-cell2-line-value">
              {{ value.sellerTaxpayerNum }}
            </div>
          </div>
        </div>
      </div>
      <!-- 建筑行业 -->
      <div
        class="invoice-model-table-line2"
        v-if="value.specialTicketCode == '03'"
      >
        <div class="table-line2-line">
          <div class="table-line2-cell1 is-title">货物或应税务、服务名称</div>
          <div class="table-line2-cell1 is-title">建筑服务发生地</div>
          <div class="table-line2-cell1 is-title">建筑项目名称</div>
          <div class="table-line2-cell5 is-title">金 额</div>
          <div class="table-line2-cell3 is-title">税率</div>
          <div class="table-line2-cell5 is-title">税 额</div>
        </div>
        <div
          class="table-line2-line"
          v-for="(item, index) in value.itemList"
          :key="index"
        >
          <div class="table-line2-cell1 color">
            *{{ item.goodsType }}*{{ item.goodsName }}*
          </div>
          <div class="table-line2-cell1 color">
            {{ value.specialField.fwfsd }}
          </div>
          <div class="table-line2-cell1 color">
            {{ value.specialField.jzxmmc }}
          </div>
          <div class="table-line2-cell5 color">{{ item.invoiceAmount }}</div>
          <div class="table-line2-cell3 color">
            {{
              item.taxRateValue == 0 ? "免税" : item.taxRateValue * 100 + "%"
            }}
          </div>
          <div class="table-line2-cell5 color">{{ item.taxRateAmount }}</div>
        </div>

        <div class="table-line2-line is-content">
          <!-- 用来占位 -->
          <div class="table-line2-cell1 is-title"></div>
          <div class="table-line2-cell1 is-title"></div>
          <div class="table-line2-cell1 is-title"></div>
          <div class="table-line2-cell5 is-bottom">
            ¥{{ value.allMoney || 0 }}
          </div>
          <div class="table-line2-cell3 is-title"></div>
          <div class="table-line2-cell5 is-bottom">
            ¥{{ value.allTax || 0 }}
          </div>
        </div>
      </div>
      <div class="invoice-model-table-line2" v-else>
        <div class="table-line2-line">
          <div class="table-line2-cell1 is-title">货物或应税务、服务名称</div>
          <div class="table-line2-cell2 is-title">规格型号</div>
          <div class="table-line2-cell3 is-title">单位</div>
          <div class="table-line2-cell4 is-title">数 量</div>
          <div class="table-line2-cell4 is-title">单 价</div>
          <div class="table-line2-cell5 is-title">金 额</div>
          <div class="table-line2-cell3 is-title">税率</div>
          <div class="table-line2-cell5 is-title">税 额</div>
        </div>
        <div
          class="table-line2-line"
          v-for="(item, index) in value.itemList"
          :key="index"
        >
          <div class="table-line2-cell1 color">
            *{{ item.goodsType }}*{{ item.goodsName }}*
          </div>
          <div class="table-line2-cell2 color">
            {{ item.specificationModel }}
          </div>
          <div class="table-line2-cell3 color">{{ item.meteringUnit }}</div>
          <div class="table-line2-cell4 color">{{ item.quantity }}</div>
          <div class="table-line2-cell4 color">{{ item.unitPrice }}</div>
          <div class="table-line2-cell5 color">{{ item.invoiceAmount }}</div>
          <div class="table-line2-cell3 color">
            {{
              item.taxRateValue == 0 ? "免税" : item.taxRateValue * 100 + "%"
            }}
          </div>
          <div class="table-line2-cell5 color">{{ item.taxRateAmount }}</div>
        </div>

        <div class="table-line2-line is-content">
          <!-- 用来占位 -->
          <div class="table-line2-cell1 is-title"></div>
          <div class="table-line2-cell2 is-title"></div>
          <div class="table-line2-cell3 is-title"></div>
          <div class="table-line2-cell4 is-title"></div>
          <div class="table-line2-cell4 is-title"></div>
          <div class="table-line2-cell5 is-bottom">
            ¥{{ value.allMoney || 0 }}
          </div>
          <div class="table-line2-cell3 is-title"></div>
          <div class="table-line2-cell5 is-bottom">
            ¥{{ value.allTax || 0 }}
          </div>
        </div>
      </div>
      <!-- 货物运输服务 -->
      <div
        class="invoice-model-table-line5"
        v-if="value.specialTicketCode == '04'"
      >
        <div class="table-line2-line">
          <div class="table-line2-cell4 is-title">运输工具种类</div>
          <div class="table-line2-cell2 is-title">运输工具牌号</div>
          <div class="table-line2-cell2 is-title">起运地</div>
          <div class="table-line2-cell2 is-title">到达地</div>
          <div class="table-line2-cell1 is-title">运输货物名称</div>
        </div>
        <div
          class="table-line2-line"
          v-for="(item, index) in value.specialField"
          :key="index"
        >
          <div class="table-line2-cell4">
            {{ item.ysgjzl | getTransportation }}
          </div>
          <div class="table-line2-cell2">{{ item.ysgjhp }}</div>
          <div class="table-line2-cell2">{{ item.qyd }}</div>
          <div class="table-line2-cell2">{{ item.ddd }}</div>
          <div class="table-line2-cell1">{{ item.yshwmc }}</div>
        </div>
      </div>
      <div class="invoice-model-table-line3">
        <div class="table-line3-cell1">价税合计(大写)</div>
        <div class="table-line3-cell2">
          <div class="table-line3-cell2-cell ">
            {{ value.totalAmountText }}
          </div>
          <div class="table-line3-cell2-cell">
            <span>(小写)</span>{{ value.totalAmount }}
          </div>
        </div>
      </div>
      <div class="invoice-model-table-line4" style="line-height: 1.5">
        <div class="line1-cell1">内容备注</div>
        <div class="line1-cell3" style="text-align: left;">
          <div v-if="value.showBuyerBankFlag == 'Y'">
            <span class="title">购买方开户银行:</span>
            {{ value.buyerBankName }}
            <span class="title">银行账号:</span>
            {{ value.buyerBankAccount }}
          </div>
          <div v-if="value.showBuyerAddressFlag == 'Y'">
            <span class="title">购买方地址:</span>
            {{ value.buyerAddress }}
            <span class="title">电话:</span>
            {{ value.buyerTel }}
          </div>
          <div v-if="value.showSellerBankFlag == 'Y'">
            <span class="title">销售方开户银行:</span>
            {{ value.sellerBankName }}
            <span class="title">银行账号:</span>
            {{ value.sellerBankAccount }}
          </div>
          <div v-if="value.showSellerAddressFlag == 'Y'">
            <span class="title">销售方地址:</span>
            {{ value.sellerAddress }}
            <span class="title">电话:</span>
            {{ value.sellerTel }}
          </div>

          {{ value.remark }}
        </div>
      </div>
    </div>
    <div class="invoice-model-below">
      <div class="below-cell">
        <div class="below-cell-title">收款人:</div>
        <div class="below-cell-value">
          {{ value.casherName }}
        </div>
      </div>
      <div class="below-cell">
        <div class="below-cell-title">复核人:</div>
        <div class="below-cell-value">
          {{ value.reviewerName }}
        </div>
      </div>
      <div class="below-cell">
        <div class="below-cell-title">开票人:</div>
        <div class="below-cell-value">
          {{ value.drawerName }}
        </div>
      </div>
      <div class="below-cell">
        <div class="below-cell-title">销售方:(章)</div>
        <div class="below-cell-value"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as invoiceData from "@/assets/js/invoiceData.js";
export default {
  props: ["invoiceData"],
  data() {
    return {
      value: this.invoiceData,
      // value: {
      //   title: "安徽增值税电子普通发票", // 发票标题
      //   clientName: "安徽XXX有限公司", // 客户名称  销售方名称
      //   clientTaxpayerNum: "423454341235547668", // 客户税号  销售方纳税人识别号
      //   qrCode: require("../assets/img/qrCode.png"), // 二维码 电子发票才有  开票成功后才有
      //   securityCode: "70243 34521 65890", // 防伪码(校验码)  开票成功后才有 普票使用  增值税发票为空
      //   invoiceCode: "04003200191", // 发票代码  开票成功后才有
      //   invoiceNo: "12980987", // 发票号码  开票成功后才有
      //   invoiceDate: "2021年6月29日", // 开票日期  开票成功后才有
      //   buyerName: "XXXXXXX有限公司", // 购买方名称
      //   buyerTaxpayerNum: "", // 购买方纳税人识别号
      //   buyerAddressTel: "", // 购买方地址、电话
      //   // buyerTel: "", // 购买方电话
      //   buyerBankNameAccount: "", // 购买方开户行、银行账号
      //   // buyerBankAccount: "", // 购买方银行账号

      //   sellerAddressTel: "销售方地址、电话", //销售方地址、电话   客户公司地址
      //   // sellerTel: "", //销售方电话
      //   sellerBankNameAccount: "销售方开户行、银行账号", //销售方开户行、银行账号
      //   // sellerBank: "", //销售方银行账号

      //   itemName: "", //主开票项目名称 可默认为开票项目列表的第一个项目名称
      //   casherName: "收款人名称", //收款人名称
      //   reviewerName: "复核人名称", //复核人名称
      //   drawerName: "开票人名称", //开票人名称
      //   itemList: [
      //     // 开票项目列表
      //     {
      //       goodsName: "服务费", // 项目名称  等价于itemName
      //       taxClassificationCode: "", // 对应税收分类编码
      //       specificationModel: "", // 对应规格型号
      //       meteringUnit: "", // 单位
      //       quantity: "1", // 数量
      //       includeTaxFlag: "", // 含税标示  0：不含税，1：含税，默认为 0 不含税
      //       unitPrice: "7500.00", // 单价
      //       invoiceAmount: "7500.00", // 金额
      //       taxRateValue: "*", // 税率
      //       taxRateAmount: "*", // 税额

      //       discountAmount: "", // 折扣金额
      //       discountTaxRateAmount: "", // 折扣税额
      //       deductionAmount: "", // 差额开票抵扣金额
      //       preferentialPolicyFlag: "", // 优惠政策标识
      //       zeroTaxFlag: "", // 零税率标识
      //       vatSpecialManage: "", // 增值税特殊管理
      //     },
      //     {
      //       goodsName: "税务费", // 项目名称  等价于itemName
      //       taxClassificationCode: "", // 对应税收分类编码
      //       specificationModel: "", // 对应规格型号
      //       meteringUnit: "", // 单位
      //       quantity: "1", // 数量
      //       includeTaxFlag: "", // 含税标示  0：不含税，1：含税，默认为 0 不含税
      //       unitPrice: "7500.00", // 单价
      //       invoiceAmount: "7500.00", // 金额
      //       taxRateValue: "*", // 税率
      //       taxRateAmount: "*", // 税额

      //       discountAmount: "", // 折扣金额
      //       discountTaxRateAmount: "", // 折扣税额
      //       deductionAmount: "", // 差额开票抵扣金额
      //       preferentialPolicyFlag: "", // 优惠政策标识
      //       zeroTaxFlag: "", // 零税率标识
      //       vatSpecialManage: "", // 增值税特殊管理
      //     },
      //   ],
      //   remark: "备注备注", // 备注
      //   machineCode: "61616161661616", // 机器编号   机柜编号?
      // },
    };
  },
  watch: {
    invoiceData() {
      //父组件值变化->>子组件也变化
      this.value = this.invoiceData;
    },
    value(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  filters: {
    getTransportation(ysgjzl) {
      return invoiceData.getTransportation(ysgjzl);
    },
  },
  created() {
    console.log("value", this.value);
  },
};
</script>

<style lang="less" scoped>
#invoice-model {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  background: url("../assets/img/shuiyin.png");
  .invoice-model-top {
    display: flex;
    width: 100%;
    height: 0;
    flex: 2;
    .top-left,
    .top-right {
      flex: 2;
      height: 100%;
      width: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 18px;
      font-family: "楷体";
      .top-right-line {
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        .top-right-title {
          width: 100px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-right: 5px;
          color: @invoiceColor;
        }
        .top-right-value {
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          width: 0;
        }
      }
      .qrCode {
        width: 110px;
        height: 110px;
        margin-left: 40px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .machineCode {
        width: 100%;
        height: 0;
        flex: 1;
        display: flex;
        align-items: center;
        .machineCode-title {
          width: auto;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-right: 5px;
          box-sizing: border-box;
          color: @invoiceColor;
        }
        .machineCode-value {
          flex: 1;
          width: 0;
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
    }
    .top-center {
      flex: 3;
      height: 100%;
      width: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .top-center-title {
        width: auto;
        height: auto;
        font-size: 36px;
        color: @invoiceColor;
        font-family: "楷体";
        font-weight: 550;
        border-bottom: 4px double @invoiceColor;
      }
    }
  }
  .invoice-model-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 0;
    flex: 9;
    border: 1px solid @invoiceColor;
    box-sizing: border-box;
    font-size: 18px;
    font-family: "楷体";
    .invoice-model-table-line1,
    .invoice-model-table-line4 {
      width: 100%;
      height: 132px;
      flex: 4;
      box-sizing: border-box;
      display: flex;
      .line1-cell1 {
        width: 35px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: @invoiceColor;
        border-right: 1px solid @invoiceColor;
        letter-spacing: 5px;
        writing-mode: vertical-lr;
        -webkit-writing-mode: vertical-rl;
      }
      .line1-cell2 {
        flex: 6;
        border-right: 1px solid @invoiceColor;
        display: flex;
        flex-direction: column;
        .line1-cell2-line {
          flex: 1;
          width: 100%;
          height: 0;
          display: flex;
          align-items: center;
          font-size: 16px;
          font-family: "楷体" !important;
          .line1-cell2-line-title {
            min-width: 120px;
            height: 100%;
            display: flex;
            align-items: center;
            margin: 0 10px;
            color: @invoiceColor;
            pre {
              font-family: "楷体" !important;
            }
          }
          .line1-cell2-line-value {
            flex: 1;
            height: 100%;
            display: flex;
            align-items: center;
            width: 0;
            text-align: left;
          }
        }
      }
      .line1-cell3 {
        box-sizing: border-box;
        padding-left: 5px;
        white-space: pre-line;
      }
      .title {
        color: @invoiceColor;
      }
    }
    .invoice-model-table-line1 {
      height: 90px;
    }
    .invoice-model-table-line2 {
      width: 100%;
      height: 0;
      flex: 7;
      border-top: 1px solid @invoiceColor;
      border-bottom: 1px solid @invoiceColor;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      .table-line2-line {
        width: 100%;
        height: 30px;
        display: flex;
        font-family: "楷体";
        .color {
          color: #ff0000;
        }
        .table-line2-cell1,
        .table-line2-cell2,
        .table-line2-cell3,
        .table-line2-cell4,
        .table-line2-cell5 {
          width: 0;
          height: 100%;
          font-size: 15px;
          box-sizing: border-box;
        }
        .table-line2-cell1 {
          flex: 12;
        }
        .table-line2-cell2 {
          flex: 4;
          // border-left: 1px solid @invoiceColor;
        }
        .table-line2-cell3 {
          flex: 3;
          // border-left: 1px solid @invoiceColor;
        }
        .table-line2-cell4 {
          flex: 6;
          // border-left: 1px solid @invoiceColor;
        }
        .table-line2-cell5 {
          flex: 7;
          // border-left: 1px solid @invoiceColor;
          .table-line2-cell5-two {
            display: flex;
          }
        }
      }
      .is-title {
        display: flex;
        align-items: center;
        justify-content: center;
        color: @invoiceColor;
      }
      .is-bottom {
        color: #ff0000;
        display: flex;
        justify-content: center;
        align-items: end;
      }
      .is-content {
        flex: 1;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
    .invoice-model-table-line3 {
      width: 100%;
      height: 0;
      flex: 1;
      border-bottom: 1px solid @invoiceColor;
      box-sizing: border-box;
      display: flex;
      font-family: "楷体";
      font-size: 15px;
      .table-line3-cell1 {
        flex: 1;
        width: 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: @invoiceColor;
      }
      .table-line3-cell2 {
        flex: 3.02;
        width: 0;
        height: 100%;
        display: flex;
        align-items: center;
        border-left: 1px solid @invoiceColor;
        .table-line3-cell2-cell {
          color: #ff0000;
          flex: 1;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-left: 5px;
          box-sizing: border-box;
          span {
            color: @invoiceColor;
          }
        }
      }
    }
    .invoice-model-table-line5 {
      width: 100%;
      height: 0;
      flex: 6;
      border-bottom: 1px solid @invoiceColor;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      .table-line2-line {
        width: 100%;
        height: 30px;
        display: flex;
        font-family: "楷体";
        .color {
          color: #ff0000;
        }
        .table-line2-cell1,
        .table-line2-cell2,
        .table-line2-cell3,
        .table-line2-cell4,
        .table-line2-cell5 {
          width: 0;
          height: 100%;
          font-size: 15px;
          box-sizing: border-box;
        }
        .table-line2-cell1 {
          flex: 12;
        }
        .table-line2-cell2 {
          flex: 4;
          // border-left: 1px solid @invoiceColor;
        }
        .table-line2-cell3 {
          flex: 3;
          // border-left: 1px solid @invoiceColor;
        }
        .table-line2-cell4 {
          flex: 6;
          // border-left: 1px solid @invoiceColor;
        }
        .table-line2-cell5 {
          flex: 7;
          // border-left: 1px solid @invoiceColor;
          .table-line2-cell5-two {
            display: flex;
          }
        }
      }
      .is-title {
        display: flex;
        align-items: center;
        justify-content: center;
        color: @invoiceColor;
        border-bottom: 1px solid @invoiceColor;
      }
      .is-bottom {
        color: #ff0000;
        display: flex;
        justify-content: center;
        align-items: end;
      }
      .is-content {
        flex: 1;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
  }
  .invoice-model-below {
    display: flex;
    width: 100%;
    height: 0;
    flex: 1;
    align-items: center;
    .below-cell {
      flex: 1;
      width: 0;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-family: "楷体";
      .below-cell-title {
        width: 100px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 5px;
        color: @invoiceColor;
      }
      .below-cell-value {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        width: 0;
      }
    }
  }
}
</style>
