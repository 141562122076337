<template>
  <div id="show-reimbursemen-item" class="customScrollBar">
    <!-- 报销详情显示 -->
    <div class="box">
      <el-divider content-position="left">报销信息</el-divider>
    </div>
    <div class="top">
      <div class="is">
        <div class="ist">
          <span style="width: 15px; color: red">*</span><span>报销类型:</span>
        </div>
        <div class="isv">
          {{ data.type }}
        </div>
      </div>
      <div class="is">
        <div class="ist">
          <span style="width: 15px; color: red">*</span><span>报销状态:</span>
        </div>
        <div class="isv">
          {{ data.status }}
        </div>
      </div>
    </div>
    <div class="content">
      <div class="item" v-for="(item, index) in data.detail" :key="index">
        <div class="line">
          <div class="money">
            <div class="title">
              <span style="width: 15px; color: red">*</span>明细金额:
            </div>
            <div class="value">
              {{ item.amount }}<span style="margin: 0 2px">元</span>
            </div>
            <div class="moneyText">
              (大写:<span>{{ item.amountText }}</span
              >)
            </div>
          </div>
        </div>
        <div class="line">
          <div class="tie">
            <span style="width: 15px; color: red">*</span>发生时间
          </div>
          <div class="vas">
            {{ item.occurTime }}
          </div>
        </div>
        <div class="line">
          <div class="tie">
            <span style="width: 15px; color: red"></span>支付方式:
          </div>
          <div class="vas">
            {{ item.payWay }}
          </div>
          <div class="tie">
            <span style="width: 15px; color: red"></span>报销说明:
          </div>
          <div class="vas">
            {{ item.remark }}
          </div>
        </div>
        <div class="line">
          <div class="tie">
            <span style="width: 15px; color: red"></span>报销附件:
          </div>
          <div class="icons">
            <div
              class="icon"
              v-for="(it, ii) in item.appendix"
              :key="ii"
              v-viewer="{ movable: false }"
            >
              <img :src="it" style="width: 100%; height: 100%" />
            </div>
          </div>
        </div>
        <!-- <div class="line" v-if="item.expenditureId">
          <div class="tie">
            <span style="width: 15px;"></span
            ><span
              style="color: #40a3ff; cursor: pointer;"
              @click="expendDetails(item.expenditureId)"
              >查看详情</span
            >
          </div>
          <div class="vas"></div>
        </div> -->
      </div>
    </div>
    <template v-if="data.detail.length > 0">
      <div class="collapse" v-if="data.detail[0].expenditureId">
        <div class="collapse-content">
          <el-divider content-position="left">付款信息</el-divider>
          <div class="collapse-content-line">
            <div class="collapse-content-item">
              <div class="collapse-content-item-title">客户名称:</div>
              <div
                class="collapse-content-item-value"
                style="color: #409EFF; cursor: pointer;"
                @click="routerToContract"
              >
                {{ payData.clientName }}
              </div>
            </div>
            <div class="collapse-content-item">
              <div class="collapse-content-item-title">合同编号:</div>
              <div
                class="collapse-content-item-value"
                style="color: #409EFF; cursor: pointer;"
                @click="routerToContract"
              >
                {{ payData.contractNumber }}
              </div>
            </div>
          </div>
          <div class="collapse-content-line">
            <div class="collapse-content-item">
              <div class="collapse-content-item-title">收款单号:</div>
              <div class="collapse-content-item-value">
                {{ payData.receiptNumber }}
              </div>
            </div>
            <div class="collapse-content-item">
              <div class="collapse-content-item-title">付款方式:</div>
              <div class="collapse-content-item-value">
                {{ payData.paymentMethod }}
              </div>
            </div>
          </div>
          <div class="collapse-content-line">
            <div class="collapse-content-item">
              <div class="collapse-content-item-title">付款金额:</div>
              <div class="collapse-content-item-value" style="display: flex;">
                <div style="margin-right: 5px">{{ payData.paymentAmount }}</div>
                <div class="moneyText">
                  (大写:<span> {{ payData.paymentAmountText }} </span>)
                </div>
              </div>
            </div>
            <div class="collapse-content-item">
              <div class="collapse-content-item-title">付款日期:</div>
              <div class="collapse-content-item-value">
                {{ payData.paymentDate }}
              </div>
            </div>
          </div>
          <el-divider content-position="left">付款账号</el-divider>
          <div class="collapse-content-account">
            <div class="collapse-content-account-item">
              <div class="collapse-content-account-item-line">
                <div class="collapse-content-account-item-line-title">
                  收款人:
                </div>
                <div class="collapse-content-account-item-line-value">
                  {{ payData.payeeName }}
                </div>
              </div>
              <div class="collapse-content-account-item-line">
                <div class="collapse-content-account-item-line-title">
                  银行账号:
                </div>
                <div class="collapse-content-account-item-line-value">
                  {{ payData.bankAccount }}
                </div>
              </div>
              <div class="collapse-content-account-item-line">
                <div class="collapse-content-account-item-line-title">
                  开户行:
                </div>
                <div class="collapse-content-account-item-line-value">
                  {{ payData.bankOfDeposit }}
                </div>
              </div>
            </div>
            <div class="line"></div>
            <div
              class="collapse-content-account-item"
              style=" margin-left: 10px;"
            >
              <div class="collapse-content-account-item-line">
                <div class="collapse-content-account-item-line-title">
                  付款人:
                </div>
                <div class="collapse-content-account-item-line-value">
                  <span v-if="data.status == '审批中' && data.editFlag">
                    <el-select
                      v-model="payData.payer"
                      placeholder="请选择"
                      @change="payerChange"
                      :popper-append-to-body="false"
                    >
                      <el-option
                        v-for="item in payOptions"
                        :key="item.name"
                        :label="item.name"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </span>
                  <span v-else>{{ payData.payerOwner }}</span>
                </div>
              </div>
              <div class="collapse-content-account-item-line">
                <div class="collapse-content-account-item-line-title">
                  附件:
                </div>
                <div
                  class="collapse-content-account-item-line-value"
                  @click="showReceivableSheet(payData.attachments)"
                  style="color: #409EFF; cursor: pointer;"
                >
                  查看支出附件
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="collapse-content-line">
            <div class="collapse-content-item">
              <div class="collapse-content-item-title">收款人:</div>
              <div class="collapse-content-item-value">
                {{ payData.payeeName }}
              </div>
            </div>
            <div class="collapse-content-item">
              <div class="collapse-content-item-title">付款人:</div>
              <div class="collapse-content-item-value"></div>
            </div>
          </div>
          <div class="collapse-content-line">
            <div class="collapse-content-item">
              <div class="collapse-content-item-title">银行账号:</div>
              <div class="collapse-content-item-value">
                {{ payData.bankAccount }}
              </div>
            </div>
            <div class="collapse-content-item">
              <div class="collapse-content-item-title">附件:</div>
              <div
                class="collapse-content-item-value"
                @click="showReceivableSheet(payData.attachments)"
                style="color: #409EFF; cursor: pointer;"
              >
                查看支出附件
              </div>
            </div>
          </div>
          <div class="collapse-content-line">
            <div class="collapse-content-item">
              <div class="collapse-content-item-title">开户行:</div>
              <div class="collapse-content-item-value">
                {{ payData.bankOfDeposit }}
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </template>

    <div class="biot">
      <div class="tie">备注:</div>
      <div class="vas">
        {{ data.des }}
      </div>
    </div>
    <div class="flow">
      <div class="tie">审批流程:</div>
      <div class="vas">
        <div class="line" v-for="(item, index) in data.flow" :key="index">
          <div class="sta">
            <div
              :class="{
                ic: true,
                blu: item.status == '已同意',
                re: item.status == '已驳回',
              }"
            >
              <i class="el-icon-s-check"></i>
            </div>
            <span style="font-size: 12px">{{ item.role }}</span>
            <span>{{ item.status }}</span>
          </div>
          <div class="val">
            <div
              class="users"
              :style="{
                borderColor:
                  item.status == '已同意'
                    ? '#71a9f3'
                    : item.status == '已驳回'
                    ? '#ff784f'
                    : '#b4b4b4',
                borderWidth: index == data.flow.length - 1 ? '0px' : '2px',
              }"
            >
              <div class="user" v-for="(it, ii) in item.user" :key="ii">
                <div class="icon">
                  <img
                    :src="it.icon"
                    style="width: 100%; height: 100%"
                    alt=""
                  />
                  <!-- <img
                    src="https://crm.hfjunling.com/图像/20220723/12-04-41_947d8e206c7ba6cbc8309100db9692b_1658549081390.jpg"
                    alt=""
                    style="width: 100%; height: 100%"
                  /> -->
                  <div
                    class="tip"
                    v-if="item.status == '已同意' || item.status == '已付款'"
                  >
                    <i class="el-icon-check"></i>
                  </div>
                  <div
                    class="tip"
                    v-if="item.status == '已驳回'"
                    style="background-color: #ff784f"
                  >
                    <i class="el-icon-close"></i>
                  </div>
                </div>
                <div class="name">{{ it.name }}</div>
                <div
                  class="time"
                  v-if="
                    item.status == '已同意' ||
                      item.status == '已付款' ||
                      item.status == '已驳回'
                  "
                >
                  <span>{{ item.status }}</span>
                  <span>{{ item.time }}</span>
                </div>
              </div>
              <div class="des" v-if="item.des != ''">
                <span>备注:</span>
                {{ item.des }}
              </div>
              <div class="file" v-if="item.file.length > 0">
                <span>附件:</span>
                <div class="val" v-viewer="{ movable: false }">
                  <img :src="it" v-for="(it, ii) in item.file" :key="ii" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @buttonOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
// import my_dialog from "@/components/dialog.vue";
export default {
  components: {
    my_dialog: () => import("@/components/dialog.vue"),
  },
  props: {
    reimbursemenDetailData: {
      type: Object,
      default: function() {
        return {
          type: "", // 报销类型
          amountAll: "", // 总金额
          status: "", // 报销状态
          modeName: "报销流程",
          submitUser: this.baseInfo.userId,
          des: "",
          detail: [
            // {
            //   amount: "", // 明细金额（元）
            //   payWay: "", // 付款方式
            //   amountText: "",
            //   occurTime: "", // 发生时间
            //   remark: "", // 报销说明
            //   appendix: [""], // 附件
            //   uploadParameter: {
            //     systemName: "finance",
            //     title: "报销附件",
            //   },
            // },
          ],
          flow: [
            // {
            // status: "", // 当前这一步的状态  已同意  审批中  待付款  已驳回
            // time: "", // 当前这个流程的审核或付款时间
            // des: "", // 审批意见
            // file: [], // 审批附件
            // user: [],
            // },
          ],
          editFlag: false,
        };
      },
    },
  },
  data() {
    return {
      baseInfo: {},
      typeOptions: [], // 报销类型
      payOptions: [], // 付款方式
      data: this.reimbursemenDetailData,
      flow: [
        {
          status: "已同意", // 当前这一步的状态  已同意  审批中  待付款  已驳回
          time: "2022-11-11 15:23:22", // 当前这个流程的审核或付款时间
          des: "dadasfd", // 审批意见
          file: [
            "https://crm.hfjunling.com/20210907/16-49-59_company_logo_1631004599291.png",
            "https://crm.hfjunling.com/20210907/16-49-59_company_logo_1631004599291.png",
          ], // 审批附件
          user: [{ name: "张爽", icon: this.$baseimgurl }],
        },
        {
          status: "已同意", // 当前这一步的状态  已同意  审批中  待付款  已驳回
          time: "2022-11-11 15:23:22", // 当前这个流程的审核或付款时间
          des: "dadasfd", // 审批意见
          file: [], // 审批附件
          user: [{ name: "张爽", icon: this.$baseimgurl }],
        },
        {
          status: "已同意", // 当前这一步的状态  已同意  审批中  待付款  已驳回
          time: "2022-11-11 15:23:22", // 当前这个流程的审核或付款时间
          des: "", // 审批意见
          file: [], // 审批附件
          user: [{ name: "张爽", icon: this.$baseimgurl }],
        },
        {
          status: "已同意", // 当前这一步的状态  已同意  审批中  待付款  已驳回
          time: "2022-11-11 15:23:22", // 当前这个流程的审核或付款时间
          des: "", // 审批意见
          file: [], // 审批附件
          user: [{ name: "张爽", icon: this.$baseimgurl }],
        },
        {
          status: "审批中", // 当前这一步的状态  已同意  审批中  待付款  已驳回
          time: "", // 当前这个流程的审核或付款时间
          des: "", // 审批意见
          file: [], // 审批附件
          user: [
            { name: "张爽", icon: this.$baseimgurl },
            { name: "刘烨", icon: this.$baseimgurl },
          ],
        },
      ],
      payData: {},
      dialogData: {
        flag: false,
        width: "45%",
        title: "新建报销",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },
      payOptions: [],
    };
  },
  methods: {
    getDetail() {
      this.data.detail.forEach((element, index) => {
        this.changeAmount(element, index);
      });
      this.$forceUpdate();
    },
    // 获取当前所需标签
    getTagList() {
      let requestData = {
        type: ["报销类型", "付款方式"],
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/getTagList",
        requestData,
        (data) => {
          console.log("当前获取到的标签组:", data);
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            if (element.type == "付款方式") {
              this.payOptions = element.value;
            }
          }
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    // 校验金额,并转大写
    changeAmount(item, index) {
      console.log("金额校验", item);
      if (!this.$formatMathDecimal(item.amount)) {
        this.data.detail[index].amount = "";
        this.data.detail[index].amountText = "";
        this.$message({
          message: "金额只能为数字!",
          type: "error",
        });
      } else {
        this.data.detail[index].amountText = this.$money.required(item.amount);
      }
    },
    /**
     * 查看回款单
     */
    showReceivableSheet(data) {
      if (data == "") {
        this.$message({
          message: "暂无支出附件!!",
          type: "error",
          duration: 1000,
        });
        return;
      }
      this.dialogData.title = "查看支出附件";
      this.dialogData.width = "30%";
      this.dialogData.buttonArray = [
        {
          text: "取消",
          active: "cancel",
        },
      ];

      this.dialogData.data = [
        {
          title: "附件",
          type: "show_imgs",
          value: data,
        },
      ];
      this.dialogData.flag = true;
    },
    getExpendDetail() {
      this.$request(
        "get",
        "/contractExpenditure/getInfoById?id=" +
          this.data.detail[0].expenditureId,
        {},
        (data) => {
          data.attachments = data.attachments.split(",");
          data.paymentAmountText = this.$money.required(data.paymentAmount);
          this.payData = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取付款方式
     */
    payerChange(e) {
      this.$emit("payerChange", e);
    },
    drawerOper(active) {
      switch (active) {
        case "cancel":
          this.colseDialog();
          break;
        default:
          break;
      }
    },
    /**
     * 跳转至合同界面
     */
    routerToContract() {
      this.$router.push({
        path: "/main/contract/contract-info",
        query: { contractNumber: this.payData.clientName },
      });
    },
    colseDialog() {
      this.dialogData.flag = false;
    },
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
  },
  mounted() {
    this.getDetail();
    if (this.data.detail.length > 0) {
      if (this.data.detail[0].expenditureId) {
        this.getExpendDetail();
        this.getTagList();
      }
    }
  },
};
</script>

<style lang="less" scoped>
#show-reimbursemen-item {
  width: 100%;
  height: 100%;
  max-height: 750px;
  overflow-y: auto;
  overflow-x: hidden;

  > .box {
    padding: 0 15px;
    box-sizing: border-box;
  }
  > .top {
    width: 100%;
    height: 45px;
    min-height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > .is {
      width: 0;
      flex: 1;
      height: 100%;

      display: flex;
      > .ist {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        margin-right: 5px;
      }
      > .isv {
        width: 0;
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 14px;
      }
    }
  }
  > .content {
    width: 100%;
    height: auto;
    max-height: 650px;
    display: flex;
    flex-direction: column;
    align-items: center;
    > .item {
      width: 100%;
      height: auto;
      // border-bottom: 1px solid #a3a3a3;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      padding-bottom: 5px;
      > .line {
        width: 100%;
        height: auto;
        min-height: 30px;
        display: flex;
        margin-top: 8px;
        > .money {
          width: 0;
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          > .title {
            width: auto;
            height: 100%;
            margin-right: 5px;
            display: flex;
            align-items: center;
            font-size: 14px;
          }
          > .value {
            width: auto;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 14px;
            margin-right: 5px;
          }
        }
        > .time {
          width: 40%;
          height: 100%;
          display: flex;
          align-items: center;
          > .title {
            width: auto;
            height: 100%;
            margin-right: 5px;
            display: flex;
            align-items: center;
            font-size: 14px;
          }
        }
        > .tie {
          width: auto;
          height: 100%;
          display: flex;
          align-items: flex-start;
          font-size: 14px;
          margin-right: 5px;
        }
        > .vas {
          width: 0;
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 14px;
        }
        > .icons {
          width: 0;
          height: auto;
          flex: 1;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          > .icon {
            width: 80px;
            height: 120px;
            border: 1px solid #cecece;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            color: #cecece;
            position: relative;
            transition: 0.3s;
            padding: 2px;
            margin: 2px;
            &:hover {
              cursor: pointer;
              color: #2873ff;
            }
            > i {
              margin-right: 5px;
            }
            > .dele {
              width: 100%;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              padding-right: 10px;
              font-size: 14px;
              color: #ff7919;
              position: absolute;
              top: 0;
              cursor: pointer;
            }
          }
        }
        > .but {
          width: auto;
          padding: 0 20px;
          height: 30px;
          font-size: 14px;
          color: #2873ff;
          transition: 0.3s;
          &:hover {
            cursor: pointer;
            color: #ff7919;
          }
        }
      }
    }
  }
  .collapse {
    width: 100%;
    height: auto;
    padding: 0 15px;
    box-sizing: border-box;
    .collapse-content {
      width: 100%;
      .collapse-content-line {
        width: 100%;
        display: flex;
        line-height: 2;
        .collapse-content-item {
          width: 50%;
          display: flex;
          font-size: 14px;
          padding-bottom: 10px;
          .collapse-content-item-title {
            min-width: 65px;
            margin-right: 5px;
            display: flex;
          }
          .collapse-content-item-value {
            text-align: left;
          }
        }
      }
      .collapse-content-account {
        display: flex;
        .collapse-content-account-item {
          width: 50%;
          font-size: 14px;
          height: 120px;
          &:last-child {
            border-right: none;
          }
          .collapse-content-account-item-line {
            width: 100%;
            display: flex;
            line-height: 2;
            padding-bottom: 10px;
            .collapse-content-account-item-line-title {
              min-width: 65px;
              margin-right: 5px;
              display: flex;
            }
            .collapse-content-account-item-line-value {
              text-align: left;
            }
          }
        }
        .line {
          width: 1px;
          height: 120px;
          background-color: #dcdfe6;
        }
      }
    }
  }
  > .biot {
    width: 100%;
    height: auto;
    min-height: 40px;
    display: flex;
    align-items: center;
    margin-top: 8px;
    > .tie {
      width: auto;
      height: 100%;
      display: flex;
      align-items: flex-start;
      font-size: 14px;
      margin-right: 5px;
    }
    > .vas {
      width: 0;
      flex: 1;
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      font-size: 14px;
    }
  }
  > .flow {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-top: 8px;
    > .tie {
      width: auto;
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      font-size: 14px;
      margin-right: 5px;
    }
    > .vas {
      width: 0;
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      > .line {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        > .sta {
          width: 100%;
          height: 40px;
          display: flex;
          align-items: center;
          > .ic {
            width: 28px;
            height: 28px;
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #b4b4b4;
            color: #ffffff;
          }
          > .blu {
            background-color: #71a9f3;
          }
          > .re {
            background-color: #ff784f;
          }
          > span {
            width: auto;
            padding: 0 5px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            font-size: 14px;
            font-weight: bolder;
          }
        }
        > .val {
          width: 100%;
          height: auto;
          display: flex;
          align-items: center;
          > .users {
            width: 0;
            height: auto;
            flex: 1;
            display: flex;
            flex-direction: column;
            border-left: 2px solid;
            padding-left: 20px;
            margin-left: 15px;
            box-sizing: border-box;
            > .user {
              width: 100%;
              height: 40px;
              display: flex;
              margin-top: 10px;
              align-items: center;
              > .icon {
                width: 35px;
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                > .tip {
                  position: absolute;
                  top: -9px;
                  right: -9px;
                  width: 15px;
                  height: 15px;
                  background-color: #71a9f3;
                  border-radius: 10px;
                  color: #ffffff;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 11px;
                  box-sizing: border-box;
                }
              }
              > .name {
                width: 0;
                flex: 1;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-left: 20px;
                box-sizing: border-box;
                font-size: 14px;
                color: #a7a7a7;
              }
              > .time {
                display: flex;
                margin-right: 15%;
                font-size: 13px;
                color: #a7a7a7;
                align-items: center;
                > span {
                  margin: 0 8px;
                }
              }
            }
            > .des {
              width: 100%;
              height: 35px;
              padding-left: 40px;
              box-sizing: border-box;
              font-size: 14px;
              color: #a7a7a7;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              > span {
                margin-right: 2px;
              }
            }
            > .file {
              width: 100%;
              height: auto;
              min-height: 35px;
              padding-left: 40px;
              box-sizing: border-box;
              font-size: 14px;
              color: #a7a7a7;
              display: flex;
              align-items: flex-start;
              justify-content: flex-start;
              > span {
                margin-right: 2px;
              }
              > .val {
                width: 0;
                flex: 1;
                height: auto;
                min-height: 35px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                > img {
                  width: 30px;
                  height: 65px;
                  margin: 2px 5px;
                }
              }
            }
          }
        }
      }
    }
  }
  .moneyText {
    width: auto;
    min-width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #969696;
    span {
      color: rgb(255, 127, 36);
    }
  }
}
</style>
