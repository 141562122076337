<template>
  <div id="renew-dialog">
    <el-radio-group v-model="value.flag">
      <el-radio :label="'0'">
        <div class="div-radio">
          <div class="div1">新建续签合同</div>
          <div class="div3">
            如果当前合同已收费完成且已经到期,则会自动标记为结束,其他情况需要自行手动标记结束。
          </div>
          <div class="div2">
            <span>续签期限</span>
            <el-select filterable v-model="year" @change="change1">
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            年
            <el-select filterable v-model="month" @change="change2">
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            月
          </div>
        </div>
      </el-radio>
      <el-radio :label="'1'">
        <div class="div-radio">
          <div class="div1">手动结束原合同并续签新合同</div>
          <div class="div2">
            <span>原合同结束至</span>
            <div class="div2-input">
              <el-date-picker
                v-model="value.time"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </div>

            <!-- 月 至 {{ value.time }} 结束 -->
          </div>
          <div class="div2">
            <span>原合同剩余费用</span>
            <div class="div2-input">
              <el-input v-model="value.cost" placeholder="0"></el-input>
            </div>
          </div>
          <div class="div3" style="white-space: pre-wrap">
            确定续签后,原合同的状态将在结束日期时,自动标记结束状态,并且会在原合同结束后将原合同所剩余的费用带至新续签的合同中,请确认时间及剩余费用并谨慎操作。
          </div>
        </div>
      </el-radio>
    </el-radio-group>
  </div>
</template>

<script>
export default {
  props: ["renewData"],
  data() {
    return {
      value: this.renewData,
      //   value: {
      //     flag: "0",
      //     time: "2021-06-21",
      //   },
      year: "1",
      month: "0",
      value1: "",
      options1: [
        {
          label: "0",
          value: "0",
        },
        {
          label: "1",
          value: "1",
        },
        {
          label: "2",
          value: "2",
        },
        {
          label: "3",
          value: "3",
        },
        {
          label: "4",
          value: "4",
        },
        {
          label: "5",
          value: "5",
        },
        {
          label: "6",
          value: "6",
        },
        {
          label: "7",
          value: "7",
        },
        {
          label: "8",
          value: "8",
        },
        {
          label: "9",
          value: "9",
        },
        {
          label: "10",
          value: "10",
        },
      ],

      options2: [
        {
          label: "0",
          value: "0",
        },
        {
          label: "1",
          value: "1",
        },
        {
          label: "2",
          value: "2",
        },
        {
          label: "3",
          value: "3",
        },
        {
          label: "4",
          value: "4",
        },
        {
          label: "5",
          value: "5",
        },
        {
          label: "6",
          value: "6",
        },
        {
          label: "7",
          value: "7",
        },
        {
          label: "8",
          value: "8",
        },
        {
          label: "9",
          value: "9",
        },
        {
          label: "10",
          value: "10",
        },
        {
          label: "11",
          value: "11",
        },
      ],
    };
  },
  watch: {
    renewData() {
      //父组件值变化->>子组件也变化
      this.value = this.renewData;
    },
    value(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {
    change1(e) {
      if (this.value.time != "") {
        if (this.value1 != "") {
          this.value = JSON.parse(JSON.stringify(this.value1));
        }
        this.value1 = JSON.parse(JSON.stringify(this.value));
        let tt = this.value.time.split("-"); // 最原始的时间
        let yy = parseInt(tt[0]) + parseInt(e);
        let mm = parseInt(parseInt(this.month) + parseInt(tt[1]));
        if (mm % 12 == 0) {
          mm = 12;
        } else if (mm % 12 < 10) {
          mm = "0" + (mm % 12);
        } else {
          mm = mm % 12;
        }
        this.value.time = yy + "-" + mm + "-" + tt[2];
        this.year = e + "";
        this.value.year = this.year;
        this.value.month = this.month;
      }
    },
    change2(e) {
      if (this.value.time != "") {
        if (this.value1 != "") {
          this.value = JSON.parse(JSON.stringify(this.value1));
        }
        this.value1 = JSON.parse(JSON.stringify(this.value));
        let tt = this.value1.time.split("-");
        let mm = parseInt(parseInt(tt[1]) + parseInt(e));
        let yy = parseInt(parseInt(tt[0]) + parseInt(this.year));
        if (mm >= 12) {
          yy = yy + 1;
        }
        if (mm % 12 == 0) {
          mm = 12;
        } else if (mm % 12 < 10) {
          mm = "0" + (mm % 12);
        } else {
          mm = mm % 12;
        }
        // mm = mm % 12 > 0 && mm % 12 < 10 ? "0" + (mm % 12) : mm % 12;
        this.value.time = yy + "-" + mm + "-" + tt[2];
        this.month = e + "";
        this.value.year = this.year;
        this.value.month = this.month;
      }
    },
  },
};
</script>

<style lang="less" scoped>
#renew-dialog {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  padding: 10px;
  box-sizing: border-box;
  text-align: left;
  line-height: 30px;
  .el-radio {
    display: flex;
    margin-bottom: 20px;
  }
  .div2 {
    margin-top: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    span {
      width: 120px;
      display: flex;
      justify-content: flex-end;
      margin-right: 10px;
    }
    .div2-input {
      flex: 1;
      width: 0;
      height: 100%;
    }
  }
  .div3 {
    color: rgb(160, 160, 160);
    margin-top: 10px;
    font-size: 14px;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    text-align: left !important;
  }
}
</style>