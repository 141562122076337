<template>
  <!-- 小程序-轮播图 -->
  <div id="xcx-swiper">
    <div class="xcx-swiper">
      <div class="xcx-swiper-line">
        <div class="xcx-swiper-search"></div>
        <div class="xcx-swiper-active"></div>
        <div
          class="xcx-swiper-but-boder"
          @click="handleClick('', 0)"
          v-showFunction="{
            active: 'add',
            array: allFunctionButtons,
          }"
        >
          <i class="el-icon-plus" style="margin-right: 5px;"></i>
          添加信息
        </div>
      </div>
    </div>
    <div class="table-content">
      <div class="person-info-content customScrollBar">
        <el-table
          :data="tableData"
          style="width: 100%"
          highlight-current-row
          height="98%"
          v-loading="loading"
          :row-style="{ height: '60px' }"
        >
          <el-table-column label="封面" width="170">
            <template slot-scope="scope">
              <div v-viewer="{ movable: false }">
                <img
                  style="height: 50px;"
                  class="table-image"
                  :src="scope.row.cover"
                  alt=""
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="link" label="路径"></el-table-column>
          <el-table-column label="操作" fixed="right" width="200">
            <template scope="scope">
              <el-button
                type="primary"
                plain
                size="mini"
                icon="el-icon-edit-outline"
                @click.native="handleClick(scope.row, 1)"
                v-showFunction="{
                  active: 'edit',
                  array: allFunctionButtons,
                }"
                >编辑</el-button
              >
              <el-button
                type="danger"
                plain
                size="mini"
                icon="el-icon-delete"
                @click="handleClick(scope.row, 2)"
                v-showFunction="{
                  active: 'delete',
                  array: allFunctionButtons,
                }"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @handleAvatarSuccess="handleAvatarSuccess"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import my_dialog from "@/components/dialog.vue";
export default {
  components: {
    my_dialog,
  },
  data() {
    return {
      loading: false,
      baseInfo: {
        userId: "",
        company: {
          companyName: "",
        },
      },
      whetherAgencyHead: false,

      dialogData: {
        flag: false,
        width: "30%",
        title: "添加账号",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },
      tableData: [],
      flag: false,
      allFunctionButtons: this.$featureData.getAllFunctionButtons("xcxSwiper"),
      temData: {},
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "xcxSwiper",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          // this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.initTableData();
      });
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      this.tableData = [];
      this.loading = true;
      this.$request(
        "get",
        "/appletCarouselMap/queryAll",
        {},
        (data) => {
          this.tableData = data;
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "sure_add":
          requestData = {
            cover: this.dialogData.data[0].value.value, // 封面
            link: this.dialogData.data[1].value, // 链接
          };
          this.$request(
            "post",
            "/appletCarouselMap/addCarouselMap",
            requestData,
            (data) => {
              this.$message.success("添加成功!");
              this.dialogData.flag = false;
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "sure_edit":
          requestData = {
            id: this.temData.id, // id
            cover: this.dialogData.data[0].value.value, // 封面
            link: this.dialogData.data[1].value, // 链接
          };
          this.$request(
            "post",
            "/appletCarouselMap/updateCarouselMap",
            requestData,
            (data) => {
              this.$message.success("修改成功!");
              this.dialogData.flag = false;
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "cancel":
          // 取消
          this.dialogData.flag = false;
        default:
          break;
      }
    },
    handleClick(data, flag) {
      this.temData = data;
      switch (flag) {
        case 0:
          // 添加
          this.dialogData.title = "添加信息";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "sure_add",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              title: "封面",
              type: "upload",
              value: {
                value: "",
                action: this.$fileBaseurl + "/uploadImageByMultipartFile",
                headers: {
                  token: "95931b371eb64f4a9c862983ca8a5d15",
                },
                uploadParameter: {
                  systemName: "finance",
                  title: "轮播图封面",
                },
              },
              key: "contractFile",
            },
            {
              title: "链接",
              type: "input",
              value: "",
            },
          ];
          this.dialogData.flag = true;
          break;
        case 1:
          // 编辑
          this.dialogData.title = "编辑信息";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "sure_edit",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              title: "封面",
              type: "upload",
              value: {
                value: this.temData.cover,
                action: this.$fileBaseurl + "/uploadImageByMultipartFile",
                headers: {
                  token: "95931b371eb64f4a9c862983ca8a5d15",
                },
                uploadParameter: {
                  systemName: "finance",
                  title: "轮播图封面",
                },
              },
              key: "contractFile",
            },
            {
              title: "链接",
              type: "input",
              value: this.temData.link,
            },
          ];
          this.dialogData.flag = true;
          break;
        case 2:
          // 删除
          this.$confirm("确认要删除这条信息吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              let requestData = {
                ids: [this.temData.id],
              };
              this.$request(
                "post",
                "/appletCarouselMap/delCarouselMap",
                requestData,
                (data) => {
                  this.$message.success("删除成功!");
                  this.initTableData();
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {});
          break;
        default:
          break;
      }
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
      // this.initTableData();
    },
    handleAvatarSuccess(data) {
      this.dialogData.data[0].value.value = data.result.path;
    },
  },
  mounted() {
    this.isCharge();
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));

    this.baseInfo.company.companyAdmins.forEach((element) => {
      if (element.companyAdminId == this.baseInfo.userId) {
        this.flag = true;
      }
    });
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#xcx-swiper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .xcx-swiper {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    .xcx-swiper-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      > .title {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 0 10px;
      }
      > .el-tag {
        margin: 0 5px;
      }
      .xcx-swiper-search {
        width: 210px;
        min-width: 210px;
        height: 30px;
        margin-left: 10px;
        display: flex;
        align-items: center;
      }
      .xcx-swiper-active {
        flex: 1;
      }
      .xcx-swiper-but-boder {
        min-width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        font-size: @contentFontSize;
        background-color: @activeBackground;
        color: @activeColor;
        margin: 0 5px;
        margin-right: 10px;
        border-radius: 4px;
        transition: 0.5s;
        padding: 0 15px;
      }
      .xcx-swiper-but-boder:hover {
        cursor: pointer;
        background-color: @activeBackground2;
      }
    }
  }
  .person-info-content {
    width: 98%;
    height: 95%;
    max-width: 90vw;
    max-height: 95%;
    overflow-y: auto;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
  }
}
</style>
