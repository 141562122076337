<template>
  <div id="wechat-contract">
    <!-- 企业微信中收费界面 -->
    <div class="wechat-contract-line">
      <el-date-picker
        v-model="dateTime"
        type="month"
        placeholder="选择签单日期"
        value-format="yyyy-MM"
      >
      </el-date-picker>
      <el-button
        type="primary"
        size="mini"
        icon="el-icon-plus"
        plain
        @click="addContract"
        >新增合同</el-button
      >
    </div>

    <div class="wechat-contract-table">
      <el-table
        :data="value.contractTableData"
        border
        style="width: 100%"
        height="75vh"
        size="mini"
      >
        <el-table-column
          prop="contractNumber"
          label="合同编号"
          align="center"
          width="130px"
        >
          <template slot-scope="scope">
            <div
              class="wechat-contract-table-link"
              @click="showContractDetails(scope.row.id)"
            >
              {{ scope.row.contractNumber }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" align="center" width="90px">
        </el-table-column>
        <el-table-column prop="money" label="金额" align="center">
        </el-table-column>
        <el-table-column prop="single" label="签单人" align="center">
        </el-table-column>
        <el-table-column
          prop="signTime"
          label="签单日期"
          align="center"
          width="90px"
        >
        </el-table-column>
        <el-table-column
          prop="dateDue"
          label="到期日期"
          align="center"
          width="90px"
        >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  props: ["wechatContractData"],
  data() {
    return {
      value: this.wechatContractData,
      // value: {
      //   contractTableData: [],
      // },
      dateTime: "",
    };
  },
  watch: {
    wechatContractData() {
      //父组件值变化->>子组件也变化
      this.value = this.wechatContractData;
    },
    value(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {
    addContract() {
      this.$emit("addContract");
      // this.$router.push("/wechat-add-contract");
    },
    showContractDetails(id) {
      this.$emit("showContractDetails", id);
    },
  },
};
</script>

<style lang="less" scoped>
#wechat-contract {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .wechat-contract-line {
    width: 100%;
    min-height: 30px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
    box-sizing: border-box;
  }
  .wechat-contract-table {
    width: 100%;
    padding: 5px;
    margin-top: 10px;
    box-sizing: border-box;
    height: auto;
    // background-color: rgb(28, 103, 168);
    .wechat-contract-table-link {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #019cfa;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>