<template>
  <!-- 选择收件人 -->
  <div id="selectRecipient">
    <div class="selectRecipient-line">
      <el-input
        style="width: 500px"
        placeholder="请输入姓名/公司名称/关联客户进行搜索，选择对应收件人"
        v-model="searchData.input"
        @keyup.enter.native="initTableData"
        ><el-button
          slot="append"
          icon="el-icon-search"
          @click.native.stop="initTableData"
        ></el-button
      ></el-input>
    </div>
    <div class="selectRecipient-line">
      <div class="selectRecipient-line-table">
        <el-table
          :data="tableData"
          height="240"
          :header-cell-style="{ background: '#ECF5FF', color: '#606266' }"
          highlight-current-row
          @row-click="rowClick"
        >
          <el-table-column prop="name" label="收件人" show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            prop="companyName"
            label="收件人公司"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column prop="phone" label="电话" show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            prop="address"
            label="详细地址"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="clientName"
            label="关联客户"
            width="150px"
            show-overflow-tooltip
          >
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectRecipientData: {
      type: Array,
      default: function() {
        return;
      },
    },
  },
  data() {
    return {
      value: {},
      tableData: [],
      searchData: {
        input: "",
      },
    };
  },
  methods: {
    /**
     * 地址表格数据
     */
    initTableData() {
      this.tableData = [];
      let requestData = {
        search: this.searchData.input, // 收件人、收件人公司、客户简称
      };
      this.$request(
        "post",
        "/expressAddress/queryAddress",
        requestData,
        (data) => {
          this.tableData = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 表格行点击事件
     */
    rowClick(row, column, event) {
      // this.$emit("rowClick", row, column, event);
      // this.$bus.$emit('事件名', 额外参数)
      this.$bus.$emit("rowClick", row);
    },
  },
  created() {
    this.value = this.selectRecipientData;
  },
};
</script>

<style lang="less" scoped>
#selectRecipient {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .selectRecipient-line {
    width: 100%;
    display: flex;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0px;
    }
    .selectRecipient-line-table {
      width: 100%;
      /deep/ .el-table__body-wrapper {
        width: 100%;
        &::-webkit-scrollbar {
          height: 8px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}
</style>
