/**
 * 物品设置菜单栏
 */
export function getMenuData() {
    let MenuData = [
        {
            name: '物品设置'
        },
        {
            name: '档案柜设置'
        }
    ]
    return MenuData 
}

/**
 * 客户档案表格数据
 */
export function getArchivesInfoTableData(data) {
    data.forEach(item => {
        if(item.archivesList) {
            item.archivesList.forEach(ele => {
                switch (item.status) {
                    case '0':
                        item.status = '未领用'
                        item.color = '#409EFF'
                        break;
                    case '1':
                        item.status = '已领用'
                        item.color = '#E6A23C'
                        break;
                    case '2':
                        item.status = '已交付'
                        item.color = '#67C23A'
                        break;
                    case '3':
                        item.status = '交付中'
                        item.color = '#909399'
                        break;
                    default:
                        break;
                }
                switch (ele.status) {
                   case '0': 
                       ele.status = '未领用'
                       ele.color = '#409EFF'
                       break;
                   case '1':
                       ele.status = '已领用'
                       ele.color = '#E6A23C' 
                       break;
                   case '2':
                       ele.status = '已交付'
                       ele.color = '#67C23A'
                       break;
                    case '3':
                        ele.status = '交付中'
                        ele.color = '#909399'
                        break;   
                   default:
                       break;
                }
            })
        } else {
            switch (item.status) {
                case '0':
                    item.status = '未领用'
                    item.color = '#409EFF'
                    break;
                case '1':
                    item.status = '已领用'
                    item.color = '#E6A23C'
                    break;
                case '2':
                    item.status = '已交付'
                    item.color = '#67C23A'
                    break;
                case '3':
                    item.status = '交付中'
                    item.color = '#909399'
                    break;   
                default:
                    break;
            }
        }
    })
    return data
}

/**
 * 添加物品 dialog
 */
 export function addArchivesDialogData(clientList, categoryList, locationList, flag) {
    categoryList.forEach(item => {
        item.categoryIdList = []
        item.checkAll = false
    })
   let data = {
    isClient: flag, // 是否为客户模块, 是不需要选择客户,直接新增物品
    clientId: '', // 客户 id
    // categoryIdList: [], // 档案类别 id 集合
    locationId: '', // 档案位置 id
    clientList: clientList, // 客户列表
    categoryList: categoryList, // 所有物品
    locationList: locationList, // 所有位置
   }
   return data
}

/**
 * 领取 归还物品 dialog
 */ 
 export function getArchivesDialogData(multipleSelection) {
   let archivesData = {
    ids: [], 
    data: []
   }
   const clientId = multipleSelection.map(item => item.clientId) //找出所有客户id
   const setClientId = new Set(clientId) // 去除相同的客户id
   
   setClientId.forEach(item => {
    archivesData.data.push({clientId: item, clientName: '', name: []})
   }) // 在新数组中添加元素 clientId 为 去重后的 id
   multipleSelection.forEach((item, index) => {
    archivesData.ids.push(item.id) // 获取所有 档案id
    archivesData.data.forEach((ele, i) => {
        if (item.clientId == ele.clientId) { 
            // 如果有相同的 clientId 往目标数组中添加 clientName name
            ele.clientName = item.clientName
            ele.name.push(item.name)
        }
    })
   })
  
   return archivesData
}

 /**
 * 获取界面筛选数据
 * 
 */
  export function archiveInfoSearchData() {
    let data = [
        {
            title: "领用人",
            type: "tip",
            flag: "0",
            hasSearch: true,
            key: "recipient",
            search_value: "",
            value: [
                // { text: "张三", is_active: false },
                // { text: "李四", is_active: false },
            ],
            options: [
                { label: "张三", value: "张三" },
                { label: "李四", value: "李四" },
                { label: "王五", value: "王五" },
            ],
        },
        
    ]
    return data
}
