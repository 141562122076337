/**
 * 业务角色抽屉数据
 */
export function getRoleDrawerData(title, data, list, auth) {
    let aa = {
        isShow: true,
        isDisable: false,
    }
    if (auth == 1) {
        // 读写
        aa.isShow = true,
            aa.isDisable = false
    } else if (auth == 2) {
        // 只读
        aa.isShow = true,
            aa.isDisable = true
    } else if (auth == 3) {
        // 不可见
        aa.isShow = false,
            aa.isDisable = true
    }
    let roleDrawerData = {
        title: "角色信息",
        flag: false,
        buttonList: [],
        hasSetting: false,
        data: {
            title: title,
            customer: 0, // 关联客户,与商机状态关联,(只有商机状态为成交才有值),其他时候为空字符串
            isDisable: false, // 是否只读
            modelList: [{
                line: [{
                    horizontal: true,
                    isShow: aa.isShow,
                    isDisable: aa.isDisable,
                    title: "角色名称",
                    type: "input",
                    value: "",
                }, {
                    horizontal: true,
                    isShow: aa.isShow,
                    isDisable: aa.isDisable,
                    title: "默认设置",
                    type: "radio",
                    options: [{
                        label: "0",
                        text: "无"
                    }, {
                        label: "1",
                        text: "自己"
                    }, {
                        label: "2",
                        text: "指定人员"
                    }],
                    value: "0",
                }, {
                    horizontal: true,
                    isShow: aa.isShow,
                    isDisable: aa.isDisable,
                    title: "默认员工",
                    type: "tags-select-obj",
                    tempValue: {},
                    isSelect: false,
                    options: list,
                    value: [],
                }]
            }]
        }
    }

    if (data != "") {
        roleDrawerData.data.modelList[0].line[0].value = data.authorityName
        roleDrawerData.data.modelList[0].line[1].value = data.flag
        roleDrawerData.data.modelList[0].line[2].value = roleDrawerData.data.modelList[0].line[1].value == "2" ? data.users : []
        roleDrawerData.data.modelList[0].line[2].isDisable = roleDrawerData.data.modelList[0].line[1].value == "2" ? aa.isDisable : true
    }
    switch (data.authorityName) {
        case "服务顾问":
        case "客户经理":
        case "收费负责人":
        case "开票员":
        case "合同审核员":
        case "出纳":
            roleDrawerData.data.modelList[0].line[0].isDisable = true
            break;
        default:
            roleDrawerData.data.modelList[0].line[0].isDisable = aa.isDisable
            break;
    }
    return roleDrawerData
}