/**
 * 对源数据截取decimals位小数，不进行四舍五入
 */
export function cutOutNum(num, _this, deal) {
    _this.tempNum = 0
    _this.axios({
        method: "post",
        url: _this.$baseurl + "/cutOutNum",
        data: { num: num },
        headers: {
            "Content-Type": "application/json",
        },
    }).then((res) => {
        if (res.data.success) {
            deal(res.data.result.num)
        } else {
            deal(0)
        }
    });
}