<template>
  <div id="expressage-maintain">
    <!-- 地址维护 -->
    <div class="expressage-maintain-screen">
      <div class="expressage-maintain-screen-line">
        <div class="expressage-maintain-screen-search">
          <el-input
            v-model="searchData.input"
            placeholder="请输入收件人/收件人公司/客户简称"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="expressage-maintain-screen-active"></div>
        <div
          class="expressage-maintain-screen-but"
          @click="handleClick('', 0)"
          v-showFunction="{
            active: 'addAddress',
            array: allFunctionButtons,
          }"
        >
          新增地址
        </div>
        <div
          class="expressage-maintain-screen-but-boder"
          @click="handleClick('', 3)"
          v-showFunction="{
            active: 'import',
            array: allFunctionButtons,
          }"
        >
          导入地址
        </div>
      </div>
    </div>

    <div class="table-content">
      <div class="expressage-maintain-table-card">
        <div class="expressage-maintain-table-card-tabs">
          <div
            :class="{
              'expressage-maintain-table-card-tab': true,
              'is-active': tabIndex == 0,
            }"
            @click="changeTab(0)"
          >
            客户地址
          </div>
          <div
            :class="{
              'expressage-maintain-table-card-tab': true,
              'is-active': tabIndex == 1,
            }"
            @click="changeTab(1)"
          >
            未关联客户地址
          </div>
          <div class="expressage-maintain-table-card-active"></div>
        </div>
        <div class="expressage-maintain-content">
          <el-table
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            height="98%"
            v-loading="loading"
            :row-style="{ height: '60px' }"
            @sort-change="sortChange"
          >
            <el-table-column type="index" width="40" fixed> </el-table-column>
            <el-table-column
              prop="name"
              label="收件人"
              width="100"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="companyName"
              label="收件人公司"
              width="150"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="phone"
              label="电话"
              width="120"
            ></el-table-column>
            <el-table-column prop="area" label="省市区" width="150">
            </el-table-column>
            <el-table-column
              prop="address"
              label="详细地址"
              width="180"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="clientNum"
              label="客户编号"
              width="150"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="clientName"
              label="客户全称"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column label="操作" width="200">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  plain
                  icon="el-icon-edit-outline"
                  @click.native="handleClick(scope.row, 1)"
                  v-showFunction="{
                    active: 'modification',
                    array: allFunctionButtons,
                  }"
                  >编辑</el-button
                >
                <el-button
                  type="danger"
                  size="mini"
                  plain
                  icon="el-icon-circle-close"
                  @click.native="handleClick(scope.row, 2)"
                  v-showFunction="{
                    active: 'delete',
                    array: allFunctionButtons,
                  }"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="expressage-maintain-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @handleAvatarSuccess="handleAvatarSuccess"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import * as expressageData from "./js/expressage";
import my_dialog from "@/components/dialog.vue";
import popo_search from "@/components/popo-search.vue";

export default {
  components: {
    popo_search,
    my_dialog,
  },
  data() {
    return {
      tabIndex: 0,
      loading: false,
      baseInfo: {},
      tableData: [],
      searchData: {
        input: "",
      },
      defaultSort: { prop: "", order: "" },
      popoData: {
        popoVisible: false,
        list: expressageData.expressageInfoSearchData(),
      },
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "expressageMaintain"
      ),
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "运单详情",
        buttonArray: [
          {
            text: "确认",
            active: "confirm",
          },
        ],
        data: [],
      },
      clientList: [],
      tempExpressageMaintain: {},
      whetherAgencyHead: false,
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "expressageMaintain",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getClientList();
        this.initTableData();
      });
    },
    /**
     * 获取客户名称列表
     */
    getClientList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/client/getAllOptions",
        requestData,
        (data) => {
          this.clientList = data;
        },
        (errormessage) => {
          this.$message.error(errormessage);
        }
      );
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      this.tableData = [];
      this.loading = true;
      let requestData = {
        unassociated: this.tabIndex,
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
        search: this.searchData.input, // 收件人、收件人公司、客户简称
        // sortProp: this.defaultSort.prop, // 手动排序项
        // sortOrder: this.defaultSort.order, // 手动排序规则 ascending descending ""
      };
      this.$request(
        "post",
        "/expressAddress/queryAddressByPage",
        requestData,
        (data) => {
          this.tableData = data.records;
          this.pageData.total = data.total; // 获取总条目数
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    /**
     * tab切换事件
     */
    changeTab(index) {
      this.tabIndex = index;
      this.initTableData();
    },
    /**
     * 界面操作
     */
    handleClick(data, flag) {
      this.tempExpressageMaintain = data;
      let requestData = {};
      switch (flag) {
        case 0:
          // 新增地址
          this.dialogData.width = "520px";
          this.dialogData.title = "新增地址";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "sure_add",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];

          this.dialogData.data = [
            {
              title: "",
              type: "delivery_detail",
              data: expressageData.AddressDetailsDialog("", this.clientList),
            },
          ];
          console.log("新增地址dialog显示:", this.dialogData.data);
          this.dialogData.flag = true;
          break;
        case 1:
          // 修改地址
          requestData = {
            id: data.id, // 订单id
          };

          this.$request(
            "get",
            `/expressAddress/getById?id=${data.id}`,
            {},
            (data) => {
              this.dialogData.data = [
                {
                  title: "",
                  type: "delivery_detail",
                  data: expressageData.AddressDetailsDialog(
                    data,
                    this.clientList
                  ),
                },
              ];
              this.dialogData.width = "520px";
              this.dialogData.title = "地址详情";
              this.dialogData.buttonArray = [
                {
                  text: "保存",
                  active: "sure_save",
                },
                {
                  text: "取消",
                  active: "cancel",
                },
              ];

              console.log("修改地址dialog显示:", this.dialogData.data);
              this.dialogData.flag = true;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );

          break;
        case 2:
          // 删除
          requestData = {
            ids: [data.id],
          };
          this.$confirm("确定要删除该地址吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$request(
                "post",
                "/expressAddress/del",
                requestData,
                (data) => {
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                  this.initTableData();
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {});

          break;
        case 3:
          // 导入地址
          this.dialogData.width = "750px";
          this.dialogData.title = "地址导入";
          this.dialogData.buttonArray = [
            {
              text: "导入",
              active: "makeSure_import_expressage",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              title: "",
              type: "address_import",
              value: {
                value: "", // 客户地址
                action: this.$fileBaseurl + "/uploadImageByMultipartFile",
                headers: {
                  token: "95931b371eb64f4a9c862983ca8a5d15",
                },
                uploadParameter: {
                  systemname: "finance",
                  title: "客户地址导入",
                },
                modelType: "expressReceiverMode",
              },
            },
          ];
          console.log("地址导入dialog显示:", this.dialogData.data);
          this.dialogData.flag = true;
          break;
        default:
          break;
      }
    },
    /**
     * 抽屉数据或dialog数据操作事件
     */
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "sure_add":
          // 确认新增
          this.operationAddress(0);
          break;
        case "sure_save":
          // 确认修改
          this.operationAddress(1);
          break;
        case "makeSure_import_expressage":
          // 导入地址
          requestData = {
            userId: this.baseInfo.userId, // 当前登录人 id
            url: this.dialogData.data[0].value.value,
          };
          if (requestData.url == "") {
            this.$message.error("请先选择要导入的文件!");
            return;
          }
          console.log("导入资源数据:", requestData);
          this.$request(
            "post",
            "/expressAddress/importExcel",
            requestData,
            (data) => {
              this.$message.success("导入成功");
              this.dialogData.flag = false;
              this.initTableData();
            },
            (err) => {
              this.$message({
                message: err,
                type: "error",
              });
              this.dialogData.flag = false;
            }
          );
          break;
        case "cancel":
          this.colseDialog();
          break;
        default:
          break;
      }
    },
    getDeliveryOrderDetails() {
      let requestData = {
        Id: "", // 订单id
      };

      // this.$request(
      //   "post",
      //   "/expressage/getDeliveryOrderDetails",
      //   requestData,
      //   (data) => {},
      //   (errorMessage) => {
      //     this.$message.error(errorMessage);
      //     this.loading = false;
      //   }
      // );
    },
    /**
     * 新增, 修改地址
     */
    operationAddress(active) {
      let code = expressageData.transformCode(
        this.dialogData.data[0].data.code
      );
      let requestData = {
        companyName: this.dialogData.data[0].data.companyName, // 收件人公司
        name: this.dialogData.data[0].data.name, // 姓名
        phone: this.dialogData.data[0].data.phone, // 电话
        area: code.area, // 省市区
        provinceCode: code.provinceCode, // 省份编码
        cityCode: code.cityCode, // 城市编码
        countyCode: code.countyCode, // 区县编码
        address: this.dialogData.data[0].data.address, // 详细地址
        clientId: this.dialogData.data[0].data.clientId, // 关联客户 id
        createId: this.baseInfo.userId, // 创建人 id
      };
      if (requestData.name == "") {
        this.$message.error("姓名为必填项!");
        return;
      }
      if (requestData.Phone == "") {
        this.$message.error("电话为必填项!");
        return;
      }
      let flag = true;
      if (!this.$phoneCheck.mobilePhoneNumberCheck(requestData.phone)) {
        // 做手机号格式校验
        flag = false;
      }
      if (!flag) {
        this.$message({
          message: "联系人手机号格式不正确,请确认填入正确的手机号!",
          type: "error",
          duration: 1000,
        });
        return;
      }
      if (requestData.provinceCode == "") {
        this.$message.error("省市区必填项!");
        return;
      }
      if (requestData.cityCode == "") {
        this.$message.error("省市区必填项!");
        return;
      }
      if (requestData.countyCode == "") {
        this.$message.error("省市区必填项!");
        return;
      }
      if (requestData.address == "") {
        this.$message.error("详细地址为必填项!");
        return;
      }

      switch (active) {
        case 0:
          // 新增
          this.$request(
            "post",
            "/expressAddress/add",
            requestData,
            (data) => {
              this.dialogData.flag = false;
              this.$message.success("新增成功!");
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case 1:
          // 修改
          requestData.id = this.tempExpressageMaintain.id;
          this.$request(
            "post",
            "/expressAddress/edit",
            requestData,
            (data) => {
              this.dialogData.flag = false;
              this.$message.success("修改成功!");
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;

        default:
          break;
      }
    },
    /**
     * 表格排序事件
     */
    sortChange(data) {
      console.log(data);
      this.defaultSort.prop = data.order == null ? "" : data.prop;
      this.defaultSort.order = data.order == null ? "" : data.order;
      // this.initTableData();
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    /**
     * 客户地址导入成功
     */
    handleAvatarSuccess(response) {
      this.$message({
        message: "客户地址导入成功!",
        type: "success",
        duration: 1000,
      });
      this.dialogData.data[0].value.value = response.result;
    },
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#expressage-maintain {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .expressage-maintain-screen {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    .expressage-maintain-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      > .title {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 0 10px;
      }
      > .el-tag {
        margin: 0 5px;
      }
      .expressage-maintain-screen-fiftrate {
        width: 65px;
        min-width: 65px;
        height: 30px;
        border: 1px solid @boderColor;
        box-sizing: border-box;
        color: @boderActiveColor;
        font-size: @contentFontSize;
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: 0.3s;
        i {
          margin: 0 4px;
        }
      }
      .expressage-maintain-screen-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }
      .expressage-maintain-screen-search {
        width: 210px;
        min-width: 210px;
        height: 30px;
        margin-left: 10px;
        display: flex;
        align-items: center;
      }
      .expressage-maintain-screen-active {
        flex: 1;
      }
      .drop-button {
        background-color: #ecf5ff;
        color: #409eff;
        &:hover {
          background-color: #abbed3;
        }
      }
      .expressage-maintain-screen-but {
        width: 100px;
        min-width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .expressage-maintain-screen-but:hover {
        cursor: pointer;
        background-color: @activeColor2;
      }
      .expressage-maintain-screen-but-boder {
        min-width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        font-size: @contentFontSize;
        background-color: @activeBackground;
        color: @activeColor;
        margin: 0 5px;
        margin-right: 10px;
        border-radius: 4px;
        transition: 0.5s;
        padding: 0 15px;
      }
      .expressage-maintain-screen-but-boder:hover {
        cursor: pointer;
        background-color: @activeBackground2;
      }
    }
  }

  .table-content {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;

    .expressage-maintain-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .expressage-maintain-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .expressage-maintain-table-card-tab {
          width: 120px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .expressage-maintain-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
      }
      .expressage-maintain-content {
        width: 100%;
        height: 95%;
        max-width: 90vw;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
      }
      .expressage-maintain-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
