import * as baseData from "./baseData.js"

import * as phoneCheck from "../../utils/mobilePhoneNumberCheck.js"
import { f } from "vue-area-linkage";
/**
 * 客户资料界面tableMenu数据
 */
export function clientInfoTableMenuData() {
    let menuData = [
        // 从数据库拿到的数据
        {
            key: "clientNname",
            title: "客户全称",
            width: "100",
            isDisable: true, // 自定义表头是否显示
        },
        {
            key: "clientNum",
            title: "客户编号",
            width: "150",
            isDisable: true, // 自定义表头是否显示
        },
        {
            key: "type",
            title: "客户类型",
            width: "100",
            isDisable: true,
        },
        {
            key: "manager",
            title: "客户经理",
            width: "100",
            isDisable: true,
        },
        {
            key: "adviser",
            title: "服务顾问",
            width: "100",
            isDisable: true,
        },
        {
            key: "serveStatus",
            title: "客户状态",
            width: "120",
            isDisable: true,
        },
        {
            key: "clientLevel",
            title: "客户等级",
            width: "100",
            isDisable: true,
        },
        {
            key: "pianqu",
            title: "所属片区",
            width: "150",
            isDisable: true,
        },
        {
            key: "addedValueTaxType",
            title: "增值税",
            width: "100",
            isDisable: true,
        },
        {
            key: "setTime",
            title: "成立日期",
            width: "100",
            isDisable: true,
        },
        {
            key: "address",
            title: "详细地址",
            width: "100",
            isDisable: true,
        },
        {
            key: "area",
            title: "所属地区",
            width: "100",
            isDisable: true,
        },
        {
            key: "tip",
            title: "标签",
            width: "100",
            isDisable: true,
        },
        {
            key: "department",
            title: "所属部门",
            width: "100",
            isDisable: true,
        },
        {
            key: "industryType",
            title: "所属行业",
            width: "100",
            isDisable: true,
        },
        {
            key: "createTime",
            title: "创建时间",
            width: "100",
            isDisable: true,
        },
        {
            key: "createName",
            title: "创建人",
            width: "100",
            isDisable: true,
        },
        {
            key: "source",
            title: "来源",
            width: "100",
            isDisable: true,
        },
    ]
    return menuData
}

/**
 * 初始化表格数据处理
 * @param {*} array  所有表格数据
 * @param {*} menuArray  当前显示的表头数组
 */
export function getInitTableData(array, menuArray) {
    array.forEach(client => {
        menuArray.forEach(menu => {
            if (client[menu.key] != null && client[menu.key].constructor === Array) {
                let dd = ""
                if (client[menu.key].length > 0) {
                    client[menu.key].forEach(element => {
                        if (dd == "") {
                            dd = element
                        } else {
                            dd = dd + "," + element
                        }
                    });
                }
            }
        });
    });
    return array
}

/**
 * 获取界面筛选数据
 * @param {*} companyData  {employeeList:员工列表,departmentList:部门列表} 
 * @param {*} tipsData {clientTipsList:客户标签,pianquList:片区标签,clientLevelList:客户等级,customerSourceList:客户来源}
 * @param {*} roleList 客户界面的业务角色列表
 * @param {*} productList 产品服务项
 */
export function clientInfoSearchData(companyData, tipsData, roleList, productList) {
    if (companyData == "") {
        companyData = {
            employeeList: [],
            departmentList: [],
        }
    }
    if (tipsData == "") {
        tipsData = {
            clientTipsList: [],
            pianquList: [],
            clientLevelList: [],
            customerSourceList: [],
            lossReasonList: [],
        }
    }
    let data = [{
            title: "",
            type: "role",
            hasSearch: true,
            flag: "0",
            key: "roles",
            search_value: "",
            searchRole: {},
            value: [],
            options: companyData.employeeList,
            roleOptions: roleList,
        }, {
            title: "服务产品",
            type: "cascader",
            hasSearch: false,
            flag: "0",
            key: "servicesTypeOrItem",
            value: [],
            options: productList,
        }, {
            title: "客户状态",
            type: "tip",
            hasSearch: false,
            flag: "0",
            key: "clientStatus",
            value: [
                { text: "正常", is_active: false, id: "" },
                { text: "已流失", is_active: false, id: "" },
                { text: "待流失审核", is_active: false, id: "" },
            ],
        },
        {
            title: "流失原因",
            type: "tip",
            flag: "0",
            hasSearch: true,
            key: "lossReason",
            search_value: "",
            value: [],
            options: tipsData.lossReasonList,
        },
        {
            title: "客户来源",
            type: "tip",
            flag: "0",
            hasSearch: true,
            key: "source",
            search_value: "",
            value: [],
            options: tipsData.customerSourceList,
        },
        {
            title: "增值税类型",
            type: "tip",
            hasSearch: false,
            flag: "0",
            key: "addedValueTaxType",
            value: [
                { text: "一般纳税人", is_active: false, id: "" },
                { text: "小规模纳税人", is_active: false, id: "" }
            ],
        },
        {
            title: "客户创建时间",
            type: "times",
            flag: "0",
            hasSearch: false,
            key: "createTime",
            value: "",
        },
        {
            title: "是否是开票客户",
            type: "radio",
            flag: "0",
            hasSearch: false,
            key: "isInvoicing",
            value: "",
        },
        {
            title: "是否是包含社保",
            type: "radio",
            flag: "0",
            hasSearch: false,
            key: "isSocialSecurity",
            value: "",
        },
        {
            title: "地址",
            type: "input",
            flag: "0",
            hasSearch: false,
            key: "address",
            value: "",
        },
        {
            title: "开户行",
            type: "input",
            flag: "0",
            hasSearch: false,
            key: "bankName",
            value: "",
        },
        {
            title: "客户联系人",
            type: "input",
            flag: "0",
            hasSearch: false,
            key: "contactName",
            value: "",
        },
        {
            title: "联系人手机号",
            type: "phone",
            flag: "0",
            hasSearch: false,
            key: "contactPhone",
            value: "",
        },
        {
            title: "创建人",
            type: "tip",
            flag: "0",
            hasSearch: true,
            key: "single",
            search_value: "",
            value: [],
            options: companyData.employeeList,
        },
        {
            title: "客户标签",
            type: "tip",
            flag: "0",
            hasSearch: true,
            key: "clientTips",
            search_value: "",
            value: [],
            options: tipsData.clientTipsList,
        },
        {
            title: "片区",
            type: "tip",
            flag: "0",
            hasSearch: true,
            key: "pianqu",
            search_value: "",
            value: [],
            options: tipsData.pianquList,
        },
        {
            title: "客户等级",
            type: "tip",
            flag: "0",
            hasSearch: true,
            key: "clientLevel",
            search_value: "",
            value: [],
            options: tipsData.clientLevelList,
        },
    ]
    return data
}

/**
 * 新增客户抽屉数据
 */
export function getNewClientDrawerData(list) {
    let data = {
        title: "新增客户",
        flag: false,
        hasSetting: false,
        buttonList: [],
        data: {
            isDisable: false, // 是否只读
            list: list,
            modelList: [{
                line: [{
                    isShow: true, // 是否显示
                    isDisable: false, // 是否只读
                    title: "",
                    type: "input-autocomplete-tooltip", // 带建议的input框,必须要有list
                    value: "",
                    message: "",
                    tooltipf_flag: true
                }, {
                    isShow: true, // 是否显示
                    isDisable: false, // 是否只读
                    title: "",
                    type: "text-color",
                    value: [{
                        text: "请输入客户名称后,",
                        color: "#aaa"
                    }, {
                        text: "按回车键",
                        color: "#f22"
                    }, {
                        text: "创建",
                        color: "#aaa"
                    }],
                }]
            }]
        }
    }
    return data
}

/**
 * 客户信息抽屉json数据
 * @param {*} title 
 * @param {*} data 
 */
export function clientInfoDrawerData(title, data, list, buttonAuth, serviceList) {
    // 客户信息的抽屉数据
    if (serviceList == "") {
        // 初始化界面数据
        serviceList = {
            pianquList: [], // 片区
            sourceList: [], // 客户来源
            tipList: [], // 客户标签
            levelList: [], // 客户等级
            departmentList: [], // 部门
            employeeList: [], // 员工
        }
    }

    let clientInfoDrawerData = {
        title: "客户",
        flag: false,
        CardList: [{
                text: "客户信息",
                active: "info",
            }, {
                text: "联系人",
                active: "lianxiren",
            },
            {
                text: "合同",
                active: "hetong",
            },
        ],
        cardIndex: 0,
        buttonList: [{
            text: "跟进",
            authority: 1,
            // authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("跟进", buttonAuth) : 1,
            icon: "el-icon-edit-outline",
            active: "genjin",
        }, ],
        hasSetting: true,
        settingOperList: [{
            text: "删除客户",
            authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("删除客户", buttonAuth) : 1,
            active: "delete_client"
        }, {
            text: "申请客户流失",
            authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("申请代账流失", buttonAuth) : 1,
            active: "run_off"
        }, {
            text: "添加关联客户",
            authority: 1,
            active: "adding_associated_customer"
        }, {
            text: "暂停代账服务",
            authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("暂停代账服务", buttonAuth) : 1,
            active: "pause_serve"
        }, {
            text: "转为非代账",
            authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("转为非代账", buttonAuth) : 1,
            active: "zhuanweifeidaizhang"
        }, {
            text: "操作记录",
            authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("操作记录", buttonAuth) : 1,
            active: "operating_record"
        }, ],
        data: {
            title: title,
            customer: 3, // 关联客户,与商机状态关联,(只有商机状态为成交才有值),其他时候为空字符串
            gridData: [], // 关联客户数组
            isDisable: true, // 是否只读
            modelList: [{
                    line: [{
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            title: "客户类型",
                            type: "text",
                            value: "",
                        },
                        {
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            title: "客户等级",
                            type: "select",
                            value: "",
                            options: serviceList.levelList
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "片区",
                            type: "select",
                            value: "",
                            options: serviceList.pianquList,
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "来源",
                            type: "selects-label",
                            value: "",
                            options: serviceList.sourceList,
                        },
                    ],
                },
                {
                    line: [{
                        horizontal: true,
                        isShow: true,
                        isDisable: false,
                        isSelect: false,
                        title: "客户标签",
                        type: "tags-select",
                        tempValue: "",
                        value: [],
                        options: serviceList.tipList
                    }, ],
                },
                {
                    line: [{
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "企业全称",
                            type: "input-but",
                            value: "",
                            buts: [{
                                text: "工商信息",
                                active: "get_companyInfo"
                            }]
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "企业税号",
                            type: "input",
                            value: "",
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "客户编号",
                            type: "input",
                            value: "",
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "增值税",
                            type: "select",
                            value: "",
                            options: [{
                                    label: "小规模纳税人",
                                    value: "小规模纳税人",
                                },
                                {
                                    label: "一般纳税人",
                                    value: "一般纳税人",
                                },
                            ],
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "行业分类",
                            type: "input",
                            value: "",
                        },
                        {
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            title: "所在地区",
                            type: "area",
                            value: [],
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "详细地址",
                            type: "input",
                            value: "",
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "联系电话",
                            type: "input",
                            value: "",
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "开户行",
                            type: "input",
                            value: "",
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "银行账号",
                            type: "input",
                            value: "",
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "开票客户",
                            type: "radio",
                            value: "N",
                            options: [{
                                label: "Y",
                                text: "是",
                            }, {
                                label: "N",
                                text: "否",
                            }, ],
                        },
                    ],
                },
                {
                    line: [{
                        horizontal: true,
                        isShow: true,
                        isDisable: false,
                        isSelect: false,
                        tempValue: "",
                        title: "其他服务人员",
                        type: "tags-select-obj",
                        value: ["180****8888"],
                        options: serviceList.employeeList,
                    }, ],
                },
            ],
        },
    }
    let index = 0
    if (data.length > 0) {
        for (let i = 0; i < clientInfoDrawerData.data.modelList.length - 1; i++) {
            let element = clientInfoDrawerData.data.modelList[i].line;
            for (let j = 0; j < element.length; j++) {
                let e = element[j];
                e.isShow = data[index].isShow
                e.isDisable = data[index].isDisable
                if (e.title == '来源') {
                    if (data[index].value.length == 0) {
                        e.value = ""
                    } else {
                        e.value = data[index].value[0]
                    }
                } else {
                    e.value = data[index].value
                }
                index++
            }
        }

        // 单独处理业务角色
        clientInfoDrawerData.data.modelList[3].line = []
        for (let i = 0; i < data[data.length - 1].value.length; i++) {
            let element = data[data.length - 1].value[i];
            let line = {
                id: element.id,
                horizontal: true,
                isShow: element.isShow,
                isDisable: element.isDisable,
                isSelect: false,
                tempValue: "",
                title: element.title,
                type: "tags-select-obj",
                value: element.value,
                options: list,
            }
            clientInfoDrawerData.data.modelList[3].line.push(line)
        }
    }

    return clientInfoDrawerData
}

/**
 * 格式化抽屉数据组件
 */
export function formatClientInfoDrawerData(data) {
    let array = []
    for (let index = 0; index < data.length - 1; index++) {
        let line = data[index].line;
        line.forEach(element => {
            let dd = {
                title: element.title == "开票客户" ? "是否是开票客户" : element.title,
                isShow: element.isShow,
                isDisable: element.isDisable,
                key: element.key,
                value: element.title == "来源" ? (element.value == "" ? [] : [element.value]) : (element.title == "所在地区" && element.value == null) ? [] : element.value,
            }
            array.push(dd)
        });
    }

    let ll = []
    for (let index = 0; index < data[data.length - 1].line.length; index++) {
        let element = data[data.length - 1].line[index];
        let d = {
            id: element.id,
            title: element.title,
            value: element.value
        }
        ll.push(d)
    }
    let d2 = {
        title: "业务角色",
        isDisable: false,
        isShow: true,
        key: "role",
        value: ll
    }
    array.push(d2)
    return array
}

/**
 * 获取客户信息假数据
 */
export function getClientInfoData() {
    let data = [{
        isShow: true,
        isDisable: false,
        value: "执行中", // 代账服务状态 

    }, {
        isShow: true,
        isDisable: false,
        value: "执行中", // 客户类型 

    }, {
        isShow: true,
        isDisable: false,
        value: "执行中", // 客户等级

    }, {
        isShow: true,
        isDisable: false,
        value: "执行中", // 片区 

    }, {
        isShow: true,
        isDisable: false,
        value: "执行中", // 来源 

    }, {
        isShow: true,
        isDisable: false,
        value: ["执行中"], // 客户标签 

    }, {
        isShow: true,
        isDisable: false,
        value: "执行中", // 企业全称  

    }, {
        isShow: true,
        isDisable: false,
        value: "执行中", // 所属部门 

    }, {
        isShow: true,
        isDisable: false,
        value: "执行中", // 企业税号 

    }, {
        isShow: true,
        isDisable: false,
        value: "执行中", // 客户编号 

    }, {
        isShow: true,
        isDisable: false,
        value: "执行中", // 增值税 

    }, {
        isShow: true,
        isDisable: false,
        value: "执行中", // 行业分类 

    }, {
        isShow: true,
        isDisable: false,
        value: ["安徽省", "合肥市", "蜀山区"], // 所在地区 

    }, {
        isShow: true,
        isDisable: false,
        value: "执行中", // 详细地址 

    }, {
        isShow: true,
        isDisable: false,
        value: [{ // 业务角色 
            isShow: true,
            isDisable: false,
            title: "客户经理",
            value: ["userId", "userId"],
        }, {
            isShow: true,
            isDisable: false,
            title: "服务顾问",
            value: ["userId"],
        }, {
            isShow: true,
            isDisable: false,
            title: "财务会计",
            value: ["userId"],
        }],

    }]
    return data
}

/**
 * 客户联系人抽屉json数据
 * @param {*} title 
 * @param {*} data 
 */
export function clientContactDrawerData(title, data, buttonAuth) {
    // 联系人的抽屉数据
    let item_data = {
        line: [{
            horizontal: true,
            isShow: true,
            isDisable: false,
            title: "姓名",
            type: "input-buts",
            value: "新建联系人",
            buts: [
                // {
                //     text: "公开",
                //     active: "gongkai"
                // },
                {
                    text: "删除",
                    active: "shanchu"
                },
            ]
        }, {
            horizontal: true,
            isShow: true,
            isDisable: false,
            title: "职位",
            type: "select",
            value: "老板",
            options: [{
                    label: "老板",
                    value: "老板",
                }, {
                    label: "法人",
                    value: "法人",
                },
                {
                    label: "股东",
                    value: "股东",
                },
                {
                    label: "会计",
                    value: "会计",
                },
                {
                    label: "其他",
                    value: "其他",
                },
            ],
        }, {
            horizontal: true,
            isShow: true,
            isDisable: false,
            title: "电话",
            type: "input",
            value: "",
        }, {
            horizontal: true,
            isShow: true,
            isDisable: false,
            title: "手机",
            type: "input",
            value: "",
        }, {
            horizontal: true,
            isShow: true,
            isDisable: false,
            title: "邮件",
            type: "input",
            value: "",
        }, {
            horizontal: true,
            isShow: true,
            isDisable: false,
            title: "备注",
            type: "input",
            value: "",
        }, ],
    }

    let clientInfoDrawerData = {
        title: "客户",
        flag: true,
        CardList: [{
                text: "客户信息",
                active: "info",
            }, {
                text: "联系人",
                active: "lianxiren",
            },
            {
                text: "合同",
                active: "hetong",
            },
        ],
        cardIndex: 1,
        buttonList: [],
        hasSetting: true,
        settingOperList: [{
            text: "删除客户",
            authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("删除客户", buttonAuth) : 1,
            active: "delete_client"
        }, {
            text: "添加关联客户",
            authority: 1,
            active: "adding_associated_customer"
        }, {
            text: "申请客户流失",
            authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("申请代账流失", buttonAuth) : 1,
            active: "run_off"
        }, {
            text: "暂停代账服务",
            authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("暂停代账服务", buttonAuth) : 1,
            active: "pause_serve"
        }, {
            text: "转为非代账",
            authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("转为非代账", buttonAuth) : 1,
            active: "zhuanweifeidaizhang"
        }, {
            text: "操作记录",
            authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("操作记录", buttonAuth) : 1,
            active: "operating_record"
        }, ],
        data: {
            title: title,
            customer: 0, // 关联客户,与商机状态关联,(只有商机状态为成交才有值),其他时候为空字符串
            isDisable: true, // 是否只读
            butArray: [{
                text: "新建联系人",
                active: "newContact",
                authority: 1
            }],
            modelList: [item_data],
        },
    }
    if (data.length > 0) {
        clientInfoDrawerData.data.modelList = []
        for (let i = 0; i < data.length; i++) {
            let element = data[i];
            item_data.line[0].value = element.name
            item_data.line[1].value = element.position
            item_data.line[2].value = element.phone
            item_data.line[3].value = element.mobile
            item_data.line[4].value = element.email
            item_data.line[5].value = element.des
            clientInfoDrawerData.data.modelList.push(JSON.parse(JSON.stringify(item_data)))
        }
    }
    return clientInfoDrawerData
}

/**
 * 格式化客户联系人抽屉数据
 * @param {*} data 
 */
export function formatClientContactDrawerData(data) {
    let array = []
    let returnData = {}
    let flag = true
    data.forEach(element => {
        let dd = {
            name: element.line[0].value, // 客户联系人姓名
            position: element.line[1].value, // 客户联系人职位(必填)
            phone: element.line[2].value, // 联系人电话
            mobile: element.line[3].value, // 联系人手机
            email: element.line[4].value, // 联系人邮件
            des: element.line[5].value // 备注
        }
        if (dd.phone != "" && !phoneCheck.mobilePhoneNumberCheck(dd.phone)) {
            flag = false
        }
        if (dd.mobile != "" && !phoneCheck.mobilePhoneNumberCheck(dd.mobile)) {
            flag = false
        }
        array.push(dd)
    });
    returnData = {
        list: array,
        flag: flag
    }
    return returnData
}

/**
 * 获取客户联系人假数据
 */
export function getClientContactData() {
    let data = [
        [{
            isShow: true,
            isDisable: false,
            value: "张三", // 姓名 
        }, {
            isShow: true,
            isDisable: false,
            value: "老板", // 职位 
        }, {
            isShow: true,
            isDisable: false,
            value: "13200002222", // 电话 
        }, {
            isShow: true,
            isDisable: false,
            value: "13222221111", // 手机 
        }, {
            isShow: true,
            isDisable: false,
            value: "123@123.com", // 邮件 
        }, {
            isShow: true,
            isDisable: false,
            value: "备注备注", // 备注 
        }, ],
        [{
            isShow: true,
            isDisable: false,
            value: "张三", // 姓名 
        }, {
            isShow: true,
            isDisable: false,
            value: "法人", // 职位 
        }, {
            isShow: true,
            isDisable: false,
            value: "13200002222", // 电话 
        }, {
            isShow: true,
            isDisable: false,
            value: "13222221111", // 手机 
        }, {
            isShow: true,
            isDisable: false,
            value: "123@123.com", // 邮件 
        }, {
            isShow: true,
            isDisable: false,
            value: "备注备注", // 备注 
        }, ]
    ]
    return data
}

/**
 * 客户合同抽屉json数据
 * @param {*} title 
 * @param {*} data 
 */
export function clientContractDrawerData(title, data, buttonAuth) {
    // 合同的抽屉数据

    let clientInfoDrawerData = {
        title: "客户",
        flag: true,
        CardList: [{
                text: "客户信息",
                active: "info",
            }, {
                text: "联系人",
                active: "lianxiren",
            },
            {
                text: "合同",
                active: "hetong",
            },
        ],
        cardIndex: 2,
        buttonList: [],
        hasSetting: true,
        settingOperList: [{
            text: "删除客户",
            authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("删除客户", buttonAuth) : 1,
            active: "delete_client"
        }, {
            text: "添加关联客户",
            authority: 1,
            active: "adding_associated_customer"
        }, {
            text: "申请客户流失",
            authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("申请代账流失", buttonAuth) : 1,
            active: "run_off"
        }, {
            text: "暂停代账服务",
            authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("暂停代账服务", buttonAuth) : 1,
            active: ""
        }, {
            text: "转为非代账",
            authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("转为非代账", buttonAuth) : 1,
            active: ""
        }, {
            text: "操作记录",
            authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("操作记录", buttonAuth) : 1,
            active: ""
        }, ],
        data: {
            title: title,
            customer: 0, // 关联客户,与商机状态关联,(只有商机状态为成交才有值),其他时候为空字符串
            isDisable: true, // 是否只读
            butArray: [{
                text: "新建合同",
                authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("新建合同", buttonAuth) : 1,
                active: "create_contract"
            }],
            modelList: [{
                line: [{
                    horizontal: true,
                    isShow: true,
                    isDisable: false,
                    title: "",
                    type: "table",
                    summary: false,
                    menu: [{
                        key: "contractNumber",
                        value: "合同编号",
                        type: "link",
                        width: "100",
                    }, {
                        key: "services",
                        value: "服务项",
                        type: "array",
                        width: "150",
                    }, {
                        key: "status",
                        value: "合同状态",
                        width: "100",
                        type: "text",
                    }, {
                        key: "money",
                        value: "合同金额",
                        type: "text",
                        width: "100",
                    }, {
                        key: "auditedIncome",
                        value: "已收款",
                        type: "text",
                        width: "100",
                    }, {
                        key: "accruedAssets",
                        value: "未收款",
                        type: "text",
                        width: "100",
                    }, {
                        key: "signTime",
                        value: "签订时间",
                        type: "text",
                        width: "100",
                    }, {
                        key: "startToEnd",
                        value: "合同期限",
                        type: "text",
                        width: "150",
                    }],
                    tableData: data
                }],
            }],
        },
    }
    if (data.length > 0) {
        clientInfoDrawerData.data.modelList[0].line[0].tableData = data
    }
    return clientInfoDrawerData
}

/**
 * 获取客户合同假数据
 */
export function getClientContractData() {
    let data = [{
        id: "",
        contractNum: "DZ11112222",
        serviceList: [{ text: "代", tip: "代代代" }, { text: "本", tip: "本本本" }],
        status: "执行中",
        contactMoney: "1200.00",
        signBillPerson: "张三",
        signBillTime: "2021-03-29"
    }, {
        id: "",
        contractNum: "DZ11112222",
        serviceList: [{ text: "代", tip: "代代代" }, { text: "本", tip: "本本本" }],
        status: "执行中",
        contactMoney: "1200.00",
        signBillPerson: "张三",
        signBillTime: "2021-03-29"
    }, ]
    return data
}

/**
 * 工作交接Dialog数据格式
 */
export function clientConnectDialogData(roleList, employeeList) {
    let dialogData = []

    roleList.forEach(role => {
        let dd = {
            type: "tips_selects",
            title: role.name,
            value: role.users,
            options: employeeList,
            inputVisible: false,
            tempValue: {}
        }
        dialogData.push(dd)
    });

    return dialogData
}

/**
 * 格式化工作交接dialog数据
 * @param {*} dialogDataArray 
 */
export function formatClientConnectDialogData(dialogDataArray) {
    let data = []
    dialogDataArray.forEach(element => {
        if (element.value.length > 0) {
            let dd = {
                name: element.title,
                users: element.value
            }
            data.push(dd)
        }
    });

    return data
}