/**
 * 手机号校验
 * @param {*} value 
 */
export function mobilePhoneNumberCheck(value) {
    let mobilePhoneNumberReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    let phoneNumberReg = /^(\d{3,4}-)?\d{7,8}$/
    let flag = true;
    if (value == "" || value == undefined || value == null) {
        flag = false;
    } else {
        if (!mobilePhoneNumberReg.test(value) &&  !phoneNumberReg.test(value) && value != "") {
            flag = false;
        } else {
            flag = true;
        }
    }
    console.log(flag);
    return flag;
}


// /**
//  * 邮箱校验
//  * @param {*} value 
//  */
// export function mailCheck(value) {
//     let reg = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/;
//     let flag = true;
//     if (value == "" || value == undefined || value == null) {
//         flag = false;
//     } else {
//         if (!reg.test(value) && value != "") {
//             flag = false;
//         } else {
//             flag = true;
//         }
//     }
//     return flag;
// }