<template>
  <!-- 抽屉 社保 -->
  <div id="company-drawer-table-social">
    <div class="company-drawer-table-social-search">
      <div class="company-drawer-table-social-search-item">
        <el-input
          v-model="searchData.name"
          placeholder="请输入姓名"
          @keyup.enter.native="initTableData"
        >
        </el-input>
      </div>
      <div class="company-drawer-table-social-search-item">
        <el-date-picker
          v-model="searchData.submitTime"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="提交开始日期"
          end-placeholder="提交结束日期"
          @change="initTableData"
        >
        </el-date-picker>
      </div>
      <div class="company-drawer-table-social-search-item">
        <el-select
          v-model="searchData.submitUserId"
          placeholder="请选择提交人"
          @change="initTableData"
          filterable
          clearable
        >
          <el-option
            v-for="item in searchData.empolyeeList"
            :key="item.userId"
            :label="item.userName"
            :value="item.userId"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="company-drawer-table-social-content">
      <el-table
        :data="tableData"
        highlight-current-row
        style="width: 100%"
        height="98%"
        size="small"
        :row-style="{ height: '60px' }"
        v-loading="loading"
      >
        <el-table-column type="index" width="40" fixed> </el-table-column>
        <el-table-column prop="name" label="姓名" width="100" fixed>
        </el-table-column>
        <el-table-column prop="remark" label="备注" width="150">
          <template slot-scope="scope">
            <span
              class="editRemark"
              v-if="scope.row.remark"
              @click="handleClick(scope.row, 1)"
            >
              <i class="el-icon-edit" style="margin-right: 5px"></i
              >{{ scope.row.remark }}</span
            >
            <span
              class="editRemark"
              @click="handleClick(scope.row, 1)"
              v-showFunction="{
                active: 'edit',
                array: allFunctionButtons,
              }"
              v-if="!scope.row.remark && scope.row.flag == -1"
            >
              <i class="el-icon-edit" style="margin-right: 5px"></i
              >点击编辑</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="flag" label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.flag == '0'" class="successColor"
              >已通过</span
            >
            <span v-else-if="scope.row.flag == '1'" class="dangerColor"
              >已驳回</span
            >
            <span v-else class="brandColor">未审核</span>
          </template>
        </el-table-column>
        <el-table-column prop="addOrDel" label="类型">
          <template slot-scope="scope">
            <span>{{ scope.row.addOrDel == 0 ? "增员" : "减员" }}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="submitUserName"
          label="提交人"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="submitTime"
          label="提交时间"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="auditUserName"
          label="审核人"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="auditTime"
          label="审核时间"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="" label="附件" width="170">
          <template slot-scope="scope">
            <div
              v-viewer="{ movable: false }"
              v-for="(item, index) in scope.row.annex"
              :key="index"
            >
              <img
                class="table-image"
                :src="item"
                alt=""
                style="margin-right: 5px"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="110" fixed="right">
          <template slot-scope="scope">
            <!-- <el-button
                  v-if="scope.row.flag == '-1'"
                  style="color: #67C23A"
                  type="text"
                  @click="handleClick(scope.row, 0)"
                  v-showFunction="{
                    active: 'check',
                    array: allFunctionButtons,
                  }"
                  >审核</el-button
                > -->
            <el-button
              v-if="scope.row.flag == '-1'"
              class="dangerColor"
              type="text"
              @click="handleClick(scope.row, 2)"
              size="mini"
              v-showFunction="{
                active: 'delete',
                array: allFunctionButtons,
              }"
              >删除</el-button
            >
            <el-button
              class="brandColor"
              type="text"
              @click="handleClick(scope.row, 3)"
              size="mini"
              v-showFunction="{
                active: 'detail',
                array: allFunctionButtons,
              }"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="company-drawer-table-social-pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageData.currentPage"
        :page-size="pageData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageData.total"
      >
      </el-pagination>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :clientName="dialogData.clientName"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @handleAvatarSuccess="handleAvatarSuccess"
        @deleteItem="deleteItem"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import * as taxData from "@/views/tax/js/tax.js";
import my_dialog from "@/components/dialog.vue";
export default {
  components: {
    my_dialog,
  },
  props: {
    tempClient: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      tabIndex: 0,
      loading: false,
      baseInfo: {},
      tableData: [],
      searchData: {
        input: "",
        name: "",
        addOrDel: "",
        flag: "-1",
        submitUserId: "",
        auditUserId: "",
        empolyeeList: [],
        submitTime: [],
        auditTime: [],
      },
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "socialCheck"
      ),
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "运单详情",
        buttonArray: [
          {
            text: "确认",
            active: "confirm",
          },
        ],
        data: [],
      },
      temSocialCheck: {},
      whetherAgencyHead: false,
      tabIndex: "-1",
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "socialCheck",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getEmployeeListForDownBox();
      });
    },
    getEmployeeListForDownBox() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/user/getEmployeeListForDownBox",
        requestData,
        (data) => {
          this.searchData.empolyeeList = data;
          this.initTableData();
        },
        (err) => {
          this.$message.error(err);
        }
      );
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      this.tableData = [];
      this.loading = true;
      let requestData = {
        clientId: this.tempClient.id,
        clientName: this.searchData.input, // 客户简称
        addOrDel: this.searchData.addOrDel, // 类型
        flag: this.searchData.flag, // 状态
        name: this.searchData.name, // 姓名
        submitUserId: this.searchData.submitUserId, // 提交人
        auditUserId: this.searchData.auditUserId, // 审核人
        submitTime: this.searchData.submitTime, // 提交时间
        auditTime: this.searchData.auditTime, // 审核时间
        pageSize: this.pageData.pageSize, // 每页条数
        currentPage: this.pageData.currentPage, // 当前页数
      };
      this.$request(
        "post",
        "/socialSecurityReview/pageByCondition",
        requestData,
        (data) => {
          this.tableData = data.records;
          this.tableData.forEach((item) => {
            if (item.annex) {
              item.annex = item.annex.split(",");
            }
          });
          this.pageData.total = data.total; // 获取总条目数
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    /**
     * 界面操作
     */
    handleClick(data, flag) {
      this.temSocialCheck = data;
      let requestData = {};
      switch (flag) {
        case 0:
          this.dialogData.width = "500px";
          this.dialogData.title = "社保审核";
          this.dialogData.clientName = this.temSocialCheck.name;
          this.dialogData.buttonArray = [
            {
              text: "驳回",
              active: "passApprove",
            },
            {
              text: "通过",
              active: "approve",
            },
          ];
          this.dialogData.data = [
            {
              title: "上传附件",
              type: "uploads",
              value: {
                value: [],
                action: this.$fileBaseurl + "/uploadImageByMultipartFile",
                headers: {
                  token: "95931b371eb64f4a9c862983ca8a5d15",
                },
                uploadParameter: {
                  systemName: "finance",
                  title: "社保审核通过附件",
                },
              },
            },
            {
              title: "备注",
              type: "textarea",
              value: "",
            },
          ];
          console.log("dialog数据", this.dialogData);
          this.dialogData.flag = true;
          break;
        case 1:
          // 编辑
          if (this.temSocialCheck.flag !== "-1") {
            return;
          }
          this.dialogData.width = "500px";
          this.dialogData.title = "社保备注";
          this.dialogData.clientName = this.temSocialCheck.name;
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "sure_edit",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              title: "备注",
              type: "textarea",
              value: this.temSocialCheck.remark,
            },
          ];
          console.log("dialog数据", this.dialogData);
          this.dialogData.flag = true;
          break;
        case 2:
          // 删除社保审核
          this.$confirm("确认要删除该审核信息吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              requestData = {
                ids: [],
              };
              requestData.ids.push(this.temSocialCheck.id);
              this.$request(
                "post",
                "/socialSecurityReview/delBatch",
                requestData,
                (data) => {
                  this.$operationRecords.saveOperationRecords(
                    this.temSocialCheck.clientId,
                    `社保审核删除-${this.temSocialCheck.name}`,
                    this.baseInfo,
                    "tax",
                    this,
                    (data2) => {
                      this.$emit("editClient", {
                        flag: "delete",
                        model: "social",
                      });
                      this.$message.success("审核已删除!");
                      this.dialogData.flag = false;
                      this.initTableData();
                    }
                  );
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
          break;
        case 3:
          if (this.temSocialCheck.flag == "-1") {
            // 查看社保提交详情
            this.dialogData.title = "社保审核详情";
            this.dialogData.width = "700px";
            this.dialogData.buttonArray = [
              {
                text: "审核",
                active: "check",
              },
              {
                text: "取消",
                active: "cancel",
              },
            ];

            this.dialogData.data = [
              {
                type: "add_social",
                data: {
                  name: this.temSocialCheck.name, // 姓名
                  identityCard: this.temSocialCheck.identityCard, // 身份证号
                  gender: this.temSocialCheck.gender, // 性别
                  phone: this.temSocialCheck.phone, // 手机号
                  businessDate: this.temSocialCheck.businessDate, // 业务年月
                  paymentDate: this.temSocialCheck.paymentDate, // 缴费年月
                  unitPaymentBase: this.temSocialCheck.unitPaymentBase
                    ? this.temSocialCheck.unitPaymentBase.toString()
                    : "", // 单位缴费基数
                  industrialInjuryAmountOfUnit: this.temSocialCheck
                    .industrialInjuryAmountOfUnit
                    ? this.temSocialCheck.industrialInjuryAmountOfUnit.toString()
                    : "", // 工伤单位
                  annex: this.temSocialCheck.annex, // 附件
                },
              },
            ];
            console.log("12312");
            this.dialogData.flag = true;
          } else {
            // 查看社保审核详情
            this.$request(
              "get",
              "/socialSecurityReviewRecord/getBySocialSecurityReviewId?socialSecurityReviewId=" +
                this.temSocialCheck.id,
              requestData,
              (data) => {
                this.dialogData.width = "500px";
                this.dialogData.title = "社保审核";
                this.dialogData.clientName = this.temSocialCheck.name;
                this.dialogData.buttonArray = [];
                this.dialogData.data = [
                  {
                    title: "完成附件",
                    type: "show_imgs",
                    value: JSON.parse(data.annex),
                  },
                  {
                    title: "完成备注",
                    type: "textarea_black",
                    value: data.remark,
                    rows: 5,
                    disabled: true,
                  },
                ];
                console.log("dialog数据", this.dialogData);
                this.dialogData.flag = true;
              },
              (errorMessage) => {
                this.$message.error(errorMessage);
              }
            );
          }
          break;
        default:
          break;
      }
    },
    /**
     * 抽屉数据或dialog数据操作事件
     */
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "check":
          // 审核
          this.handleClick(this.temSocialCheck, 0);
          break;
        case "approve":
          // 审核通过
          requestData = {
            socialSecurityReviewId: this.temSocialCheck.id, // 申报审核 id
            auditUserId: this.baseInfo.userId, // 操作人 id
            annex: this.dialogData.data[0].value.value
              ? JSON.stringify(this.dialogData.data[0].value.value)
              : "", // 完成附件
            remark: this.dialogData.data[1].value, // 完成备注
          };
          this.$request(
            "post",
            "/socialSecurityReview/complete",
            requestData,
            (data) => {
              this.$operationRecords.saveOperationRecords(
                this.temSocialCheck.clientId,
                `社保审核通过-${this.temSocialCheck.name}`,
                this.baseInfo,
                "tax",
                this,
                (data2) => {
                  this.$emit("editClient", {
                    flag: "delete",
                    model: "social",
                  });
                  this.$message.success("审核已通过!");
                  this.dialogData.flag = false;
                  this.initTableData();
                }
              );
            },
            (err) => {
              this.$message.error(err);
            }
          );
          break;
        case "sure_edit":
          // 确认编辑
          requestData = {
            id: this.temSocialCheck.id, // id
            remark: this.dialogData.data[0].value, // 备注
          };
          this.$request(
            "post",
            "/socialSecurityReview/updateSocialSecurityReview",
            requestData,
            (data) => {
              this.$message.success("编辑成功!");
              this.dialogData.flag = false;
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "passApprove":
          // 审核驳回
          requestData = {
            socialSecurityReviewId: this.temSocialCheck.id, // 申报审核 id
            auditUserId: this.baseInfo.userId, // 操作人 id
            annex: this.dialogData.data[0].value.value
              ? JSON.stringify(this.dialogData.data[0].value.value)
              : "", // 完成附件
            remark: this.dialogData.data[1].value, // 完成备注
          };
          this.$request(
            "post",
            "/socialSecurityReview/notComplete",
            requestData,
            (data) => {
              this.$operationRecords.saveOperationRecords(
                this.temSocialCheck.clientId,
                `社保审核驳回-${this.temSocialCheck.name}`,
                this.baseInfo,
                "tax",
                this,
                (data2) => {
                  this.$emit("editClient", {
                    flag: "delete",
                    model: "social",
                  });
                  this.$message.success("审核已驳回!");
                  this.dialogData.flag = false;
                  this.initTableData();
                }
              );
            },
            (err) => {
              this.$message.error(err);
            }
          );
          break;
        case "cancel":
          this.colseDialog();
          break;
        default:
          break;
      }
    },
    /**
     * tab切换事件
     */
    changeTab(index) {
      this.tabIndex = index;
      this.searchData.flag = index;
      this.initTableData();
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    /**
     * 社保审核图片上传成功
     */
    handleAvatarSuccess(response) {
      this.$message({
        message: "上传成功!",
        type: "success",
        duration: 1000,
      });
      this.dialogData.data[0].value.value.push(response.result.path);
    },
    deleteItem(index) {
      this.dialogData.data[0].value.value.splice(index, 1);
    },
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#company-drawer-table-social {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .company-drawer-table-social-search {
    width: 100%;
    height: auto;
    display: flex;
    margin-bottom: 10px;
    .company-drawer-table-social-search-item {
      width: 170px;
      min-width: 170px;
      height: 30px;
      margin-right: 10px;
      display: flex;
      align-items: center;
    }
  }
  .company-drawer-table-social-content {
    width: 100%;
    height: 100%;
    border-top: 1px solid #e9ebed;
    .table-image {
      width: 40px;
      height: 40px;
    }
    .editRemark {
      color: #409eff;
      cursor: pointer;
    }
    /deep/ .el-table--scrollable-x .el-table__body-wrapper {
      z-index: 1;
      &::-webkit-scrollbar {
        height: 6px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.2);
      }
    }
    /deep/ .el-table--scrollable-y .el-table__body-wrapper {
      x &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
  .company-drawer-table-social-pagination {
    width: 100%;
    height: auto;
  }
}
.login-dialog,
.face-dialog {
  text-align: left;
  /deep/ .el-dialog {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: calc(100% - 200px);
    max-width: calc(100% - 30px);

    .el-dialog__header {
      border-radius: 3px;
      background-color: #f5f8fa;
      display: flex;
      padding: 0;
      padding-left: 10px;
      align-items: center;
      height: 45px;
      border-bottom: 1px solid #ced4db;
    }
    .el-dialog__body {
      padding: 0;
      flex: 1;
      overflow: auto;
    }
    .el-dialog__footer {
      padding: 0;
      padding-right: 1rem;
      background-color: #f5f8fa;
      border-radius: 0 0 5px 5px;
      border-top: 1px solid #ced4db;
      height: 45px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
  /deep/ .el-tabs {
    .el-tabs__nav-scroll {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0;
    }
    .el-tabs__item {
      font-size: 17px;
      .is-active {
        color: #303130;
      }
    }
  }
  .login-dialog-note {
    position: relative;
    width: 440px;
    padding: 10px 50px 0 30px;
    box-sizing: border-box;
    /deep/ .el-form-item {
      margin-bottom: 15px;
    }
    .button {
      position: absolute;
      bottom: 3px;
      right: -57px;
    }
  }
  .face-dialog-content {
    padding: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    .text {
      font-size: 14px;
      color: @activeColor;
      margin-top: 20px;
    }
  }
}

.red-dialog {
  .red-dialog-line {
    display: flex;
    align-items: center;
  }
}
</style>
<style lang="less" scoped></style>
