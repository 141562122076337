<template>
  <div id="advances-item">
    <!-- 预收款情况表格 -->
    <div class="advances-item-line head">
      <div class="advances-item-line-item">支付时间</div>
      <div class="advances-item-line-item">支付金额</div>
      <div class="advances-item-line-item">收款账户</div>
      <div class="advances-item-line-item">收款状态</div>
      <div class="advances-item-line-item">操作</div>
    </div>
    <div class="advances-item-line" v-for="(item, index) in value" :key="index">
      <div class="advances-item-line-item">{{ item.time }}</div>
      <div class="advances-item-line-item">{{ item.money }}</div>
      <div class="advances-item-line-item">{{ item.account }}</div>
      <div class="advances-item-line-item">{{ item.status }}</div>
      <div class="advances-item-line-item">
        <div @click="show(item, index)">查看</div>
        <div @click="deleteItem(item, index)">删除</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["advancesItemData"],
  data() {
    return {
      value: this.advancesItemData,
    };
  },
  watch: {
    advancesItemData() {
      //父组件值变化->>子组件也变化
      this.value = this.advancesItemData;
    },
    value(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("showAdvances", newvalue);
    },
  },
  methods: {
    show(data, index) {
      this.$emit("showItem", { data, index });
    },
    deleteItem(data, index) {
      this.$emit("deleteItem", { data, index });
    },
  },
};
</script>

<style lang="less" scoped>
#advances-item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .advances-item-line {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid @boderColor;
    border-left: 1px solid @boderColor;
    .advances-item-line-item {
      flex: 1;
      width: 0;
      height: 100%;
      border-right: 1px solid @boderColor;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: @contentFontSize4;
      div {
        width: auto;
        padding: 0 5px;
        margin: 0 5px;
        height: 23px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        color: @activeColor;
        border-radius: 3px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .head {
    background-color: rgba(190, 212, 221, 0.438);
    border-top: 1px solid @boderColor;
  }
}
</style>