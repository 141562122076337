<template>
  <div id="profession">
    <!-- 业务模板 -->
    <div class="profession-title" v-if="value != ''">
      <div class="profession-title-text">{{ value.name }}</div>
      <div class="profession-title-but" v-if="!value.is_edit">
        <el-button
          v-for="(item, index) in value.butArray"
          plain
          :type="item.type"
          :key="index"
          size="small"
          :icon="item.icon"
          v-show="item.flag < 3"
          @click="professionOper(item.active)"
          >{{ item.title }}</el-button
        >
      </div>
      <div class="profession-title-but" v-else>
        <el-button
          plain
          type="primary"
          size="small"
          icon="el-icon-check"
          @click="professionOper('submit')"
          >提交</el-button
        >
        <el-button
          plain
          type="danger"
          size="small"
          icon="el-icon-close"
          @click="professionOper('cancel')"
          >取消</el-button
        >
      </div>
    </div>

    <div class="profession-content" v-if="value != ''">
      <div class="profession-content-model">
        <div class="profession-content-model-line">
          <div class="profession-content-model-line-icon">
            <i class="el-icon-menu"></i>
          </div>
          <div class="profession-content-model-line-title">业务类型名称</div>
          <div
            class="profession-content-model-line-content"
            v-if="!value.is_edit"
          >
            {{ value.name }}
          </div>
          <div class="profession-content-model-line-content" v-else>
            <el-input
              v-model="value.name"
              placeholder="请输入业务类型名称"
            ></el-input>
          </div>
        </div>

        <div class="profession-content-model-line">
          <div class="profession-content-model-line-icon">
            <i class="el-icon-s-tools"></i>
          </div>
          <div class="profession-content-model-line-title">作用部门</div>
          <!-- <div
            class="profession-content-model-line-content"
            v-if="!value.is_edit"
          >
            {{ value.departmentId }}
          </div> -->
          <div class="profession-content-model-line-content">
            <el-select
              v-model="value.departmentId"
              placeholder="请选择"
              filterable
              :disabled="!value.is_edit"
            >
              <el-option
                v-for="item in departmentList"
                :key="item.departmentId"
                :label="item.departmentName"
                :value="item.departmentId"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="profession-content-model-line">
          <div class="profession-content-model-line-icon">
            <i class="el-icon-s-help"></i>
          </div>
          <div class="profession-content-model-line-title">服务类别</div>
          <div
            class="profession-content-model-line-content"
            v-if="!value.is_edit"
          >
            {{ value.serviceType.typeName }}
          </div>
          <div class="profession-content-model-line-content" v-else>
            <el-select
              v-model="value.serviceType"
              placeholder="请选择"
              value-key="id"
              filterable
              @change="getAllServiceItemTypeList($event, true)"
            >
              <el-option
                v-for="item in serveType"
                :key="item.id"
                :label="item.typeName"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="profession-content-model-line">
          <div class="profession-content-model-line-icon">
            <i class="el-icon-menu"></i>
          </div>
          <div class="profession-content-model-line-title">服务产品</div>
          <div
            class="profession-content-model-line-content"
            v-if="!value.is_edit"
          >
            {{ value.serviceItemName }}
          </div>
          <div class="profession-content-model-line-content" v-else>
            <el-select
              style="width: 600px;"
              v-model="value.serviceItemId"
              filterable
              multiple
              @change="changeServiceItemId"
            >
              <el-option
                v-for="item in serveItemType"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="profession-content-model-line">
          <div class="profession-content-model-line-icon">
            <i class="el-icon-date"></i>
          </div>
          <div class="profession-content-model-line-title">业务类型备注</div>
          <div
            class="profession-content-model-line-content"
            v-if="!value.is_edit"
          >
            {{ value.des }}
          </div>
          <div class="profession-content-model-line-content" v-else>
            <el-input
              v-model="value.des"
              placeholder="请输入业务类型备注"
            ></el-input>
          </div>
        </div>

        <div class="profession-content-model-line">
          <div class="profession-content-model-line-icon">
            <i class="el-icon-edit-outline"></i>
          </div>
          <div
            class="profession-content-model-line-title"
            style="white-space: pre-wrap"
          >
            项目要求
          </div>
          <div
            class="profession-content-model-line-content"
            style="white-space: pre-wrap"
            v-html="value.projectRequire"
            v-if="!value.is_edit"
          ></div>
          <div
            class="profession-content-model-line-content"
            style="white-space: pre-wrap"
            v-else
          >
            <el-input
              type="textarea"
              v-model="value.projectRequire"
              :rows="6"
              placeholder="请输入项目要求"
            ></el-input>
          </div>
        </div>

        <div class="profession-content-model-line">
          <div class="profession-content-model-line-icon">
            <i class="el-icon-edit-outline"></i>
          </div>
          <div class="profession-content-model-line-title">所需资料</div>
          <div
            class="profession-content-model-line-content"
            style="white-space: pre-wrap"
            v-html="value.information"
            v-if="!value.is_edit"
          ></div>
          <div
            class="profession-content-model-line-content"
            style="white-space: pre-wrap"
            v-else
          >
            <el-input
              type="textarea"
              v-model="value.information"
              :rows="6"
              placeholder="请输入所需资料"
            ></el-input>
          </div>
        </div>

        <div class="profession-content-model-line">
          <div class="profession-content-model-line-icon">
            <i class="el-icon-edit-outline"></i>
          </div>
          <div class="profession-content-model-line-title">审核开关</div>
          <div class="profession-content-model-line-content">
            <el-switch v-model="value.isCheck.flag" :disabled="!value.is_edit">
            </el-switch>
            <span>{{ value.isCheck.des }}</span>
          </div>
        </div>
      </div>

      <!-- <div class="profession-content-model">
        <div class="profession-content-model-line">
          <div class="profession-content-model-line-icon">
            <i class="el-icon-s-ticket"></i>
          </div>
          <div class="profession-content-model-line-title">提成设置</div>
          <div class="profession-content-model-line-content">
            {{ value.royalty }}
          </div>
        </div>
      </div> -->

      <div class="profession-content-model">
        <div class="profession-content-model-line">
          <div class="profession-content-model-line-icon">
            <i class="el-icon-s-operation"></i>
          </div>
          <div class="profession-content-model-line-title">项目步骤</div>
          <div class="profession-content-model-line-content">
            编辑步骤只会影响新创建的项目,进行中和已完成的项目,不会出现变化。
          </div>
          <div class="is-active"></div>
          <div
            class="profession-content-model-line-content-but"
            v-if="value.is_edit"
          >
            <i class="el-icon-circle-plus-outline" @click="addStep"></i>
          </div>
        </div>
        <div class="profession-content-model-line-table">
          <div class="table-line title">
            <div class="table-line-cell"></div>
            <div class="table-line-cell">步骤名称</div>
            <div class="table-line-cell">步骤说明</div>
            <!-- <div class="table-line-cell">执行角色</div> -->
            <div class="table-line-cell">执行人</div>
            <div class="table-line-cell">是否添加下单人</div>
            <div class="table-line-cell">业务角色</div>
            <div class="table-line-cell">预计提成</div>
            <div class="table-line-cell" v-if="value.is_edit">操作</div>
          </div>
          <div
            class="table-line"
            v-for="(item, index) in value.step"
            :key="index"
          >
            <div class="table-line-cell">步骤{{ index + 1 }}</div>
            <div class="table-line-cell">{{ item.name }}</div>
            <div class="table-line-cell">{{ item.des }}</div>
            <div class="table-line-cell">
              <div v-for="(it, ii) in item.users" :key="it.id">
                <span v-if="ii > 0">、</span>{{ it.label }}
              </div>
            </div>
            <div class="table-line-cell">
              <span>{{ item.oneself ? "是" : "否" }}</span>
            </div>
            <div class="table-line-cell">
              <div v-for="(it, ii) in item.clientRole" :key="ii">
                <span v-if="ii > 0">、</span>{{ it.label }}
              </div>
            </div>
            <div class="table-line-cell" v-if="value.is_edit">
              <el-input v-model="item.money"></el-input>
            </div>
            <div class="table-line-cell" v-else>{{ item.money }}</div>
            <div class="table-line-cell" v-if="value.is_edit">
              <i class="el-icon-top" @click="upStep(item, index)"></i>
              <i class="el-icon-bottom" @click="downStep(item, index)"></i>
              <i
                class="el-icon-remove-outline"
                @click="removeStep(item, index)"
              ></i>
              <i class="el-icon-edit" @click="editStep(item, index)"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="profession-content-model">
        <div class="profession-content-model-line">
          <div class="profession-content-model-line-icon">
            <i class="el-icon-notebook-2"></i>
          </div>
          <div class="profession-content-model-line-title">项目成果</div>
          <div
            class="profession-content-model-line-content"
            style="white-space: pre-wrap"
            v-html="value.achievement"
            v-if="!value.is_edit"
          ></div>
          <div
            class="profession-content-model-line-content"
            style="white-space: pre-wrap"
            v-else
          >
            <el-input
              type="textarea"
              v-model="value.achievement"
              :rows="6"
              placeholder="请输入所需资料"
            ></el-input>
          </div>
        </div>
      </div>
    </div>
    <div class="profession-content" v-else>暂无业务类型,请先添加!!!</div>
  </div>
</template>

<script>
export default {
  props: ["professionData"],
  // props: {
  //   professionData: {
  //     type: Object,
  //     default: function () {
  //       return {
  //         id: "",
  //         name: "", // 业务类型名称
  //         des: "", // 业务类型备注
  //         // 项目要求
  //         projectRequire: "", // 所需资料
  //         departmentId: "", // 作用分公司ID
  //         departmentList: [], // 所有分公司
  //         information: "",
  //         isCheck: {
  //           // 审核开关
  //           flag: true,
  //           des: "",
  //         },
  //         // 项目成果
  //         achievement: "",
  //         royalty: "", // 提成设置
  //         step: [], // 项目步骤
  //         butArray: [],
  //         is_edit: false, // 是否开启编辑
  //       };
  //     },
  //   },
  //   allFunctionButtons: {
  //     type: Array,
  //     default: function () {
  //       return [];
  //     },
  //   },
  // },
  data() {
    return {
      baseInfo: {},
      serveType: [],
      serveItemType: [],
      value: this.professionData,
      departmentList: [],
    };
  },
  watch: {
    professionData() {
      //父组件值变化->>子组件也变化
      this.value = this.professionData;
    },
    value(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {
    /**
     * 获取所有服务项类别
     */
    getAllServiceTypeList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/serveType/getAllServeType",
        requestData,
        (data) => {
          this.serveType = data;
          this.getBranchList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有服务项子类别
     */
    getAllServiceItemTypeList(e, flag) {
      this.$request(
        "get",
        "/servicesItem/listByType?typeId=" + this.value.serviceType.id,
        {},
        (data) => {
          this.serveItemType = data;
          if (flag) {
            this.value.serviceItemId = [];
          }
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    changeServiceItemId(e) {
      this.$forceUpdate();
    },
    /**
     * 4.2.2 获取分公司列表
     */
    getBranchList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/department/getBranchList",
        requestData,
        (data) => {
          this.departmentList = data;
        },
        (err) => {
          this.$message.error(err);
        }
      );
    },
    change: function(content) {
      var reg = /[;；:：]/g;
      content = content.replace(reg, "$&\r\n");
      return content;
    },
    /**
     * 操作
     */
    professionOper(active) {
      switch (active) {
        case "edit":
          // 编辑
          this.value.is_edit = true;
          if (this.value.serviceType.id) {
            this.getAllServiceItemTypeList("", false);
          }
          break;
        case "delete":
          // 删除
          this.$emit("deleteProfession", this.value);
          break;
        case "submit":
          // 提交
          this.$emit("submitProfession", this.value);
          break;
        case "cancel":
          // 取消编辑或取消提交
          this.$emit("cancelProfession");
          break;
        default:
          break;
      }
    },
    /**
     * 编辑步骤
     */
    editStep(data, index) {
      this.$emit("editStep", { data, index });
    },
    /**
     * 添加步骤
     */
    addStep() {
      this.$emit("addStep");
    },
    /**
     * 移除步骤
     */
    removeStep(data, index) {
      this.value.step.splice(index, 1);
    },
    /**
     * 上移步骤
     */
    upStep(data, index) {
      if (index == 0) {
        return;
      }
      let temp = data;
      this.$set(this.value.step, index, this.value.step[index - 1]);
      this.$set(this.value.step, index - 1, temp);
    },
    /**
     * 下移步骤
     */
    downStep(data, index) {
      // this.$emit("downStep",{data,index});
      if (index == this.value.step.length - 1) {
        return;
      }
      let temp = data;
      this.$set(this.value.step, index, this.value.step[index + 1]);
      this.$set(this.value.step, index + 1, temp);
    },
  },
  mounted() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getAllServiceTypeList();
  },
};
</script>

<style lang="less" scoped>
#profession {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .profession-title {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
    border-bottom: 1px solid @boderColor;
    // margin-bottom: 10px;
    .profession-title-text {
      font-size: @contentFontSize3;
    }
    .profession-title-but {
      width: auto;
      height: 100%;
      display: flex;
      font-size: @contentFontSize4;
      align-items: center;
      .but {
        width: auto;
        padding: 0 20px;
        box-sizing: border-box;
        border: 1px solid @activeColor;
        background-color: @activeBackground;
        color: @activeColor;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: @contentFontSize4;
        cursor: pointer;
        border-radius: 5px;
        margin: 0 5px;
        i {
          margin-right: 5px;
        }
      }
      .is-disable {
        border-color: @activeBackground3;
        color: @editColor;
        cursor: not-allowed;
      }
    }
  }

  .profession-content {
    width: 100%;
    flex: 1;
    height: 0;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    &::-webkit-scrollbar {
      width: 1px;
    }

    .profession-content-model {
      width: 98%;
      height: auto;
      border: 1px solid @boderColor;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      margin-bottom: 10px;
      .profession-content-model-line {
        width: 100%;
        height: auto;
        min-height: 40px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid @boderColor;
        padding: 2px 0;
        box-sizing: border-box;
        &:nth-last-child(1) {
          border: none;
        }
        .profession-content-model-line-icon {
          width: 30px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgb(141, 141, 141);
        }
        .profession-content-model-line-title {
          width: 100px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: @contentFontSize4;
          color: rgb(141, 141, 141);
        }
        .profession-content-model-line-content {
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: @contentFontSize4;
          line-height: 25px;
          text-align: left !important;
          span {
            margin-left: 10px;
          }
        }
        .is-active {
          flex: 1;
        }
        .profession-content-model-line-content-but {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: 0.3s;
          &:hover {
            color: @activeColor;
          }
        }
      }
      .profession-content-model-line-table {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        font-size: @contentFontSize4;
        .table-line {
          width: 100%;
          height: 30px;
          display: flex;
          align-content: center;
          border-top: 1px solid @boderColor;
          &:nth-child(1) {
            border: none;
          }
          .table-line-cell {
            flex: 3;
            width: 0;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-left: 1px solid @boderColor;
            box-sizing: border-box;
            &:nth-child(1) {
              border: none;
              flex: 1;
            }
            &:nth-last-child(1) {
              flex: 2;
            }
            i {
              font-size: 16px;
              cursor: pointer;
              transition: 0.3s;
              margin: 0 10px;
              &:hover {
                color: @activeColor;
              }
            }
          }
        }
        .title {
          background-color: rgba(167, 167, 167, 0.199);
        }
      }
    }
  }
}
</style>
