<template>
  <!-- 税务统计 -->
  <div id="tax-info">
    <div class="tax-info-screen">
      <div class="tax-info-screen-line">
        <div class="tax-info-screen-fiftrate">
          <el-popover
            placement="bottom-start"
            width="250"
            trigger="manual"
            :visible-arrow="false"
            v-model="popoData.popoVisible"
          >
            <div class="popover-search">
              <popo_search
                :list="popoData.list"
                @cancel="popoCanael"
                @reset="popoReset"
                @makeSure="popoMakeSure"
              ></popo_search>
            </div>
            <div slot="reference" @click="popoverOpen">
              筛选 <i class="el-icon-s-operation"></i>
            </div>
          </el-popover>
        </div>
        <div class="tax-info-screen-search">
          <el-input
            v-model="searchData.input"
            placeholder="请输入客户全称"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="tax-info-screen-search">
          <el-cascader
            v-model="searchData.department"
            placeholder="请选择部门"
            :options="searchData.departTreeData"
            @change="initTableData"
            clearable
            :show-all-levels="false"
            :props="{ checkStrictly: true, value: 'id' }"
            filterable
          ></el-cascader>
        </div>
        <div class="tax-info-screen-search">
          <el-select
            v-model="searchData.userId"
            @change="selectInitTableData"
            filterable
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="tax-info-screen-search">
          <el-select
            v-model="searchData.addedValueTaxType"
            @change="initTableData"
            filterable
            clearable
          >
            <el-option label="一般纳税人" value="一般纳税人"></el-option>
            <el-option label="小规模纳税人" value="小规模纳税人"></el-option>
          </el-select>
        </div>
        <div class="tax-info-screen-search">
          <el-date-picker
            v-model="searchData.date"
            type="monthrange"
            range-separator="至"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
            value-format="yyyy-MM"
            :clearable="false"
            @change="initTableData"
          >
          </el-date-picker>
        </div>
        <div class="tax-info-screen-active"></div>
        <div
          class="tax-info-screen-but-boder"
          v-loading="pictLoading"
          element-loading-background="rgba(0, 0, 0, 0.2)"
          element-loading-spinner="el-icon-loading"
          @click="handleClick('', 0)"
          v-showFunction="{
            active: 'import',
            array: allFunctionButtons,
          }"
        >
          导入
        </div>
        <!-- <div class="tax-info-screen-but-boder" @click="handleClick('', 1)">
          导出
        </div> -->
      </div>
      <div class="tax-info-screen-line" v-if="searchData.screenList.length > 0">
        <div class="title">筛选条件:</div>
        <el-tag
          v-for="(tag, index) in searchData.screenList"
          :key="index"
          closable
          size="small"
          @close="closeScreen(index)"
        >
          {{ tag.name }}
        </el-tag>
      </div>
    </div>

    <div class="tax-table">
      <div class="tax-info-table-card">
        <!-- <div class="tax-info-table-card-tabs">
          <div class="tax-info-table-card-active"></div>
        </div> -->
        <div class="tax-info-content">
          <el-table
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            height="98%"
            v-loading="loading"
            :row-style="{ height: '60px' }"
            @sort-change="sortChange"
            show-summary
            :summary-method="getSummaries"
          >
            <el-table-column type="index" width="40" fixed> </el-table-column>
            <el-table-column label="客户名称" width="250" fixed>
              <template slot-scope="scope">
                <span
                  @click.stop="handleClick(scope.row, 1)"
                  style="color: #40a3ff; cursor: pointer;"
                  >{{ scope.row.clientName }}</span
                ></template
              ></el-table-column
            >
            <el-table-column
              prop="clientNum"
              label="客户编号"
              width="120"
              fixed
            ></el-table-column>
            <el-table-column
              prop="addedValueTaxType"
              label="公司类型"
              width="120"
              fixed
            ></el-table-column>
            <el-table-column
              prop="invoicingAmount"
              label="发票收入"
              width="120"
              sortable="custom"
            ></el-table-column>
            <el-table-column
              prop="totalTaxAmount"
              label="全部税额"
              width="120"
              sortable="custom"
            ></el-table-column>
            <el-table-column
              prop="valueAddedTax"
              label="增值税及附加"
              width="140"
              sortable="custom"
            ></el-table-column>
            <el-table-column
              prop="stampDuty"
              label="印花税"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="enterpriseTax"
              label="企税月季报"
              width="120"
              sortable="custom"
            ></el-table-column>
            <el-table-column
              prop="personalIncomeTax"
              label="个税"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="socialSecurity"
              label="社保"
              width="120"
              sortable="custom"
            ></el-table-column>
            <el-table-column
              prop="constructCost"
              label="文化事业建设费"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="generalDeclaration"
              label="通用申报"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="disabilityBenefits"
              label="残保金"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="consumptionTax"
              label="消费税及附加"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="otherTaxes"
              label="其他税种"
              width="120"
            ></el-table-column>
          </el-table>
        </div>
        <div class="tax-info-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :clientName="dialogData.clientName"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import * as taxData from "@/views/tax/js/tax.js";
import my_dialog from "@/components/dialog.vue";
import popo_search from "@/components/popo-search.vue";
// import NProgress from "nprogress";
// import "nprogress/nprogress.css";
// NProgress.configure({ showSpinner: false });
// NProgress.configure({ parent: "#tax-info .tax-table" });
export default {
  components: {
    my_dialog,
    popo_search,
  },
  data() {
    return {
      pictLoading: false,
      tabIndex: 0,
      loading: false,
      baseInfo: {},
      tableData: [],
      tableData2: [{}, {}, {}],
      popoData: {
        popoVisible: false,
        list: [],
      },
      searchData: {
        input: "",
        date: [
          this.$formatTime(
            new Date(new Date().toLocaleDateString()).getTime() -
              31 * 24 * 3600 * 1000,
            "YYYY-MM"
          ),
          this.$formatTime(
            new Date(new Date().toLocaleDateString()).getTime() -
              31 * 24 * 3600 * 1000,
            "YYYY-MM"
          ),
        ],
        addedValueTaxType: "",
        area: "",
        areaList: [],
        customerSourceList: [],
        department: "",
        departTreeData: [],
        tempUserId: "",
        userList: [],
        userIdList: [],
        screenList: [],
      },
      defaultSort: { prop: "", order: "" },
      allFunctionButtons: this.$featureData.getAllFunctionButtons("taxInfo"),
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
        pages: 0, // 总页数
      },
      // ClientPageData: {
      //   // 弹框分页参数
      //   currentPage: 1, // 当前页数
      //   total: 0, // 总条目数
      //   pageSize: 10, // 每页条数
      //   pages: 0, // 总页数
      // },
      dialogData: {
        flag: false,
        width: "30%",
        title: "运单详情",
        clientName: "",
        buttonArray: [
          {
            text: "确认",
            active: "confirm",
          },
        ],
        data: [],
      },
      clientList: [],
      tempExpressageMaintain: {},
      whetherAgencyHead: false,
      defaultSort: {
        prop: "invoicing_amount",
        order: "descending",
      },
      tempTaxData: {},
      clientTableData: [],
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "taxInfo",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getDepartmentTreeByUserId();
        // this.initTableData();
      });
    },
    /**
     * 获取部门
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
          this.getEmployeeList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.searchData.userList = [];
          data.forEach((element) => {
            let dd = {
              name: element.userName,
              id: element.userId,
            };
            this.searchData.userIdList.push(element.userId);
            this.searchData.userList.push(dd);
          });
          this.searchData.userList.splice(0, 0, {
            id: "all",
            name: "全部人员",
          });
          this.searchData.userId = this.baseInfo.userId;
          this.selectInitTableData(this.baseInfo.userId);
        },

        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      this.tableData = [];
      this.loading = true;
      let requestData = {
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
        clientName: this.searchData.input,
        date: this.searchData.date, // 月份
        addedValueTaxType: this.searchData.addedValueTaxType, // 公司类型
        area: this.searchData.area, // 片区
        sortProp: this.defaultSort.prop, // 手动排序项
        sortOrder: this.defaultSort.order, // 手动排序规则 ascending descending ""
        userIdList: this.searchData.tempUserId, // 员工id集合
        department:
          this.searchData.department.length > 0
            ? this.searchData.department[this.searchData.department.length - 1]
            : "",
        screenList: this.searchData.screenList, // 其余筛选条件
      };
      this.$request(
        "post",
        "/taxInfo/page",
        requestData,
        (data) => {
          this.tableData = data.records;
          this.pageData.total = data.total; // 获取总条目数
          this.pageData.pages = data.pages; // 总页数
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },

    /**
     * 界面操作
     */
    handleClick(data, flag) {
      this.tempTaxData = data;
      let requestData = {};
      switch (flag) {
        case 0:
          // 导入
          if (this.pictLoading == true) {
            this.$message.error("正在导入资源,请耐心等待");
            return;
          }
          this.dialogData.title = "导入税务";
          this.dialogData.data = taxData.importTaxDialogData(this);
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "makeSure_import_tax",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.flag = true;

          break;
        case 1:
          // 查询点击客户全部税务数据
          // 获取今年的一月
          let january = this.$formatTime(new Date().setMonth(0), "YYYY-MM");
          // 获取今年的十二月
          let december = this.$formatTime(new Date().setMonth(11), "YYYY-MM");
          requestData = {
            // currentPage: this.ClientPageData.currentPage, // 当前页
            // pageSize: this.ClientPageData.pageSize, // 页码
            clientId: this.tempTaxData.clientId, // 客户id
            date: [january, december], // 日期筛选项
          };
          this.$request(
            "post",
            "/taxInfo/queryByClient",
            requestData,
            (data) => {
              this.clientTableData = data;
              this.dialogData.width = "700px";
              this.dialogData.title = "税务详情";
              this.dialogData.clientName = this.tempTaxData.clientName;
              this.dialogData.buttonArray = [];
              (this.dialogData.data = [
                {
                  type: "time_month_scope",
                  value: [january, december],
                  active: "monthRange",
                },
                {
                  type: "table-summary",
                  height: "500",
                  data: this.clientTableData,
                  props: [
                    {
                      prop: "dataDate",
                      label: "时间",
                    },
                    {
                      prop: "invoicingAmount",
                      label: "发票收入",
                    },
                    {
                      prop: "totalTaxAmount",
                      label: "全部税额",
                    },
                    {
                      prop: "valueAddedTax",
                      label: "增值税及附加",
                    },
                    {
                      prop: "socialSecurity",
                      label: "社保",
                    },
                  ],
                },
              ]),
                console.log("不可用金额dialog显示:", this.dialogData.data);
              this.dialogData.flag = true;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
              this.loading = false;
            }
          );
          break;
        default:
          break;
      }
    },
    /**
     * 抽屉数据或dialog数据操作事件
     */
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "makeSure_import_tax":
          // 确定导入税务模版
          requestData = {
            date: this.dialogData.data[0].value,
            url: this.dialogData.data[1].value.url,
          };
          if (requestData.date == "") {
            this.$message.error("请先选择要导入的月份!");
            return;
          }
          if (requestData.url == "") {
            this.$message.error("请先选择要导入的文件!");
            return;
          }
          console.log("导入资源数据:", requestData);
          // NProgress.start();
          this.pictLoading = true;
          this.$request(
            "post",
            "/taxInfo/importExcel",
            requestData,
            (data) => {
              this.$message.success(data);
              this.dialogData.flag = false;
              this.pictLoading = false;
              // NProgress.done();
              this.initTableData();
            },
            (err) => {
              this.$message({
                message: err,
                type: "error",
              });
              this.dialogData.flag = false;
              this.pictLoading = false;
              // NProgress.done();
              this.initTableData();
            }
          );
          this.dialogData.flag = false;
          break;
        case "monthRange":
          requestData = {
            // currentPage: this.ClientPageData.currentPage, // 当前页
            // pageSize: this.ClientPageData.pageSize, // 页码
            clientId: this.tempTaxData.clientId, // 客户id
            date: this.dialogData.data[0].value, // 日期筛选项
          };
          this.$request(
            "post",
            "/taxInfo/queryByClient",
            requestData,
            (data) => {
              this.dialogData.data[1].data = data;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
              this.loading = false;
            }
          );
          break;
        case "cancel":
          this.colseDialog();
          break;
        default:
          break;
      }
    },

    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    getSummaries(param) {
      const { columns, data } = param;

      const sums = [];
      columns.forEach((column, index) => {
        if (index == 1) {
          sums[index] = "合计";
          return;
        }
        if (index == 0 || index == 2) {
          sums[index] = "";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            let sum = Number(Number(prev) + Number(curr)).toFixed(2); //toFixed(2)数据项保留两位小数
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = Math.round(sums[index] * 100) / 100;
        } else {
          sums[index] = "N/A";
        }
      });

      return sums;
    },
    // lazyLoading() {
    //   let dom = document.querySelector(".el-table__body-wrapper");
    //   dom.addEventListener("scroll", (v) => {
    //     const scrollDistance =
    //       dom.scrollHeight - dom.scrollTop - dom.clientHeight;
    //     // console.log("鼠标滑动-scrollDistance", scrollDistance);
    //     // if (scrollDistance <= 0) {  //分辨率问题，如果设置 100% ，滑倒最底部，scrollDistance的值 可能为 0.201 到 -0.201
    //     if (scrollDistance < 1) {
    //       // 滑动到表格底部 可以请求接口
    //       this.tableData = [...this.tableData, ...this.tableData2];
    //       // this.pageData.pageSize =
    //       //   this.pageData.pageSize * this.pageData.currentPage++;
    //       // this.initTableData();
    //       // if (this.pageData.currentPage < this.totalPage) {
    //       //   //当前页数小于总页数就请求
    //       //   this.pageData.currentPage++; //当前页数自增
    //       // }
    //       // if (this.tableData.length >= this.pageData.total) {
    //       //   // 到达底部 数据加载完毕
    //       // }
    //     }
    //   });
    // },
    /**
     * 客户地址导入成功
     */
    // handleAvatarSuccess(response) {
    //   this.$message({
    //     message: "客户地址导入成功!",
    //     type: "success",
    //     duration: 1000,
    //   });
    //   this.dialogData.data[0].value.value = response.result;
    // },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
      this.initTableData();
    },
    /**
     * 表格排序事件
     */
    sortChange(data) {
      const prop = this.$toLowerCase(data.prop);
      this.defaultSort.prop = data.order == null ? "" : prop;
      this.defaultSort.order = data.order == null ? "" : data.order;
      this.initTableData();
    },
    getAreaList() {
      let requestData = {
        type: ["片区", "客户来源"],
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/getTagList",
        requestData,
        (data) => {
          this.searchData.areaList = data[0].value.map((item) => {
            return {
              label: item.name,
              value: item.value,
            };
          });
          this.searchData.customerSourceList = data[1].value.map((item) => {
            return {
              label: item.name,
              value: item.value,
            };
          });

          this.popoData.list = taxData.taxInfoSearchData(
            this.searchData.areaList,
            this.searchData.customerSourceList
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 员工选择
     */
    selectInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.searchData.userIdList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.initTableData();
    },
    /**
     * 筛选弹出框关闭事件
     */
    popoCanael() {
      this.popoData.popoVisible = false;
    },
    /**
     * 筛选弹出框重置
     */
    popoReset() {
      this.$systemBaseData.popoReset(this.popoData.list);
      this.searchData.screenList = [];
    },
    /**
     * 筛选弹出框显示
     */
    popoverOpen() {
      // console.log("所有数组数据:", this.arrayList);
      // this.popoData.list[1].options = []; // 下单员工
      // this.popoData.list[1].options = this.searchData.allUserList.map(
      //   (item, index) => {
      //     return { label: item.userName, value: item.userId };
      //   }
      // );
      this.popoData.popoVisible = !this.popoData.popoVisible;
    },
    /**
     * 筛选弹出框确认事件
     */
    popoMakeSure() {
      this.searchData.screenList = this.$systemBaseData.remakesureSearchData(
        this.popoData.list
      );
      this.popoData.popoVisible = false;
      this.initTableData();
    },
    /**
     * 关闭筛选标签事件
     */
    closeScreen(index) {
      this.searchData.screenList.splice(index, 1);
      this.initTableData();
    },
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
    this.getAreaList();
    // this.getTagList();
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
#tax-info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .tax-info-screen {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    .tax-info-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      > .title {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 0 10px;
      }
      > .el-tag {
        margin: 0 5px;
      }
      .tax-info-screen-fiftrate {
        width: 65px;
        min-width: 65px;
        height: 30px;
        border: 1px solid @boderColor;
        box-sizing: border-box;
        color: @boderActiveColor;
        font-size: @contentFontSize;
        margin: 0 15px;
        display: flex;
        // color:#409eff
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: 0.3s;
        i {
          margin: 0 4px;
        }
      }
      .tax-info-screen-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }
      .tax-info-screen-search {
        width: 210px;
        min-width: 210px;
        height: 30px;
        margin-left: 10px;
        display: flex;
        align-items: center;
      }
      .tax-info-screen-active {
        flex: 1;
      }
      .drop-button {
        background-color: #ecf5ff;
        color: #409eff;
        &:hover {
          background-color: #abbed3;
        }
      }
      .tax-info-screen-but {
        width: 100px;
        min-width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .tax-info-screen-but:hover {
        cursor: pointer;
        background-color: @activeColor2;
      }
      .tax-info-screen-but-boder {
        min-width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        font-size: @contentFontSize;
        background-color: @activeBackground;
        color: @activeColor;
        margin: 0 5px;
        margin-right: 10px;
        border-radius: 4px;
        transition: 0.5s;
        padding: 0 15px;
        /deep/ .el-loading-spinner {
          top: 95%;
          left: -26px;
          width: 100%;
          position: absolute;
        }
      }
      .tax-info-screen-but-boder:hover {
        cursor: pointer;
        background-color: @activeBackground2;
      }
    }
  }

  .tax-table {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;

    .tax-info-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .tax-info-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .tax-info-table-card-tab {
          width: 120px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .tax-info-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
      }
      .tax-info-content {
        width: 100%;
        height: 95%;
        max-width: 90vw;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
      }
      .tax-info-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
