<template>
  <div id="show-reimbursemen-itemt-approy" class="customScrollBar">
    <!-- 报销详情显示 -->
    <div class="line">
      <div class="title">报销编号:</div>
      <div class="value">
        <span v-for="(item, index) in data.reimbursemen" :key="index">
          <span v-if="index > 0" style="margin: 0 2px">,</span>
          {{ item.numbers }}
        </span>
      </div>
    </div>
    <div class="line" v-if="flag">
      <div class="title">付款金额:</div>
      <div class="value">
        {{
          data.reimbursemen.reduce(
            (pre, cur) => pre + parseFloat(cur.amountAll),
            0
          )
        }}
      </div>
    </div>
    <div class="line" v-if="flag">
      <div class="title">付款方式:</div>
      <div class="value">
        <el-select
          v-model="data.payWay"
          placeholder="请选择"
          clearable
          filterable
        >
          <el-option
            v-for="item in payOptions"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="line" v-if="!flag">
      <div class="title">审批备注:</div>
      <div class="value">
        <el-input
          v-model="data.des"
          placeholder="请输入"
          type="textarea"
          :rows="2"
          clearable
        ></el-input>
      </div>
    </div>
    <div class="line" v-if="flag">
      <div class="title">付款附件:</div>
      <div class="value icons">
        <div
          class="icon"
          v-for="(it, ii) in data.file"
          :key="ii"
          v-viewer="{ movable: false }"
        >
          <img :src="it" style="width: 100%; height: 100%" />
          <div class="dele" @click="deleteItem(ii)" v-if="flag">
            <i class="el-icon-delete"></i>
          </div>
        </div>
        <div class="icon">
          <el-upload
            class="avatar-uploader"
            :action="action"
            :show-file-list="false"
            :headers="headers"
            :data="{ systemName: 'finance', title: '付款附件' }"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <span><i class="el-icon-circle-plus-outline"></i> 添加附件</span>
          </el-upload>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    reimbursemenDetailData: {
      type: Object,
      default: function() {
        return {
          reimbursemen: [{ id: "", number: "" }], // 审批编号
          payWay: "", // 付款方式
          des: "", // 审批意见
          file: [], // 付款附件
        };
      },
    },
    isFlag: {
      // 是否为付款
      type: Boolean,
      default: function() {
        return true;
      },
    },
  },
  data() {
    return {
      baseInfo: {},
      payOptions: [], // 付款方式
      data: this.reimbursemenDetailData,
      flag: this.isFlag,
      action: this.$fileBaseurl + "/uploadImageByMultipartFile",
      headers: {
        token: "95931b371eb64f4a9c862983ca8a5d15",
      },
    };
  },
  methods: {
    getTagList() {
      let requestData = {
        type: ["付款方式"],
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/getTagList",
        requestData,
        (data) => {
          console.log("当前获取到的标签组:", data);
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            if (element.type == "付款方式") {
              this.payOptions = element.value;
            }
          }
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    // 附件上传之前的校验
    beforeAvatarUpload(file) {
      let isJPG = file.type === "image/jpeg" || file.type === "image/png";

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 格式!");
      }
      return isJPG;
    },
    // 附件上传成功后的事件
    handleAvatarSuccess(response, file, fileList) {
      console.log("附件上传成功:", response);
      this.data.file.push(response.result.path);
    },
    deleteItem(index) {
      this.data.file.splice(index, 1);
    },
  },
  mounted() {
    console.log("数据获取", this.data);
    this.getTagList();
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
  },
};
</script>

<style lang="less" scoped>
#show-reimbursemen-itemt-approy {
  width: 100%;
  height: 100%;
  max-height: 750px;
  display: flex;
  overflow-y: auto;
  flex-direction: column;

  > .line {
    width: 100%;
    height: auto;
    min-height: 30px;
    display: flex;
    margin-top: 8px;
    > .title {
      width: auto;
      height: 100%;
      margin-right: 5px;
      display: flex;
      align-items: center;
      font-size: 14px;
    }
    > .value {
      width: auto;
      height: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 14px;
      margin-right: 5px;
    }
  }

  .icons {
    width: 0;
    height: auto;
    flex: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    > .icon {
      width: 80px;
      height: 120px;
      border: 1px solid #cecece;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      color: #cecece;
      position: relative;
      transition: 0.3s;
      padding: 2px;
      margin: 2px;
      &:hover {
        cursor: pointer;
        color: #2873ff;
      }
      > i {
        margin-right: 5px;
      }
      > .dele {
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 10px;
        font-size: 14px;
        color: #ff7919;
        position: absolute;
        top: 0;
        cursor: pointer;
      }
    }
  }
}
</style>
