import { render, staticRenderFns } from "./role-authority.vue?vue&type=template&id=22b41b59&scoped=true&"
import script from "./role-authority.vue?vue&type=script&lang=js&"
export * from "./role-authority.vue?vue&type=script&lang=js&"
import style0 from "./role-authority.vue?vue&type=style&index=0&id=22b41b59&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22b41b59",
  null
  
)

export default component.exports