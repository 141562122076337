<template>
  <div id="splitPush">
    <div class="splitPush-title">
      <div style="color: #5cb6ff">拆分推送</div>
    </div>

    <div class="splitPush-content">
      <table class="splitPush-table">
        <thead>
          <tr style="width: 2rem;">
            <th style="width: 35px;">
              <i class="el-icon-plus icon" @click="addInvoice"></i>
            </th>
            <th style="width: 185px;">货物或应税劳务名称</th>
            <th style="width: 90px;">规格型号</th>
            <th style="width: 90px;">单位</th>
            <th style="width: 90px;">数量</th>
            <th style="width: 120px;">单价</th>
            <th style="width: 120px;">金额</th>
            <th style="width: 90px;">税率</th>
            <th style="width: 120px;">税额</th>
            <th style="width: 30px; border: 0">操作</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="splitPush-content-line"
            v-for="(item, index) in list.invoiceList"
            :key="index"
          >
            <td>{{ index + 1 }}</td>
            <td>
              *{{ item.itemList[0].goodsType }}*{{ item.itemList[0].goodsName }}
            </td>
            <td>{{ item.itemList[0].specificationModel }}</td>
            <td>{{ item.itemList[0].meteringUnit }}</td>
            <td>
              <el-input
                v-model="item.itemList[0].quantity"
                @input="change"
                @change="changeNum(item, index)"
              ></el-input>
            </td>
            <td>{{ item.itemList[0].unitPrice }}</td>
            <td>
              <el-input
                v-model="item.itemList[0].invoiceAmount"
                @input="change"
                @change="changeAmount(item, index)"
              ></el-input>
            </td>
            <td>{{ item.itemList[0].taxRateValue }}</td>
            <td>{{ item.itemList[0].taxRateAmount }}</td>
            <td class="border">
              <i class="el-icon-minus icon" @click="deleteInvoice(index)"></i>
            </td>
          </tr>
          <tr
            class="splitPush-content-line"
            v-for="(item, index) in emptyList"
            :key="index + '-only'"
          >
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="border"></td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td style="width: 30px;"></td>
            <td>合计</td>
            <td class="border"></td>
            <td class="border"></td>
            <td class="border"></td>
            <td></td>
            <td>¥{{ curAllMoney }}</td>

            <td></td>
            <td>¥{{ curAllTax }}</td>
          </tr>
          <tr style="backgroundColor: #ECF5FF;">
            <td style="width: 30px; "></td>
            <td>合计</td>
            <td class="border"></td>
            <td></td>
            <td>{{ value.itemList[0].quantity }}</td>
            <td>{{ value.itemList[0].unitPrice }}</td>
            <td>¥{{ value.allMoney }}</td>
            <td>{{ value.itemList[0].taxRateValue }}</td>
            <td>¥{{ value.itemList[0].taxRateAmount }}</td>
          </tr>

          <tr>
            <td style="width: 30px;"></td>
            <td>价税合计(大写)</td>
            <td class="border">{{ curTotalAmountText }}</td>
            <td class="border"></td>
            <td></td>
            <td>(小写)</td>
            <td>¥{{ curTotalAmount }}</td>
            <td></td>
            <td></td>
          </tr>
          <tr style="backgroundColor: #ECF5FF;">
            <td style="width: 30px;"></td>
            <td>价税合计(大写)</td>
            <td class="border">{{ value.totalAmountText }}</td>
            <td class="border"></td>
            <td></td>
            <td>(小写)</td>
            <td>¥{{ value.totalAmount }}</td>
            <td></td>
            <td></td>
          </tr>
        </tfoot>
      </table>
    </div>
    <div class="splitPush-bottom">
      <div>
        <el-button type="primary" size="small" @click="confirm">确定</el-button>
        <el-button type="primary" size="small" @click="cancel">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["splitData"],
  data() {
    return {
      curAllMoney: "", // 总合计
      curAllTax: "", // 总税额
      curTotalAmount: "", // 总价税合计小写
      curTotalAmountText: "", // 总价税合计大写
      value: this.splitData[0].data,
      list: {
        invoiceList: [], // 每张发票数据
      },
      emptyList: 13,
      //   list: {
      //     invoiceList: [
      //       totalAmount, // 发票总金额（价税合计）
      //       allTax, // 所有发票项的总税额
      //       itemList: [
      //         goodsType, // 项目类型
      //         goodsName, // 项目名称
      //         taxClassificationCode, // 税收分类编码
      //         specificationModel, // 项目规格型号
      //         meteringUnit, // 项目单位
      //         quantity, // 项目数量
      //         unitPrice, // 单价
      //         taxRateValue, // 税率
      //         taxRateAmount, // 税额（税额 = 含税金额 / (1 + 税率) * 税率
      //         discountAmount, // 折扣金额
      //         discountTaxRateAmount, // 折扣税额
      //         deductionAmount, // 差额开票抵扣金额
      //         preferentialPolicyFlag, // 优惠政策标识（0 | 1）
      //         zeroTaxFlag, // 零税率标识（0 | 1）
      //         vatSpecialManage, // 零税率标识（0 | 1）
      //       ]
      //     ],

      //     invoiceId, // 源发票 id
      //   },
    };
  },
  methods: {
    /**
     * 根据数量计算
     */
    changeNum(data, index) {
      let amount =
        parseFloat(data.itemList[0].quantity) *
        parseFloat(data.itemList[0].unitPrice); // 当前发票金额 = 单价 * 数量
      this.$cutOutNum.cutOutNum(amount, this, (num) => {
        data.itemList[0].invoiceAmount = num;
        // data.totalAmount = num; // 价税合计
        // this.curTotalAmount = this.list.invoiceList.reduce(
        //   (prev, item) => prev + +item.totalAmount,
        //   0
        // ); // 所有发票价税合计
        // this.curTotalAmountText = this.$money.required(this.curTotalAmount); // 价税合计大写
        // data.totalAmountText = this.$money.required(num); // 价税合计大写
        this.changeTax(data);
      });
    },
    /**
     * 根据金额计算
     */
    changeAmount(data, index) {
      if (data.itemList[0].unitPrice) {
        let quantity =
          +parseFloat(data.itemList[0].invoiceAmount) /
          +parseFloat(data.itemList[0].unitPrice); // 数量 = 含税金额 / 含税单价
        this.$cutOutNum.cutOutNum(quantity, this, (num) => {
          data.itemList[0].quantity = num;
          this.$set(data.itemList[0], "quantity", data.itemList[0].quantity);
          this.changeTax(data);
          // this.changeNum(data, index);
        });
      } else {
        this.changeTax(data);
      }
    },
    change(e) {
      this.$forceUpdate();
    },
    /**
     * 税额计算
     */
    changeTax(data) {
      // 是否含税
      // let taxRateAmount = ""; // 税额
      if (this.value.includeTaxFlag) {
        this.$cutOutNum.cutOutNum(
          (1 / (1 + +data.itemList[0].taxRateValue)) *
            data.itemList[0].taxRateValue *
            data.itemList[0].invoiceAmount,
          this,
          (taxRateAmount) => {
            data.itemList[0].taxRateAmount = taxRateAmount; // 税额 = 1/(1 + 税率) * 税率 * 含税金额
            data.allTax = taxRateAmount;
            this.$cutOutNum.cutOutNum(
              this.list.invoiceList.reduce(
                (prev, item) => prev + +item.allTax,
                0
              ),
              this,
              (curAllTax) => {
                this.curAllTax = curAllTax;
                this.$cutOutNum.cutOutNum(
                  data.itemList[0].invoiceAmount -
                    +data.itemList[0].taxRateAmount,
                  this,
                  (allMoney) => {
                    data.allMoney = allMoney;
                    this.curAllMoney = this.list.invoiceList.reduce(
                      (prev, item) => prev + +item.allMoney,
                      0
                    ); // 所有发票合计
                    data.totalAmount = +data.allMoney + +data.allTax; // 价税合计
                    this.curTotalAmount = this.list.invoiceList.reduce(
                      (prev, item) => prev + +item.totalAmount,
                      0
                    ); // 所有发票价税合计

                    this.curTotalAmountText = this.$money.required(
                      this.curTotalAmount
                    ); // 价税合计大写
                    data.totalAmountText = this.$money.required(
                      data.totalAmount
                    ); // 价税合计大写
                    this.$forceUpdate();
                  }
                ); // 合计 = 含税金额 - 税额
              }
            );
          }
        );
      } else {
        this.$cutOutNum.cutOutNum(
          data.itemList[0].taxRateValue * data.itemList[0].invoiceAmount,
          this,
          (taxRateAmount) => {
            data.itemList[0].taxRateAmount = taxRateAmount; // 税额 = 税率 * 金额
            data.allTax = taxRateAmount;
            this.$cutOutNum.cutOutNum(
              this.list.invoiceList.reduce(
                (prev, item) => prev + +item.allTax,
                0
              ),
              this,
              (curAllTax) => {
                this.curAllTax = curAllTax;
                this.$cutOutNum.cutOutNum(
                  data.itemList[0].invoiceAmount,
                  this,
                  (allMoney) => {
                    data.allMoney = allMoney; // 合计 = 金额
                    this.curAllMoney = this.list.invoiceList.reduce(
                      (prev, item) => prev + +item.allMoney,
                      0
                    ); // 所有发票合计
                    data.totalAmount = +data.allMoney + +data.allTax; // 价税合计
                    this.curTotalAmount = this.list.invoiceList.reduce(
                      (prev, item) => prev + +item.totalAmount,
                      0
                    ); // 所有发票价税合计
                    this.curTotalAmountText = this.$money.required(
                      this.curTotalAmount
                    ); // 价税合计大写
                    data.totalAmountText = this.$money.required(
                      data.totalAmount
                    ); // 价税合计大写
                    this.$forceUpdate();
                  }
                );
              }
            );
          }
        );
      }
      // this.$cutOutNum.cutOutNum(taxRateAmount, this, (num) => {
      //   data.itemList[0].taxRateAmount = num;
      //   data.allTax = num;
      //   this.$cutOutNum.cutOutNum(
      //     this.list.invoiceList.reduce((prev, item) => prev + +item.allTax, 0),
      //     this,
      //     (num) => {
      //       this.curAllTax = num;
      //     }
      //   );
      // });
    },
    /**
     * 新增发票
     */
    addInvoice() {
      this.list.invoiceList.push({
        totalAmount: "", // 价税合计
        totalAmountText: "", // 价税合计大写
        allMoney: "", // 合计金额（所有发票项金额的合计）
        allTax: "", // 所有发票项的总税额
        itemList: [
          {
            goodsType: this.value.itemList[0].goodsType, // 商品类型
            goodsName: this.value.itemList[0].goodsName,
            specificationModel: this.value.itemList[0].specificationModel, // 项目规格型号
            taxClassificationCode: this.value.itemList[0].taxClassificationCode, // 项目规格型号
            meteringUnit: this.value.itemList[0].meteringUnit, // 项目单位
            meteringUnit: this.value.itemList[0].meteringUnit, // 项目单位
            unitPrice: this.value.itemList[0].unitPrice, // 项目数量
            invoiceAmount: "", // 总价
            taxRateValue: this.value.itemList[0].taxRateValue, // 税率
            taxRateAmount: "", // 税额,
            discountAmount: this.value.itemList[0].discountAmount, // 折扣金额
            discountTaxRateAmount: this.value.itemList[0].discountTaxRateAmount, // 折扣税额
            deductionAmount: this.value.itemList[0].deductionAmount, // 差额开票抵扣金额
            preferentialPolicyFlag: this.value.itemList[0]
              .preferentialPolicyFlag, // 优惠政策标识（0 | 1）
            zeroTaxFlag: this.value.itemList[0].zeroTaxFlag, // 零税率标识（0 | 1）
            vatSpecialManage: this.value.itemList[0].vatSpecialManage, // 零税率标识（0 | 1）
          },
        ],
      });
      if (this.emptyList > 0) {
        this.emptyList--;
      }
    },
    /**
     * 删除发票
     */
    deleteInvoice(index) {
      this.list.invoiceList.splice(index, 1);
      this.$cutOutNum.cutOutNum(
        this.list.invoiceList.reduce((prev, item) => prev + +item.allMoney, 0),
        this,
        (num) => {
          this.curAllMoney = num;
        }
      ); // 所有发票合计
      this.$cutOutNum.cutOutNum(
        this.list.invoiceList.reduce((prev, item) => prev + +item.allTax, 0),
        this,
        (num) => {
          this.curAllTax = num;
        }
      ); // 所有发票总税额
      this.$cutOutNum.cutOutNum(
        this.list.invoiceList.reduce(
          (prev, item) => prev + +item.totalAmount,
          0
        ),
        this,
        (num) => {
          this.curTotalAmount = num;
        }
      ); // 所有发票价税合计
      this.curTotalAmountText = this.$money.required(this.curTotalAmount); // 价税合计大写
      if (this.list.invoiceList.length < 13 && this.emptyList < 13) {
        this.emptyList++;
      }
    },

    /**
     * dialog取消
     */
    cancel() {
      this.$emit("cancel");
    },
    /**
     * 确认推送
     */
    confirm() {
      this.$emit(
        "confirm",
        this.list,
        this.curAllMoney,
        this.curAllTax,
        this.curTotalAmount
      );
    },
  },
  watch: {
    splitData() {
      //父组件值变化->>子组件也变化
      this.value = this.splitData;
    },
  },
};
</script>

<style lang="less" scoped>
#splitPush {
  /deep/ .el-input__inner {
    border: 0;
    padding: 0;
  }
  .splitPush-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    padding: 0 80px 10px 10px;
  }
  .splitPush-content {
    height: 577px;
    margin: 10px;
    border-top: 1px solid #d1d3d8;
    border-left: 1px solid #d1d3d8;
    border-right: 1px solid #d1d3d8;
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.2);
    }

    .splitPush-table {
      border-collapse: collapse;
      width: 100%;
      table-layout: fixed;
      thead tr th {
        z-index: 999;
        background-color: #ffffff;
        position: sticky;
        top: 0;
      }
      tfoot {
        z-index: 999;
        background-color: #fff;
        position: sticky;
        bottom: 0;
      }

      .splitPush-content-line {
        text-align: left;
        font-size: 14px;
      }
      tr {
        height: 2rem;
        border-bottom: 1px solid #dddfe8;
        border-right: none;
        text-align: center;
        th {
          border-right: 1px solid #d1d3d8;

          border-bottom: 1px solid #d1d3d8;
        }
        td {
          padding: 0 10px;
          border-right: 1px solid #d1d3d8;
        }
      }
      .th {
        font-weight: 400 !important;
        height: 18px !important;
      }
      .border {
        border-right: 0;
      }
      .icon {
        border: 1px solid #d1d3d8;
        cursor: pointer;
        &:hover {
          color: #5cb6ff;
          border: 1px solid #5cb6ff;
        }
      }
    }
  }
  .splitPush-bottom {
    display: flex;
    justify-content: center;
  }
}
</style>
