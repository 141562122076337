<template>
  <div id="expend_details">
    <div class="expend_details_top" v-if="value.clientName">
      <div class="expend_details_title" style="color: #409EFF">
        <i class="el-icon-office-building"></i>
        {{ value.clientName }}
      </div>
    </div>
    <div class="expend_details_lines">
      <div class="expend_details_line">
        <div class="expend_details_line_title">合同编号</div>
        <div class="expend_details_line_value" style="color: #409EFF">
          {{ value.contractNumber || "--" }}
        </div>
      </div>
      <div class="expend_details_line">
        <div class="expend_details_line_title">收款单号</div>
        <div class="expend_details_line_value">
          {{ value.receiptNumber }}
        </div>
      </div>
      <div class="expend_details_line">
        <div class="expend_details_line_title">支出状态</div>
        <div
          class="expend_details_line_value"
          :style="{ color: value.reimburse.color }"
        >
          {{ value.reimburse.status }}
        </div>
      </div>
      <div class="expend_details_line">
        <div class="expend_details_line_title">付款金额</div>
        <div class="expend_details_line_value">
          {{ value.paymentAmount }}
        </div>
      </div>

      <div class="expend_details_line">
        <div class="expend_details_line_title">付款方式</div>
        <div class="expend_details_line_value">
          {{ value.paymentMethod }}
        </div>
      </div>

      <div class="expend_details_line">
        <div class="expend_details_line_title">付款日期</div>
        <div class="expend_details_line_value">
          {{ value.paymentDate }}
        </div>
      </div>
      <div class="expend_details_line">
        <div class="expend_details_line_title">付款人</div>
        <div class="expend_details_line_value">
          {{ value.payerOwner }}
        </div>
      </div>
      <div class="expend_details_line">
        <div class="expend_details_line_title">收款人</div>
        <div class="expend_details_line_value">
          {{ value.payeeName }}
        </div>
      </div>

      <div class="expend_details_line">
        <div class="expend_details_line_title">银行账号</div>
        <div class="expend_details_line_value">
          {{ value.bankAccount }}
        </div>
      </div>
      <div class="expend_details_line">
        <div class="expend_details_line_title">开户行</div>
        <div class="expend_details_line_value">
          {{ value.bankOfDeposit }}
        </div>
      </div>

      <div class="expend_details_line">
        <div class="expend_details_line_title">备注说明</div>
        <div class="expend_details_line_value">
          {{ value.remark }}
        </div>
      </div>
      <div class="expend_details_line">
        <div class="expend_details_line_title">付款事由</div>
        <div class="expend_details_line_value">
          {{ value.reasons }}
        </div>
      </div>

      <div class="expend_details_line">
        <div class="expend_details_line_title">附件</div>
        <div class="expend_details_line_value">
          <div
            class="expend_details_line_value_file"
            v-if="value.file != ''"
            @click="showReceivableSheet(value.attachments)"
          >
            查看支出附件
          </div>
          <el-upload
            class="avatar-uploader"
            :action="action"
            :show-file-list="false"
            :headers="headers"
            :data="uploadParameter"
            :on-success="handleAvatarSuccess"
            v-if="value.expendStatus == '待提交'"
          >
            <div>
              <el-button size="small" type="primary" icon="el-icon-upload2"
                >点击上传</el-button
              >
            </div>
          </el-upload>
        </div>
      </div>

      <!-- <div class="expend_details_line">
        <div class="expend_details_line_title">开户银行</div>
        <div class="expend_details_line_value">
          <el-input
            v-model="value.blank"
            :disabled="value.expendStatus != '待提交'"
            placeholder="请输入内容"
          ></el-input>
        </div>
      </div>

      <div class="expend_details_line">
        <div class="expend_details_line_title">备注</div>
        <div class="expend_details_line_value">
          <el-input
            v-model="value.des"
            :disabled="value.expendStatus != '待提交'"
            placeholder="请输入内容"
          ></el-input>
        </div>
      </div> -->
    </div>

    <!-- <div class="expend_details_lines">
      <div class="expend_details_line">
        {{ value.client }} - {{ value.contractNum }}
      </div>
      <div class="expend_details_line is_table">
        <div class="expend_details_table_line is_head">
          <div class="expend_details_table_line_cell">服务项</div>
          <div class="expend_details_table_line_cell">服务项金额(元)</div>
          <div class="expend_details_table_line_cell">本次支出(元)</div>
          <div class="expend_details_table_line_cell">备注</div>
        </div>
        <div
          class="expend_details_table_line"
          v-for="(item, index) in value.services"
          :key="index"
        >
          <div class="expend_details_table_line_cell">{{ item.name }}</div>
          <div class="expend_details_table_line_cell">
            {{ item.money }}
          </div>
          <div class="expend_details_table_line_cell">
            <el-input
              v-model="item.expendMoney"
              :disabled="value.expendStatus != '待提交'"
              placeholder="请输入内容"
            ></el-input>
          </div>
          <div class="expend_details_table_line_cell">
            <el-input
              v-model="item.des"
              :disabled="value.expendStatus != '待提交'"
              placeholder="请输入内容"
            ></el-input>
          </div>
        </div>
      </div>
    </div> -->

    <div id="my-dialog" v-if="dialogData3.flag">
      <my_dialog7
        :width="dialogData3.width"
        :title="dialogData3.title"
        :buttonArray="dialogData3.buttonArray"
        :data="dialogData3.data"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @voucherFor="voucherFor"
      ></my_dialog7>
    </div>
  </div>
</template>

<script>
export default {
  props: ["expendDetailsData"],
  data() {
    return {
      value: this.expendDetailsData,
      baseInfo: {},
      options: [
        {
          value: "刻章",
          label: "刻章",
        },
        {
          value: "地址费",
          label: "地址费",
        },
        {
          value: "材料费",
          label: "材料费",
        },
        {
          value: "外包成本",
          label: "外包成本",
        },
        {
          value: "其他成本",
          label: "其他成本",
        },
        {
          value: "业务招待费",
          label: "业务招待费",
        },
      ],
      action: this.$fileBaseurl + "/uploadImageByMultipartFile",

      headers: {
        token: "95931b371eb64f4a9c862983ca8a5d15",
      },
      uploadParameter: {
        systemName: "finance",
        title: "支出附件",
      },

      dialogData3: {
        flag: false,
        width: "30%",
        title: "查看支出附件",
        buttonArray: [
          {
            text: "取消",
            active: "cancel",
          },
        ],
        data: [
          {
            title: "",
            type: "show_img",
            value: "",
          },
        ],
      },
    };
  },
  watch: {
    expendDetailsData() {
      //父组件值变化->>子组件也变化
      this.value = this.expendDetailsData;
    },
    value(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("showExpend", newvalue);
    },
  },
  methods: {
    buttonOper(data) {
      this.$emit("buttonOper", data);
    },
    handleAvatarSuccess(response, file, fileList) {
      this.value.file = response.result;
    },

    /**
     * 查看回款单
     */
    showReceivableSheet(data) {
      if (data == "") {
        this.$message({
          message: "暂无支出附件!!",
          type: "error",
          duration: 1000,
        });
        return;
      }
      this.dialogData3.title = "查看支出附件";
      this.dialogData3.width = "30%";
      this.dialogData3.buttonArray = [
        {
          text: "取消",
          active: "cancel",
        },
      ];

      this.dialogData3.data = [
        {
          title: "",
          type: "show_imgs",
          value: data,
        },
      ];
      this.dialogData3.flag = true;
    },
    /**
     * dialog 关闭
     */
    colseDialog() {
      this.dialogData3.flag = false;
    },
    drawerOper(active) {
      switch (active) {
        case "cancel":
          this.dialogData3.flag = false;
          break;

        default:
          break;
      }
    },
    voucherFor(data) {
      console.log(data);
    },
  },
  mounted() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
  },
};
</script>

<style lang="less" scoped>
#expend_details {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  padding: 0 10px;
  box-sizing: border-box;
  .expend_details_top {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    .expend_details_but {
      width: auto;
      padding: 0 15px;
      box-sizing: border-box;
      height: 25px;
      background-color: @activeColor;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      cursor: pointer;
    }
  }

  .expend_details_lines {
    margin: 5px 0;
    box-sizing: border-box;
    border: 1px solid @boderColor;
    width: 100%;
    height: auto;
    // padding: 3px;
    display: flex;
    flex-direction: column;
    .expend_details_line {
      height: auto;
      min-height: 40px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid @boderColor;
      box-sizing: border-box;
      &:nth-child(1) {
        border: none;
      }
      .expend_details_line_title {
        width: 16%;
        min-width: 100px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 5px;
      }
      .expend_details_line_value {
        flex: 1;
        padding: 2px 10px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        .expend_details_line_value_file {
          width: auto;
          padding: 0 10px;
          box-sizing: border-box;
          height: 100%;
          color: @activeColor3;
          transition: 0.3s;
          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
        .expend_details_line_value_but {
          width: auto;
          padding: 0 15px;
          box-sizing: border-box;
          height: 25px;
          background-color: @activeColor;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          cursor: pointer;
        }
      }
      .expend_details_table_line {
        width: 100%;
        height: 40px;
        padding: 0 10px;
        box-sizing: border-box;
        display: flex;
        border-top: 1px solid @boderColor;
        .expend_details_table_line_cell {
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 0 3px;
          box-sizing: border-box;
        }
      }
      .is_head {
        background-color: @contentBackground;
        border: none;
      }
    }
    .is_table {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
