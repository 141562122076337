<template>
  <div id="knowledge_base">
    <!-- 知识库 -->
    <div class="knowledge-base-screen">
      <div class="knowledge-base-screen-search">
        <el-select
          v-model="searchData.typeName"
          placeholder="请选择分组"
          @change="initTableData"
          filterable
          clearable
        >
          <el-option
            v-for="item in tagList"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="knowledge-base-screen-search">
        <el-select
          v-model="searchData.type"
          placeholder="请选择类型"
          @change="initTableData"
          filterable
          clearable
        >
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="knowledge-base-screen-search">
        <el-date-picker
          v-model="searchData.time"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          clearable
          @change="initTableData"
        >
        </el-date-picker>
      </div>
      <div class="knowledge-base-screen-search">
        <el-input
          placeholder="标题/内容/摘要"
          v-model="searchData.search"
          @keyup.enter.native="initTableData"
          clearable
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click.native.stop="initTableData"
          ></el-button>
        </el-input>
      </div>
      <div class="knowledge-base-screen-active"></div>
      <div
        class="knowledge-base-screen-but-select"
        @click="addKnowledge"
        v-showFunction="{ active: 'add', array: allFunctionButtons }"
      >
        新增知识库
      </div>
      <!-- <div
        class="knowledge-base-screen-but-boder"
        v-show="searchData.screenButton_export < 3"
        @click="exportKnowledge"
      >
        导出
      </div>
      <div
        class="knowledge-base-screen-but-boder"
        v-show="searchData.screenButton_improt < 3"
        @click="importKnowledge"
      >
        导入
      </div> -->
    </div>
    <div class="knowledge-base-table">
      <div class="knowledge-base-table-card">
        <div class="knowledge-base-table-card-tabs">
          <div
            :class="{
              'knowledge-base-table-card-tab': true,
              'is-active': tabindex == '0',
            }"
          >
            全部
          </div>
          <div class="knowledge-base-table-card-active"></div>
        </div>
        <div class="knowledge-base-table-card-content">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            height="98%"
            v-loading="loading"
            @selection-change="handleSelectionChange"
            @row-click="rowClick"
          >
            <el-table-column
              type="selection"
              width="35"
              fixed
            ></el-table-column>
            <el-table-column type="index" width="55" fixed> </el-table-column>
            <el-table-column
              v-for="(item, index) in tableMenuData"
              :key="index"
              :property="item.key"
              :label="item.title"
              :show-overflow-tooltip="true"
              :width="item.width"
            >
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="150">
              <template slot-scope="scope">
                <el-button
                  @click.native.stop="handleClick(scope.row, 'edit')"
                  type="primary"
                  size="small"
                  plain
                  v-showFunction="{ active: 'edit', array: allFunctionButtons }"
                  >编辑</el-button
                >
                <el-button
                  type="danger"
                  size="small"
                  plain
                  @click.native.stop="handleClick(scope.row, 'delete')"
                  v-showFunction="{
                    active: 'delete',
                    array: allFunctionButtons,
                  }"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="knowledge-base-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @buttonOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import my_dialog from "@/components/dialog.vue";
import * as systemData from "@/assets/js/systemAuthorityData.js";
import * as knowledgeData from "@/assets/js/knowledgeData.js";
export default {
  components: {
    my_dialog,
  },
  inject: ["reload"],
  data() {
    return {
      baseInfo: {},
      loading: true,
      searchData: {
        typeName: "", // 类型名称
        type: "",
        search: "", // 搜索内容/标题
        time: [], // 时间段
        screenButton_add: 1, // 新增 1->读写;2->只读;3->不可见
        screenButton_improt: 1, // 导入
        screenButton_export: 1, // 导出 1->读写;2->只读;3->不可见
        screenButton_edit: 1, // 导出 1->读写;2->只读;3->不可见
        screenButton_delete: 1, // 导出 1->读写;2->只读;3->不可见
      },
      tagList: [{ name: "", value: "" }],
      typeList: [
        {
          label: "文字",
          value: "0",
        },
        {
          label: "图片",
          value: "1",
        },
        {
          label: "文档",
          value: "2",
        },
        {
          label: "链接",
          value: "3",
        },
      ],
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },

      tabindex: "0",
      tableData: [],
      tableMenuData: [
        {
          key: "typeName",
          title: "分组",
          width: 150,
        },
        // {
        //   key: "type",
        //   title: "类型",
        // },
        {
          key: "title",
          title: "标题",
          width: 100,
        },
        // {
        //   key: "content",
        //   title: "摘要",
        // },
        {
          key: "uploadName",
          title: "上传人",
          width: 100,
        },
        {
          key: "time",
          title: "上传时间",
          width: 130,
        },
        {
          key: "additional",
          title: "内容",
          width: "",
        },
      ],

      dialogData: {
        flag: false,
        width: "35%",
        title: "新建",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },
      buttonAuth: [],
      multipleSelection: [], // 多选框
      tempData: {},
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "knowledgeBase"
      ),
    };
  },
  methods: {
    /**
     * 获取界面的按钮权限
     */
    getModelFeatureAuthority() {
      this.$authority.getModelFeatureAuthority(
        this,
        "knowledgeBase",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.initTableData();
          this.getTagList();
        }
      );
    },
    /**
     * 获取知识库分组
     */
    getTagList() {
      let requestData = {
        type: ["知识库分类"],
        companyId: this.baseInfo.company.companyId,
      };
      this.tagList = [];
      this.$request("post", "/getTagList", requestData, (data) => {
        if (data.length > 0) {
          this.tagList = data[0].value;
        }
      });
    },
    /**
     * 初始化表格
     */
    initTableData() {
      let requestData = {
        companyId: this.baseInfo.company.companyId, // 公司id
        startTime: this.searchData.time
          ? this.searchData.time.length > 0
            ? this.searchData.time[0]
            : ""
          : "", // 时间，年月日,可空
        endTime: this.searchData.time
          ? this.searchData.time.length > 1
            ? this.searchData.time[1]
            : ""
          : "", // 时间，年月日,可空
        search: this.searchData.search, // 标题或内容，空则为全部
        typeName: this.searchData.typeName, // 类型名称，空则为全部
        type: this.searchData.type,
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
      };
      this.loading = true;
      this.$request(
        "post",
        "/knowledgeBase/getLibraryList",
        requestData,
        (data) => {
          this.pageData.total = data.total;
          data.tableData.forEach((element) => {
            element.type =
              element.type == "0"
                ? "文字"
                : element.type == "1"
                ? "图片"
                : element.type == "2"
                ? "文档"
                : "链接";
          });
          this.tableData = data.tableData;
          this.$nextTick(() => {
            this.$refs.singleTable.doLayout();
          });
          this.loading = false;
        }
      );
    },

    /**
     * 表格单行点击事件
     */
    rowClick(row, column, event) {
      this.tempData = row;
      let requestData = {
        id: row.id,
      };
      this.$request(
        "post",
        "/knowledgeBase/getLibraryDetail",
        requestData,
        (data) => {
          this.dialogData.title = "知识库详情";
          this.dialogData.data = knowledgeData.addKnowledgeDialogData(
            this.tagList
          );
          this.dialogData.data[0].value.isDisable = true;
          this.dialogData.buttonArray = [];
          let typeName = data.typeName.split(",");
          this.dialogData.data[0].value.typeName = typeName;
          this.dialogData.data[0].value.title = data.title;
          this.dialogData.data[0].value.content = data.content;
          this.dialogData.data[0].value.type = data.type;
          if (data.type == "0") {
            // 文字
            this.dialogData.data[0].value.textAdditional = data.additional;
            this.dialogData.buttonArray = [
              {
                text: "复制内容",
                active: "copy",
              },
            ];
            this.dialogData.data[0].value.additional = data.additional;
          } else if (data.type == "1") {
            // 图片
            this.dialogData.data[0].value.imgAdditional = data.additional;
          } else if (data.type == "2") {
            // 文档
            this.dialogData.data[0].value.documentAdditional = data.additional;
          } else if (data.type == "3") {
            // 链接
            this.dialogData.data[0].value.linkAdditional = data.additional;
            this.dialogData.data[0].value.additional = data.additional;
            this.dialogData.buttonArray = [
              {
                text: "复制内容",
                active: "copy",
              },
            ];
          }
          this.dialogData.flag = true;
        }
      );
    },
    /**
     * 表格按钮点击事件
     */
    handleClick(item, active) {
      let requestData = {};
      let typeName = "";
      switch (active) {
        case "edit":
          // 编辑
          this.tempData = item;
          requestData = {
            id: item.id,
          };
          this.$request(
            "post",
            "/knowledgeBase/getLibraryDetail",
            requestData,
            (data) => {
              this.dialogData.title = "编辑知识库";
              this.dialogData.buttonArray = [
                {
                  text: "提交",
                  active: "submit_edit",
                },
                { text: "取消", active: "cancel" },
              ];
              this.dialogData.data = knowledgeData.addKnowledgeDialogData(
                this.tagList
              );
              this.dialogData.data[0].value.isDisable = false;
              typeName = data.typeName.split(",");
              this.dialogData.data[0].value.typeName = typeName;
              this.dialogData.data[0].value.title = data.title;
              this.dialogData.data[0].value.content = data.content;
              this.dialogData.data[0].value.type = data.type;
              if (data.type == "0") {
                // 文字
                this.dialogData.data[0].value.textAdditional = data.additional;
              } else if (data.type == "1") {
                // 图片
                this.dialogData.data[0].value.imgAdditional = data.additional;
              } else if (data.type == "2") {
                // 文档
                this.dialogData.data[0].value.documentAdditional =
                  data.additional;
              } else if (data.type == "3") {
                // 链接
                this.dialogData.data[0].value.linkAdditional = data.additional;
              }
              this.dialogData.flag = true;
            }
          );
          break;
        case "delete":
          // 删除
          requestData = {
            id: item.id,
          };
          this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$request(
                "post",
                "/knowledgeBase/delLibrary",
                requestData,
                (data) => {
                  this.$message({
                    message: "删除成功!",
                    type: "success",
                  });
                  this.initTableData();
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });

          break;

        default:
          break;
      }
    },
    /**
     * 新增知识库
     */
    addKnowledge() {
      this.dialogData.title = "新建知识库";
      this.dialogData.buttonArray = [
        {
          text: "提交",
          active: "submit_add",
        },
        { text: "取消", active: "cancel" },
      ];
      this.dialogData.data = knowledgeData.addKnowledgeDialogData(this.tagList);
      this.dialogData.data[0].value.isDisable = false;
      console.log(this.dialogData);
      this.dialogData.flag = true;
    },
    /**
     * 导入知识库
     */
    importKnowledge() {},
    /**
     * 导出知识库
     */
    exportKnowledge() {},
    /**
     * dialog 按钮操作事件
     */
    drawerOper(active) {
      let requestData = {};
      let additional = "";
      let dialogData = this.dialogData.data[0].value;
      let typeName = "";
      let this_ = this;
      switch (active) {
        case "submit_add":
          // 确定添加知识库
          console.log("确定添加知识库:", this.dialogData.data);

          dialogData.imgAdditional.forEach((element) => {
            if (this.$keyType.keyType(element.url) != "Object") {
              let uu = {
                path: element.url,
                title: "知识库附件",
              };
              element.url = uu;
            }
          });
          // dialogData.documentAdditional.forEach((element) => {
          //   if (this.$keyType.keyType(element.url) != "Object") {
          //     let uu = {
          //       path: element.url,
          //       title: "知识库附件",
          //     };
          //     element.url = uu;
          //   }
          // });
          additional =
            dialogData.type == "0"
              ? dialogData.textAdditional
              : dialogData.type == "1"
              ? dialogData.imgAdditional
              : dialogData.type == "2"
              ? dialogData.documentAdditional
              : dialogData.linkAdditional;
          dialogData.typeName.forEach((element) => {
            if (typeName == "") {
              typeName = element;
            } else {
              typeName = typeName + "," + element;
            }
          });
          requestData = {
            id: "", // 知识库id,空则新增,不空则修改
            companyId: this.baseInfo.company.companyId, // 公司id
            userId: this.baseInfo.userId, // 上传人id
            typeName: typeName, // 分组名称
            title: dialogData.title, // 标题
            content: dialogData.content, // 摘要
            type: dialogData.type, // 类型
            additional: additional, // 附加内容,图片文档等
          };
          this.$request(
            "post",
            "/knowledgeBase/saveOrUpdateLibrary",
            requestData,
            (data) => {
              this.$message({
                message: "添加成功!",
                type: "success",
              });
              this.dialogData.flag = false;
              this.initTableData();
            }
          );
          break;
        case "submit_edit":
          // 确定编辑知识库
          dialogData.imgAdditional.forEach((element) => {
            if (this.$keyType.keyType(element.url) != "Object") {
              let uu = {
                path: element.url,
                title: "知识库附件",
              };
              element.url = uu;
            }
          });
          // dialogData.documentAdditional.forEach((element) => {
          //   if (this.$keyType.keyType(element.url) != "Object") {
          //     let uu = {
          //       path: element.url,
          //       title: "知识库附件",
          //     };
          //     element.url = uu;
          //   }
          // });
          additional =
            dialogData.type == "0"
              ? dialogData.textAdditional
              : dialogData.type == "1"
              ? dialogData.imgAdditional
              : dialogData.type == "2"
              ? dialogData.documentAdditional
              : dialogData.linkAdditional;
          dialogData.typeName.forEach((element) => {
            if (typeName == "") {
              typeName = element;
            } else {
              typeName = typeName + "," + element;
            }
          });
          requestData = {
            id: this.tempData.id, // 知识库id,空则新增,不空则修改
            companyId: this.baseInfo.company.companyId, // 公司id
            userId: this.baseInfo.userId, // 上传人id
            typeName: typeName, // 分组名称
            title: dialogData.title, // 标题
            content: dialogData.content, // 摘要
            type: dialogData.type, // 类型
            additional: additional, // 附加内容,图片文档等
          };
          this.$request(
            "post",
            "/knowledgeBase/saveOrUpdateLibrary",
            requestData,
            (data) => {
              this.$message({
                message: "修改成功!",
                type: "success",
              });
              this.dialogData.flag = false;
              this.initTableData();
            }
          );
          break;
        case "copy":
          // 复制内容
          this.$copyText(dialogData.additional).then(
            function(e) {
              console.log("copy arguments e:", e);
              this_.$message({
                message: "复制成功!",
                type: "success",
              });
            },
            function(e) {
              console.log("copy arguments e:", e);
              this_.$message({
                message: "复制失败!",
                type: "error",
              });
            }
          );
          break;
        case "cancel":
          this.colseDialog();
          break;

        default:
          break;
      }
    },

    /**
     * 多选框事件
     */
    handleSelectionChange(val) {
      this.multipleSelection = [];
      val.forEach((element) => {
        this.multipleSelection.push(element.id);
      });
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    /**
     * dialog 关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
  },
  mounted() {
    this.loading = true;
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#knowledge_base {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .knowledge-base-screen {
    width: 100%;
    height: 45px;
    border-bottom: 1px solid @boderColor;
    display: flex;
    align-items: center;
    .knowledge-base-screen-fiftrate {
      width: 65px;
      min-width: 65px;
      height: 30px;
      border: 1px solid @boderColor;
      box-sizing: border-box;
      color: @boderActiveColor;
      font-size: @contentFontSize;
      margin: 0 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      transition: 0.3s;
      i {
        margin: 0 4px;
      }
    }
    .knowledge-base-screen-fiftrate:hover {
      cursor: pointer;
      border-color: @boderActiveColor;
    }
    .knowledge-base-screen-search {
      width: 210px;
      min-width: 210px;
      height: 30px;
      margin-left: 5px;
    }
    .knowledge-base-screen-checked {
      width: 140px;
      height: 30px;
      min-width: 140px;
      font-size: @contentFontSize;
      margin: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .knowledge-base-screen-active {
      flex: 1;
    }
    .knowledge-base-screen-but-select {
      width: 140px;
      min-width: 160px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: @activeColor;
      font-size: @contentFontSize;
      color: #fff;
      margin: 0 5px;
      border-radius: 4px;
      transition: 0.5s;
      cursor: pointer;
      .knowledge-base-screen-but-select_but {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.5s;
      }
      .knowledge-base-screen-but-select_but:hover {
        background-color: @activeColor2;
      }
      .knowledge-base-screen-but-select_select {
        width: 30px;
        height: 100%;
        border-left: 1px solid @activeColor3;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .knowledge-base-screen-but {
      width: 100px;
      min-width: 100px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: @activeColor;
      font-size: @contentFontSize;
      color: #fff;
      margin: 0 5px;
      border-radius: 4px;
      transition: 0.5s;
    }
    .knowledge-base-screen-but:hover {
      cursor: pointer;
      background-color: @activeColor2;
    }
    .knowledge-base-screen-but-boder {
      width: 50px;
      min-width: 50px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid @activeColor;
      font-size: @contentFontSize;
      background-color: @activeBackground;
      color: @activeColor;
      margin: 0 5px;
      margin-right: 10px;
      border-radius: 4px;
      transition: 0.5s;
    }
    .knowledge-base-screen-but-boder:hover {
      cursor: pointer;
      background-color: @activeBackground2;
    }
  }
  .knowledge-base-table {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    position: relative;
    .knowledge-base-table-card-content-tip {
      width: 98%;
      height: 4vh;
      position: absolute;
      top: 2vh;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .knowledge-base-table-card-active {
        flex: 1;
      }
      .knowledge-base-table-card-edit {
        width: 30px;
        min-width: 30px;
        margin-right: 10px;
        z-index: 99;
        display: flex;
        align-items: center;
        justify-content: center;
        color: @editColor;
        cursor: pointer;
      }
    }
    .knowledge-base-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .knowledge-base-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        .knowledge-base-table-card-tab {
          width: 80px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .knowledge-base-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .knowledge-base-table-card-active {
          flex: 1;
        }
        .knowledge-base-table-card-edit {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: @editColor;
          cursor: pointer;
        }
      }
      .knowledge-base-table-card-content {
        flex: 1;
        width: 98%;
        height: 0;
        display: flex;
        align-content: center;
        justify-content: center;
        font-size: @contentFontSize;
        color: @closeColor;
      }
      .knowledge-base-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }
  .hri {
    display: flex;
    i {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 3px;
      font-size: 14px;
      color: @activeColor;
    }
  }
}
</style>
