<template>
  <div id="commodity-setting">
    <!-- 常用商品设置 -->
    <div class="screen">
      <div class="screen-line">
        <div class="commodity-setting-screen-search">
          <el-select
            v-model="searchData.select_data"
            placeholder="请选择客户"
            @change="initTableData"
            clearable
            filterable
          >
            <el-option
              v-for="item in clientList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="commodity-setting-screen-search">
          <el-input
            placeholder="请输入商品名称/分类编号"
            suffix-icon="el-icon-search"
            v-model="searchData.input"
            @keyup.enter.native="initTableData"
          >
          </el-input>
        </div>
        <div class="commodity-setting-screen-checked"></div>
        <div class="commodity-setting-screen-active"></div>
        <div
          class="commodity-setting-screen-but-select"
          @click="handleClick({}, 0)"
          v-showFunction="{
            active: 'addCommonCustomer',
            array: allFunctionButtons,
          }"
        >
          添加常用商品
        </div>
      </div>
    </div>

    <div class="table-content">
      <div class="commodity-setting-content">
        <el-table
          ref="singleTable"
          :data="tableData"
          highlight-current-row
          style="width: 100%"
          height="98%"
          @selection-change="handleSelectionChange"
          v-loading="loading"
        >
          <el-table-column type="selection" width="55" fixed> </el-table-column>

          <el-table-column
            type="index"
            width="55"
            label="序号"
            fixed
            align="center"
          ></el-table-column>
          <el-table-column prop="name" label="商品名称"></el-table-column>
          <el-table-column prop="clientName" label="客户名称"></el-table-column>
          <el-table-column prop="codeNum" label="分类编号"></el-table-column>
          <el-table-column prop="codeName" label="分类"></el-table-column>
          <!-- <el-table-column prop="size" label="规格"></el-table-column>
          <el-table-column prop="unit" label="单位"></el-table-column>
          <el-table-column prop="taxRate" label="税率"></el-table-column> -->
          <el-table-column label="操作">
            <template slot-scope="scope">
              <!-- <el-button type="text" @click="tableClick(scope.row, 0)"
                >编辑</el-button
              > -->
              <el-button
                type="text"
                @click="tableClick(scope.row, 1)"
                v-showFunction="{
                  active: 'deleteCommonCustomer',
                  array: allFunctionButtons,
                }"
                >移除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-dialog
      title="货物编号选择"
      :visible.sync="goodsDialog.flag"
      width="1250px"
      :before-close="handleClose"
      :modal="false"
      top="10vh"
    >
      <div class="goods-dialog-content">
        <commondity
          :commondityData="goodsDialog.data"
          @checkCode="checkCode"
        ></commondity>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as invoiceData from "@/assets/js/invoiceData.js";
import my_drawer from "@/components/my-drawer.vue";
import my_dialog from "@/components/dialog.vue";
import commondity from "@/components/commodity.vue";
export default {
  components: {
    my_drawer,
    my_dialog,
    commondity,
  },
  data() {
    return {
      baseInfo: {},
      loading: true,
      whetherAgencyHead: false,
      searchData: {
        input: "",
        select_data: "",
        checked: false,
        screenButton_add: 0,
        screenButton_improt: 0,
      },

      tableData: [
        {
          id: "",
          name: "渔业", //名称
          codeNum: "1010000000000000000", // 分类编号
          codeName: "农产品", // 分类名称
          size: "cc", // 规格
          unit: "cc", // 单位
          taxRate: "3", // 税率
        },
      ],

      clientList: [],

      goodsDialog: {
        flag: false,
        data: {
          treeData: [],
          tableData: [],
          name: "",
        },
      },

      buttonAuth: [],
      multipleSelection: [],
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "CommoditySetting"
      ),
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "CommoditySetting",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          // this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getAllClientList();
      });
    },
    /**
     * 获取所有客户列表
     */
    getAllClientList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: [this.baseInfo.userId],
        type: "全部客户", // 与业务角色对应(对应关系暂定 20210618)
        hasLeave: "0", // 是否包含流失,"0"为否,"1"为是
        currentPage: 1, // 当前页数
        pageSize: 100, // 每页条数
        search: "",
        sortProp: "followTime",
        sortOrder: "descending",
        screenList: [],
      };
      this.$request(
        "post",
        "/client/getAllClientByUserId",
        requestData,
        (data) => {
          console.log("获取所有客户数据", data);
          this.clientList = [];
          data.tableData.forEach((element) => {
            let dd = {
              label: element.clientName,
              value: element.id,
            };
            this.clientList.push(dd);
          });
          console.log("获取所有客户数据之后", this.clientList);
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      this.loading = true;
      let requestData = {
        clientId: this.searchData.select_data,
        companyId: this.baseInfo.company.companyId,
        search: this.searchData.input,
      };
      this.$request(
        "post",
        "/activeCommodity/queryAllCommodity",
        requestData,
        (data) => {
          this.tableData = data;
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 多选框事件
     */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * 界面操作事件
     */
    handleClick(data, flag) {
      let requestData = {};
      switch (flag) {
        case 0:
          // 添加常用商品
          if (
            this.searchData.select_data == "" ||
            this.searchData.select_data == "全部客户"
          ) {
            this.$message({
              message: "请先选择客户!",
              type: "warning",
            });
            return;
          }
          this.goodsDialog.flag = true;
          break;

        default:
          break;
      }
    },
    /**
     * 确认选择货物编号
     */
    checkCode(data) {
      console.log("确认选择货物编号:", data);
      let requestData = {
        name: data.name, //名称
        clientId: this.searchData.select_data, // 客户ID
        codeNum: data.num, // 分类编号
        codeName: data.abb, // 分类名称
        size: "", // 规格
        unit: "", // 单位
        taxRate: "", // 税率
        des: data.remark,
      };
      this.$request(
        "post",
        "/activeCommodity/addCommodity",
        requestData,
        (data) => {
          this.$message({
            message: "常用商品添加成功!",
            type: "success",
            duration: 1000,
          });
          this.goodsDialog.flag = false;
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 表格操作事件
     */
    tableClick(item, flag) {
      let requestData = {};
      switch (flag) {
        case 0:
          // 编辑常用商品

          break;
        case 1:
          // 移除常用商品
          requestData = {
            id: item.id,
          };
          this.$request(
            "post",
            "/activeCommodity/deleteCommodity",
            requestData,
            (data) => {
              this.$message({
                message: "常用商品移除成功!",
                type: "success",
                duration: 1000,
              });
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;

        default:
          break;
      }
    },
    /**
     * 折扣行dialog关闭事件
     */
    handleClose() {
      this.goodsDialog.flag = false;
    },
  },
  mounted() {
    this.loading = true;
    this.isCharge();
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#commodity-setting {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .commodity-setting-screen-fiftrate {
    width: 65px;
    min-width: 65px;
    height: 30px;
    border: 1px solid @boderColor;
    box-sizing: border-box;
    color: @boderActiveColor;
    font-size: @contentFontSize;
    margin: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    transition: 0.3s;
    i {
      margin: 0 4px;
    }
  }
  .commodity-setting-screen-fiftrate:hover {
    cursor: pointer;
    border-color: @boderActiveColor;
  }
  .commodity-setting-screen-search {
    width: 210px;
    min-width: 210px;
    height: 30px;
    margin: 0 5px;
  }
  .commodity-setting-screen-checked {
    width: 140px;
    height: 30px;
    min-width: 140px;
    font-size: @contentFontSize;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .commodity-setting-screen-active {
    flex: 1;
  }
  .commodity-setting-screen-but-select {
    width: 140px;
    min-width: 160px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @activeColor;
    font-size: @contentFontSize;
    color: #fff;
    margin: 0 5px;
    border-radius: 4px;
    transition: 0.5s;
    cursor: pointer;
    .commodity-setting-screen-but-select_but {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.5s;
    }
    .commodity-setting-screen-but-select_but:hover {
      background-color: @activeColor2;
    }
    .commodity-setting-screen-but-select_select {
      width: 30px;
      height: 100%;
      border-left: 1px solid @activeColor3;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .commodity-setting-screen-but {
    width: 100px;
    min-width: 100px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @activeColor;
    font-size: @contentFontSize;
    color: #fff;
    margin: 0 5px;
    border-radius: 4px;
    transition: 0.5s;
  }
  .commodity-setting-screen-but:hover {
    cursor: pointer;
    background-color: @activeColor2;
  }
  .commodity-setting-screen-but-boder {
    width: 50px;
    min-width: 50px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid @activeColor;
    font-size: @contentFontSize;
    background-color: @activeBackground;
    color: @activeColor;
    margin: 0 5px;
    margin-right: 10px;
    border-radius: 4px;
    transition: 0.5s;
  }
  .commodity-setting-screen-but-boder:hover {
    cursor: pointer;
    background-color: @activeBackground2;
  }
  .table-content {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    .commodity-setting-content {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .line-cell {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        cursor: pointer;
        i {
          margin: 5px;
        }
      }
      .gray {
        color: rgb(146, 146, 146, 0.637);
      }
      .orange {
        color: rgba(255, 115, 0, 0.637);
      }
      .green {
        color: rgb(9, 255, 0, 0.637);
      }
    }
  }

  .goods-dialog-content {
    width: 1200px;
    height: 600px;
    overflow-y: auto;
    display: flex;
    // align-items: center;
    // justify-content: flex-start;
    &::-webkit-scrollbar {
      width: 1px;
    }
  }
}
</style>