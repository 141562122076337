<template>
  <div id="report">
    <!-- 报表界面 -->
    <div class="report-menu">
      <div v-for="(item, index) in menuData" :key="index">
        <div
          class="report-menu-lines"
          v-if="item.children.length > 0 && item.flag == 1"
        >
          <div class="report-menu-line">
            <i :class="item.icon"></i> {{ item.title }}
          </div>
          <div
            :class="{
              'report-menu-line-item': true,
              'is-active': menu_index == it.path,
            }"
            v-for="(it, ii) in item.children"
            :key="ii"
            v-show="it.flag == 1"
            @click="changeMenu(it)"
          >
            {{ it.title }}
          </div>
        </div>
        <div
          :class="{
            'report-menu-line': true,
            'is-active': menu_index == item.path,
          }"
          @click="changeMenu(item)"
          v-else-if="item.flag == 1"
        >
          <i :class="item.icon"></i>{{ item.title }}
        </div>
      </div>
    </div>
    <div class="report-content">
      <router-view v-if="isRouterAlive" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      baseInfo: {},
      isRouterAlive: true,
      menu_index: "report-collection",
      pathList: [],
      menuData: [
        {
          title: "报表",
          icon: "el-icon-s-help",
          path: "1",
          flag: 0,
          children: [
            {
              title: "收款报表",
              path: "report-collection",
              active: "reportCollection",
              flag: 0,
            },
            {
              title: "销售统计",
              path: "report-sales",
              active: "reportSales",
              flag: 0,
            },
            {
              title: "续费统计",
              path: "report-renewal",
              active: "reportRenewal",
              flag: 0,
            },
            {
              title: "跟进记录",
              path: "report-follow",
              active: "reportFollow",
              flag: 0,
            },
            // {
            //   title: "报销报表",
            //   path: "report-reimburse",
            //   active: "reportReimburse",
            //   flag: 0,
            // },
            {
              title: "话单列表",
              path: "call-list",
              active: "callList",
              flag: 0,
            },
            {
              title: "日账单",
              path: "call-day",
              active: "callDay",
              flag: 0,
            },
            {
              title: "复制记录",
              path: "copy-record",
              active: "copyRecord",
              flag: 0,
            },
            {
              title: "失败列表",
              path: "fail-list",
              active: "failList",
              flag: 0,
            },
            {
              title: "发票报表",
              path: "report-invoice",
              active: "reportInvoice",
              flag: 0,
            },
          ],
        },
        // {
        //   title: "员工信息",
        //   icon: "el-icon-user-solid",
        //   path: "employee-information",
        //   active: "employeeInformation",
        //   flag: 0,
        //   children: [],
        // },
      ],
    };
  },
  methods: {
    /**
     * 获取初始菜单设置的按钮权限
     */
    getUserAuthorityByModels() {
      this.$authority.getUserAuthorityByModels(
        this,
        this.menuData,
        (data, pathList) => {
          this.menuData = data;
          this.pathList = pathList;
          if (this.pathList.length > 0) {
            this.menu_index =
              sessionStorage.getItem("menuSecondActiveIndex") &&
              this.pathList.indexOf(
                sessionStorage.getItem("menuSecondActiveIndex")
              ) > -1
                ? sessionStorage.getItem("menuSecondActiveIndex")
                : this.pathList[0];
            sessionStorage.setItem("menuSecondActiveIndex", this.menu_index);

            this.$router.push("/main/report/" + this.menu_index);
          } else {
            this.$router.push("/main/index");
          }
        }
      );
    },
    changeMenu(data) {
      if (data.flag == 1) {
        this.menu_index = data.path;
        if (data.path == "report-reimburse") {
          this.$message.error(this.$errorMessage.getErrorMessageInfo("system"));
          return;
        }
        sessionStorage.setItem("menuSecondActiveIndex", this.menu_index);
        this.$router.push("/main/report/" + data.path);
      } else {
        this.$message.error(
          this.$errorMessage.getErrorMessageInfo("authority")
        );
      }
    },
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getUserAuthorityByModels();
  },
};
</script>

<style lang="less" scope>
#report {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #fff;
  .report-menu {
    width: 180px;
    height: 100%;
    // min-width: 180px;
    min-width: 9vw;
    border-right: 1px solid @boderColor;
    overflow: hidden;
    font-size: @contentFontSize;
    .report-menu-lines {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .report-menu-line-item,
    .report-menu-line {
      width: 100%;
      height: 40px;
      display: flex;
      padding-left: 10px;
      transition: 0.3s;
      align-items: center;
      border-left: 4px solid #fff;
      box-sizing: border-box;
      i {
        margin-right: 10px;
      }
    }
    .report-menu-line-item {
      padding-left: 53px;
    }
    .report-menu-line {
      font-weight: 600;
    }
    .report-menu-line-item:hover,
    .report-menu-line:hover {
      cursor: pointer;
      background-color: @activeBackground;
    }
    .is-active {
      background-color: @activeBackground;
      border-left: 4px solid @activeColor;
      box-sizing: border-box;
      color: @activeColor;
    }
  }
  .report-content {
    flex: 1;
    height: 100%;
    position: relative;
  }
}
</style>
