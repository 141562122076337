<template>
  <div id="re-new-contract">
    <!-- 新建合同 -->
    <div class="re-new-contract-line">
      <div class="re-new-contract-line-title">客户名称:</div>
      <div class="re-new-contract-line-value">{{ value.clientName }}</div>
    </div>

    <div class="content">
      <div class="list">
        <div class="title">产品类型：{{ serviceType.typeName }}</div>
        <div class="con">
          <div
            :class="{ line: true, 'left-active': serviceType == item }"
            v-for="(item, index) in serviceTtypeOptions"
            :key="index"
            @click="changeServiceType(item)"
          >
            {{ item.typeName }}
          </div>
        </div>
      </div>
      <div class="split"></div>
      <div class="list">
        <div class="title">请选择具体产品：</div>
        <div class="con">
          <div
            class="line"
            v-for="(item, index) in productList"
            :key="index"
            @click="choseProductTipOne(item)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["newContract"],
  data() {
    return {
      baseInfo: {},
      value: this.newContract,
      //   value: {
      //     clientName: "",
      //   },
      serviceType: {},
      serviceTtypeOptions: [],
      productList: [],
    };
  },
  watch: {
    newContract() {
      //父组件值变化->>子组件也变化
      this.value = this.newContract;
    },
    value(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {
    /**
     * 选择服务类别,显示具体合同模板
     */
    changeServiceType(val) {
      this.serviceType = val;
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        serviceId: val.id,
      };
      this.$request(
        "post",
        "/product/getAllProductByServiceType",
        requestData,
        (data) => {
          this.productList = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 选择合同模板单击事件
     */
    choseProductTipOne(data) {
      this.$emit("choseProductTipOne", data);
    },
  },
  mounted() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    let requestData = {
      companyId: this.baseInfo.company.companyId,
    };
    this.$request(
      "post",
      "/serveType/getAllServeType",
      requestData,
      (data) => {
        this.serviceTtypeOptions = data;
        if (data.length > 0) {
          this.serviceType = data[0];
          this.changeServiceType(data[0]);
        }
      },
      (errorMessage) => {
        this.$message.error(errorMessage);
      }
    );
  },
};
</script>

<style lang="less" scoped>
#re-new-contract {
  width: 100%;
  height: 500px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  .re-new-contract-line {
    width: 100%;
    height: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    .re-new-contract-line-title {
      width: auto;
      height: 100%;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-right: 5px;
    }
    .re-new-contract-line-value {
      flex: 1;
      height: 100%;
      width: 0;
      display: flex;
      align-items: center;
    }
  }

  > .content {
    width: 100%;
    height: 50vh;
    max-height: 50vh;
    display: flex;
    > .split {
      width: 1px;
      height: 100%;
      background-color: @boderColor;
      margin-right: 10px;
    }
    > .list {
      width: 0;
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      > .title {
        width: 100%;
        height: 50px;
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 5px;
        box-sizing: border-box;
        font-size: 13px;
        color: @activeColor;
      }

      > .con {
        width: 100%;
        height: 420px;
        min-height: 420px;
        max-height: 420px;
        overflow: auto;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        > .line {
          width: auto;
          height: 50px;
          padding: 0 20px;
          border: 1px solid @boderColor;
          box-sizing: border-box;
          margin: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          border-radius: 3px;
          transition: 0.3s;
          &:hover {
            cursor: pointer;
            border-color: @activeColor;
            color: @activeColor;
            transform: scale(1.03);
          }
        }
        > .left-active {
          border-color: @activeColor;
          color: @activeColor;
        }
      }
    }
  }

  .re-new-contract-choose {
    width: 100%;
    height: 0;
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    // flex-flow: row wrap;
    align-content: flex-start;
    &::-webkit-scrollbar {
      width: 1px;
    }
    .div-border {
      width: 170px;
      height: 100px;
      box-sizing: border-box;
      border: 1px solid @boderColor;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      margin: 5px;
      transition: 0.3s;
      &:hover {
        background-color: @editGrayColor;
        color: @activeColor;
        cursor: pointer;
        border-color: @activeColor3;
      }
    }
  }
  .select-box span:focus-within {
    border: none;
    outline: none;
  }
}
</style>