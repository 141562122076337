<template>
  <div id="drager">
    <div class="drager-model">
      <div class="drager-model-span">
        已选
        <div>可拖动调整显示顺序(右击可设置为隐藏)</div>
      </div>
      <div class="drager-model-tips">
        <!-- :class="{ 'drager-model-tip': index > 0 }" -->
        <div
          class="drager-model-tip"
          v-for="(item, index) in data.selectMenuData"
          :key="index"
          @contextmenu="changeStatus(0, item)"
          v-dragging="{
            list: data.selectMenuData,
            item: item,
            group: 'key',
          }"
        >
          {{ item.title }}
          <!-- {{ index > 0 ? item.title : "" }} -->
        </div>
      </div>
    </div>
    <div class="drager-model">
      <div class="drager-model-span">
        可选择展示的字段
        <div>右击可设置为显示</div>
      </div>

      <div class="drager-model-tips">
        <div
          class="drager-model-tip"
          v-for="(item, index) in data.chooseableMenuData"
          :key="index"
          @contextmenu="changeStatus(1, item)"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["dragerData"],
  data() {
    return {
      data: this.dragerData,
      // data: JSON.parse(JSON.stringify(this.dragerData)),
    };
  },

  watch: {
    dragerData() {
      //父组件值变化->>子组件也变化
      this.data = this.dragerData;
    },
    data(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {
    changeStatus(index, item) {
      let that = this;
      document.oncontextmenu = function () {
        //   关闭浏览器右击事件
        return false;
      };
      this.$emit("changeStatus", { index, item });
      // if (index == 0) {
      //   item.isDisable = false;
      //   this.data.selectMenuData.splice(
      //     this.data.selectMenuData.indexOf(item),
      //     1
      //   );
      //   this.data.chooseableMenuData.push(item);
      // } else {
      //   item.isDisable = true;
      //   this.data.chooseableMenuData.splice(
      //     this.data.chooseableMenuData.indexOf(item),
      //     1
      //   );
      //   this.data.selectMenuData.push(item);
      // }
      // this.$emit("changeStatus", { index, item});
    },
  },
  mounted() {
    // 自定义导航顺序
    this.$dragging.$on("dragged", ({ value }) => {
      this.$emit("dragging", value);
    });
  },
};
</script>

<style lang="less" scoped>
#drager {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .drager-model {
    flex: 1;
    width: 100%;
    margin: 10px;
    display: flex;
    flex-direction: column;
    .drager-model-span {
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      font-size: @contentFontSize2;
      > div {
        font-size: @contentFontSize;
        margin-left: 10px;
        color: @tipColor;
      }
    }
    .drager-model-tips {
      height: auto;
      width: 96%;
      display: flex;
      flex-wrap: wrap;
      .drager-model-tip {
        width: auto;
        height: 25px;
        margin: 10px;
        padding: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @boderActiveColor;
        transition: 0.3s;
        font-size: @contentFontSize;
      }
      .drager-model-tip:hover {
        cursor: pointer;
        border: 1px dashed @activeColor;
        background-color: @activeBackground;
        color: @activeColor;
      }
    }
  }
}
</style>