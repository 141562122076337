<template>
  <div id="select-electronic-contract">
    <div class="line">
      <div class="title">
        <div class="text">签约人:</div>
        <div class="value">
          <el-input
            v-model="value.signName"
            placeholder="请输入内容"
          ></el-input>
        </div>
      </div>
    </div>
    <div class="line">
      <div class="title">
        <div class="text">签约主体:</div>
        <div class="value">
          <el-select
            v-model="value.signInfo"
            filterable
            placeholder="请选择"
            value-key="id"
          >
            <el-option
              v-for="item in signInfoList"
              :key="item.id"
              :label="item.name"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="content" v-if="value.signInfo.id">
        <div class="item">
          <div class="title">公司名称:</div>
          <div class="text">{{ value.signInfo.companyName }}</div>
        </div>
        <div class="item">
          <div class="title">开户银行:</div>
          <div class="text">{{ value.signInfo.bankName }}</div>
        </div>
        <div class="item">
          <div class="title">银行账号:</div>
          <div class="text">{{ value.signInfo.bankAccount }}</div>
        </div>
        <div class="item">
          <div class="title">法定代表人:</div>
          <div class="text">{{ value.signInfo.legal }}</div>
        </div>
        <div class="item">
          <div class="title">电话:</div>
          <div class="text">{{ value.signInfo.tel }}</div>
        </div>
        <div class="item">
          <div class="title">地址:</div>
          <div class="text">{{ value.signInfo.address }}</div>
        </div>
      </div>
    </div>
    <div class="line">
      <div class="title">
        <div class="text">客户联系人:</div>
        <div class="value">
          <el-input
            v-model="contactName"
            placeholder="请输入联系人名称"
          ></el-input>
        </div>
        <div class="but" @click="addContent">
          <i class="el-icon-plus"></i>添加
        </div>
      </div>
      <div class="content" v-if="value.contact.length > 0">
        <div class="item1" v-for="(item, index) in value.contact" :key="index">
          <div class="title">{{ item.name }}:</div>
          <div class="value">
            <el-input
              v-model="item.phone"
              placeholder="请输入联系方式1"
            ></el-input>
            <el-input
              v-model="item.mobile"
              placeholder="请输入联系人方式2"
            ></el-input>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              size="mini"
              @click="deleteContact(index)"
            ></el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="line">
      <div class="title">
        <div class="text">选择合同模板:</div>
      </div>
      <div class="content">
        <div
          :class="{ item2: true, active: contractModel == item }"
          v-for="(item, index) in contractModelList"
          :key="index"
          @click="contractModel = item"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
    <div class="line">
      <div class="but">
        <span @click="toGenerateElectronicContract">生成电子合同</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["SelectElectronicContractData"],
  data() {
    return {
      baseInfo: {},
      value: this.SelectElectronicContractData,
      //   value: {
      //     signInfo: {},
      //     contact: [],
      //     signName: "", // 签单人
      //   },
      contactName: "",
      signInfoList: [], // 签约主体列表
      contractModelList: [], // 合同模板列表
      contractModel: {}, // 当前选中的合同模板
    };
  },
  watch: {
    SelectElectronicContractData() {
      //父组件值变化->>子组件也变化
      this.value = this.SelectElectronicContractData;
    },
    value(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {
    /**
     * 获取所有签约主体信息列表
     */
    getSignInfoList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/signInfo/getSignInfoList",
        requestData,
        (data) => {
          this.signInfoList = data;
          this.getAllContractModelList();
        },
        (errorMessage) => {
          this.signInfoList = data;
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有合同模板
     */
    getAllContractModelList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/contractModel/getAllContractModelList",
        requestData,
        (data) => {
          this.contractModelList = data;
        },
        (errorMessage) => {
          this.signInfoList = [];
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 添加联系人
     */
    addContent() {
      let data = {
        name: this.contactName,
        phone: "",
        mobile: "",
      };
      if (this.contactName != "") {
        this.value.contact.push(JSON.parse(JSON.stringify(data)));
      }
      this.contactName = "";
    },
    /**
     * 移除联系人
     */
    deleteContact(index) {
      this.value.contact.splice(index, 1);
    },
    /**
     * 确定生成电子合同
     */
    toGenerateElectronicContract() {
      console.log(this.contractModel);
      let phone = "";
      let flag = true;
      let that = this;
      this.value.contact.forEach((element) => {
        if (
          (element.phone != "" &&
            !this.$phoneCheck.mobilePhoneNumberCheck(element.phone)) ||
          (element.mobile != "" &&
            !this.$phoneCheck.mobilePhoneNumberCheck(element.mobile))
        ) {
          flag = false;
        }
      });
      if (!flag) {
        this.$message({
          message: "请填写正确的联系方式!",
          type: "error",
        });
        return;
      }
      this.value.contact.forEach((element) => {
        if (element.phone != "") {
          if (phone == "") {
            phone = element.phone;
          } else {
            phone = phone + "," + element.phone;
          }
        }
        if (element.mobile != "") {
          if (phone == "") {
            phone = element.mobile;
          } else {
            phone = phone + "," + element.mobile;
          }
        }
      });
      if (this.value.signName == "") {
        this.$message({
          message: "签单人不能为空!",
          type: "error",
        });
        return;
      }
      if (!this.value.signInfo.id || this.value.signInfo.id == "") {
        this.$message({
          message: "请先选择签约主体!",
          type: "error",
        });
        return;
      }
      if (phone == "") {
        this.$message({
          message: "请至少添加一个联系方式!",
          type: "error",
        });
        return;
      }
      if (!this.contractModel.value) {
        this.$message({
          message: "请先选择合同模板!",
          type: "error",
        });
        return;
      }
      let data = {
        signInfoId: this.value.signInfo.id,
        phone: phone,
        contractId: this.contractModel.value,
        signName: this.value.signName,
      };
      this.$emit("toGenerateElectronicContract", data);
    },
  },
  mounted() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getSignInfoList();
  },
};
</script>

<style lang="less" scoped>
#select-electronic-contract {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  > .line {
    width: 100%;
    height: auto;
    min-height: 35px;
    display: flex;
    flex-direction: column;
    > .title {
      width: 100%;
      height: auto;
      min-height: 35px;
      display: flex;
      align-items: center;
      font-size: 13px;
      > .text {
        width: 20%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        box-sizing: border-box;
        padding-right: 5px;
        align-content: flex-start;
      }
      > .value {
        width: 0;
        flex: 1;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
      }
      .but {
        width: auto;
        height: 30px;
        border: 1px solid @activeColor;
        color: @activeColor;
        font-size: 13px;
        box-sizing: border-box;
        padding: 0 10px;
        border-radius: 3px;
        margin: 0 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          margin-right: 5px;
        }
        transition: 0.3s;
        &:hover {
          cursor: pointer;
          color: @activeColor2;
          border-color: @activeColor2;
          transform: scale(1.01);
        }
      }
    }
    > .content {
      width: 100%;
      height: auto;
      min-height: 50px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      justify-content: flex-start;
      // justify-content: space-between;
      > .item {
        width: 49%;
        height: auto;
        min-height: 35px;
        display: flex;
        align-items: center;
        font-size: 13px;
        > .title {
          width: 25%;
          height: 100%;
          display: flex;
          align-items: center;
          align-content: flex-start;
          justify-content: flex-end;
          padding-right: 3px;
          font-weight: bold;
          box-sizing: border-box;
        }
        > .text {
          width: 0;
          height: 100%;
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          text-align: left;
        }
      }
      > .item1 {
        width: 100%;
        height: 35px;
        margin-top: 5px;
        display: flex;
        align-items: center;
        font-size: 13px;
        > .title {
          width: 20%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-right: 3px;
          box-sizing: border-box;
        }
        > .value {
          width: 0;
          height: 100%;
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          > .el-input {
            width: 47% !important;
          }
          > .el-button {
            margin-left: 5px;
          }
        }
      }
      > .item2 {
        width: 150px;
        height: 80px;
        padding: 5px 15px;
        box-sizing: border-box;
        border: 1px dashed @activeColor;
        color: @activeColor;
        margin: 5px;
        transition: 0.3s;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          cursor: pointer;
          color: @tipColor;
          border-color: @tipColor;
        }
      }
      > .active {
        color: @tipColor;
        border-color: @tipColor;
      }
    }
    > .but {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        width: auto;
        height: 30px;
        box-sizing: border-box;
        border: 1px solid @activeColor;
        color: @activeColor;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        padding: 0 20px;
        transition: 0.3s;
        &:hover {
          cursor: pointer;
          color: @activeColor2;
          border-color: @activeColor2;
          transform: scale(1.01);
        }
      }
    }
  }
}
</style>