<template>
  <!-- 复制记录 -->
  <div id="copy-record">
    <div class="copy-record-screen">
      <div class="copy-record-screen-line">
        <!-- <div class="copy-record-screen-search">
          <el-cascader
            v-model="searchData.department"
            placeholder="请选择部门"
            :options="searchData.departTreeData"
            @change="initTableData"
            clearable
            :show-all-levels="false"
            :props="{ checkStrictly: true, value: 'id' }"
            filterable
          ></el-cascader>
        </div> -->
        <div class="copy-record-screen-search">
          <el-select
            v-model="searchData.userId"
            @change="selectInitTableData"
            filterable
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="copy-record-screen-search">
          <el-date-picker
            v-model="searchData.time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="initTableData"
          >
          </el-date-picker>
        </div>
        <div class="copy-record-screen-active"></div>
      </div>
    </div>

    <div class="table-content">
      <div class="copy-record-table-card">
        <div class="copy-record-content">
          <el-table
            :data="tableData"
            highlight-current-row
            style="width: 100%, overflow-x:auto"
            height="98%"
            v-loading="loading"
            row-key="id"
            :row-style="{ height: '60px' }"
          >
            <el-table-column type="index" width="40"></el-table-column>
            <el-table-column prop="userName" label="姓名"></el-table-column>
            <el-table-column
              prop="copyModule"
              label="复制模块"
            ></el-table-column>
            <el-table-column prop="name" label="客户名称"></el-table-column>
            <el-table-column
              prop="copyContent"
              label="复制内容"
            ></el-table-column>
            <el-table-column prop="date" label="复制日期"></el-table-column>
            <el-table-column prop="count" label="复制次数"></el-table-column>

            <!-- <el-table-column prop="" label="复制日期">
              <template slot-scope="scope">
                <span style="color: #409EFF;">{{
                  scope.row.beginTime
                }}</span></template
              ></el-table-column
            > -->
          </el-table>
        </div>
        <div class="copy-record-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import * as expressageData from "@/views/expressage/js/expressage.js";
import my_drawer from "@/components/my-drawer.vue";
import my_dialog from "@/components/dialog.vue";
import popo_search from "@/components/popo-search.vue";
export default {
  components: {
    my_drawer,
    my_dialog,
    popo_search,
  },
  data() {
    return {
      baseInfo: {},
      whetherAgencyHead: false,
      loading: false,
      allFunctionButtons: this.$featureData.getAllFunctionButtons("callDay"),
      tableData: [],
      searchData: {
        // allUserList: [],
        screenList: [],
        time: ["", ""],
        department: "",
        departTreeData: [],
        userId: "",
        userIdList: [],
        userList: [],
        tempUserId: [],
      },
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "新增档案",
        buttonArray: [
          {
            text: "确认",
            active: "confirm",
          },
        ],
        data: [],
      },
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "callDay",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;

        this.getDepartmentTreeByUserId();
        // this.getEmployeeListForDownBox();
      });
    },
    // /**
    //  * 获取所有人员列表
    //  */
    // getEmployeeListForDownBox() {
    //   let requestData = {
    //     companyId: this.baseInfo.company.companyId,
    //   };
    //   this.$request(
    //     "post",
    //     "/user/getEmployeeListForDownBox",
    //     requestData,
    //     (data) => {
    //       this.searchData.allUserList = data;
    //       this.searchData.allUserList.splice(0, 0, {
    //         userId: "",
    //         userName: "全部人员",
    //       });
    //     }
    //   );
    // },
    /**
     * 获取部门
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
          this.getEmployeeList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.searchData.userList = [];
          data.forEach((element) => {
            let dd = {
              name: element.userName,
              id: element.userId,
            };
            this.searchData.userIdList.push(element.userId);
            this.searchData.userList.push(dd);
          });
          this.searchData.userList.splice(0, 0, {
            id: "all",
            name: "全部人员",
          });
          this.searchData.userId = "all";
          this.selectInitTableData("all");
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取表格界面数据
     */
    initTableData() {
      this.loading = true;
      let requestData = {
        currentPage: this.pageData.currentPage, // 当前页
        pageSize: this.pageData.pageSize, // 每页条数
        date: this.searchData.time, // 开始时间, 结束时间
        // department:
        //   this.searchData.department.length > 0
        //     ? this.searchData.department[this.searchData.department.length - 1]
        //     : "",
        userIds: this.searchData.tempUserId, // 员工id集合
      };
      this.$request(
        "post",
        "/copyListening/page",
        requestData,
        (data) => {
          this.pageData.total = data.total;
          this.tableData = data.records;
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    /**
     * 界面操作
     * batch 是否为批量操作
     */
    handleClick(data, flag) {},
    /**
     * 抽屉数据或dialog数据操作事件
     */
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        default:
          break;
      }
    },
    /**
     * 员工选择
     */
    selectInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.searchData.userIdList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.initTableData();
    },

    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
      this.isEdit = false;
      this.isCopy = false;
    },
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
  mounted() {
    this.searchData.userId = "all";
  },
};
</script>

<style lang="less" scoped>
#copy-record {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .copy-record-screen {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    .copy-record-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      > .title {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 0 10px;
      }
      > .el-tag {
        margin: 0 5px;
      }
      .copy-record-screen-line-fiftrate {
        width: 65px;
        min-width: 65px;
        height: 30px;
        border: 1px solid @boderColor;
        box-sizing: border-box;
        color: @boderActiveColor;
        font-size: @contentFontSize;
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: 0.3s;
        i {
          margin: 0 4px;
        }
      }
      .copy-record-screen-line-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }
      .copy-record-screen-search {
        width: 210px;
        min-width: 210px;
        height: 30px;
        margin-left: 10px;
      }
      .copy-record-screen-active {
        flex: 1;
      }
      .drop-button {
        background-color: #ecf5ff;
        color: #409eff;
        transition: 0.5s;
        &:hover {
          background-color: #abbed3;
        }
      }
      .archives-screen-but-select {
        padding: 0px 52px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
        cursor: pointer;
      }
    }
  }

  .table-content {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;

    .copy-record-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .copy-record-content {
        width: 100%;
        height: 95%;
        max-width: 90vw;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
      }
      .copy-record-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
