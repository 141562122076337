<template>
  <div id="invoice-info">
    <!-- 发票开具 -->
    <div class="invoice-info-screen">
      <div class="invoice-info-screen-line">
        <div class="invoice-info-screen-search">
          <el-input
            placeholder="请输入客户全称/编号/税号"
            v-model="searchData.input"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="invoice-info-screen-search">
          <el-cascader
            v-model="searchData.department"
            placeholder="请选择部门"
            :options="searchData.departTreeData"
            @change="initTableData"
            clearable
            :show-all-levels="false"
            :props="{ checkStrictly: true, value: 'id' }"
            filterable
          ></el-cascader>
        </div>
        <div class="invoice-info-screen-search">
          <el-select
            v-model="searchData.userId"
            placeholder="请选择员工"
            filterable
            @change="selectInitTableData"
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="invoice-info-screen-search">
          <el-select
            v-model="searchData.addedValueTaxType"
            placeholder="请选择企业类型"
            filterable
            @change="initTableData"
          >
            <el-option
              v-for="item in searchData.companyTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <!-- <div class="invoice-info-screen-search">
          <el-checkbox
            v-model="searchData.quarter"
            :true-label="1"
            :false-label="0"
            @change="initTableData"
            >季度三十万</el-checkbox
          >
          <el-checkbox
            v-model="searchData.annual"
            :true-label="1"
            :false-label="0"
            @change="initTableData"
            >年度五百万</el-checkbox
          >
        </div> -->
        <div class="invoice-info-screen-active"></div>
      </div>
    </div>
    <div class="table-content">
      <div class="invoice-info-table-card">
        <div class="invoice-info-content">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            height="98%"
            @row-click="rowClick"
            v-loading="loading"
            @sort-change="sortChange"
            :row-class-name="rowClassName"
          >
            <el-table-column type="index" width="55" fixed align="center">
            </el-table-column>
            <el-table-column
              prop="clientName"
              label="客户名称"
              fixed
            ></el-table-column>
            <el-table-column
              prop="clientNum"
              label="客户编号"
              width="100"
              fixed
            ></el-table-column>
            <el-table-column label="操作" width="100" fixed>
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click.native.stop="handleClick(scope.row, 0)"
                  v-showFunction="{
                    active: 'create',
                    array: allFunctionButtons,
                  }"
                  >开票</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="addedValueTaxType"
              label="企业类型"
            ></el-table-column>
            <el-table-column
              prop="enterpriseNumber"
              label="纳税人识别号"
            ></el-table-column>
            <el-table-column
              prop="taxBureauUsername"
              label="电子税务局账号"
            ></el-table-column>
            <el-table-column
              prop="taxBureauPosition"
              label="电子税务局身份"
            ></el-table-column>
          </el-table>
        </div>
        <div class="invoice-info-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>

    <!-- 右边抽屉 -->
    <div id="my-drawer" v-if="invoiceInfoDrawerData.flag">
      <my_drawer
        :drawerData="invoiceInfoDrawerData"
        :allFunctionButtons="allFunctionButtons"
        :model="'invoiceInfo'"
        @show="showDrawer"
        @closeUpdateTableDrawer="closeUpdateTableDrawer"
        @drawerOper="drawerOper"
        @linkNow="linkNow"
        @delete="deleteINfo"
        :minWidth="'700px'"
      ></my_drawer>
    </div>
  </div>
</template>

<script>
import * as invoiceData from "@/assets/js/invoiceData.js";
import my_drawer from "@/components/my-drawer.vue";
import my_dialog from "@/components/dialog.vue";
export default {
  components: {
    my_drawer,
    my_dialog,
  },
  data() {
    return {
      baseInfo: {},
      loading: true,
      whetherAgencyHead: false,
      searchData: {
        input: "",
        checked: false,
        screenButton_add: 0,
        screenButton_improt: 0,
        userId: "", // 员工id
        userList: [], // 员工列表
        userIdList: [], // 员工id列表
        tempUserId: [],
        department: "",
        departTreeData: [],
        roleList: [],
        addedValueTaxType: "",
        companyTypeList: [
          {
            label: "全部",
            value: "",
          },
          {
            label: "一般纳税人",
            value: "一般纳税人",
          },
          {
            label: "小规模纳税人",
            value: "小规模纳税人",
          },
        ],
        emptyList: [
          {
            label: "小于五",
            value: "5",
          },
        ], // 空白发票列表
        quarter: 0, // 季度三十万  0 - 未选中 1- 选中
        annual: 0, // 年度五百万  0 - 未选中 1- 选中
      },
      tableData: [],
      tabindex: 0,
      invoiceInfoDrawerData: invoiceData.getInvoiceDrawerData([], ""),
      dialogData: {
        flag: false,
        width: "30%",
        title: "添加跟进记录",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },
      isCopy: false, // 是否为复制发票
      isEdit: false, // 是否为编辑发票
      radio: true, // 加急
      tempInvoiceId: "",
      tempInvoice: {},
      tempClient: {},
      tempInventory: {},
      employeeList: [],
      invoiceEditFlag: true, // 判断当前是编辑还是新增
      defaultSort: { prop: "", order: "" }, // 排序
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "invoiceInfo"
      ),
      status: "", // 发票状态
      index: 0,
      tempTableData: [],
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "invoiceInfo",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          // this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getDepartmentTreeByUserId();
      });
    },
    /**
     * 获取部门
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
          this.getEmployeeList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.employeeList = [];
          this.searchData.userList = [];
          data.forEach((element) => {
            let dd = {
              name: element.userName,
              id: element.userId,
            };
            this.searchData.userIdList.push(element.userId);
            this.employeeList.push(dd);
            this.searchData.userList.push(dd);
          });
          this.searchData.userList.splice(0, 0, {
            id: "all",
            name: "全部人员",
          });
          this.searchData.userId = this.baseInfo.userId;
          this.selectInitTableData(this.baseInfo.userId);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取表格界面数据
     */
    initTableData() {
      this.loading = true;
      // 如果选中季度三十万 或 年度五百万 公司类型改为 小规模纳税人
      if (this.searchData.quarter == 1 || this.searchData.annual == 1) {
        this.searchData.addedValueTaxType = "小规模纳税人";
      }
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        empolyeeId: this.baseInfo.userId,
        addedValueTaxType: this.searchData.addedValueTaxType, // 企业类型  一般纳税人  小规模纳税人
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
        search: this.searchData.input,
        userIdList: this.searchData.tempUserId, // 员工id
        department:
          this.searchData.department.length > 0
            ? this.searchData.department[this.searchData.department.length - 1]
            : "",
        sortProp: this.defaultSort.prop, // 手动排序项
        sortOrder: this.defaultSort.order, // 手动排序规则 ascending descending ""
        quarter: this.searchData.quarter, // 季度三十万  0 - 未选中 1- 选中
        annual: this.searchData.annual, // 年度五百万  0 - 未选中 1- 选中
      };
      this.$request(
        "post",
        "/invoice/queryInvoiceClientByCondition",
        requestData,
        (data) => {
          this.pageData.total = data.total;
          this.tableData = data.records;
          // this.tableData.forEach((item) => {
          //   // 本月开票金额
          //   item.moneyMonth = parseFloat(item.moneyMonth).toLocaleString(
          //     "en-US",
          //     {
          //       style: "currency",
          //       currency: "USD",
          //     }
          //   );
          //   item.moneyMonth = item.moneyMonth
          //     .replace("$", "")
          //     .replace(/,/g, ", ");

          //   // 本月开票税额
          //   item.taxMonth = parseFloat(item.taxMonth).toLocaleString("en-US", {
          //     style: "currency",
          //     currency: "USD",
          //   });
          //   item.taxMonth = item.taxMonth.replace("$", "").replace(/,/g, ", ");

          //   // 本季度开票金额
          //   item.moneyQuarter = parseFloat(item.moneyQuarter).toLocaleString(
          //     "en-US",
          //     {
          //       style: "currency",
          //       currency: "USD",
          //     }
          //   );
          //   item.moneyQuarter = item.moneyQuarter
          //     .replace("$", "")
          //     .replace(/,/g, ", ");

          //   // 累计12月开票金额
          //   item.moneyYear = parseFloat(item.moneyYear).toLocaleString(
          //     "en-US",
          //     {
          //       style: "currency",
          //       currency: "USD",
          //     }
          //   );
          //   item.moneyYear = item.moneyYear
          //     .replace("$", "")
          //     .replace(/,/g, ", ");
          // });
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * tab切换事件
     */
    changeTab(index) {
      this.tabindex = index;
      this.initTableData();
    },
    /**
     * 界面按钮操作
     */
    handleClick(data, flag) {
      this.tempInventory = data;
      switch (flag) {
        case 0:
          // 开票
          /**
           * 1. 根据客户名称,默认填充:分机号及状态,库存,单张开票限额,销售方名称,纳税人识别号,地址,电话,开户行及账号等信息,提供客户类型(是小规模纳税人还是一般纳税人)
           * 2. 选择开票类型:电子普通发票开具、电子专用发票开具、纸质普通发票开具、纸质专用发票开具
           *    注:纸质发票中没有收票人手机和收票人邮箱
           * 3. 填写购买方信息(必填):名称,纳税人识别号,地址,电话,开户行及账号
           *    填写货物或应税劳务、服务名称(必填),金额(必填),税率(根据客户类型选择税率)(必填),税额(计算)(必填)
           * 4. 填写收款人,复核人,开票人,
           * 5. 填写收票人手机,收票人邮箱
           */
          this.tempClient.clientId = data.id;
          this.getClientInfoByClientID(this.tempClient.clientId, "");
          break;
        default:
          break;
      }
    },
    /**
     * 开票dialog打开
     */
    getClientInfoByClientID(id, invoice_data) {
      // 1. 获取销售方信息
      let requestData = {
        id: id,
      };
      this.$request(
        "post",
        "/client/getClientInfoByClientID",
        requestData,
        (sellerData) => {
          // 2. 获取销售方 开票员信息
          this.dialogData.width = "1300px";
          this.dialogData.title = "发票开具";
          this.dialogData.buttonArray = [
            {
              text: "保存",
              active: "save",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.$set(sellerData, "clientId", id);
          this.dialogData.data = [
            {
              type: "invoice_dialog",
              title: "",
              data: invoiceData.getInvoiceDialogData(
                sellerData,
                this.baseInfo.company.companyId,
                invoice_data,
                true
              ),
            },
          ];
          console.log("发票开具dialog显示:", this.dialogData.data);
          this.dialogData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 表格单行点击事件
     */
    rowClick(row, column, event) {
      if (!this.invoiceInfoDrawerData.flag) {
        //防止重复点击
        this.tempClient = row;
        this.tempClient.clientId = row.id;
        this.showInvoiceInfoDrawerData();
        // this.invoiceInfoDrawerData.flag = true;
      } else {
        this.closeUpdateTableDrawer();
      }
    },
    /**
     * 抽屉界面打开
     */
    showInvoiceInfoDrawerData() {
      console.log("this.tempClient.clientId", this.tempClient.clientId);
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        clientId: this.tempClient.clientId,
        status: this.status,
        type: this.invoiceInfoDrawerData.data.type, // 发票类型
        sellerName: this.tempClient.clientName, // 购买方名称
        buyerName: this.invoiceInfoDrawerData.data.buyerName, // 购买方名称
        billingDate: this.invoiceInfoDrawerData.data.time, // 时间
      };
      this.$request(
        "post",
        "/invoice/queryInvoiceByClientId",
        requestData,
        (data) => {
          data.forEach((item) => {
            item.billingDate = this.$formatTime(item.billingDate, "YYYY-MM-DD");
          });
          this.invoiceInfoDrawerData = invoiceData.getInvoiceDrawerData(
            data,
            this.tempClient.clientName,
            this.invoiceInfoDrawerData.data.buyerName,
            this.invoiceInfoDrawerData.data.time,
            this.invoiceInfoDrawerData.data.type
          );
          this.invoiceInfoDrawerData.cardIndex = this.index; // 表格状态
          this.invoiceInfoDrawerData.multipleSelection = []; // 表格可选
          this.invoiceInfoDrawerData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 发票信息预览
     */
    linkNow(data) {
      console.log("发票详细信息:", data);
      this.$request(
        "get",
        `/invoice/getInvoice?id=${data.item.id}`,
        {},
        (redata) => {
          this.isEdit = false;
          this.isCopy = false;
          this.tempInvoiceId = redata.id;
          this.dialogData.title = "发票预览";
          this.dialogData.width = "1300px";
          this.dialogData.buttonArray = [];
          if (data.item.status == "未开票") {
            this.dialogData.buttonArray.push({
              text: "编辑",
              active: "edit",
            });
          }
          this.dialogData.buttonArray.push({
            text: "复制",
            active: "copy",
          });
          this.dialogData.buttonArray.push({
            text: "取消",
            active: "cancel",
          });

          this.tempInvoice = JSON.parse(JSON.stringify(redata));
          this.dialogData.data = [
            {
              type: "invoice",
              title: "",
              data: invoiceData.getInvoiveDetails(redata),
            },
          ];
          this.dialogData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 抽屉数据或dialog数据操作事件
     */
    drawerOper(active) {
      let requestData = {};
      let that = this;
      switch (active) {
        case "edit":
          // 发票编辑
          that.isEdit = true;
          this.getClientInfoByClientID(
            this.tempClient.clientId,
            this.tempInvoice
          );
          break;
        case "copy":
          // 发票复制
          that.isCopy = true;
          let ttInvoiceData = JSON.parse(JSON.stringify(that.tempInvoice));
          ttInvoiceData.id = "";
          console.log("临时发票数据:", ttInvoiceData);
          that.$message({
            type: "success",
            message: "发票已复制!",
            duration: 1000,
          });
          this.dialogData.data = [];
          this.getClientInfoByClientID(this.tempClient.clientId, ttInvoiceData);
          break;
        case "save":
          // 发票保存
          this.saveInvoiceDetails().then(() => {
            if (this.isEdit || this.isCopy) {
              that.showInvoiceInfoDrawerData();
            }
            this.$message({
              type: "success",
              message: "发票保存成功!",
              duration: 1000,
            });
          });
          break;

        case "全部":
          this.status = "";
          this.index = 0;
          this.showInvoiceInfoDrawerData();
          break;
        case "未开票":
          this.status = "01";
          this.index = 1;
          this.showInvoiceInfoDrawerData();
          break;
        case "开票成功":
          this.status = "02";
          this.index = 2;
          this.showInvoiceInfoDrawerData();
          break;
        case "开票失败":
          this.status = "03";
          this.index = 3;
          this.showInvoiceInfoDrawerData();
          break;
        case "export":
          let ids = this.invoiceInfoDrawerData.multipleSelection.map((item) => {
            return item.id;
          });
          if (ids.length == 0) {
            this.$message.error("请先选择要导出的发票!");
            return;
          }
          let requestData3 = {
            ids: ids,
          };
          window.location.href =
            this.$baseurl +
            "/invoiceInfo/exportExcel?data=" +
            JSON.stringify(requestData3);
          break;
        case "search":
          // 搜索购买方名称
          this.showInvoiceInfoDrawerData();
          break;
        case "cancel":
          // 取消
          this.dialogData.flag = false;
          this.isEdit = false;
          this.isCopy = false;
          break;
        default:
          break;
      }
    },

    /**
     * 保存发票
     */
    saveInvoiceDetails() {
      let that = this;
      let p = new Promise(function(resolve, reject) {
        if (
          that.dialogData.data[0].data.specialFieldEcho &&
          JSON.stringify(that.dialogData.data[0].data.specialFieldEcho) !== "{}"
        ) {
          that.dialogData.data[0].data.specialField = invoiceData.transSpecialField(
            that.dialogData.data[0].data
          );
        }
        let requestData = {
          ...that.dialogData.data[0].data,
          createUserId: that.baseInfo.userId, // 创建人 id
          // inOrOut: "1",
        };
        requestData.paperTicketCodeEcho = JSON.stringify(
          requestData.paperTicketCodeEcho
        );
        requestData.specialField = JSON.stringify(requestData.specialField);
        requestData.specialFieldEcho = JSON.stringify(
          requestData.specialFieldEcho
        );
        requestData.collectField = JSON.stringify(requestData.collectField);
        requestData.collectFieldEcho = JSON.stringify(
          requestData.collectFieldEcho
        );
        if (that.isEdit) {
          that.$request(
            "post",
            "/invoice/updInvoice",
            requestData,
            (data) => {
              if (!data.flag) {
                // 当前客户不是常用客户,提示是否添加进常用客户
                that
                  .$confirm("是否将此客戶添加进常用客戶?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                  })
                  .then(() => {
                    // 添加常用客户
                    let customersData = {
                      id: that.tempInvoiceId, // id
                      name: requestData.buyerName, // 名称,
                      clientId: requestData.clientId, // 客户ID,
                      taxNum: requestData.buyerTaxpayerNum, // 企业税号,
                      tel: requestData.buyerTel, // 电话,
                      bank: requestData.buyerBankName, // 开户行,
                      bankAccount: requestData.buyerBankAccount, // 银行账号,
                      address: requestData.buyerAddress, // 详细地址,
                    };
                    that.$request(
                      "post",
                      "/activeCustomer/addCustomers",
                      customersData,
                      (dd) => {
                        that.$message({
                          type: "success",
                          message: "常用客户添加成功!",
                          duration: 1000,
                        });
                        that.dialogData.flag = false;
                        that.showInvoiceInfoDrawerData();
                      },
                      (errorMessage) => {
                        that.$message.error(errorMessage);
                      }
                    );
                  })
                  .catch(() => {
                    that.isEdit = false;
                    that.isCopy = false;
                    that.dialogData.flag = false;
                    that.showInvoiceInfoDrawerData();
                  });
              } else {
                that.dialogData.flag = false;
                that.showInvoiceInfoDrawerData();
              }
              resolve(true);
            },
            (errorMessage) => {
              reject(errorMessage);
              that.$message.error(errorMessage);
            }
          );
        } else {
          that.$request(
            "post",
            "/invoice/saveInvoice",
            requestData,
            (data) => {
              that.tempInvoiceId = data.id;
              if (!data.flag) {
                // 当前客户不是常用客户,提示是否添加进常用客户
                that
                  .$confirm("是否将此客戶添加进常用客戶?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                  })
                  .then(() => {
                    // 添加常用客户
                    let customersData = {
                      name: requestData.buyerName, // 名称,
                      clientId: requestData.clientId, // 客户ID,
                      taxNum: requestData.buyerTaxpayerNum, // 企业税号,
                      tel: requestData.buyerTel, // 电话,
                      bank: requestData.buyerBankName, // 开户行,
                      bankAccount: requestData.buyerBankAccount, // 银行账号,
                      address: requestData.buyerAddress, // 详细地址,
                    };
                    that.$request(
                      "post",
                      "/activeCustomer/addCustomers",
                      customersData,
                      (dd) => {
                        that.$message({
                          type: "success",
                          message: "常用客户添加成功!",
                          duration: 1000,
                        });

                        that.dialogData.flag = false;
                      },
                      (errorMessage) => {
                        that.$message.error(errorMessage);
                      }
                    );
                  })
                  .catch(() => {
                    that.dialogData.flag = false;
                  });
              } else {
                that.dialogData.flag = false;
              }
              resolve(true);
            },
            (errorMessage) => {
              reject(errorMessage);
              that.$message.error(errorMessage);
            }
          );
        }
      });
      return p;
    },
    /**
     * 保存抽屉数据
     */
    saveDrawerData() {},
    /**
     * 右边抽屉关闭事件
     */
    closeUpdateTableDrawer() {
      this.initTableData();
      this.invoiceInfoDrawerData.flag = false;
      this.status = "";
      this.index = 0;
      // this.saveDrawerData();
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
      this.isEdit = false;
      this.isCopy = false;
    },
    /**
     * 右边抽屉父子组件连锁事件
     */
    showDrawer(value) {
      this.invoiceInfoDrawerData = value;
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },

    /**
     * 员工选择
     */
    selectInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.searchData.userIdList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.initTableData();
    },
    /**
     * 表格排序事件
     */
    sortChange(data) {
      this.defaultSort.prop = data.order == null ? "" : data.prop;
      this.defaultSort.order = data.order == null ? "" : data.order;
      this.initTableData();
    },
    /**
     * 删除发票
     */
    deleteINfo(row) {
      console.log("row", row);
      let requestData = {
        ids: [row.id],
      };
      this.$confirm("确认要删除该发票吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (row.row.status == "未开票") {
            if (!this.whetherAgencyHead) {
              this.$message({
                type: "info",
                message: "只可删除未开票发票!",
              });
              return;
            }
          }
          this.$request(
            "post",
            "/invoiceInfo/delInvoice",
            requestData,
            (data) => {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.showInvoiceInfoDrawerData();
            },
            (errorMessage) => {
              that.$message.error(errorMessage);
            }
          );
        })
        .catch(() => {});
    },
    rowClassName({ row, rowIndex }) {
      // if (
      //   (parseFloat(row.moneyQuarter.replace(/, /g, "")) > 250000 ||
      //     parseFloat(row.moneyYear.replace(/, /g, "")) > 4500000) &&
      //   row.companyType == "小规模纳税人"
      // ) {
      //   return "rowName";
      // }
    },
  },
  mounted() {
    this.loading = true;
    this.isCharge();
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
    this.searchData.userId = "all";
  },
};
</script>

<style lang="less" scoped>
#invoice-info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .invoice-info-screen-fiftrate {
    width: 65px;
    min-width: 65px;
    height: 30px;
    border: 1px solid @boderColor;
    box-sizing: border-box;
    color: @boderActiveColor;
    font-size: @contentFontSize;
    margin: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    transition: 0.3s;
    i {
      margin: 0 4px;
    }
  }
  .invoice-info-screen-fiftrate:hover {
    cursor: pointer;
    border-color: @boderActiveColor;
  }
  .invoice-info-screen {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    .invoice-info-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      > .title {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 0 10px;
      }
      > .el-tag {
        margin: 0 5px;
      }
      .invoice-info-screen-fiftrate {
        width: 65px;
        min-width: 65px;
        height: 30px;
        border: 1px solid @boderColor;
        box-sizing: border-box;
        color: @boderActiveColor;
        font-size: @contentFontSize;
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: 0.3s;
        i {
          margin: 0 4px;
        }
      }
      .invoice-info-screen-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }
      .invoice-info-screen-search {
        width: 210px;
        min-width: 210px;
        height: 30px;
        margin-left: 10px;
        display: flex;
        align-items: center;
      }
      .invoice-info-screen-active {
        flex: 1;
      }
      .drop-button {
        background-color: #ecf5ff;
        color: #409eff;
        &:hover {
          background-color: #abbed3;
        }
      }
      .invoice-info-screen-but {
        width: 100px;
        min-width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
        &:hover {
          cursor: pointer;
          background-color: @activeColor2;
        }
      }

      .invoice-info-screen-but-boder {
        width: 50px;
        min-width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        font-size: @contentFontSize;
        background-color: @activeBackground;
        color: @activeColor;
        margin: 0 5px;
        margin-right: 10px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .invoice-info-screen-but-boder:hover {
        cursor: pointer;
        background-color: @activeBackground2;
      }
    }
  }

  .table-content {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    .invoice-info-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .invoice-info-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .invoice-info-table-card-tab {
          width: 120px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .invoice-info-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .invoice-info-table-card-active {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: @contentFontSize;
          .textArea {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: @activeColor;
            cursor: pointer;
            &:hover {
              text-decoration: underline; // 下划线
            }
          }
          .numArea {
            height: 100%;
            padding-top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            color: @activeColor;
          }
        }
        .invoice-info-table-card-edit {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: @editColor;
          cursor: pointer;
        }
      }
      .invoice-info-content {
        width: 100%;
        height: 95%;
        max-width: 90vw;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        /deep/.el-table .rowName {
          background: #fef0f0;
        }
        .line-cell {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          cursor: pointer;
          i {
            margin: 5px;
          }
        }
        .gray {
          color: rgb(146, 146, 146, 0.637);
        }
        .orange {
          color: rgba(255, 115, 0, 0.637);
        }
        .green {
          color: rgb(9, 255, 0, 0.637);
        }
      }
      .invoice-info-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }
}
</style>
