<template>
  <div id="pool-thread">
    <!-- 商机池 -->
    <div class="thread-screen">
      <div class="thread-screen-line">
        <div class="thread-screen-fiftrate">
          <el-popover
            placement="bottom-start"
            width="250"
            trigger="manual"
            :visible-arrow="false"
            v-model="popoData.popoVisible"
          >
            <div class="popover-search">
              <popo_search
                :list="popoData.list"
                @cancel="popoCanael"
                @reset="popoReset"
                @makeSure="popoMakeSure"
              ></popo_search>
            </div>
            <div slot="reference" @click="popoverOpen">
              筛选 <i class="el-icon-s-operation"></i>
            </div>
          </el-popover>
        </div>
        <div class="thread-screen-search">
          <el-input
            placeholder="商机名称/联系人/手机号"
            v-model="searchData.input"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="thread-screen-search">
          <el-input
            placeholder="请输入地址"
            v-model="searchData.address"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="thread-screen-search">
          <el-date-picker
            v-model="searchData.accountDueTime"
            type="monthrange"
            range-separator="至"
            start-placeholder="到期时间"
            end-placeholder="到期时间"
            value-format="yyyy-MM"
            @change="initTableData"
          >
          </el-date-picker>
        </div>
        <div class="thread-screen-active"></div>
        <!-- <div class="thread-screen-but-select">
        <div class="thread-screen-but-select_but">新客户商机</div>
        <div class="thread-screen-but-select_select">
          <i class="el-icon-caret-bottom"></i>
        </div>
      </div> -->
        <div
          class="thread-screen-but-select"
          v-showFunction="{ active: 'distribution', array: allFunctionButtons }"
        >
          <div
            class="thread-screen-but-select_but"
            @click="openAllocationThreadDialog(true)"
          >
            批量分配
          </div>
        </div>
        <div
          class="thread-screen-but-border"
          v-showFunction="{ active: 'import', array: allFunctionButtons }"
          @click="importThread"
          v-loading="pictLoading"
          element-loading-background="rgba(0, 0, 0, 0.2)"
          element-loading-spinner="el-icon-loading"
        >
          导入
        </div>
        <div
          class="thread-screen-but-border"
          v-showFunction="{ active: 'export', array: allFunctionButtons }"
          @click="exportThread"
        >
          导出
        </div>
      </div>
      <div class="thread-screen-line" v-if="searchData.screenList.length > 0">
        <div class="title">筛选条件:</div>
        <el-tag
          v-for="(tag, index) in searchData.screenList"
          :key="index"
          closable
          size="small"
          @close="closeScreen(index)"
        >
          {{ tag.name }}
        </el-tag>
      </div>
    </div>
    <div class="thread-table">
      <div class="thread-table-card">
        <div class="thread-table-card-tabs">
          <div
            :class="{
              'thread-table-card-tab': true,
              'is-active': tabindex == 0,
            }"
            @click="changeTab(0)"
          >
            全部
          </div>
          <div class="thread-table-card-active"></div>
          <div class="thread-table-card-edit">
            <i class="el-icon-edit-outline" @click="updateTableMenu"></i>
          </div>
        </div>
        <div class="thread-table-card-content">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            @row-click="rowClick"
            style="width: 100%"
            height="98%"
            v-loading="loading"
            @selection-change="handleSelectionChange"
            :row-style="{ height: '65px' }"
            :default-sort="defaultSort"
            @sort-change="sortChange"
          >
            <el-table-column type="selection" width="35" fixed>
            </el-table-column>
            <el-table-column type="index" width="55" fixed> </el-table-column>
            <template v-for="(item, index) in tabMenu"
              ><el-table-column
                :key="index"
                :property="item.key"
                :fixed="index == 0"
                :label="item.name"
                v-if="item.authority == 1"
                :min-width="
                  item.key == 'threadName'
                    ? 270
                    : item.key == 'followName'
                    ? 100
                    : item.key == 'lastContactTime'
                    ? 130
                    : 110
                "
                :sortable="
                  item.key == 'lastContactTime' ||
                  item.key == 'createTime' ||
                  item.key == 'protectTime'
                    ? 'custom'
                    : false
                "
                :show-overflow-tooltip="true"
              >
                <template slot-scope="scope">
                  <div class="hri" v-if="item.key == 'threadName'">
                    <i
                      class="el-icon-notebook-2"
                      @click.stop="showGenjin(scope.row)"
                    ></i>
                    <el-tooltip
                      class="item"
                      effect="light"
                      content="已签约"
                      placement="top"
                    >
                      <i
                        class="el-icon-star-on"
                        v-if="scope.row.clientFlag"
                      ></i> </el-tooltip
                    >{{ scope.row[item.key] }}
                  </div>
                  <div
                    class="hri"
                    v-else-if="
                      item.key == 'customerSource' ||
                        item.key == 'intentionalProduct'
                    "
                  >
                    <!-- 客户来源或意向产品为数组格式,循环遍历显示 -->
                    <div v-for="(it, ii) in scope.row[item.key]" :key="ii">
                      {{ ii == 0 ? it.title : "," + it.title }}
                    </div>
                  </div>
                  <div v-else>
                    {{ scope.row[item.key] }}
                  </div>
                </template>
              </el-table-column></template
            >
          </el-table>
        </div>
        <div class="thread-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 自定义列 -->
    <div id="my-dialog" v-if="tragerDialogData.flag">
      <div class="my-dialog-cc">
        <div class="my-dialog-title">
          <div class="my-dialog-title-text">{{ tragerDialogData.title }}</div>
          <div class="my-dialog-title-active"></div>
          <div class="my-dialog-title-close" @click="closeUpdateTableMenu">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <el-divider></el-divider>
        <div class="my-dialog-content">
          <new_drager
            :dragerData="tragerDialogData.data"
            @show="showDrager"
            @dragging="dragging"
            @closeUpdateTableMenu="closeUpdateTableMenu"
            @markSureUpdateTableMenu="markSureUpdateTableMenu"
          ></new_drager>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @buttonOper="drawerOper"
        @choseProductTipOne="choseProductTipOne"
        @saveDrawerData="saveDrawerData"
      ></my_dialog>
    </div>

    <!-- 右边抽屉 -->

    <div id="my-drawer" v-if="threadDrawerData.flag">
      <my_drawer
        :drawerData="threadDrawerData"
        :allFunctionButtons="allFunctionButtons"
        :model="'threadPool'"
        @show="showDrawer"
        @closeUpdateTableDrawer="closeUpdateTableDrawer"
        @saveDrawerData="saveDrawerData"
        @tagsHandleClose="tagsHandleClose"
        @addTags="addTags"
        @drawerOper="drawerOper"
      ></my_drawer>
    </div>
  </div>
</template>

<script>
import drager from "@/components/drager.vue";
import my_drawer from "@/components/my-drawer.vue";
import popo_search from "@/components/popo-search.vue";
import my_dialog from "@/components/dialog.vue";
import * as myThreadData from "@/assets/js/myThread.js";
import * as clientInfoData from "@/assets/js/clientInfoData.js";
import * as systemData from "@/assets/js/systemAuthorityData.js";
import * as employeeData from "@/assets/js/employeeData.js";
import * as productData from "@/assets/js/productData.js";
import * as contractDrawData from "@/assets/js/contractDraw.js";
import * as threadData from "./js/threadData.js";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
export default {
  components: {
    drager,
    my_drawer,
    popo_search,
    my_dialog,
  },
  data() {
    return {
      pictLoading: false,
      baseInfo: {},
      loading: true,
      whetherAgencyHead: true, // 是否是机构负责人
      searchData: {
        input: "",
        checked: false,
        screenButton_allocate: 1, // 批量分配商机按钮的权限 1->读写;2->只读;3->不可见
        screenButton_improt: 1, // 导出客户商机按钮的权限 1->读写;2->只读;3->不可见
        screenList: [],
        address: "",
        accountDueTime: [],
      },
      popoData: {
        popoVisible: false,
        list: myThreadData.reThreadPoolSearchData(),
      },
      tabindex: "0",
      defaultSort: { prop: "", order: "" },
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      tabMenu: myThreadData.myThreadTableMenuData(),
      tableMenuData: {
        selectMenuData: [], // 真正显示的表头数据
        chooseableMenuData: [], // 隐藏的表头
      },
      tableData: [],
      multipleSelection: [], // 多选的数据
      tragerDialogData: {
        title: "自定义列",
        flag: false,
        data: {},
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "添加跟进记录",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: myThreadData.getMyThreadsGenjinjiluData(),
      },
      threadDrawerData: myThreadData.threadPoolDrawerData("商机", {
        buttonAuth: [],
        data: [],
        customer: { name: "", id: "" },
      }),
      tempTags: [],
      tempThread: {
        id: "",
        name: "",
        clientFlag: false,
      },
      arrayList: {
        employeeList: [], // 所有员工列表
        departmentList: [], // 所有部门列表
        areaList: [], // 所有片区列表
        sourceList: [], // 所有客户来源列表
        followStageList: [], // 跟进阶段列表
        addedValueTaxTypeList: [], // 增值税类型列表
        intentionalProductList: [], // 所有意向产品列表
      },
      buttonAuth: [],
      productList: [],
      clientList: [],
      tempContract: { id: "", name: "" }, // 临时合同
      tempClient: {},
      tempServices: [], // 该合同所包含的所有服务
      professionList: [], // 所有合同执行模板
      serviceItemList: [], // 所有服务项数据
      employeeList: [], // 所有员工
      departmentList: [], // 所有员工
      contractSettingButtonList: [],
      contractDrawerData: {}, // 合同预览数据
      accountList: [], // 收款账号
      contractId: "", // 预览的合同ID
      ttempClient: {},
      allFunctionButtons: this.$featureData.getAllFunctionButtons("threadPool"),
      drawerOptions: this.$tagData.getDrawerOptionsList("threadPool"), // 抽屉中的下拉框配置项
      drawerAuthorityData: [],
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "threadPool",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.initMenu();
      });
    },
    initMenu() {
      this.$featureData.getAttributeData(
        "threadPool",
        true,
        [],
        (attributeData) => {
          this.$menuData.getModelMenuAndAuthority(
            "threadPool",
            attributeData,
            this,
            (data) => {
              this.tabMenu = data;
              console.log(this.tabMenu);
              this.tragerDialogData.data = JSON.parse(
                JSON.stringify(this.tabMenu)
              );
              this.getEmployeeList();

              this.$tagData.getTagList(
                this.drawerOptions,
                ["片区", "客户来源", "增值税类型"],
                this.baseInfo.company.companyId,
                this
              );
            }
          );
        }
      );
    },
    /**
     * 获取所有员工列表
     */
    getEmployeeList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListForDownBox",
        requestData,
        (data) => {
          this.employeeList = [];
          data.forEach((element) => {
            let dd = {
              label: element.userName,
              value: element.userId,
            };
            this.employeeList.push(dd);
          });
          this.drawerOptions.allUser = this.employeeList;
          this.getThreadAndClientName();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },

    /**
     * 获取所有客户成交信息信息
     */
    getThreadAndClientName() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        // "/thread/getThreadAndClientName",
        "/reThread/getThreadAndClientName",
        requestData,
        (data) => {
          this.clientList = data;
          this.getServicesItem();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有服务项列表
     */
    getServicesItem() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        status: "1",
        name: "", // 服务产品名称 模糊查询
        type: "",
      };
      this.$request(
        "post",
        "/servicesItem/getServicesItem",
        requestData,
        (data) => {
          this.serviceItemList = data;
          this.getAllProductList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },

    /**
     * 获取所有合同模板/产品列表
     */
    getAllProductList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        name: "", // 服务产品名称 模糊查询
        type: "",
        currentPage: 1, // 当前页数
        pageSize: 1000, // 每页条数
      };
      this.$request(
        "post",
        "/product/getAllProduct",
        requestData,
        (data) => {
          this.productList = data.tableData;
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 表格排序事件
     */
    sortChange(data) {
      // console.log("表格排序事件", data.prop, data.order);
      this.defaultSort.prop = data.prop;
      this.defaultSort.order = data.order == null ? "" : data.order;
      // console.log("表格排序事件", this.defaultSort);
      this.initTableData();
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      this.tableData = [];
      this.loading = true;
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId, // 当前公司ID
        haveBargain: "0", // 是否包含已成交、无效,"0"为否,"1"为是
        state: "3", // 商机状态:"0"->全部商机;"1"->待首次联系商机;"2"->待联系商机
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
        search: this.searchData.input,
        accountDueTime: this.searchData.accountDueTime, // 筛选项：到期时间，年月
        sortProp: this.defaultSort.prop, // 手动排序项
        sortOrder: this.defaultSort.order, // 手动排序规则 ascending descending ""
        screenList: this.searchData.screenList,
        address: this.searchData.address, // 地址
      };
      this.$request(
        "post",
        // "/thread/getMyThread",
        "/reThread/getThreadPool",
        requestData,
        (data) => {
          // this.pageData.total = data.total; // 获取总条目数
          this.pageData.total = data.total; // 获取总条目数
          // this.tableData = data.tableData;
          // this.tableData = myThreadData.getInitTableData(
          this.tableData = myThreadData.regetInitTableData(
            data.tableData,
            this.tableMenuData.selectMenuData
          );
          this.$nextTick(() => {
            this.$refs.singleTable.doLayout();
          });
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },

    /**
     * tab切换事件
     * index:0 -> 全部; 1->待首次跟进; 2->待联系
     */
    changeTab(index) {
      this.tabindex = index;
    },

    /**
     * 多选框事件
     */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },

    /**
     * 批量分配商机dialog打开
     */
    openAllocationThreadDialog(flag) {
      if (flag) {
        // 批量
        if (this.searchData.screenButton_allocate == 2) {
          this.$message.error(
            this.$errorMessage.getErrorMessageInfo("authority")
          );
          return;
        }
        if (this.multipleSelection.length == 0) {
          this.$message.error("请先选择想要分配的商机");
          return;
        }
      }
      this.dialogData.title = "选择跟进人";
      this.dialogData.buttonArray = [];
      this.dialogData.buttonArray.push({
        text: "提交",
        active: "submit_allocation",
      });
      this.dialogData.data = [];

      let d2 = {
        type: "select",
        value: "",
        title: "请选择分配人员",
        placeholder: "请选择分配人员",
        options: this.employeeList,
      };
      // this.dialogData.data.push(d1);
      this.dialogData.data.push(d2);
      this.dialogData.flag = true;
    },
    /**
     * 导入商机
     */
    importThread() {
      // 导入资源
      if (this.pictLoading == true) {
        this.$message.error("正在导入资源,请耐心等待");
        return;
      }

      this.dialogData.title = "导入资源";
      this.dialogData.data = myThreadData.improtResourcesDialogData(this);
      this.dialogData.buttonArray = [
        {
          text: "确定",
          active: "makesure_improt_resources",
        },
      ];
      this.dialogData.flag = true;
    },
    /**
     * 商机的批量导出
     */
    exportThread() {
      if (this.searchData.screenButton_improt == 2) {
        this.$message.error(
          this.$errorMessage.getErrorMessageInfo("authority")
        );
        return;
      }
      if (this.multipleSelection.length == 0) {
        this.$message.error("请先选择想要导出的商机");
        return;
      }
      let requestData = "";
      this.multipleSelection.forEach((element) => {
        if (requestData == "") {
          requestData = element.id;
        } else {
          requestData = requestData + "," + element.id;
        }
      });
      window.location.href =
        this.$baseurl + "/reThread/importThread?threadId=" + requestData;
    },

    /**
     * 筛选弹出框显示
     */
    popoverOpen() {
      console.log("所有数组数据:", this.arrayList);
      // this.popoData.list[0].value = this.arrayList.employeeList// 商机状态
      this.popoData.list[1].options = []; // 客户来源
      this.popoData.list[7].options = []; // 商机创建人
      this.popoData.list[6].options = []; // 商机创建人
      this.popoData.list[8].options = []; // 意向产品
      this.arrayList.sourceList.forEach((element) => {
        if (element.active) {
          let dd = {
            label: element.name,
            value: element.value[0],
          };
          this.popoData.list[1].options.push(dd);
        }
      });

      this.popoData.list[6].options = this.employeeList;
      this.popoData.list[7].options = this.employeeList;
      this.productList.forEach((element) => {
        let dd = {
          label: element.name,
          value: element.id,
        };
        this.popoData.list[8].options.push(dd);
      });

      this.popoData.popoVisible = !this.popoData.popoVisible;
    },
    /**
     * 筛选弹出框确认事件
     */
    popoMakeSure() {
      this.searchData.screenList = this.$systemBaseData.remakesureSearchData(
        this.popoData.list
      );
      // console.log(this.popoData.list);
      this.popoData.popoVisible = false;
      this.initTableData();
    },
    /**
     * 筛选弹出框重置
     */
    popoReset() {
      this.$systemBaseData.popoReset(this.popoData.list);
      this.searchData.screenList = [];
    },
    /**
     * 筛选弹出框关闭事件
     */
    popoCanael() {
      this.popoData.popoVisible = false;
    },
    /**
     * 关闭筛选标签事件
     */
    closeScreen(index) {
      this.searchData.screenList.splice(index, 1);
      this.initTableData();
    },

    /**
     *右击改变表头显示与否事件
     */
    changeStatus(data) {
      if (data.index == 0) {
        data.item.isDisable = true;
        this.tragerDialogData.data.selectMenuData.splice(
          this.tragerDialogData.data.selectMenuData.indexOf(data.item),
          1
        );
        this.tragerDialogData.data.chooseableMenuData.push(data.item);
      } else {
        data.item.isDisable = false;
        this.tragerDialogData.data.chooseableMenuData.splice(
          this.tragerDialogData.data.chooseableMenuData.indexOf(data.item),
          1
        );
        this.tragerDialogData.data.selectMenuData.push(data.item);
      }
    },
    /**
     * 修改表头顺序dialog打开
     */
    updateTableMenu() {
      this.tragerDialogData.data = JSON.parse(JSON.stringify(this.tabMenu));
      this.tragerDialogData.flag = true;
    },
    /**
     * 确定修改表头数据
     */
    markSureUpdateTableMenu(resultData) {
      console.log("确定修改表头数据:", resultData);
      this.tabMenu = JSON.parse(JSON.stringify(resultData));
      this.tragerDialogData.flag = false;
      this.initTableData();
      this.$menuData.saveTableMenuData(resultData, "threadPool", this);
    },

    /**
     * 表格单行点击事件
     */
    rowClick(row, column, event) {
      if (!this.popoData.popoVisible) {
        if (!this.threadDrawerData.flag) {
          //防止重复点击
          this.tempThread.id = row.id;
          this.tempThread.clientFlag = row.clientFlag;
          this.multipleSelection = [];
          this.multipleSelection.push({
            id: row.id,
          });
          this.tempThread.name = row.threadName;
          this.ttempClient = row;
          this.$featureData.getAttributeData(
            "threadPool",
            false,
            threadData.returnDrawerKeys(),
            (authorityData) => {
              this.drawerAuthorityData = authorityData;
              // 2.拿对应属性的权限
              this.$authority.getModelFeatureAuthority(
                this,
                "threadPool",
                authorityData,
                (authorityList) => {
                  // 3.将权限数据对应到界面
                  this.threadDrawerData = threadData.myThreadDrawerData(
                    this.tempThread.name,
                    authorityList,
                    this.whetherAgencyHead, // 是否是机构负责人
                    this.drawerOptions
                  );
                  // 4.拿具体的值
                  this.getMyThreadDetailsByThreadId();
                }
              );
            }
          );
        } else {
          this.closeUpdateTableDrawer();
        }
      } else {
        this.popoCanael();
      }
    },
    /**
     * 获取商机的具体信息
     */
    getMyThreadDetailsByThreadId() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId, // 当前公司ID
        threadId: this.tempThread.id, // 当前线索ID
        authorityData: this.$initDrawerAuthorityData.getKeysByAuthorityList(
          this.drawerAuthorityData
        ), // 模块具体权限key数组
      };
      this.$request(
        "post",
        "/reThread/getMyThreadDetailsByThreadId",
        requestData,
        (data) => {
          // 5.将具体的值匹配到界面
          // 保证联系人有数据
          if (data.contact.length == 0) {
            data.contact = [{ name: "", phone: ["", ""] }];
          }
          // 单独处理客户来源  将数组格式转换成字符串
          if (data.customerSource.length > 0) {
            data.customerSource = data.customerSource[0];
          } else {
            data.customerSource = "";
          }
          this.threadDrawerData = this.$initDrawerAuthorityData.initDrawerData(
            this.threadDrawerData,
            data
          );
          // 6.在权限的基础上叠加状态
          this.threadDrawerData.status = data.status;
          this.threadDrawerData.flag = true;
        }
      );
    },
    /**
     * 抽屉中tag移除事件
     * data(tag,i,j):tag->移除的tag,i为第几个model,j为第几line
     */
    tagsHandleClose(data) {
      this.threadDrawerData.data.modelList[data.i].line[data.j].value.splice(
        this.threadDrawerData.data.modelList[data.i].line[data.j].value.indexOf(
          data.tag
        ),
        1
      );
      this.saveDrawerData(
        this.threadDrawerData.data.modelList[data.i].line[data.j].title
      );
    },
    /**
     * 抽屉中添加tag事件
     * data(i,j):i为第几个model,j为第几line
     */
    addTags(data) {
      this.tempTags = this.threadDrawerData.data.modelList[data.i].line[
        data.j
      ].value;
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.dialogData.title = "选择产品";
      this.dialogData.buttonArray = [];
      this.dialogData.buttonArray.push({
        text: "确定",
        active: "makesure_addPurposeThread",
      });
      this.dialogData.buttonArray.push({
        text: "取消",
        active: "cancel",
      });
      let tempProductList = [];
      this.productList.forEach((element) => {
        let dd = {
          id: element.id,
          title: element.name,
          serve: element.servicesItem,
        };
        tempProductList.push(dd);
      });
      console.log("当前数据", this.tempTags);
      this.dialogData.data = [
        {
          type: "intention_the_product",
          title: "",
          value: {
            clientName: this.tempThread.name,
            chooseProduct: JSON.parse(JSON.stringify(this.tempTags)),
          },
        },
      ];
      this.dialogData.flag = true;
    },
    /**
     * 抽屉组件中按钮操作事件(主要是显示弹出框事件)
     */
    drawerOper(active) {
      let dd = {};
      let requestData = {};
      switch (active) {
        case "allocation":
          // 分配商机
          this.openAllocationThreadDialog(false);
          console.log("商机分配", this.dialogData);
          break;
        case "submit_allocation":
          // 确认分配
          this.allocationThread(
            this.multipleSelection,
            this.dialogData.data[0].value
          );
          break;
        case "zhuanchengjiao":
          // 商机转成交
          this.dialogData.title = "提示";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push({
            text: "确定",
            active: "makesure_zhuanchengjiao",
          });
          this.dialogData.buttonArray.push({
            text: "取消",
            active: "cancel",
          });
          this.dialogData.data = [];
          this.dialogData.data.push({
            type: "reminder",
            text: "转为客户",
            value: this.threadDrawerData.data.title,
          });
          this.dialogData.flag = true;

          break;
        case "makesure_zhuanchengjiao":
          // 确认转成交
          this.createContractDialogOpen(this.tempThread.name);
          break;
        case "recover":
          // 商机恢复
          this.dialogData.title = "提示";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push({
            text: "确定",
            active: "makesure_recover",
          });
          this.dialogData.buttonArray.push({
            text: "取消",
            active: "cancel",
          });
          this.dialogData.data = [];
          this.dialogData.data.push({
            type: "reminder",
            text: "恢复",
            value: this.threadDrawerData.data.title,
          });
          this.dialogData.flag = true;
          break;
        case "makesure_recover":
          // 确认恢复商机
          this.recoverThread();
          break;
        case "delete_thread":
          // 删除商机
          this.dialogData.title = "提示";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push({
            text: "确定",
            active: "makesure_delete_thread",
          });
          this.dialogData.buttonArray.push({
            text: "取消",
            active: "cancel",
          });
          this.dialogData.data = [];
          this.dialogData.data.push({
            type: "reminder",
            text: "删除",
            value: this.threadDrawerData.data.title,
          });
          this.dialogData.flag = true;

          break;
        case "makesure_delete_thread":
          // 确认删除商机
          this.setInvalid("删除");
          break;
        case "to_receive":
          // 领取商机
          this.dialogData.title = "提示";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push({
            text: "确定",
            active: "makesure_to_receive_thread",
          });
          this.dialogData.buttonArray.push({
            text: "取消",
            active: "cancel",
          });
          this.dialogData.data = [];
          this.dialogData.data.push({
            type: "reminder",
            text: "领取",
            value: this.threadDrawerData.data.title,
          });
          this.dialogData.flag = true;

          break;
        case "makesure_to_receive_thread":
          // 确认领取商机
          requestData = {
            threadId: this.tempThread.id, // 商机ID
            userId: this.baseInfo.userId,
          };
          this.$request(
            "post",
            // "/thread/toReceiveThread",
            "/reThread/toReceiveThread",
            requestData,
            (data) => {
              this.dialogData.flag = false;
              this.threadDrawerData.flag = false;
              this.$operationRecords.saveOperationRecords(
                this.tempThread.id,
                "商机【" + this.tempThread.name + "】领取成功!",
                this.baseInfo,
                "thread",
                this,
                (data) => {
                  this.initTableData();
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
              this.loading = false;
            }
          );
          break;
        case "makesure_addPurposeThread":
          this.addPurposeThread();
          // 添加意向产品
          break;
        case "operating_record":
          // 查看操作记录
          this.getOperationRecordsList();
          break;

        case "get_companyInfo":
          // 获取工商信息
          requestData = {
            name: this.threadDrawerData.data.title,
          };
          this.$request(
            "post",
            "/client/getCreditCode",
            requestData,
            (data) => {
              this.threadDrawerData.data.modelList[3].line[4].value =
                data.taxNum == ""
                  ? this.threadDrawerData.data.modelList[3].line[4].value
                  : data.taxNum;
              this.threadDrawerData.data.modelList[3].line[5].value =
                data.tel == ""
                  ? this.threadDrawerData.data.modelList[3].line[5].value
                  : data.tel;
              this.threadDrawerData.data.modelList[3].line[6].value =
                data.bank == ""
                  ? this.threadDrawerData.data.modelList[3].line[6].value
                  : data.bank;
              this.threadDrawerData.data.modelList[3].line[7].value =
                data.bankAccount == ""
                  ? this.threadDrawerData.data.modelList[3].line[7].value
                  : data.bankAccount;
              this.threadDrawerData.data.modelList[3].line[3].value =
                data.address == ""
                  ? this.threadDrawerData.data.modelList[3].line[3].value
                  : data.address;

              this.threadDrawerData.data.modelList[3].line[2].value = [];
              this.threadDrawerData.data.modelList[3].line[2].value =
                data.address == ""
                  ? []
                  : this.$addressAutomaticallyMatch.addressAutomaticallyMatch(
                      data.address
                    );

              if (
                this.threadDrawerData.data.modelList[3].line[2].value == null ||
                this.threadDrawerData.data.modelList[3].line[2].value.length < 3
              ) {
                this.threadDrawerData.data.modelList[3].line[2].value = [];
              }
              this.saveDrawerData("工商信息");
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
        case "makesure_improt_resources":
          // 确定导入资源模板
          requestData = {
            userId: this.baseInfo.userId,
            companyId: this.baseInfo.company.companyId,
            url: this.dialogData.data[0].value.url,
          };
          if (requestData.url == "") {
            this.$message.error("请先选择要导入的文件!");
            return;
          }
          NProgress.start();
          this.pictLoading = true;

          this.$request(
            "get",
            "/reThread/importExcel?data=" + JSON.stringify(requestData),
            {},
            (data) => {
              this.$message({
                message: data,
                type: "success",
              });
              this.dialogData.flag = false;
              this.pictLoading = false;
              NProgress.done();
              this.initTableData();
            },
            (err) => {
              this.$message({
                message: err,
                type: "error",
              });
              this.pictLoading = false;
              this.dialogData.flag = false;
              NProgress.done();
              this.initTableData();
            }
          );
          this.dialogData.flag = false;
          break;
          break;
        case "cancel":
          // dialog关闭
          this.dialogData.flag = false;

          break;

        default:
          break;
      }
    },
    /**
     * 确认添加意向产品
     */
    addPurposeThread() {
      console.log("确认添加意向产品", this.dialogData.data);
      let requestData = {
        threadId: this.tempThread.id, // 商机ID
        value: this.dialogData.data[0].value.chooseProduct,
      };
      if (requestData.value.length == 0) {
        this.$message({
          message: "产品不可为空!",
          type: "error",
          duration: 1000,
        });
        return;
      }
      this.$request(
        "post",
        // "/thread/addPurposeThread",
        "/reThread/addPurposeThread",
        requestData,
        (data) => {
          this.dialogData.flag = false;
          this.$operationRecords.saveOperationRecords(
            this.tempThread.id,
            "将商机【" + this.tempThread.name + "】添加意向产品!",
            this.baseInfo,
            "thread",
            this,
            (data) => {
              this.initTableData();
              this.getMyThreadDetailsByThreadId();
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 新建合同
     */
    createContractDialogOpen(title) {
      // this.clientInfoDrawerData.data.modelList[0].line[0].tooltipf_flag = true;
      this.dialogData.title = "选择产品";
      this.dialogData.buttonArray = [];
      this.dialogData.buttonArray.push({
        text: "取消",
        active: "cancel",
      });
      this.dialogData.data = [
        {
          type: "input_disable",
          title: "客户名称",
          value: title,
        },
        {
          type: "input_carriage",
          title: "请选择一个服务产品",
          placeholder: "搜索服务产品",
          value: "",
        },
        {
          type: "select_array_one",
          title: "",
          value: this.productList,
        },
      ];
      this.dialogData.flag = true;
    },
    /**
     * 选择产品创建客户
     */
    choseProductTipOne(data) {
      if (this.dialogData.title == "选择产品") {
        let requestData = {
          userId: this.baseInfo.userId,
          name: this.tempThread.name, // 商机名称
          companyId: this.baseInfo.company.companyId,
          productId: data.id, // 合同模板ID
          threadId: this.tempThread.id, // 商机ID
          clientFlag: this.tempThread.clientFlag,
        };
        this.$request(
          "post",
          "/client/addClientDirect",
          requestData,
          (data) => {
            this.dialogData.flag = false;
            this.threadDrawerData.flag = false;
            // this.tempThreadId = ""; // 每次新增完,商机ID置空
            this.$message({
              message: "客户创建成功!",
              duration: 1000,
              type: "success",
            });
            this.initTableData();
            this.$operationRecords.saveOperationRecords(
              data.clientId,
              "创建客户【" + requestData.name + "】!",
              this.baseInfo,
              "client",
              this,
              (data1) => {
                this.$operationRecords.saveOperationRecords(
                  data.contractId,
                  "创建合同!",
                  this.baseInfo,
                  "contract",
                  this,
                  (data2) => {
                    this.showContractInfoByID();
                    this.initTableData();
                  }
                );
              }
            );
          },
          (errorMessage) => {
            this.$message.error(errorMessage);
          }
        );
      }

      // 新建支出
      if (this.dialogData.title == "新建支出") {
        let dd = {
          client: this.tempThread.name,
          contractNum: this.tempThread.name,
          contractId: this.tempContract.id,
          expendStatus: "待提交",
          expendNum: "",
          time: "",
          expendType: data.name,
          receiptName: "",
          receiptAccount: "",
          blank: "",
          des: "",
          file: "", // 附件
          services: this.tempServices,
        };
        this.$request(
          "post",
          "/contract/addExpend",
          dd,
          (data) => {
            this.$message({
              message: "支出添加成功!",
              type: "success",
              duration: 1000,
            });
            this.dialogData.flag = false;
            this.$operationRecords.saveOperationRecords(
              this.tempContract.id,
              "添加支出!",
              this.baseInfo,
              "contract",
              this,
              (data) => {
                this.getExpendByContractId();
              }
            );
          },
          (errorMessage) => {
            this.$message.error(errorMessage);
          }
        );
      }
    },

    /**
     * 批量分配商机
     */
    allocationThread(array, userId) {
      let ids = array.map((item) => item.id);
      let requestData = {
        threadIds: ids, // 商机ID
        userId: this.baseInfo.userId, // 分配人
        followUser: userId, // 将以上商机分配给该用户
      };
      this.$request(
        "post",
        "/reThread/fromThreadPoolToMyResource",
        requestData,
        (data) => {
          this.dialogData.flag = false;
          this.threadDrawerData.flag = false;
          this.initTableData();
          array.forEach((element) => {
            this.$operationRecords.saveOperationRecords(
              element.id,
              "商机【" + element.threadName + "】分配成功!",
              this.baseInfo,
              "thread",
              this,
              (data) => {}
            );
          });
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 商机转成交
     */
    makeBargainThread() {
      let requestData = {
        threadId: this.tempThread.id, // 商机ID
      };
      this.$request(
        "post",
        // "/thread/makeBargainThread",
        "/reThread/makeBargainThread",
        requestData,
        (data) => {
          this.dialogData.flag = false;
          this.$operationRecords.saveOperationRecords(
            this.tempThread.id,
            "商机【" + this.tempThread.name + "】转成交!",
            this.baseInfo,
            "thread",
            this,
            (data) => {
              this.initTableData();
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 商机恢复
     */
    recoverThread() {
      let requestData = {
        threadId: this.tempThread.id, // 商机ID
      };
      this.$request(
        "post",
        // "/thread/recoverThread",
        "/reThread/recoverThread",
        requestData,
        (data) => {
          this.dialogData.flag = false;
          this.threadDrawerData.flag = false;
          this.$operationRecords.saveOperationRecords(
            this.tempThread.id,
            "商机【" + this.tempThread.name + "】恢复!",
            this.baseInfo,
            "thread",
            this,
            (data) => {
              this.initTableData();
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 商机置为无效/删除
     */
    setInvalid(oper) {
      let requestData = {
        threadId: this.tempThread.id, // 商机ID
      };
      this.$request(
        "post",
        // "/thread/deleteThread",
        "/reThread/deleteThread",
        requestData,
        (data) => {
          this.dialogData.flag = false;
          this.threadDrawerData.flag = false;
          this.$operationRecords.saveOperationRecords(
            this.tempThread.id,
            "将商机【" + this.tempThread.name + "】置为无效!",
            this.baseInfo,
            "thread",
            this,
            (data) => {
              this.initTableData();
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 查看操作记录
     */
    getOperationRecordsList() {
      this.$operationRecords.showOperationRecords(
        this.tempThread.id,
        "thread",
        this
      );
    },
    /**
     * 保存抽屉数据
     */
    saveDrawerData(drawerTitle) {
      if (
        !myThreadData.getDrawerSubmitData(this.threadDrawerData.data.modelList)
          .flag
      ) {
        this.$message({
          message: "联系人手机号格式不正确,请确认填入正确的手机号!",
          type: "error",
          duration: 1000,
        });
        // this.getMyThreadDetailsByThreadId();
        return;
      }
      let requestData = {
        threadId: this.tempThread.id,
        userId: this.baseInfo.userId,
        company: this.baseInfo.company.companyId,
        data: myThreadData.getDrawerSubmitData(
          this.threadDrawerData.data.modelList
        ).list,
      };
      requestData.data.push({
        value: this.threadDrawerData.data.title,
        isShow: true,
        isDisable: false,
        flag: "0",
        key: "threadName",
        title: "商机名称",
      });
      // console.log("保存数据:", this.threadDrawerData.data);
      this.$request(
        "post",
        "/reThread/updateThread",
        requestData,
        (data) => {
          // this.$message({
          //   message: "保存成功!",
          //   type: "success",
          //   duration: 1000,
          // });
          this.$operationRecords.saveOperationRecords(
            this.tempThread.id,
            "修改" + drawerTitle + "信息!",
            this.baseInfo,
            "thread",
            this,
            (data) => {
              this.initTableData();
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          // this.loading = false;
        }
      );
    },
    /**
     * 右边抽屉父子组件连锁事件
     */
    showDrawer(value) {
      this.threadDrawerData = value;
    },
    /**
     * 右边抽屉关闭事件
     */
    closeUpdateTableDrawer() {
      this.threadDrawerData.colseanimationflag = true;
      this.threadDrawerData.flag = false;
      // this.initTableData();
    },

    /**
     * 拖拽表头排序父子组件连锁事件
     */
    showDrager(value) {
      this.tableMenuData = value;
    },
    /**
     * 修改表头顺序dialog关闭
     */
    closeUpdateTableMenu() {
      this.tragerDialogData.flag = false;
    },
    /**
     * 弹出框关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
      this.dialogData.data.forEach((element) => {
        if (element.value) {
          element.value = "";
        }
      });
      this.multipleSelection = [];
    },

    /**
     * 表头div拖动方法
     */
    dragging(value) {
      console.log("div拖动", value);
    },

    /**
     * 查看跟进记录
     */
    showGenjin(clientData) {
      this.ttempClient = clientData;
      let requestData = {
        threadId: clientData.id,
        clientId: "",
      };
      this.$request(
        "post",
        "/followRecord/showFollowRecord",
        requestData,
        (data) => {
          this.dialogData.title = "跟进记录-" + clientData.threadName;
          this.dialogData.buttonArray = [];
          this.dialogData.data = [
            {
              type: "genjinjilu",
              value: {
                clientId: clientData.id,
                threadId: "",
                hasContent: false,
                content: "",
                time: "",
                list: data,
                hasTarget: false,
                target: "",
                hasPhase: true,
                phase: "",
              },
            },
          ];
          this.dialogData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
  },
  mounted() {
    NProgress.configure({
      showSpinner: false,
      parent: "#pool-thread .thread-table",
    });
    this.loading = true;
    this.isCharge();
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#pool-thread {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .thread-screen {
    width: 100%;
    height: auto;
    border-bottom: 1px solid @boderColor;
    display: flex;
    flex-direction: column;
    align-items: center;
    .thread-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      > .title {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 0 10px;
      }
      > .el-tag {
        margin: 0 5px;
      }
      .thread-screen-fiftrate {
        width: 65px;
        min-width: 65px;
        height: 30px;
        border: 1px solid @boderColor;
        box-sizing: border-box;
        color: @boderActiveColor;
        font-size: @contentFontSize;
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: 0.3s;
        i {
          margin: 0 4px;
        }
      }
      .thread-screen-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }
      .thread-screen-search {
        width: 210px;
        min-width: 210px;
        height: 30px;
        margin-right: 5px;
      }
      .thread-screen-checked {
        width: 140px;
        height: 30px;
        min-width: 140px;
        font-size: @contentFontSize;
        margin: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .thread-screen-active {
        flex: 1;
      }
      .thread-screen-but-select {
        width: 140px;
        min-width: 160px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
        cursor: pointer;
        .thread-screen-but-select_but {
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.5s;
        }
        .thread-screen-but-select_but:hover {
          background-color: @activeColor2;
        }
        .thread-screen-but-select_select {
          width: 30px;
          height: 100%;
          border-left: 1px solid @activeColor3;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .thread-screen-but {
        width: 100px;
        min-width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .thread-screen-but:hover {
        cursor: pointer;
        background-color: @activeColor2;
      }
      .thread-screen-but-border {
        width: 50px;
        min-width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        font-size: @contentFontSize;
        background-color: @activeBackground;
        color: @activeColor;
        margin: 0 5px;
        margin-right: 10px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .thread-screen-but-border:hover {
        cursor: pointer;
        background-color: @activeBackground2;
      }
    }
  }
  .thread-table {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    .thread-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .thread-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .thread-table-card-tab {
          width: 80px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .thread-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .thread-table-card-active {
          flex: 1;
        }
        .thread-table-card-edit {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: @editColor;
          cursor: pointer;
        }
      }
      .thread-table-card-content {
        flex: 1;
        width: 98%;
        height: 0;
        display: flex;
        align-content: center;
        justify-content: center;
        font-size: @contentFontSize;
        color: @closeColor;
      }
      .thread-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }

  .hri {
    display: flex;
    i {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 3px;
      font-size: 14px;
      color: @activeColor;
    }
  }
}
.popover-search {
  // 筛选的pop弹出框
  max-height: 800px;
}
</style>
