<template>
  <div id="product-model">
    <!-- 合同模板 -->
    <div class="product-model-line">
      <div class="product-model-line-title">服务产品名称:</div>
      <div class="product-model-line-value">
        <el-input v-model="value.name" placeholder="请输入内容"></el-input>
      </div>
    </div>

    <div class="product-model-line">
      <div class="product-model-line-title">服务产品说明:</div>
      <div class="product-model-line-value">
        <el-input v-model="value.des" placeholder="请输入内容"></el-input>
      </div>
    </div>

    <div class="product-model-line">
      <div class="product-model-line-title">合同类型:</div>
      <div class="product-model-line-value">
        <el-select
          popper-class="select-box"
          v-model="value.contractType"
          placeholder="请选择"
          filterable
          @change="changeContractType"
          popper-append-to-body
        >
          <el-option
            v-for="item in contractTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="product-model-line">
      <div class="product-model-line-title">是否自动续签:</div>
      <div class="product-model-line-value">
        <el-radio-group
          v-model="value.automaticRenewal"
          :disabled="disableFlag"
        >
          <el-radio :label="'0'">是</el-radio>
          <el-radio :label="'1'">否</el-radio>
        </el-radio-group>
      </div>
    </div>

    <div class="product-model-line">
      <div class="product-model-line-title">服务类型:</div>
      <div class="product-model-line-value">
        <el-select
          v-model="value.serviceType"
          placeholder="请选择"
          filterable
          @change="changeServiceType"
          value-key="id"
          popper-append-to-body
        >
          <el-option
            v-for="item in serviceTtypeOptions"
            :key="item.id"
            :label="item.typeName"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="product-model-table">
      <div class="product-model-table-line head">
        <div class="cell">服务项目名称</div>
        <div class="cell">原价</div>
        <div class="cell">减免</div>
        <div class="cell">催费时间</div>
        <div class="cell">
          操作
          <i class="el-icon-circle-plus-outline" @click="addService"></i>
        </div>
      </div>
      <div
        class="product-model-table-line"
        v-for="(item, index) in value.servicesItem"
        :key="index"
      >
        <div class="cell">
          <el-select
            v-model="item.data"
            placeholder="请选择"
            filterable
            @change="changeServiceItem(item.data, index)"
            value-key="id"
          >
            <el-option
              v-for="it in serviceItemsList"
              :key="it.id"
              :label="it.name"
              :value="it"
            >
            </el-option>
          </el-select>
        </div>
        <div class="cell">
          <div class="menu1">
            <el-input
              v-model="item.price"
              placeholder="0.00"
              @change="changeValue(item, index, 0)"
            ></el-input>
          </div>
          元/{{ item.unit }}
        </div>
        <div class="cell">
          <div class="menu1">
            <el-input
              v-model="item.discounts"
              placeholder="0.00"
              @change="changeValue(item, index, 1)"
            ></el-input>
          </div>
          元/{{ item.unit }}
        </div>
        <div class="cell">
          <el-radio-group
            v-model="item.dunning.flag"
            @change="changeValue(item, index, 2)"
          >
            <el-radio :label="'0'">提前</el-radio>
            <el-radio :label="'1'">延后</el-radio>
          </el-radio-group>
          <div class="menu2">
            <el-input
              v-model="item.dunning.day"
              placeholder="0"
              @change="changeValue(item, index, 3)"
            ></el-input>
          </div>
          天
        </div>
        <div class="cell">
          <el-button type="text" @click="splititem(item)">移除</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["produceModelData"],
  data() {
    return {
      baseInfo: {},
      value: this.produceModelData,
      //   value: {
      //     id: "", // 服务产品ID
      //     name: "", // 服务产品名称
      //     des: "", // 服务产品说明
      //     contractType: "周期服务", // 合同类型 (周期服务/单次服务)
      //     automaticRenewal: "0", // 是否自动续签
      //     serviceType: "", // 服务类型
      //     servicesItem: [], // 服务项列表
      //   },
      disableFlag: false,
      contractTypeOptions: [
        // 合同类型
        {
          label: "周期服务",
          value: "周期服务",
        },
        {
          label: "单次服务",
          value: "单次服务",
        },
      ],
      serviceTtypeOptions: [],
      serviceItemsList: [],
      firstflag: false,
    };
  },
  watch: {
    produceModelData() {
      //父组件值变化->>子组件也变化
      this.value = this.produceModelData;
    },
    value(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {
    /**
     * 合同类型值改变
     */
    changeContractType(val) {
      if (val == "周期服务") {
        //   当合同类型为周期服务时,自动续签属性显示
        this.disableFlag = false;
      } else {
        this.disableFlag = true;
      }
    },
    /**
     * 服务类型值改变
     * 添加对应服务类型中的服务项目
     */
    changeServiceType(val) {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        status: "1", // 0为停用;1为启用;2为全部
        name: "", // 模糊查询
        type: val.id, // 服务项类别ID 获取全部数据时,该项为空
      };
      this.$request(
        "post",
        "/servicesItem/getServicesItem",
        requestData,
        (data) => {
          this.serviceItemsList = data;
          if (!this.firstflag) {
            this.value.servicesItem = [];
            this.addService();
          }
          this.firstflag = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 服务项列表选择事件
     */
    changeServiceItem(data, index) {
      this.value.servicesItem[index].price = data.price;
      this.value.servicesItem[index].unit = data.unit;
      this.value.servicesItem[index].dunning.flag = data.dunning.flag;
      this.value.servicesItem[index].dunning.day = data.dunning.day;
    },

    /**
     * 移除当前项
     */
    splititem(data) {
      this.value.servicesItem.splice(this.value.servicesItem.indexOf(data), 1);
    },
    /**
     * 添加服务项
     */
    addService() {
      let data = {
        data: {},
        price: "0",
        unit: "次",
        discounts: "0",
        dunning: {
          flag: "0",
          day: "0",
        },
      };
      this.value.servicesItem.push(data);
    },
    /**
     *
     */
    changeValue(data, index, flag) {
      switch (flag) {
        case 0:
          // 修改单价
          data.data.price = data.price;
          break;
        case 1:
          // 修改减免
          data.data.discounts.mitigate = data.discounts;
          data.data.discounts.flag = "2";
          break;
        case 2:
          // 修改催费时间
          // data.data.dunning.day = data.dunning.day
          data.data.dunning.flag = data.dunning.flag;
          break;
        case 3:
          // 修改催费时间
          data.data.dunning.day = data.dunning.day;
          // data.data.dunning.flag = data.dunning.flag
          break;

        default:
          break;
      }
    },
  },
  mounted() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    let requestData = {
      companyId: this.baseInfo.company.companyId,
    };
    this.$request(
      "post",
      "/serveType/getAllServeType",
      requestData,
      (data) => {
        this.serviceTtypeOptions = data;
        if (data.length > 0) {
          this.value.serviceType =
            this.value.serviceType == "" ? data[0] : this.value.serviceType;
          this.firstflag = true;
          this.changeServiceType(this.value.serviceType);
        }
      },
      (errorMessage) => {
        this.$message.error(errorMessage);
      }
    );
  },
};
</script>

<style lang="less" scoped>
#product-model {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  .product-model-line {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    .product-model-line-title {
      width: 20%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 10px;
      box-sizing: border-box;
    }
    .product-model-line-value {
      flex: 1;
      width: 0;
      height: 100%;
      display: flex;
      align-items: center;
      //   justify-content: center;
    }
  }
  .product-model-table {
    width: 100%;
    height: auto;
    max-height: 400px;
    overflow-y: auto;
    border: 1px solid @boderColor;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    &::-webkit-scrollbar {
      width: 1px;
    }
    .product-model-table-line {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid @boderColor;
      box-sizing: border-box;
      &:nth-last-child(1) {
        border: none;
      }
      .cell {
        flex: 1;
        height: 100%;
        width: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 1px solid @boderColor;
        &:nth-child(1) {
          border: none;
        }
        .menu1 {
          width: 80%;
        }
        .menu2 {
          width: 30%;
        }
        i {
          margin: 5px;
          cursor: pointer;
        }
      }
    }
    .head {
      background-color: @activeBackground;
      min-height: 40px !important;
    }
  }
  .select-box span:focus-within {
    border: none;
    outline: none;
  }
}
</style>