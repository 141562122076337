<template>
  <div id="report-invoice">
    <!-- 发票报表 -->
    <div class="report-invoice-screen">
      <div class="report-invoice-screen-line">
        <div class="report-invoice-screen-search">
          <el-input
            placeholder="交易单号"
            v-model="searchData.voucherNo"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="report-invoice-screen-search">
          <el-input
            placeholder="客户名称"
            v-model="searchData.clientName"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>

        <div class="report-invoice-screen-search">
          <el-select
            v-model="searchData.accountId"
            placeholder="请选择收款账户"
            @change="initTableData"
            filterable
            clearable
          >
            <el-option
              v-for="item in searchData.accountList"
              :key="item.account"
              :label="item.owner"
              :value="item.account"
            >
            </el-option>
          </el-select>
        </div>
        <div class="report-invoice-screen-search">
          <el-cascader
            v-model="searchData.productId"
            :options="searchData.productList"
            placeholder="请选择服务项目"
            @change="initTableData"
            :props="{ checkStrictly: true, label: 'name', value: 'id' }"
            clearable
            filterable
          ></el-cascader>
        </div>

        <div class="report-invoice-screen-search">
          <el-date-picker
            v-model="searchData.month"
            type="monthrange"
            value-format="yyyy-MM"
            range-separator="至"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
            @change="initTableData"
            :clearable="false"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="report-invoice-screen-line">
        <div
          class="report-invoice-screen-search"
          style="display: flex; align-items: center; justify-content: start;"
        >
          <el-cascader
            style="width: 160px"
            v-model="searchData.department"
            placeholder="请选择部门"
            :options="searchData.departTreeData"
            @change="initTableData"
            clearable
            :show-all-levels="false"
            :props="{ checkStrictly: true, value: 'id' }"
            filterable
          ></el-cascader>
          <el-select
            style="width: 160px"
            v-model="searchData.userId"
            placeholder="请选择员工"
            @change="selectInitTableData2"
            filterable
            clearable
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.userId"
              :label="item.userName == '全部' ? '全部人员' : item.userName"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </div>
        <div
          class="report-invoice-screen-search"
          style="display: flex; align-items: center; justify-content: start;"
        >
          <el-select
            style="width: 160px"
            v-model="searchData.roleId"
            placeholder="请选择角色"
            @change="initTableData"
            filterable
          >
            <el-option
              v-for="item in searchData.roleList"
              :key="item.authorityId"
              :label="item.authorityName"
              :value="item.authorityId"
            >
            </el-option>
          </el-select>
          <el-select
            v-if="searchData.roleId"
            style="width: 160px"
            v-model="searchData.roleUserId"
            placeholder="请选择员工"
            @change="selectInitTableData1"
            filterable
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.userId"
              :label="item.userName == '全部' ? '全部人员' : item.userName"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </div>
        <!-- <div
          class="
          report-invoice-screen-search-left
          report-invoice-screen-search"
        ></div> -->
        <!-- <div class="report-invoice-screen-search">
          <el-checkbox-group
            v-model="searchData.receivedFlag"
            @change="initTableData"
          >
            <el-checkbox label="1">全部收款</el-checkbox>
            <el-checkbox label="2">部分收款</el-checkbox>
            <el-checkbox label="3">未收款</el-checkbox>
          </el-checkbox-group>
        </div> -->
        <div class="report-invoice-screen-search">
          <el-checkbox-group
            v-model="searchData.invoiceFlag"
            @change="initTableData"
          >
            <el-checkbox :label="0">未开票</el-checkbox>
            <el-checkbox :label="1">已开票</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="report-invoice-screen-active"></div>
        <!-- <div
          class="report-invoice-screen-but-boder"
          @click="handleClick({}, 1)"
          v-if="whetherAgencyHead"
        >
          导出
        </div> -->
      </div>
    </div>
    <div class="report-invoice-table">
      <div class="report-invoice-table-card">
        <div class="report-invoice-table-card-tabs">
          <div
            :class="{
              'report-invoice-table-card-tab': true,
              'is-active': tabindex == 0,
            }"
            @click="changeTab(0)"
          >
            全部收款报表
          </div>
          <div
            :class="{
              'report-invoice-table-card-tab': true,
              'is-active': tabindex == 1,
            }"
            @click="changeTab(1)"
          >
            新签收款报表
          </div>
          <div
            :class="{
              'report-invoice-table-card-tab': true,
              'is-active': tabindex == 2,
            }"
            @click="changeTab(2)"
          >
            续签收款报表
          </div>
          <!-- <div
            class="report-invoice-table-card-active"
            v-if="whetherAgencyHead"
          >
            <div class="textArea">签约总金额:</div>
            <div class="numArea">{{ allMoney }}</div>
            <div class="textArea">合同成本:</div>
            <div class="numArea">{{ allCost }}</div>
            <div class="textArea">收款总金额:</div>
            <div class="numArea">{{ allPaymentCollect }}</div>
            <div class="textArea">未回款总金额:</div>
            <div class="numArea">{{ allAmountsOw }}</div>
            <div class="textArea">总利润:</div>
            <div class="numArea">{{ allProfits }}</div>
          </div> -->
        </div>
        <div class="report-invoice-table-card-content">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            row-key="id"
            height="98%"
            :row-style="getTableChildrenStyle"
            v-loading="loading"
            :tree-props="{ children: 'children' }"
            show-summary
            :summary-method="getSummaries"
          >
            <el-table-column type="index" width="55" fixed> </el-table-column>
            <el-table-column
              v-for="(item, index) in tableMenuData"
              :key="index"
              :property="item.key"
              :fixed="index == 0"
              :label="item.title"
              :width="item.width"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <div>
                  {{ scope.row[item.key] }}
                </div>
              </template>
            </el-table-column>
            <el-table-column width="100" fixed="right" label="操作">
              <template slot-scope="scope">
                <el-button
                  type="success"
                  size="small"
                  plain
                  v-if="!scope.row.invoiceId"
                  v-showFunction="{
                    active: 'takeInvoice',
                    array: allFunctionButtons,
                  }"
                  @click="handleClick(scope.row, 2)"
                  >开票</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="report-invoice-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import * as reportData from "@/assets/js/report.js";
import my_dialog from "@/components/dialog.vue";
import * as invoiceData from "@/assets/js/invoiceData.js";
export default {
  components: {
    my_dialog,
  },
  data() {
    return {
      baseInfo: {},
      rowStyle: this.$rowStyle,
      whetherAgencyHead: false, // 是否是机构负责人
      loading: true,
      searchData: {
        input: "",
        checked: false,
        screenButton_export: 1, // 导出
        userId: "", // 旧的部门人员
        roleUserId: "", // 新的部门人员
        accountId: "", // 收款账号
        accountList: [], // 收款账号
        roleId: "", // 角色ID
        roleList: [],
        userList: [],
        tempUserId: [],
        roleUserList: [],
        departTreeData: [],
        department: "",
        productList: [],
        productId: [],
        month: [
          this.$formatTime(new Date(), "YYYY-MM"),
          this.$formatTime(new Date(), "YYYY-MM"),
        ],
        voucherNo: "", // 交易单号
        clientName: "", // 客户名称
        // receivedFlag: [],
        invoiceFlag: [],
      },
      tabindex: 0,

      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      tableMenuData: reportData.getReportInvoiceMenuData(),
      tableData: [],
      allMoney: "", // 签约总金额
      allPaymentCollect: "", // 收款总金额
      allAmountsOw: "", // 欠款总金额
      allProfits: "", // 总利润
      allCost: "", // 合同成本
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "reportInvoice"
      ),
      templateData: {},
      dialogData: {
        flag: false,
        width: "30%",
        title: "添加跟进记录",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },

      tempInvoiceId: "",
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("this.allFunctionButtons", this.allFunctionButtons);
      this.$authority.getModelFeatureAuthority(
        this,
        "reportInvoice",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          console.log("按钮权限", this.allFunctionButtons);
          this.isCharge();
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getEmployeeListByUserId();
      });
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeListByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.searchData.userList = data;
          this.tempAllEmpolyeeList = [];
          data.forEach((element) => {
            this.tempAllEmpolyeeList.push(element.userId);
          });
          this.searchData.tempUserId = this.tempAllEmpolyeeList;
          this.searchData.roleUserList = this.tempAllEmpolyeeList;
          this.searchData.userList.splice(0, 0, {
            userId: "all",
            userName: "全部人员",
          });
          this.initTableData();
          this.getBusinessAuthorityList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有业务角色列表
     */
    getBusinessAuthorityList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/role/getBusinessAuthorityList",
        requestData,
        (data) => {
          this.searchData.roleList = data;

          this.getAccountList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有收款账号
     */
    getAccountList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/companyAccount/getAccountList",
        requestData,
        (data) => {
          this.searchData.accountList = [];
          let dd = {};
          data.forEach((element) => {
            let account = "";
            element.accountList.forEach((ele) => {
              account =
                account == "" ? ele.account : account + "," + ele.account;
            });

            dd = {
              owner: element.owner,
              account: account,
            };
            this.searchData.accountList.push(dd);
          });

          console.log("获取所有收款账号:", this.searchData.accountList);
          this.getDepartmentTreeByUserId();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        // "/setting/getDepartmentTreeByUserId",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
          this.getAllProduct();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有产品
     */
    getAllProduct() {
      this.searchData.productList = [];
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/servicesItem/getServeTypeAndItem",
        requestData,
        (data) => {
          this.searchData.productList = data;
        }
      );
    },
    handleClick(data, index) {
      this.templateData = data;
      let requestData = {};
      switch (index) {
        case 1:
          // 导出收款报表
          requestData = {
            searchTime: this.searchData.month, // 每条合同的最后一笔收款时间
            productId:
              this.searchData.productId.length > 0
                ? this.searchData.productId[
                    this.searchData.productId.length - 1
                  ]
                : "", // 产品
            department:
              this.searchData.department.length > 0
                ? this.searchData.department[
                    this.searchData.department.length - 1
                  ]
                : "",
            accountId: this.searchData.accountId,
            deUser: this.searchData.tempUserId, // 旧的部门人员
            roleUser: this.searchData.roleUserList, // 新的部门人员
            type: this.tabindex == 0 ? 1 : this.tabindex == 1 ? 2 : 3,
            role: this.searchData.roleId,
            companyId: this.baseInfo.company.companyId,
            clientName: this.searchData.clientName, // 客户名称
            voucherNo: this.searchData.voucherNo, // 交易单号
            // receivedFlag: receivedFlag, // 1-全部收款 2-部分收款 3-未收款
          };
          window.location.href =
            this.$baseurl +
            "/report/exportPaymentReport?data=" +
            JSON.stringify(requestData);
          break;
        case 2:
          // 开票
          /**
           * 1. 根据客户名称,默认填充:分机号及状态,库存,单张开票限额,销售方名称,纳税人识别号,地址,电话,开户行及账号等信息,提供客户类型(是小规模纳税人还是一般纳税人)
           * 2. 选择开票类型:电子普通发票开具、电子专用发票开具、纸质普通发票开具、纸质专用发票开具
           *    注:纸质发票中没有收票人手机和收票人邮箱
           * 3. 填写购买方信息(必填):名称,纳税人识别号,地址,电话,开户行及账号
           *    填写货物或应税劳务、服务名称(必填),金额(必填),税率(根据客户类型选择税率)(必填),税额(计算)(必填)
           * 4. 填写收款人,复核人,开票人,
           * 5. 填写收票人手机,收票人邮箱
           */
          this.$request(
            "get",
            "/client/getByReceiptNumber?receiptNumber=" +
              this.templateData.voucherNo,
            {},
            (data) => {
              if (data.isInvoicing === "Y") {
                this.getClientInfoByClientID(data.id, data);
              } else {
                this.$message.error("该销售方不是开票客户, 请先设置!");
              }
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        default:
          break;
      }
    },
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "submit_invoice":
          // 提交发票
          // 计算发票数量 (总价税 - 税价) % (发票限额 * 10000)
          this.invoiceSheet =
            Math.ceil(
              (this.dialogData.data[0].data.totalAmount -
                this.dialogData.data[0].data.allTax) /
                (this.dialogData.data[0].data.LimitAmount * 10000)
            ) || 0;
          this.dialogFlag = true;
          break;
        case "save_invoice":
          // 发票保存
          this.saveInvoiceDetails().then(() => {
            this.$message({
              type: "success",
              message: "发票保存成功!",
              duration: 1000,
            });
            this.initTableData();
          });
          break;

        case "cancel":
          this.dialogData.flag = false;
          break;
        default:
          break;
      }
    },
    initTableData() {
      this.loading = true;
      let requestData = {
        searchTime: this.searchData.month, // 每条合同的最后一笔收款时间
        productId:
          this.searchData.productId.length > 0
            ? this.searchData.productId[this.searchData.productId.length - 1]
            : "", // 产品
        department:
          this.searchData.department.length > 0
            ? this.searchData.department[this.searchData.department.length - 1]
            : "",
        accountId: this.searchData.accountId,
        deUser: this.searchData.tempUserId, // 旧的部门人员
        roleUser: this.searchData.roleUserList, // 新的部门人员
        role: this.searchData.roleId,
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
        companyId: this.baseInfo.company.companyId,
        clientName: this.searchData.clientName, // 客户名称
        voucherNo: this.searchData.voucherNo, // 交易单号
        // receivedFlag: this.searchData.receivedFlag, // 1-全部收款 2-部分收款 3-未收款
        invoiceFlag: this.searchData.invoiceFlag, // 0-未开票 1-已开票
        isRoyalty: false,
      };
      this.$request(
        "post",
        "/report/queryInvoiceReport",
        requestData,
        (data) => {
          this.pageData.total = data.total;
          this.tableData = data.tableData;
          this.allMoney = data.allMoney;
          this.allCost = data.allCost;
          this.allPaymentCollect = data.allPaymentCollect;
          this.allAmountsOw = data.allAmountsOw;
          this.allProfits = data.allProfits;
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    selectInitTableData1(val) {
      if (val == "all") {
        this.searchData.roleUserList = this.tempAllEmpolyeeList;
      } else {
        this.searchData.roleUserList = [val];
      }
      this.initTableData();
    },
    selectInitTableData2(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.tempAllEmpolyeeList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.initTableData();
    },
    changeTab(index) {
      this.tabindex = index;
      this.initTableData();
    },
    handleCurrentChange(val) {
      this.initTableData();
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    getTableChildrenStyle(data) {
      let row = data.row;
      let style = { height: "65px", backgroundColor: "#ffffff" };
      if (row.onlyChildrenHave) {
        style.backgroundColor = "#ebeef5";
      }
      return style;
    },
    getSummaries(param) {
      const { columns, data } = param;

      const sums = [];
      columns.forEach((column, index) => {
        if (index == 1) {
          sums[index] = "合计";
          return;
        }
        if (
          index == 0 ||
          index == 2 ||
          index == 3 ||
          index == 4 ||
          index == 8 ||
          index == 10 ||
          index == 11
        ) {
          sums[index] = "";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            let sum = Number(Number(prev) + Number(curr)).toFixed(2); //toFixed(2)数据项保留两位小数
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = Math.round(sums[index] * 100) / 100;
        } else {
          sums[index] = "N/A";
        }
      });

      return sums;
    },
    /**
     * @description 开票dialog打开
     * @param id 销售方 clientId
     * @param sellInvoiceData 销售方 发票信息
     */
    getClientInfoByClientID(id, sellInvoiceData) {
      // 获取购买方发票信息
      let requestData = {
        id: this.templateData.clientId,
      };
      this.$request(
        "post",
        "/client/getClientInfoByClientID",
        requestData,
        (clientData) => {
          this.dialogData.width = "1300px";
          this.dialogData.title = "发票开具";
          this.dialogData.buttonArray = [
            {
              text: "保存",
              active: "save_invoice",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.$set(clientData, "clientId", this.templateData.clientId);
          // 获取销售方发票类型限额
          this.dialogData.data = [
            {
              type: "invoice_dialog",
              title: "",
              data: invoiceData.getInvoiceDialogDataByContract(
                clientData, // 客户信息 购买方
                this.baseInfo.company.companyId,
                "", // 发票信息 空
                false, // 是否是销项发票
                sellInvoiceData, // 销售方信息
                this.templateData.profits // 收款金额
              ),
            },
          ];
          this.dialogData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /*
     * 保存发票详情
     */
    saveInvoiceDetails() {
      let that = this;
      let p = new Promise(function(resolve, reject) {
        if (that.dialogData.data[0].data.specialFieldEcho) {
          that.dialogData.data[0].data.specialField = invoiceData.transSpecialField(
            that.dialogData.data[0].data
          );
        }

        let requestData = {
          ...that.dialogData.data[0].data,
          createUserId: that.baseInfo.userId, // 创建人 id
          // inOrOut: "1",
          receiptNumber: that.templateData.voucherNo, // 关联的收款单号
          receiptId: that.templateData.id, // 关联 收款单号 id
          contractId: that.templateData.contractId, // 合同 id
        };
        requestData.paperTicketCodeEcho = JSON.stringify(
          requestData.paperTicketCodeEcho
        );
        requestData.specialField = JSON.stringify(requestData.specialField);
        requestData.specialFieldEcho = JSON.stringify(
          requestData.specialFieldEcho
        );
        that.$request(
          "post",
          "/invoice/saveInvoice",
          requestData,
          (data) => {
            // 拿到发票id 提交发票时使用
            that.tempInvoiceId = data.id;
            that.dialogData.flag = false;
            // 更新合同抽屉 收款记录数据
            let requestData2 = {
              invoiceId: data.id, // 发票 id
              contractId: that.templateData.contractId, //合同 id
              receiptId: that.templateData.id, // 收款记录 id
            };
            that.$request(
              "post",
              "/contract/updateReceipt",
              requestData2,
              (data) => {
                resolve(true);
              },
              (errorMessage) => {
                that.$message.error(errorMessage);
              }
            );
          },
          (errorMessage) => {
            reject(errorMessage);
            that.$message.error(errorMessage);
          }
        );
      });
      return p;
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
  },
  mounted() {
    this.loading = true;
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.searchData.userId = "all";
    this.searchData.roleUserId = "all";
    // this.searchData.tempUserId = ["all"];
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#report-invoice {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .report-invoice-screen {
    width: 100%;
    height: auto;
    border-bottom: 1px solid @boderColor;
    display: flex;
    align-items: center;
    flex-direction: column;

    .report-invoice-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      .el-checkbox-group {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .report-invoice-screen-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }

      .report-invoice-screen-search {
        width: auto;
        min-width: 210px;
        height: 30px;
        margin-left: 10px;
        padding: 8px 0;
        &:nth-child(7n + 1) {
          margin-left: 8px;
          // margin-right: 15px;
        }
      }

      .report-invoice-screen-search-left {
        margin-left: 0;
      }
      .report-invoice-screen-active {
        flex: 1;
      }
      .report-invoice-screen-but-select {
        width: 140px;
        min-width: 160px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
        cursor: pointer;
        .report-invoice-screen-but-select_but {
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.5s;
        }
        .report-invoice-screen-but-select_but:hover {
          background-color: @activeColor2;
        }
        .report-invoice-screen-but-select_select {
          width: 30px;
          height: 100%;
          border-left: 1px solid @activeColor3;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .report-invoice-screen-but {
        width: 100px;
        min-width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .report-invoice-screen-but:hover {
        cursor: pointer;
        background-color: @activeColor2;
      }
      .report-invoice-screen-but-boder {
        width: 50px;
        min-width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        font-size: @contentFontSize;
        background-color: @activeBackground;
        color: @activeColor;
        margin: 0 5px;
        margin-right: 10px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .report-invoice-screen-but-boder:hover {
        cursor: pointer;
        background-color: @activeBackground2;
      }
    }
  }
  .report-invoice-table {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    .report-invoice-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .report-invoice-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .report-invoice-table-card-tab {
          width: 130px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .report-invoice-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .report-invoice-table-card-active {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: @contentFontSize;
          .textArea {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .numArea {
            height: 100%;
            padding-top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            color: @activeColor;
          }
        }
        .report-invoice-table-card-edit {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: @editColor;
          cursor: pointer;
        }
      }
      .report-invoice-table-card-content {
        flex: 1;
        width: 98%;
        .report-invoice-table-card-content_text {
          width: auto;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 0px;
          box-sizing: border-box;
          color: rgb(99, 211, 34);
          font-size: 12px;
        }
      }
      .report-invoice-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }

  .table-tip {
    height: 30px;
    width: auto;
    padding: 2px 5px;
    margin: 0 3px;
    border: 1px solid #333;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hri {
    display: flex;
    i {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 3px;
      font-size: 14px;
      color: @activeColor;
    }
  }
  .link {
    color: @activeColor;
    transition: 0.3s;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .el-date-editor.el-input__inner {
    width: 300px !important;
  }
  .el-table__row--level-1 {
    background-color: #dddddd !important;
  }
}
</style>
