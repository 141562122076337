<template>
  <div id="human-dispatch">
    <!-- 工商提成表 -->
    <div class="human-dispatch-screen">
      <div class="human-dispatch-screen-line">
        <div class="human-dispatch-screen-search">
          <el-input
            placeholder="客户名称"
            v-model="searchData.clientName"
            @keyup.enter.native="resetPage"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="resetPage"
            ></el-button>
          </el-input>
        </div>
        <div class="human-dispatch-screen-search">
          <el-cascader
            v-model="searchData.department"
            placeholder="请选择部门"
            :options="searchData.departTreeData"
            @change="resetPage"
            clearable
            :show-all-levels="false"
            :props="{ checkStrictly: true, value: 'id' }"
            filterable
          ></el-cascader>
        </div>
        <div class="human-dispatch-screen-search">
          <el-select
            v-model="searchData.userId"
            @change="seleteInitTableData"
            filterable
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.userId"
              :label="item.userName"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="human-dispatch-screen-search" v-show="tabindex == 1">
          <el-date-picker
            v-model="searchData.currentStepTime"
            type="monthrange"
            value-format="yyyy-MM"
            range-separator="至"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
            @change="resetPage"
            :clearable="false"
          >
          </el-date-picker>
        </div>
        <div class="human-dispatch-screen-search" v-show="tabindex == 2">
          <el-date-picker
            v-model="searchData.allStepTime"
            type="month"
            value-format="yyyy-MM"
            placeholder="选择月"
            :clearable="false"
            @change="resetPage"
          >
          </el-date-picker>
        </div>
        <div class="human-dispatch-screen-search" v-if="tabindex == 1">
          <el-select
            v-model="searchData.status"
            placeholder="请选择状态"
            @change="resetPage"
            filterable
            clearable
          >
            <el-option label="未完成" value="未完成"> </el-option>
            <el-option label="已完成" value="已完成"> </el-option>
          </el-select>
        </div>
        <div class="human-dispatch-screen-active"></div>
        <div style="margin: 0 5px; ">
          <el-dropdown
            v-showFunction="{ active: 'batchEdit', array: allFunctionButtons }"
          >
            <el-button type="small" class="drop-button">
              批量操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-if="tabindex == 1"
                @click.native="handleClick('', 1)"
                >修改执行人</el-dropdown-item
              >
              <el-dropdown-item
                v-if="tabindex == 1"
                @click.native="handleClick('', 2)"
                >修改提成</el-dropdown-item
              >
              <el-dropdown-item
                v-if="tabindex == 2"
                @click.native="handleClick('', 3)"
                >修改提成比率</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="human-dispatch-table">
      <div class="human-dispatch-table-card">
        <div class="human-dispatch-table-card-tabs">
          <div
            :class="{
              'human-dispatch-table-card-tab': true,
              'is-active': tabindex == 1,
            }"
            @click="changeTab(1)"
          >
            详情
          </div>
          <div
            :class="{
              'human-dispatch-table-card-tab': true,
              'is-active': tabindex == 2,
            }"
            @click="changeTab(2)"
          >
            统计
          </div>
          <!-- <div
            class="human-dispatch-table-card-active"
            v-if="whetherAgencyHead"
          >
            <div class="textArea">合计提成:</div>
            <div class="numArea">{{ allRoyalty }}</div>
          </div> -->
        </div>
        <div class="human-dispatch-table-card-content" v-if="tabindex == 1">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            row-key="id"
            height="98%"
            :row-style="getTableChildrenStyle"
            v-loading="loading"
            :tree-props="{ children: 'children' }"
            @selection-change="handleSelectionChange"
            show-summary
            :summary-method="getSummaries"
          >
            <el-table-column type="selection" width="60" fixed>
            </el-table-column>
            <el-table-column type="index" width="40" fixed> </el-table-column>
            <el-table-column
              v-for="(item, index) in tableMenuData"
              :key="index"
              :property="item.prop"
              :fixed="index == 0"
              :label="item.title"
              :width="item.prop == 'clientName' ? 250 : item.width"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <div v-if="item.prop == 'serviceItem'" class="hri">
                  <!-- 服务项 -->
                  <div
                    v-for="(ddd, dd) in scope.row[item.prop]"
                    :key="dd"
                    class="table-tip"
                    :style="{
                      borderColor: ddd.color,
                      color: ddd.color,
                    }"
                  >
                    <el-tooltip :content="ddd.tip" placement="top">
                      <div>{{ ddd.text }}</div>
                    </el-tooltip>
                  </div>
                </div>
                <span v-else-if="item.prop == 'status'">
                  <span
                    :style="{
                      color:
                        scope.row[item.prop] == '已完成'
                          ? '#67C23A'
                          : '#F56C6C',
                    }"
                    >{{ scope.row[item.prop] }}</span
                  >
                </span>
                <el-select
                  v-else-if="item.prop == 'completeUserName'"
                  v-model="scope.row[item.prop]"
                  @change="
                    changeItem($event, scope.row, item.prop, item.updateKey)
                  "
                  :disabled="editFlag"
                >
                  <el-option
                    v-for="item in searchData.userList2"
                    :key="item.userId"
                    :label="item.userName"
                    :value="item.userId"
                  >
                  </el-option>
                </el-select>
                <el-input
                  v-else-if="item.prop == 'commission'"
                  v-model="scope.row[item.prop]"
                  @keyup.enter.native="
                    changeItem(
                      scope.row[item.prop],
                      scope.row,
                      item.prop,
                      item.updateKey
                    )
                  "
                  :disabled="editFlag"
                ></el-input>
                <span v-else>
                  {{ scope.row[item.prop] }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="human-dispatch-table-card-content" v-else>
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            row-key="id"
            height="98%"
            :row-style="getTableChildrenStyle"
            v-loading="loading"
            :tree-props="{ children: 'children' }"
            @selection-change="handleSelectionChange"
            show-summary
            :summary-method="getSummaries"
          >
            <el-table-column type="selection" width="60" fixed>
            </el-table-column>
            <el-table-column type="index" width="40" fixed> </el-table-column>
            <el-table-column
              v-for="(item, index) in tableMenuData2"
              :key="index"
              :property="item.prop"
              :fixed="index == 0"
              :label="item.title"
              :width="item.width"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <span
                  v-if="item.prop == 'completeUserName'"
                  @click.stop="handleClick(scope.row, 4)"
                  style="color: #40a3ff; cursor: pointer;"
                  >{{ scope.row[item.prop] }}</span
                >
                <div class="royaltyRate" v-else-if="item.prop == 'royaltyRate'">
                  <el-input
                    v-model="scope.row[item.prop]"
                    @change="
                      changeItem2(
                        scope.row[item.prop],
                        scope.row,
                        item.prop,
                        item.updateKey
                      )
                    "
                    :disabled="editFlag"
                  >
                  </el-input>
                  <span>%</span>
                </div>
                <span v-else>
                  {{ scope.row[item.prop] }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="human-dispatch-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-sizes="[10, 30, 50, 100, 500, 1000]"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import * as humanData from "./js/humanData.js";
import my_dialog from "@/components/dialog.vue";
export default {
  components: {
    my_dialog,
  },
  data() {
    return {
      baseInfo: {},
      rowStyle: this.$rowStyle,
      whetherAgencyHead: false, // 是否是机构负责人
      loading: true,
      searchData: {
        input: "",
        checked: false,
        status: "",
        userId: "",
        userList: [],
        userList2: [],
        tempUserId: [],
        departTreeData: [],
        department: "",
        currentStepTime: [
          this.$formatTime(new Date(), "YYYY-MM"),
          this.$formatTime(new Date(), "YYYY-MM"),
        ],
        allStepTime: this.$formatTime(new Date(), "YYYY-MM"),
        clientName: "", // 客户名称
      },
      tabindex: 1,
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      tableMenuData: humanData.getHumanDispatchMenuData(),
      tableMenuData2: humanData.getHumanDispatchMenuData2(),
      tableData: [],
      allMoney: "", // 签约总金额
      allPaymentCollect: "", // 收款总金额
      allAmountsOw: "", // 欠款总金额
      allProfits: "", // 总利润
      allCost: "", // 合同成本
      allRoyalty: "", // 合计提成
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "humanDispatch"
      ),
      tempData: {},
      multipleSelection: [],
      dialogData: {
        flag: false,
        width: "30%",
        title: "新增档案",
        buttonArray: [
          {
            text: "确认",
            active: "confirm",
          },
        ],
        data: [],
      },
      editFlag: true,
    };
  },

  methods: {
    getModelFeatureAuthority() {
      this.$authority.getModelFeatureAuthority(
        this,
        "humanDispatch",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.editFlag = !this.allFunctionButtons.some(
            (item) => item.key == "edit" && item.authority == "1"
          );
          console.log("当前显示的按钮权限", data);
          this.getEmployeeListByUserId();
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getEmployeeListByUserId();
      });
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeListByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.searchData.userList2 = JSON.parse(JSON.stringify(data));
          this.searchData.userList = JSON.parse(JSON.stringify(data));
          this.searchData.userId = "all";
          this.tempAllEmpolyeeList = [];
          this.searchData.userList.forEach((element) => {
            this.tempAllEmpolyeeList.push(element.userId);
          });
          this.searchData.userList.splice(0, 0, {
            userId: "all",
            userName: "全部",
          });
          this.seleteInitTableData("all");
          this.getDepartmentTreeByUserId();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有业务角色列表
     */
    getBusinessAuthorityList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/role/getBusinessAuthorityList",
        requestData,
        (data) => {
          this.searchData.roleList = data;

          this.getAccountList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有收款账号
     */
    getAccountList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/companyAccount/getAccountList",
        requestData,
        (data) => {
          this.searchData.accountList = [];
          let dd = {};
          data.forEach((element) => {
            let account = "";
            element.accountList.forEach((ele) => {
              account =
                account == "" ? ele.account : account + "," + ele.account;
            });

            dd = {
              owner: element.owner,
              account: account,
            };
            this.searchData.accountList.push(dd);
          });

          console.log("获取所有收款账号:", this.searchData.accountList);
          this.getDepartmentTreeByUserId();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        // "/setting/getDepartmentTreeByUserId",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
          this.getAllProduct();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有产品
     */
    getAllProduct() {
      this.searchData.productList = [];
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/servicesItem/getServeTypeAndItem",
        requestData,
        (data) => {
          this.searchData.productList = data;
        }
      );
    },
    initTableData() {
      let requestData = {};
      let url = "";
      this.loading = true;
      if (this.tabindex == 1) {
        url = "/dispatchCommission/page";
        requestData = {
          clientName: this.searchData.clientName,
          userIds: this.searchData.tempUserId,
          department:
            this.searchData.department.length > 0
              ? this.searchData.department[
                  this.searchData.department.length - 1
                ]
              : "",
          currentStepTime: this.searchData.currentStepTime,
          status: this.searchData.status,
          pageSize: this.pageData.pageSize, // 每页条数
          currentPage: this.pageData.currentPage, // 当前页数
        };
      } else {
        url = "/dispatchCommission/statistics";
        requestData = {
          clientName: this.searchData.clientName,
          department:
            this.searchData.department.length > 0
              ? this.searchData.department[
                  this.searchData.department.length - 1
                ]
              : "",
          userIds: this.searchData.tempUserId,
          allStepTime: this.searchData.allStepTime,
          pageSize: this.pageData.pageSize, // 每页条数
          currentPage: this.pageData.currentPage, // 当前页数
        };
      }
      this.$request(
        "post",
        url,
        requestData,
        (data) => {
          this.pageData.total = data.total;
          this.tableData = data.records;
          this.tableData.forEach((item) => {
            if (item.royaltyRate) {
              item.royaltyRate = item.royaltyRate * 100;
            }
          });
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    handleClick(data, flag) {
      this.tempData = JSON.parse(JSON.stringify(data));
      let requestData = {};
      switch (flag) {
        case 1:
          if (this.multipleSelection.length <= 0) {
            return this.$message.error("请先选择要操作的数据");
          }
          this.dialogData.width = "500px";
          this.dialogData.title = "批量修改执行人";
          this.dialogData.buttonArray = [
            {
              text: "取消",
              active: "cancel",
            },
            {
              text: "确定",
              active: "submit_completeUserName",
            },
          ];
          let options = [];
          this.searchData.userList2.forEach((item) => {
            options.push({
              label: item.userName,
              value: item.userId,
            });
          });
          this.dialogData.data = [
            {
              title: "执行人",
              type: "select",
              value: "",
              options: options,
            },
          ];
          this.dialogData.flag = true;
          break;
        case 2:
          if (this.multipleSelection.length <= 0) {
            return this.$message.error("请先选择要操作的数据");
          }
          this.dialogData.width = "500px";
          this.dialogData.title = "批量修改提成";
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "submit_commission",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              title: "步骤提成",
              type: "input-number2",
              value: "",
            },
          ];
          this.dialogData.flag = true;
          break;
        case 3:
          if (this.multipleSelection.length <= 0) {
            return this.$message.error("请先选择要操作的数据");
          }
          this.dialogData.width = "500px";
          this.dialogData.title = "批量修改提成比率";
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "submit_royaltyRate",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              title: "提成比例 (%)",
              type: "input-number2",
              value: "",
            },
          ];
          this.dialogData.flag = true;
          break;
        case 4:
          let currentMonth = this.$formatTime(new Date(), "YYYY-MM");
          requestData = {
            clientName: "",
            status: "",
            department: "",
            userIds: [this.tempData.completeUserId],
            allStepTime: currentMonth,
            pageSize: 10, // 每页条数
            currentPage: 1, // 当前页数
          };
          this.$request(
            "post",
            "/dispatchCommission/page",
            requestData,
            (data) => {
              this.dialogData.width = "1300px";
              this.dialogData.title = "工商提成详情";
              this.dialogData.clientName = this.tempData.completeUserName;
              this.dialogData.buttonArray = [];
              this.dialogData.data = [
                {
                  type: "table_human",
                  data: {
                    ...data,
                    userId: this.tempData.completeUserId,
                    date: currentMonth,
                    url: "/dispatchCommission/page",
                  },
                },
              ];
              console.log("dialog数据", this.dialogData);
              this.dialogData.flag = true;
            },
            (err) => {
              this.$message.error(err);
            }
          );
          break;
        default:
          break;
      }
    },
    /**
     * 抽屉数据或dialog数据操作事件
     */
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "submit_completeUserName":
          // 批量修改执行人
          requestData = {
            ids: this.multipleSelection.map((item) => item.id), // 合同 id
            completeUserId: this.dialogData.data[0].value, // 执行人 id
          };
          this.$request(
            "post",
            "/dispatchCommission/edit",
            requestData,
            (data) => {
              this.dialogData.flag = false;
              this.$message.success("修改成功");
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "submit_commission":
          // 批量修改提成
          requestData = {
            ids: this.multipleSelection.map((item) => item.id), // 合同 id
            commission: this.dialogData.data[0].value, // 执行人 id
          };
          this.$request(
            "post",
            "/dispatchCommission/edit",
            requestData,
            (data) => {
              this.dialogData.flag = false;
              this.$message.success("修改成功");
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );

          break;
        case "submit_royaltyRate":
          // 批量修改提成比率
          requestData = {
            userIds: this.multipleSelection.map((item) => item.completeUserId), // 要修改的用户
            date: this.searchData.allStepTime, // 修改月份
            royaltyRate: this.dialogData.data[0].value / 100, // 提成比率
          };
          this.$request(
            "post",
            "/dispatchCommissionRate/addOrEdit",
            requestData,
            (data) => {
              this.dialogData.flag = false;
              this.$message.success("修改成功");
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "cancel":
          // 取消
          this.dialogData.flag = false;
          break;
        default:
          break;
      }
    },
    /**
     * 修改详情数据
     */
    changeItem(value, row, prop, updateKey) {
      let requestData = {
        ids: [row.id],
      };
      if (updateKey) {
        requestData[updateKey] = value;
      } else {
        requestData[prop] = value;
      }
      let flag = this.$numberReg(value);
      if (prop == "commission" && !flag) {
        this.$message.error("请填写数字再进行编辑");
        return;
      }
      this.$request(
        "post",
        "/dispatchCommission/edit",
        requestData,
        (data) => {
          this.$message.success("编辑成功!");
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 修改统计数据
     */
    changeItem2(value, row, prop, updateKey) {
      let requestData = {
        userIds: [row.completeUserId],
        date: this.searchData.allStepTime,
      };
      if (updateKey) {
        requestData[updateKey] = value;
      } else {
        requestData[prop] = value;
      }
      let flag = this.$numberReg(value);
      if (prop == "royaltyRate" && !flag) {
        this.$message.error("请填写数字再进行编辑");
        return;
      }
      if (prop == "royaltyRate") {
        requestData[prop] = requestData[prop] / 100;
      }
      this.$request(
        "post",
        "/dispatchCommissionRate/addOrEdit",
        requestData,
        (data) => {
          this.$message.success("编辑成功!");
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    seleteInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.tempAllEmpolyeeList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.resetPage();
    },
    changeTab(index) {
      this.tabindex = index;
      this.resetPage();
    },
    handleCurrentChange(val) {
      this.initTableData();
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    getTableChildrenStyle(data) {
      let row = data.row;
      let style = { height: "65px", backgroundColor: "#ffffff" };
      if (row.onlyChildrenHave) {
        style.backgroundColor = "#ebeef5";
      }
      return style;
    },
    /**
     * 表格多选事件
     */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index == 0) {
          sums[index] = "合计";
          return;
        }
        if (this.tabindex == 1) {
          if (index !== 8) {
            sums[index] = "";
            return;
          }
        } else {
          if (index == 0 || index == 1 || index == 2 || index == 4) {
            sums[index] = "";
            return;
          }
        }

        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            let sum = Number(Number(prev) + Number(curr)).toFixed(2); //toFixed(2)数据项保留两位小数
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = Math.round(sums[index] * 100) / 100;
        } else {
          sums[index] = "N/A";
        }
      });

      return sums;
    },
    resetPage() {
      this.pageData.currentPage = 1;
      this.initTableData();
    },
  },
  mounted() {
    this.loading = true;
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.searchData.userId = "all";
    this.searchData.roleUserId = "all";
    // this.searchData.tempUserId = ["all"];
    this.getModelFeatureAuthority();
    this.isCharge();
  },
};
</script>

<style lang="less" scoped>
#human-dispatch {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .human-dispatch-screen {
    width: 100%;
    height: auto;
    border-bottom: 1px solid @boderColor;
    display: flex;
    align-items: center;
    flex-direction: column;

    .human-dispatch-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      .el-checkbox-group {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .human-dispatch-screen-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }

      .human-dispatch-screen-search {
        width: 210px;
        min-width: 210px;
        height: 30px;
        margin-left: 10px;
        display: flex;
        align-items: center;
      }

      .human-dispatch-screen-search-left {
        margin-left: 0;
      }
      .human-dispatch-screen-active {
        flex: 1;
      }
      .drop-button {
        background-color: #ecf5ff;
        color: #409eff;
        transition: 0.5s;
        &:hover {
          background-color: #abbed3;
        }
      }
      .human-dispatch-screen-but-select {
        width: 140px;
        min-width: 160px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
        cursor: pointer;
        .human-dispatch-screen-but-select_but {
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.5s;
        }
        .human-dispatch-screen-but-select_but:hover {
          background-color: @activeColor2;
        }
        .human-dispatch-screen-but-select_select {
          width: 30px;
          height: 100%;
          border-left: 1px solid @activeColor3;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .human-dispatch-screen-but {
        width: 100px;
        min-width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .human-dispatch-screen-but:hover {
        cursor: pointer;
        background-color: @activeColor2;
      }
      .human-dispatch-screen-but-boder {
        padding: 0 15px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        font-size: @contentFontSize;
        background-color: @activeBackground;
        color: @activeColor;
        margin: 0 5px;
        margin-right: 10px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .human-dispatch-screen-but-boder:hover {
        cursor: pointer;
        background-color: @activeBackground2;
      }
    }
  }
  .human-dispatch-table {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    .human-dispatch-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .human-dispatch-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .human-dispatch-table-card-tab {
          width: 130px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .human-dispatch-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .human-dispatch-table-card-active {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: @contentFontSize;
          .textArea {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .numArea {
            height: 100%;
            padding-top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            color: @activeColor;
          }
        }
        .human-dispatch-table-card-edit {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: @editColor;
          cursor: pointer;
        }
      }
      .human-dispatch-table-card-content {
        flex: 1;
        width: 98%;
        .human-dispatch-table-card-content_text {
          width: auto;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 0px;
          box-sizing: border-box;
          color: rgb(99, 211, 34);
          font-size: 12px;
        }
        .royaltyRate {
          /deep/ .el-input__inner {
            width: 25px;
            padding: 0;
            margin: 0;
            color: #333;
            cursor: text;
            border: 1px solid transparent;
            background-color: transparent;
            text-align: right;
            color: #409eff;
          }

          /deep/ .el-input {
            width: 70%;
          }
        }
        /deep/ .el-input__inner {
          color: #333;
          cursor: text;
          border: 1px solid transparent;
          background-color: transparent;
        }
        /deep/ .el-input__suffix {
          display: none;
        }
      }
      .human-dispatch-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }

  .table-tip {
    height: 30px;
    width: auto;
    padding: 2px 5px;
    margin: 0 3px;
    border: 1px solid #333;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hri {
    display: flex;
    i {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 3px;
      font-size: 14px;
      color: @activeColor;
    }
  }
  .link {
    color: @activeColor;
    transition: 0.3s;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .el-date-editor.el-input__inner {
    width: 300px !important;
  }
  .el-table__row--level-1 {
    background-color: #dddddd !important;
  }
}
</style>
