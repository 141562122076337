<template>
  <div id="expressage-account">
    <div class="expressage-account-top">
      <div class="expressage-account-top-left">
        <div class="left-inner">
          <div class="info-bd">
            <div style="font-size: 24px; line-height: 30px;">
              ¥ {{ accountDetail.availableAmount }}
            </div>
            <div>可用余额</div>
            <div
              class="expressage-account-top-button"
              @click="handleClick('', 0)"
              v-showFunction="{
                active: 'recharge',
                array: allFunctionButtons,
              }"
            >
              充值
            </div>
          </div>
          <div class="info-ft">
            <div class="info-ft-item">
              <div>账户余额</div>
              <div>¥{{ accountDetail.accountBalance }}</div>
            </div>
            <div class="info-ft-item">
              <div>不可用金额</div>
              <div
                style="color: #40a3ff; cursor: pointer;"
                @click="handleClick('', 1)"
              >
                ¥{{ accountDetail.unavailableBalance }}
              </div>
            </div>
          </div>
          <div class="info-invoice">
            <div>
              <span style="margin-right: 5px">可开票金额</span>
              <span>{{ accountDetail.unavailableBalance }}</span>
            </div>
            <div
              class="info-invoice-button"
              @click="handleClick('', 2)"
              v-showFunction="{
                active: 'invoice',
                array: allFunctionButtons,
              }"
            >
              开发票
            </div>
          </div>
        </div>
      </div>
      <div class="expressage-account-top-right">
        <div class="right-head">
          <div class="right-head-title">快递统计</div>
          <div>
            <el-date-picker
              @change="datePickerChange"
              v-model="searchData.time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              :clearable="false"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="right-body">
          <div class="right-body-title">
            <div style="font-size: 24px;">{{ expressStatus.total }}</div>
            <div>全部快递</div>
          </div>
          <div class="right-body-send fs14">
            <div class="right-body-send-hd">
              <span style="margin-right: 10px">寄付快递</span>
              <span class="fs16">{{ expressStatus.paidTotal }}</span>
            </div>
            <div class="right-body-bd">
              <div
                class="right-body-bd-item fs12"
                v-for="item in expressStatus.deliveryExpress"
                :key="item.title"
              >
                <div class="right-body-bd-item-title">
                  {{ item.title }}
                </div>
                <div class="right-body-bd-item-value">
                  {{ item.value }}
                </div>
              </div>
            </div>
          </div>
          <div class="right-body-send">
            <div class="right-body-send-hd">
              <span style="margin-right: 10px">到付快递</span>
              <span class="fs16">{{ expressStatus.codTotal }}</span>
            </div>
            <div class="right-body-bd">
              <div
                class="right-body-bd-item fs12"
                v-for="item in expressStatus.collectExpress"
                :key="item.title"
              >
                <div class="right-body-bd-item-title">
                  {{ item.title }}
                </div>
                <div class="right-body-bd-item-value">
                  {{ item.value }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-content">
      <div class="expressage-account-table-card">
        <div class="expressage-account-table-card-tabs">
          <div
            :class="{
              'expressage-account-table-card-tab': true,
              'is-active': tabIndex == 0,
            }"
            @click="changeTab(0)"
          >
            充值及扣款记录
          </div>
          <!-- <div
            :class="{
              'expressage-account-table-card-tab': true,
              'is-active': tabIndex == 1,
            }"
            @click="changeTab(1)"
          >
            开票记录
          </div> -->
          <div class="expressage-account-table-card-active"></div>
          <el-select
            style="width: 105px;"
            v-model="searchData.record"
            @change="initTableData"
          >
            <el-option
              v-for="item in recordList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input
            v-model="searchData.key"
            style="width: 200px; margin: 0 10px"
            placeholder="输入运单号、收件人搜索"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              size="mini"
              @click.native.stop="initTableData"
            ></el-button
          ></el-input>
        </div>
        <div class="expressage-account-content">
          <el-table
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            height="98%"
            v-loading="loading"
          >
            <el-table-column type="index" width="50" fixed> </el-table-column>
            <el-table-column
              prop="payTime"
              label="充值或扣款时间"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="payType" label="类型">
              <template slot-scope="scope">
                <span v-if="scope.row.payType == 1">充值</span>
                <span v-else>扣款</span>
              </template>
            </el-table-column>
            <el-table-column prop="amount" label="金额">
              <template slot-scope="scope">
                <span :style="{ color: scope.row.color }"
                  >{{ scope.row.payType == 1 ? "+" : "-" }}
                  {{ scope.row.amount }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="payUserName"
              label="充值或下单员工"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="deliveryId"
              label="运单号"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="originalPrice" label="原价">
            </el-table-column>
            <el-table-column prop="preferential" label="优惠">
            </el-table-column>
            <el-table-column prop="itemType" label="项目"></el-table-column>
            <el-table-column label="操作"> </el-table-column>
          </el-table>
        </div>
        <div class="expressage-account-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import * as expressageData from "./js/expressage";
import my_dialog from "@/components/dialog.vue";

export default {
  components: {
    my_dialog,
  },
  data() {
    return {
      value1: "",
      loading: false,
      tabIndex: 0,
      baseInfo: {},
      tableData: [],
      searchData: {
        key: "", // 运单号, 收件人搜索
        record: "", // 记录
        time: ["", ""], // 快递统计日期
      },
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "运单详情",
        buttonArray: [
          {
            text: "确认",
            active: "confirm",
          },
        ],
        data: [],
      },
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "expressageAccount"
      ),
      whetherAgencyHead: false,
      moneyList: expressageData.getAccountMoneyList(),
      agreement: true,
      isShow: false,
      recordList: [
        {
          label: "全部记录",
          value: "",
        },
        {
          label: "充值记录",
          value: "1",
        },
        {
          label: "扣款记录",
          value: "2",
        },
      ],
      accountDetail: {},
      expressStatus: [], // 快递状态
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "expressageAccount",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getTradeAccountDetail();
        this.initTableData();
      });
    },
    /**
     * 获取账户金额详情
     */
    getTradeAccountDetail() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/expressPayRecords/getTradeAccountDetail",
        requestData,
        (data) => {
          this.$cutOutNum.cutOutNum(data.availableAmount, this, (money) => {
            data.availableAmount = money;
          });
          this.$cutOutNum.cutOutNum(data.accountBalance, this, (money) => {
            data.accountBalance = money;
          });
          this.$cutOutNum.cutOutNum(data.unavailableBalance, this, (money) => {
            data.unavailableBalance = money;
          });
          data.unavailableOrderList.forEach((item) => {
            if (item.preFreight) {
              this.$cutOutNum.cutOutNum(item.preFreight, this, (money) => {
                item.preFreight = money;
              });
            }
          });
          this.accountDetail = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取快递状态
     */
    getExpressAndTradeStaticInfo() {
      let requestData = {
        time: this.searchData.time,
      };
      this.$request(
        "post",
        "/expressPayRecords/getExpressAndTradeStaticInfo",
        requestData,
        (data) => {
          this.expressStatus = expressageData.DeliveryMoney(data, this);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      this.tableData = [];
      this.loading = true;
      let requestData = {
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
        key: this.searchData.key, // 运单号、收件人模糊搜索
        payType: this.searchData.record, // 充值还是扣款，1-充值；2-扣款
      };
      this.$request(
        "post",
        "/expressPayRecords/queryAccountFlowRecord",
        requestData,
        (data) => {
          this.tableData = expressageData.getAccountListData(
            data.records,
            this
          );
          this.pageData.total = data.total; // 获取总条目数
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    /**
     * 界面操作
     */
    handleClick(data, flag) {
      this.tempData = data;
      let requestData = {};
      switch (flag) {
        case 0:
          // 查询运单详情
          this.dialogData.width = "420px";
          this.dialogData.title = "充值";
          this.dialogData.buttonArray = [
            {
              text: "下一步",
              active: "next",
            },
          ];
          (this.dialogData.data = [
            {
              type: "charge_money",
              data: [
                {
                  amount: "",
                  isShow: this.isShow,
                  moneyList: this.moneyList,
                  agreement: this.agreement,
                },
              ],
            },
          ]),
            console.log("充值dialog显示:", this.dialogData.data);
          this.dialogData.flag = true;
          break;
        case 1:
          // 查看不可用金额列表
          this.dialogData.width = "700px";
          this.dialogData.title = "不可用金额";
          this.dialogData.buttonArray = [];
          (this.dialogData.data = [
            {
              type: "table-summary",
              height: "500",
              data: this.accountDetail.unavailableOrderList,
              props: [
                {
                  prop: "deliveryId",
                  label: "运单号",
                },
                {
                  prop: "preFreight",
                  label: "冻结金额",
                },
                {
                  prop: "orderDate",
                  label: "下单时间",
                },
                {
                  prop: "createName",
                  label: "下单员工",
                },
              ],
            },
          ]),
            console.log("不可用金额dialog显示:", this.dialogData.data);
          this.dialogData.flag = true;
          break;
        case 2:
          // 开发票
          this.$message.error("该功能暂未开通!");
          return;
          // this.dialogData.width = "720px";
          // this.dialogData.title = "申请开票";
          // this.dialogData.buttonArray = [];
          // (this.dialogData.data = [
          //   {
          //     type: "expressage_invoice",
          //     data: [],
          //   },
          // ]),
          //   console.log("不可用金额dialog显示:", this.dialogData.data);
          // this.dialogData.flag = true;
          break;
        default:
          break;
      }
    },
    /**
     * 抽屉数据或dialog数据操作事件
     */
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "next":
          // 展示充值二维码
          let totalAmount = this.dialogData.data[0].data[0].amount;
          let agreement = this.dialogData.data[0].data[0].agreement;
          if (totalAmount == "") {
            this.$message.error("请填入要充值的金额");
            return;
          }
          if (!agreement) {
            this.$message.error("请先勾选我已同意");
            return;
          }
          // 展示支付二维码
          requestData = {
            userId: this.baseInfo.userId, // 下单用户 id
            companyId: this.baseInfo.company.companyId, // 公司 id
            description: "支付", // 描述
            outTradeNo: this.tempData.deliveryId, // 订单号 没有不传
            totalAmount: totalAmount, // 订单金额
          };
          let jsonRequestData = JSON.stringify(requestData);
          this.$request(
            "get",
            `/wxPay/getQrCode?state=${jsonRequestData}`,
            {},
            (data) => {
              // const blob = base64ImgtoFile(pic);
              // const blobUrl = window.URL.createObjectURL(blob);
              // this.dialogData.flag = false;
              // 查询运单详情
              this.dialogData.width = "430px";
              this.dialogData.title = "支付";
              this.dialogData.buttonArray = [];
              (this.dialogData.data = [
                {
                  type: "account_qrCode",
                  data: [
                    {
                      url: data,
                      rowData: this.tempData,
                    },
                  ],
                },
              ]),
                console.log("充值二维码dialog显示:", this.dialogData.data);
              this.dialogData.flag = true;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "refresh":
          // 充值成功刷新页面
          this.colseDialog();
          this.getTradeAccountDetail();
          this.initTableData();
          break;
        case "cancel":
          this.colseDialog();
          break;

        default:
          break;
      }
    },
    /**
     * tab切换事件
     */
    changeTab(index) {
      this.tabIndex = index;
      this.initTableData();
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
      this.initTableData();
    },
    /**
     * 选择快递统计时间
     */
    datePickerChange(val) {
      this.searchData.time = val;
      this.getExpressAndTradeStaticInfo();
    },
  },
  created() {
    this.searchData.time[1] = this.$formatTime(new Date(), "YYYY-MM-DD"); // 快递统计 开始时间
    this.searchData.time[0] = this.$formatTime(
      new Date(new Date() - 1000 * 60 * 60 * 24 * 30),
      "YYYY-MM-DD"
    );
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
    this.getExpressAndTradeStaticInfo();
  },
};
</script>

<style lang="less" scoped>
#expressage-account {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .expressage-account-top {
    display: flex;
    padding: 20px 20px 10px 20px;
    background-color: @contentBackground;
    .expressage-account-top-left {
      align-items: center;
      background: #fff;
      border: 1px solid #c4c6cc;
      display: flex;
      font-size: 12px;
      margin-right: 20px;
      width: 250px;
      .left-inner {
        padding: 16px;
        width: 100%;
        .info-bd {
          display: flex;
          flex-direction: column;
          align-items: center;
          border-bottom: 1px solid #d1d3d8;
          padding-bottom: 20px;
          text-align: center;
          .expressage-account-top-button {
            cursor: pointer;
            background-color: #2c7db3;
            width: 55px;
            min-width: 55px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #40a3ff;
            font-size: 14px;
            color: #fff;
            margin: 0 5px;
            border-radius: 4px;
            transition: 0.5s;
            &:hover {
              background-color: #3f94cc;
              transition: all 0.3s;
            }
          }
        }
        .info-ft {
          display: flex;
          flex-direction: column;
          color: #666;
          padding: 8px 40px 0;
          .info-ft-item {
            display: flex;
            justify-content: space-between;
          }
        }
        .info-invoice {
          align-items: center;
          display: flex;
          justify-content: space-between;
          margin-top: 12px;
          .info-invoice-button {
            width: 68px;
            height: 24px;
            line-height: 24px;
            text-align: center;
            border: 1px solid @baseColor;
            color: @baseColor;
            border-radius: 3px;
            cursor: pointer;
            &:hover {
              transition: all 0.3s;
              background-color: #f0f4f9;
            }
          }
        }
      }
    }
    .expressage-account-top-right {
      background: #fff;
      border: 1px solid #c4c6cc;
      flex-grow: 1;
      min-width: 232px;
      .fs16 {
        font-size: 16px;
      }
      .fs14 {
        font-size: 14px;
      }
      .fs12 {
        font-size: 12px;
      }
      .right-head {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #d1d3d8;
        padding: 10px 24px;
        font-size: 14px;
        .right-head-title {
          margin-right: 20px;
        }
      }
      .right-body {
        display: flex;
        width: 100%;
        padding: 20px 0;
        height: 55%;
        font-size: 12px;
        .right-body-title {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 20%;
          min-width: 120px;
        }
        .right-body-send {
          width: 312px;
          min-width: 312px;
          margin-right: 120px;
          .right-body-send-hd {
            width: 100%;
            display: flex;
            align-items: center;
            padding-bottom: 15px;
            border-bottom: 1px solid #999;
          }
          .right-body-bd {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .right-body-bd-item {
              width: 47%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin: 5px 0;
              color: #666;
            }
          }
        }
      }
    }
  }
  .table-content {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    .expressage-account-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .expressage-account-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        align-items: center;
        font-size: @contentFontSize;
        .expressage-account-table-card-tab {
          width: 120px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .expressage-account-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .expressage-account-table-card-active {
          flex: 1;
        }
      }
      .expressage-account-content {
        width: 100%;
        height: 95%;
        max-width: 90vw;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        /deep/ .el-table--scrollable-y .el-table__body-wrapper {
          overflow-x: auto;
          height: 300px;
          &::-webkit-scrollbar {
            height: 8px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: rgba(0, 0, 0, 0.2);
          }
        }
      }
      .expressage-account-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
