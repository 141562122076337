<template>
  <div id="expressage-info">
    <!-- 寄件管理 -->
    <div class="expressage-info-screen">
      <div class="expressage-info-screen-line">
        <div class="expressage-info-screen-fiftrate">
          <el-popover
            placement="bottom-start"
            width="250"
            trigger="manual"
            :visible-arrow="false"
            v-model="popoData.popoVisible"
          >
            <div class="popover-search">
              <popo_search
                :list="popoData.list"
                @cancel="popoCanael"
                @reset="popoReset"
                @makeSure="popoMakeSure"
              ></popo_search>
            </div>
            <div slot="reference" @click="popoverOpen">
              筛选 <i class="el-icon-s-operation"></i>
            </div>
          </el-popover>
        </div>
        <div class="expressage-info-screen-search">
          <el-input
            v-model="searchData.input"
            placeholder="请输入客户全称/运单号/收件人"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="expressage-info-screen-search">
          <el-select
            v-model="searchData.deliveryState"
            filterable
            @change="initTableData"
          >
            <el-option
              v-for="item in searchData.deliveryStateList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="expressage-info-screen-search">
          <el-select
            v-model="searchData.payOrNot"
            placeholder="请选择支付状态"
            filterable
            clearable
            @change="initTableData"
          >
            <el-option
              v-for="item in searchData.payStateList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="expressage-info-screen-search">
          <el-checkbox v-model="searchData.excludeFail" @change="initTableData"
            >不看未成交</el-checkbox
          >
        </div>
        <div class="expressage-info-screen-active"></div>
        <div
          class="expressage-info-screen-but"
          @click="handleClick('', 1)"
          v-showFunction="{
            active: 'order',
            array: allFunctionButtons,
          }"
        >
          寄件
        </div>
        <div
          class="expressage-info-screen-but-boder"
          @click="handleClick('', 4)"
          v-showFunction="{
            active: 'export',
            array: allFunctionButtons,
          }"
        >
          导出
        </div>
      </div>
      <div
        class="expressage-info-screen-line"
        v-if="searchData.screenList.length > 0"
      >
        <div class="title">筛选条件:</div>
        <el-tag
          v-for="(tag, index) in searchData.screenList"
          :key="index"
          closable
          size="small"
          @close="closeScreen(index)"
        >
          {{ tag.name }}
        </el-tag>
      </div>
    </div>

    <div class="table-content">
      <div class="expressage-info-table-card">
        <div class="expressage-info-content">
          <el-table
            :data="tableData"
            highlight-current-row
            :row-style="{ height: '60px' }"
            style="width: 100%"
            height="98%"
            v-loading="loading"
          >
            <el-table-column type="index" width="40" fixed> </el-table-column>
            <el-table-column
              prop="deliveryId"
              label="运单号"
              width="150"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="receiverClientName"
              label="客户名称"
              width="180"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="receiverName"
              label="收件人"
              width="100"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column prop="goods" label="物品" show-overflow-tooltip>
            </el-table-column>

            <el-table-column
              prop="orderDate"
              label="下单时间"
              width="150"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="createName"
              label="下单员工"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column width="80" prop="payAmount" label="实扣运费">
            </el-table-column>
            <el-table-column
              prop="deliveryState"
              label="订单状态"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="payOrNot"
              label="支付状态"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span style="color: #909399" v-if="scope.row.payOrNot == 0"
                  >无需支付</span
                >
                <span style="color: #409EFF" v-else-if="scope.row.payOrNot == 1"
                  >未支付</span
                >
                <span style="color: #E6A23C" v-else-if="scope.row.payOrNot == 2"
                  >待支付</span
                >
                <span style="color: #67C23A" v-else>已支付</span>
              </template>
            </el-table-column>
            <el-table-column prop="memo" label="备注" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="操作" width="200" fixed="right">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  plain
                  icon="el-icon-edit-outline"
                  @click.native="handleClick(scope.row, 0)"
                  v-showFunction="{
                    active: 'query',
                    array: allFunctionButtons,
                  }"
                  >查询</el-button
                >
                <el-button
                  v-if="scope.row.deliveryState == '待揽收'"
                  type="info"
                  size="mini"
                  plain
                  icon="el-icon-edit-outline"
                  @click.native="handleClick(scope.row, 2)"
                  v-showFunction="{
                    active: 'cancel',
                    array: allFunctionButtons,
                  }"
                  >取消</el-button
                >
                <el-button
                  v-if="scope.row.payOrNot == 2"
                  type="success"
                  size="mini"
                  plain
                  icon="el-icon-check"
                  @click.native="handleClick(scope.row, 3)"
                  v-showFunction="{
                    active: 'pay',
                    array: allFunctionButtons,
                  }"
                  >支付</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="expressage-info-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import * as expressageData from "./js/expressage";
import my_dialog from "@/components/dialog.vue";
import popo_search from "@/components/popo-search.vue";

export default {
  components: {
    popo_search,
    my_dialog,
  },
  data() {
    return {
      loading: false,
      baseInfo: {},
      tableData: [],
      searchData: {
        input: "",
        deliveryState: "",
        excludeFail: false,
        deliveryStateList: expressageData.getDeliveryState(),
        payStateList: expressageData.getPayState(),
        payOrNot: "",
        allUserList: [],
        screenList: [],
      },
      defaultSort: { prop: "", order: "" },
      popoData: {
        popoVisible: false,
        list: expressageData.expressageInfoSearchData(),
      },
      arrayList: {},
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "expressageInfo"
      ),
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "运单详情",
        buttonArray: [
          {
            text: "确认",
            active: "confirm",
          },
        ],
        data: [],
      },
      clientList: [],
      tempData: {},
      whetherAgencyHead: false,
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "expressageInfo",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getEmployeeListForDownBox();
        this.getClientList();
        this.initTableData();
      });
    },
    /**
     * 获取客户名称列表
     */
    getClientList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/client/getAllOptions",
        requestData,
        (data) => {
          this.clientList = data;
        },
        (errormessage) => {
          this.$message.error(errormessage);
        }
      );
    },
    /**
     * 获取所有人员列表
     */
    getEmployeeListForDownBox() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/user/getEmployeeListForDownBox",
        requestData,
        (data) => {
          this.searchData.allUserList = data;
        }
      );
    },

    /**
     * 初始化表格数据
     */
    initTableData() {
      this.tableData = [];
      this.loading = true;
      let requestData = {
        companyId: this.baseInfo.company.companyId, // 当前公司ID
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
        search: this.searchData.input, // 客户全称 运单号
        sortProp: this.defaultSort.prop, // 手动排序项
        sortOrder: this.defaultSort.order, // 手动排序规则 ascending descending ""
        deliveryState: this.searchData.deliveryState, // 运单状态
        payOrNot: this.searchData.payOrNot, // 支付状态
        excludeFail: this.searchData.excludeFail, // 是否查看未成交
        screenList: this.searchData.screenList, // 其余筛选条件
      };
      this.$request(
        "post",
        "/expressOrder/queryOrderByPage",
        requestData,
        (data) => {
          this.tableData = data.records;
          this.pageData.total = data.total; // 获取总条目数
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    /**
     * 界面操作
     */
    handleClick(data, flag) {
      this.tempData = data;
      let requestData = {};
      switch (flag) {
        case 0:
          // 查询运单详情
          this.dialogData.width = "1000px";
          this.dialogData.title = "运单详情";
          this.dialogData.buttonArray = [];
          this.$request(
            "get",
            `/expressOrder/getOrderById?id=${data.id}`,
            {},
            (resData) => {
              (this.dialogData.data = [
                {
                  title: "",
                  type: "get_delivery",
                  data: expressageData.getDeliveryOrderDetails(
                    resData,
                    this.clientList
                  ),
                },
              ]),
                console.log("查询运单详情dialog显示:", this.dialogData.data);
              this.dialogData.flag = true;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case 1:
          // 寄件
          this.dialogData.width = "1000px";
          this.dialogData.title = "寄件";
          this.dialogData.buttonArray = [];
          this.$request(
            "get",
            `/expressAddress/getSendAddress?userId=${this.baseInfo.userId}`,
            requestData,
            (data) => {
              (this.dialogData.data = [
                {
                  title: "",
                  type: "mail",
                  data: expressageData.mailDialog(
                    this.clientList,
                    data,
                    "",
                    ""
                  ),
                },
              ]),
                console.log("寄件dialog显示:", this.dialogData.data);
              this.dialogData.flag = true;
            },
            (errormessage) => {
              this.$message.error(errormessage);
            }
          );
          break;
        case 2:
          // 取消订单
          this.$confirm("确定要取消订单吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$request(
                "get",
                `/expressOrder/cancel?id=${data.id}&operatorId=${this.baseInfo.userId}`,
                {},
                (data) => {
                  this.$message({
                    type: "success",
                    message: "取消成功!",
                  });
                  this.initTableData();
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {});
          break;
        case 3:
          // 展示支付二维码
          requestData = {
            userId: this.baseInfo.userId, // 下单用户 id
            companyId: this.baseInfo.company.companyId, // 公司 id
            description: "支付", // 描述
            outTradeNo: this.tempData.deliveryId, // 订单号 没有不传
            totalAmount: this.tempData.freight, // 订单金额
          };
          let jsonRequestData = JSON.stringify(requestData);
          this.$request(
            "get",
            `/wxPay/getQrCode?state=${jsonRequestData}`,
            {},
            (data) => {
              // const blob = base64ImgtoFile(pic);
              // const blobUrl = window.URL.createObjectURL(blob);
              // this.dialogData.flag = false;
              // 查询运单详情
              this.dialogData.width = "430px";
              this.dialogData.title = "支付";
              this.dialogData.buttonArray = [];
              (this.dialogData.data = [
                {
                  type: "account_qrCode",
                  data: [
                    {
                      url: data,
                      rowData: this.tempData,
                    },
                  ],
                },
              ]),
                console.log("充值二维码dialog显示:", this.dialogData.data);
              this.dialogData.flag = true;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case 4:
          requestData = {
            excludeFail: this.searchData.excludeFail, // 是否查看未成交
            key: this.searchData.input, // 客户全称 运单号
            screenList: this.searchData.screenList, // 其余筛选条件
          };
          window.location.href =
            this.$baseurl +
            "/expressOrder/exportExcel?data=" +
            JSON.stringify(requestData);
          break;
        default:
          break;
      }
    },
    /**
     * 抽屉数据或dialog数据操作事件
     */
    drawerOper(active) {
      switch (active) {
        case "refresh":
          // 支付成功刷新页面
          this.colseDialog();
          this.initTableData();
          break;
        case "cancel":
          this.colseDialog();
          break;
        default:
          break;
      }
    },
    // /**
    //  * 表格排序事件
    //  */
    // sortChange(data) {
    //   console.log(data);
    //   this.defaultSort.prop = data.order == null ? "" : data.prop;
    //   this.defaultSort.order = data.order == null ? "" : data.order;
    //   // this.initTableData();
    // },
    /**
     * 筛选弹出框关闭事件
     */
    popoCanael() {
      this.popoData.popoVisible = false;
    },
    /**
     * 筛选弹出框重置
     */
    popoReset() {
      this.$systemBaseData.popoReset(this.popoData.list);
      this.searchData.screenList = [];
    },
    /**
     * 筛选弹出框显示
     */
    popoverOpen() {
      // console.log("所有数组数据:", this.arrayList);
      this.popoData.list[1].options = []; // 下单员工
      this.popoData.list[1].options = this.searchData.allUserList.map(
        (item, index) => {
          return { label: item.userName, value: item.userId };
        }
      );
      this.popoData.popoVisible = !this.popoData.popoVisible;
    },
    /**
     * 筛选弹出框确认事件
     */
    popoMakeSure() {
      this.searchData.screenList = this.$systemBaseData.remakesureSearchData(
        this.popoData.list
      );
      this.popoData.popoVisible = false;
      this.initTableData();
    },
    /**
     * 关闭筛选标签事件
     */
    closeScreen(index) {
      this.searchData.screenList.splice(index, 1);
      this.initTableData();
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
      this.initTableData();
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
    // 下单
    this.$bus.$off("order").$on("order", () => {
      console.log("事件总线 order");
      let nowData = this.dialogData.data[0].data;
      if (!nowData.sender.name) {
        this.$message.error("请输入寄件人姓名!");
        return;
      }
      if (!nowData.sender.phone) {
        this.$message.error("请输入寄件人电话!");
        return;
      }
      if (nowData.sender.code.length == 0) {
        this.$message.error("请输入寄件人省市区!");
        return;
      }
      if (!nowData.sender.address) {
        this.$message.error("请输入寄件人详细地址!");
        return;
      }
      if (!nowData.receiver.name) {
        this.$message.error("请输入收件人姓名!");
        return;
      }
      if (!nowData.receiver.phone) {
        this.$message.error("请输入收件人电话!");
        return;
      }
      if (nowData.receiver.code.length == 0) {
        this.$message.error("请输入收件人省市区!");
        return;
      }
      if (!nowData.receiver.address) {
        this.$message.error("请输入收件人详细地址!");
        return;
      }
      if (!nowData.payMethod) {
        this.$message.error("请输入支付方式!");
        return;
      }
      if (!nowData.preWeight) {
        this.$message.error("请输入货物重量!");
        return;
      }
      if (!nowData.sendStartTime) {
        this.$message.error("请输入预计送达时间!");
        return;
      }
      // if (new Date(nowData.startTime).getTime() < new Date()) {
      //   this.$message.error("预计送达时间要大于当前时间!");
      //   return;
      // }
      let flag1 = true;
      if (!this.$phoneCheck.mobilePhoneNumberCheck(nowData.sender.phone)) {
        // 做手机号格式校验
        flag1 = false;
      }
      if (!flag1) {
        this.$message({
          message: "联系人手机号格式不正确,请确认填入正确的手机号!",
          type: "error",
          duration: 1000,
        });
        return;
      }
      let flag2 = true;
      if (!this.$phoneCheck.mobilePhoneNumberCheck(nowData.receiver.phone)) {
        // 做手机号格式校验
        flag2 = false;
      }
      if (!flag2) {
        this.$message({
          message: "联系人手机号格式不正确,请确认填入正确的手机号!",
          type: "error",
          duration: 1000,
        });
        return;
      }
      if (!nowData.serviceAgreement) {
        this.$message.error("请先勾选我已同意");
        return;
      }
      let senderCode = expressageData.transformCode(nowData.sender.code);
      let receiverCode = expressageData.transformCode(nowData.receiver.code);
      let activeArchives = JSON.stringify(nowData.activeArchives);
      if (nowData.goods == "") {
        nowData.goods = "文件";
      }
      let requestData = {
        companyId: this.baseInfo.company.companyId, // 公司 id
        createId: this.baseInfo.userId, // 下单人 id
        supplierName: nowData.supplierName, // 快递服务商
        goods: nowData.goods, // 物品
        expressType: nowData.expressType, // 时效产品 1-顺丰特快 2-顺丰标快
        preWeight: nowData.preWeight, // 下单重量
        isOneseIfPickup: nowData.isOneseIfPickup, // 寄件方式 1-客户同意快件自取 0-客户不同意快件自取
        sendStartTime: nowData.sendStartTime, // 期望上门时间
        payMethod: nowData.payMethod, // 支付方式
        preFreight: nowData.preFreight, // 预估运费
        deliverTime: nowData.deliverTime, // 预计送达时间
        discounts: nowData.discounts, // 预计送达时间
        remark: nowData.remark, // 留言
        memo: nowData.memo, // 备注
        sender: nowData.sender, // 寄件人信息
        receiver: nowData.receiver, // 收件人信息
        saveOrUpdateReceiver: nowData.saveOrUpdateReceiver, // 收件人信息
        archivesInfoIds: activeArchives, // 跟档案模块联动的字段，记录交付的档案 ids
      };
      requestData.sender.userId = this.baseInfo.userId; // 寄件人省份编码
      requestData.sender.createId = this.baseInfo.userId; // 寄件人省份编码
      requestData.sender.provinceCode = senderCode.provinceCode; // 寄件人省份编码
      requestData.sender.cityCode = senderCode.cityCode; // 寄件人城市编码
      requestData.sender.countyCode = senderCode.countyCode; // 寄件人区县编码
      requestData.sender.area = senderCode.area; // 寄件人省市区
      requestData.receiver.createId = this.baseInfo.userId; // 收件人省份编码
      requestData.receiver.provinceCode = receiverCode.provinceCode; // 收件人省份编码
      requestData.receiver.cityCode = receiverCode.cityCode; // 收件人城市编码
      requestData.receiver.countyCode = receiverCode.countyCode; // 收件人区县编码
      requestData.receiver.area = receiverCode.area; // 收件人省市区
      this.$request(
        "post",
        "/expressOrder/send",
        requestData,
        (data) => {
          this.$message.success("下单成功!");
          this.initTableData();
          this.dialogData.flag = false;
          // this.$bus.$off("order");
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    });
  },
};
</script>

<style lang="less" scoped>
#expressage-info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .expressage-info-screen {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    .expressage-info-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      > .title {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 0 10px;
      }
      > .el-tag {
        margin: 0 5px;
      }
      .expressage-info-screen-fiftrate {
        width: 65px;
        min-width: 65px;
        height: 30px;
        border: 1px solid @boderColor;
        box-sizing: border-box;
        color: @boderActiveColor;
        font-size: @contentFontSize;
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: 0.3s;
        i {
          margin: 0 4px;
        }
      }
      .expressage-info-screen-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }
      .expressage-info-screen-search {
        width: 210px;
        min-width: 210px;
        height: 30px;
        margin-left: 10px;
        display: flex;
        align-items: center;
      }
      .expressage-info-screen-active {
        flex: 1;
      }
      .drop-button {
        background-color: #ecf5ff;
        color: #409eff;
        &:hover {
          background-color: #abbed3;
        }
      }
      .expressage-info-screen-but {
        width: 100px;
        min-width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
        &:hover {
          cursor: pointer;
          background-color: @activeColor2;
        }
      }

      .expressage-info-screen-but-boder {
        width: 50px;
        min-width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        font-size: @contentFontSize;
        background-color: @activeBackground;
        color: @activeColor;
        margin: 0 5px;
        margin-right: 10px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .expressage-info-screen-but-boder:hover {
        cursor: pointer;
        background-color: @activeBackground2;
      }
    }
  }

  .table-content {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;

    .expressage-info-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .expressage-info-content {
        width: 100%;
        height: 95%;
        max-width: 90vw;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
      }
      .expressage-info-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
