let SM4 = require("gm-crypt").sm4;
let sm4Config = {
    //配置sm4参数
    key: "lyhgjwehfjskslkh", //这里这个key值是跟后端要的
    iv: "wehfjsklyhgjslkh", //这里这个iv值是跟后端要的
    mode: "cbc", // 加密的方式有两种，ecb和cbc两种，也是看后端如何定义的，不过要是cbc的话下面还要加一个iv的参数，ecb不用
    cipherType: "base64" // 
};
let sm4 = new SM4(sm4Config); //这里new一个函数，将上面的sm4Config作为参数传递进去。然后就可以开心的加密了
function encrypt(data) {
    let temp = sm4.encrypt(data)
    console.log(temp);
    return sm4.encrypt(data);
}

function decrypt(data) {
    return sm4.decrypt(data);
}
export {
    encrypt,
    decrypt
}