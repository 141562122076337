import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/main.vue'
import Test from '../views/test.vue'
import Login from '../views/login.vue'
// import Login from '../views/landing.vue'
import Threads from "../views/thread/threads.vue"
import Index from "../views/index.vue"

import MyThread from "../views/thread/thread_mine.vue"
import AllThread from "../views/thread/thread_all.vue"
import MyResources from "../views/thread/my_resources.vue"
import ClientThread from "../views/thread/thread_client.vue"
import PoolThread from "../views/thread/thread_pool.vue"
import RecyleThread from "../views/thread/thread_recyle.vue"
import SettingThread from "../views/thread/thread_setting.vue"
import WeixinResources from "../views/thread/weixin-resource.vue";
import ThreadShare from "../views/thread/thread_share.vue"; // 共享商机

import Department from "../views/department.vue"
import Employee from "../views/employee_setting.vue"
import Company from "../views/company_setting.vue"
import DepartmentSetting from "../views/department_setting.vue"

import System from "../views/system/system.vue"
import SystemAuthority from "../views/system_authority.vue"
import RoleSetting from "../views/role_setting.vue"
import OrganizationSetting from "../views/system/organization_setting.vue"
import XcxSwiper from "../views/system/xcx_swiper.vue";
import XcxPolicy from "../views/system/xcx_policy.vue";
import XcxService from "../views/system/xcx_service.vue";

import Person from "../views/person.vue"
import PersonInfo from "../views/person_info.vue"
import PersonICon from "../views/person_icon.vue"

import Client from "../views/client/client.vue"
import ClientInfo from "../views/client/client_info.vue"
import ClientConnect from "../views/client/client_connect.vue"
import ClientSetting from "../views/client/client_setting.vue"
import ClientCheck from "../views/client/client_check.vue"

import Contract from "../views/contract/contract.vue"
import ContractInfo from "../views/contract/contract_info.vue"
import ContractCheck from "../views/contract/contract_check.vue"
import ContractNote from "../views/contract/contract_note.vue"
import ContractRenew from "../views/contract/contract_renew.vue"
import ContractInvoice from "../views/contract/contract_invoice.vue"
import ContractWork from "../views/contract/contract_work.vue"
import ContractPayment from "../views/contract/contract_payment.vue"
import ContractSetting from "../views/contract/contract_setting.vue"

import Product from "../views/product/product.vue"
import ProductList from "../views/product/product_list.vue"
import ServeItem from "../views/product/serve_item.vue"

import Invoice from "../views/invoice/invoice.vue"
import InvoiceRegister from "../views/invoice/invoice_register.vue"
import InvoiceGather from "../views/invoice/invoice_gather.vue"
import InvoiceInfo from "../views/invoice/invoice_info.vue"
import InvoiceAccount from "../views/invoice/invoice_account.vue"
import InvoiceSetting from "../views/invoice/invoice_setting.vue"
import InvoiceCheck from "../views/invoice/invoice_check.vue"
import TheirTickets from "../views/invoice/their_tickets.vue"
import CommoditySetting from "../views/invoice/commodity_setting.vue"

import Waite from "../views/waite/waite.vue"
import WaiteAll from "../views/waite/waite_all.vue"
import WaiteThread from "../views/waite/waite_thread.vue"
import WaiteContract from "../views/waite/waite_contract.vue"
import WaiteClient from "../views/waite/waite_client.vue"
import WaiteInvoice from "../views/waite/waite_invoice.vue"

import DepartureSetting from "../views/departure_setting.vue"

import Report from "../views/report/report.vue"
import ReportCollection from "../views/report/report_collection.vue"
import ReportSales from "../views/report/report_sales.vue"
import ReportRenewal from "../views/report/report_renewal.vue"
import ReportFollow from "../views/report/report_follow.vue"
import ReportReimburse from "../views/report/report_reimburse.vue"
import EmployeeInformation from "../views/report/report.vue"
import CallList from "../views/report/call_list.vue";
import CallDay from "../views/report/call_day.vue";
import copyRecord from "../views/report/copy_record.vue";
import FailList from "../views/report/fail_list.vue";
import ReportInvoice from "../views/report/report_invoice.vue";

import wechatClient from "../views/wechat_client.vue"
import wechatLink from "../views/wechat_link.vue"
import WechatAddContract from "../components/wechat-add-contract.vue"

import Knowledge from "../views/knowledge/knowledge.vue"
import KnowledgeBase from "../views/knowledge/knowledge_base.vue"
import PublicNotice from "../views/knowledge/public_notice.vue"
import KnowledgeSetting from "../views/knowledge/knowledge_setting.vue"

import WechatInvoiceInfo from "../components/invoice-info.vue"

import Human from "../views/human/human.vue"
import MyHuman from "../views/human/human_mine.vue"
import HumanBase from "../views/human/human_base.vue"
import HumanSetting from "../views/human/human_setting.vue"
import HumanPayroll from "../views/human/human_payroll.vue";
import HumanNote from "../views/human/human_note.vue";
import HumanPerformance from "../views/human/human_performance.vue";
import HumanDispatch from "../views/human/human_dispatch.vue";
import HumanContract from "../views/human/human_contract.vue";

import Reimbursement from "../views/reimbursement/reimbursement.vue"
import MyReimbursement from "../views/reimbursement/my_reimbursement.vue"
import AllReimbursement from "../views/reimbursement/all_reimbursement.vue"
import CheckReimbursement from "../views/reimbursement/check_reimbursement.vue"
import ReimbursementSetting from "../views/reimbursement/reimbursement_setting.vue"

import ReDepartmentDetails from "../views/department/department_details.vue"

import Archives from "../views/archives/archives.vue";
import ArchivesInfo from "../views/archives/archives_info.vue";
import ArchivesSetting from "../views/archives/archives_setting.vue";

import Expressage from "../views/expressage/expressage.vue";
import ExpressageInfo from "../views/expressage/expressage_info.vue";
import ExpressageMaintain from "../views/expressage/expressage_maintain.vue";
import ExpressageAccount from "../views/expressage/expressage_account.vue";
import ExpressageSetting from "../views/expressage/expressage_setting.vue";

import Dispatch from "../views/dispatch/dispatch.vue";
import MyAssignment from "../views/dispatch/myAssignment.vue";
import MyDispatch from "../views/dispatch/myDispatch.vue";
import DispatchSetting from "../views/dispatch/dispatch_setting.vue";
import DispatchPool from "@/views/dispatch/dispatch_pool.vue";
import DispatchContract from "@/views/dispatch/dispatch_contract.vue";
import DispatchSocial from "@/views/dispatch/dispatch_social.vue";

import Tax from "@/views/tax/tax.vue";
import TaxInfo from "@/views/tax/tax_info.vue";
import SocialSecurity from "@/views/tax/social_security.vue";
import SocialCheck from "@/views/tax/social_check.vue";
import TaxStatement from "@/views/tax/tax_statement.vue"; // 企业流水
import TaxPark from "@/views/tax/tax_park.vue"; // 企业园区
import TaxCost from "@/views/tax/tax_cost.vue"; // 财务数据

Vue.use(VueRouter)

let routes = [{
        path: '/',
        name: 'Login',
        component: Login,
    }, {
        path: '/main',
        name: 'Main',
        component: Main,
        // redirect: "/main/index",
        children: [{
                path: "index", // 首页
                name: "Index",
                component: Index,
            },
            {
                path: "thread", // 商机
                name: "Threads",
                component: Threads,
                // redirect: "/main/thread/my-thread",
                children: [{
                        path: "my-thread", // 我的商机
                        name: "MyThread",
                        component: MyThread,
                    },
                    {
                        path: "all-thread", // 全部商机
                        name: "AllThread",
                        component: AllThread,
                    },
                    {
                        path: "my-resources", // 我的资源
                        name: "MyResources",
                        component: MyResources,
                    }, 
                    {
                        path: "weixin-resources", // 微信待加
                        name: "WeixinResources",
                        component: WeixinResources,
                    }, 
                    {
                        path: "thread-share", // 共享商机
                        name: "ThreadShare",
                        component: ThreadShare,
                    }, 
                    {
                        path: "client-thread", // 已签约客户
                        name: "ClientThread",
                        component: ClientThread,
                    },
                    {
                        path: "threads", // 商机中的商机池
                        name: "PoolThread",
                        component: PoolThread,
                    },
                    {
                        path: "threads-recyle", // 商机中的回收站
                        name: "RecyleThread",
                        component: RecyleThread,
                    },
                    {
                        path: "thread-setting", // 商机中的设置
                        name: "SettingThread",
                        component: SettingThread,
                    },
                ]
            },
            {
                path: "client", // 客户
                name: "Client",
                component: Client,
                redirect: "/main/client/client-info",
                children: [{
                    path: "client-info", // 客户资料
                    name: "ClientInfo",
                    component: ClientInfo,
                }, {
                    path: "client-connect", // 工作交接
                    name: "ClientConnect",
                    component: ClientConnect,
                }, {
                    path: "client-check", // 流失审核
                    name: "ClientCheck",
                    component: ClientCheck,
                }, {
                    path: "client-recyle", // 回收站
                    name: "ClientInfo",
                    component: ClientInfo,
                }, {
                    path: "client-setting", // 设置
                    name: "ClientSetting",
                    component: ClientSetting,
                }, ]
            },
            {
                path: "contract", // 合同
                name: "Contract",
                component: Contract,
                redirect: "/main/contract/contract-info",
                children: [{
                    path: "contract-info", // 周期合同
                    name: "ContractInfo",
                    component: ContractInfo,
                }, {
                    path: "contract-check", // 合同审核
                    name: "ContractCheck",
                    component: ContractCheck,
                }, {
                    path: "contract-note", // 记单合同
                    name: "ContractNote",
                    component: ContractNote,
                }, {
                    path: "contract-payment", // 财务收款
                    name: "ContractPayment",
                    component: ContractPayment,
                }, 
                {
                    path: "contract-work", // 合同派工
                    name: "ContractWork",
                    component: ContractWork,
                }, 
                {
                    path: "contract-renew", // 续签管理
                    name: "ContractRenew",
                    component: ContractRenew,
                },
                {
                    path: "contract-invoice", // 合同发票
                    name: "ContractInvoice",
                    component: ContractInvoice,
                },
                {
                    path: "contract-setting", // 设置
                    name: "ContractSetting",
                    component: ContractSetting,
                }, 
                ]
            },
            {
                path: "department", // 部门及员工设置
                name: "Department",
                component: Department,
                // redirect: "/main/department/department-setting",
                children: [{
                        path: "department-setting", // 部门设置
                        name: "DepartmentSetting",
                        // component: DepartmentSetting,
                        component: ReDepartmentDetails,
                    },
                    {
                        path: "company-setting", // 机构设置
                        name: "Company",
                        component: Company,
                    },
                    {
                        path: "employee-setting", // 员工设置
                        name: "Employee",
                        component: Employee,
                    },
                    {
                        path: "departure-setting", // 员工离职设置
                        name: "DepartureSetting",
                        component: DepartureSetting,
                    },
                ]
            },
            {
                path: "invoice", // 发票
                name: "Invoice",
                component: Invoice,
                redirect: "/main/invoice/invoice-info",
                children: [{
                    path: "invoice-info", // 发票开具
                    name: "InvoiceInfo",
                    component: InvoiceInfo,
                }, {
                    path: "invoice-account", // 数电账号
                    name: "InvoiceAccount",
                    component: InvoiceAccount
                },{
                    path: "invoice-check", // 发票审核
                    name: "InvoiceCheck",
                    component: InvoiceCheck,
                }, {
                    path: "their-tickets", // 领票设置
                    name: "TheirTickets",
                    component: TheirTickets
                }, {
                    path: "invoice-collect", // 发票汇总
                    name: "InvoiceCollect",
                    component: ServeItem,
                }, {
                    path: "invoice-register", // 理票登记
                    name: "InvoiceRegister",
                    component: InvoiceRegister,
                }, {
                    path: "invoice-gather", // 进销项采集
                    name: "InvoiceGather",
                    component: InvoiceGather,
                }, {
                    path: "invoice-setting", // 客户设置
                    name: "InvoiceSetting",
                    component: InvoiceSetting,
                }, {
                    path: "commodity-setting", // 常用商品设置
                    name: "CommoditySetting",
                    component: CommoditySetting,
                }, ]
            },
            {
                path: "tax", // 税务
                name: "Tax",
                component: Tax,
                redirect: "/main/tax/tax-info",
                children: [{
                    path: "tax-info", // 税务统计
                    name: "TaxInfo",
                    component: TaxInfo,
                },
                {
                    path: "social-security", // 社保统计
                    name: "SocialSecurity",
                    component: SocialSecurity,
                },
                {
                    path: "social-check", // 社保审核
                    name: "SocialCheck",
                    component: SocialCheck,
                },
                {
                    path: "tax-statement", // 企业流水
                    name: "TaxStatement",
                    component: TaxStatement,
                },
                {
                    path: "tax-park", // 企业园区
                    name: "TaxPark",
                    component: TaxPark,
                },{
                    path: "tax-cost", // 财务数据
                    name: "TaxCost",
                    component: TaxCost,
                },]
            },
            {
                path: "system", // 系统设置
                name: "System",
                component: System,
                children: [{
                    path: "authority-management", // 权限管理
                    name: "RoleSetting",
                    component: RoleSetting,
                }, {
                    path: "organization-setting", // 机构设置
                    name: "OrganizationSetting",
                    component: OrganizationSetting,
                }, 
                {
                    path: "xcx-swiper", // 小程序 轮播图
                    name: "XcxSwiper",
                    component: XcxSwiper,
                },
                {
                    path: "xcx-policy", // 小程序 最新政策
                    name: "XcxPolicy",
                    component: XcxPolicy,
                }, 
                {
                    path: "xcx-service", // 小程序 更多服务
                    name: "XcxService",
                    component: XcxService,
                },
            ]
            },
            {
                path: "person", // 个人设置
                name: "Person",
                component: Person,
                redirect: "/main/person/person-info",
                children: [{
                    path: "person-info", // 个人资料
                    name: "PersonInfo",
                    component: PersonInfo,
                }, {
                    path: "person-icon", // 头像设置
                    name: "PersonICon",
                    component: PersonICon,
                }, ]
            },
            {
                path: "product", // 产品
                name: "Product",
                component: Product,
                redirect: "/main/product/product-list",
                children: [{
                    path: "product-list", // 产品组合
                    name: "ProductList",
                    component: ProductList,
                }, {
                    path: "serve-item", // 服务项管理
                    name: "ServeItem",
                    component: ServeItem,
                }, ]
            },
            // {
            //     path: "waite", // 待办
            //     name: "Waite",
            //     component: Waite,
            //     redirect: "/main/waite/waite-all",
            //     children: [{
            //         path: "waite-all", // 所有待办
            //         name: "WaiteAll",
            //         component: WaiteAll,
            //     }, {
            //         path: "waite-thread", // 待跟进商机
            //         name: "WaiteThread",
            //         component: WaiteThread,
            //     }, {
            //         path: "waite-contract", // 合同待办
            //         name: "WaiteContract",
            //         component: WaiteContract,
            //     }, {
            //         path: "waite-client", // 待客户流失审核
            //         name: "WaiteClient",
            //         component: WaiteClient,
            //     }, {
            //         path: "waite-invoice", // 待开票
            //         name: "WaiteInvoice",
            //         component: WaiteInvoice,
            //     }, ]
            // },
            {
                path: "dispatch", // 派工
                name: "dispatch",
                component: Dispatch,
                redirect: "/main/dispatch/my-assignment",
                children: [{
                    path: "my-assignment", // 我的任务
                    name: "myAssignment",
                    component: MyAssignment,
                },
                {
                    path: "my-dispatch", // 我的派工
                    name: "myDispatch",
                    component: MyDispatch,
                },
                {
                    path: "dispatch-pool", // 派工池
                    name: "dispatchPool",
                    component: DispatchPool,
                },
                {
                    path: "dispatch-contract", // 合同派工
                    name: "dispatchContract",
                    component: DispatchContract,
                },
                {
                    path: "dispatch-social", // 合同派工
                    name: "dispatchSocial",
                    component: DispatchSocial,
                },
                {
                    path: "dispatch-setting", // 设置
                    name: "dispatchSetting",
                    component: DispatchSetting,
                },  ]
            },
            {
                path: "report", // 报表
                name: "report",
                component: Report,
                redirect: "/main/report/report-collection",
                children: [{
                    path: "report-collection", // 收款报表
                    name: "ReportCollection",
                    component: ReportCollection,
                }, {
                    path: "report-sales", // 销售统计
                    name: "ReportSales",
                    component: ReportSales,
                }, {
                    path: "report-renewal", // 续费统计
                    name: "ReportRenewal",
                    component: ReportRenewal,
                }, {
                    path: "report-follow", // 跟进记录
                    name: "ReportFollow",
                    component: ReportFollow,
                }, {
                    path: "report-reimburse", // 报销报表
                    name: "ReportReimburse",
                    component: ReportReimburse,
                }, {
                    path: "employee-information", // 员工信息
                    name: "EmployeeInformation",
                    component: EmployeeInformation,
                }, {
                    path: "call-list", // 话单列表
                    name: "CallList",
                    component: CallList,
                },
                {
                    path: "call-day", // 日账单
                    name: "CallDay",
                    component: CallDay,
                },
                {
                    path: "copy-record", // 复制记录
                    name: "copyRecord",
                    component: copyRecord,
                },
                {
                    path: "fail-list", // 失败列表
                    name: "FailList",
                    component: FailList,
                },
                {
                    path: "report-invoice", // 发票报表
                    name: "ReportInvoice",
                    component: ReportInvoice,
                },]
            },
            {
                path: "knowledge", // 知识库
                name: "knowledge",
                component: Knowledge,
                redirect: "/main/knowledge/knowledge-base",
                children: [{
                    path: "knowledge-base", // 知识库
                    name: "KnowledgeBase",
                    component: KnowledgeBase,
                }, {
                    path: "public-notice", // 公告
                    name: "PublicNotice",
                    component: PublicNotice,
                }, {
                    path: "knowledge-setting", // 知识库 -- 设置
                    name: "KnowledgeSetting",
                    component: KnowledgeSetting,
                }, ]
            },
            {
                path: "archives", // 档案
                name: "archives",
                component: Archives,
                redirect: "/main/archives/archives-info",
                children: [{
                    path: "archives-info", // 客户档案
                    name: "archivesInfo",
                    component: ArchivesInfo,
                },
                {
                    path: "archives-setting", // 设置
                    name: "ArchivesSetting",
                    component: ArchivesSetting,
                },  ]
            },
            {
                path: "expressage", // 快递
                name: "expressage",
                component: Expressage,
                redirect: "/main/expressage/expressage-info",
                children: [{
                    path: "expressage-info", // 寄件管理
                    name: "expressageInfo",
                    component: ExpressageInfo,
                },
                {
                    path: "expressage-maintain", // 地址维护
                    name: "expressageMaintain",
                    component: ExpressageMaintain,
                },
                {
                    path: "expressage-account", // 我的账户
                    name: "expressageAccount",
                    component: ExpressageAccount,
                }, 
                ]
            },
            {
                path: "human", // 人力资源模块
                name: "human",
                component: Human,
                redirect: "/main/human/human-payroll",
                children: [
                {
                    path: "human-payroll", // 工资单
                    name: "HumanPayroll",
                    component: HumanPayroll,
                },
                {
                    path: "human-note", // 记单合同
                    name: "HumanNote",
                    component: HumanNote,
                },
                {
                    path: "human-performance", // 业绩提成表
                    name: "HumanPerformance",
                    component: HumanPerformance,
                },
                {
                    path: "human-dispatch", // 工商提成表
                    name: "HumanDispatch",
                    component: HumanDispatch,
                },
                {
                    path: "human-contract", // 交付提成表
                    name: "HumanContract",
                    component: HumanContract,
                },
                {
                    path: "human-mine", // 我的人才
                    name: "MyHuman",
                    component: MyHuman,
                }, 
                // {
                //     path: "human-base", // 人才库
                //     name: "HumanBase",
                //     component: HumanBase,
                // }, 
                // {
                //     path: "human-setting", // 人力资源模块 -- 人才设置
                //     name: "HumanSetting",
                //     component: HumanSetting,
                // }, 
               ]
            }, {
                path: "reimbursement", // 报销模块
                name: "reimbursement",
                component: Reimbursement,
                redirect: "/main/reimbursement/my-reimbursement",
                children: [{
                    path: "my-reimbursement", // 我的报销
                    name: "MyReimbursement",
                    component: MyReimbursement,
                }, {
                    path: "all-reimbursement", // 全部报销
                    name: "AllReimbursement",
                    component: AllReimbursement,
                }, {
                    path: "check-reimbursement", // 我的审批
                    name: "CheckReimbursement",
                    component: CheckReimbursement,
                }, {
                    path: "reimbursement-setting", // 报销设置
                    name: "ReimbursementSetting",
                    component: ReimbursementSetting,
                }, ]
            }
        ]
    },
    {
        path: '/wechat-client',
        name: 'wechatClient',
        component: wechatClient,
    },
    {
        path: '/wechat-link',
        name: 'wechatLink',
        component: wechatLink,
    },
    {
        path: "/wechat-add-contract",
        name: "WechatAddContract",
        component: WechatAddContract
    },
    {
        path: '/test',
        name: 'Test',
        component: Test,
    },
    {
        path: '/WechatInvoiceInfo',
        name: 'WechatInvoiceInfo',
        component: WechatInvoiceInfo,
    }
]

let router = new VueRouter({
    routes
}) 

// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
    if (to.path === '/' || to.path === '/test' || to.path === '/wechat-client' || to.path === '/wechat-link') {
        next();
    } else {
        // let token = sessionStorage.getItem('token');
        let baseInfo = sessionStorage.getItem("userData");
        // console.log("导航守卫", baseInfo);

        // if (token === 'null' || token === '') {  
        if (baseInfo === 'null' || baseInfo === '' || baseInfo == null) {
            next('/');
        } else {
            next();
        }
    }
});
//重复路由
let originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
export default router