/**
 * 流程模板模拟数据
 */
export function initReimbursementData() {
    return [{
        id: "",
        moneyCon: ["", ""], // 金额条件
        deCon: [
            // 部门条件
        ],
        priority: 1, // 优先级
        approvers: [
            // 审核人
        ],
        twoApprovers: [
            // 第二审核人
        ],
        payer: [
            // 付款确认人
        ],
        isDefault: "0", // 是否默认  0 是 1 否
    }]
}

/**
 * 获取流程条件的弹出框数据类型
 */
export function initDialogDataType() {
    let list = [{
        type: "select",
        title: "优先级",
        placeholder: "选择优先级",
        value: "",
        options: [], // value label
        key: "priority"
    }, {
        type: "depertment_trees",
        title: "部门条件",
        value: [],
        options: [], //  id label
        key: "deCon"
    }, {
        type: "money_range",
        title: "金额范围",
        value: ["", ""],
        key: "moneyCon"
    }, {
        type: "select-object",
        title: "审核人",
        value: [],
        options: [], // value  label
        key: "approvers"
    }, {
        type: "select-object",
        title: "第二审核人",
        value: [],
        options: [], // value  label
        key: "twoApprovers"
    }, {
        type: "select-object",
        title: "付款人",
        value: [],
        options: [], // value  label
        key: "payer"
    }, ]

    return list
}

export function getDialogDefaultData(data, options) {
    let list = initDialogDataType()
    for (let index = 0; index < list.length; index++) {
        const element = list[index];
        if (element.key == 'deCon') {
            element.value = []
            for (let ii = 0; ii < data[element.key].length; ii++) {
                const ele = data[element.key][ii];
                element.value.push(ele.id)
            }
        } else if (element.key == "approvers" || element.key == "twoApprovers" || element.key == "payer") {
            let array = []
            for (let ii = 0; ii < data[element.key].length; ii++) {
                const ele = data[element.key][ii];
                let dd = {
                    label: ele.name,
                    value: ele.id
                }
                array.push(dd)
            }
            element.value = array
        } else {
            element.value = data[element.key]
        }
        if (element.options) {
            element.options = options[element.key]
        }
    }
    return list
}

/**
 * 获取dialog确定之后的值
 * @param {*} dialogData 弹出框数据
 * @param {*} this_ 当前VUE
 */
export function getReturnDialogData(dialogData, this_) {
    let data = {}
    for (let index = 0; index < dialogData.length; index++) {
        const element = dialogData[index];
        if (element.key == "deCon") {
            this_.$set(data, element.key, getDepartmentObject(element.value, element.options))
        } else if (element.key == "approvers" || element.key == "twoApprovers" || element.key == "payer") {
            let list = []
            for (let ii = 0; ii < element.value.length; ii++) {
                const ele = element.value[ii];
                let dd = {
                    id: ele.value,
                    name: ele.label
                }
                list.push(dd)
            }
            this_.$set(data, element.key, list)
        } else {
            this_.$set(data, element.key, element.value)
        }
    }
    return data
}

/**
 * 将部门id数组转化成部门对象数组
 * @param {*} departmentIds 
 * @param {*} departmentTree 
 */
function getDepartmentObject(departmentIds, departmentTree) {
    let array = []
    for (let index = 0; index < departmentIds.length; index++) {
        const element = departmentIds[index];
        let dd = {
            id: element,
            name: searchDepartNameFromTree(element, departmentTree)
        }
        array.push(dd)
    }
    return array
}

/**
 * 获取部门树中id对应的部门名称
 * @param {*} id 
 * @param {*} departmentTree 
 */
function searchDepartNameFromTree(id, departmentTree) {
    let label = ""
    for (let index = 0; index < departmentTree.length; index++) {
        const element = departmentTree[index];
        if (element.id == id) {
            label = element.label
            return label
        } else if (element.children && element.children.length > 0) {
            label = searchDepartNameFromTree(id, element.children)
        }
    }
    return label

}

/**
 * 修改优先级
 * @param {*} array 流程数组
 * @param {*} priority 要改的优先级
 * @param {*} oldIndex 改之前的优先级所在的索引
 */
export function changeIndex(array, priority, oldIndex) {
    let list = JSON.parse(JSON.stringify(array))
    let ii = -1 // 当前要改的优先级所在的索引
    for (let i = 0; i < array.length; i++) {
        const element = array[i];
        if (element.priority == priority && i != oldIndex) {
            ii = i
        }
    }
    if (ii > -1) {
        // 改的优先级不是自己的优先级
        let item = JSON.parse(JSON.stringify(list[oldIndex])) // 原位置对象
        list.splice(ii, 0, item)
        list.splice(list.length - 1, 1)
        list = initReimbursementDataPriority(list)
    }
    return list
}

/**
 * 删除当前索引的流程item
 * @param {*} array 流程数组
 * @param {*} index 当前要删除的索引
 */
export function deleteIndex(array, index) {
    let list = JSON.parse(JSON.stringify(array))
    list.splice(index, 1)
    list = initReimbursementDataPriority(list)
    return list
}

/**
 * 重构流程数组中的优先级
 * @param {*} array 
 */
export function initReimbursementDataPriority(array) {
    let list = JSON.parse(JSON.stringify(array))
    for (let i = 0; i < list.length; i++) {
        const element = list[i];
        element.priority = i + 1
    }
    return list
}


/**
 * 获取界面筛选数据
 * 
 */
export function myReimbursementSearchData(tipsData) {
    console.log('tipsData', tipsData)
    let data = [
        {
            title: "提交时间",
            type: "times",
            flag: "0",
            hasSearch: false,
            key: "submitTime",
            value: "",
        },
        {
            title: "审批时间",
            type: "times",
            flag: "0",
            hasSearch: false,
            key: "examineTime",
            value: "",
        },
        {
            title: "付款方式",
            type: "select",
            flag: "0",
            hasSearch: false,
            key: "examineTime",
            value: "",
            options: tipsData.payOptions
        },
    ]
    return data
}

/**
 * 我的报销表头数据
 */
export function getMyReimbursementTabMenu() {
    return [
        { key: "numbers", name: "审批编号", sortable: false },
        { key: "submitTime", name: "提交时间", sortable: false },
        { key: "payWay", name: "付款方式", sortable: false },
        { key: "submitUser", name: "提交人", sortable: false },
        { key: "type", name: "报销类型", sortable: false },
        { key: "amountAll", name: "报销金额(元)", sortable: false },
        { key: "examineTime", name: "审批时间", sortable: false },
        { key: "examineUser", name: "审批人", sortable: false },
        { key: "payTime", name: "付款时间", sortable: false },
        { key: "payUser", name: "付款人", sortable: false },
        { key: "payeeName", name: "收款人全称", sortable: false },
        { key: "status", name: "报销状态", sortable: false },
    ]
}

/**
 * 导入报销的dialog数据
 */
export function improtReimbursementDialogData(this_) {
    return [{
        type: "improt_resources",
        value: {
            url: "",
            text: "",
            action: this_.$fileBaseurl + "/uploadImageByMultipartFile",
            uploadParameter: {
                systemName: "finance",
                title: "导入报销",
            },
            headers: {
                token: "95931b371eb64f4a9c862983ca8a5d15",
            },
            modelType: "reimbursementMode"
        },

    }]
}

export function initTableData(data) {
    console.log("data", data);
   data.forEach(item => {
    if(item.type == '付款' || item.type == '成本费') {
        item.borderColor = "#35C07D"
    } else {
        item.borderColor = "#F9A437"
    }
  })
  return data
}