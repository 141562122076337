<template>
  <div class="wechat_link"></div>
</template>

<script>
export default {
  data() {
    return {
      client: {
        userId: "", // 客户的外部联系人ID
        chartId: "", // 客户群的ID
      },
    };
  },
  methods: {
    getAgent() {
      let that = this;
      let p = new Promise(function (resolve, reject) {
        setTimeout(() => {
          //设置延迟执行
          that.$request(
            "post",
            "/getAgentConfig",
            { url: "" },
            (data) => {
              console.log("PC后台连接数据", data);
              wx.agentConfig({
                corpid: data.corpId, // 必填，企业微信的corpid，必须与当前登录的企业一致
                agentid: data.agentId, // 必填，企业微信的应用id （e.g. 1000247）
                timestamp: data.timestamp, // 必填，生成签名的时间戳
                nonceStr: data.nonceStr, // 必填，生成签名的随机串
                signature: data.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
                jsApiList: ["getCurExternalContact", "getCurExternalChat"], //必填，传入需要使用的接口名称
                success: function (res) {
                  // 回调
                  console.log("agentConfig----success", res);
                  wx.invoke("getCurExternalContact", {}, function (res) {
                    console.log("获取客户成功!", res);
                    if (res.err_msg == "getCurExternalContact:ok") {
                      that.client.userId = res.userId; //返回当前客户外部联系人userId
                      if (res.userId != "") {
                        resolve({ msg: res.userId, flag: 0 });
                      }
                    } else {
                      //错误处理
                      // alert("成功人员用户id错误");
                      console.log("单个客户fail", res);
                    }
                  });

                  wx.invoke("getCurExternalChat", {}, function (res) {
                    console.log("获取客户群成功!", res);
                    if (res.err_msg == "getCurExternalChat:ok") {
                      that.client.chartId = res.chatId; //返回当前客户外部联系人userId
                      if (res.chatId) {
                        resolve({ msg: res.chatId, flag: 1 });
                      }
                    } else {
                      //错误处理
                      // alert("成功人员用户id错误");
                      console.log("群fail", res);
                    }
                  });
                },
                fail: function (res) {
                  //直接重新获取所有信息
                  console.log("agentConfig----fail", res);
                  reject(res);
                  // that.getAgent();
                },
              });
            },
            (errorMessage) => {
              that.$message.error(errorMessage);
            }
          );
        }, 5000);
      });
      return p;
    },

    pageJump(data) {
      this.$request(
        "post",
        "/wechat/pageJump",
        {
          userId: data.flag == 0 ? data.msg : "",
          chartId: data.flag == 1 ? data.msg : "",
        },
        (data) => {
          console.log("跳转前地址:", data);
          sessionStorage.setItem("currentFlag", 0);
          sessionStorage.setItem("lastFlag", 1);
          window.location.href = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    GetQueryString(name, str) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = str.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
      var context = "";
      if (r != null) context = r[2];
      reg = null;
      r = null;
      return context == null || context == "" || context == "undefined"
        ? ""
        : context;
    },
  },
  mounted() {
    let that = this;
    let currentFlag =
      sessionStorage.getItem("currentFlag") == ""
        ? 0
        : sessionStorage.getItem("currentFlag");
    let lastFlag =
      sessionStorage.getItem("lastFlag") == ""
        ? 0
        : sessionStorage.getItem("lastFlag");
    if (currentFlag == lastFlag) {
      // 第一次跳转
      this.getAgent().then(function (resolveData) {
        // localStorage.setItem("wechatBaseInfo", JSON.stringify(that.client));
        // console.log("link界面:", that.client);
        // let ss = JSON.stringify(that.client);
        // console.log("localStorage:", localStorage.getItem("wechatBaseInfo"));
        that.pageJump(resolveData);
        // window.location.href =
        //   "https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww83f0f3b64e9ca3f5&redirect_uri=http%3A%2F%2Ftest.soft-ats.com%3A8099%2F%23%2Fwechat-client%3Faction%3Dget&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect";
      });
    }
    console.log("CODE:", location.href);
    console.log("CODE:", location.search);
    console.log("CODE:", this.GetQueryString("code", location.search));
    // this.getAgent().then(function (resolveData) {
    //   // localStorage.setItem("wechatBaseInfo", JSON.stringify(that.client));
    //   // console.log("link界面:", that.client);
    //   // let ss = JSON.stringify(that.client);
    //   // console.log("localStorage:", localStorage.getItem("wechatBaseInfo"));
    //   that.pageJump(resolveData);
    //   // window.location.href =
    //   //   "https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww83f0f3b64e9ca3f5&redirect_uri=http%3A%2F%2Ftest.soft-ats.com%3A8099%2F%23%2Fwechat-client%3Faction%3Dget&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect";
    // });
  },
};
</script>

<style lang="less" scoped>
#wechat_link {
  width: 100%;
  height: 100%;
}
</style>