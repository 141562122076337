<template>
  <!-- 服务项管理 -->
  <div id="serve-item">
    <div class="serve-item-screen">
      <div class="serve-item-screen-search">
        <el-select
          v-model="searchData.select_value"
          placeholder="请选择"
          filterable
          @change="initTableData"
        >
          <el-option
            v-for="item in searchData.options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="serve-item-screen-search">
        <el-input
          placeholder="请输入服务项名称"
          v-model="searchData.input"
          @keyup.enter.native="initTableData"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click.native.stop="initTableData"
          ></el-button>
        </el-input>
      </div>
      <div class="serve-item-screen-active"></div>
      <div
        class="serve-item-screen-but-boder"
        v-showFunction="{ active: 'add', array: allFunctionButtons }"
        @click="handleClick({}, 0)"
      >
        新增服务项
      </div>
    </div>

    <div class="serve-item-table">
      <div class="serve-item-table-card">
        <div class="serve-item-table-card-type">
          <div
            :class="{
              'serve-item-table-card-type-line': true,
              'is-active': isTypeActive == -1,
            }"
            @click="changeType({ typeName: '全部类型', id: '' }, -1)"
          >
            全部类型
            <div class="serve-item-table-card-type-line-oper">
              <i
                class="el-icon-circle-plus-outline"
                v-showFunction="{
                  active: 'addType',
                  array: allFunctionButtons,
                }"
                @click="handleClick({}, 4)"
              ></i>
            </div>
          </div>
          <div
            :class="{
              'serve-item-table-card-type-line': true,
              'is-active': isTypeActive == index,
            }"
            v-for="(item, index) in servesTypeList"
            :key="index"
            @click="changeType(item, index)"
          >
            {{ item.typeName }}
            <div class="serve-item-table-card-type-line-oper">
              <i
                class="el-icon-edit-outline"
                v-showFunction="{
                  active: 'editType',
                  array: allFunctionButtons,
                }"
                @click.stop="handleClick(item, 5)"
              ></i>
              <i
                class="el-icon-close"
                v-showFunction="{
                  active: 'deleteType',
                  array: allFunctionButtons,
                }"
                @click.stop="handleClick(item, 6)"
              ></i>
            </div>
          </div>
        </div>
        <div class="serve-item-table-card-content">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            height="100%"
            v-loading="loading"
          >
            <el-table-column type="index" width="55" fixed> </el-table-column>
            <el-table-column prop="name" label="服务项名称"> </el-table-column>
            <el-table-column prop="status" label="启用状态"> </el-table-column>
            <el-table-column prop="type" label="服务类型"
              ><template slot-scope="scope">
                {{ scope.row.type.typeName }}
              </template>
            </el-table-column>
            <el-table-column prop="icon" label="图标简写">
              <template slot-scope="scope">
                <div
                  class="table-tip"
                  :style="{
                    borderColor: scope.row.icon.color,
                    color: scope.row.icon.color,
                  }"
                >
                  {{ scope.row.icon.text }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="price" label="服务单价(元)">
            </el-table-column>
            <el-table-column prop="unit" label="计价单位"> </el-table-column>
            <el-table-column fixed="right" label="操作">
              <template slot-scope="scope">
                <el-button
                  @click="handleClick(scope.row, 1)"
                  type="text"
                  v-showFunction="{
                    active: 'edit',
                    array: allFunctionButtons,
                  }"
                  size="small"
                  >编辑</el-button
                >
                <!-- <el-button
                  @click="handleClick(scope.row, 2)"
                  v-showFunction="{
                    active: 'editType',
                    array: allFunctionButtons,
                  }"
                  disabled
                  type="text"
                  size="small"
                  >停用</el-button
                > -->
                <el-button
                  @click="handleClick(scope.row, 3)"
                  v-showFunction="{
                    active: 'delete',
                    array: allFunctionButtons,
                  }"
                  type="text"
                  size="small"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import my_dialog from "@/components/dialog.vue";
import * as serveItemData from "@/assets/js/serveItemData.js";
export default {
  components: {
    my_dialog,
  },
  data() {
    return {
      baseInfo: {},
      loading: true,
      searchData: {
        input: "",
        select_value: "1",
        options: [
          { label: "启用", value: "1" },
          { label: "停用", value: "0" },
          { label: "全部", value: "2" },
        ],
      },
      isTypeActive: -1,
      isTypeId: "",
      servesTypeList: [],
      tableData: [],
      dialogData: {
        flag: false,
        width: "30%",
        title: "添加跟进记录",
        id: "",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },
      buttonAuth: {
        add_type: 3,
        edit_type: 3,
        dele_type: 3,
        add: 3,
        edit: 3,
        oper: 3,
        dele: 3,
      },

      allFunctionButtons: this.$featureData.getAllFunctionButtons("serveItem"),
      whetherAgencyHead: false, // 是否是机构负责人
    };
  },

  methods: {
    getModelFeatureAuthority() {
      this.$authority.getModelFeatureAuthority(
        this,
        "serveItem",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.isCharge();
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getAllServeType();
      });
    },
    /**
     * 获取服务项类别
     */
    getAllServeType() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/serveType/getAllServeType",
        requestData,
        (data) => {
          this.servesTypeList = data;
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    /**
     * 初始化表格
     */
    initTableData() {
      this.loading = true;
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        status: this.searchData.select_value, // 0为停用;1为启用;2为全部
        name: this.searchData.input, // 模糊查询
        type: this.isTypeId, // 服务项类别ID 获取全部数据时,该项为空
      };
      this.$request(
        "post",
        "/servicesItem/getServicesItem",
        requestData,
        (data) => {
          this.tableData = data;
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    /**
     * 根据服务项类别筛选
     */
    changeType(data, index) {
      this.isTypeActive = index;
      this.isTypeId = data.id;
      this.initTableData();
    },
    /**
     * 按钮操作
     */
    handleClick(data, flag) {
      switch (flag) {
        case 0:
          // 添加服务项
          this.dialogData.title = "添加服务项";
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "submit_add",
            },
            {
              text: "取消",
              active: "calcel",
            },
          ];
          this.dialogData.data = serveItemData.getServeItemDialogData(
            "",
            this.servesTypeList
          );
          this.dialogData.flag = true;
          break;
        case 1:
          // 编辑服务项
          this.dialogData.title = "编辑服务项";
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "submit_edit",
            },
            {
              text: "取消",
              active: "calcel",
            },
          ];
          this.dialogData.data = serveItemData.getServeItemDialogData(
            data,
            this.servesTypeList
          );
          this.dialogData.id = data.id;
          this.dialogData.flag = true;
          break;
        case 2:
          // 停用服务项
          console.log("停用", data);
          let requestData = {
            id: data.id,
            name: data.name, // 服务项名称
            type: data.type, // 服务项类别
            status: "0", // 服务项状态
            icon: data.icon, // 图标简写
            price: data.price, // 服务单价
            unit: data.unit, // 计价单位/付费周期
            workType: data.workType, // 服务派工类型
          };
          this.$request(
            "post",
            "/servicesItem/updateServicesItem",
            requestData,
            (data) => {
              this.$message({
                type: "success",
                message: this.$errorMessage.getErrorMessageInfo("update"),
                duration: 1000,
              });
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
              this.loading = false;
            }
          );
          break;
        case 3: //performanceCalculationRules
          // 删除服务项
          this.$confirm("此操作将永久删除该服务项, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$request(
                "post",
                "/servicesItem/deleteServicesItem",
                { id: data.id },
                (data) => {
                  this.$message({
                    type: "success",
                    message: "删除成功",
                    duration: 1000,
                  });
                  this.getAllServeType();
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                  this.loading = false;
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
          break;
        case 4:
          // 添加服务项类别
          this.dialogData.title = "添加服务类型";
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "submit_add_type",
            },
            {
              text: "取消",
              active: "calcel",
            },
          ];
          this.dialogData.data = [
            {
              type: "performance-calculation-rules",
              title: "",
              value: {
                serveName: "",
                rulesList: [
                  {
                    scopeOf: ["0", "1000"],
                    value: "0.5",
                  },
                  {
                    scopeOf: ["1001", "∞"],
                    value: "0.5",
                  },
                ],
              },
            },
          ];
          this.dialogData.flag = true;
          break;
        case 5:
          // 编辑服务项类别
          this.dialogData.title = "添加服务类型";
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "submit_edit_type",
            },
            {
              text: "取消",
              active: "calcel",
            },
          ];
          this.dialogData.data = [
            {
              type: "performance-calculation-rules",
              title: "",
              value: { serveName: data.typeName, rulesList: data.rulesList },
            },
          ];
          this.dialogData.id = data.id;
          this.dialogData.flag = true;
          break;
        case 6:
          // 删除服务项类别
          this.$confirm("此操作将永久删除该类型, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$request(
                "post",
                "/serveType/deleteServeType",
                { id: data.id },
                (data) => {
                  this.$message({
                    type: "success",
                    message: "删除成功",
                    duration: 1000,
                  });
                  this.getAllServeType();
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                  this.loading = false;
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
          break;

        default:
          break;
      }
    },
    /**
     * 弹出框中的按钮操作事件
     */
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "submit_add_type":
          // 确定添加服务类别
          if (this.dialogData.data[0].value.serveName == "") {
            this.$message({
              message: "服务项类别名不能为空!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          if (this.dialogData.data[0].value.rulesList.length == 0) {
            this.$message({
              message: "业绩计算规则不能为空!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          requestData = {
            typeName: this.dialogData.data[0].value.serveName, // 服务项类别名
            rulesList: this.dialogData.data[0].value.rulesList, // 业绩计算规则
            companyId: this.baseInfo.company.companyId,
          };
          this.$request(
            "post",
            "/serveType/addServeType",
            requestData,
            (data) => {
              this.$message({
                type: "success",
                message: this.$errorMessage.getErrorMessageInfo("add"),
                duration: 1000,
              });
              this.getAllServeType();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
              this.loading = false;
            }
          );
          break;
        case "submit_edit_type":
          // 确定编辑服务类别
          if (this.dialogData.data[0].value.serveName == "") {
            this.$message({
              message: "服务项类别名不能为空!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          if (this.dialogData.data[0].value.rulesList.length == 0) {
            this.$message({
              message: "业绩计算规则不能为空!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          requestData = {
            typeName: this.dialogData.data[0].value.serveName, // 服务项类别名
            rulesList: this.dialogData.data[0].value.rulesList, // 业绩计算规则
            id: this.dialogData.id,
          };
          this.$request(
            "post",
            "/serveType/updateServeType",
            requestData,
            (data) => {
              this.$message({
                type: "success",
                message: this.$errorMessage.getErrorMessageInfo("update"),
                duration: 1000,
              });
              this.getAllServeType();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
              this.loading = false;
            }
          );
          break;
        case "submit_add":
          // 确定添加服务项
          console.log("确定添加服务项", this.dialogData.data);
          for (let index = 0; index < this.dialogData.data.length; index++) {
            let element = this.dialogData.data[index];
            if (element.value == "") {
              this.$message({
                message: element.title + "不能为空!",
                type: "error",
                duration: 1000,
              });
              return;
            }
            if (
              element.title.indexOf("单价") > -1 &&
              !this.$formatMathDecimal(element.value)
            ) {
              console.log(element.value);
              this.$message({
                message: element.title + "只能填写数字!",
                type: "error",
                duration: 1000,
              });
              return;
            }
          }
          requestData = {
            companyId: this.baseInfo.company.companyId,
            name: this.dialogData.data[0].value, // 服务项名称
            type: this.dialogData.data[1].value, // 服务项类别
            status: "1", // 服务项状态
            icon: {
              text: this.dialogData.data[2].value,
              color:
                this.dialogData.data[4].value == "次"
                  ? "#F9A437"
                  : this.dialogData.data[4].value == "个"
                  ? "#F9A437"
                  : "#35C07D",
            }, // 图标简写
            price: this.dialogData.data[3].value, // 服务单价
            unit: this.dialogData.data[4].value, // 计价单位/付费周期
            // workType: this.dialogData.data[5].value, // 服务派工类型
            workType: [], // 服务派工类型
            // 以下是拼接数据
            dunning: {
              // 催费时间
              flag: "0", // 0为提前;1为延后
              day: "0", // 提前/延后天数
              firstDunning: "", // 首次催费时间,和天数相互绑定,即修改天数,该时间同步变化,反之亦然,默认为当前签约时间当月的1号
            },
            discounts: {
              //  优惠
              falg: "", // 0为无优惠;1为打折;2为减免
              discount: "", // 折扣率  flag为1时使用,取值范围0~100
              mitigate: "", // 减免金额  flag为2时使用
              totalPrice: "", // 服务项总价 flag为0时使用  默认有单价+周期计算 如:单价10元/月,周期为一年,则总价默认为120
            },
            times: [
              {
                // 服务时间  用于具体服务阶段优惠折扣计算
                times: "", // 服务时间
                price: "", // 原价
                discount: "", // 本次服务折扣或减免  若discounts中的flag为1,该值与discounts中的discount一致,且不可变,若discounts中的flag为2,该值与discounts中的mitigate一致 ,可变,并连锁变化
                truePrice: "", // 原价与折扣计算后的金额
              },
            ],
          };
          this.$request(
            "post",
            "/servicesItem/addServicesItem",
            requestData,
            (data) => {
              this.$message({
                type: "success",
                message: this.$errorMessage.getErrorMessageInfo("add"),
                duration: 1000,
              });
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
              this.loading = false;
            }
          );
          break;
        case "submit_edit":
          // 确定添加服务项
          for (let index = 0; index < this.dialogData.data.length; index++) {
            let element = this.dialogData.data[index];
            if (element.value == "") {
              this.$message({
                message: element.title + "不能为空!",
                type: "error",
                duration: 1000,
              });
              return;
            }
          }

          // if (
          //   this.dialogData.data[3].title.indexOf("单价") > 0 &&
          //   !this.$phoneCheck.mobilePhoneNumberCheck(
          //     this.dialogData.data[3].value
          //   )
          // ) {
          //   this.$message({
          //     message: this.dialogData.data[3].title + "只能填写数字!",
          //     type: "error",
          //     duration: 1000,
          //   });
          //   return;
          // }
          requestData = {
            id: this.dialogData.id,
            name: this.dialogData.data[0].value, // 服务项名称
            type: this.dialogData.data[1].value, // 服务项类别
            status: "1", // 服务项状态
            icon: {
              text: this.dialogData.data[2].value,
              color:
                this.dialogData.data[4].value == "次" ? "#F9A437" : "#35C07D",
            }, // 图标简写
            price: this.dialogData.data[3].value, // 服务单价
            unit: this.dialogData.data[4].value, // 计价单位/付费周期
            workType: [], // 服务派工类型
            // workType: this.dialogData.data[5].value, // 服务派工类型
          };
          this.$request(
            "post",
            "/servicesItem/updateServicesItem",
            requestData,
            (data) => {
              this.$message({
                type: "success",
                message: this.$errorMessage.getErrorMessageInfo("update"),
                duration: 1000,
              });
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
              this.loading = false;
            }
          );
          break;
        case "cancel":
          break;

        default:
          break;
      }
      this.colseDialog();
    },
    /**
     * 弹出框关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
  },
  mounted() {
    this.loading = true;
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#serve-item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .serve-item-screen {
    width: 100%;
    height: 45px;
    border-bottom: 1px solid @boderColor;
    display: flex;
    align-items: center;
    .serve-item-screen-search {
      width: 210px;
      min-width: 210px;
      height: 30px;
      margin: 0 10px;
    }
    .serve-item-screen-active {
      flex: 1;
      height: 100%;
    }
    .serve-item-screen-but-select {
      width: 140px;
      min-width: 160px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: @activeColor;
      font-size: @contentFontSize;
      color: #fff;
      margin: 0 5px;
      border-radius: 4px;
      transition: 0.5s;
      cursor: pointer;
      .serve-item-screen-but-select_but {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.5s;
      }
      .serve-item-screen-but-select_but:hover {
        background-color: @activeColor2;
      }
      .serve-item-screen-but-select_select {
        width: 30px;
        height: 100%;
        border-left: 1px solid @activeColor3;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .serve-item-screen-but-boder {
      width: 150px;
      min-width: 50px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid @activeColor;
      font-size: @contentFontSize;
      background-color: @activeBackground;
      color: @activeColor;
      margin: 0 5px;
      margin-right: 10px;
      border-radius: 4px;
      transition: 0.5s;
    }
    .serve-item-screen-but-boder:hover {
      cursor: pointer;
      background-color: @activeBackground2;
    }
  }
  .serve-item-table {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    .serve-item-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      .serve-item-table-card-type {
        width: 10%;
        height: 100%;
        display: flex;
        flex-direction: column;
        border-right: 1px solid @boderColor;
        .serve-item-table-card-type-line {
          width: 100%;
          height: 35px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: @contentFontSize5;
          padding-left: 2px;
          box-sizing: border-box;
          transition: 0.3s;
          &:hover {
            cursor: pointer;
            background-color: @activeBackground3;
          }
          .serve-item-table-card-type-line-oper {
            width: auto;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            i {
              width: 15px;
              height: 15px;
              margin: 5px;
              font-size: 15px;
            }
          }
        }
        .is-active {
          background-color: @activeBackground3;
        }
      }
      .serve-item-table-card-content {
        flex: 1;
        width: 0;
        height: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
        font-size: @contentFontSize;
        color: @closeColor;
        .table-tip {
          height: 30px;
          width: auto;
          padding: 2px 5px;
          margin: 0 3px;
          border: 1px solid #333;
          border-radius: 5px;
          box-sizing: border-box;
        }
      }
      .serve-item-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }
}
</style>
