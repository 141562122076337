<template>
  <div id="service_item_table">
    <div class="title">
      <div>
        <i class="el-icon-s-operation"></i>
        {{ value.text }}({{ value.data.length }})
      </div>
      <div class="but" @click="addService">
        <i class="el-icon-circle-plus-outline"></i> 添加服务项
      </div>
    </div>
    <div class="tb_head">
      <div class="menu1">服务项目名称</div>
      <div class="menu1">原价</div>
      <div class="menu1">减免</div>
      <div class="menu1">催费时间</div>
      <div class="menu1">操作</div>
    </div>
    <div class="tb_body" v-for="(item, index) in value.data" :key="index">
      <div class="menu1">
        <el-select
          v-model="item.data"
          filterable
          placeholder="请选择"
          value-key="id"
          @change="selectService(item.data, index)"
        >
          <el-option
            v-for="it in value.options"
            :key="it.id"
            :label="it.name"
            :value="it"
          >
          </el-option>
        </el-select>
      </div>
      <div class="menu1">
        <div class="menu2">
          <el-input
            v-model="item.price"
            placeholder="请输入内容"
            @change="changeValue(item, index, 0)"
          ></el-input>
        </div>
        元/{{ item.unit }}
      </div>
      <div class="menu1">
        <div class="menu2">
          <el-input
            v-model="item.discounts"
            placeholder="请输入内容"
            @change="changeValue(item, index, 1)"
          ></el-input>
        </div>
        元/{{ item.unit }}
      </div>
      <div class="menu1">
        <div class="menu2">
          <el-radio-group
            v-model="item.dunning.flag"
            @change="changeValue(item, index, 2)"
          >
            <el-radio :label="0">提前</el-radio>
            <el-radio :label="1">延后</el-radio>
          </el-radio-group>
        </div>
        <div class="menu2">
          <el-input
            v-model="item.dunning.day"
            @change="changeValue(item, index, 3)"
            placeholder="请输入内容"
          ></el-input>
        </div>
        天
      </div>
      <div class="menu1">
        <el-button type="text" @click="splititem(item)">移除</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["serviceItemData"],
  data() {
    return {
      value: this.serviceItemData,
    };
  },
  watch: {
    serviceItemData() {
      //父组件值变化->>子组件也变化
      this.value = this.serviceItemData;
    },
    value(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {
    /**
     * 选择服务项目名称事件
     */
    selectService(data, index) {
      this.value.data[index].price = data.price; // 服务单价
      this.value.data[index].unit = data.unit; // 计价单位
      this.value.data[index].discounts =
        data.discounts.mitigate == "" ? "0" : data.discounts.mitigate; // 减免
      this.value.data[index].dunning.flag =
        data.dunning.flag == "" ? 1 : data.dunning.flag; // 催费方式
      this.value.data[index].dunning.day =
        data.dunning.day == "" ? "0" : data.dunning.day; // 催费时间
    },

    /**
     * 移除当前项
     */
    splititem(data) {
      this.value.data.splice(this.value.data.indexOf(data), 1);
    },
    /**
     * 添加服务项
     */
    addService() {
      let data = {
        data: {},
        price: "0",
        unit: "次",
        discounts: "0",
        dunning: {
          flag: 0,
          day: "0",
        },
      };
      this.value.data.push(data);
    },
    /**
     *
     */
    changeValue(data, index, flag) {
      switch (flag) {
        case 0:
          // 修改单价
          data.data.price = data.price;
          break;
        case 1:
          // 修改减免
          data.data.discounts.mitigate = data.discounts;
          data.data.discounts.flag = "2";
          break;
        case 2:
          // 修改催费时间
          // data.data.dunning.day = data.dunning.day
          data.data.dunning.flag = data.dunning.flag;
          break;
        case 3:
          // 修改催费时间
          data.data.dunning.day = data.dunning.day;
          // data.data.dunning.flag = data.dunning.flag
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
#service_item_table {
  width: 100%;
  height: auto;
  max-height: 30vh;
  display: flex;
  flex-direction: column;
  border: 1px solid @boderColor;
  border-radius: 10px;
  padding: 0 5px;
  box-sizing: border-box;
  .title {
    width: 100%;
    min-height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
    font-size: @contentFontSize;
    .but {
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        color: @activeColor;
      }
    }
  }
  .tb_head,
  .tb_body {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid @boderColor;
    .menu1 {
      flex: 1;
      height: 100%;
      width: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid @boderColor;
      padding: 0 5px;
      box-sizing: border-box;
      .el-input__icon {
        width: 80% !important;
      }
      &:nth-child(1) {
        border: none;
      }
      .menu2 {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .el-radio-group {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .el-radio {
            margin: 0;
          }
        }
      }
    }
  }
  .tb_head {
    min-height: 40px;
    background-color: rgba(190, 212, 221, 0.438);
    border-bottom: none;
  }
  .tb_body:nth-last-child(1) {
    border-bottom: none;
  }
}
</style>