<template>
  <!-- 运单详情 -->
  <div id="get_delivery">
    <div class="expressage-content">
      <div class="expressage-row">
        <div class="expressage-col-12">
          <div class="expressage-each-title">
            <div class="mg-border-icon"></div>
            寄件人信息
          </div>
          <div class="expressage-each-person">
            <div class="expressage-each-person-name">
              {{ value.senderName }}
            </div>
            <div class="expressage-each-person-info">
              <div class="expressage-each-person-address">
                {{ value.senderArea + value.senderAddress }}
              </div>
              <div class="expressage-each-person-phone">
                {{ value.senderTel }}
              </div>
            </div>
          </div>
        </div>
        <div class="expressage-col-12">
          <div class="expressage-each-title">
            <div class="mg-border-icon"></div>
            收件人信息
          </div>
          <div class="expressage-each-person">
            <div class="expressage-each-person-name">
              {{ value.receiverName }}
            </div>
            <div class="expressage-each-person-info">
              <div class="expressage-each-person-address">
                {{ value.receiverArea + value.receiverAddress }}
              </div>
              <div class="expressage-each-person-phone">
                {{ value.receiverTel }}
              </div>
            </div>
          </div>
          <div class="expressage-each-person-client">
            <div class="expressage-each-person-client-title">关联客户:</div>
            <div class="expressage-each-person-client-value">
              <div v-if="isEdit">
                <el-select
                  v-model="value.receiverClientId"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in value.clientList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
                <span class="mg-link-text" @click="editClient">确定</span>
              </div>
              <div v-else>
                <span>{{ value.receiverClientName || "--" }}</span>
                <span class="mg-link-text" @click="isEdit = true">修改</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="expressage-row2">
        <div class="expressage-each-title">
          <div class="mg-border-icon"></div>
          运单信息
        </div>
        <div class="expressage-each-contain">
          <div class="expressage-each-contain-line">
            <div class="expressage-each-contain-right">运单号</div>
            <div class="expressage-each-contain-left">
              {{ value.deliveryId }}
            </div>
            <div class="expressage-each-contain-right">运单状态</div>
            <div class="expressage-each-contain-left">
              {{ value.deliveryState }}
            </div>
          </div>
          <div class="expressage-each-contain-line">
            <div class="expressage-each-contain-right">下单时间</div>
            <div class="expressage-each-contain-left">
              {{ value.orderDate }}
            </div>
            <div class="expressage-each-contain-right">预计送达时间</div>
            <div class="expressage-each-contain-left">
              {{ value.deliverTime }}
            </div>
          </div>
          <div class="expressage-each-contain-line">
            <div class="expressage-each-contain-right">扣款时间</div>
            <div class="expressage-each-contain-left">
              {{ value.payDate }}
            </div>
            <div class="expressage-each-contain-right">签收时间</div>
            <div class="expressage-each-contain-left">
              {{ value.signedTime }}
            </div>
          </div>
          <div class="expressage-each-contain-line">
            <div class="expressage-each-contain-right">快递服务商</div>
            <div class="expressage-each-contain-left">
              {{ value.supplierName }}
            </div>
            <div class="expressage-each-contain-right">产品类型</div>
            <div class="expressage-each-contain-left">
              {{ value.expressType }}
            </div>
          </div>
          <div class="expressage-each-contain-line">
            <div class="expressage-each-contain-right">付款方式</div>
            <div class="expressage-each-contain-left">
              {{ value.payMethod }}
            </div>
            <div class="expressage-each-contain-right">物品</div>
            <div class="expressage-each-contain-left">
              {{ value.goods }}
            </div>
          </div>
          <div class="expressage-each-contain-line">
            <div class="expressage-each-contain-right">下单重量</div>
            <div class="expressage-each-contain-left">
              {{ value.preWeight ? `${value.preWeight} kg` : "" }}
            </div>
            <div class="expressage-each-contain-right">预估运费</div>
            <div class="expressage-each-contain-left">
              {{ value.preFreight ? `￥ ${value.preFreight}` : "" }}
            </div>
          </div>
          <div class="expressage-each-contain-line">
            <div class="expressage-each-contain-right">计费重量</div>
            <div class="expressage-each-contain-left">
              {{ value.weight ? `${value.weight} kg` : "" }}
            </div>
            <div class="expressage-each-contain-right">标准运费</div>
            <div class="expressage-each-contain-left">
              {{ value.freight ? `￥ ${value.freight}` : "" }}
            </div>
          </div>
          <div class="expressage-each-contain-line">
            <div class="expressage-each-contain-right">优惠</div>
            <div class="expressage-each-contain-left">
              {{ value.discounts ? `￥ ${value.discounts}` : "" }}
            </div>
            <div class="expressage-each-contain-right">实扣运费</div>
            <div class="expressage-each-contain-left">
              {{ value.payAmount ? `￥ ${value.payAmount}` : "" }}
            </div>
          </div>
          <div class="expressage-each-contain-line">
            <div class="expressage-each-contain-right">留言</div>
            <div class="expressage-each-contain-left">
              {{ value.remark }}
            </div>
            <div class="expressage-each-contain-right">添加备注</div>
            <div class="expressage-each-contain-left">
              <el-input
                autosize
                type="textarea"
                v-model="value.memo"
                @change="editClient"
                @keyup.enter.native="editClient"
              ></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="expressage-row3">
        <div class="expressage-each-title">
          <div class="mg-border-icon"></div>
          物流信息
        </div>
        <div class="expressage-each-contain">
          <el-timeline>
            <el-timeline-item
              v-for="(activity, index) in value.routes"
              :key="index"
              :timestamp="activity.acceptTime"
            >
              {{ activity.remark }}
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    DeliveryData: {
      type: Object,
      default: function() {
        return;
      },
    },
  },
  data() {
    return {
      isEdit: false,
      value: {},
    };
  },
  watch: {},
  methods: {
    editClient() {
      let requestData = {
        id: this.value.id, // 订单 id
        receiverClientId: this.value.receiverClientId, // 关联客户 id
        memo: this.value.memo, // 备注
      };
      this.$request(
        "post",
        "/expressOrder/edit",
        requestData,
        (data) => {
          this.$message.success("修改成功!");
          this.DeliveryData.memo = data.memo;
          this.DeliveryData.receiverClientId = data.receiverClientId;
          this.DeliveryData.receiverClientName = data.receiverClientName;
          this.isEdit = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
  },
  created() {
    this.value = this.DeliveryData;
  },
};
</script>

<style lang="less" scoped>
#get_delivery {
  width: 100%;
  max-height: 900px;

  // overflow-y: hidden;
  .expressage-content {
    padding: 0 20px;
    .expressage-row {
      display: flex;
      min-height: 21px;
      margin-top: 10px;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #d1d3d8;
      .expressage-col-12 {
        width: 50%;
        display: flex;
        flex-direction: column;
        .expressage-each-title {
          display: flex;
          align-items: center;
          margin-bottom: 25px;
          .mg-border-icon {
            margin-top: 1px;
            height: 16px;
            width: 6px;
            background: @baseColor;
            border-radius: 3px;
            margin-right: 10px;
          }
        }
        .expressage-each-person {
          display: flex;
          .expressage-each-person-name {
            width: 16%;
            height: 40px;
            display: flex;
            font-size: 14px;
            flex-direction: row-reverse;
            padding-left: 30px;
            text-align: right;
          }
          .expressage-each-person-info {
            display: flex;
            flex-direction: column;
            padding: 0 20px;
            line-height: 18px;
            color: #666;
            font-size: 13px;
            .expressage-each-person-address {
              display: flex;
              text-align: left;
            }
            .expressage-each-person-phone {
              text-align: left;
            }
          }
        }
        .expressage-each-person-client {
          display: flex;
          font-size: 14px;

          .expressage-each-person-client-title {
            padding-left: 38px;
          }
          .expressage-each-person-client-value {
            padding: 0 20px;
            height: 30px;
            /deep/ .el-select {
              width: 160px;
            }
            .mg-link-text {
              color: @baseColor;
              margin-left: 12px;
              cursor: pointer;
            }
          }
        }
      }
    }
    .expressage-row2 {
      display: flex;
      flex-direction: column;
      min-height: 21px;
      margin-top: 10px;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #d1d3d8;
      .expressage-each-title {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        .mg-border-icon {
          margin-top: 1px;
          height: 16px;
          width: 6px;
          background: @baseColor;
          border-radius: 3px;
          margin-right: 10px;
        }
      }
      .expressage-each-contain {
        .expressage-each-contain-line {
          display: flex;
          align-items: baseline;
          margin-top: 10px;
          min-height: 21px;
          font-size: 14px;
          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            align-items: flex-start;
          }
          .expressage-each-contain-right {
            text-align: right;
            width: 12.5%;
          }
          .expressage-each-contain-left {
            width: 37.5%;
            color: #666;
            text-align: left;
            padding-left: 15px;
          }
        }
      }
    }
    .expressage-row3 {
      display: flex;
      flex-direction: column;
      min-height: 21px;
      margin-top: 10px;
      margin-bottom: 20px;
      .expressage-each-title {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        .mg-border-icon {
          margin-top: 1px;
          height: 16px;
          width: 6px;
          background: @baseColor;
          border-radius: 3px;
          margin-right: 10px;
        }
      }
      .expressage-each-contain {
        /deep/ .el-timeline-item__tail {
          left: 180px;
        }
        /deep/ .el-timeline-item__node--normal {
          left: 175px;
        }
        /deep/ .el-timeline-item__content {
          text-align: left;
          margin-left: 170px;
        }
        /deep/ .el-timeline-item__timestamp.is-bottom {
          position: absolute;
          top: -5px;
          left: 43px;
        }
      }
    }
  }
}
</style>
