<template>
  <div id="contract-setting">
    <!-- 商机设置 -->
    <div class="screen">
      <div class="screen-line">
        <div class="contract-setting-screen-tabs">
          <template v-for="(item, index) in tabList">
            <div
              :class="{
                'contract-setting-screen-tab': true,
                'is-active': tabIndex == item.text,
              }"
              v-showFunction="{
                active: item.trueActive,
                array: allFunctionButtons,
              }"
              :key="index"
              @click="changeTab(item, index)"
            >
              {{ item.text }}
            </div>
          </template>
          <div class="spl"></div>
          <div
            class="but"
            v-if="tabIndex == '签约主体设置'"
            v-showFunction="{
              active: 'addContractSubject',
              array: allFunctionButtons,
            }"
            @click="handlClick({}, 'add')"
          >
            <i class="el-icon-plus"></i>添加签约主体
          </div>
        </div>
      </div>
    </div>
    <div class="table-content">
      <div class="contract-table-card" v-loading="loading">
        <div
          class="contract-table-card-content"
          v-if="tabIndex == '待办流程设置'"
        >
          <div class="contract-table-card-content-tags-setting">
            <div class="contract-table-card-content-tags-setting-left">
              <div class="contract-table-card-content-tags-setting-left-line">
                <el-input
                  placeholder="业务类型名称"
                  v-model="searchData.input"
                  @keyup.enter.native="getAllProfessionList"
                  @clear="getAllProfessionList"
                  clearable
                >
                </el-input>
              </div>
              <div class="contract-table-card-content-tags-setting-left-line">
                业务类型
                <i
                  class="el-icon-circle-plus-outline"
                  @click="addProfession"
                  v-showFunction="{
                    active: 'addProcess',
                    array: allFunctionButtons,
                  }"
                ></i>
              </div>
              <div class="content customScrollBar">
                <el-tree
                  :data="professionLsit"
                  node-key="id"
                  :props="defaultProps"
                  :default-expand-all="false"
                  accordion
                >
                  <template #default="{ data, node }">
                    <!-- <el-tooltip
                      class="item"
                      effect="dark"
                      :content="data.name"
                      placement="top-start"
                      :disabled="data.name.length <= 6"
                    >
                    </el-tooltip>  -->
                    <span @click="changeProfession(data, node)">{{
                      data.name
                    }}</span>
                  </template></el-tree
                >
                <!-- <div
                  :class="{
                    'contract-table-card-content-tags-setting-left-line': true,
                    'is-active': index == tagsIndex,
                  }"
                  v-for="(item, index) in professionLsit"
                  :key="index"
                  @click="changeProfession(item, index)"
                >
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item.name"
                    placement="top"
                    :disabled="item.name.length <= 10"
                  >
                    <span>{{ item.name }}</span>
                  </el-tooltip>
                </div> -->
              </div>
            </div>
            <div class="contract-table-card-content-tags-setting-right">
              <profession
                :professionData="professionData"
                :allFunctionButtons="allFunctionButtons"
                @show="professionShow"
                @deleteProfession="deleteProfession"
                @cancelProfession="cancelProfession"
                @submitProfession="submitProfession"
                @addStep="addStep"
                @editStep="editStep"
              ></profession>
            </div>
          </div>
        </div>
        <div
          class="contract-table-card-content"
          v-if="tabIndex == '签约主体设置'"
        >
          <el-table
            ref="singleTable"
            :data="signInfoData"
            highlight-current-row
            style="width: 100%"
            height="98%"
            v-loading="loading"
          >
            <el-table-column
              prop="name"
              label="名称"
              width="180"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="companyName"
              label="公司名称"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="bankName"
              label="开户行"
              width="240"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="bankAccount"
              label="银行账户"
              width="240"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="legal" label="法人" width="120">
            </el-table-column>
            <el-table-column prop="tel" label="电话" width="180">
            </el-table-column>
            <el-table-column
              prop="address"
              label="地址"
              width="180"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column label="操作" width="180">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  plain
                  size="mini"
                  @click.native.stop="handlClick(scope.row, 'edit')"
                  v-showFunction="{
                    active: 'editContractSubject',
                    array: allFunctionButtons,
                  }"
                  >编辑</el-button
                >
                <el-button
                  type="danger"
                  plain
                  size="mini"
                  @click.native.stop="handlClick(scope.row, 'delete')"
                  v-showFunction="{
                    active: 'deleteContractSubject',
                    array: allFunctionButtons,
                  }"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @selectHandel="selectHandel"
        @handleAvatarSuccess="handleAvatarSuccess"
      ></my_dialog>
      <!-- @selectHandel="selectHandel" -->
    </div>
  </div>
</template>

<script>
import profession from "@/components/profession.vue";
import my_dialog from "@/components/dialog.vue";
export default {
  components: {
    profession,
    my_dialog,
  },
  data() {
    return {
      baseInfo: {},
      loading: true,
      tabIndex: "待办流程设置",
      searchData: {
        input: "",
      },
      whetherAgencyHead: false,
      dialogData: {
        flag: false,
        width: "60%",
        title: "添加步骤",
        buttonArray: [
          {
            text: "提交",
            active: "add_step",
          },
        ],
        data: [],
      },
      tagsIndex: 0,
      tabList: [
        {
          text: "待办流程设置",
          active: "业务设置",
          flag: 0,
          trueActive: "showWaitProcess",
        },
        {
          text: "签约主体设置",
          active: "签约主体/开票抬头设置",
          flag: 1,
          trueActive: "showSignInfo",
        },
      ],
      professionLsit: [
        {
          // 业务列表
          id: "",
          name: "公司注册",
        },
      ],
      departmentList: [], // 所有分公司 包括all
      professionData: {
        id: "",
        name: "", // 业务类型名称
        departmentId: "", // 作用分公司ID
        departmentList: [], // 所有分公司
        serviceType: {
          typeName: "",
          id: "",
        },
        serviceItemId: [], // 服务产品
        des: "", // 业务类型备注
        // 项目要求
        projectRequire: "", // 所需资料
        information: "",
        isCheck: {
          // 审核开关
          flag: true,
          des:
            "开启后仅指定的人员拥有该权限,其他人无法审核或反审核项目,设置上限为5人",
        },
        // 项目成果
        achievement: "",
        royalty: "", // 提成设置
        step: [],
        butArray: [],
        is_edit: false, // 是否开启编辑
      },
      butArray: [
        {
          title: "编辑",
          text: "流程编辑",
          flag: 1,
          active: "edit",
          type: "primary",
          icon: "el-icon-edit-outline",
        },
        {
          title: "复制",
          text: "流程编辑1",
          flag: 3,
          active: "copy",
          icon: "el-icon-document-copy",
        },
        {
          title: "停用",
          text: "流程编辑1",
          flag: 3,
          active: "off",
          icon: "el-icon-document-delete",
        },
        {
          title: "删除",
          text: "流程编辑1",
          flag: 1,
          active: "delete",
          type: "danger",
          icon: "el-icon-delete",
        },
      ],
      optionValueList1: [{ name: "", value: [{ name: "", value: "" }] }], // 业务设置
      optionValueList2: [{ name: "", value: [{ name: "", value: "" }] }], // 流程设置
      optionValueList3: [{ name: "", value: [{ name: "", value: "" }] }], // 权限设置
      tempTagList: [],
      tempProfessionId: "",
      tempStep: { data: {}, index: 0 }, // 临时步骤

      roleList: [], // 业务角色
      usersList: [], // 员工列表

      signInfoData: [],
      tempSignnfo: {},

      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "contractSetting"
      ),
      defaultProps: {
        children: "child",
        label: "name",
      },
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "contractSetting",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          // this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getBranchList();
      });
    },
    /**
     * 4.2.2 获取分公司列表
     */
    getBranchList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/department/getBranchList",
        requestData,
        (data) => {
          this.professionData.departmentList = data;
          this.departmentList = JSON.parse(JSON.stringify(data));
          this.departmentList.unshift({
            departmentId: "all",
            departmentName: "全部",
          });
          this.getBusinessAuthorityList();
        }
      );
    },
    /**
     * 获取所有业务角色
     */
    getBusinessAuthorityList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/role/getBusinessAuthorityList",
        requestData,
        (data) => {
          this.roleList = [];
          data.forEach((element) => {
            let dd = {
              label: element.authorityName,
              value: element.authorityId,
            };
            this.roleList.push(dd);
          });
          this.getAllProfessionList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取业务列表
     */
    getAllProfessionList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        departmentId: "all",
        name: this.searchData.input,
      };
      this.$request(
        "post",
        "/profession/getProfessionList",
        requestData,
        (data) => {
          this.professionLsit = data;

          if (data.length > 0) {
            this.tempProfessionId = data[0].child[0].id;
            this.getProfessionDataById(data[0].child[0].id);
          } else {
            this.professionData = "";
          }
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取具体业务信息
     */
    getProfessionDataById(id) {
      let requestData = {
        id: id,
      };
      this.$request(
        "post",
        "/profession/getProfessionById",
        requestData,
        (data) => {
          this.professionData = data;
          this.$set(this.professionData, "butArray", this.butArray);
          this.$set(this.professionData, "is_edit", false);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 切换业务
     */
    changeProfession(data, node) {
      if (node.level == 2) {
        this.tempProfessionId = data.id;
        this.getProfessionDataById(data.id);
      }
    },
    /**
     * 添加业务类型
     */
    addProfession() {
      console.log("addProfession");
      if (this.professionData == "") {
        this.professionData = {
          id: "",
          name: "",
          serviceType: {},
          serviceItemId: [],
          serviceItemName: "",
          des: "",
          projectRequire: "",
          departmentId: "",
          information: "",
          isCheck: {
            flag: false,
            des:
              "开启后仅指定的人员拥有该权限,其他人无法审核或反审核项目,设置上限为5人",
          },
          achievement: "",
          royalty: "",
          step: [],
          butArray: this.butArray,
          is_edit: true,
        };
      } else {
        this.professionData.id = "";
        this.professionData.name = "";
        this.professionData.serviceType = {};
        this.professionData.serviceItemId = [];
        this.professionData.serviceItemName = "";
        this.professionData.des = "";
        this.professionData.departmentId = "";
        this.professionData.projectRequire = "";
        this.professionData.information = "";
        this.professionData.isCheck.flag = false;
        this.professionData.isCheck.des =
          "开启后仅指定的人员拥有该权限,其他人无法审核或反审核项目,设置上限为5人";
        this.professionData.achievement = "";
        this.professionData.royalty = "";
        this.professionData.step = [];
        this.professionData.butArray = this.butArray;
        this.professionData.is_edit = true;
      }
    },
    // 删除待办流程设置项
    deleteProfession(value) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let requestData = {
            id: value.id,
          };
          this.$request(
            "post",
            "/profession/deleteProfession",
            requestData,
            (data) => {
              this.$message.success("删除成功!");
              this.getAllProfessionList();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
        })
        .catch(() => {});
    },
    /**
     * 取消提交或取消编辑
     */
    cancelProfession() {
      if (this.tempProfessionId == "") {
        this.addProfession();
      } else {
        this.getProfessionDataById(this.tempProfessionId);
      }
    },
    /**
     * 添加步骤
     */
    addStep() {
      this.dialogData.title = "添加步骤";
      this.dialogData.buttonArray = [
        {
          text: "确认",
          active: "add_step",
        },
        {
          text: "取消",
          active: "cancel",
        },
      ];
      let options = [];
      let array = ["服务顾问", "主办会计", "客户经理", "分部负责人"];
      this.roleList.forEach((item) => {
        array.forEach((it) => {
          if (item.label == it) {
            options.push({
              label: item.label,
              value: item.value,
            });
          }
        });
      });
      this.dialogData.data = [
        {
          title: "步骤名称",
          key: "name",
          type: "input",
          placeholder: "请输入步骤名称",
          value: "",
        },
        {
          title: "步骤描述",
          key: "des",
          type: "input",
          placeholder: "请输入步骤描述",
          value: "",
        },
        {
          title: "执行员工",
          key: "employee",
          type: "select-order",
          employee: {
            key: "users",
            placeholder: "请选择执行员工",
            options: this.usersList,
            value: [],
          },
          orderUser: {
            key: "oneself",
            title: "下单人自己",
            value: false,
          },
        },
        {
          title: "客户角色",
          key: "clientRole",
          type: "select-object",
          value: [],
          options: options,
        },
      ];
      this.dialogData.flag = true;
    },
    /**
     * 编辑步骤
     */
    editStep(val) {
      this.tempStep.data = val.data;
      console.log("this.tempStep.data", this.tempStep.data);
      this.tempStep.index = val.index;
      this.dialogData.title = "编辑步骤";
      this.dialogData.buttonArray = [
        {
          text: "确认",
          active: "edit_step",
        },
        {
          text: "取消",
          active: "cancel",
        },
      ];
      let options = [];
      let array = ["服务顾问", "主办会计", "客户经理", "分部负责人"];
      this.roleList.forEach((item) => {
        array.forEach((it) => {
          if (item.label == it) {
            options.push({
              label: item.label,
              value: item.value,
            });
          }
        });
      });
      this.dialogData.data = [
        {
          title: "步骤名称",
          key: "name",
          type: "input",
          placeholder: "请输入步骤名称",
          value: this.tempStep.data.name,
        },
        {
          title: "步骤描述",
          key: "des",
          type: "input",
          placeholder: "请输入步骤描述",
          value: this.tempStep.data.des,
        },
        {
          title: "执行员工",
          key: "employee",
          type: "select-order",
          employee: {
            key: "users",
            placeholder: "请选择执行员工",
            options: this.usersList,
            value: this.tempStep.data.users,
          },
          orderUser: {
            key: "oneself",
            title: "下单人自己",
            value: this.tempStep.data.oneself,
          },
        },
        {
          title: "客户角色",
          key: "clientRole",
          type: "select-object",
          value: this.tempStep.data.clientRole || [],
          options: options,
        },
      ];
      this.dialogData.flag = true;
    },
    /**
     * 获取对应角色员工
     */
    selectHandel(data) {
      console.log("业务角色", data);
      let requestData = {
        authorityId: data.value,
      };
      this.$request(
        "post",
        "/role/getSaleUserListByAuthorityId",
        requestData,
        (data) => {
          this.usersList = [];
          data.forEach((element) => {
            let dd = {
              label: element.name,
              value: element.id,
            };
            this.usersList.push(dd);
          });
          this.dialogData.data[3].options = this.usersList;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 下移步骤
     */
    downStep(data) {
      if (data.index == this.professionData.step.length - 1) {
        return;
      }
      console.log("下移", data);

      let temp = JSON.parse(JSON.stringify(data.data));
      this.data.data = JSON.parse(
        JSON.stringify(this.professionData.step[data.index + 1])
      );
      this.professionData.step[data.index + 1] = JSON.parse(
        JSON.stringify(temp)
      );
      console.log("下移结束", this.professionData);
    },
    /**
     * 提交或编辑业务信息
     */
    submitProfession(data) {
      console.log(data);
      let requestData = {
        companyId: this.baseInfo.company.companyId, // 公司id
        id: data.id,
        serviceType: data.serviceType, // 服务类别
        serviceItemId: data.serviceItemId, // 服务产品
        departmentId: data.departmentId, // 作用部门ID
        name: data.name, // 业务类型名称
        des: data.des, // 业务类型备注
        projectRequire: data.projectRequire, // 项目要求
        information: data.information, // 所需资料
        isCheck: data.isCheck, // 审核开关{flag:true,des:"描述"}
        royalty: data.royalty, // 提成设置
        achievement: data.achievement, // 项目成果
        step: data.step, // 项目步骤
      };
      if (requestData.name == "") {
        this.$message({
          message: "业务类型名称不可为空!",
          type: "error",
          duration: 1000,
        });
        return;
      }
      if (!requestData.serviceType.id) {
        this.$message({
          message: "服务类别不可为空!",
          type: "error",
          duration: 1000,
        });
        return;
      }
      if (requestData.serviceItemId.length <= 0) {
        this.$message({
          message: "服务产品不可为空!",
          type: "error",
          duration: 1000,
        });
        return;
      }
      if (data.id == "") {
        // 新增
        this.$request(
          "post",
          "/profession/addProfession",
          requestData,
          (resultData) => {
            this.getAllProfessionList();
          },
          (errorMessage) => {
            this.$message.error(errorMessage);
          }
        );
      } else {
        // 编辑
        this.$request(
          "post",
          "/profession/updateProfessionById",
          requestData,
          (resultData) => {
            this.$message.success("提交成功!");
            this.getProfessionDataById(this.tempProfessionId);
          },
          (errorMessage) => {
            this.$message.error(errorMessage);
          }
        );
      }
    },
    /**
     * 切换tab
     */
    changeTab(data, index) {
      if (this.tabList[index].flag < 3) {
        this.loading = true;
        this.tabIndex = data.text;
        switch (index) {
          case 0:
            // 选项值设置
            this.Loading();
            this.getAllProfessionList();
            break;
          case 1:
            // 签约主体设置
            this.tabIndex = "签约主体设置";
            this.getSignInfoList();
            break;
          case 2:
            // 权限设置
            this.tabIndex = "选项值设置";
            this.$message.error("功能尚未开放!");
            this.changeTab({ text: "选项值设置" }, 0);

            break;

          default:
            break;
        }
      } else {
        this.$message.error(
          this.$errorMessage.getErrorMessageInfo("authority")
        );
      }
    },
    /**
     * 获取签约主体信息列表
     */
    getSignInfoList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/signInfo/getSignInfoList",
        requestData,
        (data) => {
          this.signInfoData = data;
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 签约主体表格操作
     */
    handlClick(row, active) {
      let requestData = {};
      switch (active) {
        case "add":
          // 添加签约主体
          this.tempSignnfo = { id: "" };
          this.dialogData.data = [
            {
              title: "名称",
              type: "input",
              placeholder: "请输入名称",
              value: "",
            },
            {
              title: "公司抬头",
              type: "input",
              placeholder: "请输入公司抬头",
              value: "",
            },
            {
              title: "开户银行",
              type: "input",
              placeholder: "请输入开户银行",
              value: "",
            },
            {
              title: "银行账号",
              type: "input",
              placeholder: "请输入银行账号",
              value: "",
            },
            {
              title: "法定代表人",
              type: "input",
              placeholder: "请输入法定代表人",
              value: "",
            },
            {
              title: "电话",
              type: "input",
              placeholder: "请输入电话",
              value: "",
            },
            {
              title: "地址",
              type: "input",
              placeholder: "请输入地址",
              value: "",
            },
            {
              title: "电子章",
              type: "upload_img_png",
              placeholder: "请输入地址",
              value: {
                value: "",
                action: this.$fileBaseurl + "/uploadImageByMultipartFile",
                flag: false,
                headers: {
                  token: "95931b371eb64f4a9c862983ca8a5d15",
                },
                uploadParameter: {
                  systemName: "finance",
                  title: "合同电子章",
                },
              },
            },
          ];
          this.dialogData.title = "添加签约主体/开票抬头";
          this.dialogData.width = "50%";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "save_sign_info",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.flag = true;
          break;
        case "edit":
          // 编辑签约主体
          this.tempSignnfo = row;
          this.dialogData.data = [
            {
              title: "名称",
              type: "input",
              placeholder: "请输入名称",
              value: row.name,
            },
            {
              title: "公司抬头",
              type: "input",
              placeholder: "请输入公司抬头",
              value: row.companyName,
            },
            {
              title: "开户银行",
              type: "input",
              placeholder: "请输入开户银行",
              value: row.bankName,
            },
            {
              title: "银行账号",
              type: "input",
              placeholder: "请输入银行账号",
              value: row.bankAccount,
            },
            {
              title: "法定代表人",
              type: "input",
              placeholder: "请输入法定代表人",
              value: row.legal,
            },
            {
              title: "电话",
              type: "input",
              placeholder: "请输入电话",
              value: row.tel,
            },
            {
              title: "地址",
              type: "input",
              placeholder: "请输入地址",
              value: row.address,
            },
            {
              title: "电子章",
              type: "upload_img_png",
              placeholder: "请输入地址",
              value: {
                value: row.url,
                action: this.$fileBaseurl + "/uploadImageByMultipartFile",
                flag: false,
                headers: {
                  token: "95931b371eb64f4a9c862983ca8a5d15",
                },
                uploadParameter: {
                  systemName: "finance",
                  title: "合同电子章",
                },
              },
            },
          ];
          this.dialogData.title = "添加签约主体/开票抬头";
          this.dialogData.width = "50%";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "save_sign_info",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.flag = true;
          break;
        case "delete":
          this.$confirm("此操作将永久删除该条数据, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              requestData = {
                id: row.id,
              };
              this.$request(
                "post",
                "/signInfo/delSignInfo",
                requestData,
                (data) => {
                  this.$message.success("删除成功!");
                  this.getSignInfoList();
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
          break;

        default:
          break;
      }
    },
    /**
     * 弹出框操作事件
     */
    drawerOper(active) {
      let dd = {};
      switch (active) {
        case "add_step":
          // 确认添加步骤
          dd = {
            role: {},
            money: "",
            active: "no",
          };
          this.dialogData.data.forEach((item) => {
            if (item.key == "employee") {
              dd[item.employee.key] = item.employee.value;
              dd[item.orderUser.key] = item.orderUser.value;
            } else {
              dd[item.key] = item.value;
            }
          });
          this.professionData.step.push(dd);
          this.dialogData.flag = false;
          break;
        case "edit_step":
          // 确认编辑步骤
          dd = {
            role: {},
            money: "",
            active: "no",
          };
          this.dialogData.data.forEach((item) => {
            if (item.key == "employee") {
              dd[item.employee.key] = item.employee.value;
              dd[item.orderUser.key] = item.orderUser.value;
            } else {
              dd[item.key] = item.value;
            }
          });
          this.professionData.step.splice(this.tempStep.index, 1, dd);
          console.log("编辑步骤:", this.professionData);
          this.dialogData.flag = false;
          break;
        case "save_sign_info":
          // 添加签约主体
          let requestData = {
            id: this.tempSignnfo.id, // 有则修改,空则新增
            companyId: this.baseInfo.company.companyId, // 公司id
            name: this.dialogData.data[0].value, // 主体名称
            companyName: this.dialogData.data[1].value, // 公司名称
            bankName: this.dialogData.data[2].value, // 开户银行
            bankAccount: this.dialogData.data[3].value, // 银行账号
            legal: this.dialogData.data[4].value, // 法定代表人
            tel: this.dialogData.data[5].value, // 电话
            address: this.dialogData.data[6].value, // 地址
            url: this.dialogData.data[7].value.value, // 电子合同章
          };
          if (requestData.name == "") {
            this.$message({
              type: "error",
              message: "签约主体名称不能为空!",
            });
            return;
          }
          if (requestData.nacompanyNameme == "") {
            this.$message({
              type: "error",
              message: "签约主体公司抬头不能为空!",
            });
            return;
          }
          if (requestData.url == "") {
            this.$message({
              type: "error",
              message: "合同的电子章不能为空!",
            });
            return;
          }
          this.$request(
            "post",
            "/signInfo/saveSignInfo",
            requestData,
            (data) => {
              this.$message({
                type: "success",
                message: "修改成功!",
              });
              this.getSignInfoList();
              this.dialogData.flag = false;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "cancel":
          // 取消
          this.colseDialog();
          break;

        default:
          break;
      }
    },
    /**
     * 上传电子合同章成功
     */
    handleAvatarSuccess(response) {
      this.$message({
        message: "电子章上传成功!",
        type: "success",
        duration: 1000,
      });
      this.dialogData.data[7].value.value = response.result;
    },
    /**
     * 页面加载动画
     */
    Loading() {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    /**
     * 流程父子组件连锁事件
     */
    professionShow(value) {
      this.professionData = value;
    },
    /**
     * 关闭弹出框
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
  },
  mounted() {
    let employeeList = JSON.parse(
      JSON.stringify(this.$store.getters.getAllEmployeeList)
    );
    this.usersList = [];
    employeeList.forEach((element) => {
      let dd = {
        label: element.userName,
        value: element.userId,
      };
      this.usersList.push(dd);
    });

    this.isCharge();
    this.loading = false;
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#contract-setting {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /deep/ .el-tree {
    font-size: 14px;
    padding: 0 5px;
  }
  /deep/ .el-tree-node__content > .el-tree-node__expand-icon {
    display: none;
  }
  /deep/ .el-tree-node__content:hover {
    transition: 0.3s;
    color: @baseColor;
    background-color: #ffffff;
  }

  .contract-setting-screen-tabs {
    width: 90%;
    height: 100%;
    display: flex;
    font-size: 14px;
    margin-left: 20px;
    align-items: center;
    .contract-setting-screen-tab {
      width: 100px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      transition: 0.3s;
      box-sizing: border-box;
    }
    .contract-setting-screen-tab:hover {
      cursor: pointer;
      color: @activeColor;
    }
    .is-active {
      color: @activeColor;
      border-bottom: 4px solid @activeColor;
      box-sizing: border-box;
    }
    .spl {
      flex: 1;
    }
    > .but {
      width: auto;
      padding: 0 20px;
      box-sizing: border-box;
      border: 1px solid @activeColor;
      background-color: @activeBackground;
      color: @activeColor;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: @contentFontSize4;
      cursor: pointer;
      border-radius: 5px;
      margin: 0 5px;
      i {
        margin-right: 5px;
      }
    }
  }
  .table-content {
    flex: 1;
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    .contract-table-card {
      width: 98%;
      height: 98%;
      max-height: 830px;
      max-width: 90vw;
      overflow-y: auto;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;

      &::-webkit-scrollbar {
        width: 1px;
      }
      .contract-table-card-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .contract-table-card-content-line,
        .contract-table-card-content-line2 {
          width: 100%;
          flex: 1;
          // min-height: 200px;
          height: 200px;
          border-bottom: 1px solid @boderColor;
          display: flex;
          flex-direction: column;
          overflow: auto;
          &::-webkit-scrollbar {
            width: 1px;
          }
          .contract-table-card-content-line-title {
            width: 98%;
            height: 40px;
            margin-left: 20px;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            span {
              height: 60%;
              display: flex;
              align-items: flex-end;
              color: @tipColor;
              font-size: @contentFontSize;
              margin-left: 10px;
            }
            .contract-table-card-content-line-title-save {
              width: auto;
              height: 30px;
              // margin-right: 20px;
              padding: 0 10px;
              border: 1px solid @activeColor3;
              background-color: @activeBackground;
              border-radius: 5px;
              box-sizing: border-box;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: 0.3s;
              font-size: 12px;
              &:hover {
                cursor: pointer;
                // transform: scale(1.02);
                background-color: @activeColor;
                color: #fff;
              }
            }
          }
          .contract-table-card-content-line-value {
            width: 95%;
            height: 90%;
            display: flex;
            margin-left: 40px;
            .el-tag + .el-tag {
              margin-left: 20px;
            }
            .button-new-tag {
              margin-left: 20px;
              height: 32px;
              line-height: 30px;
              padding-top: 0;
              padding-bottom: 0;
            }
            .input-new-tag {
              width: 90px;
              margin-left: 10px;
              vertical-align: bottom;
            }
            .div-tags {
              height: auto;
              width: 96%;
              display: flex;
              flex-wrap: wrap;
              & > div {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                & > span {
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                }
              }
              .div-tag {
                width: auto;
                min-width: 30px;
                height: 25px;
                margin: 10px;
                padding: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid @boderActiveColor;
                transition: 0.5s;
                font-size: @contentFontSize;
              }
              .div-tag:hover {
                cursor: pointer;
                border: 1px dashed @activeColor;
                background-color: @activeBackground;
                color: @activeColor;
              }
            }
            .contract-table-card-content-line-value-button {
              width: 98%;
              flex: 1;
              height: 0;
              // overflow: auto;
              display: flex;
              .contract-table-card-content-line-value-button-title {
                width: 15%;
                height: 98%;
              }
              .contract-table-card-content-line-value-button-values {
                flex: 1;
                height: 98%;
                overflow: auto;
                display: flex;
                flex-wrap: wrap;
                min-height: 190px;

                &::-webkit-scrollbar {
                  width: 1px;
                }
              }
            }
            .contract-table-card-content-line-value-button:nth-child(1) {
              // height: 120px;
              border-bottom: 1px solid @boderColor;
              margin-bottom: 10px;
              padding-bottom: 10px;
            }
          }
          .div-flex-column {
            flex-direction: column;
          }
          .div-flex-space-between {
            justify-content: space-between;
          }
        }
        .contract-table-card-content-line2 {
          max-height: 400px;
        }
        .contract-table-card-content-line {
          min-height: 200px;
        }

        .contract-table-card-content-tags-setting {
          width: 100%;
          height: 100%;
          display: flex;
          .contract-table-card-content-tags-setting-left {
            width: 10vw;
            height: 100%;
            display: flex;
            flex-direction: column;
            border-right: 1px solid @activeColor3;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12),
              0 0 6px rgba(0, 0, 0, 0.04);
            padding-right: 5px;
            box-sizing: border-box;
            > .content {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              overflow-y: auto;
              overflow-x: hidden;
            }
            .contract-table-card-content-tags-setting-left-line {
              width: 100%;
              height: 50px;
              min-height: 50px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0 5px;
              box-sizing: border-box;
              font-size: @contentFontSize;
              transition: 0.3s;
              color: #000;
              overflow: hidden;
              white-space: nowrap;
              &:hover {
                cursor: pointer;
                color: @activeColor;
              }
            }
            .is-active {
              color: @activeColor;
            }
          }
          .contract-table-card-content-tags-setting-right {
            flex: 1;
            width: 0;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .contract-table-card-content-tags-setting-right-add {
              width: 100%;
              height: 8vh;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              padding-left: 20px;
              .butt {
                width: 100px;
                height: 40px;
                border: 1px solid @activeColor;
                background-color: @activeColor;
                color: #fff;
                font-size: @contentFontSize;
                display: flex;
                align-items: center;
                border-radius: 10px;
                justify-content: center;
                transition: 0.3s;
                &:hover {
                  cursor: pointer;
                  background-color: @activeColor2;
                }
              }
            }
            .contract-table-card-content-tags-setting-right-list {
              flex: 1;
              height: 0;
              width: 97%;
              display: flex;
              flex-direction: column;
              overflow-y: auto;

              &::-webkit-scrollbar {
                width: 1px;
              }
              .contract-table-card-content-tags-setting-right-line {
                height: 50px;
                min-height: 50px;
                width: 100%;
                display: flex;
                align-items: center;
                padding-left: 10px;
                box-sizing: border-box;
                border-bottom: 1px solid @boderColor;
                justify-content: space-between;
                & > div {
                  display: flex;
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
