<template>
  <div id="authority-dialog">
    <div class="authority-dialog-title">
      <div class="authority-dialog-title-texts">
        <div class="authority-dialog-title-text">
          <span>模块: {{ data.modelName }}</span>
          <el-switch v-model="data.modelShow"> </el-switch>
        </div>
        <div class="authority-dialog-title-texts-cards">
          <!-- 选择子菜单 -->
          <div
            :class="{
              'authority-dialog-title-texts-card': true,
              'is-active': cardIndex == index,
            }"
            v-for="(item, index) in data.modelMenuAuthor"
            :key="index"
            @click="drawerOper(item, index)"
          >
            {{ item.menu }}
          </div>
        </div>
      </div>
    </div>
    <div class="authority-dialog-content">
      <div class="authority-dialog-content-title">
        <span>可见性:</span
        ><el-switch v-model="data.modelMenuAuthor[cardIndex].isShow">
        </el-switch>
      </div>
      <div class="authority-dialog-content-lines-but">
        <div
          class="authority-dialog-content-line"
          v-for="(item, index) in data.authorData[cardIndex].buttonAuth"
          :key="index"
        >
          <span>{{ item.title }}:</span
          ><el-radio-group
            v-model="item.value"
            :disabled="!data.modelMenuAuthor[cardIndex].isShow"
          >
            <el-radio :label="1">读写</el-radio>
            <el-radio :label="2">只读</el-radio>
            <el-radio :label="3">不可见</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="authority-dialog-content-lines">
        <div
          class="authority-dialog-content-line"
          v-for="(item, index) in data.authorData[cardIndex].data"
          :key="index"
        >
          <span>{{ item.title }}:</span
          ><el-radio-group
            v-model="item.value"
            :disabled="!data.modelMenuAuthor[cardIndex].isShow"
          >
            <el-radio :label="1">读写</el-radio>
            <el-radio :label="2">只读</el-radio>
            <el-radio :label="3">不可见</el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["authorityData"],
  data() {
    return {
      data: this.authorityData,
      cardIndex: 0,
    };
  },
  watch: {
    authorityData() {
      //父组件值变化->>子组件也变化
      this.data = this.authorityData;
    },
    data(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {
    drawerOper(data, index) {
      this.cardIndex = index;
    },
  },
};
</script>

<style lang="less" scoped>
#authority-dialog {
  width: 100%;
  height: 840px;
  .authority-dialog-title {
    width: 100%;
    height: 45px;
    display: flex;
    // position: fixed;
    border-bottom: 1px solid @boderColor;
    .authority-dialog-title-texts {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 12px;
      margin-left: 0;
      .authority-dialog-title-text {
        width: 70%;
        flex: 1;
        margin-left: 20px;
        display: flex;
        span {
          margin-right: 10px;
        }
        // justify-content: space-between;
      }
      .authority-dialog-title-texts-cards {
        flex: 1;
        display: flex;
        .authority-dialog-title-texts-card {
          width: auto;
          margin: 0 10px;
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-bottom: 1px solid rgba(255, 255, 255, 0);
        }
        .authority-dialog-title-texts-card:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 1px solid @activeColor;
        }
      }
    }
  }
  .authority-dialog-content {
    width: 98%;
    flex: 1;
    max-height: 800px;
    display: flex;
    flex-direction: column;
    margin: 0 10px;
    // margin-top: 45px;
    margin-bottom: 40px;
    overflow-y: auto;
    font-size: @contentFontSize5;

    &::-webkit-scrollbar {
      width: 1px;
    }
    .authority-dialog-content-title {
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 12px;
      span {
        margin-right: 10px;
      }
    }
    .authority-dialog-content-lines,
    .authority-dialog-content-lines-but {
      width: 90%;
      height: auto;
      overflow: auto;
      display: flex;
      flex-direction: column;

      &::-webkit-scrollbar {
        width: 1px;
      }
      //   padding: 10px;
      .authority-dialog-content-line {
        width: 100%;
        height: 35px;
        min-height: 35px;
        display: flex;
        align-items: center;
        span {
          width: 150px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-right: 10px;
        }
      }
    }
    .authority-dialog-content-lines-but {
      border-bottom: 1px solid @activeColor;
      margin-bottom: 10px;
      max-height: 300px;
    }
    .authority-dialog-content-lines {
      max-height: 500px;
    }
  }
}
</style>