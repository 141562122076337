/**
 * 获取收款报表的菜单数据
 */
export function getReportCollectionMenuData() {
    return [{
        key: "userName",
        title: "员工姓名",
        width: "120"
    }, {
        key: "clientName",
        title: "公司名称",
        width: ""
    }, {
        key: "serveItem",
        title: "服务项目",
        width: "120"
    }, {
        key: "money",
        title: "签约金额",
        width: ""
    }, {
        key: "cost",
        title: "成本",
        width: ""
    }, {
        key: "paymentCollect",
        title: "收款金额",
        width: ""
    }, {
        key: "amountsOw",
        title: "未回款额",
        width: ""
    }, {
        key: "profits",
        title: "毛利润",
        width: ""
    }, {
        key: "checkTime",
        title: "领款时间",
        width: "150"
    }, {
        key: "transactionNumber",
        title: "交易单号",
        width: "230"
    }, {
        key: "contractSd",
        title: "合同起始日",
        width: "120"
    }, {
        key: "contractEd",
        title: "合同截止日",
        width: "120"
    }, {
        key: "contractNumber",
        title: "合同编号",
        width: "150"
    }, {
        key: "accountOwner",
        title: "账号所属者",
        width: "120"
    }, {
        key: "account",
        title: "账号",
        width: "120"
    }]
}

/**
 * 获取发票报表的菜单数据
 */
export function getReportInvoiceMenuData() {
    return [{
        key: "payeeName",
        title: "收款人姓名",
        width: "120"
    }, {
        key: "clientName",
        title: "公司名称",
        width: "260"
    }, {
        key: "contractNum",
        title: "合同编号",
        width: "150"
    },{
        key: "service",
        title: "服务项目",
        width: "120"
    }, {
        key: "money",
        title: "签约金额",
        width: "120"
    }, {
        key: "profits",
        title: "收款金额",
        width: "120"
    }, {
        key: "amountsOw",
        title: "未回款金额",
        width: "120"
    }, {
        key: "invoiceName",
        title: "开票名称",
        width: "260"
    },{
        key: "invoiceAmount",
        title: "开票金额",
        width: "120"
    },{
        key: "invoiceApplicant",
        title: "发票申请人",
        width: "120"
    },]
}



/**
 * 获取销售报表的菜单数据
 */
export function getReportSalesMenuData() {
    return [{
        key: "userName",
        title: "员工姓名",
        width: ""
    }, {
        key: "department",
        title: "所属部门",
        width: ""
    }, {
        key: "todaySignedSingular",
        title: "今日签单数",
        width: ""
    }, {
        key: "todayNewThreads",
        title: "今日商机",
        width: ""
    }, {
        key: "followUpNumberToday",
        title: "今日跟进次数",
        width: ""
    }, {
        key: "monthSignedSingular",
        title: "本月签单数",
        width: ""
    }, {
        key: "monthNewThreads",
        title: "本月新增商机",
        width: ""
    }, {
        key: "monthNewClient",
        title: "本月客户数",
        width: ""
    }, {
        key: "followUpNumberMonth",
        title: "本月跟进次数",
        width: ""
    }, {
        key: "threads",
        title: "商机总数",
        width: ""
    }, ]
}

/**
 * 获取续费统计的菜单数据
 */
export function getReportRenewalMenuData() {
    return [{
        key: "userName",
        title: "员工姓名",
        width: "120"
    }, {
        key: "department",
        title: "所属部门",
        width: "120"
    }, {
        key: "todayReceivable",
        title: "今日回款数",
        width: "120"
    }, {
        key: "todayReceivableMoney",
        title: "今日回款金额",
        width: "120"
    }, {
        key: "monthReceivableClient",
        title: "本月回款客户数",
        width: "120"
    }, {
        key: "monthReceivableMoney",
        title: "本月回款金额",
        width: "120"
    }, {
        key: "huikuanlv",
        title: "回款率", // 当月回款客户数/当月到期客户数
        tip: "当月回款客户数/当月到期客户数",
        width: "200"
    }, {
        key: "quarterHuikuanlv",
        title: "季度回款率", // 当月回款客户/上月到期客户+本月到期客户数+下月到期客户数
        tip: "当月回款客户/上月到期客户+本月到期客户数+下月到期客户数",
        width: "400"
    }, {
        key: "dueHuikuanlv",
        title: "到期回款率", // 当月回款客户数/本月到期客户+到当天为止所有未全部回款客户数
        tip: "当月回款客户数/本月到期客户+到当天为止所有未全部回款客户数",
        width: "400"
    }, {
        key: "expiredClient",
        title: "已到期客户数",
        width: "120"
    }, {
        key: "dueMonthClient",
        title: "本月到期客户数",
        width: "120"
    }, {
        key: "dueNextMonthClient",
        title: "下月到期客户数",
        width: "120"
    }, {
        key: "due3MonthsClient",
        title: "3个月内到期客户数",
        width: "150"
    }, ]
}

/**
 * 获取客户跟进记录的菜单数据
 */
export function getReportFollowMenuData() {
    return [{
        key: "clientName",
        title: "客户名称",
        width: 270
    }, {
        key: "responsible",
        title: "负责人",
        width: 200
    }, {
        key: "followTime",
        title: "跟进时间",
        width: 120
    }, {
        key: "followUser",
        title: "跟进人",
        width: 180
    }, {
        key: "followContont",
        title: "跟进记录",
        width: 270
    }, ]
}

/**
 * 获取报销报表的菜单数据
 */
export function getReportReimburseMenuData() {
    return [{
        key: "userName",
        title: "姓名",
        width: ""
    }, {
        key: "department",
        title: "部门",
        width: ""
    }, {
        key: "position",
        title: "职位",
        width: ""
    }, {
        key: "title",
        title: "标题",
        width: ""
    }, {
        key: "type",
        title: "科目",
        width: ""
    }, {
        key: "time",
        title: "时间",
        width: ""
    }, {
        key: "money",
        title: "金额",
        width: ""
    }, {
        key: "status",
        title: "状态",
        width: ""
    }, ]
}