<template>
  <div id="tax">
    <!-- 税务 -->
    <div class="tax-menu">
      <div v-for="(item, index) in menuData" :key="index">
        <div
          class="tax-menu-lines"
          v-if="item.children.length > 0 && item.flag == 1"
        >
          <div class="tax-menu-line">
            <i :class="item.icon"></i> {{ item.title }}
          </div>
          <div
            :class="{
              'tax-menu-line-item': true,
              'is-active': menu_index == it.path,
            }"
            v-for="(it, ii) in item.children"
            :key="ii"
            v-show="it.flag == 1"
            @click="changeMenu(it.path)"
          >
            {{ it.title }}
          </div>
        </div>
        <div
          :class="{
            'tax-menu-line': true,
            'is-active': menu_index == item.path,
          }"
          @click="changeMenu(item.path)"
          v-else-if="item.flag == 1"
        >
          <i :class="item.icon"></i>{{ item.title }}
        </div>
      </div>
    </div>
    <div class="tax-content">
      <router-view v-if="isRouterAlive" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      baseInfo: {},
      menu_index: "tax-info",
      isRouterAlive: true,
      pathList: [
        // "tax-info",
      ],
      menuData: [
        {
          title: "税务",
          icon: "el-icon-coin",
          flag: 0,
          path: "1",
          children: [
            {
              title: "税务统计",
              path: "tax-info",
              active: "taxInfo",
              flag: 0,
            },
          ],
        },
        {
          title: "社保",
          icon: "el-icon-s-custom",
          flag: 0,
          path: "1",
          children: [
            {
              title: "社保统计",
              path: "social-security",
              active: "socialSecurity",
              flag: 0,
            },
            {
              title: "社保审核",
              path: "social-check",
              active: "socialCheck",
              flag: 0,
            },
          ],
        },
        {
          title: "流水",
          icon: "el-icon-s-data",
          flag: 0,
          path: "1",
          children: [
            {
              title: "企业流水",
              path: "tax-statement",
              active: "taxStatement",
              flag: 0,
            },
          ],
        },
        {
          title: "园区",
          icon: "el-icon-office-building",
          flag: 0,
          path: "1",
          children: [
            {
              title: "企业园区",
              path: "tax-park",
              active: "taxPark",
              flag: 0,
            },
          ],
        },
        {
          title: "数据",
          icon: "el-icon-document",
          flag: 0,
          path: "1",
          children: [
            {
              title: "财务数据",
              path: "tax-cost",
              active: "taxCost",
              flag: 0,
            },
          ],
        },
      ],
    };
  },
  methods: {
    /**
     * 获取初始菜单设置的按钮权限
     */
    getUserAuthorityByModels() {
      this.$authority.getUserAuthorityByModels(
        this,
        this.menuData,
        (data, pathList) => {
          this.menuData = data;
          this.pathList = pathList;
          if (this.pathList.length > 0) {
            this.menu_index =
              sessionStorage.getItem("menuSecondActiveIndex") &&
              this.pathList.indexOf(
                sessionStorage.getItem("menuSecondActiveIndex")
              ) > -1
                ? sessionStorage.getItem("menuSecondActiveIndex")
                : this.pathList[0];
            sessionStorage.setItem("menuSecondActiveIndex", this.menu_index);

            this.$router.push("/main/tax/" + this.menu_index);
          } else {
            this.$router.push("/main/index");
          }
        }
      );
    },
    changeMenu(path) {
      this.menu_index = path;
      sessionStorage.setItem("menuSecondActiveIndex", this.menu_index);
      this.$router.push("/main/tax/" + path);
    },
  },
  mounted() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getUserAuthorityByModels();
  },
};
</script>

<style lang="less" scoped>
#tax {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #fff;
  .tax-menu {
    width: 180px;
    height: 100%;
    min-width: 9vw;
    border-right: 1px solid @boderColor;
    overflow: hidden;
    font-size: @contentFontSize;
    .tax-menu-lines {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .tax-menu-line-item,
    .tax-menu-line {
      width: 100%;
      height: 40px;
      display: flex;
      padding-left: 10px;
      transition: 0.3s;
      align-items: center;
      border-left: 4px solid #fff;
      box-sizing: border-box;
      i {
        margin-right: 10px;
      }
    }
    .tax-menu-line-item {
      padding-left: 53px;
    }
    .tax-menu-line {
      font-weight: 600;
    }
    .tax-menu-line-item:hover,
    .tax-menu-line:hover {
      cursor: pointer;
      background-color: @activeBackground;
    }
    .is-active {
      background-color: @activeBackground;
      border-left: 4px solid @activeColor;
      box-sizing: border-box;
      color: @activeColor;
    }
  }
  .tax-content {
    flex: 1;
    height: 100%;
    position: relative;
  }
}
</style>
