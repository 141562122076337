<template>
  <div id="department_details" v-loading="isLoading">
    <!-- 权限重构后的部门详情 -->
    <div class="content">
      <div class="left">
        <div class="tree customScrollBar">
          <el-tree
            :data="departList"
            node-key="id"
            default-expand-all
            :expand-on-click-node="false"
            icon-class="el-icon-help"
            @node-click="showDetail"
          >
            <span class="custom-tree-node" slot-scope="{ data }">
              <span>{{ data.label }}</span>
            </span>
          </el-tree>
        </div>
      </div>
      <div class="right">
        <div class="content" v-if="flag == 0">
          <!-- 部门详情 -->
          <div class="line">
            <div class="title">部门名称:</div>
            <div class="value">
              <div class="text" v-if="!departmentNameFlag">
                {{ department.name }}
              </div>
              <div class="input" v-if="departmentNameFlag">
                <el-input
                  v-model="department.name"
                  placeholder="请输入部门名称"
                  @keyup.enter.native="departmentNameFlag = false"
                  @blur="departmentNameFlag = false"
                ></el-input>
              </div>
              <i
                class="el-icon-edit"
                v-if="!departmentNameFlag"
                @click="departmentNameFlag = true"
                v-showFunction="{ active: 'edit', array: allFunctionButtons }"
              ></i>
            </div>
            <div class="buts">
              <div
                class="but success"
                @click="businessRole"
                v-showFunction="{ active: 'add', array: allFunctionButtons }"
                v-if="department.type == '1'"
              >
                <i class="el-icon-s-tools"> 业务角色</i>
              </div>
              <div
                class="but primary"
                @click="addDepartment"
                v-showFunction="{ active: 'add', array: allFunctionButtons }"
              >
                <i class="el-icon-plus"> 添加部门</i>
              </div>
              <div
                class="but danger"
                @click="deleteDepartment"
                v-showFunction="{
                  active: 'delete',
                  array: allFunctionButtons,
                }"
                v-if="department.type != '1'"
              >
                <i class="el-icon-delete"> 删除部门</i>
              </div>
            </div>
          </div>
          <div class="line">
            <div class="title">上级部门:</div>
            <div class="value">{{ department.parentName }}</div>
          </div>
          <div class="line">
            <div class="title">部门类型:</div>
            <div class="value">
              <el-radio-group v-model="department.type">
                <el-radio :label="'1'" disabled>总公司</el-radio>
                <el-radio
                  :label="'2'"
                  :disabled="department.type == '1' || !editFlag"
                  >分公司</el-radio
                >
                <el-radio
                  :label="'0'"
                  :disabled="department.type == '1' || !editFlag"
                  >普通部门</el-radio
                >
              </el-radio-group>
            </div>
          </div>
          <div class="line">
            <div class="title">
              {{ department.type == "1" ? "机构负责人" : "部门主管" }}:
            </div>
            <div class="value users">
              <div class="user">
                <el-tag
                  :key="tag.id"
                  v-for="tag in department.manager"
                  :closable="editFlag"
                  :disable-transitions="false"
                  @close="handleClose(tag, department.manager)"
                >
                  {{ tag.name }}
                </el-tag>
                <el-select
                  v-model="inputValueManager"
                  class="button-new-tag"
                  filterable
                  placeholder="请选择"
                  v-if="inputVisibleManager"
                  @change="handleInputConfirmManger"
                  value-key="id"
                >
                  <el-option
                    v-for="item in userList"
                    :key="item.id"
                    :label="item.name"
                    :value="item"
                  >
                  </el-option>
                </el-select>
                <el-button
                  v-else
                  class="button-new-tag"
                  size="small"
                  @click="showTag(0)"
                  v-showFunction="{ active: 'edit', array: allFunctionButtons }"
                  >+ 添加</el-button
                >
              </div>
              <div
                class="role"
                @click="showRole(department.managerRoleId, '部门主管')"
                v-showFunction="{
                  active: 'showRole',
                  array: allFunctionButtons,
                }"
              >
                查看权限
              </div>
            </div>
          </div>
          <div class="line">
            <div class="title">
              {{ department.type == "1" ? "机构主管" : "部门员工" }}:
            </div>
            <div class="value users">
              <div class="user">
                <el-tag
                  :key="tag.id"
                  v-for="tag in department.staff"
                  :closable="editFlag"
                  :disable-transitions="false"
                  @close="handleClose(tag, department.staff)"
                >
                  {{ tag.name }}
                </el-tag>
                <el-select
                  v-model="inputValueStaff"
                  class="button-new-tag"
                  filterable
                  placeholder="请选择"
                  v-if="inputVisibleStaff"
                  @change="handleInputConfirmStaff"
                  value-key="id"
                >
                  <el-option
                    v-for="item in userList"
                    :key="item.id"
                    :label="item.name"
                    :value="item"
                  >
                  </el-option>
                </el-select>
                <el-button
                  v-else
                  class="button-new-tag"
                  size="small"
                  @click="showTag(1)"
                  v-showFunction="{ active: 'edit', array: allFunctionButtons }"
                  >+ 添加</el-button
                >
              </div>
              <div
                class="role"
                @click="showRole(department.staffId, '部门员工')"
                v-showFunction="{
                  active: 'showRole',
                  array: allFunctionButtons,
                }"
              >
                查看权限
              </div>
            </div>
          </div>
          <div class="line">
            <div class="title">部门介绍:</div>
            <div class="value">
              <div class="text" v-if="!departmentDesFlag">
                {{ department.des == "" ? "无" : department.des }}
              </div>
              <div class="input" v-if="departmentDesFlag">
                <el-input
                  v-model="department.des"
                  placeholder="请输入部门介绍"
                  @keyup.enter.native="departmentDesFlag = false"
                  @blur="departmentDesFlag = false"
                  type="textarea"
                  :rows="2"
                ></el-input>
              </div>
              <i
                class="el-icon-edit"
                v-if="!departmentDesFlag"
                @click="departmentDesFlag = true"
                v-showFunction="{
                  active: 'showRole',
                  array: allFunctionButtons,
                }"
              ></i>
            </div>
          </div>
          <div class="line">
            <div class="title">业务角色:</div>
            <div class="value role">
              <div class="tip">
                <span
                  >默认继承上级部门所设业务角色
                  编辑当前部门,仅影响当前部门角色</span
                >
              </div>
              <div class="content customScrollBar">
                <div
                  class="role"
                  v-for="(item, index) in department.role"
                  :key="index"
                >
                  <div class="title">
                    {{ item.name }}
                  </div>
                  <div class="value">
                    <div class="radio">
                      <el-radio-group v-model="item.type">
                        <el-radio :label="'0'" :disabled="!editFlag"
                          >无</el-radio
                        >
                        <el-radio :label="'1'" :disabled="!editFlag"
                          >自己</el-radio
                        >
                        <el-radio :label="'2'" :disabled="!editFlag"
                          >指定人员</el-radio
                        >
                      </el-radio-group>
                    </div>
                    <div class="user" v-if="item.type == 2">
                      <el-tag
                        :key="tag.id"
                        v-for="tag in item.user"
                        :closable="editFlag"
                        :disable-transitions="false"
                        @close="handleClose(tag, item.user)"
                      >
                        {{ tag.name }}
                      </el-tag>
                      <el-select
                        v-model="item.value"
                        class="button-new-tag"
                        filterable
                        placeholder="请选择"
                        v-if="item.flag"
                        @change="handleInputConfirmStaffObj(item)"
                        value-key="id"
                      >
                        <el-option
                          v-for="item in userList"
                          :key="item.id"
                          :label="item.name"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                      <el-button
                        v-else
                        class="button-new-tag"
                        size="small"
                        @click="showTagObj(item)"
                        v-showFunction="{
                          active: 'edit',
                          array: allFunctionButtons,
                        }"
                        >+ 添加</el-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content" v-if="flag == 1">
          <!-- 角色详情 -->
          <div class="line">
            <div class="title" style="width: auto">
              {{ department.name }}-{{ role.name }}:
            </div>
            <div class="value">
              <!-- <div
                class="but"
                v-if="department.type != '1'"
                @click="chooseDepartmentAuthority(0)"
              >
                上级权限
              </div>-->
              <div class="but" @click="chooseDepartmentAuthority(1)">
                <i class="el-icon-document-copy"></i> 权限配置复制
              </div>
            </div>
          </div>
          <div class="role_content customScrollBar">
            <el-tree
              :data="role.roleList"
              node-key="id"
              :default-expand-all="false"
              :expand-on-click-node="false"
            >
              <template slot-scope="{ node, data }">
                <div class="role_tree">
                  <div class="line">
                    <div class="title">{{ data.name }}:</div>
                    <div class="sw">
                      <el-switch
                        v-model="data.show"
                        @change="changeFunctionalAuthority(data, node)"
                      >
                      </el-switch>
                    </div>
                    <div
                      class="option"
                      v-if="
                        node.isLeaf && data.show && data.isAttribute == 'true'
                      "
                      @click="showBaseOption(data)"
                    >
                      基本属性
                    </div>
                  </div>
                  <div
                    class="authority"
                    v-if="node.isLeaf && data.feature.length > 0"
                  >
                    <!-- 功能权限 -->
                    <el-checkbox
                      v-model="item.authority"
                      class="item"
                      v-for="(item, index) in data.feature"
                      :key="index"
                      :disabled="item.isd"
                      >{{ item.name }}</el-checkbox
                    >
                  </div>
                </div>
              </template>
            </el-tree>
          </div>
        </div>
        <div class="buts">
          <div
            class="but"
            @click="save"
            v-showFunction="{ active: 'edit', array: allFunctionButtons }"
          >
            保存
          </div>
          <div
            class="but"
            @click="editCancel"
            v-showFunction="{ active: 'edit', array: allFunctionButtons }"
          >
            取消
          </div>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @makeSureBusinessRole="makeSureBusinessRole"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import * as departmentData from "./js/departmentData.js";
import my_dialog from "@/components/dialog.vue";
export default {
  components: {
    my_dialog,
  },
  data() {
    return {
      baseInfo: {},
      isLoading: true,
      flag: 0, // 0 表示部门详情  1 表示角色详情
      isHead: false, // 是否是头结点
      departList: [],
      userList: [], // 当前公司所有在职员工
      departmentNameFlag: false, // 是否编辑部门名称
      departmentDesFlag: false, // 是否编辑部门介绍

      inputVisibleManager: "", // 部门主管添加时标签标识
      inputValueManager: {}, // 添加部门主管时的临时变量
      inputVisibleStaff: "", // 部门主管添加时标签标识
      inputValueStaff: {}, // 添加部门主管时的临时变量

      department: departmentData.getDepartmentBaseInfo("峻岭集团"), // 部门详情数据
      role: departmentData.getDepartmentRoleInitData(this), // 角色详情数据

      dialogData: {
        flag: false,
        title: "",
        width: "30%",
        data: [],
        buttonArray: [],
      },
      allFunctionButtons: this.$featureData.getAllFunctionButtons("department"),
      editFlag: true,
    };
  },
  methods: {
    getModelFeatureAuthority() {
      this.$authority.getModelFeatureAuthority(
        this,
        "department",
        this.allFunctionButtons,
        (data) => {
          console.log("当前权限:", data);
          this.allFunctionButtons = data;
          for (let index = 0; index < data.length; index++) {
            let element = data[index];
            if (element.key == "edit") {
              this.editFlag = element.authority == 1 ? true : false;
            }
          }
          this.getEmployeeListForDownBox();
        }
      );
    },
    // 获取当前公司所有在职员工
    getEmployeeListForDownBox() {
      let requestData = {
        companyId: this.baseInfo.company.companyId, // 当前公司Id
      };
      this.$request(
        "post",
        "/user/getEmployeeListForDownBox",
        requestData,
        (data) => {
          this.userList = [];
          for (let index = 0; index < data.length; index++) {
            let element = data[index];
            let dd = {
              id: element.userId,
              name: element.userName,
            };
            this.userList.push(dd);
          }
          this.getDepartmentTree(true);
        }
      );
    },
    //  获取部门树
    getDepartmentTree(flag) {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/department/getDepartmentTree",
        requestData,
        (data) => {
          this.departList = [data];
          this.isLoading = false;
          if (flag) {
            this.showDepartmentDetail(data.id, data.lable);
          }
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 点击部门
     */
    showDetail(data, node, tree) {
      this.showDepartmentDetail(data.id, data.label);
    },
    // 显示部门详情
    showDepartmentDetail(id, name) {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        departmentId: id,
      };
      this.$request(
        "post",
        "/department/getDepartmentBaseInfo",
        requestData,
        (data) => {
          for (let index = 0; index < data.role.length; index++) {
            let element = data.role[index];
            this.$set(element, "flag", false);
            this.$set(element, "value", {});
          }
          this.department = data;
          this.flag = 0;
          console.log(this.department, "初始化");
        }
      );
    },
    // 部门主管/员工的移除事件
    handleClose(tag, array) {
      array.splice(array.indexOf(tag), 1);
    },
    // 部门主管/员工添加事件
    showTag(flag) {
      if (this.userList.length == 0) {
        this.$message.error("请联系管理员添加公司员工!");
      } else if (flag == 0) {
        this.inputValueManager = {};
        this.inputVisibleManager = true;
      } else {
        this.inputValueStaff = {};
        this.inputVisibleStaff = true;
      }
    },
    // 业务角色指定人员添加事件
    showTagObj(item) {
      console.log(item);
      if (this.userList.length == 0) {
        this.$message.error("请联系管理员添加公司员工!");
        return;
      }
      item.flag = true;
    },

    // 部门主管选择后的添加事件
    handleInputConfirmManger() {
      let inputValue = JSON.parse(JSON.stringify(this.inputValueManager));
      if (
        inputValue &&
        this.determineThe(this.department.manager, inputValue)
      ) {
        this.department.manager.push(inputValue);
      }
      this.inputVisibleManager = false;
      this.inputValueManager = {};
    },

    // 部门员工选择后的添加事件
    handleInputConfirmStaff() {
      let inputValue = JSON.parse(JSON.stringify(this.inputValueStaff));
      if (inputValue && this.determineThe(this.department.staff, inputValue)) {
        this.department.staff.push(inputValue);
      }
      this.inputVisibleStaff = false;
      this.inputValueStaff = {};
    },
    // 业务角色的指定人员添加事件
    handleInputConfirmStaffObj(item) {
      let inputValue = JSON.parse(JSON.stringify(item.value));
      if (inputValue && this.determineThe(item.user, inputValue)) {
        item.user.push(inputValue);
      }
      item.flag = false;
      item.value = {};
    },
    /**
     * 判断当前数组中是否有这个对象存在
     */
    determineThe(array, obj) {
      let flag = true;
      for (let index = 0; index < array.length; index++) {
        let element = array[index];
        if (obj.id == element.id) {
          flag = false;
        }
      }
      return flag;
    },
    /**
     * 添加部门
     */
    addDepartment() {
      this.dialogData.title = "添加部门";
      this.dialogData.buttonArray = [
        {
          text: "确定",
          active: "makesure_addDepartment",
          key: "add",
        },
        {
          text: "取消",
          active: "cancel",
          key: "cancel",
        },
      ];
      this.dialogData.data = [
        {
          type: "radio",
          title: "级别",
          value: 0,
          options: [
            { label: "兄弟部门", value: 0 },
            { label: "子部门", value: 1 },
          ],
        },
        {
          type: "radio",
          title: "部门类型",
          value: 0,
          options: [
            { label: "普通部门", value: 0 },
            { label: "分公司", value: 2 },
          ],
        },
        {
          type: "input",
          title: "部门名称",
          value: "",
          placeholder: "请输入部门名称",
        },
      ];
      this.dialogData.flag = true;
    },
    /**
     * 删除部门
     */
    deleteDepartment() {
      let requestData = {
        departmentId: this.department.id,
        companyId: this.baseInfo.company.companyId,
      };
      if (this.department.parentId == this.baseInfo.company.companyId) {
        // 最大部门  不可删除
        this.$message.error("该部门为总公司,不可删除!");
        return;
      }
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$request(
            "post",
            "/department/deleteDepartment",
            requestData,
            (data) => {
              this.$message.success("部门删除成功!");
              this.showDepartmentDetail(
                this.department.parentId,
                this.department.parentName
              );
              this.getDepartmentTree(false);
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    /**
     * 角色详情
     */
    // 查看当前角色的具体权限
    showRole(roleId, name) {
      // 调用接口获取数据
      this.role = {
        name: name,
        id: roleId,
        roleList: [],
      };
      this.getDepartmentRoleInitData(roleId);
    },
    getDepartmentRoleInitData(roleId) {
      let requestData = {
        roleId: roleId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/department/getDepartmentRoleInitData",
        requestData,
        (data) => {
          this.role.roleList = departmentData.formattFunctionData(
            data.roleList,
            this,
            true
          );
          console.log(this.role);
          this.flag = 1;
        }
      );
    },
    // 改变当前功能的权限 (switch)
    changeFunctionalAuthority(data, node) {
      console.log(data, node);
      if (node.isLeaf && data.show) {
        // 是叶子节点 并且开启模块权限 放开功能禁用
        data.feature.forEach((element) => {
          element.isd = false;
          element.authority = true;
        });
      } else if (node.isLeaf && !data.show) {
        data.feature.forEach((element) => {
          element.isd = true;
          element.authority = false;
        });
      }
      if (!data.show) {
        if (!node.isLeaf) {
          data.children = departmentData.firefoxSync(data.children);
        } else {
          data.feature = departmentData.firefoxSync(data.feature);
        }
      } else {
        // 模块节点权限开放,同时开放其直属父级节点
        node = departmentData.synchronizationTheParent(node);
      }
    },
    // 查看具体功能的属性
    showBaseOption(option) {
      console.log(option);
      // 调用接口获取数据
      let requestData = {
        roleId: this.role.id, // 当前角色ID
        companyId: this.baseInfo.company.companyId, // 当前公司ID
        modelId: option.modelId, // 当前模块
      };
      this.$request(
        "post",
        "/department/getDepartmentRoleOptionsInitData",
        requestData,
        (data) => {
          this.dialogData.title = option.name;
          this.dialogData.data = [
            {
              title: "",
              type: "role_authority",
              value: data,
              id: option.modelId,
            },
          ];
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "makesure_updete",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.flag = true;
        }
      );
    },
    // 修改属性dialog按钮点击事件
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "cancel":
          this.colseDialog();
          break;
        case "makesure_addDepartment":
          // 确定添加部门
          requestData = {
            fatherId:
              this.dialogData.data[0].value == 0
                ? this.department.parentId
                : this.department.id,
            name: this.dialogData.data[2].value,
            companyId: this.baseInfo.company.companyId,
            type: this.dialogData.data[1].value, // 0普通部门 2分公司
          };
          if (
            this.department.type == "1" &&
            this.dialogData.data[0].value == 0
          ) {
            // 最大部门  不可添加兄弟部门
            this.$message.error("不可添加总公司的兄弟部门!");
            return;
          }
          if (requestData.name == "") {
            this.$message.error("部门名称不能为空!");
            return;
          }
          this.$request(
            "post",
            "/department/addDepartment",
            requestData,
            (data) => {
              this.$message.success("部门添加成功!");
              this.showDepartmentDetail(data.departmentId, requestData.name);
              this.getDepartmentTree(false);
              this.dialogData.flag = false;
            }
          );
          break;
        case "makesure_updete":
          // 确定修改属性数据
          console.log("当前属性", this.dialogData.data[0].value);
          requestData = {
            modelId: this.dialogData.data[0].id,
            roleId: this.role.id,
            companyId: this.baseInfo.company.companyId, // 当前公司ID
            data: this.dialogData.data[0].value,
          };
          this.$request(
            "post",
            "/department/saveRoleOptions",
            requestData,
            (data) => {
              this.$message.success("当前属性修改成功!");
              this.dialogData.flag = false;
            }
          );
          break;
        case "makesure_chooseDepartmentRole":
          // 确定选择部门对应权限
          let id = this.dialogData.data[0].value;
          if (id == "") {
            this.$message.error("请先选择对应部门!");
            return;
          }
          this.getFatherDepartmentRoleId(id);
          break;
        default:
          break;
      }
    },
    // 选择具体部门的对应权限
    chooseDepartmentAuthority(flag) {
      let str = flag == 0 ? "上级" : "本公司任一";
      // this.$alert(
      //   "请选择" + str + "部门对应该角色,并将其权限复制!",
      //   "标题名称",
      //   {
      //     confirmButtonText: "确定",
      //     callback: (action) => {
      switch (flag) {
        case 0:
          // 上级部门
          this.getFatherDepartmentRoleId(this.department.parentId);
          break;
        case 1:
          // 任一部门
          this.dialogData.title = "选择部门";
          this.dialogData.data = [
            {
              type: "depertment_tree",
              title: "选择部门",
              value: "",
              options: this.departList,
            },
          ];
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "makesure_chooseDepartmentRole",
              key: "editRole",
            },
            {
              text: "取消",
              active: "cancel",
              key: "cancel",
            },
          ];
          this.dialogData.flag = true;
          break;

        default:
          break;
      }
      //     },
      //   }
      // );
    },
    // 4.5 获取当前角色的部门，返回指定部门的对应当前角色ID
    getFatherDepartmentRoleId(departmentId) {
      let id = departmentId[departmentId.length - 1];
      let requestData = {
        departmentId: id,
        roleId: this.role.id,
        companyId: this.baseInfo.company.companyId,
      };
      this.$confirm("此操作将复制所选部门的对应权限, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$request(
            "post",
            "/featureRoleRel/copyPower",
            requestData,
            (data) => {
              this.getDepartmentRoleInitData(this.role.id);
              this.$message.success("权限复制成功!");
              this.isLoading = false;
              this.dialogData.flag = false;
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    // 关闭dialog
    colseDialog() {
      this.dialogData.flag = false;
    },

    // 保存
    save() {
      let requestData = {};
      this.isLoading = true;

      switch (this.flag) {
        case 0:
          // 部门详细信息的保存
          console.log("当前部门详细信息:", this.department);
          requestData = this.department;
          console.log("requestData", requestData);
          this.$set(requestData, "companyId", this.baseInfo.company.companyId);
          this.$request(
            "post",
            "/department/saveDepartmentInfoAndRolesInfo",
            requestData,
            (data) => {
              this.showDepartmentDetail(
                this.department.id,
                this.department.name
              );
              this.$message.success("部门基本信息修改成功!");
              this.isLoading = false;
            },
            (err) => {
              this.$message.error("配置失败!");
              this.isLoading = false;
            }
          );
          break;
        case 1:
          // 部门角色(包括业务角色信息的保存)
          let roleData = departmentData.formattSubmitFunctionData(
            JSON.parse(JSON.stringify(this.role)).roleList,
            this
          );
          console.log("当前部门角色功能权限:", this.role);
          console.log("当前部门角色功能权限2:", roleData);
          let roleList = departmentData.turnArrayObject(roleData, []);
          console.log("当前部门角色功能权限3:", roleList);

          requestData = {
            id: this.role.id, // 当前角色ID
            roleList: roleList,
          };
          this.$request(
            "post",
            "/department/saveRoleFeature",
            requestData,
            (data) => {
              this.getDepartmentRoleInitData(this.role.id);
              this.$message.success("当前角色基本权限修改成功!");
              this.isLoading = false;
            },
            (err) => {
              this.$message.error("配置失败!");
              this.isLoading = false;
            }
          );
          break;

        default:
          break;
      }
    },

    // 取消
    editCancel() {
      this.flag = 0; // 回到部门详情界面,并且刷新部门详情数据
    },

    // 业务角色
    businessRole() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/role/getBusinessAuthorityList",
        requestData,
        (data) => {
          this.dialogData.title = "系统业务角色";
          this.dialogData.width = "30%";
          this.dialogData.buttonArray = [];
          data.forEach((element) => {
            let flag = false;
            if (
              element.authorityName == "服务顾问" ||
              element.authorityName == "客户经理" ||
              element.authorityName == "收费负责人" ||
              element.authorityName == "开票员" ||
              element.authorityName == "合同审核员" ||
              element.authorityName == "出纳"
            ) {
              flag = true;
            }
            this.$set(element, "flag", flag);
          });
          this.dialogData.data = [
            {
              title: "",
              type: "business-role",
              value: data,
            },
          ];
          this.dialogData.flag = true;
        },
        (err) => {
          this.$message.error("获取业务角色失败!");
          this.isLoading = false;
        }
      );
    },
    /**
     * 编辑业务角色
     */
    makeSureBusinessRole(value) {
      console.log(value);
      let requestData = {};
      switch (value.title) {
        case "编辑业务角色":
          this.isLoading = true;
          requestData = {
            name: value.data,
            roleId: value.id,
            companyId: this.baseInfo.company.companyId,
          };
          if (requestData.name == "") {
            this.$message.error("业务角色名称不能为空!");
            return;
          }
          this.$request(
            "post",
            "/role/updateBusinessRole",
            requestData,
            (data) => {
              this.$message.success("编辑成功!");
              value.flag = false;
              this.businessRole();
              this.isLoading = false;
            },
            (err) => {
              this.$message.error("配置失败!");
              value.flag = false;
              this.dialogData.flag = false;
              this.isLoading = false;
            }
          );
          break;
        case "添加业务角色":
          this.isLoading = true;
          requestData = {
            roleName: value.data,
            companyId: this.baseInfo.company.companyId,
          };
          if (requestData.roleName == "") {
            this.$message.error("业务角色名称不能为空!");
            return;
          }
          this.$request(
            "post",
            "/department/addDepartmentRole",
            requestData,
            (data) => {
              this.$message.success("添加成功!");
              value.flag = false;
              this.businessRole();
              this.isLoading = false;
            },
            (err) => {
              this.$message.error("配置失败!");
              value.flag = false;
              this.dialogData.flag = false;
              this.isLoading = false;
            }
          );
          break;
        case "删除业务角色":
          this.isLoading = true;
          this.$confirm("此操作将所有客户中的相关业务角色, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              requestData = {
                roleId: value.id,
                companyId: this.baseInfo.company.companyId,
              };
              this.$request(
                "post",
                "/department/delDepartmentRole",
                requestData,
                (data) => {
                  this.$message.success("删除成功!");
                  value.flag = false;
                  this.businessRole();
                  this.isLoading = false;
                },
                (err) => {
                  this.$message.error("配置失败!");
                  value.flag = false;
                  this.dialogData.flag = false;
                  this.isLoading = false;
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });

          break;

        default:
          break;
      }
    },
  },
  mounted() {},
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#department_details {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #efefef;
  > .content {
    width: 98%;
    height: 95%;
    max-width: 90vw;
    max-height: 900px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
    display: flex;
    > .left {
      width: 15%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 5px;
      box-sizing: border-box;
      border-right: solid 1px @borderColor;
      > .tree {
        width: 100%;
        height: 0;
        flex: 1;
        overflow-y: auto;
      }
    }
    > .right {
      width: 0;
      flex: 1;
      height: 100%;
      padding: 5px 15px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      > .content {
        width: 100%;
        height: 0;
        flex: 1;
        display: flex;
        flex-direction: column;
        > .line {
          width: 100%;
          height: auto;
          margin: 13px 0;
          display: flex;
          > .title {
            width: 8%;
            height: auto;
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            font-size: 15px;
            font-weight: bolder;
          }
          > .value {
            width: 0;
            flex: 1;
            height: 100%;
            margin-left: 10px;
            display: flex;
            font-size: 15px;
            align-items: flex-start;
            justify-content: flex-start;
            > i {
              color: @baseColor;
              margin-left: 5px;
              transition: 0.3s;
              &:hover {
                cursor: pointer;
                color: #ff6804;
              }
            }
            > .but {
              width: auto;
              height: auto;
              padding: 2px 15px;
              box-sizing: border-box;
              border: 1px solid #3fc5ff;
              color: #3fc5ff;
              font-size: 12px;
              border-radius: 5px;
              margin: 0 10px;
              transition: 0.3s;
              &:hover {
                cursor: pointer;
                color: #ff6804;
                border-color: #ff6804;
              }
            }
          }
          > .buts {
            width: auto;
            height: 100%;
            display: flex;
            > .but {
              width: auto;
              height: auto;
              padding: 8px 10px;
              box-sizing: border-box;
              font-size: 0.8vw;
              transition: 0.3s;
              cursor: pointer;
              > i {
                margin-right: 5px;
              }
            }
          }
          > .users {
            display: flex;
            flex-direction: column;
            > .user {
              width: 100%;
              height: auto;
              display: flex;
              flex-wrap: wrap;
            }
            > .role {
              font-size: 13px;
              margin-top: 15px;
              color: @baseColor;
              transition: 0.3s;
              &:hover {
                cursor: pointer;
                color: #ff6804;
              }
            }
          }
          > .role {
            display: flex;
            flex-direction: column;
            > .tip {
              width: 100%;
              height: 30px;
              display: flex;
              > span {
                font-size: 13px;
                width: auto;
                height: 100%;
                display: flex;
                box-sizing: border-box;
                padding: 0 10px;
                color: #ff6804;
                justify-content: flex-end;
                align-items: center;
              }
              > .but {
                width: auto;
                height: auto;
                padding: 5px 15px;
                box-sizing: border-box;
                border: 1px solid @baseColor;
                border-radius: 5px;
                color: @baseColor;
                transition: 0.3s;
                font-size: 13px;
                margin-left: 20px;
                > i {
                  margin-right: 5px;
                }
                &:hover {
                  cursor: pointer;
                  background-color: @baseColor;
                  color: #ffffff;
                }
              }
            }
            > .content {
              width: 60%;
              height: 30vh;
              // min-height: 30vh;
              overflow-y: auto;
              display: flex;
              flex-direction: column;
              .role {
                width: 100%;
                height: auto;
                display: flex;
                margin-top: 20px;
                > .title {
                  width: 15%;
                  height: 100%;
                  display: flex;
                  align-items: flex-start;
                  justify-content: flex-end;
                  color: @baseColor;
                  transition: 0.3s;
                  &:hover {
                    // cursor: pointer;
                    // color: #ff6804;
                    // text-decoration: underline;
                  }
                }
                > .value {
                  width: 0;
                  flex: 1;
                  height: auto;
                  display: flex;
                  flex-direction: column;
                  margin-left: 30px;
                  > .radio {
                    width: 100%;
                    height: auto;
                    display: flex;
                  }
                  > .user {
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 15px;
                  }
                }
              }
            }
          }
        }
        > .role_content {
          width: 95%;
          height: 0;
          flex: 1;
          overflow-y: auto;
          // background-color: #ff6804;
          margin-left: 10px;
          .role_tree {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            > .line {
              width: 100%;
              height: 45px;
              display: flex;
              > .title {
                width: auto;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                font-size: 14px;
                font-weight: bolder;
                margin-right: 5px;
              }
              > .sw {
                width: 0;
                height: 100%;
                flex: 1;
                display: flex;
                align-items: center;
                margin-left: 10px;
              }
              > .option {
                width: auto;
                height: 100%;
                display: flex;
                align-items: flex-end;
                padding: 0 15px;
                box-sizing: border-box;
                font-size: 12px;
                color: #40a3ff;
                transition: 0.3s;
                &:hover {
                  cursor: pointer;
                  color: #ff6804;
                  text-decoration: underline;
                }
              }
            }
            > .authority {
              width: 100%;
              height: auto;
              display: flex;
              flex-wrap: wrap;
              margin-top: 20px;
              margin-bottom: 20px;
              .item {
                margin-right: 30px;
                height: 35px;
              }
            }
          }
        }
      }
      > .buts {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 5px 0;
        > .but {
          width: auto;
          height: auto;
          padding: 8px 20px;
          margin: 0 20px;
          box-sizing: border-box;
          background-color: @baseColor;
          border-radius: 8px;
          color: #ffffff;
          font-size: 0.6vw;
          transition: 0.3s;
          &:hover {
            cursor: pointer;
            background-color: @but-hoverColor;
          }
          > i {
            margin-right: 5px;
          }
        }
      }
    }
  }
  .primary {
    color: #40a3ff;
    &:hover {
      color: #ff6804;
    }
  }
  .danger {
    color: #f5523c;
    &:hover {
      color: #ff1e00;
    }
  }
  .success {
    color: #5cb8be;
    &:hover {
      color: #ff1e00;
    }
  }
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
}
</style>
