<template>
  <div id="contract-invoice">
    <!-- 发票审核 -->
    <div class="screen">
      <div class="screen-line">
        <div class="contract-invoice-screen-search">
          <el-input
            placeholder="请输入客户全称/编号/税号"
            v-model="searchData.input"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="contract-invoice-screen-search">
          <el-input
            placeholder="请输入购买方名称"
            v-model="searchData.buyerName"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="contract-invoice-screen-search">
          <el-select
            v-model="searchData.invoiceType"
            placeholder="请输入发票类型"
            @change="initTableData"
          >
            <el-option
              v-for="item in invoiceType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="contract-invoice-screen-search">
          <el-select
            v-model="searchData.userId"
            placeholder="请选择员工"
            filterable
            @change="selectInitTableData"
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="contract-invoice-screen-search">
          <el-select
            v-model="searchData.submitUserId"
            placeholder="请选择提交人"
            filterable
            @change="selectInitTableData"
          >
            <el-option
              v-for="item in searchData.allUserList"
              :key="item.userId"
              :label="item.userName"
              :value="item.userId"
            ></el-option>
          </el-select>
        </div>
        <div class="contract-invoice-screen-search">
          <el-select
            v-model="searchData.checkerId"
            placeholder="请选择审核人"
            filterable
            @change="selectInitTableData"
          >
            <el-option
              v-for="item in searchData.allUserList"
              :key="item.userId"
              :label="item.userName"
              :value="item.userId"
            ></el-option>
          </el-select>
        </div>

        <div class="contract-invoice-screen-active"></div>
      </div>
      <div class="screen-line">
        <div class="contract-invoice-screen-search">
          <el-date-picker
            style="width: 210px"
            v-model="searchData.time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="initTableData"
          >
          </el-date-picker>
        </div>
      </div>
    </div>

    <div class="table-content">
      <div class="contract-invoice-table-card">
        <div class="contract-invoice-table-card-tabs">
          <div
            :class="{
              'contract-invoice-table-card-tab': true,
              'is-active': tabIndex == 0,
            }"
            @click="changeTab(['0'])"
          >
            待提交
          </div>
          <div
            :class="{
              'contract-invoice-table-card-tab': true,
              'is-active': tabIndex == 1,
            }"
            @click="changeTab(['1'])"
          >
            待审核
          </div>
          <div
            :class="{
              'contract-invoice-table-card-tab': true,
              'is-active': tabIndex == 2,
            }"
            @click="changeTab(['2'])"
          >
            已完成
          </div>
          <div
            :class="{
              'contract-invoice-table-card-tab': true,
              'is-active': tabIndex == 3,
            }"
            @click="changeTab(['3'])"
          >
            推送中
          </div>
          <div
            :class="{
              'contract-invoice-table-card-tab': true,
              'is-active': tabIndex == 4,
            }"
            @click="changeTab(['4', '5', '6'])"
          >
            其他
          </div>
          <div class="contract-invoice-table-card-active">
            <div class="textArea">
              <!-- 在开票机安装提票工具,实时自动提取销项发票,无需在手工提取。 -->
            </div>
          </div>
        </div>
        <div class="contract-invoice-content">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            height="98%"
            :row-style="{ height: '65px' }"
            @row-click="tableRowClick"
            v-loading="loading"
          >
            <el-table-column
              type="index"
              width="50"
              label="序号"
              fixed
              align="center"
            ></el-table-column>
            <el-table-column label="客户全称" width="240" show-overflow-tooltip>
              <template slot-scope="scope">
                <i
                  v-if="
                    scope.row.isUrgent &&
                      (scope.row.status == '待审核' ||
                        scope.row.status == '待提交')
                  "
                  class="iconfont icon-jiaji"
                ></i>
                {{ scope.row.clientName }}
              </template>
            </el-table-column>
            <el-table-column
              prop="clientNum"
              label="客户编号"
              width="100"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="status"
              label="状态"
              width="80"
            ></el-table-column>
            <el-table-column prop="type" label="种类" width="120">
              <template slot-scope="scope">
                <el-tooltip
                  :content="scope.row.pushFailedInfo"
                  placement="top"
                  :disabled="!scope.row.pushFailedInfo"
                >
                  <span> </span>
                  <span
                    :style="{
                      fontWeight: scope.row.weight,
                      color: scope.row.color,
                    }"
                  >
                    <i v-if="scope.row.isPush" class="el-icon-s-promotion"></i>
                    {{ scope.row.type }}
                  </span>
                </el-tooltip>
              </template>
            </el-table-column>

            <el-table-column
              prop="totalAmount"
              label="金额"
              width="60"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="buyerName"
              label="购买方"
              width="220"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column prop="submitUserName" label="提交人">
            </el-table-column>
            <el-table-column
              prop="checkerName"
              label="审核人"
            ></el-table-column>
            <el-table-column prop="submitDate" label="提交日期">
              <template v-if="scope.row.status == '待审核'" slot-scope="scope">
                {{ scope.row.submitDate }}
              </template>
            </el-table-column>
            <el-table-column
              prop="billingDate"
              label="开票日期"
            ></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  v-if="
                    scope.row.status == '待提交' || scope.row.status == '待审核'
                  "
                  @click.native.stop="deleteINfo(scope.row)"
                  type="text"
                  v-showFunction="{
                    active: 'delete',
                    array: allFunctionButtons,
                  }"
                  >删除</el-button
                >
                <el-button
                  v-if="
                    scope.row.status == '已完成' &&
                      scope.row.pushStatus &&
                      (scope.row.type == '电子普票' ||
                        scope.row.type == '电子专票')
                  "
                  @click.native.stop="downLoad(scope.row, 'PDF')"
                  type="text"
                  v-showFunction="{
                    active: 'pdf',
                    array: allFunctionButtons,
                  }"
                  >下载PDF</el-button
                >
                <el-button
                  v-if="
                    scope.row.status == '已完成' &&
                      scope.row.pushStatus &&
                      (scope.row.type == '电子普票' ||
                        scope.row.type == '电子专票')
                  "
                  @click.native.stop="downLoad(scope.row, 'OFD')"
                  type="text"
                  v-showFunction="{
                    active: 'ofd',
                    array: allFunctionButtons,
                  }"
                  >下载OFD</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="contract-invoice-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
    <!-- 拆分推送 -->
    <el-dialog
      :visible.sync="splitFlag"
      width="70%"
      top="20vh"
      @closed="closed"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <splitPush
        v-if="splitFlag"
        :splitData="dialogData.data"
        @cancel="closed"
        @confirm="confirm(arguments)"
      ></splitPush>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogFlag"
      width="20%"
      top="35vh"
      @close="closeDialog2"
    >
      <div style="margin-bottom: 5px;">
        提示: 本次预计开票{{ invoiceSheet }}张
      </div>
      <div>
        备注:
        <el-input
          style="margin-top: 5px;"
          type="textarea"
          v-model="remark"
          maxlength="40"
          show-word-limit
          autosize
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="closeDialog2">取 消</el-button>
        <el-button size="mini" type="primary" @click="drawerOper('confirm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as invoiceData from "@/assets/js/invoiceData.js";
import my_drawer from "@/components/my-drawer.vue";
import my_dialog from "@/components/dialog.vue";
import splitPush from "@/components/splitPush";
export default {
  components: {
    my_drawer,
    my_dialog,
    splitPush,
  },
  data() {
    return {
      flag: false,
      curLimitAmount: 0, // 当前发票预计开票张数
      dialogFlag: false, // dialog显示隐藏
      baseInfo: {},
      loading: true,
      whetherAgencyHead: false,
      searchData: {
        time: [],
        input: "",
        checked: false,
        screenButton_add: 0,
        screenButton_improt: 0,
        userId: "", // 员工id
        userList: [], // 员工列表
        userIdList: [], // 全部员工id
        tempUserId: [], // 员工id
        submitUserId: "", // 提交人
        checkerId: "", // 审核人
        allUserList: [], // 所有员工列表
        buyerName: "", //购买方名称
        invoiceType: "", // 发票类型
      },
      status: ["0"], // 发票状态
      tabIndex: 0,
      tableData: [],
      clientList: [],
      dialogData: {
        flag: false,
        width: "30%",
        title: "添加跟进记录",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },

      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      tempInvoice: {},
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "ContractInvoice"
      ),
      splitFlag: false, // 拆分推送 dialog
      disableList: {
        // 开票禁用
        flag1: false,
        flag2: false,
        flag3: false,
        flag4: false,
      },
      invoiceSheet: "", // 开票张数
      remark: "", // 发票备注
      invoiceType: [
        {
          label: "电子普票",
          value: "026",
        },
        {
          label: "电子专票",
          value: "028",
        },
        {
          label: "纸质普票",
          value: "007",
        },
        {
          label: "纸质专票",
          value: "004",
        },
      ],
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "ContractInvoice",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          // this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 获取所有人员列表
     */
    getEmployeeListForDownBox() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/user/getEmployeeListForDownBox",
        requestData,
        (data) => {
          this.searchData.allUserList = data;
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getEmployeeListForDownBox();
        // this.getEmployeeList();
      });
    },
    /**
     * 员工选择
     */
    selectInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.searchData.userIdList;
      } else {
        this.searchData.tempUserId = [val];
      }

      this.initTableData();
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      this.loading = true;
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
        search: this.searchData.input, // 客户名称 当客户名称为空时,获取所有客户
        time: this.searchData.time, // 开票月份
        currentPage: this.pageData.currentPage, // 当前页数 20210907 改
        pageSize: this.pageData.pageSize, // 每页条数 20210907 改
        status: this.status, // 发票状态
        userIdList: this.searchData.tempUserId, // 员工 id
        submitUserId: this.searchData.submitUserId, // 提交人
        checkerId: this.searchData.checkerId, // 审核人
        buyerName: this.searchData.buyerName, // 购买方名称
        type: this.searchData.invoiceType, // 发票类型
      };
      this.$request(
        "post",
        "/invoiceInfo/listByStatusToContract",
        requestData,
        (data) => {
          this.pageData.total = data.total;
          data.records.forEach((item) => {
            item.status == "0"
              ? (item.status = "待提交")
              : item.status == "1"
              ? (item.status = "待审核")
              : item.status == "2"
              ? (item.status = "已完成")
              : item.status == "3"
              ? (item.status = "推送中")
              : item.status == "4"
              ? (item.status = "已作废")
              : item.status == "5"
              ? (item.status = "已红冲(正)")
              : (item.status = "已红冲(负)");
            if (item.type == "004") {
              item.type = "纸质专票";
              item.color = "#f9a437";
              item.weight = 700;
            } else if (item.type == "007") {
              item.type = "纸质普票";
              item.color = "#f9a437";
            } else if (item.type == "026") {
              item.type = "电子普票";
              item.color = "#35c07d";
            } else {
              item.type = "电子专票";
              item.color = "#35c07d";
              item.weight = 700;
            }
            if (item.status == "已完成") {
              if (item.isPush) {
                item.pushStatus == 0
                  ? (item.color = "#d92424")
                  : (item.color = "#35c07d");
              }
            }
            // item.type == "004"
            //   ? (item.type = "纸质专票")
            //   : item.type == "007"
            //   ? (item.type = "纸质普票")
            //   : item.type == "026"
            //   ? (item.type = "电子普票")
            //   : (item.type = "电子专票");
          });
          this.tableData = data.records;
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },

    /**
     * 表格行点击事件
     */
    tableRowClick(row, colum, event) {
      this.tempInvoice = row;
      this.$request(
        "post",
        "/invoiceConf/getQuotaByClientId",
        { clientId: row.clientId },
        (res) => {
          this.$request(
            "get",
            `/invoice/getInvoice?id=${row.id}`,
            {},
            (redata) => {
              this.dialogData.title = "发票预览";
              this.dialogData.width = "1300px";
              this.dialogData.buttonArray = [];
              if (row.status == "待提交") {
                this.dialogData.buttonArray.push({
                  text: "编辑",
                  active: "edit",
                });
              }
              if (row.status == "待审核") {
                this.dialogData.buttonArray.push({
                  text: "开票",
                  active: "makeout",
                });
              }
              // if (row.status == "已完成" && !redata.isRedFlush) {
              //   this.dialogData.buttonArray.push({
              //     text: "作废",
              //     active: "cancellation",
              //   });
              // }
              // if (
              //   (row.status == "已完成" &&
              //     redata.isPush == 0 &&
              //     redata.isRedFlush) ||
              //   (row.status == "已完成" &&
              //     redata.isPush &&
              //     redata.isRedFlush &&
              //     redata.pushStatus)
              // ) {
              //   this.dialogData.buttonArray.push({
              //     text: "红冲",
              //     active: "red",
              //   });
              // }
              this.dialogData.buttonArray.push({
                text: "取消",
                active: "cancel",
              });
              this.tempInvoice = JSON.parse(JSON.stringify(redata));
              this.dialogData.data = [
                {
                  type: "invoice",
                  title: "",
                  data: invoiceData.getInvoiveDetails(redata),
                },
              ];
              this.dialogData.flag = true;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * tab切换事件
     */
    changeTab(index) {
      this.tabIndex = index[0];
      this.status = [];
      this.status = index;
      this.initTableData();
    },
    /**
     * 弹出框操作事件
     */
    drawerOper(active) {
      let that = this;
      switch (active) {
        case "makeout":
          let curLimitAmount = this.dialogData.data[0].data.invoiceSheet || 0;
          this.$prompt(`请输入开票张数(最小为${curLimitAmount})`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
          })
            .then(({ value }) => {
              console.log(value);
              if (isNaN(value)) {
                this.$message.error("只能输入数字");
                return;
              }
              if (value == null || value.trim() == "") {
                this.$message.error("请输入开票张数");
                return;
              }
              if (value < curLimitAmount) {
                this.$message({
                  type: "info",
                  message: `最小开票张数不能小于${curLimitAmount}张`,
                });
                return;
              }
              this.makeOutInvoice(value);
              this.dialogData.flag = false;
              this.initTableData();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
          break;
        case "push":
          // 推送开具

          if (
            this.dialogData.data[0].data.LimitAmount * 10000 >
            +this.dialogData.data[0].data.allMoney
          ) {
            // 判断是否大于限额
            this.dialogData.data[0].data.loadingBtn = true;

            let requestData = {
              ...this.dialogData.data[0].data,
              inOrOut: "1",
            };
            this.$request(
              "post",
              `/invoiceInfo/push?userId=${this.baseInfo.userId}`,
              requestData,
              (data) => {
                this.dialogData.data[0].data.loadingBtn = false;
                this.dialogData.flag = false;
                this.$message.success("推送成功");
                this.initTableData();
              },
              (errorMessage) => {
                this.dialogData.data[0].data.loadingBtn = false;
                this.$message.error(errorMessage);
              }
            );
          } else {
            this.$message.error("发票金额大于限额,无法推送!");
          }
          break;
        case "split_push":
          if (this.dialogData.data[0].data.itemList.length > 1) {
            this.$message.error("商品种类大于1,无法推送!");
            return;
          }

          if (
            +this.dialogData.data[0].data.itemList[0].unitPrice >
            this.dialogData.data[0].data.LimitAmount * 10000
          ) {
            this.$message.error("商品单价不可大于限额!");
            return;
          }
          this.splitFlag = true;

          break;
        case "cancellation":
          // 作废
          this.$confirm("确认将该发票作废?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.calcellationInvoice();
              this.dialogData.flag = false;
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
          break;
        case "cancel":
          // 取消
          this.dialogData.flag = false;
          break;
        case "red":
          // 发票红冲
          this.$confirm("确认将该发票红冲吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              let requestData = {
                id: this.tempInvoice.id,
                userId: this.baseInfo.userId,
              };
              this.$request(
                "post",
                "/invoiceInfo/redFlush",
                requestData,
                (data) => {
                  this.$message({
                    message: "发票已红冲!",
                    type: "success",
                    duration: 1000,
                  });
                  this.initTableData();
                  this.dialogData.flag = false;
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {});

          break;
        case "edit":
          let requestData = {
            clientId: that.tempInvoice.clientId,
          };
          that.$request(
            "post",
            "/invoiceConf/getSurplusByClientId",
            requestData,
            (data) => {
              // 开票
              /**
               * 1. 根据客户名称,默认填充:分机号及状态,库存,单张开票限额,销售方名称,纳税人识别号,地址,电话,开户行及账号等信息,提供客户类型(是小规模纳税人还是一般纳税人)
               * 2. 选择开票类型:电子普通发票开具、电子专用发票开具、纸质普通发票开具、纸质专用发票开具
               *    注:纸质发票中没有收票人手机和收票人邮箱
               * 3. 填写购买方信息(必填):名称,纳税人识别号,地址,电话,开户行及账号
               *    填写货物或应税劳务、服务名称(必填),金额(必填),税率(根据客户类型选择税率)(必填),税额(计算)(必填)
               * 4. 填写收款人,复核人,开票人,
               * 5. 填写收票人手机,收票人邮箱
               */

              if (
                (data.specialTicketPaperNum == 0 ||
                  data.specialTicketPaperNum == "0") &&
                (data.commonTicketPaperNum == 0 ||
                  data.commonTicketPaperNum == "0") &&
                (data.commonTicketElectronicNum == 0 ||
                  data.commonTicketElectronicNum == "0") &&
                (data.specialTicketElectronicNum == 0 ||
                  data.specialTicketElectronicNum == "0")
              ) {
                that.$message({
                  message: "库存不足!",
                  type: "error",
                  duration: 1000,
                });
                return;
              }
              that.disableList.flag1 = false;
              that.disableList.flag2 = false;
              that.disableList.flag3 = false;
              that.disableList.flag4 = false;
              if (
                data.specialTicketPaperNum == 0 ||
                data.specialTicketPaperNum == "0"
              ) {
                that.disableList.flag4 = true; // 纸质专票
              }
              if (
                data.commonTicketPaperNum == 0 ||
                data.commonTicketPaperNum == "0"
              ) {
                that.disableList.flag3 = true; // 纸质普票
              }
              if (
                data.commonTicketElectronicNum == 0 ||
                data.commonTicketElectronicNum == "0"
              ) {
                that.disableList.flag1 = true; // 电子普票
              }
              if (
                data.specialTicketElectronicNum == 0 ||
                data.specialTicketElectronicNum == "0"
              ) {
                that.disableList.flag2 = true; // 电子专票
              }
              that.$request(
                "post",
                "/client/getClientInfoByClientID",
                { id: that.tempInvoice.clientId },
                (clientData) => {
                  that.dialogData.width = "1300px";
                  that.dialogData.title = "发票开具";
                  that.dialogData.buttonArray = [
                    // {
                    //   text: "提交",
                    //   active: "submit",
                    // },
                    {
                      text: "仅保存",
                      active: "save",
                    },
                    {
                      text: "取消",
                      active: "cancel",
                    },
                  ];
                  that.$set(clientData, "clientId", that.tempInvoice.clientId);
                  let requestData2 = {
                    clientId: that.tempInvoice.clientId,
                  };
                  that.$request(
                    "post",
                    "/invoiceConf/getQuotaByClientId",
                    requestData2,
                    (data) => {
                      console.log("ddd", that.tempInvoice);
                      that.dialogData.data = [
                        {
                          type: "invoice_dialog",
                          title: "",
                          data: invoiceData.getInvoiceDialogData(
                            clientData,
                            that.baseInfo.company.companyId,
                            that.tempInvoice,
                            true,
                            that.disableList,
                            data
                          ),
                        },
                      ];
                      console.log("发票开具dialog显示:", that.dialogData.data);
                      that.dialogData.flag = true;
                    }
                  );
                },
                (errorMessage) => {
                  that.$message.error(errorMessage);
                }
              );
            },
            (errorMessage) => {
              that.$message.error(errorMessage);
            }
          );
          return;
        case "submit":
          // 计算发票数量 (总价税 - 税价) % (发票限额 * 10000)
          that.invoiceSheet =
            Math.ceil(
              (that.dialogData.data[0].data.totalAmount -
                that.dialogData.data[0].data.allTax) /
                (that.dialogData.data[0].data.LimitAmount * 10000)
            ) || 0;
          that.dialogFlag = true;
          break;
        case "confirm":
          // 确认开票
          let submitRemark = that.remark;
          that.saveInvoiceDetails().then(function(resolveData) {
            that.$request(
              "post",
              "/invoiceInfo/submitInvoice",
              {
                id: that.tempInvoice.id, // 	发票id
                submitRemark: submitRemark, // 备注
                submitUserId: that.baseInfo.userId, // 提交人
              },
              (data) => {
                that.$message({
                  type: "success",
                  message: "发票提交成功!",
                  duration: 1000,
                });
                that.closeDialog2();
                that.initTableData();
              },
              (errorMessage) => {
                that.$message.error(errorMessage);
              }
            );
          });
          break;
        case "save":
          // 发票保存
          this.saveInvoiceDetails().then(() => {
            this.$message({
              type: "success",
              message: "发票保存成功!",
              duration: 1000,
            });
            that.initTableData();
          });
          break;
          break;
        default:
          break;
      }
    },
    /**
     * 发票数量查询
     */
    CheckInvoiceQuantity() {},
    /**
     * 保存发票
     */
    saveInvoiceDetails() {
      let that = this;
      let p = new Promise(function(resolve, reject) {
        let requestData = {
          ...that.dialogData.data[0].data,
          createUserId: that.baseInfo.userId, // 创建人 id
          inOrOut: "1",
        };
        that.$request(
          "post",
          "/invoiceInfo/updateInvoice",
          requestData,
          (data) => {
            if (!data.flag) {
              // 当前客户不是常用客户,提示是否添加进常用客户
              that
                .$confirm("是否将此客戶添加进常用客戶?", "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                })
                .then(() => {
                  // 添加常用客户
                  let customersData = {
                    id: that.tempInvoice.id, // id
                    name: requestData.buyerName, // 名称,
                    clientId: requestData.clientId, // 客户ID,
                    taxNum: requestData.buyerTaxpayerNum, // 企业税号,
                    tel: requestData.buyerTel, // 电话,
                    bank: requestData.buyerBankName, // 开户行,
                    bankAccount: requestData.buyerBankAccount, // 银行账号,
                    address: requestData.buyerAddress, // 详细地址,
                  };
                  that.$request(
                    "post",
                    "/activeCustomer/addCustomers",
                    customersData,
                    (dd) => {
                      that.$message({
                        type: "success",
                        message: "常用客户添加成功!",
                        duration: 1000,
                      });
                      that.dialogData.flag = false;
                    },
                    (errorMessage) => {
                      that.$message.error(errorMessage);
                    }
                  );
                })
                .catch(() => {
                  that.dialogData.flag = false;
                });
            } else {
              that.dialogData.flag = false;
            }
            resolve(true);
          },
          (errorMessage) => {
            reject(errorMessage);
            that.$message.error(errorMessage);
          }
        );
      });
      return p;
    },

    /**
     * 表格操作事件
     */
    tableClick(data, flag) {
      let requestData = {};
      this.tempInvoice = data;
      switch (flag) {
        case 0:
          // 开票
          this.$confirm("确认开票?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.makeOutInvoice();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
          break;
        case 1:
          // 作废
          this.$confirm("确认将发票" + data.num + "作废?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.calcellationInvoice();
              this.initTableData();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
          break;
        case 2:
          // 发票预览
          console.log("发票详细信息:", data);
          requestData = {
            id: data.id, // 具体发票的ID
          };
          this.$request(
            "post",
            "/invoice/getInvoiceDetails",
            requestData,
            (redata) => {
              this.dialogData.title = "发票预览";
              this.dialogData.width = "1300px";
              this.dialogData.buttonArray = [];
              this.dialogData.buttonArray.push({
                text: "取消",
                active: "cancel",
              });
              this.tempInvoice = JSON.parse(JSON.stringify(redata));
              this.dialogData.data = [
                {
                  type: "invoice",
                  title: "",
                  data: invoiceData.getInvoiveDetails(redata),
                },
              ];
              this.dialogData.flag = true;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        default:
          break;
      }
    },
    /**
     * 开票
     */
    makeOutInvoice(value) {
      let requestData = {
        id: this.tempInvoice.id,
        checkerId: this.baseInfo.userId,
        numberOf: value,
      };
      this.$request(
        "post",
        "/invoiceInfo/makeOutInvoice",
        requestData,
        (data) => {
          this.initTableData();
          this.$message({
            message: "开票成功!",
            type: "success",
            duration: 1000,
          });
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 开票作废
     */
    calcellationInvoice() {
      let requestData = {
        id: this.tempInvoice.id, // 发票id
        checkerId: this.baseInfo.userId, // 审核人id
      };
      this.$request(
        "post",
        "/invoiceInfo/voidInvoice",
        requestData,
        (data) => {
          this.$message({
            message: "发票已作废!",
            type: "success",
            duration: 1000,
          });
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 解锁
     */
    // unlock(e) {
    //   this.$request(
    //     "post",
    //     "/invoiceInfo/unlockById",
    //     { id: this.tempInvoice.id },
    //     (data) => {},
    //     (errorMessage) => {
    //       this.$message.error(errorMessage);
    //     }
    //   );
    //   // e = e || window.event;
    //   // if (e) {
    //   //   e.returnValue = "关闭提示";
    //   // }
    //   // return "关闭提示";
    // },
    /**
     * 弹出框关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    /**
     * 拆分推送dialog关闭
     */
    closed() {
      this.splitFlag = false;
    },
    /**
     * 拆分推送
     */
    confirm(e) {
      // if (+this.dialogData.data[0].data.allMoney !== +e[1]) {
      //   this.$message.error("合计金额与大发票不符!");
      //   return;
      // }
      // if (+this.dialogData.data[0].data.allTax !== +e[2]) {
      //   this.$message.error("合计税额与大发票不符!");
      //   return;
      // }
      // if (+this.dialogData.data[0].data.totalAmount !== +e[3]) {
      //   this.$message.error("价税合计金额与大发票不符!");
      //   return;
      // }
      this.$confirm("确认要推送本张发票吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let requestData = {
            invoiceId: this.tempInvoice.id,
            invoiceList: e[0].invoiceList,
            userId: this.baseInfo.userId,
          };
          this.$request(
            "post",
            "/invoiceInfo/pushBatch",
            requestData,
            (data) => {
              this.colseDialog();
              this.splitFlag = false;
              this.$message.success("推送成功");
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
        })
        .catch(() => {});
    },
    downLoad(row, active) {
      switch (active) {
        case "PDF":
          // pdf 下载
          let requestData = {
            invoiceId: row.id,
          };
          this.$request(
            "post",
            "/invoiceInfo/getIdForNewTimeAi",
            requestData,
            (data) => {
              window.location.href =
                this.$baseurl +
                `/invoiceInfo/downLoadPDF?invoiceId=${row.id}&invoiceIdForNewTimeAi=${data}`;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );

          break;
        case "OFD":
          // ofd 下载
          this.$request(
            "get",
            `/invoiceInfo/downLoadOFD?invoiceId=${row.id}`,
            {},
            (data) => {
              console.log("asd", data);
              window.location.href = data;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        default:
          break;
      }
    },
    /**
     * 删除发票
     */
    deleteINfo(row) {
      let requestData = {
        ids: [row.id],
      };
      this.$confirm("确认要删除该发票吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$request(
            "post",
            "/invoice/delInvoice",
            requestData,
            (data) => {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.initTableData();
            },
            (errorMessage) => {
              that.$message.error(errorMessage);
            }
          );
        })
        .catch(() => {});
    },
    closeDialog2() {
      this.dialogFlag = false;
      this.remark = "";
    },
  },
  mounted() {
    this.loading = true;
    this.isCharge();
    this.initTableData();
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
    // this.getClientOptions();
  },
};
</script>

<style lang="less" scoped>
#contract-invoice {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  /deep/ .el-dialog {
    border-radius: 10px;
  }
  /deep/ .el-dialog__body {
    padding: 30px 15px;
    text-align: left;
    font-size: 15px;
  }
  /deep/ .el-dialog__header {
    padding: 0;
  }
  /deep/ .el-dialog__wrapper {
    top: -75px;
  }
  .contract-invoice-screen-fiftrate {
    width: 65px;
    min-width: 65px;
    height: 30px;
    border: 1px solid @boderColor;
    box-sizing: border-box;
    color: @boderActiveColor;
    font-size: @contentFontSize;
    margin: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    transition: 0.3s;
    i {
      margin: 0 4px;
    }
  }
  .contract-invoice-screen-fiftrate:hover {
    cursor: pointer;
    border-color: @boderActiveColor;
  }
  .contract-invoice-screen-search {
    width: 210px;
    min-width: 210px;
    height: 30px;
    margin-left: 10px;
  }
  .contract-invoice-screen-checked {
    width: 140px;
    height: 30px;
    min-width: 140px;
    font-size: @contentFontSize;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contract-invoice-screen-active {
    flex: 1;
  }
  .contract-invoice-screen-but-select {
    width: 140px;
    min-width: 160px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @activeColor;
    font-size: @contentFontSize;
    color: #fff;
    margin: 0 5px;
    border-radius: 4px;
    transition: 0.5s;
    cursor: pointer;
    .contract-invoice-screen-but-select_but {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.5s;
    }
    .contract-invoice-screen-but-select_but:hover {
      background-color: @activeColor2;
    }
    .contract-invoice-screen-but-select_select {
      width: 30px;
      height: 100%;
      border-left: 1px solid @activeColor3;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .contract-invoice-screen-but {
    width: 100px;
    min-width: 100px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @activeColor;
    font-size: @contentFontSize;
    color: #fff;
    margin: 0 5px;
    border-radius: 4px;
    transition: 0.5s;
  }
  .contract-invoice-screen-but:hover {
    cursor: pointer;
    background-color: @activeColor2;
  }
  .contract-invoice-screen-but-boder {
    width: 50px;
    min-width: 50px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid @activeColor;
    font-size: @contentFontSize;
    background-color: @activeBackground;
    color: @activeColor;
    margin: 0 5px;
    margin-right: 10px;
    border-radius: 4px;
    transition: 0.5s;
  }
  .contract-invoice-screen-but-boder:hover {
    cursor: pointer;
    background-color: @activeBackground2;
  }
  .table-content {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    .contract-invoice-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;

      .contract-invoice-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .contract-invoice-table-card-tab {
          width: 120px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .contract-invoice-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .contract-invoice-table-card-active {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: @contentFontSize;
          .textArea {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: @activeColor;
            cursor: pointer;
            &:hover {
              text-decoration: underline; // 下划线
            }
          }
          .numArea {
            height: 100%;
            padding-top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            color: @activeColor;
          }
        }
        .contract-invoice-table-card-edit {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: @editColor;
          cursor: pointer;
        }
      }
      .contract-invoice-content {
        width: 100%;
        height: 95%;
        max-width: 90vw;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
        display: flex;
        flex-direction: column;

        .line-cell {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          cursor: pointer;
          i {
            margin: 5px;
          }
        }
        .gray {
          color: rgb(146, 146, 146, 0.637);
        }
        .orange {
          color: rgba(255, 115, 0, 0.637);
        }
        .green {
          color: rgb(9, 255, 0, 0.637);
        }
      }
      .contract-invoice-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }

  .goods-dialog-content {
    width: 1200px;
    height: 600px;
    overflow-y: auto;
    display: flex;
    // align-items: center;
    // justify-content: flex-start;
    &::-webkit-scrollbar {
      width: 1px;
    }
  }
}
</style>
