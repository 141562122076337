<template>
  <!-- 小程序-最新政策 -->
  <div id="xcx-policy">
    <div class="xcx-policy-screen">
      <div class="xcx-policy-screen-line">
        <div class="xcx-policy-screen-search"></div>
        <div class="xcx-policy-screen-active"></div>
        <div
          class="xcx-policy-but-boder"
          @click="handleClick('', 0)"
          v-if="!editorFlag"
          v-showFunction="{
            active: 'add',
            array: allFunctionButtons,
          }"
        >
          <i class="el-icon-plus" style="margin-right: 5px;"></i>
          添加信息
        </div>
      </div>
    </div>
    <div class="table-content">
      <div class="xcx-policy-table-card">
        <div class="xcx-policy-content" v-if="!editorFlag">
          <el-table
            :data="tableData"
            style="width: 100%"
            highlight-current-row
            height="98%"
            v-loading="loading"
            :row-style="{ height: '60px' }"
          >
            <el-table-column type="index" width="40" fixed> </el-table-column>
            <el-table-column prop="title" label="标题"></el-table-column>
            <el-table-column prop="intro" label="简介"></el-table-column>
            <el-table-column prop="source" label="来源"></el-table-column>
            <el-table-column prop="content" label="内容" show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{ scope.row.content }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="cover" label="封面">
              <template slot-scope="scope">
                <div v-viewer="{ movable: false }">
                  <img
                    style="height: 50px;"
                    class="table-image"
                    :src="scope.row.cover"
                    alt=""
                  />
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="200">
              <template scope="scope">
                <el-button
                  type="primary"
                  plain
                  size="mini"
                  icon="el-icon-edit-outline"
                  @click.native="handleClick(scope.row, 1)"
                  v-showFunction="{
                    active: 'edit',
                    array: allFunctionButtons,
                  }"
                  >编辑</el-button
                >
                <el-button
                  type="danger"
                  plain
                  size="mini"
                  icon="el-icon-delete"
                  @click="handleClick(scope.row, 2)"
                  v-showFunction="{
                    active: 'delete',
                    array: allFunctionButtons,
                  }"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="xcx-policy-content" v-else>
          <div class="xcx-policy-content-box">
            <div class="xcx-policy-content-line">
              <div class="xcx-policy-content-line-title">标题</div>
              <div class="xcx-policy-content-line-value">
                <el-input v-model="formData.title"></el-input>
              </div>
            </div>
            <div class="xcx-policy-content-line">
              <div class="xcx-policy-content-line-title">简介</div>
              <div class="xcx-policy-content-line-value">
                <el-input v-model="formData.intro"></el-input>
              </div>
            </div>
            <div class="xcx-policy-content-line">
              <div class="xcx-policy-content-line-title">来源</div>
              <div class="xcx-policy-content-line-value">
                <el-input v-model="formData.source"> </el-input>
              </div>
            </div>
            <div class="xcx-policy-content-line" style="align-items: start;">
              <div class="xcx-policy-content-line-title">内容</div>
              <div class="xcx-policy-content-line-value">
                <myEditor
                  :content="formData.content"
                  @setHtml="setHtml"
                ></myEditor>
              </div>
            </div>
            <div class="xcx-policy-content-line" style="align-items: start;">
              <div class="xcx-policy-content-line-title">图片</div>
              <div class="xcx-policy-content-line-value">
                <div class="upload">
                  <el-upload
                    class="avatar-uploader"
                    :action="uploadData.value.action"
                    :show-file-list="false"
                    :headers="uploadData.value.headers"
                    :data="{ systemName: 'finance', title: '新建支出' }"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                  >
                    <img
                      v-if="formData.cover"
                      :src="formData.cover"
                      class="avatar"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </div>
              </div>
            </div>
          </div>
          <div class="xcx-policy-content-button">
            <el-button type="primary" @click="drawerOper('confirm')"
              >确认</el-button
            >
            <el-button type="danger" @click="cancelEditorFlag"> 取消</el-button>
          </div>
        </div>
        <div class="xcx-policy-table-card-bottom" v-if="!editorFlag">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import my_dialog from "@/components/dialog.vue";
import myEditor from "@/components/myEditor.vue";
export default {
  components: {
    my_dialog,
    myEditor,
  },
  data() {
    return {
      loading: false,
      editorFlag: false,
      baseInfo: {
        userId: "",
        company: {
          companyName: "",
        },
      },
      whetherAgencyHead: false,
      dialogData: {
        flag: false,
        width: "30%",
        title: "添加账号",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },
      formData: {
        title: "",
        intro: "",
        source: "",
        content: "",
        cover: "",
      },
      tableData: [],
      flag: false,
      uploadData: {
        title: "上传附件",
        type: "upload",
        value: {
          value: "",
          action: this.$fileBaseurl + "/uploadImageByMultipartFile",
          headers: {
            token: "95931b371eb64f4a9c862983ca8a5d15",
          },
          uploadParameter: {
            systemName: "finance",
            title: "最新政策",
          },
        },
      },
      allFunctionButtons: this.$featureData.getAllFunctionButtons("xcxPolicy"),
      temData: {},
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
    };
  },

  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "xcxPolicy",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          // this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.initTableData();
      });
    },
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "confirm":
          // 确认
          if (!this.temData.id) {
            console.log("新增");
            requestData = {
              title: this.formData.title, // 标题
              intro: this.formData.intro, // 简介
              source: this.formData.source, // 来源
              content: this.formData.content, // 内容
              cover: this.formData.cover, // 封面
            };
            // 新增
            this.$request(
              "post",
              "/appletPolicy/addPolicy",
              requestData,
              (data) => {
                this.$message.success("添加成功!");
                this.cancelEditorFlag();
                this.initTableData();
              },
              (errorMessage) => {
                this.$message.error(errorMessage);
              }
            );
          } else {
            // 修改
            console.log("修改");
            requestData = {
              id: this.temData.id, // id
              title: this.formData.title, // 标题
              intro: this.formData.intro, // 简介
              source: this.formData.source, // 来源
              content: this.formData.content, // 内容
              cover: this.formData.cover, // 封面
            };
            this.$request(
              "post",
              "/appletPolicy/updatePolicy",
              requestData,
              (data) => {
                this.$message.success("修改成功!");
                this.cancelEditorFlag();
                this.initTableData();
              },
              (errorMessage) => {
                this.$message.error(errorMessage);
              }
            );
          }
          break;

        default:
          break;
      }
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      this.tableData = [];
      this.loading = true;
      let requestData = {
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
      };
      this.$request(
        "post",
        "/appletPolicy/page",
        requestData,
        (data) => {
          this.tableData = data.records;
          this.pageData.total = data.total; // 获取总条目数
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    handleClick(data, flag) {
      let requestData = {};
      this.temData = data;
      switch (flag) {
        case 0:
          // 编辑
          this.editorFlag = true;

          break;
        case 1:
          // 编辑
          this.editorFlag = true;
          this.formData = data;

          break;
        case 2:
          // 删除
          requestData = {
            ids: [data.id],
          };
          this.$confirm("确认要删除这条信息吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$request(
                "post",
                "/appletPolicy/delPolicy",
                requestData,
                (data) => {
                  this.$message.success("删除成功!");
                  this.initTableData();
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {});
          break;
        default:
          break;
      }
    },
    // 附件上传之前的校验
    beforeAvatarUpload(file) {
      let isJPG = file.type === "image/jpeg" || file.type === "image/png";

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 格式!");
      }
      return isJPG;
    },
    handleAvatarSuccess(response, file, fileList) {
      console.log(file);

      this.formData.cover = response.result.path;
    },
    colseDialog() {
      this.dialogData.flag = false;
    },
    cancelEditorFlag() {
      this.editorFlag = false;
      this.formData = {
        title: "",
        source: "",
        content: "",
        cover: "",
      };
    },
    setHtml(html) {
      this.formData.content = html;
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
  },
  mounted() {
    this.isCharge();
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));

    this.baseInfo.company.companyAdmins.forEach((element) => {
      if (element.companyAdminId == this.baseInfo.userId) {
        this.flag = true;
      }
    });
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#xcx-policy {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .xcx-policy-screen {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    .xcx-policy-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      > .title {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 0 10px;
      }
      > .el-tag {
        margin: 0 5px;
      }
      .xcx-policy-screen-search {
        width: 210px;
        min-width: 210px;
        height: 30px;
        margin-left: 10px;
        display: flex;
        align-items: center;
      }
      .xcx-policy-screen-active {
        flex: 1;
      }
      .xcx-policy-but-boder {
        min-width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        font-size: @contentFontSize;
        background-color: @activeBackground;
        color: @activeColor;
        margin: 0 5px;
        margin-right: 10px;
        border-radius: 4px;
        transition: 0.5s;
        padding: 0 15px;
      }
      .xcx-policy-but-boder:hover {
        cursor: pointer;
        background-color: @activeBackground2;
      }
    }
  }
  .table-content {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    .xcx-policy-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .xcx-policy-content {
        position: relative;
        width: 100%;
        height: 95%;
        max-width: 90vw;
        max-height: 95vh;
        overflow-y: auto;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        .xcx-policy-content-box {
          height: 100%;
          overflow-y: auto;
          padding-bottom: 100px;
          .xcx-policy-content-line {
            width: 70%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px 0 50px 0;
            .xcx-policy-content-line-title {
              min-width: 150px;
              font-size: 18px;
              text-align: right;
              margin-right: 10px;
            }
            .xcx-policy-content-line-value {
              width: 100%;
              text-align: left;
              .upload {
                .avatar-uploader {
                  .avatar {
                    width: 178px;
                    height: 178px;
                    display: block;
                  }
                  .avatar-uploader-icon {
                    font-size: 28px;
                    color: #8c939d;
                    width: 178px;
                    height: 178px;
                    line-height: 178px;
                    text-align: center;
                  }
                }
              }
            }
          }
        }

        .xcx-policy-content-button {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 98%;
          height: 100px;
          background-color: #fff;
          overflow: hidden;
        }
      }
      .xcx-policy-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
