<template>
  <div id="add_archives">
    <div class="archives-line" v-if="value.isClient">
      <div class="archives-line-title">客户设置:</div>
      <div class="archives-line-value" style="width: 60%">
        <el-select
          v-model="value.clientId"
          filterable
          placeholder="请选择客户名称"
        >
          <el-option
            v-for="item in value.clientList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="archives-line">
      <div class="archives-line-title">录入位置:</div>
      <div class="archives-line-value" style="width: 60%">
        <el-cascader
          v-model="value.locationId"
          :options="value.locationList"
          :props="{ value: 'id', label: 'name', emitPath: false }"
          clearable
          filterable
        ></el-cascader>
      </div>
    </div>
    <div class="archives-line">
      <div class="archives-line-title">录入物品:</div>
      <div class="archives-line-value category">
        <div
          class="archives-line-box"
          v-for="(item, index) in value.categoryList"
          :key="item.name"
        >
          <div class="archives-line-box-title">
            <i :class="`iconfont ${item.icon}`"></i>
            {{ item.name }}:
          </div>
          <div class="archives-line-box-value">
            <el-checkbox
              v-model="item.checkAll"
              @change="handleCheckAllChange($event, index)"
              >全选</el-checkbox
            >
            <el-checkbox-group
              v-model="item.categoryIdList"
              @change="handleCheckedCitiesChange($event, index)"
            >
              <el-checkbox
                v-for="category in item.children"
                :key="category.id"
                :label="category.id"
                >{{ category.name }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    archivesData: {
      type: Object,
      default: function() {
        return;
      },
    },
  },
  data() {
    return {
      city: [],
      value: this.archivesData,
    };
  },

  methods: {
    /**
     * 全选
     */
    handleCheckAllChange(val, index) {
      this.$forceUpdate(); // 强制刷新
      let categoryIdList = [];
      this.value.categoryList[index].children.forEach((item) => {
        categoryIdList.push(item.id);
      });
      this.value.categoryList[index].categoryIdList = val ? categoryIdList : [];
      this.value.categoryList[index].checkAll = val ? true : false;
    },
    /**
     * 单选
     */
    handleCheckedCitiesChange(value, index) {
      this.$forceUpdate(); // 强制刷新
      let checkedCount = this.value.categoryList[index].categoryIdList.length; // 选中数量
      this.value.categoryList[index].checkAll =
        checkedCount === this.value.categoryList[index].children.length;
      console.log(this.value.categoryList[0].checkAll);
    },
  },
  created() {
    console.log("this.value1", this.value);
  },
};
</script>

<style lang="less" scoped>
#add_archives {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  .category {
    height: 250px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.2);
    }
    &:-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }
  }
  .archives-line {
    width: 100%;
    font-size: 14px;
    display: flex;
    justify-self: center;
    align-items: center;
    margin-bottom: 20px;
    &:last-child {
      align-items: flex-start;
    }
    .archives-line-title {
      display: flex;
      width: auto;
      min-width: 70px;
      flex-direction: row-reverse;
      font-weight: 700;
    }
    .archives-line-value {
      margin-left: 10px;
      width: 83%;
      .archives-line-box {
        display: flex;
        justify-content: start;
        align-items: center;
        .archives-line-box-title {
          display: flex;
          align-items: baseline;
          width: 90px;
          min-width: 90px;
          margin: 0 5px 20px 0;
          .iconfont {
            margin-right: 5px;
          }
        }
        .archives-line-box-value {
          display: flex;
          // flex-wrap: wrap;
          /deep/ .el-checkbox {
            margin-right: 15px;
            margin-bottom: 15px;
          }
          /deep/ .el-checkbox-group {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
          }
        }
      }
    }
  }
}
</style>
