var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"payroll-detail"}},[_c('div',{staticClass:"payroll-detail-list"},[_c('div',{staticClass:"payroll-detail-list-item"},[_c('div',{staticClass:"line"},[_c('div',{staticClass:"title"},[_vm._v("姓名:")]),_c('div',{staticClass:"value"},[_c('el-select',{attrs:{"placeholder":"请选择","filterable":""},model:{value:(_vm.value.name),callback:function ($$v) {_vm.$set(_vm.value, "name", $$v)},expression:"value.name"}},_vm._l((_vm.value.employeeList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1)])]),_c('div',{staticClass:"payroll-detail-list-item"},[_c('div',{staticClass:"line"},[_c('div',{staticClass:"title"},[_vm._v("月份:")]),_c('div',{staticClass:"value"},[_c('el-date-picker',{attrs:{"type":"month","placeholder":"选择月","value-format":"yyyy-MM","clearable":false},model:{value:(_vm.value.date),callback:function ($$v) {_vm.$set(_vm.value, "date", $$v)},expression:"value.date"}})],1)])]),_vm._l((_vm.value.rowData),function(item){return _c('div',{key:item.name,staticClass:"payroll-detail-list-item"},[(
          item.name !== '实发工资' &&
            item.name !== '扣除总计' &&
            item.name !== '累计工资收入'
        )?_c('div',{staticClass:"line"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.name)+":")]),_c('div',{staticClass:"value"},[_c('el-input',{model:{value:(item.data),callback:function ($$v) {_vm.$set(item, "data", $$v)},expression:"item.data"}})],1)]):_vm._e(),_c('div',{staticClass:"line",staticStyle:{"align-items":"start"}},[(
            item.name == '实发工资' ||
              item.name == '扣除总计' ||
              item.name == '累计工资收入'
          )?_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(item.name)+"备注: ")]):(item.name !== '所在公司')?_c('div',{staticClass:"title"},[_vm._v("备注:")]):_vm._e(),(item.name !== '所在公司')?_c('div',{staticClass:"value"},[_c('el-input',{attrs:{"type":"textarea"},model:{value:(item.remark),callback:function ($$v) {_vm.$set(item, "remark", $$v)},expression:"item.remark"}})],1):_vm._e()])])}),_c('div',{staticClass:"payroll-detail-list-item"},[_c('div',{staticClass:"line"},[_c('div',{staticClass:"title"},[_vm._v("备注:")]),_c('div',{staticClass:"value"},[_c('el-input',{attrs:{"type":"textarea"},model:{value:(_vm.value.remark),callback:function ($$v) {_vm.$set(_vm.value, "remark", $$v)},expression:"value.remark"}})],1)])])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }