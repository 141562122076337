<template>
  <div id="new-contract">
    <!-- 新建合同 -->
    <div class="new-contract-line">
      <div class="new-contract-line-title">客户名称:</div>
      <div class="new-contract-line-value">{{ value.clientName }}</div>
    </div>

    <div class="new-contract-line">
      <div class="new-contract-line-title">请先选择服务类型:</div>
      <div class="new-contract-line-value">
        <el-select
          v-model="serviceType"
          placeholder="请选择"
          value-key="id"
          filterable
          @change="changeServiceType"
          popper-append-to-body
        >
          <el-option
            v-for="item in serviceTtypeOptions"
            :key="item.id"
            :label="item.typeName"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="new-contract-choose">
      <div
        class="div-border"
        v-for="(item, index) in productList"
        :key="index"
        @click="choseProductTipOne(item)"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["newContract"],
  data() {
    return {
      baseInfo: {},
      value: this.newContract,
      //   value: {
      //     clientName: "",
      //   },
      serviceType: {},
      serviceTtypeOptions: [],
      productList: [{ id: "", name: "ttt" }],
    };
  },
  watch: {
    newContract() {
      //父组件值变化->>子组件也变化
      this.value = this.newContract;
    },
    value(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {
    /**
     * 选择服务类别,显示具体合同模板
     */
    changeServiceType(val) {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        serviceId: val.id,
      };
      this.$request(
        "post",
        "/product/getAllProductByServiceType",
        requestData,
        (data) => {
          this.productList = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 选择合同模板单击事件
     */
    choseProductTipOne(data) {
      this.$emit("choseProductTipOne", data);
    },
  },
  mounted() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    let requestData = {
      companyId: this.baseInfo.company.companyId,
    };
    this.$request(
      "post",
      "/serveType/getAllServeType",
      requestData,
      (data) => {
        this.serviceTtypeOptions = data;
        if (data.length > 0) {
          this.serviceType = data[0];
          this.changeServiceType(data[0]);
        }
      },
      (errorMessage) => {
        this.$message.error(errorMessage);
      }
    );
  },
};
</script>

<style lang="less" scoped>
#new-contract {
  width: 100%;
  height: 500px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  .new-contract-line {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 12px;
    .new-contract-line-title {
      width: 20%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-right: 5px;
    }
    .new-contract-line-value {
      flex: 1;
      height: 100%;
      width: 0;
      display: flex;
      align-items: center;
    }
  }

  .new-contract-choose {
    width: 100%;
    height: 0;
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    // flex-flow: row wrap;
    align-content: flex-start;
    &::-webkit-scrollbar {
      width: 1px;
    }
    .div-border {
      width: 170px;
      height: 100px;
      box-sizing: border-box;
      border: 1px solid @boderColor;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      margin: 5px;
      transition: 0.3s;
      &:hover {
        background-color: @editGrayColor;
        color: @activeColor;
        cursor: pointer;
        border-color: @activeColor3;
      }
    }
  }
  .select-box span:focus-within {
    border: none;
    outline: none;
  }
}
</style>