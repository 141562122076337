/**
 * 获取运单状态
 */
export function getDeliveryState() {
    let state = [
        {
         name: '全部运单',
         value: ''   
        },
        {
         name: '待揽收',
         value: '待揽收'  
        },
        {
         name: '已揽件',
         value: '已揽件'   
        },
        {
         name: '运输中',
         value: '运输中'   
        },
        {
         name: '派送中',
         value: '派送中'   
        },
        {
         name: '已签收',
         value: '已签收'   
        },
        {
         name: '未成交',
         value: '未成交' 
        },
        {
         name: '拒收',
         value: '拒收'   
        },
        {
         name: '下单失败',
         value: '下单失败'   
        },
    ]
    return state
}

/**
 * 获取支付状态
 */
export function getPayState() {
  let state = [
      {
       name: '无需支付',
       value: '0'  
      },
      {
       name: '未支付',
       value: '1'  
      },
      {
       name: '待支付',
       value: '2'   
      },
      {
       name: '已支付',
       value: '3'   
      },
  ]
  return state
}

/**
 * 获取界面筛选数据
 * 
 */
export function expressageInfoSearchData() {
  let data = [
      {
          title: "下单时间",
          type: "times",
          flag: "0",
          hasSearch: false,
          key: "orderTime",
          value: "",
      },
      {
          title: "下单员工",
          type: "tip",
          flag: "0",
          hasSearch: true,
          key: "createId",
          search_value: "",
          value: [
              // { text: "张三", is_active: false },
          ],
          options: [
              { label: "张三", value: "张三" },
              { label: "李四", value: "李四" },
              { label: "王五", value: "王五" },
          ],
      },
      {
        title: "支付方式",
        type: "tip",  
        hasSearch: false,
        flag: "0",
        key: "payMethod",
        value: [
            { text: " 公司寄付", is_active: false, id: "1" },
            { text: "到付", is_active: false, id: "2" },
            { text: "客户支付", is_active: false, id: "3" },
        ],
    },
  ]
  return data
}

/**
 * 获取运单详情
 * @param {*} 运单详情
 * @param {*} clientList 客户列表
 */
export function getDeliveryOrderDetails(data, clientList) {
  let OrderDetails = { 
    id: data.id, // 订单 id
    senderName: data.senderName, // 寄件人姓名
    senderTel: data.senderTel, // 寄件人电话
    senderArea: data.senderArea, // 寄件人省市区
    senderAddress: data.senderAddress, // 寄件人详细地址
    senderClientName: data.senderClientName, // 寄件人公司名称
    receiverName: data.receiverName, // 收件人姓名
    receiverTel: data.receiverTel, // 收件人电话 
    receiverArea: data.receiverArea, // 收件人省市区
    receiverAddress: data.receiverAddress, // 收件人详细地址
    receiverClientId: data.receiverClientId, // 收件人关联客户 id
    receiverClientName: data.receiverClientName, // 收件人关联客户名称
    deliveryId: data.deliveryId, // 顺丰运单号
    deliveryState: data.deliveryState, // 订单状态
    orderDate: data.orderDate, // 下单时间
    deliverTime: data.deliverTime, // 预计送达时间
    payDate: data.payDate, // 扣款时间 
    signedTime: data.signedTime, // 签收时间
    supplierName: data.supplierName, // 快递服务商 
    expressType: data.expressType == (2 || '2') ? '顺丰标快' : '顺丰陆运', // 产品类型：1-顺丰特快 2-顺丰标快
    payMethod: data.payMethod == 1 ? '公司寄付' : data.payMethod == (2 || '2') ? '到付' : '客户支付', // 付款方式 1-寄方付 2-收方付 3-第三方付
    goods: data.goods, // 物品
    preWeight: data.preWeight, // 下单重量
    preFreight: data.preFreight, // 预估运费
    weight: data.weight, // 计费重量
    freight: data.freight, // 标准运费
    discounts: data.discounts, // 优惠
    payAmount: data.payAmount, // 实扣运费
    remark: data.remark, // 留言
    memo: data.memo, // 备注
    routes: data.routes, // 路由（物流）信息
    clientList: clientList, // 客户列表
  };
  return OrderDetails
}
 
/**
 * 地址dialog
 * @param {*} data 回显数据
 * @param {*} clientList 客户列表
 */
export function AddressDetailsDialog(data, clientList) {
  let code = []
  if(data) {
    code.push(data.provinceCode, data.cityCode, data.countyCode)
  }
  let AddressDetailsDialog = {
    companyName: data == "" ? "" : data.companyName, // 收件人公司
    name: data == "" ? "" : data.name, // 姓名
    phone: data == "" ? "" : data.phone, // 电话
    area: data == "" ? "" : data.area, // 省市区
    code: data == "" ? [] : code, // 省市区编码
    provinceCode: data == "" ? "" : data.provinceCode, // 省份编码
    cityCode: data == "" ? "" : data.cityCode, // 城市编码
    countyCode: data == "" ? "" : data.countyCode, // 区县编码
    address: data == "" ? "" : data.address, // 详细地址
    clientList:clientList, // 所有客户列表
    clientId: data == "" ? "" : data.clientId, // 关联客户 id
    createId: data == "" ? "" : data.createId, // 创建人 id
  }
  return AddressDetailsDialog
}

/** 
 * 寄件dialog
 * @param {*} clientList 客户列表
 * @param {*} sanderAddress 上次填写的寄件人信息
 * @param {*} archivesData 档案物品信息
 * @param {*} tempArchives 档案信息
 */
export function mailDialog(clientList, sanderAddress, archivesData, tempArchives) {
  
  let supplierList = [
    {
      label: '顺丰',
      value: '顺丰'
    }
  ]

  let expressTypeList = [
    {
      label: '顺丰陆运',
      value: '231' 
    }, 
    {
      label: '顺丰标快',
      value: '2'
    }
  ]
 
  let payMethodList = [
    {
      label: '公司寄付',
      value: '1'
    },
    {
      label: '到付',
      value: '2'
    },
    {
      label: '客户支付',
      value: '3'
    }
  ]

  let code = []
  if(sanderAddress) {
    code.push(sanderAddress.provinceCode, sanderAddress.cityCode, sanderAddress.countyCode)
  }

  let now = new Date().toLocaleString().replace(/^\D*/, '')
  let num = now.indexOf(':');
  let time = now.substring(0, num) + ":00:00";
  const [date, times] = time.split(' '); // 解构赋值
  const [year, month, day] = date.split('/').map(str => str.padStart(2, '0')); // 数组解构和 map 操作，同时使用  padStart 操作补齐前导零
  const [hour, minute, second] = times.split(':').map(str => str.padStart(2, '0')); // 数组解构和 map 操作，同时使用 padStart 操作补齐前导零
  const sendStartTime = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  let data = {
    // 寄件人信息
    sender: {
      id: sanderAddress ? sanderAddress.id : '', // 地址
      address: sanderAddress ? sanderAddress.address : '', // 地址
      area: sanderAddress ? sanderAddress.address : '', // 省市区
      code: code, // 省市区code 
      provinceCode: sanderAddress ? sanderAddress.provinceCode : '', // 省级code
      cityCode: sanderAddress ? sanderAddress.cityCode : '', // 市级code
      countyCode: sanderAddress ? sanderAddress.countyCode : '', // 县级code
      name: sanderAddress ? sanderAddress.name : '', // 姓名
      phone: sanderAddress ? sanderAddress.phone : '', // 手机号
      companyName: sanderAddress ? sanderAddress.companyName : '', // 公司名称
    },
    // 收件人信息
    receiver: {
      id: '', // 收件人信息id
      address: '', // 地址
      area: '', // 省市区
      code: [], // 省市区code
      provinceCode: '', // 省级code
      cityCode: '', // 市级code
      countyCode: '', // 县级code 
      name: '', // 姓名 
      phone: '', // 手机号
      clientId: tempArchives.clientId ? tempArchives.clientId : '', // 关联客户
      companyName: '', // 收件人公司
    },
    saveOrUpdateReceiver: false, // 是否保存/更新收件人信息到地址维护列表标识
    supplierName: '顺丰', // 快递服务商
    supplierList: supplierList, // 快递服务商列表
    expressType: '231', // 时效产品 1-顺丰特快 2-顺丰标快 231-顺丰陆运
    expressTypeList: expressTypeList, // 时效产品列表
    isOneseIfPickup: '0', // 寄件方式 1-客户同意快件自取 0-客户不同意快件自取 
    payMethod: '', // 支付方式 
    payMethodList: payMethodList, // 支付方式列表
    remark: '', // 留言
    goods: '', // 物品
    preWeight: '1', // 下单重量
    weight: '', // 计费重量 
    startTime: sendStartTime, // 当前整点时间
    sendStartTime: sendStartTime, // 期望上门时间 
    deliverTime: "", // 预计送达时间
    memo: '', // 备注
    preFreight: '', // 预估运费
    freight: '', // 标准运费
    discounts: "", // 优惠 
    discountsMoney: '', // 优惠后金额 
    serviceAgreement: true, // 已同意并阅读 服务协议
    clientList: clientList, // 客户列表
    activeArchives: [], // 选中档案信息
    archivesData: archivesData, // 档案物品信息
  }
  return data
}

/**
 * 省市县数据转换
 */
export function transformCode(code) {
  let data = {
    provinceCode: "",
    cityCode: "",
    countyCode: "",
    area: "",
  } 
  for (let i = 0; i < code.length; i++) {
    const item = code[i];
    const [itemCode, itemAddress] = Object.entries(item)[0];
    if (i === 0) data.provinceCode = itemCode;
    if (i === 1) data.cityCode = itemCode;
    if (i === 2) data.countyCode = itemCode;
    data.area += itemAddress;
  }
  return data
}

/**
 * 省市县数据转换2
 */
 export function transformCode2(code) {
  let data = {
    provinceCode: "",
    cityCode: "",
    countyCode: "",
    provinceName: '',
    cityName: '',
    countyName: '',
  } 
  for (let i = 0; i < code.length; i++) {
    const item = code[i];
    const [itemCode, itemAddress] = Object.entries(item)[0];
    if (i === 0) data.provinceCode = itemCode, data.provinceName = itemAddress;
    if (i === 1) data.cityCode = itemCode, data.cityName = itemAddress;
    if (i === 2) data.countyCode = itemCode, data.countyName = itemAddress;
  }
  return data
}

/**
 * 我的账户快递数据统计
 */
export function DeliveryMoney(DeliveryMoney, _this) {
  let data = [
    {
      title: '已扣款快递',
      value: DeliveryMoney.paidOverSize,
    },
    {
      title: '待扣款快递',
      value: DeliveryMoney.paidToPaySize,
    },
    {
      title: '已扣款金额',
      value:  '¥' + DeliveryMoney.paidOverAmount,
    },
    {
      title: '待扣款金额',
      value: '¥' + DeliveryMoney.paidToPayAmount,
    },
    {
      title: '已享受优惠金额',
      value: '¥' + DeliveryMoney.paidDiscountAmount,
    },
    {
      title: '预计可优惠金额',
      value: '¥' + DeliveryMoney.forecastDiscountAmount,
    },
  ]
  
  let data2 = [
    {
      title: '到付在途快递',
      value: DeliveryMoney.codNotSignedSize,
    },
    {
      title: '到付签收快递',
      value: DeliveryMoney.codSignedSize,
    },
    {
      title: '拒收扣款金额',
      value: '¥' + DeliveryMoney.codRejectedPayAmount,
    },
  ]

  return {
    deliveryExpress: data,
    collectExpress: data2,
    total: DeliveryMoney.total, 
    paidTotal: DeliveryMoney.paidTotal,
    codTotal: DeliveryMoney.codTotal,
  }
}

/**
 * 我的账户充值金额
 */
export function getAccountMoneyList() {
  let moneyList = [
    {
      title: '15元',
      value: '15'
    },
    {
      title: '100元',
      value: '10'
    },
    {
      title: '200元',
      value: '20'
    },
    {
      title: '500元',
      value: '500'
    },
    {
      title: '1000元',
      value: '1000'
    },
    {
      title: '其他',
      value: ""
    },
  ]
  return moneyList
}
 
/**
 * 我的账户列表数据处理
 */
export function getAccountListData(data, _this) {
  data.forEach(item => {
    item.payType == 1 ? item.color = "#67C23A" : item.color = ""
    if (item.amount) {
      _this.$cutOutNum.cutOutNum(item.amount, _this, (money) => {
        item.amount = money;
      });
    }
    if (item.originalPrice) {
      _this.$cutOutNum.cutOutNum(item.originalPrice, _this, (money) => {
        item.originalPrice = money;
      });
    }
    if (item.preferential) {
      _this.$cutOutNum.cutOutNum(item.cutOutNum, _this, (money) => {
        item.cutOutNum = money;
      });
    }
  })
  return data
}