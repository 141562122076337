<template>
  <div id="company-drawer-table-invoice">
    <div class="company-drawer-table-invoice-search">
      <div class="company-drawer-table-invoice-search-item">
        <el-input
          placeholder="请输入购买方名称"
          v-model="searchData.buyerName"
          @keyup.enter.native="initTableData"
        >
        </el-input>
      </div>
      <div class="company-drawer-table-invoice-search-item">
        <el-select
          v-model="searchData.invoiceType"
          placeholder="请输入发票类型"
          @change="initTableData"
          clearable
        >
          <el-option
            v-for="item in invoiceType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="company-drawer-table-invoice-search-item">
        <el-date-picker
          style="width: 210px"
          v-model="searchData.gmtCreate"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="initTableData"
        >
        </el-date-picker>
      </div>
    </div>
    <div class="company-drawer-table-invoice-content">
      <el-table
        :data="tableData"
        style="width: 100%"
        highlight-current-row
        height="98%"
        size="small"
        v-loading="loading"
        @row-click="tableRowClick"
      >
        <el-table-column
          type="index"
          width="50"
          label="序号"
          fixed
          align="center"
        ></el-table-column>
        <el-table-column
          prop="buyerName"
          label="购买方名称"
          show-overflow-tooltip
          width="200"
        ></el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          width="80"
        ></el-table-column>
        <el-table-column prop="type" label="种类" width="100">
          <template slot-scope="scope">
            <el-tooltip
              :content="scope.row.pushFailedInfo"
              placement="top"
              :disabled="!scope.row.pushFailedInfo"
            >
              <span
                :style="{
                  fontWeight: scope.row.weight,
                  color: scope.row.color,
                }"
              >
                <i v-if="scope.row.isPush" class="el-icon-s-promotion"></i>
                {{ scope.row.type }}
              </span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="totalAmount"
          label="金额"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="createUserName"
          label="创建人"
          width="120"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="drawerName"
          label="开票员"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="gmtCreate"
          label="创建日期"
          width="150"
        ></el-table-column>
        <el-table-column label="操作" width="55" fixed="right">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status == '未开票'"
              @click.native.stop="handleClick(scope.row, 0)"
              type="text"
              size="mini"
              v-showFunction="{
                active: 'delete',
                array: allFunctionButtons,
              }"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="company-drawer-table-invoice-pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageData.currentPage"
        :page-size="pageData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageData.total"
      >
      </el-pagination>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
    <!-- 登录认证 -->
    <el-dialog
      class="login-dialog"
      title="登录认证"
      :visible.sync="dialogVisible"
      width="500px"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      @closed="loginClosed"
      append-to-body
    >
      <el-tabs v-model="activeName" @tab-click="tbasClick">
        <el-tab-pane
          v-for="(item, index) in tabList"
          :key="index"
          :label="item.label"
          :name="item.name"
        >
          <div class="login-dialog-note" v-if="activeName == '短信认证'">
            <el-form
              label-position="left"
              label-width="160px"
              :model="loginForm"
            >
              <el-form-item label="社会信用代码/识别号:">
                <el-input disabled v-model="loginForm.nsrsbh"></el-input>
              </el-form-item>
              <el-form-item label="数电账号:">
                <el-input disabled v-model="loginForm.username"></el-input>
              </el-form-item>
              <el-form-item label="手机验证码:">
                <el-input v-model="loginForm.sms"></el-input>
              </el-form-item>
            </el-form>
            <div class="button">
              <el-button
                :disabled="codeFlag"
                type="primary"
                size="small"
                @click="getCode"
              >
                <span v-if="codeFlag">({{ buttonText }})</span>
                <span v-else>获取验证码</span>
              </el-button>
            </div>
          </div>
          <div v-else-if="activeName == '扫码认证'">
            <el-alert
              :title="
                `请使用账号${loginForm.username}登录税务APP扫描二维码完成认证, 认证成功后, 点击认证按钮`
              "
              type="warning"
              show-icon
            >
            </el-alert>
            <div class="face-dialog-content">
              <img
                style="width: 150px; height: 150px"
                :src="`data:image/png;base64,${qrCode}`"
                alt=""
              />
            </div>
          </div>
          <div v-else-if="activeName == '人脸认证'">
            <el-alert
              :title="
                `请使用账号${loginForm.username}登录税务APP扫描二维码完成认证, 认证成功后, 点击认证按钮`
              "
              type="warning"
              show-icon
            >
            </el-alert>
            <div class="face-dialog-content">
              <qriously :value="qrCode" :size="180" />
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="drawerOper('renzheng')"
          >认证</el-button
        >
      </span>
    </el-dialog>
    <!-- 人脸识别 -->
    <el-dialog
      class="face-dialog"
      title="人脸识别"
      :visible.sync="faceFlag"
      width="500px"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      @closed="faceClosed"
      append-to-body
    >
      <div class="face-dialog-content">
        <qriously :value="qrCode" :size="180" />
        <div class="text" style="cursor: pointer;" @click="getFaceRecognition">
          点击刷新
        </div>
        <div class="text">请使用手机扫码进行人脸识别</div>
      </div>
    </el-dialog>
    <!-- 选择购买方 销售方红冲 -->
    <el-dialog
      class="red-dialog"
      title="提示"
      :visible.sync="redDialog.flag"
      width="30%"
      top="30vh"
      append-to-body
    >
      <div class="red-dialog-line">
        <div>请选择红冲方式:</div>
        <div>
          <el-radio v-model="redDialog.value" label="2">销方全额红冲</el-radio>
          <el-radio v-model="redDialog.value" label="3">购方全额红冲</el-radio>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="redDialog.flag = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="drawerOper('sure_red')" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as invoiceData from "@/assets/js/invoiceData.js";
import my_dialog from "@/components/dialog.vue";
export default {
  components: {
    my_dialog,
  },
  props: {
    tempClient: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      flag: false,
      dialogVisible: false, // 登录认证
      faceFlag: false, // 人脸识别
      redDialog: {
        flag: false,
        value: "2",
      }, //选择购买方 销售方红冲
      baseInfo: {},
      loading: true,
      whetherAgencyHead: false,
      searchData: {
        billingDate: [],
        gmtCreate: [],
        input: "",
        checked: false,
        screenButton_add: 0,
        screenButton_improt: 0,
        createUserId: "", // 创建人
        billingUserId: "", // 实际开票人
        drawerName: "", // 开票员
        userId: "", // 员工id
        userList: [], // 员工列表
        userIdList: [], // 全部员工id
        tempUserId: [], // 员工id
        allUserList: [], // 所有员工列表
        buyerName: "", //购买方名称
        invoiceType: "", // 发票类型
      },
      status: "01", // 发票状态
      tabIndex: "01",
      tableData: [],
      clientList: [],
      multipleSelection: [],
      dialogData: {
        flag: false,
        width: "30%",
        title: "添加跟进记录",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      tempInvoice: {},
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "InvoiceCheck"
      ),
      splitFlag: false, // 拆分推送 dialog
      invoiceType: [
        {
          label: "数电普票",
          value: "82",
        },
        {
          label: "数电专票",
          value: "81",
        },
        {
          label: "数电纸普",
          value: "86",
        },
        {
          label: "数电纸专",
          value: "85",
        },
      ],
      tabList: [
        {
          label: "短信认证",
          name: "短信认证",
        },
        {
          label: "扫码认证",
          name: "扫码认证",
        },
        {
          label: "人脸认证",
          name: "人脸认证",
        },
      ],
      activeName: "短信认证",
      codeFlag: false,
      buttonText: "60秒",
      timer: null, // 短信倒计时
      time: null, // 二维码状态轮询
      qrCode: "",
      countdown: 60,
      loginForm: {
        nsrsbh: "", // 纳税人识别号
        username: "", // 电票平台账号
        password: "", // 电票平台密码
        position: "", // 电子税务局身份
        ewmlx: "", // 1-人脸二维码登录 10-税务 app 扫码登录
        ewmid: "", // /
        sms: "", // 验证码
      },
      isBatch: false,
      invoiceBatch: {
        ids: "",
        firstElement: "",
      },
    };
  },
  methods: {
    tbasClick(e) {
      this.loginForm.ewmlx = "";
      this.loginForm.ewmid = "";
      if (this.activeName !== "短信认证") {
        switch (this.activeName) {
          case "扫码认证":
            this.loginForm.ewmlx = 10;
            let requestData = {
              nsrsbh: this.loginForm.nsrsbh, // 纳税人识别号
              username: this.loginForm.username, // 电票平台账号
              password: this.loginForm.password, // 用户电票平台密码
              ewmlx: this.loginForm.ewmlx, // 1-人脸二维码登录 10-税务 app 扫码登录
            };
            this.$request(
              "post",
              "/invoice/loginDppt",
              requestData,
              (data) => {
                if (data.code === 10009) {
                  this.loginForm.ewmid = data.data.ewmid;
                  this.qrCode = data.data.qrcode;
                }
              },
              (errorMessage) => {
                this.$message.error(errorMessage);
              }
            );
            break;
          case "人脸认证":
            this.loginForm.ewmlx = 1;
            break;
          default:
            break;
        }
      }
    },
    faceClosed() {
      clearInterval(this.time);
    },
    loginClosed() {
      this.loginForm = {
        nsrsbh: "", // 纳税人识别号
        username: "", // 电票平台账号
        password: "", // 电票平台密码
        position: "", // 电子税务局身份
        ewmlx: "", // 1-人脸二维码登录 10-税务 app 扫码登录
        ewmid: "", // /
        sms: "", // 验证码
      };
      this.activeName = "短信认证";
    },
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "InvoiceCheck",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          // this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 获取所有人员列表
     */
    getEmployeeListForDownBox() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/user/getEmployeeListForDownBox",
        requestData,
        (data) => {
          this.searchData.allUserList = data;
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getEmployeeListForDownBox();
        // this.getEmployeeList();
      });
    },
    /**
     * 员工选择
     */
    selectInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.searchData.userIdList;
      } else {
        this.searchData.tempUserId = [val];
      }

      this.initTableData();
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      this.loading = true;
      let requestData = {
        clientId: this.tempClient.id,
        companyId: this.baseInfo.company.companyId,
        sellerName: this.searchData.input, // 客户名称 当客户名称为空时,获取所有客户
        status: this.status, // 发票状态
        gmtCreate: this.searchData.gmtCreate, // 创建日期
        billingDate: this.searchData.billingDate, // 开票时间
        createUserId: this.searchData.createUserId, // 创建人
        billingUserId: this.searchData.billingUserId, // 实际开票人
        drawerName: this.searchData.drawerName, // 开票员
        buyerName: this.searchData.buyerName, // 购买方名称
        type: this.searchData.invoiceType, // 发票类型
        pageSize: this.pageData.pageSize, // 每页条数
        currentPage: this.pageData.currentPage, // 当前页数
      };
      this.$request(
        "post",
        "/invoice/queryInvoiceByCondition",
        requestData,
        (data) => {
          this.pageData.total = data.total;
          this.tableData = invoiceData.transTableData(data.records);
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 表格行点击事件
     */
    tableRowClick(row, colum, event) {
      this.tempInvoice = row;
      this.$request(
        "get",
        `/invoice/getInvoice?id=${row.id}`,
        {},
        (redata) => {
          this.dialogData.title = "发票预览";
          this.dialogData.width = "1300px";
          this.dialogData.buttonArray = [];
          if (row.status == "未开票") {
            this.dialogData.buttonArray.push({
              text: "编辑",
              active: "edit",
            });
            this.dialogData.buttonArray.push({
              text: "开票",
              active: "makeout",
            });
          }
          if (row.status == "开票成功") {
            this.dialogData.buttonArray.push({
              text: "红冲",
              active: "red",
            });
          }
          this.dialogData.buttonArray.push({
            text: "取消",
            active: "cancel",
          });
          this.tempInvoice = JSON.parse(JSON.stringify(redata));
          this.dialogData.data = [
            {
              type: "invoice",
              title: "",
              data: invoiceData.getInvoiveDetails(redata),
            },
          ];
          this.dialogData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * tab切换事件
     */
    changeTab(index) {
      this.tabIndex = index;
      this.status = index;
      this.initTableData();
    },
    handleClick(data, flag) {
      this.tempInvoice = data;
      let requestData = {};
      switch (flag) {
        case 0:
          // 删除发票
          requestData = {
            ids: [this.tempInvoice.id],
          };
          this.$confirm("确认要删除该发票吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$request(
                "post",
                "/invoice/delInvoice",
                requestData,
                (data) => {
                  this.$emit("editClient", {
                    flag: "delete",
                    model: "invoice",
                  });
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                  this.initTableData();
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {});
          break;
        case 1:
          // 批量开票
          // 检测是否选中数据

          if (this.status == "02") {
            this.$message.error("已开具的发票不可再次开具!");
            return;
          }
          if (this.multipleSelection.length <= 0) {
            this.$message.error("请选择要操作的数据!");
            return;
          }
          this.isBatch = true; // 是批量开票
          this.invoiceBatch.ids = this.multipleSelection.map((item) => item.id);
          let taxBureauUsernames = this.multipleSelection.map(
            (item) => item.taxBureauUsername
          );
          let sellerTaxpayerNum = this.multipleSelection[0].sellerTaxpayerNum;
          this.invoiceBatch.firstElement = taxBureauUsernames[0];
          console.log("taxBureauUsernames", taxBureauUsernames);
          for (let i = 1; i < taxBureauUsernames.length; i++) {
            // 检测是否都存在 数电账号
            if (!taxBureauUsernames[i]) {
              return this.$message.error("未查询到电子税务局账号，请先绑定!");
            }
            // 检测数电账号 是否一致
            if (taxBureauUsernames[i] !== this.invoiceBatch.firstElement) {
              return this.$message.error(
                "数电账号不相同, 请选择相同的数电账号!"
              );
            }
          }
          this.loading = true;
          this.makeInvoice(sellerTaxpayerNum);
          break;
        case 2:
          break;
        default:
          break;
      }
    },
    downLoad(item, flag) {
      // 下载发票
      this.$request(
        "get",
        "/invoiceTaxAccount/queryByTaxpayerNumber?taxpayerNumber=" +
          item.sellerTaxpayerNum,
        {},
        (data) => {
          let requestData = {
            downflag: "4", // 获取版式类型
            nsrsbh: item.sellerTaxpayerNum, // 纳税人识别号 用户电票平台账号
            username: data.username, // 数电账号
            fphm: item.invoiceNumber, // 发票号码
            addSeal: "1",
          };
          this.$request(
            "post",
            "/invoice/getInvoicePdfOfd",
            requestData,
            (data2) => {
              if (data2.code !== 200) {
                this.$message.error(data2.message);
                return;
              }
              switch (flag) {
                case "pdf":
                  window.open(data2.data.pdfUrl, "_blank");
                  break;
                case "ofd":
                  window.open(data2.data.ofdUrl, "_blank");
                  break;
                case "xml":
                  window.open(data2.data.xmlUrl, "_blank");
                  break;
                default:
                  break;
              }
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 弹出框操作事件
     */
    drawerOper(active) {
      let that = this;
      let requestData = {};
      switch (active) {
        case "makeout":
          // 开票 推送至智云
          this.isBatch = false; // 不是批量开票
          this.makeInvoice(this.tempInvoice.sellerTaxpayerNum);
          break;
        case "renzheng":
          // 验证码登录认证
          requestData = {
            username: this.loginForm.username, // 电票平台账号
            nsrsbh: this.loginForm.nsrsbh, // 纳税人识别号
            password: this.loginForm.password, // 用户电票平台密码
            sms: this.loginForm.sms, // 验证码
            ewmlx: this.loginForm.ewmlx, // 1-人脸二维码登录 10-税务 app 扫码登录
            ewmid: this.loginForm.ewmid, // 第一次调用只传二维码类型，会返回 ewmid 和二维码的 base64，第二次调用二维码类型跟第一次调用值必须一样
          };
          this.$request(
            "post",
            "/invoice/loginDppt",
            requestData,
            (data) => {
              if (data.code === 200) {
                this.loginClosed();
                this.$message.success(data.message);
                this.dialogVisible = false;
              } else {
                this.$message.error(data.message);
              }
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "cancel":
          // 取消
          this.dialogData.flag = false;
          break;
        case "edit":
          // 编辑发票
          that.$request(
            "post",
            "/client/getClientInfoByClientID",
            { id: that.tempInvoice.clientId },
            (clientData) => {
              that.dialogData.width = "1300px";
              that.dialogData.title = "发票开具";
              that.dialogData.buttonArray = [
                {
                  text: "保存",
                  active: "save",
                },
                {
                  text: "取消",
                  active: "cancel",
                },
              ];
              that.$set(clientData, "clientId", that.tempInvoice.clientId);
              that.dialogData.data = [
                {
                  type: "invoice_dialog",
                  title: "",
                  data: invoiceData.getInvoiceDialogData(
                    clientData,
                    that.baseInfo.company.companyId,
                    that.tempInvoice,
                    true
                  ),
                },
              ];
              console.log("发票开具dialog显示:", that.dialogData.data);
              that.dialogData.flag = true;
            },
            (errorMessage) => {
              that.$message.error(errorMessage);
            }
          );
          break;
        case "save":
          // 发票保存
          this.saveInvoiceDetails().then(() => {
            this.$message({
              type: "success",
              message: "发票保存成功!",
              duration: 1000,
            });
            that.initTableData();
          });
          break;
        case "red":
          this.redDialog.value = "2";
          this.redDialog.flag = true;
          break;
        case "sure_red":
          this.dialogLoading(0, true);
          this.redDialog.flag = false;
          this.$request(
            "get",
            "/invoiceTaxAccount/queryByTaxpayerNumber?taxpayerNumber=" +
              that.tempInvoice.sellerTaxpayerNum,
            {},
            (data) => {
              this.loginForm.nsrsbh = data.taxpayerNumber; // 纳税人识别号
              this.loginForm.username = data.username; // 电票平台账号
              this.loginForm.password = data.password; // 电票平台密码
              this.loginForm.position = data.position; // 电票平台密码
              requestData = {
                id: that.tempInvoice.id, // 发票 id
                userId: that.baseInfo.userId, // 登录用户 id
                taxBureauUsername: this.loginForm.username, // 电税账号
                sqyy: this.redDialog.value, // 申请原因 '2'-销方全额红冲申请 '3'-购方全额红冲
              };
              this.$request(
                "post",
                "/invoice/redPunch",
                requestData,
                (data) => {
                  this.dialogData.flag = false;
                  this.initTableData();
                  this.$message.success("红冲成功!");
                },
                (errorMessage, errorData) => {
                  if (errorData.code == 302) {
                    // 未登录
                    this.dialogLoading(0, false);
                    this.dialogVisible = true;
                    this.$message.error(errorData.message);
                  } else {
                    this.dialogLoading(0, false);
                    this.$message.error(errorData.message);
                  }
                }
              );
            },
            (errorMessage) => {
              this.dialogLoading(0, false);
              this.$message.error(errorMessage);
            }
          );
          beak;
        default:
          break;
      }
    },
    /**
     * 获取验证码
     */
    getCode() {
      this.codeFlag = true;
      let requestData = {
        username: this.loginForm.username, // 电票平台账号
        nsrsbh: this.loginForm.nsrsbh, // 纳税人识别号
        password: this.loginForm.password, // 用户电票平台密码
        sms: this.loginForm.sms, // 验证码
        // cert: "", // 1-使用证书登录 2-使用软证书登录
        // ewmlx: "", // 1-人脸二维码登录 10-税务 app 扫码登录
        // ewmid: "", // 第一次调用只传二维码类型，会返回 ewmid 和二维码的 base64，第二次调用二维码类型跟第一次调用值必须一样
        // pin: "", // 软证书口令，cert 为 2 时如果有就传
      };
      this.$request(
        "post",
        "/invoice/loginDppt",
        requestData,
        (data) => {
          if (data.code === 200) {
            this.$message.success(data.message);
          } else {
            this.$message.error(data.message);
          }
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.countdown > 0 && this.countdown <= 60) {
            this.countdown--;
            if (this.countdown !== 0) {
              this.buttonText = this.countdown + "秒";
            } else {
              clearInterval(this.timer);
              this.buttonText = "60秒";
              this.codeFlag = false;
              this.countdown = 60;
              this.timer = null;
            }
          }
        }, 1000);
      }
    },
    /**
     * 提交开票
     */
    makeInvoice(nsrsbh) {
      if (!this.isBatch) {
        // 不是批量开票, dialog 按钮显示loading 防止多次点击
        this.dialogLoading(1, true);
      }
      // 1. 根据纳税人识别号查询电子税务局账号
      this.$request(
        "get",
        "/invoiceTaxAccount/queryByTaxpayerNumber?taxpayerNumber=" + nsrsbh,
        {},
        (data) => {
          this.loginForm.nsrsbh = nsrsbh; // 纳税人识别号
          this.loginForm.username = data.username; // 电票平台账号
          this.loginForm.password = data.password; // 电票平台密码
          this.loginForm.position = data.position; // 电票平台密码
          // 2. 调取切换或者账号接口 检查是否
          let requestData = {
            newNsrsbh: this.loginForm.nsrsbh, // 需要切换的纳税人识别号
            username: this.loginForm.username, // 电票平台账号
            sf: this.loginForm.position, // 电子税务局身份，切换后纳税人的身份
          };
          this.$request(
            "post",
            "/invoice/changeUser",
            requestData,
            (data2) => {
              // 3. 先调用 是否需要人脸识别接口
              let requestData2 = {
                username: this.loginForm.username, // 电票平台账号
                nsrsbh: this.loginForm.nsrsbh, // 纳税人识别号
              };
              this.$request(
                "post",
                "/invoice/queryFaceAuthState",
                requestData2,
                (data3) => {
                  if (data3.code === 200) {
                    // 开票
                    // 对返回数据进行解码
                    let code = JSON.parse(this.base64("decode", data3.data));
                    console.log("code", code);
                    if (code.Sfsl == "N" && !this.isBatch) {
                      // 不需要人脸识别 且 不是批量开票 可以直接开票
                      this.submitInvoice();
                    } else if (code.Sfsl == "N" && this.isBatch) {
                      // 不需要人脸识别 且 是批量开票 可以直接开票
                      this.submitInvoiceBatch();
                    } else if (code.Sfsl == "Y") {
                      // 需要人脸识别 先进行人脸识别再开票
                      if (!this.isBatch) {
                        this.dialogLoading(1, false);
                      } else {
                        this.loading = false;
                      }
                      this.getFaceRecognition();
                    }
                  } else if (data3.code === 302) {
                    // 未登录
                    if (!this.isBatch) {
                      this.dialogLoading(1, false);
                    } else {
                      this.loading = false;
                    }
                    this.dialogVisible = true;
                    setTimeout(() => {
                      this.$message.error("暂未登录电票平台, 请先登录!");
                    }, 100);
                  } else {
                    this.$message.error(data3.message);
                  }
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 确认推送发票
     */
    submitInvoice() {
      setTimeout(() => {
        this.$message.success("正在开票, 请耐心等待!");
      }, 100);
      this.$request(
        "get",
        `/invoice/makeOutAnInvoice?id=${this.tempInvoice.id}&userId=${this.baseInfo.userId}&taxBureauUsername=${this.loginForm.username}`,
        {},
        (data) => {
          this.$emit("editClient", {
            flag: "delete",
            model: "invoice",
          });
          this.dialogData.flag = false;
          this.$message.success("开票成功");
          this.initTableData();
        },
        (errorMessage) => {
          // 取消 loading
          this.dialogLoading(1, false);
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 批量提交开票
     */
    submitInvoiceBatch() {
      let requestData = {
        ids: this.invoiceBatch.ids, // 发票 id 集合
        userId: this.baseInfo.userId, // 操作用户 id
        taxBureauUsername: this.invoiceBatch.firstElement, // 开票账号
      };
      this.$request(
        "post",
        "/invoice/makeOutAnInvoiceBatch",
        requestData,
        (data) => {
          this.loading = false;
          this.$message.success("开票成功", data);
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * base64 编码解码
     */
    base64(type, data) {
      let Base64 = require("js-base64").Base64; // 引入
      let code = "";
      if (type == "encode") {
        // 编码
        code = Base64.encode(data);
      } else if (type == "decode") {
        // 解码
        code = Base64.decode(data);
      }
      return code;
    },
    dialogLoading(index, flag) {
      this.$set(this.dialogData.buttonArray[index], "loading", flag);
    },
    /**
     * 获取人脸识别二维码
     */
    getFaceRecognition() {
      let requestData = {
        username: this.loginForm.username, // 电票平台账号
        nsrsbh: this.loginForm.nsrsbh, // 纳税人识别号
        type: "", // 类型 值为 2 获取个人所得税二维码，不传或者其他值都是税局app 二维码
      };
      this.$request(
        "post",
        "/invoice/getFaceImg",
        requestData,
        (data) => {
          if (data.code === 200) {
            this.qrCode = data.data.ewm;
            console.log("this.qrCode", this.qrCode);
            this.faceFlag = true;
            setTimeout(() => {
              this.$message.error("暂未进行人脸识别认证, 请认证!");
            }, 100);
            clearInterval(this.time);
            this.time = setInterval(() => {
              this.getFaceRecognitionState(data.data.rzid);
            }, 1000);
          } else {
            this.$message.error(data.message);
          }
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取人脸二维码认证状态
     */
    getFaceRecognitionState(rzid) {
      let requestData = {
        username: this.loginForm.username, // 电票平台账号
        nsrsbh: this.loginForm.nsrsbh, // 纳税人识别号
        rzid: rzid, // 认证id
        type: "", // 类型 值为 2 获取个人所得税二维码，不传或者其他值都是税局app 二维码
      };
      this.$request(
        "post",
        "/invoice/getFaceState",
        requestData,
        (data) => {
          // 检查受理状态: 1 未认证，2 成功，3 二维码过期
          if (data.data.slzt == 2) {
            this.faceFlag = false;
            clearInterval(this.time);
            this.$message.success(data.message);
          }
          if (data.data.slzt == 3) {
            clearInterval(this.time);
            this.$message.error("二维码已过期，请重新获取!");
          }
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 保存发票
     */
    saveInvoiceDetails() {
      let that = this;
      let p = new Promise(function(resolve, reject) {
        if (that.dialogData.data[0].data.specialFieldEcho) {
          that.dialogData.data[0].data.specialField = invoiceData.transSpecialField(
            that.dialogData.data[0].data
          );
        }
        let requestData = {
          ...that.dialogData.data[0].data,
          createUserId: that.baseInfo.userId, // 创建人 id
          // inOrOut: "1",
        };
        // 特定信息 必填项校验
        // if (Array.isArray(requestData.specialField)) {
        //   requestData.specialField.forEach((item, index) => {
        //     let key = Object.keys(item);
        //     if (!item[key[index]]) {
        //       that.$message.error("请填写完所有字段!");
        //       reject();
        //     }
        //   });
        // } else {
        //   for (
        //     let index = 0;
        //     index < requestData.specialFieldEcho.list.length;
        //     index++
        //   ) {
        //     if (
        //       requestData.specialFieldEcho.list[index].is_require &&
        //       !requestData.specialFieldEcho.list[index].value
        //     ) {
        //       reject();
        //     }
        //   }
        // }
        requestData.paperTicketCodeEcho = JSON.stringify(
          requestData.paperTicketCodeEcho
        );
        requestData.specialField = JSON.stringify(requestData.specialField);
        requestData.specialFieldEcho = JSON.stringify(
          requestData.specialFieldEcho
        );
        requestData.collectField = JSON.stringify(requestData.collectField);
        requestData.collectFieldEcho = JSON.stringify(
          requestData.collectFieldEcho
        );
        that.$request(
          "post",
          "/invoice/updInvoice",
          requestData,
          (data) => {
            if (!data.flag) {
              // 当前客户不是常用客户,提示是否添加进常用客户
              that
                .$confirm("是否将此客戶添加进常用客戶?", "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                })
                .then(() => {
                  // 添加常用客户
                  let customersData = {
                    id: that.tempInvoice.id, // id
                    name: requestData.buyerName, // 名称,
                    clientId: requestData.clientId, // 客户ID,
                    taxNum: requestData.buyerTaxpayerNum, // 企业税号,
                    tel: requestData.buyerTel, // 电话,
                    bank: requestData.buyerBankName, // 开户行,
                    bankAccount: requestData.buyerBankAccount, // 银行账号,
                    address: requestData.buyerAddress, // 详细地址,
                  };
                  that.$request(
                    "post",
                    "/activeCustomer/addCustomers",
                    customersData,
                    (dd) => {
                      that.$message({
                        type: "success",
                        message: "常用客户添加成功!",
                        duration: 1000,
                      });
                    },
                    (errorMessage) => {
                      that.$message.error(errorMessage);
                    }
                  );
                })
                .catch(() => {
                  that.dialogData.flag = false;
                });
            } else {
              that.dialogData.flag = false;
            }
            resolve(true);
          },
          (errorMessage) => {
            reject(errorMessage);
            that.$message.error(errorMessage);
          }
        );
      });
      return p;
    },
    /**
     * 弹出框关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageData.currentPage = val;
      this.initTableData();
    },
    /**
     * 拆分推送dialog关闭
     */
    closed() {
      this.splitFlag = false;
    },
    /**
     * 多选选择事件
     */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
  mounted() {
    this.loading = true;
    this.isCharge();
    this.initTableData();
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#company-drawer-table-invoice {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .company-drawer-table-invoice-search {
    width: 100%;
    height: auto;
    display: flex;
    margin-bottom: 10px;
    .company-drawer-table-invoice-search-item {
      width: 170px;
      min-width: 170px;
      height: 30px;
      margin-right: 10px;
      display: flex;
      align-items: center;
    }
  }
  .company-drawer-table-invoice-content {
    width: 100%;
    height: 100%;
    border-top: 1px solid #e9ebed;
    /deep/ .el-table--scrollable-x .el-table__body-wrapper {
      z-index: 1;
      &::-webkit-scrollbar {
        height: 6px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.2);
      }
    }
    /deep/ .el-table--scrollable-y .el-table__body-wrapper {
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
  .company-drawer-table-invoice-pagination {
    width: 100%;
    height: auto;
  }
}
.login-dialog,
.face-dialog {
  text-align: left;
  /deep/ .el-dialog {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: calc(100% - 200px);
    max-width: calc(100% - 30px);

    .el-dialog__header {
      border-radius: 3px;
      background-color: #f5f8fa;
      display: flex;
      padding: 0;
      padding-left: 10px;
      align-items: center;
      height: 45px;
      border-bottom: 1px solid #ced4db;
    }
    .el-dialog__body {
      padding: 0;
      flex: 1;
      overflow: auto;
    }
    .el-dialog__footer {
      padding: 0;
      padding-right: 1rem;
      background-color: #f5f8fa;
      border-radius: 0 0 5px 5px;
      border-top: 1px solid #ced4db;
      height: 45px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
  /deep/ .el-tabs {
    .el-tabs__nav-scroll {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0;
    }
    .el-tabs__item {
      font-size: 17px;
      .is-active {
        color: #303130;
      }
    }
  }
  .login-dialog-note {
    position: relative;
    width: 440px;
    padding: 10px 50px 0 30px;
    box-sizing: border-box;
    /deep/ .el-form-item {
      margin-bottom: 15px;
    }
    .button {
      position: absolute;
      bottom: 3px;
      right: -57px;
    }
  }
  .face-dialog-content {
    padding: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    .text {
      font-size: 14px;
      color: @activeColor;
      margin-top: 20px;
    }
  }
}

.red-dialog {
  .red-dialog-line {
    display: flex;
    align-items: center;
  }
}
</style>
<style lang="less" scoped></style>
