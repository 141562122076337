import * as baseData from "./baseData.js"
import * as employeeData from "./employeeData.js";
import Vue from 'vue'

/**
 * 部门右侧抽屉数据
 * @param {*} title 部门名
 * @param {*} data  部门基本信息
 */
export function departmentDrawerData(title, data, buttonAuth, personList) {

    let myThreadDrawerData = {
        title: "部门信息",
        flag: false,
        hasSetting: false,
        buttonList: [{
                text: "主管权限",
                authority: buttonAuth == "" ? "" : buttonAuth.editDepartmentManage,
                icon: "el-icon-user-solid",
                active: "show_manager_authority",
            },
            {
                text: "员工权限",
                authority: buttonAuth == "" ? "" : buttonAuth.editDepartmentEmployee,
                icon: "el-icon-user",
                active: "show_employee_authority",
            },

        ],
        data: {
            title: title,
            customer: 0, // 关联客户,与商机状态关联,(只有商机状态为成交才有值),其他时候为空字符串
            isDisable: false, // 是否只读
            modelList: [{
                line: [{
                        horizontal: true,
                        isShow: true, // 是否显示
                        isDisable: false, // 是否只读
                        title: "上级部门",
                        type: "text",
                        value: data == "" ? "--" : data.superiorDepartment,
                    },
                    {
                        horizontal: true,
                        isShow: true,
                        isDisable: false,
                        title: "部门名称",
                        type: "input",
                        value: data == "" ? "--" : data.departmentName,
                    },
                    {
                        horizontal: true,
                        isShow: true,
                        isDisable: false,
                        title: "部门主管",
                        type: "tags-select-obj-search",
                        value: data == "" ? [] : data.departmentManager,
                        isSelect: false,
                        tempValue: {},
                        options: personList.length > 0 ? personList : [],
                    },
                    {
                        horizontal: false,
                        isShow: true,
                        isDisable: false,
                        title: "部门成员",
                        type: "tags-select-obj-search",
                        value: data == "" ? [] : data.departmentEmployee,
                        isSelect: false,
                        tempValue: {},
                        options: personList.length > 0 ? personList : [],
                    },
                    {
                        horizontal: false,
                        isShow: true,
                        isDisable: false,
                        title: "部门介绍",
                        type: "textarea",
                        value: data == "" ? "--" : data.departmentDes,
                    }
                ]
            }]
        }
    }

    return myThreadDrawerData
}