<template>
  <div id="person">
    <!-- 系统设置界面 -->
    <div class="person-menu">
      <div v-for="(item, index) in menuData" :key="index">
        <div class="person-menu-lines" v-if="item.children.length > 0">
          <div class="person-menu-line">
            <i :class="item.icon"></i> {{ item.title }}
          </div>
          <div
            :class="{
              'person-menu-line-item': true,
              'is-active': menu_index == it.path,
            }"
            v-for="(it, ii) in item.children"
            :key="ii"
            @click="changeMenu(it.path)"
          >
            {{ it.title }}
          </div>
        </div>
        <div
          :class="{
            'person-menu-line': true,
            'is-active': menu_index == item.path,
          }"
          @click="changeMenu(item.path)"
          v-else
        >
          <i :class="item.icon"></i>{{ item.title }}
        </div>
      </div>
    </div>
    <div class="person-content">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menu_index: "person-info",
      pathList: ["person-info", "person-icon"],
      menuData: [
        {
          title: "个人设置",
          icon: "el-icon-s-tools",
          path: "1",
          children: [
            {
              title: "个人资料",
              path: "person-info",
            },
            {
              title: "头像设置",
              path: "person-icon",
            },
          ],
        },
      ],
    };
  },
  methods: {
    changeMenu(path) {
      this.menu_index = path;
      sessionStorage.setItem("menuSecondActiveIndex", this.menu_index);
      this.$router.push("/main/person/" + path);
    },
  },
  mounted() {
    this.menu_index =
      sessionStorage.getItem("menuSecondActiveIndex") &&
      this.pathList.indexOf(sessionStorage.getItem("menuSecondActiveIndex")) >
        -1
        ? sessionStorage.getItem("menuSecondActiveIndex")
        : "person-info";
    sessionStorage.setItem("menuSecondActiveIndex", this.menu_index);
  },
};
</script>


<style lang="less" scope>
#person {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #fff;
  .person-menu {
    width: 180px;
    height: 100%;
    min-width: 9vw;
    border-right: 1px solid @boderColor;
    overflow: hidden;
    font-size: @contentFontSize;
    .person-menu-lines {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .person-menu-line-item,
    .person-menu-line {
      width: 100%;
      height: 40px;
      display: flex;
      padding-left: 10px;
      transition: 0.3s;
      align-items: center;
      border-left: 4px solid #fff;
      box-sizing: border-box;
      i {
        margin-right: 10px;
      }
    }
    .person-menu-line-item {
      padding-left: 53px;
    }
    .person-menu-line {
      font-weight: 600;
    }
    .person-menu-line-item:hover,
    .person-menu-line:hover {
      cursor: pointer;
      background-color: @activeBackground;
    }
    .is-active {
      background-color: @activeBackground;
      border-left: 4px solid @activeColor;
      box-sizing: border-box;
      color: @activeColor;
    }
  }
  .person-content {
    flex: 1;
    height: 100%;
    position: relative;
  }
}
</style>