<template>
  <div id="department-setting" v-loading="isLoading">
    <div class="table-content">
      <div class="department-setting-content">
        <el-tree
          :data="departList"
          node-key="id"
          default-expand-all
          :expand-on-click-node="false"
          icon-class="el-icon-help"
          @node-click="showDetail"
        >
          <span class="custom-tree-node" slot-scope="{ data }">
            <span>{{ data.label }}</span>
            <span>
              <el-button
                type="success"
                plain
                size="mini"
                @click.native.stop="oper(data, 'add')"
                v-if="buttonAuthorityList.addDepartment < 3"
              >
                <i class="el-icon-plus"></i>
                新增部门
              </el-button>
              <el-button
                type="danger"
                plain
                size="mini"
                v-if="buttonAuthorityList.deleteDepartment < 3"
                @click.native.stop="oper(data, 'delete')"
              >
                <i class="el-icon-delete"></i>
                删除
              </el-button>
            </span>
          </span>
        </el-tree>
      </div>
    </div>

    <div id="my-drawer" v-if="departmentDrawerData.flag">
      <my_drawer
        :drawerData="departmentDrawerData"
        @drawerOper="drawerOper"
        @closeUpdateTableDrawer="closeUpdateTableDrawer"
        @tagsHandleClose="handleClose"
        @addTags="addTags"
        @saveDrawerData="saveDrawerData"
      >
      </my_drawer>
    </div>

    <!-- dialog -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @authorityShow="authorityShow"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import my_drawer from "../components/my-drawer.vue";
import my_dialog from "../components/dialog.vue";
import * as departmentData from "../assets/js/departmentData.js";
export default {
  components: {
    my_drawer,
    my_dialog,
  },
  data() {
    return {
      baseInfo: {},
      isLoading: true,
      companyData: {
        value: "XXX公司",
        companyId: "",
        options: [
          {
            label: "A公司",
            value: "A公司",
          },
          {
            label: "B公司",
            value: "B公司",
          },
        ],
      },
      departList: [
        {
          id: "departmentId", //  super创建的部门ID
          fatherId: "", // 直接为空字符串,公司没有上级部门
          label: "部门", // 等价于公司名称
          children: [
            {
              id: "bumenId1", // 一级部门ID
              fatherId: "gongsiId", // 该部门的上级部门或公司ID
              label: "部门一", // 部门名称
              children: [
                {
                  // 下级部门
                  id: "bumen1-1",
                  fatherId: "bumenId1",
                  label: "部门一1组",
                },
                {
                  id: "bumen1-2",
                  fatherId: "bumenId1",
                  label: "部门一2组",
                },
              ],
            },
          ],
        },
      ],
      dialogData: {
        flag: false,
        width: "30%",
        title: "新增部门",
        buttonArray: [
          {
            text: "确认",
            active: "add_department",
          },
          {
            text: "取消",
            active: "cancel",
          },
        ],
        data: [],
      },
      personList: [], // 该公司下的所有员工列表

      isHead: false, // 是否是头结点

      tempDepartment: {}, // 临时部门
      tempAuthorityId: "", // 临时主管或员工权限ID,
      tempModel: "", // 临时model
      tempAuthorityData: {}, // 临时存储权限数据
      buttonAuthorityList: {
        addDepartment: 0, // 新增部门
        editDepartment: 0, // 编辑部门
        deleteDepartment: 0, // 删除部门
        editDepartmentManageAuth: 0, // 编辑部门主管权限
        editDepartmentEmployeeAuth: 0, // 编辑部门员工权限
        editDepartmentManage: 0, // 添加/删除主管
        editDepartmentEmployee: 0, // 添加/删除员工
      },

      departmentDrawerData: departmentData.departmentDrawerData("", "", "", []),
    };
  },
  methods: {
    /**
     * 获取部门设置的按钮权限
     */
    getModelAuthority() {
      this.$authority.getModelAuthority(
        ["system", "department"],
        "modelChildMenu",
        this,
        false,
        (data) => {
          if (data.length > 0) {
            this.buttonAuthorityList.addDepartment = this.$systemBaseData.judgeButtonAuthority(
              "新增部门",
              data
            );
            this.buttonAuthorityList.editDepartment = this.$systemBaseData.judgeButtonAuthority(
              "编辑部门",
              data
            );
            this.buttonAuthorityList.deleteDepartment = this.$systemBaseData.judgeButtonAuthority(
              "删除部门",
              data
            );
            this.buttonAuthorityList.editDepartmentManageAuth = this.$systemBaseData.judgeButtonAuthority(
              "编辑部门主管权限",
              data
            );
            this.buttonAuthorityList.editDepartmentEmployeeAuth = this.$systemBaseData.judgeButtonAuthority(
              "编辑部门员工权限",
              data
            );
            this.buttonAuthorityList.editDepartmentManage = this.$systemBaseData.judgeButtonAuthority(
              "添加/删除主管",
              data
            );
            this.buttonAuthorityList.editDepartmentEmployee = this.$systemBaseData.judgeButtonAuthority(
              "添加/删除员工",
              data
            );
            console.log("查看所有权限:", this.buttonAuthorityList);
          }
          this.getAllPersonList();
          // this.getDepartmentTree();
        }
      );
    },
    /**
     * 获取所有人员列表
     * 在修改部门员工或主管人员时使用,即在options时用
     * options:[
     * {
     *    id:"",
     *    name:""
     * }]
     */
    getAllPersonList() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/isCharge",
        requestData,
        (data) => {
          data.forEach((element) => {
            let dd = {
              id: element.userId,
              name: element.userName,
            };
            this.personList.push(dd);
          });
          this.getDepartmentTree();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取部门树
     */
    getDepartmentTree() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/setting/getDepartmentTree",
        requestData,
        (data) => {
          this.departList = [data];
          this.isLoading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 界面操作按钮
     */
    oper(data, flag) {
      console.log("部门设置中得按钮点击事件", data);
      switch (flag) {
        case "add":
          // 添加子部门
          if (this.buttonAuthorityList.addDepartment == 2) {
            this.$message.error(
              this.$errorMessage.getErrorMessageInfo("authority")
            );
            return;
          }
          this.dialogData.width = "30%";
          this.dialogData.title = "新增部门";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "add_department",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            { title: "部门名称", type: "input", value: "" },
          ];
          this.tempDepartment.departmentId = data.id;
          this.dialogData.flag = true;
          break;
        case "delete":
          // 删除部门
          if (this.buttonAuthorityList.deleteDepartment == 2) {
            this.$message.error(
              this.$errorMessage.getErrorMessageInfo("authority")
            );
            return;
          }

          break;

        default:
          break;
      }
    },
    /**
     * 获取所有
     */
    /**
     * 点击部门,显示右边抽屉
     */
    showDetail(data, node, tree) {
      console.log("部门信息展示", data, node, tree);
      if (data.fatherId == this.baseInfo.company.companyId) {
        this.isHead = true;
      } else {
        this.isHead = false;
      }
      this.showDepartmentDetail(data.id, data.label);
    },
    showDepartmentDetail(id, title) {
      let requestData = {
        departmentId: id,
      };
      this.$request(
        "post",
        "/setting/getDepartmentBaseInfo",
        requestData,
        (resultData) => {
          console.log("aaaaaaaaaaaa");
          this.departmentDrawerData = departmentData.departmentDrawerData(
            title,
            resultData,
            this.buttonAuthorityList,
            this.personList
          );
          this.tempDepartment = resultData;
          if (this.isHead) {
            this.departmentDrawerData.data.modelList[0].line[2].title =
              "机构负责人";
            this.departmentDrawerData.data.modelList[0].line[3].title =
              "机构主管";
          }
          this.departmentDrawerData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 添加标签事件
     * i为抽屉数据中模块索引,j为模块中属性索引
     * isSelect为true,显示下拉框,false显示添加按钮
     */
    addTags(data) {
      console.log(
        "选择人员:",
        this.departmentDrawerData.data.modelList[data.i].line[data.j]
      );
      let auth = 0;
      if (
        this.departmentDrawerData.data.modelList[data.i].line[data.j].title ==
          "部门主管" ||
        this.departmentDrawerData.data.modelList[data.i].line[data.j].title ==
          "机构负责人"
      ) {
        auth = this.buttonAuthorityList.editDepartmentManage;
      } else if (
        this.departmentDrawerData.data.modelList[data.i].line[data.j].title ==
          "部门成员" ||
        this.departmentDrawerData.data.modelList[data.i].line[data.j].title ==
          "机构主管"
      ) {
        auth = this.buttonAuthorityList.editDepartmentEmployee;
      }
      if (auth == 1) {
        // 判断是否是下拉选择
        if (
          this.departmentDrawerData.data.modelList[data.i].line[data.j].isSelect
        ) {
          let requestData = {
            userId: this.departmentDrawerData.data.modelList[data.i].line[
              data.j
            ].tempValue.id,
            roleId:
              this.departmentDrawerData.data.modelList[data.i].line[data.j]
                .title == "部门主管" ||
              this.departmentDrawerData.data.modelList[data.i].line[data.j]
                .title == "机构负责人"
                ? this.tempDepartment.managerRoleId
                : this.tempDepartment.employeeRoleId,
            type: "add",
          };
          this.$request(
            "post",
            "/setting/setDepartmentRoleForEmployee",
            requestData,
            (resultData) => {
              this.$message({
                type: "success",
                message: this.$errorMessage.getErrorMessageInfo("update"),
                duration: 1000,
              });
              // 清空临时对象
              this.departmentDrawerData.data.modelList[data.i].line[
                data.j
              ].tempValue = {};
              this.departmentDrawerData.data.modelList[data.i].line[
                data.j
              ].isSelect = !this.departmentDrawerData.data.modelList[data.i]
                .line[data.j].isSelect;
              this.showDepartmentDetail(
                this.tempDepartment.departmentId,
                this.tempDepartment.departmentName
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
        } else {
          this.departmentDrawerData.data.modelList[data.i].line[
            data.j
          ].isSelect = !this.departmentDrawerData.data.modelList[data.i].line[
            data.j
          ].isSelect;
        }
      } else {
        this.$message.error(
          this.$errorMessage.getErrorMessageInfo("authority")
        );
        return;
      }
    },
    /**
     * 删除标签事件
     * tag,i,j
     */
    handleClose(data) {
      let auth = 0;
      if (
        this.departmentDrawerData.data.modelList[data.i].line[data.j].title ==
          "部门主管" ||
        this.departmentDrawerData.data.modelList[data.i].line[data.j].title ==
          "机构负责人"
      ) {
        auth = this.buttonAuthorityList.editDepartmentManage;
      } else if (
        this.departmentDrawerData.data.modelList[data.i].line[data.j].title ==
          "部门成员" ||
        this.departmentDrawerData.data.modelList[data.i].line[data.j].title ==
          "机构主管"
      ) {
        auth = this.buttonAuthorityList.editDepartmentEmployee;
      }
      if (auth == 1) {
        console.log("删除标签事件:", data);
        let requestData = {
          userId: data.tag.id,
          roleId:
            this.departmentDrawerData.data.modelList[data.i].line[data.j]
              .title == "部门主管" ||
            this.departmentDrawerData.data.modelList[data.i].line[data.j]
              .title == "机构负责人"
              ? this.tempDepartment.managerRoleId
              : this.tempDepartment.employeeRoleId,
          type: "delete",
        };
        this.$request(
          "post",
          "/setting/setDepartmentRoleForEmployee",
          requestData,
          (resultData) => {
            this.$message({
              type: "success",
              message: this.$errorMessage.getErrorMessageInfo("update"),
              duration: 1000,
            });
            this.showDepartmentDetail(
              this.tempDepartment.departmentId,
              this.tempDepartment.departmentName
            );
          },
          (errorMessage) => {
            this.$message.error(errorMessage);
          }
        );
      } else {
        this.$message.error(
          this.$errorMessage.getErrorMessageInfo("authority")
        );
        return;
      }
    },
    /**
     * 抽屉按钮操作事件
     */
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "show_manager_authority":
          // 查看主管权限
          if (this.buttonAuthorityList.editDepartmentEmployeeAuth == 1) {
            this.tempAuthorityId = this.tempDepartment.managerRoleId;
            this.dialogData.width = "30%";
            this.dialogData.title = "角色权限编辑";
            this.dialogData.buttonArray = [
              {
                text: "确认",
                active: "edit_authority_choseModel",
              },
              {
                text: "取消",
                active: "cancel",
              },
            ];
            this.dialogData.data = [
              {
                title: "模块",
                type: "select",
                value: "",
                options: this.$systemBaseData.modelListToModelOptions(),
              },
            ];
            this.dialogData.flag = true;
          } else {
            this.$message.error(
              this.$errorMessage.getErrorMessageInfo("authority")
            );
          }
          break;

        case "show_employee_authority":
          // 查看员工权限
          if (this.buttonAuthorityList.editDepartmentManageAuth == 1) {
            this.tempAuthorityId = this.tempDepartment.employeeRoleId;
            this.dialogData.width = "30%";
            this.dialogData.title = "角色权限编辑";
            this.dialogData.buttonArray = [
              {
                text: "确认",
                active: "edit_authority_choseModel",
              },
              {
                text: "取消",
                active: "cancel",
              },
            ];
            this.dialogData.data = [
              {
                title: "模块",
                type: "select",
                value: "",
                options: this.$systemBaseData.modelListToModelOptions(),
              },
            ];
            this.dialogData.flag = true;
          } else {
            this.$message.error(
              this.$errorMessage.getErrorMessageInfo("authority")
            );
          }
          break;
        case "edit_authority_choseModel":
          requestData = { authorityId: this.tempAuthorityId };
          this.tempModel = this.dialogData.data[0].value;
          this.$request(
            "post",
            "/setting/getAuthorityList",
            requestData,
            (data) => {
              this.tempAuthorityData = JSON.parse(JSON.stringify(data));
              this.dialogData.title = "修改角色权限";
              this.dialogData.width = "720px";
              this.dialogData.buttonArray = [
                {
                  text: "确认",
                  active: "edit_authority",
                },
                {
                  text: "取消",
                  active: "cancel",
                },
              ];
              this.dialogData.data = [
                {
                  title: "",
                  type: "authority_dialog",
                  data: this.$authority.getAllRoleAuthorityList(
                    this.tempModel,
                    data
                  ),
                },
              ];
              console.log("权限具体信息:", this.dialogData.data);
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "edit_authority":
          // 确认修改部门角色权限
          requestData = {
            authorityId: this.tempAuthorityId,
            authorityData: this.$authority.formatSubmitAllRoleAuthorityList(
              this.tempAuthorityData,
              this.tempModel,
              JSON.parse(JSON.stringify(this.dialogData.data[0].data))
            ),
            authorityDataOld: {},
          };
          this.$request(
            "post",
            "/setting/saveAuthorityList",
            requestData,
            (data) => {
              this.$message({
                type: "success",
                message: this.$errorMessage.getErrorMessageInfo("update"),
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.getDepartmentTree();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
              this.dialogData.flag = false;
            }
          );
          break;
        case "add_department":
          // 确认添加部门
          requestData = {
            fatherId: this.tempDepartment.departmentId, // 上级部门的ID
            departmentName: this.dialogData.data[0].value, // 要添加的部门名称
            companyId: this.baseInfo.company.companyId, // 当前系统所选公司ID
          };
          this.$request(
            "post",
            "/setting/addDepartment",
            requestData,
            (data) => {
              this.$message({
                type: "success",
                message: this.$errorMessage.getErrorMessageInfo("add"),
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.getDepartmentTree();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
              this.dialogData.flag = false;
            }
          );
          break;
        case "cancel":
          this.colseDialog();
          break;
        default:
          break;
      }
    },
    /**
     * dialog父子组件连锁事件
     */
    authorityShow(data) {
      if (this.dialogData.data.length > 0) {
        for (let index = 0; index < this.dialogData.data.length; index++) {
          let element = this.dialogData.data[index];
          if (
            element.type == "authority_dialog" ||
            element.type == "authority_tips"
          ) {
            element.data = data;
          }
        }
      }
    },
    /**
     * 修改部门信息
     */
    saveDrawerData() {
      let requestData = {
        departmentId: this.tempDepartment.departmentId, // 当前修改的公司ID
        departmentName: this.departmentDrawerData.data.modelList[0].line[1]
          .value, // 要修改的公司名称
      };
      this.$request(
        "post",
        "/setting/updateDepartment",
        requestData,
        (data) => {
          this.$message({
            message: "操作成功!",
            type: "success",
            duration: 1000,
          });
          this.showDepartmentDetail(
            this.tempDepartment.departmentId,
            this.tempDepartment.departmentName
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 右边抽屉关闭事件
     */
    closeUpdateTableDrawer() {
      this.departmentDrawerData.flag = false;
      this.getDepartmentTree();
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 页面加载动画
     */
    Loading() {
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    },
  },
  mounted() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    // this.Loading();
    this.getModelAuthority();
  },
};
</script>

<style lang="less" scoped>
#department-setting {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .department-setting-changecompany {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 10px;
  }
  .department-setting-active {
    flex: 1;
  }
  .department-setting-but {
    width: 120px;
    min-width: 120px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @activeColor;
    font-size: @contentFontSize;
    color: #fff;
    margin: 0 5px;
    border-radius: 4px;
    transition: 0.5s;
    margin-right: 20px;
    cursor: pointer;
    i {
      margin: 0 4px;
    }
  }
  .department-setting-but:hover {
    background-color: @activeColor2;
  }
  .department-setting-content {
    width: 98%;
    height: 95%;
    max-width: 90vw;
    max-height: 900px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 1px;
    }

    .department-setting-content-line {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 1px solid @boderColor;
      .department-setting-content-line-show {
        margin-left: 50px;
      }
      span {
        width: auto;
        display: flex;
        margin-left: 40px;
        font-size: 15px;
        color: @editGrayColor;
        > div {
          margin: 0 10px;
        }
      }
    }
    .department-setting-content-line:hover {
      cursor: pointer;
      background-color: @activeBackground;
    }
  }
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: @contentFontSize3;
    padding-right: 18px;
  }
}
</style>
