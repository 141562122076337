<template>
  <div id="performance-calculation-rules">
    <!-- 添加服务类别时 添加业绩计算规则 -->
    <div class="performance-calculation-rules-line">
      <div class="performance-calculation-rules-line-title">类别名称:</div>
      <div class="performance-calculation-rules-line-value">
        <el-input v-model="value.serveName" placeholder="请输入内容"></el-input>
      </div>
    </div>
    <div class="performance-calculation-rules-line">
      <div class="performance-calculation-rules-line-title">业绩计算规则:</div>
    </div>
    <div class="performance-calculation-rules-table">
      <div
        class="performance-calculation-rules-table-line"
        v-for="(item, index) in value.rulesList"
        :key="index"
      >
        <div class="performance-calculation-rules-table-line-div">
          <span>利润:</span>
          <div class="performance-calculation-rules-table-line-value">
            <el-input v-model="item.scopeOf[0]" placeholder="0 "></el-input>
          </div>
          <span>~</span>
          <div class="performance-calculation-rules-table-line-value">
            <el-input
              v-model="item.scopeOf[1]"
              placeholder="0 "
              :disabled="index == value.rulesList.length - 1"
              @change="changeMoney(item.scopeOf[1], index)"
            ></el-input>
          </div>
          <span>元</span>
        </div>
        <div class="performance-calculation-rules-table-line-div"></div>
        <div class="performance-calculation-rules-table-line-div">
          <span>记</span>
          <div class="performance-calculation-rules-table-line-value">
            <el-input v-model="item.value" placeholder="0 "></el-input>
          </div>
          <span>点</span>
        </div>
        <div class="performance-calculation-rules-table-line-div">
          <i class="el-icon-circle-plus-outline" @click="add(index)"></i>
          <i class="el-icon-remove-outline" @click="dele(item, index)"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["performanceCalculationRulesData"],
  data() {
    return {
      value: this.performanceCalculationRulesData,
      //   value: {
      //     serveName: "", // 服务类别名称
      //     rulesList: [
      // {
      //   scopeOf: ["0 ", "1000 "],
      //   value: "0.5",
      // },
      // {
      //   scopeOf: ["1001 ", "2000 "],
      //   value: "1",
      // },
      //     ],
      //   },
    };
  },
  watch: {
    performanceCalculationRulesData() {
      this.value = this.performanceCalculationRulesData;
    },
    value(val) {
      this.$emit("show", val);
    },
  },
  methods: {
    dele(data, index) {
      if (
        index == this.value.rulesList.length - 1 &&
        this.value.rulesList.length > 1
      ) {
        this.value.rulesList[index - 1].scopeOf[1] = "∞";
      }
      this.value.rulesList.splice(index, 1);
      if (this.value.rulesList.length == 0) {
        this.add(0);
      }
    },
    add(index) {
      let dd = {
        scopeOf: ["0", "1000"],
        value: "0.5",
      };
      if (this.value.rulesList.length == 0) {
        dd.scopeOf[1] = "∞";
        this.value.rulesList.splice(index, 0, dd);
      } else if (index == this.value.rulesList.length - 1) {
        //   如果是最后一个规则,则在之前添加
        this.value.rulesList.splice(index, 0, dd);
        if (this.value.rulesList.length == 2) {
          // 只有一个规则
          this.value.rulesList[index].scopeOf[1] = parseFloat(
            this.value.rulesList[index + 1].scopeOf[0]
          );
        } else {
          // 前一个规则的大值 == 新增规则的小值
          this.value.rulesList[index].scopeOf[0] = parseFloat(
            this.value.rulesList[index - 1].scopeOf[1]
          );
          // 后一个规则的小值 == 新增规则的大值
          this.value.rulesList[index].scopeOf[1] = parseFloat(
            this.value.rulesList[index + 1].scopeOf[0]
          );
        }
      } else {
        //   在该条规则之后添加
        this.value.rulesList.splice(index + 1, 0, dd);
        this.value.rulesList[index + 1].scopeOf[0] = parseFloat(
          this.value.rulesList[index].scopeOf[1]
        );
      }
    },
    /**
     * 改变规则时,大值改变,下一行利润的首值跟着变化
     */
    changeMoney(value, index) {
      if (index < this.value.rulesList.length - 1) {
        // 不是最后一行规则
        this.value.rulesList[index + 1].scopeOf[0] = value;
      }
    },
  },
  mounted() {
    if (this.value.rulesList.length == 0 || this.value.rulesList == "") {
      this.value.rulesList = [
        {
          scopeOf: ["0 ", "1000 "],
          value: "0.5",
        },
        {
          scopeOf: ["1001 ", "2000 "],
          value: "1",
        },
      ];
    }
  },
};
</script>

<style lang="less" scoped>
#performance-calculation-rules {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .performance-calculation-rules-line {
    width: 100%;
    min-height: 30px;
    height: auto;
    display: flex;
    align-items: center;
    .performance-calculation-rules-line-title {
      width: auto;
      height: 100%;
      margin-left: 5px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }
    .performance-calculation-rules-line-value {
      flex: 1;
      width: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .performance-calculation-rules-table {
    width: 100%;
    height: 300px;
    max-height: 300px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0px;
    }
    .performance-calculation-rules-table-line {
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      margin-top: 3px;
      .performance-calculation-rules-table-line-div {
        width: 0;
        height: 100%;
        flex: 2;
        display: flex;
        align-items: center;
        font-size: 12px;
        &:nth-child(1) {
          flex: 4;
        }
        &:nth-child(2),
        &:nth-child(4) {
          flex: 1;
        }
        .performance-calculation-rules-table-line-value {
          flex: 1;
          width: 0;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        span {
          width: auto;
          height: 100%;
          padding: 0 5px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        i {
          margin: 5px;
          transition: 0.3s;
          &:hover {
            cursor: pointer;
            color: @activeColor;
          }
        }
      }
    }
  }
}
</style>