<template>
  <div id="leaveContent">
    <el-collapse v-model="activeNames" accordion>
      <el-collapse-item
        :title="item.name"
        v-for="(item, index) in value"
        :name="item.name"
        :key="index"
      >
        <div
          class="leaveContent-item"
          v-html="item.value"
          style="white-space: pre-wrap"
        ></div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  props: ["leaveContentData"],
  data() {
    return {
      //   value: [{ name: "1", value: "s\nd\nw" }],
      value: this.leaveContentData,
      activeNames: "",
    };
  },
  mounted() {
    this.activeNames = this.value[0].name;
  },
};
</script>

<style lang="less" scoped>
#leaveContent {
  width: 100%;
  height: 60vh;
  max-height: 60vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0px;
  }
  .leaveContent-item {
    width: 100%;
    height: 20vh;
    overflow-y: auto;
    text-align: left;
    font-size: 12px;
  }
}
</style>