<template>
  <div id="social-security">
    <!-- 社保统计 -->
    <div class="social-security-screen">
      <div class="social-security-screen-line">
        <div class="social-security-screen-search">
          <el-input
            v-model="searchData.input"
            placeholder="请输入客户全称"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="social-security-screen-search">
          <el-cascader
            v-model="searchData.department"
            placeholder="请选择部门"
            :options="searchData.departTreeData"
            @change="initTableData"
            clearable
            :show-all-levels="false"
            :props="{ checkStrictly: true, value: 'id' }"
            filterable
          ></el-cascader>
        </div>
        <div class="social-security-screen-search">
          <el-select
            v-model="searchData.userId"
            @change="selectInitTableData"
            filterable
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="social-security-screen-search">
          <el-date-picker
            v-model="searchData.date"
            type="monthrange"
            range-separator="至"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
            value-format="yyyy-MM"
            :clearable="false"
            @change="initTableData"
          >
          </el-date-picker>
        </div>
        <div class="social-security-screen-active"></div>
        <!-- <div
          v-loading="pictLoading"
          element-loading-background="rgba(0, 0, 0, 0.2)"
          element-loading-spinner="el-icon-loading"
          class="social-security-screen-but"
          @click="handleClick('', 0)"
        >
          新增人员
        </div> -->
      </div>
    </div>

    <div class="social-table">
      <div class="social-security-table-card">
        <div class="social-security-content">
          <el-table
            :data="tableData"
            :header-cell-style="{ background: '#F5F7FA', color: '#606266' }"
            highlight-current-row
            style="width: 100%"
            height="98%"
            border
            v-loading="loading"
            :row-style="{ height: '60px' }"
            show-summary
            :summary-method="getSummaries"
          >
            <el-table-column type="index" width="40" fixed> </el-table-column>
            <el-table-column label="客户名称" width="250" fixed align="center">
              <template slot-scope="scope">
                <span
                  @click.stop="handleClick(scope.row, 2)"
                  style="color: #40a3ff; cursor: pointer;"
                  >{{ scope.row.clientName }}</span
                ></template
              >
            </el-table-column>
            <el-table-column
              prop="clientNum"
              label="客户编号"
              width="160"
              fixed
              align="center"
            ></el-table-column>
            <el-table-column
              prop="numberOfPeople"
              label="参保人数"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="totalPaymentAmount"
              label="单位+个人缴费"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="unitPaymentAmount"
              label="单位缴费"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="personalPaymentAmount"
              label="个人缴费"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="medicalAmountOfUnit"
              label="医疗单位"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="medicalAmountOfPersonal"
              label="医疗个人"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="retirementAmountOfUnit"
              label="养老单位"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="retirementAmountOfPersonal"
              label="养老个人"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="unemploymentAmountOfUnit"
              label="失业单位"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="unemploymentAmountOfPersonal"
              label="失业个人"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="industrialInjuryAmountOfUnit"
              label="工伤"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="personalLateFee"
              label="滞纳金"
              width="120"
            ></el-table-column>
            <el-table-column label="操作" width="200" fixed="right">
              <template slot-scope="scope">
                <el-button
                  style="color: #67C23A"
                  type="text"
                  @click="handleClick(scope.row, 0)"
                  v-showFunction="{
                    active: 'add',
                    array: allFunctionButtons,
                  }"
                  >增员</el-button
                >
                <el-button
                  style="color: #67C23A"
                  type="text"
                  @click="handleClick(scope.row, 3)"
                  v-showFunction="{
                    active: 'delete',
                    array: allFunctionButtons,
                  }"
                  >减员</el-button
                >
                <el-button
                  type="text"
                  @click="handleClick(scope.row, 1)"
                  v-showFunction="{
                    active: 'import',
                    array: allFunctionButtons,
                  }"
                  >导入</el-button
                >
                <el-button
                  type="text"
                  @click="handleClick(scope.row, 4)"
                  v-showFunction="{
                    active: 'record',
                    array: allFunctionButtons,
                  }"
                  >操作记录</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="social-security-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :clientName="dialogData.clientName"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @handleAvatarSuccess="handleAvatarSuccess"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import * as taxData from "@/views/tax/js/tax.js";
import my_dialog from "@/components/dialog.vue";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
NProgress.configure({ showSpinner: false });
NProgress.configure({ parent: "#social-security .social-table" });
import popo_search from "@/components/popo-search.vue";

export default {
  components: {
    popo_search,
    my_dialog,
  },
  data() {
    return {
      pictLoading: false,
      tabIndex: 0,
      loading: false,
      baseInfo: {},
      tableData: [],
      searchData: {
        input: "",
        department: "",
        departTreeData: [],
        userId: "",
        userList: [],
        userIdList: [],
        tempUserId: [],
        date: [
          this.$formatTime(
            new Date(new Date().toLocaleDateString()).getTime() -
              31 * 24 * 3600 * 1000,
            "YYYY-MM"
          ),
          this.$formatTime(
            new Date(new Date().toLocaleDateString()).getTime() -
              31 * 24 * 3600 * 1000,
            "YYYY-MM"
          ),
        ],
      },
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "socialSecurity"
      ),
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "运单详情",
        buttonArray: [
          {
            text: "确认",
            active: "confirm",
          },
        ],
        data: [],
      },

      tempExpressageMaintain: {},
      whetherAgencyHead: false,
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "socialSecurity",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getDepartmentTreeByUserId();
      });
    },
    /**
     * 获取部门
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
          this.getEmployeeList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.searchData.userList = [];
          data.forEach((element) => {
            let dd = {
              name: element.userName,
              id: element.userId,
            };
            this.searchData.userIdList.push(element.userId);
            this.searchData.userList.push(dd);
          });
          this.searchData.userList.splice(0, 0, {
            id: "all",
            name: "全部人员",
          });
          this.searchData.userId = this.baseInfo.userId;
          this.selectInitTableData(this.baseInfo.userId);
        },

        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      // this.tableData = [];
      this.loading = true;
      let requestData = {
        // unassociated: this.tabIndex,
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
        clientName: this.searchData.input, // 客户简称
        department:
          this.searchData.department.length > 0
            ? this.searchData.department[this.searchData.department.length - 1]
            : "", // 部门 id
        userIdList: this.searchData.tempUserId, // 员工 id 集合
        date: this.searchData.date, // 日期
        // sortOrder: this.defaultSort.order, // 手动排序规则 ascending descending ""
      };
      this.$request(
        "post",
        "/socialSecurity/pageByCondition",
        requestData,
        (data) => {
          this.tableData = data.records;
          this.pageData.total = data.total; // 获取总条目数
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },

    /**
     * 界面操作
     */
    handleClick(data, flag) {
      this.tempSocial = data;
      let requestData = {};
      switch (flag) {
        case 0:
          // 新增人员
          // 下个月份
          let nextMonth = this.$formatTime(
            new Date(new Date().toLocaleDateString()).getTime() +
              31 * 24 * 3600 * 1000,
            "YYYY-MM"
          );
          this.dialogData.title = "新增人员";
          this.dialogData.clientName = this.tempSocial.clientName;
          this.dialogData.width = "700px";
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "make_sure_add",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          // 获取上个月
          let previousMonth = this.$formatTime(
            new Date(new Date().toLocaleDateString()).getTime() -
              31 * 24 * 3600 * 1000,
            "YYYY-MM"
          );
          requestData = {
            clientId: this.tempSocial.clientId, // 客户 id
            businessDate: previousMonth, // 日期
          };
          this.$request(
            "post",
            "/socialSecurity/getByBusinessDate",
            requestData,
            (data) => {
              this.dialogData.data = [
                {
                  type: "add_social",
                  data: {
                    name: "", // 姓名
                    identityCard: "", // 身份证号
                    gender: "男", // 性别
                    phone: "", // 手机号
                    businessDate: nextMonth, // 业务年月
                    paymentDate: "", // 缴费年月
                    unitPaymentBase: data.unitPaymentBase
                      ? data.unitPaymentBase.toString()
                      : "", // 单位缴费基数
                    industrialInjuryAmountOfUnit:
                      data.industrialInjuryAmountOfUnit || "", // 工伤单位
                    annex: [], // 附件
                  },
                },
              ];
              this.dialogData.flag = true;
            },
            (err) => {
              this.$message.error(err);
            }
          );

          break;
        case 1:
          // 导入
          if (this.pictLoading == true) {
            this.$message.error("正在导入资源,请耐心等待");
            return;
          }
          this.dialogData.width = "576px";
          this.dialogData.title = "导入社保";
          this.dialogData.clientName = this.tempSocial.clientName;
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "makeSure_import_social",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = taxData.importSocialSecurityData(this);
          this.dialogData.flag = true;
          break;
        case 2:
          // 查看公司社保缴纳人员
          // 获取今年的一月
          let currentMonth = this.$formatTime(new Date(), "YYYY-MM");
          requestData = {
            clientId: this.tempSocial.clientId, // 客户 id
            pageSize: 10,
            currentPage: 1,
            date: [currentMonth, currentMonth], // 日期
            name: "", // 姓名
          };
          this.$request(
            "post",
            "/socialSecurity/pageByClient",
            requestData,
            (data) => {
              this.dialogData.width = "1300px";
              this.dialogData.title = "公司社保缴纳详情";
              this.dialogData.clientName = this.tempSocial.clientName;
              this.dialogData.buttonArray = [];
              this.dialogData.data = [
                {
                  type: "table_social",
                  data: {
                    ...data,
                    clientId: this.tempSocial.clientId,
                    date: [currentMonth, currentMonth],
                    name: "",
                  },
                },
              ];
              console.log("dialog数据", this.dialogData);
              this.dialogData.flag = true;
            },
            (err) => {
              this.$message.error(err);
            }
          );
          break;
        case 3:
          // 减员
          this.dialogData.width = "600px";
          this.dialogData.title = "减少人员";
          this.dialogData.clientName = this.tempSocial.clientName;
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "makeSure_reduce",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              type: "reduce-people",
              title: "公司人员",
              name: "",
              gender: "",
              identityCard: "",
              clientId: this.tempSocial.clientId,
            },
          ];
          console.log("dialog数据", this.dialogData);
          this.dialogData.flag = true;
          break;
        case 4:
          // 操作记录
          this.$operationRecords.showOperationRecords(
            this.tempSocial.clientId,
            "tax",
            this
          );
          break;
        default:
          break;
      }
    },
    /**
     * 抽屉数据或dialog数据操作事件
     */
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "make_sure_add":
          requestData = {
            clientId: this.tempSocial.clientId, // 客户 id
            ...this.dialogData.data[0].data,
            industrialInjuryAmountOfPersonal: 0, // 工伤个人缴费额
            annex: this.dialogData.data[0].data.annex.toString(),
            addOrDel: "0", // 增员
            submitUserId: this.baseInfo.userId, // 提交人
          };
          if (!requestData.name) {
            this.$message.error("姓名不能为空!");
            return;
          }
          if (!requestData.identityCard) {
            this.$message.error("身份证号不能为空!");
            return;
          }
          let flag1 = true;
          if (!this.$phoneCheck.mobilePhoneNumberCheck(requestData.phone)) {
            // 做手机号格式校验
            flag1 = false;
          }
          if (!flag1) {
            this.$message({
              message: "联系人手机号格式不正确,请确认填入正确的手机号!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          if (!requestData.businessDate) {
            this.$message.error("业务年月不能为空!");
            return;
          }
          if (!requestData.paymentDate) {
            this.$message.error("缴费年月不能为空!");
            return;
          }
          if (!requestData.unitPaymentBase) {
            this.$message.error("单位缴费基数不能为空!");
            return;
          }
          this.$request(
            "post",
            "/socialSecurityReview/addSocialSecurityReview",
            requestData,
            (data) => {
              this.$operationRecords.saveOperationRecords(
                this.tempSocial.clientId,
                `社保增员提交-${requestData.name}`,
                this.baseInfo,
                "tax",
                this,
                (data2) => {
                  this.$message.success("增员审核已添加, 请耐心等待审核!");
                  this.dialogData.flag = false;
                  this.initTableData();
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "makeSure_reduce":
          // 确定减员
          requestData = {
            clientId: this.tempSocial.clientId, // 客户 id
            name: this.dialogData.data[0].name, // 姓名
            gender: this.dialogData.data[0].gender, // 性别
            phone: this.dialogData.data[0].phone, // 手机号
            identityCard: this.dialogData.data[0].identityCard, // 身份证号
            paymentDate: "", // 缴费年月
            businessDate: "", // 日期
            addOrDel: "1", // 减员
            submitUserId: this.baseInfo.userId, // 提交人
          };
          if (!requestData.name || !requestData.identityCard) {
            this.$message.error("姓名和身份证号不能为空!");
            return;
          }
          this.$request(
            "post",
            "/socialSecurityReview/addSocialSecurityReview",
            requestData,
            (data) => {
              this.$operationRecords.saveOperationRecords(
                this.tempSocial.clientId,
                `社保减员提交-${requestData.name}`,
                this.baseInfo,
                "tax",
                this,
                (data2) => {
                  this.$message.success("减员审核已添加, 请耐心等待审核!");
                  this.dialogData.flag = false;
                  this.initTableData();
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "makeSure_import_social":
          // 确定导入税务模版
          requestData = {
            clientId: this.tempSocial.clientId, // 客户 id
            url: this.dialogData.data[0].value.url, // 文件路径
          };
          if (requestData.url == "") {
            this.$message.error("请先选择要导入的文件!");
            return;
          }
          // NProgress.start();
          // this.pictLoading = true;
          console.log("导入资源数据:", requestData);
          this.$request(
            "get",
            "/socialSecurity/importExcel?data=" + JSON.stringify(requestData),
            {},
            (data) => {
              this.$operationRecords.saveOperationRecords(
                this.tempSocial.clientId,
                "社保导入!",
                this.baseInfo,
                "tax",
                this,
                (data2) => {
                  // this.pictLoading = false;
                  // NProgress.done();
                  this.$message.success(data);
                  this.dialogData.flag = false;
                  this.initTableData();
                }
              );
            },
            (err) => {
              this.$message({
                message: err,
                type: "error",
              });
              this.dialogData.flag = false;
              // this.pictLoading = false;
              // NProgress.done();
              this.initTableData();
            }
          );
          this.dialogData.flag = false;
          break;
        case "cancel":
          this.colseDialog();
          break;
        default:
          break;
      }
    },

    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    /**
     * 客户地址导入成功
     */
    handleAvatarSuccess(response) {
      this.$message({
        message: "上传成功!",
        type: "success",
        duration: 1000,
      });
      this.dialogData.data[0].data.file = response.result;
    },
    /**
     * 员工选择
     */
    selectInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.searchData.userIdList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.initTableData();
    },
    getSummaries(param) {
      const { columns, data } = param;

      const sums = [];
      columns.forEach((column, index) => {
        if (index == 1) {
          sums[index] = "合计";
          return;
        }
        if (index == 0 || index == 2 || index == 15) {
          sums[index] = "";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            let sum = Number(Number(prev) + Number(curr)).toFixed(2); //toFixed(2)数据项保留两位小数
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = Math.round(sums[index] * 100) / 100;
        } else {
          sums[index] = "N/A";
        }
      });

      return sums;
    },
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#social-security {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .social-security-screen {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    .social-security-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      > .title {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 0 10px;
      }
      > .el-tag {
        margin: 0 5px;
      }
      .social-security-screen-fiftrate {
        width: 65px;
        min-width: 65px;
        height: 30px;
        border: 1px solid @boderColor;
        box-sizing: border-box;
        color: @boderActiveColor;
        font-size: @contentFontSize;
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: 0.3s;
        i {
          margin: 0 4px;
        }
      }
      .social-security-screen-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }
      .social-security-screen-search {
        width: 210px;
        min-width: 210px;
        height: 30px;
        margin-left: 10px;
        display: flex;
        align-items: center;
      }
      .social-security-screen-active {
        flex: 1;
      }
      .drop-button {
        background-color: #ecf5ff;
        color: #409eff;
        &:hover {
          background-color: #abbed3;
        }
      }
      .social-security-screen-but {
        width: 100px;
        min-width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .social-security-screen-but:hover {
        cursor: pointer;
        background-color: @activeColor2;
      }
      .social-security-screen-but-boder {
        min-width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        font-size: @contentFontSize;
        background-color: @activeBackground;
        color: @activeColor;
        margin: 0 5px;
        margin-right: 10px;
        border-radius: 4px;
        transition: 0.5s;
        padding: 0 15px;
      }
      .social-security-screen-but-boder:hover {
        cursor: pointer;
        background-color: @activeBackground2;
      }
    }
  }

  .social-table {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;

    .social-security-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .social-security-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .social-security-table-card-tab {
          width: 120px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .social-security-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
      }
      .social-security-content {
        width: 100%;
        height: 95%;
        max-width: 90vw;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
      }
      .social-security-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;

        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
