export const waitDealLocation = {
    methods: {
        /**
     * 获取地址列表
     */
    waitDealLocation() {
        this.$request(
          "post",
          "/waitDealLocation/getTreeList",
          { flag: true },
          (data) => {
            data.forEach((item) => {
              item.children.forEach((ele) => {
                ele.children = "";
              });
            });
            this.list = data;
            this.isAdd = false;
          },
          (errormessage) => {
            this.$message.error(errormessage);
          }
        );
      }
    }
}
