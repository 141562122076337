<template>
  <div id="move-file">
    <div class="move-file-title">
      选择将要移动到的文件夹，点击“移动到此处”完成移动操作
    </div>
    <div class="move-file-tree">
      <el-tree
        ref="tree"
        :data="treeList"
        node-key="id"
        :props="defaultProps"
        default-expand-all
        check-strictly
        show-checkbox
        @node-click="handleNodeClick"
        @check-change="checkChange"
      >
        <template #default="{ data }">
          <div class="move-file-tree-line">
            <div class="move-file-tree-line-name">
              <span class="icon"><i class="iconfont icon-folder"></i></span>
              <span>{{ data.name }}</span>
            </div>
          </div>
        </template></el-tree
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fileData: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  data() {
    return {
      treeList: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
    };
  },
  methods: {
    queryFolderTree(clientId) {
      let arr = this.fileData.multipleSelection.map((item) => item.id);
      let requestData = {
        clientId,
        filteredIds: arr,
        clientAppShow: "", // 客户是否可见  '' 所有 0-客户不可见 1-客户可见
      };
      this.$request(
        "post",
        "/fileManage/queryFolderTree",
        requestData,
        (data) => {
          this.treeList = data;
          this.$nextTick(() => {
            this.$refs.tree.filter();
          });
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    handleNodeClick(data, node, self) {
      //自己定义的editCheckId，防止单选出现混乱
      this.fileData.targetFolderId = data.id;
      this.$refs.tree.setCheckedKeys([data.id]);
    },
    checkChange(data, node, self) {
      if (node == true) {
        this.fileData.targetFolderId = data.id;
        this.$refs.tree.setCheckedKeys([data.id]);
      } else {
        if (this.fileData.targetFolderId == data.id) {
          this.$refs.tree.setCheckedKeys([data.id]);
        }
      }
    },
  },
  created() {
    this.queryFolderTree(this.fileData.clientId);
  },
};
</script>

<style lang="less" scoped>
#move-file {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  .move-file-title {
    font-size: 14px;
  }
  .move-file-tree {
    width: 100%;
    margin: 20px 0;
    .move-file-tree-line {
      width: 90%;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      .move-file-tree-line-name {
        width: 300px;
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        white-space: nowrap;
        .icon {
          margin-right: 10px;
        }
      }
      .move-file-tree-line-radio {
      }
    }
    /deep/ .el-tree-node__content {
      border: 1px solid #6161611f;
      border-bottom: 0;
      display: flex;
      flex-direction: row-reverse;
    }
  }
}
</style>
