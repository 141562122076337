var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"archives-info"}},[_c('div',{staticClass:"archives-info-screen"},[_c('div',{staticClass:"archives-info-screen-line"},[_c('div',{staticClass:"archives-info-screen-line-fiftrate"},[_c('el-popover',{attrs:{"placement":"bottom-start","width":"250","trigger":"manual","visible-arrow":false},model:{value:(_vm.popoData.popoVisible),callback:function ($$v) {_vm.$set(_vm.popoData, "popoVisible", $$v)},expression:"popoData.popoVisible"}},[_c('div',{staticClass:"popover-search"},[_c('popo_search',{attrs:{"list":_vm.popoData.list},on:{"cancel":_vm.popoCanael,"reset":_vm.popoReset,"makeSure":_vm.popoMakeSure}})],1),_c('div',{attrs:{"slot":"reference"},on:{"click":_vm.popoverOpen},slot:"reference"},[_vm._v(" 筛选 "),_c('i',{staticClass:"el-icon-s-operation"})])])],1),_c('div',{staticClass:"archives-info-screen-search"},[_c('el-input',{attrs:{"placeholder":"请输入客户全称/编号/税号"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.initTableData($event)}},model:{value:(_vm.searchData.input),callback:function ($$v) {_vm.$set(_vm.searchData, "input", $$v)},expression:"searchData.input"}},[_c('el-button',{attrs:{"slot":"append","icon":"el-icon-search"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.initTableData($event)}},slot:"append"})],1)],1),_c('div',{staticClass:"archives-info-screen-search"},[_c('el-cascader',{attrs:{"placeholder":"请选择物品","options":_vm.categoryList,"collapse-tags":"","props":{
            multiple: true,
            value: 'id',
            label: 'name',
            emitPath: false,
          },"clearable":"","filterable":""},on:{"change":_vm.initTableData},model:{value:(_vm.searchData.categoryId),callback:function ($$v) {_vm.$set(_vm.searchData, "categoryId", $$v)},expression:"searchData.categoryId"}})],1),_c('div',{staticClass:"archives-info-screen-search"},[_c('el-cascader',{attrs:{"placeholder":"请选择位置","options":_vm.locationList,"collapse-tags":"","props":{
            multiple: true,
            value: 'id',
            label: 'name',
            emitPath: false,
          },"clearable":"","filterable":""},on:{"change":_vm.initTableData},model:{value:(_vm.searchData.locationId),callback:function ($$v) {_vm.$set(_vm.searchData, "locationId", $$v)},expression:"searchData.locationId"}})],1),_c('div',{staticClass:"archives-info-screen-search"},[_c('el-select',{attrs:{"filterable":""},on:{"change":_vm.selectInitTableData},model:{value:(_vm.searchData.createId),callback:function ($$v) {_vm.$set(_vm.searchData, "createId", $$v)},expression:"searchData.createId"}},_vm._l((_vm.searchData.allUserList),function(item){return _c('el-option',{key:item.userId,attrs:{"label":item.userName,"value":item.userId}})}),1)],1),_c('div',{staticClass:"archives-info-screen-active"}),_c('div',[_c('el-dropdown',[_c('el-button',{staticClass:"drop-button",attrs:{"type":"small"}},[_vm._v(" 批量操作"),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{directives:[{name:"showFunction",rawName:"v-showFunction",value:({
                active: 'getBatch',
                array: _vm.allFunctionButtons,
              }),expression:"{\n                active: 'getBatch',\n                array: allFunctionButtons,\n              }"}],nativeOn:{"click":function($event){return _vm.handleClick('', 1, true)}}},[_vm._v("批量领用")]),_c('el-dropdown-item',{directives:[{name:"showFunction",rawName:"v-showFunction",value:({
                active: 'saveBatch',
                array: _vm.allFunctionButtons,
              }),expression:"{\n                active: 'saveBatch',\n                array: allFunctionButtons,\n              }"}],nativeOn:{"click":function($event){return _vm.handleClick('', 2, true)}}},[_vm._v("批量归还")]),_c('el-dropdown-item',{directives:[{name:"showFunction",rawName:"v-showFunction",value:({
                active: 'returnBatch',
                array: _vm.allFunctionButtons,
              }),expression:"{\n                active: 'returnBatch',\n                array: allFunctionButtons,\n              }"}],nativeOn:{"click":function($event){return _vm.handleClick('', 3, true)}}},[_vm._v("批量交付")]),_c('el-dropdown-item',{directives:[{name:"showFunction",rawName:"v-showFunction",value:({
                active: 'moveBatch',
                array: _vm.allFunctionButtons,
              }),expression:"{\n                active: 'moveBatch',\n                array: allFunctionButtons,\n              }"}],nativeOn:{"click":function($event){return _vm.handleClick('', 4, true)}}},[_vm._v("批量转移")])],1)],1)],1),_c('div',{directives:[{name:"showFunction",rawName:"v-showFunction",value:({
          active: 'add',
          array: _vm.allFunctionButtons,
        }),expression:"{\n          active: 'add',\n          array: allFunctionButtons,\n        }"}],staticClass:"archives-screen-but-select",on:{"click":function($event){return _vm.handleClick('', 0, false)}}},[_vm._v(" 新增档案 ")])]),(_vm.searchData.screenList.length > 0)?_c('div',{staticClass:"archives-info-screen-line"},[_c('div',{staticClass:"title"},[_vm._v("筛选条件:")]),_vm._l((_vm.searchData.screenList),function(tag,index){return _c('el-tag',{key:index,attrs:{"closable":"","size":"small"},on:{"close":function($event){return _vm.closeScreen(index)}}},[_vm._v(" "+_vm._s(tag.name)+" ")])})],2):_vm._e()]),_c('div',{staticClass:"table-content"},[_c('div',{staticClass:"archives-info-table-card"},[_c('div',{staticClass:"archives-info-content"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"tableRef",staticStyle:{"width":"100%, overflow-x:auto"},attrs:{"data":_vm.tableData,"highlight-current-row":"","height":"98%","row-key":"id","row-style":{ height: '60px' },"tree-props":{
            children: 'archivesList',
          },"cell-class-name":_vm.cellStyle},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"30"}}),_c('el-table-column',{attrs:{"type":"index","width":"40","fixed":""}}),_c('el-table-column',{attrs:{"prop":"clientName","label":"客户全称","show-overflow-tooltip":"","width":"220px"}}),_c('el-table-column',{attrs:{"prop":"clientNum","label":"客户编号"}}),_c('el-table-column',{attrs:{"prop":"name","label":"录入物品","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"location","label":"录入位置","width":"100","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"createName","label":"录入人","width":"100","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"label":"状态","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{style:({ color: scope.row.color })},[_vm._v(" "+_vm._s(scope.row.status))])]}}])}),_c('el-table-column',{attrs:{"prop":"gmtModified","label":"操作时间","width":"150"}}),_c('el-table-column',{attrs:{"label":"操作","width":"350","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return (!scope.row.archivesList)?[(scope.row.status == '未领用')?_c('el-button',{directives:[{name:"showFunction",rawName:"v-showFunction",value:({
                  active: 'get',
                  array: _vm.allFunctionButtons,
                }),expression:"{\n                  active: 'get',\n                  array: allFunctionButtons,\n                }"}],attrs:{"type":"primary","plain":"","size":"mini","icon":"el-icon-edit-outline"},nativeOn:{"click":function($event){return _vm.handleClick(scope.row, 1, false)}}},[_vm._v("领用")]):_vm._e(),(scope.row.recipient == _vm.baseInfo.userId)?_c('el-button',{directives:[{name:"showFunction",rawName:"v-showFunction",value:({
                  active: 'save',
                  array: _vm.allFunctionButtons,
                }),expression:"{\n                  active: 'save',\n                  array: allFunctionButtons,\n                }"}],attrs:{"type":"warning","plain":"","size":"mini","icon":"el-icon-edit"},on:{"click":function($event){return _vm.handleClick(scope.row, 2, false)}}},[_vm._v("归还")]):_vm._e(),(scope.row.status == '未领用')?_c('el-button',{directives:[{name:"showFunction",rawName:"v-showFunction",value:({
                  active: 'move',
                  array: _vm.allFunctionButtons,
                }),expression:"{\n                  active: 'move',\n                  array: allFunctionButtons,\n                }"}],attrs:{"type":"info","plain":"","size":"mini","icon":"el-icon-share"},on:{"click":function($event){return _vm.handleClick(scope.row, 4, false)}}},[_vm._v("转移")]):_vm._e(),(
                  scope.row.status == '未领用' ||
                    scope.row.recipient == _vm.baseInfo.userId
                )?_c('el-button',{directives:[{name:"showFunction",rawName:"v-showFunction",value:({
                  active: 'return',
                  array: _vm.allFunctionButtons,
                }),expression:"{\n                  active: 'return',\n                  array: allFunctionButtons,\n                }"}],attrs:{"type":"success","plain":"","size":"mini","icon":"el-icon-check"},on:{"click":function($event){return _vm.handleClick(scope.row, 3, false)}}},[_vm._v("交付")]):_vm._e(),_c('el-button',{directives:[{name:"showFunction",rawName:"v-showFunction",value:({
                  active: 'record',
                  array: _vm.allFunctionButtons,
                }),expression:"{\n                  active: 'record',\n                  array: allFunctionButtons,\n                }"}],attrs:{"type":"primary","plain":"","size":"mini"},on:{"click":function($event){return _vm.handleClick(scope.row, 5, false)}}},[_vm._v("操作记录")])]:undefined}}],null,true)})],1)],1),_c('div',{staticClass:"archives-info-table-card-bottom"},[_c('el-pagination',{attrs:{"current-page":_vm.pageData.currentPage,"page-size":_vm.pageData.pageSize,"layout":"total,sizes, prev, pager, next,jumper","total":_vm.pageData.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){return _vm.$set(_vm.pageData, "currentPage", $event)},"update:current-page":function($event){return _vm.$set(_vm.pageData, "currentPage", $event)}}})],1)])]),(_vm.dialogData.flag)?_c('div',{attrs:{"id":"my-dialog"}},[_c('my_dialog',{attrs:{"width":_vm.dialogData.width,"title":_vm.dialogData.title,"buttonArray":_vm.dialogData.buttonArray,"data":_vm.dialogData.data,"allFunctionButtons":_vm.allFunctionButtons},on:{"colseDialog":_vm.colseDialog,"dialogOper":_vm.drawerOper}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }