<template>
  <contractWork></contractWork>
</template>

<script>
import contractWork from "../contract/contract_work.vue";
export default {
  data() {
    return {};
  },
  components: {
    contractWork,
  },
};
</script>

<style></style>
