<template>
  <div id="contract-in-attachment">
    <div class="attachment-tip">
      <i class="el-icon-info"></i>
      <span>文件可上传jpg、png、doc、docx、xls、xlsx、pdf类型文件</span>
    </div>

    <div class="attachment-content">
      <div
        class="attachment-content-item"
        v-for="(item, index) in value.data"
        :key="index"
      >
        <div class="attachment-content-item-index">
          {{ index + 1 }}
          <div class="right">
            <div
              class="text"
              v-if="item.qrUrl != ''"
              @click="showQr(item.qrUrl)"
            >
              <el-tooltip
                class="item"
                effect="dark"
                content="请提示客户进行电子签名!"
                placement="bottom"
              >
                <i
                  class="el-icon-warning"
                  style="
                     {
                      color: red;
                    }
                  "
                  v-if="item.signUrl == ''"
                ></i>
              </el-tooltip>
              电子合同二维码
            </div>
            <i
              class="el-icon-delete-solid"
              @click="deleteFile(item, index)"
            ></i>
          </div>
          <!-- <i class="el-icon-delete-solid" @click="deleteFile(index)"></i> -->
        </div>
        <div class="attachment-content-item-img" v-viewer="{ movable: false }">
          <img
            v-if="item.type == 'img'"
            :src="item.url"
            alt=""
            style="width: 180px; height: 165px"
          />
          <span v-else @click="downloadFile(item)">{{ item.name }}</span>
        </div>
      </div>
      <div class="attachment-content-item">
        <div class="attachment-content-item-index">添加资料附件</div>
        <div class="attachment-content-item-img">
          <el-upload
            class="avatar-uploader"
            :action="value.action"
            :show-file-list="false"
            :headers="value.headers"
            :data="value.uploadParameter"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <div class="attachment-content-item-img-upload">
              <el-button size="small" type="primary" icon="el-icon-upload2"
                >点击上传</el-button
              >
            </div>
          </el-upload>
        </div>
      </div>
    </div>

    <!-- <div id="my-dialog_img" v-if="dialogData.flag">
      <div class="my-dialog-content" v-loading="dialogData.loading">
        <div class="top div-flex-center">
          <div class="title">{{ dialogData.title }}</div>
          <div class="perch-active"></div>
          <div class="close">
            <i class="el-icon-close" @click="dialogData.flag = false"></i>
          </div>
        </div>

        <div
          class="content div-flex-column-center"
          v-if="dialogData.type == 'show_img'"
        >
          <el-image
            style="width: 350px; height: 350px"
            :src="dialogData.url"
            fit="contain"
          ></el-image>

          <div class="tip" @click="downloadIamge('电子合同二维码')">
            二维码下载
          </div>
        </div>
      </div>
    </div> -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog11
        :width="dialogData.width"
        :title="dialogData.title"
        :data="dialogData.data"
        :buttonArray="dialogData.buttonArray"
        @colseDialog="colseDialog"
        @downloadIamge="downloadIamge"
      ></my_dialog11>
    </div>
  </div>
</template>

<script>
import my_dialog11 from "./dialog.vue";
export default {
  props: ["attachmentData"],
  // components: {
  //   my_dialog11,
  // },
  components: {
    my_dialog11: () => import("./dialog.vue"),
  },

  data() {
    return {
      value: this.attachmentData,
      dialogData: {
        loading: true,
        flag: false,
        width: "40%",
        title: "二维码",
        type: "show_information",
        data: [],
        buttonArray: [],
        url: "",
        buttonList: "",
      },
    };
  },

  watch: {
    attachmentData() {
      //父组件值变化->>子组件也变化
      this.value = this.attachmentData;
    },
    value(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("showAttachment", newvalue);
    },
  },
  methods: {
    /**
     * 文件上传前格式判断
     */
    beforeAvatarUpload(file) {
      console.log("上传文件格式:", file.type);
      let isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 10MB!");
      }
      return isLt2M;
    },
    handleAvatarSuccess(response, file, fileList) {
      let dd = {
        type: "",
        url: response.result,
        name: file.name,
      };
      let fileUrlList = file.name.split(".");
      let imgType = ["jpg", "jpeg", "png"];
      if (imgType.indexOf(fileUrlList[fileUrlList.length - 1]) >= 0) {
        dd.type = "img";
      } else {
        dd.type = "text";
      }
      this.value.data.push(dd);
      this.$emit("saveDrawerData");
    },
    deleteFile(item, index) {
      if (item.qrUrl != "") {
        // 删除的是电子合同附件
        let requestData = {
          contractId: item.contractId,
        };
        this.$request(
          "post",
          "/contract/deleteElectronicContract",
          requestData,
          (data) => {
            this.value.data.splice(index, 1);
            this.$emit("saveDrawerData");
          },
          (err) => {
            this.$message.error(err);
          }
        );
      } else {
        this.value.data.splice(index, 1);
        this.$emit("saveDrawerData");
      }
    },
    /**
     * 文件下载
     */
    downloadFile(data) {
      console.log("data", data);
      if (this.$keyType.keyType(data.url) == "Object") {
        window.open(data.url.path, "_blank");
      } else if (this.$keyType.keyType(data.url) == "String") {
        window.open(data.url, "_blank");
      } else {
        this.$message.error("附件格式错误,请联系管理员!");
      }
    },
    /**
     * 展示电子合同二维码
     */
    showQr(url) {
      // this.dialogData.title = "二维码";
      // this.dialogData.type = "show_img";
      this.dialogData.url = url;
      // this.dialogData.buttonList = [];
      // this.dialogData.loading = false;
      // this.dialogData.flag = true;

      this.dialogData.data = [
        {
          title: "",
          type: "show_img_1",
          value: url,
        },
      ];
      this.dialogData.flag = true;
    },
    /**
     * 图片下载
     */
    downloadIamge() {
      let imgsrc = this.dialogData.url;
      let name = "电子合同二维码";
      //下载图片地址和图片名

      var image = new Image();

      // 解决跨域 Canvas 污染问题

      image.setAttribute("crossOrigin", "anonymous");

      image.onload = function() {
        var canvas = document.createElement("canvas");

        canvas.width = image.width;

        canvas.height = image.height;

        var context = canvas.getContext("2d");

        context.drawImage(image, 0, 0, image.width, image.height);

        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据

        var a = document.createElement("a"); // 生成一个a元素

        var event = new MouseEvent("click"); // 创建一个单击事件

        a.download = name || "photo"; // 设置图片名称

        a.href = url; // 将生成的URL设置为a.href属性

        a.dispatchEvent(event); // 触发a的单击事件
      };

      image.src = imgsrc;
    },
    colseDialog() {
      this.dialogData.flag = false;
    },
  },
};
</script>

<style lang="less" scoped>
#contract-in-attachment {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .attachment-tip {
    width: 98%;
    height: 30px;
    background-color: rgba(255, 255, 255, 0.548);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 8px;
    margin: 0 5px;
    font-size: 14px;
    box-sizing: border-box;
    i {
      color: rgb(250, 100, 0);
      margin-right: 5px;
    }
  }
  .attachment-content {
    height: 0;
    flex: 1;
    padding: 8px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .attachment-content-item {
      width: 200px;
      height: 200px;
      background-color: #fff;
      border: 1px solid @boderColor;
      display: flex;
      flex-direction: column;
      font-size: 12px;
      box-sizing: border-box;
      margin-right: 10px;
      margin-top: 10px;
      .attachment-content-item-index {
        width: 100%;
        height: 25px;
        padding-left: 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        > .right {
          width: auto;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          > .text {
            width: auto;
            height: 100%;
            display: flex;
            align-items: center;
            margin-right: 4px;
            justify-content: center;
            transition: 0.3s;
            &:hover {
              cursor: pointer;
              color: @tipColor;
            }
          }
          i {
            color: #f00;
            margin-right: 10px;
            cursor: pointer;
          }
        }
      }
      .attachment-content-item-img {
        width: 100%;
        height: 0;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          color: @activeColor;
          transition: 0.3;
          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
        .attachment-content-item-img-upload {
          width: 90%;
          height: 90%;
        }
      }
    }
  }
}
// #my-dialog_img {
//   width: 100vw;
//   height: 100vh;
//   position: fixed;
//   display: flex;
//   align-items: center;
//   background-color: rgba(0, 0, 0, 0.219);
//   z-index: 999999;
//   > .my-dialog-content {
//     width: 35%;
//     height: 30%;
//     background-color: #fff;
//     display: flex;
//     flex-direction: column;
//     > .top {
//       width: 100%;
//       height: 35px;
//       display: flex;
//       padding: 0 10px;
//       box-sizing: border-box;
//       > .title {
//         width: auto;
//         height: 100%;
//         font-size: 14px;
//         font-weight: bold;
//       }
//       > .perch-active {
//         flex: 1;
//       }
//       > .close {
//         width: auto;
//         height: 100%;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         font-size: 13px;
//         i {
//           transition: 0.3s;
//           &:hover {
//             cursor: pointer;
//             color: @tipColor;
//           }
//         }
//       }
//     }

//     > .content {
//       width: 100%;
//       height: 0;
//       flex: 1;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       flex-direction: column;
//       > .tip {
//         width: 100%;
//         height: 50px;
//         font-size: 13px;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         transition: 0.3s;
//         &:hover {
//           cursor: pointer;
//           color: @tipColor;
//           text-decoration: underline;
//         }
//       }
//     }
//   }
// }
</style>
