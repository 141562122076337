<template>
  <div id="wechat-waite">
    <!-- 企业微信中待办界面 -->
    <div class="wechat-contract-line">
      <el-button
        type="primary"
        size="mini"
        icon="el-icon-refresh"
        plain
        v-if="index == 0"
        @click="changeIndex(1)"
        >历史待办</el-button
      ><el-button
        type="primary"
        size="mini"
        icon="el-icon-refresh"
        plain
        v-if="index == 1"
        @click="changeIndex(0)"
        >当前待办
      </el-button>
    </div>

    <div class="wechat-contract-table">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        height="75vh"
        size="mini"
      >
        <el-table-column prop="name" label="待办事项"> </el-table-column>
        <el-table-column prop="status" label="状态"> </el-table-column>
        <el-table-column prop="des" label="时间"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="hri">
              <el-button
                type="primary"
                size="mini"
                v-if="index == 0"
                @click.native.stop="handlClick(scope.row, 'finish')"
                >完成</el-button
              >
              <el-button
                type="primary"
                size="mini"
                v-if="index == 1"
                @click.native.stop="handlClick(scope.row, 'reduction')"
                >还原</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  props: ["wechatWaiteData"],
  data() {
    return {
      value: this.wechatWaiteData,
      //   value: {
      //     userId: "fc2477a8b74c4184a9270a22cf40b0f0",
      //     companyId: "4a8add03df734b3bba7bcbb9f488f50f",
      //   },
      tableData: [],
      index: 0,
    };
  },
  watch: {
    wechatWaiteData() {
      //父组件值变化->>子组件也变化
      this.value = this.wechatWaiteData;
    },
    value(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {
    initTableData() {
      let requestData = {
        type: "all",
        userId: this.value.userId,
        flag: this.index,
        companyId: this.value.companyId,
        currentPage: 1, // 当前页数  20210903 加
        pageSize: 500, // 每页条数  20210903 加
      };
      this.$request(
        "post",
        "/waitDeal/getAllWaitDealByUserId",
        requestData,
        (data) => {
          this.tableData = data.tableData;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    changeIndex(flag) {
      this.index = flag;
      console.log("历史待办", this.index);
      this.initTableData();
    },

    /**
     * 具体事项操作事件
     */
    handlClick(data, flag) {
      let requestData = {};
      switch (flag) {
        case "finish":
          // 手动完成自定义事项
          let requestData = {
            userId: this.value.userId,
            id: data.id,
          };
          this.$request(
            "post",
            "/waitDeal/finishWaitDeal",
            requestData,
            (data) => {
              this.$message({
                message: "完成!",
                type: "success",
                duration: 1000,
              });
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );

          break;
        case "reduction":
          // 还原历史事项
          this.revokeWaitDeal(data.id);
          break;
        case "add":
          // 添加自定义待办
          this.dialogData.data = [
            {
              type: "custom-to-do",
              title: "",
              value: {
                name: "",
                data: [],
              },
            },
          ];
          this.dialogData.flag = true;
          break;

        default:
          break;
      }
    },
    /**
     * 撤销历史待办事项
     */
    revokeWaitDeal(id) {
      let requestData = {
        userId: this.value.userId,
        id: id,
      };
      this.$request(
        "post",
        "/waitDeal/revokeWaitDeal",
        requestData,
        (data) => {
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
  },
  mounted() {
    this.initTableData();
  },
};
</script>

<style lang="less" scoped>
#wechat-waite {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .wechat-contract-line {
    width: 100%;
    min-height: 30px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
    box-sizing: border-box;
  }
  .wechat-contract-table {
    width: 100%;
    padding: 5px;
    margin-top: 10px;
    box-sizing: border-box;
    height: auto;
    // background-color: rgb(28, 103, 168);
    .wechat-contract-table-link {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #019cfa;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
