<template>
  <div id="all-thread">
    <!-- 全部商机 -->
    <div class="thread-screen">
      <div class="thread-screen-line">
        <div class="thread-screen-fiftrate">
          <el-popover
            placement="bottom-start"
            width="250"
            trigger="manual"
            :visible-arrow="false"
            v-model="popoData.popoVisible"
          >
            <div class="popover-search">
              <popo_search
                :list="popoData.list"
                @cancel="popoCanael"
                @reset="popoReset"
                @makeSure="popoMakeSure"
              ></popo_search>
            </div>
            <div slot="reference" @click="popoverOpen">
              筛选 <i class="el-icon-s-operation"></i>
            </div>
          </el-popover>
        </div>
        <div class="thread-screen-search">
          <el-select
            v-model="searchData.userId"
            placeholder="请选择商机跟进人"
            @change="seleteInitTableData"
            filterable
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.userId"
              :label="item.userName"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="thread-screen-search">
          <el-cascader
            v-model="searchData.department"
            placeholder="请选择部门"
            :options="searchData.departTreeData"
            @change="initTableData"
            clearable
            :show-all-levels="false"
            :props="{ checkStrictly: true, value: 'id' }"
            filterable
          ></el-cascader>
        </div>
        <div class="thread-screen-search">
          <el-input
            placeholder="商机名称/联系人/手机号"
            v-model="searchData.input"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="thread-screen-search">
          <el-date-picker
            v-model="searchData.accountDueTime"
            type="monthrange"
            range-separator="至"
            start-placeholder="到期时间"
            end-placeholder="到期时间"
            value-format="yyyy-MM"
            @change="initTableData"
          >
          </el-date-picker>
        </div>
        <div class="thread-screen-active"></div>
        <div
          class="thread-screen-but-boder"
          v-showFunction="{ active: 'export', array: allFunctionButtons }"
          @click="importThread"
        >
          导出
        </div>
      </div>
      <div class="thread-screen-line" v-if="searchData.screenList.length > 0">
        <div class="title">筛选条件:</div>
        <el-tag
          v-for="(tag, index) in searchData.screenList"
          :key="index"
          closable
          size="small"
          @close="closeScreen(index)"
        >
          {{ tag.name }}
        </el-tag>
      </div>
    </div>
    <div class="thread-table">
      <div class="thread-table-card-content-tip">
        <div class="thread-table-card-active"></div>
        <div class="thread-table-card-edit">
          <i class="el-icon-edit-outline" @click="updateTableMenu"></i>
        </div>
      </div>
      <div class="thread-table-card">
        <div class="thread-table-card-content">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            @row-click="rowClick"
            style="width: 100%"
            height="98%"
            @selection-change="handleSelectionChange"
            :row-style="changeRowTextColor"
            :default-sort="defaultSort"
            @sort-change="sortChange"
            v-loading="loading"
          >
            <el-table-column
              type="selection"
              width="35"
              fixed
            ></el-table-column>
            <el-table-column type="index" width="55" fixed> </el-table-column>
            <template v-for="(item, index) in tabMenu">
              <el-table-column
                :key="index"
                :property="item.key"
                :fixed="index == 0"
                :label="item.name"
                v-if="item.authority == 1"
                :sortable="
                  item.key == 'lastContactTime' ||
                  item.key == 'createTime' ||
                  item.key == 'protectTime'
                    ? 'custom'
                    : false
                "
                :min-width="
                  item.key == 'threadName'
                    ? 270
                    : item.key == 'followName'
                    ? 100
                    : item.key == 'lastContactTime'
                    ? 130
                    : 110
                "
                :show-overflow-tooltip="true"
              >
                <template slot-scope="scope">
                  <div class="hri" v-if="item.key == 'threadName'" style="">
                    <i
                      class="el-icon-notebook-2"
                      :style="{
                        color: scope.row.flag != 1 ? '#40a3ff' : 'red',
                      }"
                      @click.stop="showGenjin(scope.row)"
                    ></i>
                    <el-tooltip
                      class="item"
                      effect="light"
                      content="已签约"
                      placement="top"
                    >
                      <i
                        class="el-icon-star-on"
                        :style="{
                          color: scope.row.flag != 1 ? '#40a3ff' : 'red',
                        }"
                        v-if="scope.row.clientFlag"
                      ></i>
                    </el-tooltip>
                    {{ scope.row[item.key] }}
                  </div>
                  <div
                    class="hri"
                    v-else-if="
                      item.key == 'customerSource' ||
                        item.key == 'intentionalProduct'
                    "
                  >
                    <!-- 客户来源或意向产品为数组格式,循环遍历显示 -->
                    <div v-for="(it, ii) in scope.row[item.key]" :key="ii">
                      {{ ii == 0 ? it.title : "," + it.title }}
                    </div>
                  </div>
                  <div v-else>
                    {{ scope.row[item.key] }}
                  </div>
                </template>
              </el-table-column>
            </template>
          </el-table>
        </div>
        <div class="thread-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 自定义列 -->
    <div id="my-dialog" v-if="tragerDialogData.flag">
      <div class="my-dialog-cc">
        <div class="my-dialog-title">
          <div class="my-dialog-title-text">{{ tragerDialogData.title }}</div>
          <div class="my-dialog-title-active"></div>
          <div class="my-dialog-title-close" @click="closeUpdateTableMenu">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <el-divider></el-divider>
        <div class="my-dialog-content">
          <new_drager
            :dragerData="tragerDialogData.data"
            @show="showDrager"
            @dragging="dragging"
            @closeUpdateTableMenu="closeUpdateTableMenu"
            @markSureUpdateTableMenu="markSureUpdateTableMenu"
          ></new_drager>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @buttonOper="drawerOper"
        @choseProductTipOne="choseProductTipOne"
        @saveDrawerData="saveDrawerData"
        @addFollowRecord="followRecord"
      ></my_dialog>
    </div>

    <!-- 右边抽屉 -->

    <div id="my-drawer" v-if="threadDrawerData.flag">
      <my_drawer
        :drawerData="threadDrawerData"
        :allFunctionButtons="allFunctionButtons"
        :model="'allThread'"
        @show="showDrawer"
        @closeUpdateTableDrawer="closeUpdateTableDrawer"
        @saveDrawerData="saveDrawerData"
        @tagsHandleClose="tagsHandleClose"
        @addTags="addTags"
        @drawerOper="drawerOper"
        @showExpendDetails="showExpendDetails"
        @addExpend="addExpend"
        @showExpend="showExpend"
        @radioChange="radioChange"
        @cancelServiceItems="cancelServiceItems"
        @deleteService="deleteService"
        @linkNowFile="linkNowFile"
      ></my_drawer>
    </div>
  </div>
</template>

<script>
import drager from "@/components/drager.vue";
import my_drawer from "@/components/my-drawer.vue";
import my_dialog from "@/components/dialog.vue";
import popo_search from "@/components/popo-search.vue";
import * as myThreadData from "@/assets/js/myThread.js";
import * as clientInfoData from "@/assets/js/clientInfoData.js";
import * as systemData from "@/assets/js/systemAuthorityData.js";
import * as employeeData from "@/assets/js/employeeData.js";
import * as productData from "@/assets/js/productData.js";
import * as contractDrawData from "@/assets/js/contractDraw.js";
import * as threadData from "./js/threadData.js";
import * as allContractData from "../contract/js/contractData.js";
export default {
  components: {
    drager,
    my_drawer,
    popo_search,
    my_dialog,
  },
  data() {
    return {
      baseInfo: {},
      loading: true,
      whetherAgencyHead: true, // 是否是机构负责人
      searchData: {
        input: "",
        checked: false,
        screenButton_improt: 1, // 导出客户商机按钮的权限 1->读写;2->只读;3->不可见
        userId: "", //
        userList: [],
        tempUserId: "",
        departTreeData: [],
        department: "",
        screenList: [],
        accountDueTime: [],
      },
      popoData: {
        popoVisible: false,
        list: myThreadData.remyThreadSearchData(),
      },
      tabindex: "0",
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      tabMenu: myThreadData.myThreadTableMenuData(),
      tableMenuData: {
        selectMenuData: [], // 真正显示的表头数据
        chooseableMenuData: [], // 隐藏的表头
      },
      tableData: [],
      multipleSelection: [],
      tableButIndex: -1,
      tragerDialogData: {
        title: "自定义列",
        flag: false,
        data: {},
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "添加跟进记录",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: myThreadData.getMyThreadsGenjinjiluData(),
      },
      threadDrawerData: threadData.myThreadDrawerData("商机", [], false, {}),
      tempTags: [],
      tempThread: {
        id: "",
        name: "",
        clientFlag: false,
      },
      arrayList: {
        employeeList: [], // 所有员工列表
        departmentList: [], // 所有部门列表
        areaList: [], // 所有片区列表
        sourceList: [], // 所有客户来源列表
        followStageList: [], // 跟进阶段列表
        addedValueTaxTypeList: [], // 增值税类型列表
        intentionalProductList: [], // 所有意向产品列表
      },
      buttonAuth: [],
      tempAllEmpolyeeList: [],
      productList: [],
      clientList: [],

      tempContract: { id: "", name: "" }, // 临时合同
      tempClient: {},
      tempServices: [], // 该合同所包含的所有服务
      professionList: [], // 所有合同执行模板
      serviceItemList: [], // 所有服务项数据
      employeeList: [], // 所有员工
      departmentList: [], // 所有员工

      contractSettingButtonList: [],

      defaultSort: { prop: "", order: "" },
      contractDrawerData: {}, // 合同预览数据
      accountList: [], // 收款账号
      contractId: "", // 预览的合同ID

      ttempClient: {},
      allFunctionButtons: this.$featureData.getAllFunctionButtons("allThread"),
      drawerOptions: this.$tagData.getDrawerOptionsList("allThread"), // 抽屉中的下拉框配置项
      drawerAuthorityData: [],

      contractAllFunctionButtons: this.$featureData.getAllFunctionButtons(
        "contractInfo"
      ),
      contractDrawerOptions: this.$tagData.getDrawerOptionsList("contractInfo"), // 抽屉中的下拉框配置项
      contractDrawerAuthorityData: [],
      contractDrawerDataAuthorityList: [],
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "allThread",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.initMenu();
      });
    },
    initMenu() {
      this.$featureData.getAttributeData(
        "allThread",
        true,
        [],
        (attributeData) => {
          this.$menuData.getModelMenuAndAuthority(
            "allThread",
            attributeData,
            this,
            (data) => {
              this.tabMenu = data;
              console.log(this.tabMenu);
              this.tragerDialogData.data = JSON.parse(
                JSON.stringify(this.tabMenu)
              );
              this.getEmployeeList();
              this.$tagData.getTagList(
                this.drawerOptions,
                ["片区", "客户来源", "增值税类型"],
                this.baseInfo.company.companyId,
                this
              );
              myThreadData.getTagList(
                this.arrayList,
                ["片区", "客户来源", "增值税类型"],
                this.baseInfo.company.companyId,
                this
              );
            }
          );
        }
      );
    },
    /**
     * 获取所有员工列表
     */
    getEmployeeList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/user/getEmployeeListForDownBox",
        requestData,
        (data) => {
          this.employeeList = [];
          data.forEach((element) => {
            let dd = {
              label: element.userName,
              value: element.userId,
            };
            this.employeeList.push(dd);
          });
          this.drawerOptions.allUser = this.employeeList;
          this.getThreadAndClientName();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },

    /**
     * 获取所有客户成交信息信息
     */
    getThreadAndClientName() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        // "/thread/getThreadAndClientName",
        "/reThread/getThreadAndClientName",
        requestData,
        (data) => {
          this.clientList = data;
          this.getServicesItem();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有服务项列表
     */
    getServicesItem() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        status: "1",
        name: "", // 服务产品名称 模糊查询
        type: "",
      };
      this.$request(
        "post",
        "/servicesItem/getServicesItem",
        requestData,
        (data) => {
          this.serviceItemList = data;
          this.getAllProductList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },

    /**
     * 获取所有合同模板/产品列表
     */
    getAllProductList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        name: "", // 服务产品名称 模糊查询
        type: "",
        currentPage: 1, // 当前页数
        pageSize: 1000, // 每页条数
      };
      this.$request(
        "post",
        "/product/getAllProduct",
        requestData,
        (data) => {
          this.productList = data.tableData;
          this.getEmployeeListByUserId();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },

    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeListByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.searchData.userList = data;
          this.tempAllEmpolyeeList = [];
          data.forEach((element) => {
            this.tempAllEmpolyeeList.push(element.userId);
          });
          this.searchData.userList.splice(0, 0, {
            userId: "all",
            userName: "全部",
          });
          this.getDepartmentTreeByUserId();
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        // "/setting/getDepartmentTreeByUserId",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
          // this.getBusinessAuthorityList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },

    /**
     * 表格排序事件
     */
    sortChange(data) {
      // console.log("表格排序事件", data.prop, data.order);
      this.defaultSort.prop = data.prop;
      this.defaultSort.order = data.order == null ? "" : data.order;
      // console.log("表格排序事件", this.defaultSort);
      this.initTableData();
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      this.tableData = [];
      this.loading = true;
      let requestData = {
        userId: this.searchData.tempUserId,
        companyId: this.baseInfo.company.companyId, // 当前公司ID
        haveBargain: this.searchData.checked ? "1" : "0", // 是否包含已成交、无效,"0"为否,"1"为是
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
        department:
          this.searchData.department.length > 0
            ? this.searchData.department[this.searchData.department.length - 1]
            : "",
        search: this.searchData.input,
        accountDueTime: this.searchData.accountDueTime, // 筛选项：到期时间，年月
        sortProp: this.defaultSort.prop, // 手动排序项
        sortOrder: this.defaultSort.order, // 手动排序规则 ascending descending ""
        screenList: this.searchData.screenList,
      };
      this.$request(
        "post",
        // "/thread/getMyThreadAll",
        "/reThread/getMyThreadAll",
        requestData,
        (data) => {
          this.pageData.total = data.total; // 获取总条目数
          // this.tableData = myThreadData.getInitTableData(
          this.tableData = myThreadData.regetInitTableData(
            data.tableData,
            this.tableMenuData.selectMenuData
          );

          this.$nextTick(() => {
            this.$refs.singleTable.doLayout();
          });
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    seleteInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.tempAllEmpolyeeList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.initTableData();
    },
    /**
     * 修改表头顺序dialog打开
     */
    updateTableMenu() {
      this.tragerDialogData.data = JSON.parse(JSON.stringify(this.tabMenu));
      this.tragerDialogData.flag = true;
    },
    /**
     * 确定修改表头数据
     */
    markSureUpdateTableMenu(resultData) {
      console.log("确定修改表头数据:", resultData);
      this.tabMenu = JSON.parse(JSON.stringify(resultData));
      this.tragerDialogData.flag = false;
      this.initTableData();
      this.$menuData.saveTableMenuData(resultData, "allThread", this);
    },

    /**
     * 多选框事件
     */
    handleSelectionChange(val) {
      this.multipleSelection = [];
      val.forEach((element) => {
        this.multipleSelection.push(element.id);
      });
    },
    /**
     * 商机的批量导出
     */
    importThread() {
      if (this.searchData.screenButton_improt == 2) {
        this.$message.error(
          this.$errorMessage.getErrorMessageInfo("authority")
        );
        return;
      }
      if (this.multipleSelection.length == 0) {
        this.$message.error("请先选择想要导出的商机");
        return;
      }
      let requestData = "";
      this.multipleSelection.forEach((element) => {
        if (requestData == "") {
          requestData = element;
        } else {
          requestData = requestData + "," + element;
        }
      });
      window.location.href =
        this.$baseurl + "/reThread/importThread?threadId=" + requestData;
    },

    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    /**
     * 表格单行点击事件
     */
    rowClick(row, column, event) {
      console.log("表格行点击事件:", row);
      if (!this.popoData.popoVisible) {
        if (!this.threadDrawerData.flag) {
          //防止重复点击
          this.tempThread.id = row.id;
          this.tempThread.name = row.threadName;
          this.tempThread.clientFlag = row.clientFlag;
          this.ttempClient = row;
          this.$featureData.getAttributeData(
            "allThread",
            false,
            threadData.returnDrawerKeys(),
            (authorityData) => {
              this.drawerAuthorityData = authorityData;
              // 2.拿对应属性的权限
              this.$authority.getModelFeatureAuthority(
                this,
                "allThread",
                authorityData,
                (authorityList) => {
                  // 3.将权限数据对应到界面
                  this.threadDrawerData = threadData.myThreadDrawerData(
                    this.tempThread.name,
                    authorityList,
                    this.whetherAgencyHead, // 是否是机构负责人
                    this.drawerOptions
                  );
                  // 4.拿具体的值
                  this.getMyThreadDetailsByThreadId();
                }
              );
            }
          );
        } else {
          this.closeUpdateTableDrawer();
        }
      } else {
        this.popoCanael();
      }
    },
    /**
     * 获取商机的具体信息
     */
    getMyThreadDetailsByThreadId() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId, // 当前公司ID
        threadId: this.tempThread.id, // 当前线索ID
        authorityData: this.$initDrawerAuthorityData.getKeysByAuthorityList(
          this.drawerAuthorityData
        ), // 模块具体权限key数组
      };
      this.$request(
        "post",
        "/reThread/getMyThreadDetailsByThreadId",
        requestData,
        (data) => {
          // 5.将具体的值匹配到界面
          // 保证联系人有数据
          if (data.contact.length == 0) {
            data.contact = [{ name: "", phone: ["", ""] }];
          }
          // 单独处理客户来源  将数组格式转换成字符串
          if (data.customerSource.length > 0) {
            data.customerSource = data.customerSource[0].value;
          } else {
            data.customerSource = "";
          }
          this.threadDrawerData = this.$initDrawerAuthorityData.initDrawerData(
            this.threadDrawerData,
            data
          );
          // 6.在权限的基础上叠加状态
          this.threadDrawerData.status = data.status;
          this.threadDrawerData.flag = true;
        }
      );
    },
    /**
     * 表头div拖动方法
     */
    dragging(value) {
      console.log("div拖动", value);
    },
    /**
     *右击改变表头显示与否事件
     */
    changeStatus(data) {
      if (data.index == 0) {
        data.item.isDisable = true;
        this.tragerDialogData.data.selectMenuData.splice(
          this.tragerDialogData.data.selectMenuData.indexOf(data.item),
          1
        );
        this.tragerDialogData.data.chooseableMenuData.push(data.item);
      } else {
        data.item.isDisable = false;
        this.tragerDialogData.data.chooseableMenuData.splice(
          this.tragerDialogData.data.chooseableMenuData.indexOf(data.item),
          1
        );
        this.tragerDialogData.data.selectMenuData.push(data.item);
      }
    },
    /**
     * 修改表头顺序dialog关闭
     */
    closeUpdateTableMenu() {
      this.tragerDialogData.flag = false;
    },
    /**
     * 抽屉中tag移除事件
     * data(tag,i,j):tag->移除的tag,i为第几个model,j为第几line
     */
    tagsHandleClose(data) {
      this.threadDrawerData.data.modelList[data.i].line[data.j].value.splice(
        this.threadDrawerData.data.modelList[data.i].line[data.j].value.indexOf(
          data.tag
        ),
        1
      );
      this.saveDrawerData(
        this.threadDrawerData.data.modelList[data.i].line[data.j].title
      );
    },
    /**
     * 抽屉中添加tag事件
     * data(i,j):i为第几个model,j为第几line
     */
    addTags(data) {
      this.tempTags = this.threadDrawerData.data.modelList[data.i].line[
        data.j
      ].value;
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.dialogData.title = "选择产品";
      this.dialogData.buttonArray = [];
      this.dialogData.buttonArray.push({
        text: "确定",
        active: "makesure_addPurposeThread",
      });
      this.dialogData.buttonArray.push({
        text: "取消",
        active: "cancel",
      });
      let tempProductList = [];
      this.productList.forEach((element) => {
        let dd = {
          id: element.id,
          title: element.name,
          serve: element.servicesItem,
        };
        tempProductList.push(dd);
      });
      // this.dialogData.data = myThreadData.getAddPurposeThreadDialogData(
      //   this.tempThread.name, //当前商机名称
      //   myThreadData.initPurposeArrayData(
      //     // 获取所有产品,并格式化
      //     tempProductList, // 获取所有产品
      //     this.tempTags // 当前商机所包含的产品
      //   )
      // );
      this.dialogData.data = [
        {
          type: "intention_the_product",
          title: "",
          value: {
            clientName: this.tempThread.name,
            chooseProduct: JSON.parse(JSON.stringify(this.tempTags)),
          },
        },
      ];
      this.dialogData.flag = true;
    },
    /**
     * 抽屉组件中按钮操作事件(主要是显示弹出框事件)
     */
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "showDetail":
          // 查看商机详情
          this.dialogData.title = "";
          this.dialogData.width = "720px";
          this.dialogData.buttonArray = [];
          let dd = {
            type: "drawer",
            data: clientInfoData.clientInfoDrawerData(
              "商机1",
              clientInfoData.getClientInfoData()
            ),
          };

          this.dialogData.data = [];
          this.dialogData.data.push(dd);
          this.dialogData.flag = true;

          break;
        case "genjin":
          // 添加跟进记录
          if (this.threadDrawerData.data.modelList[0].line[5].value == "") {
            this.$message.error("客户来源不能为空!");
            break;
          }
          if (
            this.threadDrawerData.data.modelList[2].line[0].value.length == 1 && // 只有一组联系人
            (this.threadDrawerData.data.modelList[2].line[0].value[0].name == // 假数据 联系人姓名为空
              "" ||
              (this.threadDrawerData.data.modelList[2].line[0].value[0] // 假数据  联系人两组手机号中有一个为空
                .phone[0] == "" &&
                this.threadDrawerData.data.modelList[2].line[0].value[0]
                  .phone[1] == ""))
          ) {
            this.$message.error("联系人或手机号不能为空!");
            break;
          }
          if (
            this.threadDrawerData.data.modelList[4].line[0].value.length == 0
          ) {
            this.$message.error("意向产品不能为空!");
            break;
          }

          this.showGenjin(this.ttempClient);

          break;
        case "submit_gnjinjilu":
          // 确定提交跟进记录
          this.followThread();
          break;
        case "zhuanchengjiao":
          // 商机转成交
          this.dialogData.title = "提示";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push({
            text: "确定",
            active: "makesure_zhuanchengjiao",
          });
          this.dialogData.buttonArray.push({
            text: "取消",
            active: "cancel",
          });
          this.dialogData.data = [];
          this.dialogData.data.push({
            type: "reminder",
            text: "转为客户",
            value: this.threadDrawerData.data.title,
          });
          this.dialogData.flag = true;

          break;
        case "makesure_zhuanchengjiao":
          // 确认转成交
          this.createContractDialogOpen(this.tempThread.name);
          break;
        case "zhiweiwuxiao":
          // 置为无效
          this.dialogData.title = "提示";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push({
            text: "确定",
            active: "makesure_zhiweiwuxiao",
          });
          this.dialogData.buttonArray.push({
            text: "取消",
            active: "cancel",
          });
          this.dialogData.data = [];
          this.dialogData.data.push({
            type: "reminder",
            text: "置为无效",
            value: this.threadDrawerData.data.title,
          });
          this.dialogData.flag = true;
          break;
        case "makesure_zhiweiwuxiao":
          // 确认置为无效
          this.setInvalid();
          break;
        case "shifang":
          // 释放至商机池
          this.dialogData.title = "提示";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push({
            text: "确定",
            active: "makesure_shifang",
          });
          this.dialogData.buttonArray.push({
            text: "取消",
            active: "cancel",
          });
          this.dialogData.data = [];
          this.dialogData.data.push({
            type: "reminder",
            text: "释放至商机池",
            value: this.threadDrawerData.data.title,
          });
          this.dialogData.flag = true;
          break;
        case "makesure_shifang":
          // 确认释放至商机池
          this.redistributionThread("释放至商机池");
          break;
        case "delete_thread":
          // 删除商机
          this.dialogData.title = "提示";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push({
            text: "确定",
            active: "makesure_delete_thread",
          });
          this.dialogData.buttonArray.push({
            text: "取消",
            active: "cancel",
          });
          this.dialogData.data = [];
          this.dialogData.data.push({
            type: "reminder",
            text: "删除",
            value: this.threadDrawerData.data.title,
          });
          this.dialogData.flag = true;
          break;
        case "cancel_expend":
          this.dialogData.flag = false;
          this.getExpendByContractId();
          break;
        case "makesure_delete_thread":
          // 确认删除商机
          this.setInvalid("删除");
          break;
        case "operating_record":
          // 查看操作记录
          this.getOperationRecordsList();
          break;
        case "makesure_addPurposeThread":
          this.addPurposeThread();
          // 添加意向产品
          break;

        case "get_companyInfo":
          // 获取工商信息
          requestData = {
            name: this.threadDrawerData.data.title,
          };
          this.$request(
            "post",
            "/client/getCreditCode",
            requestData,
            (data) => {
              this.threadDrawerData.data.modelList[3].line[4].value =
                data.taxNum == ""
                  ? this.threadDrawerData.data.modelList[13].line[4].value
                  : data.taxNum; // 企业税号
              this.threadDrawerData.data.modelList[3].line[5].value =
                data.tel == ""
                  ? this.threadDrawerData.data.modelList[3].line[5].value
                  : data.tel; // 联系方式
              this.threadDrawerData.data.modelList[3].line[6].value =
                data.bank == ""
                  ? this.threadDrawerData.data.modelList[3].line[6].value
                  : data.bank; // 开户行
              this.threadDrawerData.data.modelList[3].line[7].value =
                data.bankAccount == ""
                  ? this.threadDrawerData.data.modelList[3].line[7].value
                  : data.bankAccount; // 银行账号
              this.threadDrawerData.data.modelList[3].line[3].value =
                data.address == ""
                  ? this.threadDrawerData.data.modelList[3].line[3].value
                  : data.address; //详细地址

              this.threadDrawerData.data.modelList[3].line[2].value = [];
              this.threadDrawerData.data.modelList[3].line[2].value = this.$addressAutomaticallyMatch.addressAutomaticallyMatch(
                this.threadDrawerData.data.modelList[3].line[3].value
              );

              if (
                this.threadDrawerData.data.modelList[3].line[2].value == null ||
                this.threadDrawerData.data.modelList[3].line[2].value.length < 3
              ) {
                this.threadDrawerData.data.modelList[3].line[2].value = [];
              }
              this.saveDrawerData("工商信息");
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;

        case "info":
          // 合同信息
          this.showContractInfoByID();
          break;
        case "end_the_manual":
          // 手动结束合同
          this.$confirm("该合同未到期或未全部回款, 是否继续结束?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$request(
                "post",
                "/contract/setContractFinish",
                { contractId: this.tempContract.id },
                (data) => {
                  this.$message({
                    type: "success",
                    message: "操作成功!",
                    duration: 1000,
                  });
                  this.threadDrawerData.flag = false;
                  this.$operationRecords.saveOperationRecords(
                    this.tempContract.id,
                    "手动结束合同!",
                    this.baseInfo,
                    "contract",
                    this,
                    (data2) => {
                      this.initTableData();
                    }
                  );
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                  this.dialogData.flag = false;
                }
              );
            })
            .catch(() => {});
          break;
        case "huikuan":
          // 合同抽屉中的收款记录
          this.getReceiptRecord();
          break;
        case "shoufei":
          // 合同抽屉中的收费情况
          this.getCharge();
          break;
        case "delete_contract":
          // 删除合同
          this.$request(
            "post",
            "/contract/beforeDelContract",
            { contractId: this.tempContract.id },
            (data) => {
              if (data.flag) {
                // 正常删除  当前合同没有收款记录
                this.deleteContract();
              } else {
                // 有收款记录,提示删除
                this.$confirm("该合同已有收款记录,是否继续删除?", "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                })
                  .then(() => {
                    this.deleteContract();
                  })
                  .catch(() => {});
              }
            }
          );
          break;
        case "zhichu":
          // 合同抽屉中的合同支出
          this.getExpendByContractId();
          break;
        case "fujian":
          // 合同抽屉中的合同附件
          break;
        case "submit":
          // 合同提交
          this.$confirm("确定合同提交?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.submitContract();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消提交",
                duration: 1000,
              });
            });

          break;
        case "add_huikuan":
          // 合同抽屉中的添加回款
          console.log("该合同所有服务项:", this.tempServices);
          let options = [];
          let accountList = [];
          this.tempServices.forEach((element) => {
            let ll = {
              label: element.name,
              value: element.name,
            };
            options.push(ll);
          });
          this.accountList.forEach((element) => {
            let dd = {
              label: element.account + "-" + element.owner + "-" + element.type,
              value: element.account,
            };
            accountList.push(dd);
          });
          this.dialogData.title = "添加回款信息";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push(
            {
              text: "确定",
              active: "sure_huikuan",
            },
            {
              text: "取消",
              active: "cancel",
            }
          );
          this.dialogData.data = [
            {
              type: "input_disable",
              title: "客户名称",
              value: this.tempContract.name,
            },
            {
              type: "input",
              title: "回款金额",
              value: "",
            },
            {
              type: "select",
              title: "回款服务项",
              value: "",
              options: options,
            },
            {
              type: "select",
              title: "收款账户",
              value: "",
              options: accountList,
            },
            {
              type: "input",
              title: "交易单号",
              value: "",
            },
            {
              type: "radio",
              title: "上传位置",
              value: "0",
              options: [
                {
                  label: "本地",
                  value: "0",
                },
                {
                  label: "云盘",
                  value: "1",
                },
              ],
            },
            {
              type: "upload",
              title: "付款凭证",
              value: {
                value: "", // 回款单附件
                action: this.$fileBaseurl + "/uploadImageByMultipartFile",
                // action: "http://114.55.138.13:8089/uploadImageByMultipartFile",
                headers: {
                  token: "95931b371eb64f4a9c862983ca8a5d15",
                },
                uploadParameter: {
                  systemName: "finance",
                  title: "付款凭证附件",
                },
              },
            },
          ];
          this.dialogData.flag = true;

          break;
        case "sure_huikuan":
          // 确认添加回款信息
          requestData = {
            contractId: this.tempContract.id, // 合同ID
            addTime: this.$formatTime(new Date(), "YYYY-MM-DD HH:mm:ss"), // 回款时间
            money: this.dialogData.data[1].value, // 回款金额
            service: this.dialogData.data[2].value, // 回款服务项
            receivedId: this.dialogData.data[4].value, // 回款账号
            file: this.dialogData.data[6].value.value, // 回款单附件
            account: this.dialogData.data[3].value, // 回款账号
            userId: this.baseInfo.userId, // 当前操作人
            status: "待审核", // 回款状态
            companyId: this.baseInfo.company.companyId,
          };

          if (requestData.money == "") {
            this.$message({
              message: "回款金额不能为空!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          if (requestData.service == "") {
            this.$message({
              message: "回款服务项不能为空!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          if (requestData.receivedId == "") {
            this.$message({
              message: "回款单号不能为空!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          if (requestData.account == "") {
            this.$message({
              message: "回款账号不能为空!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          if (requestData.file == "") {
            this.$message({
              message: "请上传付款凭证!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          this.$request(
            "post",
            "/contract/chargeContract",
            requestData,
            (data) => {
              this.$message({
                message: "操作成功!",
                type: "success",
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.$operationRecords.saveOperationRecords(
                this.tempContract.id,
                "添加收款记录!",
                this.baseInfo,
                "contract",
                this,
                (data2) => {
                  this.showContractInfoByID();
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;

        case "add_serves":
          // 合同抽屉中的添加服务项
          dd = {
            flag: true,
            tableData: {},
            item: [
              {
                horizontal: false,
                isShow: true,
                isDisable: false,
                title: "服务名称",
                type: "select",
                value: "",
                options: this.serviceItemList,
              },
              {
                horizontal: false,
                isShow: true,
                isDisable: false,
                title: "付费周期",
                type: "select",
                value: "每年",
                options: [
                  {
                    label: "每年",
                    value: "每年",
                    img: "",
                  },
                  {
                    label: "每月",
                    value: "每月",
                    img: "",
                  },
                  {
                    label: "每季度",
                    value: "每季度",
                    img: "",
                  },
                ],
              },
              {
                horizontal: false,
                isShow: true,
                isDisable: true,
                title: "催费时间",
                type: "收费提醒选择",
                radio: "提前",
                beforeDay: "",
                lateDay: "",
                value: "",
              },
              {
                horizontal: false,
                isShow: true,
                isDisable: false,
                title: "优惠",
                type: "discounts",
                value: {
                  discount: "",
                  flag: "",
                  mitigate: "",
                  totalPrice: "",
                },
              },
              {
                horizontal: false,
                isShow: true,
                isDisable: false,
                title: "首次催费时间",
                type: "time",
                value: "",
              },
              {
                horizontal: false,
                isShow: true,
                isDisable: false,
                title: "服务总价",
                type: "input",
                value: "",
              },
              {
                horizontal: false,
                isShow: true,
                isDisable: false,
                title: "服务时间",
                type: "timeToTime",
                value: [
                  {
                    discounts: "",
                    money: "",
                    price: "",
                    time1: "2021-07",
                    time2: "2022-07",
                  },
                ],
              },
            ],
          };
          this.threadDrawerData.data.modelList[2].line[0].value.push(dd);
          break;
        case "add_free_serves":
          // 合同抽屉中的添加赠送的服务项
          if (!this.threadDrawerData.data.modelList[3].line[0].isDisable) {
            dd = {
              isEdit: true, // 是否是编辑状态
              flag: true, // 是否是新增
              serviceItem: {}, //服务项
              serviceTime: "", // 服务时间
            };
            this.threadDrawerData.data.modelList[3].line[0].value.push(dd);
          } else {
            this.$message({
              message: "当前合同状态不可编辑!",
              duration: 1000,
              type: "error",
            });
          }
          break;
        case "expend_submit":
          // 合同抽屉中的合同支出提交
          requestData = {
            contractId: this.tempContract.id, // 合同ID
            expendNum: this.dialogData.data[0].value.expendNum, // 支出编号
            expendType: this.dialogData.data[0].value.expendType, // 支出类型
            expendStatus: "完成", // 支出状态  待提交  待审核  完成  审核不通过
            time: this.$formatTime(new Date(), "YYYY-MM-DD HH:mm:ss"), // 确认支出时间  当状态为完成时自动填充s
            title: this.dialogData.data[0].value.title, //收款账户
            file: this.dialogData.data[0].value.file, // 附件
            services: this.dialogData.data[0].value.services,
            user: this.baseInfo.userId,
          };
          if (requestData.title == "") {
            this.$message.error("支出标题不能为空!");
            return;
          }
          if (requestData.file == "") {
            this.$message.error("支出附件不能为空!");
            return;
          }
          if (requestData.services[0].expendMoney == "") {
            this.$message.error("支出金额不能为空!");
            return;
          }
          this.$request(
            "post",
            "/contract/updateExpend",
            requestData,
            (data) => {
              this.$message({
                message: "操作成功!",
                type: "success",
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.$operationRecords.saveOperationRecords(
                this.tempContract.id,
                "添加合同支出!",
                this.baseInfo,
                "contract",
                this,
                (data2) => {
                  this.getExpendByContractId();
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "expend_echeck":
          // 合同支出审核
          requestData = {
            contractId: this.tempContract.id, // 合同ID
            expendNum: this.dialogData.data[0].value.expendNum, // 支出编号
            expendType: this.dialogData.data[0].value.expendType, // 支出类型
            expendStatus: "完成", // 支出状态  待提交  待审核  完成  审核不通过
            time: this.dialogData.data[0].value.time, // 确认支出时间  当状态为完成时自动填充
            receiptAccount: this.dialogData.data[0].value.receiptAccount, //收款账户
            receiptName: this.dialogData.data[0].value.receiptName, // 收款人姓名
            blank: this.dialogData.data[0].value.blank, // 开户银行  如徽商银行等
            des: this.dialogData.data[0].value.des, // 支出备注
            file: this.dialogData.data[0].value.file, // 附件
            services: this.dialogData.data[0].value.services,
          };
          this.$request(
            "post",
            "/contract/updateExpend",
            requestData,
            (data) => {
              this.$message({
                message: "操作成功!",
                type: "success",
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.$operationRecords.saveOperationRecords(
                this.tempContract.id,
                "支出审核!",
                this.baseInfo,
                "contract",
                this,
                (data2) => {
                  this.getExpendByContractId();
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;

        case "makesure_relevance":
          // 确定手动添加关联客户
          this.makesureRelevance();
          break;
        case "operating_record_contract":
          // 查看合同的操作记录
          this.$operationRecords.showOperationRecords(
            this.contractId,
            "contract",
            this
          );
          break;

        case "cancel":
          // dialog关闭
          this.dialogData.flag = false;
          break;
        default:
          break;
      }
    },
    /**
     * 合同删除
     */
    deleteContract() {
      let requestData = {
        contractId: this.tempContract.id,
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$confirm("此操作将永久删除该合同, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$request(
            "post",
            "/contract/deleteContract",
            requestData,
            (data) => {
              this.$message({
                type: "success",
                message: "删除成功",
                duration: 1000,
              });
              this.contractDrawerData.flag = false;
              this.$operationRecords.saveOperationRecords(
                this.tempContract.id,
                "合同删除!",
                this.baseInfo,
                "contract",
                this,
                (data2) => {
                  this.initTableData();
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    /**
     * 合同提交
     */
    submitContract() {
      if (this.threadDrawerData.data.modelList[0].line[5].value == "") {
        this.$message({
          message: "合同成本不能为空!",
          duration: 1000,
          type: "error",
        });
        return;
      }
      let requestData = {
        userId: this.baseInfo.userId,
        id: this.tempContract.id,
        // professionId: data.id, // 执行流程IDID
      };
      this.$request(
        "post",
        "/contract/submitContract",
        requestData,
        (data) => {
          this.dialogData.flag = false;
          this.threadDrawerData.flag = false;
          this.$message({
            message: "合同提交成功!",
            duration: 1000,
            type: "success",
          });
          this.$operationRecords.saveOperationRecords(
            this.tempContract.id,
            "合同提交!",
            this.baseInfo,
            "contract",
            this,
            (data2) => {
              this.initTableData();
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },

    /**
     * 通过合同ID,预览合同
     */
    showContractInfoByID() {
      this.$authority.getModelFeatureAuthority(
        this,
        "myThread",
        this.allFunctionButtons,
        (contractAllFunctionButtons) => {
          this.contractAllFunctionButtons = contractAllFunctionButtons;
          console.log("当前显示的按钮权限", data);
          setTimeout(() => {
            this.$featureData.getAttributeData(
              "contractInfo",
              false,
              allContractData.returnDrawerKeys("合同信息"),
              (authorityData) => {
                this.contractDrawerAuthorityData = authorityData;
                // 2.拿对应属性的权限
                this.$authority.getModelFeatureAuthority(
                  this,
                  "contractInfo",
                  authorityData,
                  (authorityList) => {
                    this.contractDrawerDataAuthorityList = authorityList;
                    // this.showContractInfoByID();
                    let requestData = {
                      contractId: this.tempContract.id,
                      userId: this.baseInfo.userId,
                      companyId: this.baseInfo.company.companyId,
                      authorityData: this.$initDrawerAuthorityData.getKeysByAuthorityList(
                        this.contractDrawerAuthorityData
                      ),
                    };
                    this.$request(
                      "post",
                      "/contract/showContractByID",
                      requestData,
                      (data) => {
                        // 3.将权限数据对应到界面
                        let butText = {
                          text: "提交",
                          active: "submit",
                        };
                        if (
                          data.data.status == "待提交" ||
                          data.data.status == "合同审核不通过"
                        ) {
                          butText = {
                            text: "提交",
                            active: "submit",
                            key: "cancel",
                          };
                        } else {
                          butText = {
                            text: "电子合同生成",
                            active: "electronic_contract",
                            key: "electronicContract",
                          };
                        }
                        this.threadDrawerData = allContractData.contractInfoDrawerData(
                          this.tempClientName,
                          this.contractDrawerDataAuthorityList,
                          this.whetherAgencyHead, // 是否是机构负责人
                          this.contractDrawerOptions,
                          "合同信息",
                          butText
                        );
                        // this.tempSign = data.data.sign;
                        this.threadDrawerData = this.$initDrawerAuthorityData.initDrawerData(
                          this.threadDrawerData,
                          data.data
                        );
                        allContractData.initData(
                          data,
                          this.threadDrawerData,
                          this.contractAllFunctionButtons,
                          this.whetherAgencyHead
                        );
                        this.threadDrawerData.status = this.whetherAgencyHead
                          ? ""
                          : data.data.status;
                        console.log("打开抽屉:", this.threadDrawerData);

                        this.threadDrawerData.flag = true;
                      },
                      (errorMessage) => {
                        this.$message.error(errorMessage);
                      }
                    );
                  }
                );
              }
            );
          }, 0);
        }
      );
    },
    /**
     * 获取收费情况
     */
    getCharge() {
      let requestData = {
        contractId: this.tempContract.id,
      };
      this.$request(
        "post",
        "/contract/getCharge",
        requestData,
        (data) => {
          this.threadDrawerData = contractDrawData.contractShouFeiDrawerData(
            this.tempThread.name,
            data,
            this.contractSettingButtonList
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取合同支出情况
     */
    getExpendByContractId() {
      let requestData = {
        contractId: this.tempContract.id,
      };
      this.$request(
        "post",
        "/contract/getExpendByContractId",
        requestData,
        (data) => {
          this.threadDrawerData = contractDrawData.contractExpendDrawerData(
            this.tempThread.name,
            data,
            this.buttonAuth
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取收款记录
     */
    getReceiptRecord() {
      let requestData = {
        contractId: this.tempContract.id,
      };
      this.$request(
        "post",
        "/contract/getReceiptRecord",
        requestData,
        (data) => {
          this.threadDrawerData = contractDrawData.contractReturnedMoneyDrawerData(
            this.tempThread.name,
            data,
            this.contractSettingButtonList
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 查看具体附件内容
     */
    linkNowFile(data) {
      if (data.item.fileType == "img") {
        // 附件是图片
        this.dialogData.title = "查看收款附件";
        this.dialogData.width = "30%";
        this.dialogData.data = [
          {
            type: "show_img",
            value: data.item.file,
            title: "",
          },
        ];
        this.dialogData.buttonArray = [];
        this.dialogData.flag = true;
      } else if (data.item.file != "") {
        // 附件是文件
        // window.open(data.item.file, "_blank");

        if (this.$keyType.keyType(data.item.file) == "Object") {
          window.open(data.item.file.path, "_blank");
        } else if (this.$keyType.keyType(data.item.file) == "String") {
          window.open(data.item.file, "_blank");
        } else {
          this.$message.error("附件格式错误,请联系管理员!");
        }
      }
    },
    /**
     * 显示支出详情
     */
    showExpendDetails(data) {
      console.log("显示支出详情:", data, this.tempThread.name);
      let dd = {
        client: this.tempContract.name,
        contractNum: this.tempContract.name,
        contractId: this.tempContract.id,
        expendStatus: data.expendStatus,
        expendNum: data.expendNum,
        time: data.time,
        expendType: data.expendType,
        user: data.user,
        title: data.title,
        file: data.file, // 附件
        services: data.services,
      };
      this.dialogData.title = "支出";
      this.dialogData.width = "40%";
      this.dialogData.buttonArray = [];
      this.dialogData.buttonArray.push({
        text: "取消",
        active: "cancel_expend",
      });
      this.dialogData.data = [
        {
          type: "expend_details",
          title: "",
          value: dd,
        },
      ];
      this.dialogData.flag = true;
    },
    /**
     * 添加支出
     */
    addExpend() {
      let options = [
        {
          id: "",
          name: "刻章",
          flag: false,
        },
        {
          name: "地址费",
          flag: false,
        },
        {
          name: "材料费",
          flag: false,
        },
        {
          name: "外包成本",
          flag: false,
        },
        {
          name: "其他成本",
          flag: false,
        },
        {
          name: "业务招待费",
          flag: false,
        },
      ];
      this.dialogData.title = "新建支出";
      this.dialogData.buttonArray = [];
      this.dialogData.buttonArray.push({
        text: "取消",
        active: "cancel",
      });
      this.dialogData.data = [
        {
          type: "text",
          title: "",
          value: "请选择一个支出类型",
        },
        {
          type: "select_array_one",
          title: "",
          value: options,
        },
      ];
      this.dialogData.flag = true;
    },
    showExpend(value) {
      this.dialogData.data[0] = value;
    },
    /**
     * 是否赠送服务项单选框选择事件
     */
    radioChange(data) {
      console.log("是否赠送服务项单选框选择事件", data);

      if (data.item.title == "是否赠送服务") {
        if (data.val == "Y") {
          this.contractDrawerData.data.modelList[3].line[0].isShow = true;
        } else {
          this.contractDrawerData.data.modelList[3].line[0].isShow = false;
          this.contractDrawerData.data.modelList[3].line[0].value = [];
        }
      }
      if (data.item.title == "是否优惠") {
        if (data.val == "Y") {
          this.contractDrawerData.data.modelList[0].line[8].isShow = true;
          this.clientInfoDrawerData.data.modelList[0].line[8].value = "0.00";
        } else {
          this.contractDrawerData.data.modelList[0].line[8].isShow = false;
          this.contractDrawerData.data.modelList[0].line[8].value = "";
        }
      }
      this.saveDrawerData("赠送服务");
    },
    /**
     * 取消赠送服务项修改
     */
    cancelServiceItems() {
      this.showContractInfoByID();
    },
    /**
     * 删除具体赠送的服务项
     */
    deleteService(j) {
      for (
        let index = 0;
        index < this.threadDrawerData.data.modelList.length;
        index++
      ) {
        let element = this.threadDrawerData.data.modelList[index];
        for (let i = 0; i < element.line.length; i++) {
          let ele = element.line[i];
          if (ele.key == "freeServes") {
            // 赠送服务项
            console.log("删除赠送服务项");
            ele.value.splice(j, 1);
          }
        }
      }
      this.saveDrawerData("删除赠送服务");
    },

    /**
     * 新建合同
     */
    createContractDialogOpen(title) {
      // this.clientInfoDrawerData.data.modelList[0].line[0].tooltipf_flag = true;
      this.dialogData.title = "选择产品";
      this.dialogData.buttonArray = [];
      this.dialogData.buttonArray.push({
        text: "取消",
        active: "cancel",
      });
      this.dialogData.data = [
        {
          type: "input_disable",
          title: "客户名称",
          value: title,
        },
        {
          type: "input_carriage",
          title: "请选择一个服务产品",
          placeholder: "搜索服务产品",
          value: "",
        },
        {
          type: "select_array_one",
          title: "",
          value: this.productList,
        },
      ];
      this.dialogData.flag = true;
    },
    /**
     * 选择产品创建客户
     */
    choseProductTipOne(data) {
      if (this.dialogData.title == "选择产品") {
        let requestData = {
          userId: this.baseInfo.userId,
          name: this.tempThread.name, // 商机名称
          companyId: this.baseInfo.company.companyId,
          productId: data.id, // 合同模板ID
          threadId: this.tempThread.id, // 商机ID
          clientFlag: this.tempThread.clientFlag,
        };
        this.$request(
          "post",
          "/client/addClientDirect",
          requestData,
          (data) => {
            this.dialogData.flag = false;
            this.threadDrawerData.flag = false;
            // this.tempThreadId = ""; // 每次新增完,商机ID置空
            this.$message({
              message: "客户创建成功!",
              duration: 1000,
              type: "success",
            });
            this.$message({
              message: "客户创建成功!",
              duration: 1000,
              type: "success",
            });
            this.$operationRecords.saveOperationRecords(
              data.clientId,
              "创建客户【" + requestData.name + "】!",
              this.baseInfo,
              "client",
              this,
              (data1) => {
                this.$operationRecords.saveOperationRecords(
                  data.contractId,
                  "创建合同!",
                  this.baseInfo,
                  "contract",
                  this,
                  (data2) => {
                    this.showContractInfoByID();
                    this.initTableData();
                  }
                );
              }
            );
            this.initTableData();
          },
          (errorMessage) => {
            this.$message.error(errorMessage);
          }
        );
      }

      // 新建支出
      if (this.dialogData.title == "新建支出") {
        let dd = {
          client: this.tempClient.label,
          contractNum: this.tempContract.name,
          contractId: this.tempContract.id,
          expendStatus: "待提交",
          expendNum: "",
          time: "",
          expendType: data.name,
          user: this.baseInfo.userId,
          title: "",
          file: "", // 附件
          services: this.tempServices,
        };
        this.$request(
          "post",
          "/contract/addExpend",
          dd,
          (data) => {
            this.$message({
              message: "支出添加成功!",
              type: "success",
              duration: 1000,
            });
            this.dialogData.flag = false;
            this.$operationRecords.saveOperationRecords(
              this.tempContract.id,
              "添加支出!",
              this.baseInfo,
              "contract",
              this,
              (data) => {
                this.getExpendByContractId();
              }
            );
          },
          (errorMessage) => {
            this.$message.error(errorMessage);
          }
        );
      }
    },
    /**
     * 添加跟进记录
     */
    followThread() {
      let requestData = {
        threadId: this.tempThread.id, // 商机ID
        userId: this.baseInfo.userId, // 商机跟进人
        content: this.dialogData.data[0].value, // 跟进记录
        stage: this.dialogData.data[1].value, // 跟进阶段
        time: this.dialogData.data[2].value, // 下次跟进时间
      };
      this.$request(
        "post",
        // "/thread/followThread",
        "/reThread/followThread",
        requestData,
        (data) => {
          this.dialogData.flag = false;
          this.$operationRecords.saveOperationRecords(
            this.tempThread.id,
            "商机【" + this.tempThread.name + "】添加跟进记录!",
            this.baseInfo,
            "thread",
            this,
            (data) => {
              this.getMyThreadDetailsByThreadId();
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 商机转成交
     */
    makeBargainThread() {
      let requestData = {
        threadId: this.tempThread.id, // 商机ID
      };
      this.$request(
        "post",
        // "/thread/makeBargainThread",
        "/reThread/makeBargainThread",
        requestData,
        (data) => {
          this.dialogData.flag = false;
          this.$operationRecords.saveOperationRecords(
            this.tempThread.id,
            "将商机【" + this.tempThread.name + "】转成交!",
            this.baseInfo,
            "thread",
            this,
            (data) => {
              this.getMyThreadDetailsByThreadId();
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 商机置为无效
     */
    setInvalid(oper) {
      let requestData = {
        threadId: this.tempThread.id, // 商机ID
      };
      this.$request(
        "post",
        // "/thread/deleteThread",
        "/reThread/deleteThread",
        requestData,
        (data) => {
          this.dialogData.flag = false;
          this.threadDrawerData.flag = false;
          this.$operationRecords.saveOperationRecords(
            this.tempThread.id,
            "将商机【" + this.tempThread.name + "】置为无效",
            this.baseInfo,
            "thread",
            this,
            (data) => {
              this.initTableData();
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 将商机释放至商机池
     */
    redistributionThread(oper) {
      let requestData = {
        threadId: this.tempThread.id, // 商机ID
      };
      this.$request(
        "post",
        // "/thread/redistributionThread",
        "/reThread/redistributionThread",
        requestData,
        (data) => {
          this.dialogData.flag = false;
          this.threadDrawerData.flag = false;
          this.$operationRecords.saveOperationRecords(
            this.tempThread.id,
            "将商机【" + this.tempThread.name + "】释放至商机池!",
            this.baseInfo,
            "thread",
            this,
            (data) => {
              this.initTableData();
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 查看操作记录
     */
    getOperationRecordsList() {
      this.$operationRecords.showOperationRecords(
        this.tempThread.id,
        "thread",
        this
      );
    },
    /**
     * 确认添加意向产品
     */
    addPurposeThread() {
      let requestData = {
        threadId: this.tempThread.id, // 商机ID
        value: this.dialogData.data[0].value.chooseProduct,
      };
      this.$request(
        "post",
        // "/thread/addPurposeThread",
        "/reThread/addPurposeThread",
        requestData,
        (data) => {
          this.dialogData.flag = false;
          this.$operationRecords.saveOperationRecords(
            this.tempThread.id,
            "将商机【" + this.tempThread.name + "】添加意向产品!",
            this.baseInfo,
            "thread",
            this,
            (data) => {
              this.initTableData();
              this.getMyThreadDetailsByThreadId();
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 保存抽屉数据
     */
    saveDrawerData(drawerTitle) {
      if (
        !myThreadData.getDrawerSubmitData(this.threadDrawerData.data.modelList)
          .flag
      ) {
        this.$message({
          message: "联系人手机号格式不正确,请确认填入正确的手机号!",
          type: "error",
          duration: 1000,
        });
        // this.getMyThreadDetailsByThreadId();
        return;
      }
      let requestData = {
        threadId: this.tempThread.id,
        userId: this.baseInfo.userId,
        company: this.baseInfo.company.companyId,
        data: myThreadData.getDrawerSubmitData(
          this.threadDrawerData.data.modelList
        ).list,
      };
      requestData.data.push({
        value: this.threadDrawerData.data.title,
        isShow: true,
        isDisable: false,
        flag: "0",
        key: "threadName",
        title: "商机名称",
      });
      this.$request(
        "post",
        // "/thread/updateThread",
        "/reThread/updateThread",
        requestData,
        (data) => {
          // this.$message({
          //   message: "保存成功!",
          //   type: "success",
          //   duration: 1000,
          // });
          this.$operationRecords.saveOperationRecords(
            this.tempThread.id,
            "修改" + drawerTitle + "信息!",
            this.baseInfo,
            "thread",
            this,
            (data) => {
              this.getMyThreadDetailsByThreadId();
              this.initTableData();
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          // this.loading = false;
        }
      );
    },
    /**
     * 筛选弹出框显示
     */
    popoverOpen() {
      console.log("所有数组数据:", this.arrayList);
      // this.popoData.list[0].value = this.arrayList.employeeList// 商机状态
      this.popoData.list[1].options = []; // 客户来源
      this.popoData.list[7].options = []; // 商机创建人
      this.popoData.list[6].options = []; // 商机创建人
      this.popoData.list[8].options = []; // 意向产品
      this.arrayList.sourceList.forEach((element) => {
        if (element.active) {
          let dd = {
            label: element.name,
            value: element.value[0],
          };
          this.popoData.list[1].options.push(dd);
        }
      });

      this.popoData.list[6].options = this.employeeList;
      this.popoData.list[7].options = this.employeeList;
      this.productList.forEach((element) => {
        let dd = {
          label: element.name,
          value: element.id,
        };
        this.popoData.list[8].options.push(dd);
      });

      this.popoData.popoVisible = !this.popoData.popoVisible;
    },
    /**
     * 筛选弹出框确认事件
     */
    popoMakeSure() {
      this.searchData.screenList = this.$systemBaseData.remakesureSearchData(
        this.popoData.list
      );
      // console.log(this.popoData.list);
      this.popoData.popoVisible = false;
      this.initTableData();
    },
    /**
     * 关闭筛选标签事件
     */
    closeScreen(index) {
      this.searchData.screenList.splice(index, 1);
      this.initTableData();
    },
    /**
     * 筛选弹出框重置
     */
    popoReset() {
      this.$systemBaseData.popoReset(this.popoData.list);
      this.searchData.screenList = [];
    },
    /**
     * 右边抽屉父子组件连锁事件
     */
    showDrawer(value) {
      this.threadDrawerData = value;
    },
    /**
     * 拖拽表头排序父子组件连锁事件
     */
    showDrager(value) {
      this.tableMenuData = value;
    },
    /**
     * 右边抽屉关闭事件
     */
    closeUpdateTableDrawer() {
      console.log("ddddddddddddddddd");
      this.threadDrawerData.colseanimationflag = true;
      this.threadDrawerData.flag = false;
      // this.initTableData();
    },
    /**
     * 筛选弹出框关闭事件
     */
    popoCanael() {
      this.popoData.popoVisible = false;
    },
    /**
     * 表头div拖动方法
     */
    dragging(value) {
      console.log("div拖动", value);
    },
    /**
     * 弹出框关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
      this.dialogData.data.forEach((element) => {
        if (element.value) {
          element.value = "";
        }
      });
    },
    /**
     * 根据商机状态,修改行颜色
     */
    changeRowTextColor(data) {
      if (data.row.threadStatus == "已成交" || data.row.flag == 1) {
        return {
          color: "red",
          height: "71.5px",
        };
      } else {
        return {
          height: "71.5px",
        };
      }
    },

    /**
     * 查看跟进记录
     */
    showGenjin(clientData) {
      this.ttempClient = clientData;
      let requestData = {
        threadId: clientData.id,
        clientId: "",
      };
      this.$request(
        "post",
        "/followRecord/showFollowRecord",
        requestData,
        (data) => {
          this.dialogData.title = "跟进记录-" + clientData.threadName;
          this.dialogData.buttonArray = [];
          this.dialogData.data = [
            {
              type: "genjinjilu",
              value: {
                clientId: clientData.id,
                threadId: "",
                hasContent: true,
                content: "",
                time: "",
                list: data,
                hasTarget: false,
                target: "",
                hasPhase: true,
                phase: "",
              },
            },
          ];
          this.dialogData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 确定添加跟进记录
     */
    followRecord(recordData) {
      console.log("跟进记录:", recordData);
      let requestData = {
        clientId: "", // 商机ID
        threadId: this.ttempClient.id, // 商机ID
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId, // 操作人ID
        content: recordData.content, // 当前操作
        time: recordData.time, // 下次跟进时间
        stage: recordData.phase,
      };
      if (requestData.content == "") {
        this.$message.error("跟进内容不能为空!");
        return;
      }
      this.$request(
        "post",
        "/followRecord/addFollowRecord",
        requestData,
        (data) => {
          this.$message({
            message: "跟进记录添加成功!",
            type: "success",
            duration: 1000,
          });
          this.$operationRecords.saveOperationRecords(
            this.ttempClient.id,
            "添加跟进记录:" + recordData.content + "!",
            this.baseInfo,
            "client",
            this,
            (data2) => {
              this.initTableData();
              this.showGenjin(this.ttempClient);
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
  },
  mounted() {
    this.loading = true;
    this.searchData.userId = this.baseInfo.userId;
    this.searchData.tempUserId = [this.baseInfo.userId];
    this.isCharge();
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#all-thread {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .thread-screen {
    width: 100%;
    height: auto;
    border-bottom: 1px solid @boderColor;
    display: flex;
    flex-direction: column;
    align-items: center;
    .thread-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      > .title {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 0 10px;
      }
      > .el-tag {
        margin: 0 5px;
      }
      .thread-screen-fiftrate {
        width: 65px;
        min-width: 65px;
        height: 30px;
        border: 1px solid @boderColor;
        box-sizing: border-box;
        color: @boderActiveColor;
        font-size: @contentFontSize;
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: 0.3s;
        i {
          margin: 0 4px;
        }
      }
      .thread-screen-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }
      .thread-screen-search {
        width: 210px;
        min-width: 210px;
        height: 30px;
        margin-right: 5px;
      }
      .thread-screen-checked {
        width: 140px;
        height: 30px;
        min-width: 140px;
        font-size: @contentFontSize;
        margin: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .thread-screen-active {
        flex: 1;
      }
      .thread-screen-but-select {
        width: 140px;
        min-width: 160px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
        cursor: pointer;
        .thread-screen-but-select_but {
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.5s;
        }
        .thread-screen-but-select_but:hover {
          background-color: @activeColor2;
        }
        .thread-screen-but-select_select {
          width: 30px;
          height: 100%;
          border-left: 1px solid @activeColor3;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .thread-screen-but {
        width: 100px;
        min-width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .thread-screen-but:hover {
        cursor: pointer;
        background-color: @activeColor2;
      }
      .thread-screen-but-boder {
        width: 50px;
        min-width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        font-size: @contentFontSize;
        background-color: @activeBackground;
        color: @activeColor;
        margin: 0 5px;
        margin-right: 10px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .thread-screen-but-boder:hover {
        cursor: pointer;
        background-color: @activeBackground2;
      }
    }
  }
  .thread-table {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    position: relative;
    .thread-table-card-content-tip {
      width: 98%;
      height: 4vh;
      position: absolute;
      top: 2vh;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .thread-table-card-active {
        flex: 1;
      }
      .thread-table-card-edit {
        width: 30px;
        min-width: 30px;
        margin-right: 10px;
        z-index: 99;
        display: flex;
        align-items: center;
        justify-content: center;
        color: @editColor;
        cursor: pointer;
      }
    }
    .thread-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .thread-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        .thread-table-card-tab {
          width: 80px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .thread-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .thread-table-card-active {
          flex: 1;
        }
        .thread-table-card-edit {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: @editColor;
          cursor: pointer;
        }
      }
      .thread-table-card-content {
        flex: 1;
        width: 98%;
        height: 0;
        display: flex;
        align-content: center;
        justify-content: center;
        font-size: @contentFontSize;
        color: @closeColor;
      }
      .thread-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }
  .hri {
    display: flex;
    i {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 3px;
      font-size: 14px;
      // color: @activeColor;
      cursor: pointer;
    }
  }
}
.popover-search {
  // 筛选的pop弹出框
  max-height: 800px;
}
</style>
