<template>
  <div id="add-social">
    <div class="add-social-content">
      <div class="social-item">
        <div class="title">
          <div class="icon">*</div>
          姓名
        </div>
        <div class="value">
          <el-input placeholder="请输入姓名" v-model="value.name"></el-input>
        </div>
      </div>
      <div class="social-item">
        <div class="title">
          <div class="icon">*</div>
          身份证号
        </div>
        <div class="value">
          <el-input
            placeholder="请输入身份证号"
            v-model="value.identityCard"
          ></el-input>
        </div>
      </div>
      <div class="social-item">
        <div class="title">
          <div class="icon">*</div>
          性别
        </div>
        <div class="value">
          <el-radio-group class="radio" v-model="value.gender">
            <el-radio label="男">男</el-radio>
            <el-radio label="女">女</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="social-item">
        <div class="title">
          <div class="icon">*</div>
          手机号
        </div>
        <div class="value">
          <el-input placeholder="请输入手机号" v-model="value.phone"></el-input>
        </div>
      </div>
      <div class="social-item">
        <div class="title">
          <div class="icon">*</div>
          业务年月
        </div>
        <div class="value">
          <el-date-picker
            v-model="value.businessDate"
            type="month"
            value-format="yyyy-MM"
            placeholder="请输入业务年月"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="social-item">
        <div class="title">
          <div class="icon">*</div>
          缴费年月
        </div>
        <div class="value">
          <el-date-picker
            v-model="value.paymentDate"
            type="month"
            value-format="yyyy-MM"
            placeholder="请输入缴费年月"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="social-item">
        <div class="title">
          <div class="icon">*</div>
          单位缴费基数
        </div>
        <div class="value">
          <el-input
            :onkeyup="
              (value.unitPaymentBase = value.unitPaymentBase.replace(
                /[^0-9.]/g,
                ''
              ))
            "
            oninput="value=value.indexOf('.') > -1?value.slice(0, value.indexOf('.') + 3):value"
            v-model="value.unitPaymentBase"
            placeholder="请输入单位缴费基数"
          ></el-input>
        </div>
      </div>
      <div class="social-item">
        <div class="title">
          <div class="icon">*</div>
          工伤
        </div>
        <div class="value">
          <el-input
            :onkeyup="
              (value.industrialInjuryAmountOfUnit = value.industrialInjuryAmountOfUnit
                .toString()
                .replace(/[^0-9.]/g, ''))
            "
            oninput="value=value.indexOf('.') > -1?value.slice(0, value.indexOf('.') + 3):value"
            placeholder="请输入工伤金额"
            v-model="value.industrialInjuryAmountOfUnit"
          ></el-input>
        </div>
      </div>
      <div class="social-item" style="width: 100%; align-items: center">
        <div class="title" style="width: 14%;">
          上传附件
        </div>
        <div class="value" style="width: 86%;">
          <div class="icons">
            <div
              class="icon"
              v-for="(item, index) in value.annex"
              :key="index"
              v-viewer="{ movable: false }"
            >
              <img :src="item" style="width: 100%; height: 100%" />
              <div class="dele" @click="deleteItem(index)">
                <i class="el-icon-delete"></i>
              </div>
            </div>
            <div class="icon">
              <el-upload
                class="avatar-uploader"
                :action="uploadData.value.action"
                :show-file-list="false"
                :headers="uploadData.value.headers"
                :data="{ systemName: 'finance', title: '123' }"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <span
                  ><i class="el-icon-circle-plus-outline"></i> 添加附件</span
                >
              </el-upload>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      index: 0,
      value: {},
      uploadData: {
        title: "上传附件",
        type: "upload",
        value: {
          value: "",
          action: this.$fileBaseurl + "/uploadImageByMultipartFile",
          headers: {
            token: "95931b371eb64f4a9c862983ca8a5d15",
          },
          uploadParameter: {
            systemName: "finance",
            title: "新增社保人员附件",
          },
        },
      },
    };
  },
  methods: {
    // 附件上传之前的校验
    beforeAvatarUpload(file) {
      let isJPG = file.type === "image/jpeg" || file.type === "image/png";

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 格式!");
      }
      return isJPG;
    },
    // 附件上传成功后的事件
    handleAvatarSuccess(response, file, fileList) {
      this.$message({
        message: "上传成功!",
        type: "success",
        duration: 1000,
      });
      console.log("附件上传成功:", response);
      this.value.annex.push(response.result.path);
    },
    // 删除附件
    deleteItem(index) {
      this.value.annex.splice(index, 1);
    },
  },
  created() {
    this.value = this.data;
  },
};
</script>

<style lang="less" scoped>
#add-social {
  width: 100%;
  height: 100%;
  min-height: 250px;
  .add-social-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    .social-item {
      width: 46%;
      display: flex;
      align-items: baseline;
      padding: 10px 10px;
      font-size: 14px;
      .title {
        width: 20%;
        min-width: 100px;
        display: flex;
        align-items: baseline;
        justify-content: flex-end;
        margin-right: 8px;
        .icon {
          color: red;
          margin-right: 5px;
        }
      }
      .value {
        display: flex;
        flex-wrap: wrap;
        width: 80%;
        .radio {
          display: flex;
          flex-wrap: wrap;
          /deep/ .el-radio {
            margin: 6px;
          }
        }
        /deep/ .el-date-editor.el-input,
        .el-date-editor.el-input__inner {
          width: 100%;
        }
        > .icons {
          width: 0;
          height: auto;
          flex: 1;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          > .icon {
            width: 80px;
            height: 120px;
            border: 1px solid #cecece;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            color: #cecece;
            position: relative;
            transition: 0.3s;
            padding: 2px;
            margin: 2px;
            &:hover {
              cursor: pointer;
              color: #2873ff;
            }
            > i {
              margin-right: 5px;
            }
            > .dele {
              width: 100%;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              padding-right: 10px;
              font-size: 14px;
              color: #ff7919;
              position: absolute;
              top: 0;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
