<template>
  <div id="give-service-item">
    <!-- 赠送服务项 -->
    <div class="give-service-item-edit" v-if="value.isEdit">
      <div class="give-service-item-line">
        <div class="give-service-item-line-title">服务名称:</div>
        <div class="give-service-item-line-value">
          <el-select
            v-model="value.serviceItem"
            placeholder="请选择"
            value-key="id"
            filterable
            :disabled="isDisable"
            @change="changeServiceItem"
          >
            <el-option
              v-for="item in serviceItemList"
              :key="item.id"
              :label="item.name + item.price + '元/' + item.unit"
              :value="item"
            >
              <div :style="{ display: 'flex', 'align-items': 'center' }">
                <div
                  :style="{
                    color: item.icon.color,
                    borderColor: item.icon.color,
                    border: '1px solid',
                    width: 'auto',
                    padding: '0 5px',
                    boxSizing: 'border-box',
                    height: '30px',
                    dispaly: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '5px',
                    marginRight: '5px',
                  }"
                >
                  {{ item.icon.text }}
                </div>
                <div>
                  {{ item.name }}
                </div>
                <div>{{ item.price }}元/{{ item.unit }}</div>
              </div>
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="give-service-item-line" v-if="showTime">
        <div class="give-service-item-line-title">服务时间:</div>
        <div class="give-service-item-line-value">
          <span>延期</span>
          <div class="give-service-item-line-value-input">
            <el-input
              v-model="value.serviceTime"
              placeholder="0"
              type="number"
              :disabled="isDisable"
            ></el-input>
          </div>
          <span>个月</span>
        </div>
      </div>

      <div class="give-service-item-line">
        <div
          class="give-service-item-line-but"
          @click="savePresentServiceItems"
        >
          保存
        </div>
        <div class="give-service-item-line-but" @click="cancelServiceItems">
          取消
        </div>
      </div>
    </div>

    <div class="give-service-item-table" v-else>
      <!-- 表格数据 -->
      <div
        class="give-service-item-table-icon"
        :style="{
          color: value.serviceItem.icon.color,
          borderColor: value.serviceItem.icon.color,
        }"
      >
        {{ value.serviceItem.icon.text }}
      </div>
      <div class="give-service-item-table-name">
        {{ value.serviceItem.name }}
      </div>
      <div class="give-service-item-table-time" v-if="value.serviceTime != ''">
        延期{{ value.serviceTime }}个月
      </div>
      <div class="is-active" v-else></div>
      <div class="give-service-item-table-oper">
        <i
          class="el-icon-edit-outline"
          v-if="!isDisable"
          @click="value.isEdit = true"
        ></i>
      </div>
      <div class="give-service-item-table-oper">
        <i class="el-icon-delete" v-if="!isDisable" @click="deleteService"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    giveServiceItem: {
      type: Object,
      default: function() {
        return {};
      },
    },
    isShow: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
    isDisable: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
  },
  data() {
    return {
      value: this.giveServiceItem,
      baseInfo: {},
      // value: {
      //   isEdit: true, // 是否是编辑状态
      //   flag: true, // 是否是新增
      //   serviceItem: {}, //服务项
      //   serviceTime: "", // 服务时间
      // },
      showTime: false, // 是否显示服务时间
      serviceItemList: [],
    };
  },
  watch: {
    giveServiceItem() {
      //父组件值变化->>子组件也变化
      this.value = this.giveServiceItem;
    },
    value(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {
    /**
     * 服务项改变事件
     */
    changeServiceItem(val) {
      if (val.unit == "次") {
        this.showTime = false;
        this.value.serviceTime = "";
      } else {
        this.showTime = true;
      }
    },
    /**
     * 保存设置
     */
    savePresentServiceItems() {
      this.value.isEdit = false;
      this.$emit("savePresentServiceItems");
    },
    /**
     * 取消设置
     */
    cancelServiceItems() {
      this.$emit("cancelServiceItems");
    },
    /**
     * 删除服务
     */
    deleteService() {
      this.$emit("deleteService");
    },
  },
  mounted() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    if (this.value.serviceTime == "") {
      this.showTime = false;
    } else {
      this.showTime = true;
    }
    let requestData = {
      companyId: this.baseInfo.company.companyId,
      status: "1", // 0为停用;1为启用;2为全部
      name: "", // 模糊查询
      type: "", // 服务项类别ID 获取全部数据时,该项为空
    };
    this.$request(
      "post",
      "/servicesItem/getServicesItem",
      requestData,
      (data) => {
        this.serviceItemList = data;
      },
      (errorMessage) => {
        this.$message.error(errorMessage);
      }
    );
  },
};
</script>

<style lang="less" scoped>
#give-service-item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .give-service-item-edit {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .give-service-item-line {
      width: 70%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      .give-service-item-line-title {
        width: 30%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 5px;
      }
      .give-service-item-line-value {
        width: 0;
        height: 100%;
        flex: 1;
        display: flex;
        align-items: center;
        // justify-content: center;
        span {
          width: auto;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 10px;
          box-sizing: border-box;
        }
        .give-service-item-line-value-input {
          flex: 1;
          width: 0;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .give-service-item-line-but {
        width: auto;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
        box-sizing: border-box;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.3s;
        &:nth-child(1) {
          background-color: @activeColor;
          color: #fff;
          margin-right: 20px;
        }
        &:nth-child(2) {
          border: 1px solid @activeColor;
          color: @activeColor;
        }
        &:hover {
          background-color: @activeColor2;
          color: #fff;
        }
      }
    }
  }

  .give-service-item-table {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 12px;
    .give-service-item-table-icon {
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid;
      border-radius: 5px;
      margin-left: 10px;
    }
    .give-service-item-table-name {
      width: auto;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 30px 0 10px;
      box-sizing: border-box;
    }
    .give-service-item-table-time {
      width: 0;
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      &::before {
        content: "";
        width: 3px;
        height: 3px;
        background-color: @activeColor;
        border-radius: 3px;
        margin-right: 5px;
      }
    }
    .give-service-item-table-oper {
      width: 15px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: @activeColor;
      cursor: pointer;
      margin-right: 5px;
    }
    .is-active {
      flex: 1;
    }
  }
}
</style>
