<template>
  <div id="serveice-details">
    <div class="serveice-details-line">
      <div class="serveice-details-line-title">服务名称</div>
      <div class="serveice-details-line-value">
        <el-select
          v-model="value[0].value"
          placeholder="请选择服务"
          value-key="id"
          filterable
        >
          <el-option
            v-for="item2 in value[0].options"
            :key="item2.id"
            :label="item2.name"
            :value="item2"
          >
            <span>{{ item2.name }}</span>
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="serveice-details-line is-table">
      <div class="serveice-details-line-tr is-head">
        <div class="serveice-details-line-tr-cell">服务时间</div>
        <div class="serveice-details-line-tr-cell">单价(元/月)</div>
        <!-- <div class="serveice-details-line-tr-cell">折扣与减免(每月)</div> -->
        <!-- <div class="serveice-details-line-tr-cell">实际单价(元/月)</div> -->
        <!-- <div class="serveice-details-line-tr-cell">
          <el-button type="text" @click="addService">添加服务项</el-button>
        </div> -->
      </div>
      <div
        class="serveice-details-line-tr"
        v-for="(item, index) in value[6].value"
        :key="index"
      >
        <div class="serveice-details-line-tr-cell">
          <!-- <el-date-picker
            style="width: 150px"
            v-model="item.time1"
            type="date"
            :disabled="index == 0"
            placeholder="选择日期"
            prefix-icon="none"
            value-format="yyyy-MM"
          >
          </el-date-picker> -->
          {{ value[1].value }}
        </div>
        <div class="serveice-details-line-tr-cell">
          <!-- 原价 -->
          <el-input v-model="item.price" placeholder="请输入内容"></el-input>
        </div>
        <!-- <div class="serveice-details-line-tr-cell">
          <div v-if="value[3].value.flag == 0 || value[3].value.flag == '0'">
            --
          </div>
          <div v-if="value[3].value.flag == 1 || value[3].value.flag == '1'">
            {{ value[3].value.discount }}%
          </div>
          <div v-if="value[3].value.flag == 2 || value[3].value.flag == '2'">
            <el-input
              v-model="item.discounts"
              placeholder="请输入内容"
            ></el-input>
          </div>
        </div> -->
        <!--  <div class="serveice-details-line-tr-cell">
        实际单价 
          <el-input v-model="item.money" placeholder="请输入内容"></el-input>
        </div>-->
        <!-- <div class="serveice-details-line-tr-cell">
          <el-button type="text" v-if="index != 0">删除</el-button>
        </div> -->
      </div>
    </div>

    <div class="serveice-details-line">
      <div class="serveice-details-line-but" @click="but_click_sure">保存</div>
      <div class="serveice-details-line-but" @click="but_click_canel">取消</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["serviceDetailsData"],
  data() {
    return {
      value: this.serviceDetailsData,
    };
  },
  watch: {
    serviceDetailsData() {
      //父组件值变化->>子组件也变化
      this.value = this.serviceDetailsData;
    },
    value(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {
    but_click_sure(index) {
      this.$emit("but_click_sure");
    },
    but_click_canel(index) {
      this.$emit("but_click_canel");
    },
    addService() {
      let tempTime = JSON.parse(
        JSON.stringify(this.value[6].value[this.value[6].value.length - 1])
      );
      let tt = tempTime.time1.split("-");
      this.value[6].value[this.value[6].value.length - 1].time2 =
        tt[0] + "-" + ((parseInt(tt[1]) + 1) % 12);

      let dd = {
        time1: this.value[6].value[this.value[6].value.length - 1].time2,
        time2: tempTime.time2,
        discounts: "",
        money: "",
        price: "",
      };
      this.value[6].value.push(dd);
    },
    radioChange() {
      let data = this.value[3].value.mitigate;
      let discounts = data / 12;
      this.value[6].value.forEach((element) => {
        element.discounts = discounts;
      });
    },
  },
  mounted() {
    console.log("服务项详细信息:", this.value);
  },
};
</script>

<style lang="less" scoped>
#serveice-details {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .serveice-details-line {
    width: 100%;
    height: auto;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    .serveice-details-line-title {
      width: 100px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 5px;
      box-sizing: border-box;
    }
    .serveice-details-line-value {
      flex: 1;
      height: 100%;
      width: 0;
      display: flex;
      align-items: center;
      .selectItem {
        flex: 1;
        display: flex;
      }
    }
    .serveice-details-line-tr {
      width: 100%;
      height: 30px;
      border-bottom: 1px solid @boderColor;
      border-left: 1px solid @boderColor;
      border-right: 1px solid @boderColor;
      display: flex;
      .serveice-details-line-tr-cell {
        flex: 1;
        height: 100%;
        width: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .is-head {
      border-top: 1px solid @boderColor;
      background-color: rgba(190, 212, 221, 0.438);
    }
    .serveice-details-line-but {
      width: 70px;
      height: 30px;
      border: 1px solid @activeColor;
      color: @activeColor;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 3px 10px;
      border-radius: 5px;
      cursor: pointer;
    }
  }
  .is-table {
    flex-direction: column;
  }
}
</style>