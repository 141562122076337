<template>
  <div id="custom-to-do">
    <div class="custom-to-do-line">
      <div class="custom-to-do-line-title">待办事项:</div>
      <div class="custom-to-do-line-value">
        <el-input v-model="value.name" placeholder="请输入待办事项"></el-input>
      </div>
    </div>
    <div class="custom-to-do-line">
      <div class="custom-to-do-line-title">相关客户:</div>
      <div class="custom-to-do-line-value">
        <el-select v-model="value.clientId" filterable placeholder="请选择">
          <el-option
            v-for="item in clientList"
            :key="item.clientId"
            :label="item.clientName"
            :value="item.clientId"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="custom-to-do-table">
      <div class="custom-to-do-table-line tr">
        <div class="custom-to-do-table-line-cell">步骤</div>
        <div class="custom-to-do-table-line-cell">执行人</div>
        <div class="custom-to-do-table-line-cell">执行时间</div>
        <div class="custom-to-do-table-line-cell">
          操作
          <i class="el-icon-circle-plus-outline" @click="add(-1)"></i>
        </div>
      </div>

      <div
        class="custom-to-do-table-line"
        v-for="(item, index) in value.data"
        :key="index"
      >
        <div class="custom-to-do-table-line-cell">
          <el-input v-model="item.name" placeholder="请输入待办事项"></el-input>
        </div>
        <div class="custom-to-do-table-line-cell">
          <el-select
            v-model="item.person"
            filterable
            multiple
            placeholder="请选择"
          >
            <el-option
              v-for="it in options"
              :key="it.userId"
              :label="it.userName"
              :value="it.userId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="custom-to-do-table-line-cell">
          <el-date-picker
            v-model="item.time"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </div>
        <div class="custom-to-do-table-line-cell">
          <i class="el-icon-circle-plus-outline" @click="add(index)"></i>
          <i class="el-icon-remove-outline" @click="remove(index)"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["customToDoData"],
  data() {
    return {
      value: this.customToDoData,
      // value: {
      //   name: "", // 待办事项名称
      //   data: [
      //     {
      //       name: "", // 步骤名称
      //       person: [], // 步骤执行人
      //       time: "", // 步骤预计执行时间
      //     },
      //   ],
      // },
      options: JSON.parse(
        JSON.stringify(this.$store.getters.getAllEmployeeList)
      ),
      clientList: [],
      baseInfo: {},
    };
  },
  watch: {
    customToDoData() {
      //父组件值变化->>子组件也变化
      this.value = this.customToDoData;
    },
    value(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("showCustomToDoData", newvalue);
    },
  },
  methods: {
    /**
     * 添加执行步骤
     */
    add(index) {
      let dd = {
        name: "",
        person: [],
        time: "",
      };
      if (index == 0) {
        if (this.value.data.length > 0) {
          if (
            this.value.data[index].name != "" &&
            this.value.data[index].person.length > 0 &&
            this.value.data[index].time != ""
          ) {
            this.value.data.splice(index + 1, 0, dd);
          }
        } else {
          this.value.data.splice(index, 0, dd);
        }
      } else if (index == -1) {
        this.value.data.splice(index + 1, 0, dd);
      } else {
        if (
          this.value.data[index].name != "" &&
          this.value.data[index].person.length > 0 &&
          this.value.data[index].time != ""
        ) {
          this.value.data.splice(index + 1, 0, dd);
        }
      }
    },
    /**
     * 删除执行步骤
     */
    remove(index) {
      this.value.data.splice(index, 1);
    },
  },
  mounted() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    let clientList = JSON.parse(
      JSON.stringify(this.$store.getters.getAllClientByUserId)
    );
    this.clientList = clientList;
    // clientList.forEach((element) => {
    //   let dd = {
    //     id: element.clientId,
    //     clientName: element.clientName,
    //   };
    //   this.clientList.push(dd);
    // });
  },
};
</script>

<style lang="less" scoped>
#custom-to-do {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  .custom-to-do-line {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    .custom-to-do-line-title {
      width: 20%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 10px;
      box-sizing: border-box;
    }
    .custom-to-do-line-value {
      width: 0;
      height: 100%;
      flex: 1;
      display: flex;
      align-items: center;
    }
  }
  .custom-to-do-table {
    width: 100%;
    height: 500px;
    max-height: 500px;
    display: flex;
    flex-direction: column;
    border-left: 1px solid @boderColor;
    border-top: 1px solid @boderColor;
    box-sizing: border-box;
    .custom-to-do-table-line {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid @boderColor;
      box-sizing: border-box;
      .custom-to-do-table-line-cell {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid @boderColor;
        box-sizing: border-box;
        &:nth-child(2) {
          flex: 2;
        }
        i {
          margin: 0 4px;
          transition: 0.3s;
          &:hover {
            cursor: pointer;
            color: @activeColor;
          }
        }
      }
    }
    .tr {
      background-color: @activeBackground;
      color: @activeColor;
    }
  }
}
</style>
