import * as baseData from "./baseData.js"
import * as employeeData from "./employeeData.js";
import * as phoneCheck from "../../utils/mobilePhoneNumberCheck.js"
import Vue from 'vue'
/**
 * 获取我的商机和全部商机中的抽屉数据格式
 * @param {*} title 当前商机名称
 * @param {*} data 抽屉数据
 */
export function myThreadDrawerData(title, data) {

    let myThreadDrawerData = {
        title: "销售商机",
        flag: false,
        hasSetting: true,
        colseanimationflag: false,
        settingOperList: [{
            text: "置为无效",
            authority: data.buttonAuth.length > 0 ? baseData.judgeButtonAuthority("置为无效", data.buttonAuth) : 1,
            active: "zhiweiwuxiao",
            key: "invalid"
        }, {
            text: "释放至公海",
            authority: data.buttonAuth.length > 0 ? baseData.judgeButtonAuthority("释放至商机池", data.buttonAuth) : 1,
            active: "shifang",
            key: "openSea"
        }, {
            text: "删除",
            authority: data.buttonAuth.length > 0 ? baseData.judgeButtonAuthority("删除", data.buttonAuth) : 1,
            active: "delete_thread",
            key: "delete"
        }, {
            text: "操作记录",
            authority: data.buttonAuth.length > 0 ? baseData.judgeButtonAuthority("操作记录", data.buttonAuth) : 1,
            active: "operating_record",
            key: "cancel"
        }, ],
        buttonList: [{
                text: "跟进",
                authority: data.buttonAuth.length > 0 ? baseData.judgeButtonAuthority("跟进", data.buttonAuth) : 1,
                icon: "el-icon-edit-outline",
                active: "genjin",
                key: "followUp"
            },
            {
                text: "转成交",
                authority: data.buttonAuth.length > 0 ? baseData.judgeButtonAuthority("转成交", data.buttonAuth) : 1,
                icon: "el-icon-user",
                active: "zhuanchengjiao",
                key: "turnover"
            },
            {
                text: "新建合同",
                authority: data.buttonAuth.length > 0 ? baseData.judgeButtonAuthority("新建合同", data.buttonAuth) : 1,
                icon: "el-icon-user",
                active: "create-contract",
                key: ""
            },

        ],
        data: {
            title: title,
            titleEdit: true,
            customer: data.customer.name, // 关联客户,与商机状态关联,(只有商机状态为成交才有值),其他时候为空字符串
            isDisable: false, // 是否只读
            modelList: [{
                    line: [{
                            horizontal: true,
                            isShow: true, // 是否显示
                            isDisable: false, // 是否只读
                            title: "跟进联系人",
                            key: "followName",
                            flag: "0", // 当前字段是否在其他字段中:0不在,1在
                            type: "select",
                            value: "180****8888",
                            options: [{
                                    label: "180****8888",
                                    value: "180****8888",
                                },
                                {
                                    label: "张三",
                                    value: "张三",
                                },
                                {
                                    label: "李四",
                                    value: "李四",
                                },
                                {
                                    label: "王五",
                                    value: "王五",
                                },
                            ],
                        },
                        {
                            horizontal: true,
                            isShow: false,
                            isDisable: false,
                            title: "商机状态",
                            key: "threadStatus",
                            flag: "0", // 当前字段是否在其他字段中:0不在,1在
                            type: "text",
                            value: "跟进中",
                        },
                        {
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            title: "跟进阶段",
                            key: "followStage",
                            flag: "1", // 当前字段是否在其他字段中:0不在,1在
                            type: "text",
                            value: "",
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "片区",
                            flag: "1", // 当前字段是否在其他字段中:0不在,1在
                            type: "select",
                            value: "",
                            options: [],
                        },
                        {
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            marked: true,
                            title: "客户来源",
                            key: "customerSource",
                            flag: "1", // 当前字段是否在其他字段中:0不在,1在
                            type: "selects",
                            value: "",
                            options: [],
                        }, {
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            title: "商机创建人",
                            key: "createName",
                            flag: "0", // 当前字段是否在其他字段中:0不在,1在
                            type: "text",
                            value: "",
                        },
                    ],
                },
                {
                    line: [
                        {
                        horizontal: true,
                        isShow: true,
                        isDisable: false,
                        title: "商机提交人",
                        key: "createName",
                        flag: "0", // 当前字段是否在其他字段中:0不在,1在
                        type: "select",
                        value: "",
                        options: [{
                            label: "180****8888",
                            value: "180****8888",
                        }, ],
                    }, ]
                },
                {
                    line: [{
                        horizontal: true,
                        isShow: true,
                        isDisable: false,
                        marked: true,
                        title: "",
                        flag: "1", // 当前字段是否在其他字段中:0不在,1在
                        type: "linkman",
                        value: [{
                            name: "张三",
                            phone: ["", ""]
                        }],
                    }, ]
                },
                {
                    line: [{
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "增值税类型",
                            key: "addedValueTaxTypes",
                            flag: "1", // 当前字段是否在其他字段中:0不在,1在
                            type: "select",
                            value: "",
                            options: [],
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: true,
                            title: "创建日期",
                            key: "createTime",
                            flag: "0", // 当前字段是否在其他字段中:0不在,1在
                            type: "time",
                            value: "",
                        },
                        {
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            title: "地区",
                            flag: "1", // 当前字段是否在其他字段中:0不在,1在
                            type: "area",
                            value: [],
                        },
                        {
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            title: "详细地址",
                            flag: "1", // 当前字段是否在其他字段中:0不在,1在
                            type: "input",
                            value: "跟进中",
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "企业税号",
                            type: "input-but",
                            value: "--",
                            buts: [{
                                text: "工商信息",
                                active: "get_companyInfo"
                            }]
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "联系电话",
                            type: "input",
                            value: "--",
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "开户行",
                            type: "input",
                            value: "--",
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "银行账号",
                            type: "input",
                            value: "--",
                        },
                    ],
                },
                {
                    line: [{
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            title: "意向产品",
                            marked: true,
                            key: "intentionalProduct",
                            flag: "1", // 当前字段是否在其他字段中:0不在,1在
                            type: "tags",
                            value: [],
                        },
                        {
                            horizontal: true,
                            isShow: true,
                            title: "备注",
                            flag: "1", // 当前字段是否在其他字段中:0不在,1在
                            type: "textarea",
                            value: "180****8888",
                        },
                    ],
                },
                {
                    line: [{
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            title: "跟进记录",
                            type: "text",
                            value: "",
                        },
                        {
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            title: "",
                            flag: "0", // 当前字段是否在其他字段中:0不在,1在
                            type: "record", // 跟进记录
                            value: [{
                                icon: "", // 当前操作人头像
                                name: "qwere", // 当前操作人
                                content: "223", // 跟进记录
                                stage: "跟进阶段", // 跟进阶段
                                time: "111", // 下次联系时间
                                operTime: "222", // 当前记录操作时间
                            }],
                        },
                    ],
                },
            ],
        },
    }
    if (data.data.length > 0) {
        let index = 0
        for (let i = 0; i < myThreadDrawerData.data.modelList.length; i++) {
            let element = myThreadDrawerData.data.modelList[i].line;
            for (let j = 0; j < element.length; j++) {
                let e = element[j];
                if (e.title != "跟进记录") {
                    // if (e.title == "片区") {
                    //     e.value = data.data[index].value.length == 0 ? "" : data.data[index].value[0]
                    // } else
                    if (e.title == "客户来源") {
                        if (data.data[index].value.length == 0) {
                            e.value = ""
                        } else {
                            e.value = data.data[index].value[0]
                                // data.data[index].value.forEach(ee => {
                                //     e.value = e.value + ee.title
                                // });
                        }
                    } else if (e.type == "linkman") {
                        // 联系人
                        if (data.data[index].value.length == 0) {
                            let dd = {
                                name: "",
                                phone: ["", ""],
                            };
                            data.data[index].value.push(dd);

                        }
                        e.value = data.data[index].value
                    } else {
                        e.value = data.data[index].value
                    }
                    e.isShow = data.data[index].isShow
                    e.isDisable = data.data[index].isDisable
                    e.flag = data.data[index].flag
                    if (data.data[index].key) {
                        e.key = data.data[index].key
                    }
                    index++
                }
            }
        }
    }
    return myThreadDrawerData
}

/**
 * 获取商机池中的抽屉数据格式
 * @param {*} title 当前商机名称
 * @param {*} data 抽屉数据
 */
export function threadPoolDrawerData(title, data) {

    let myThreadDrawerData = {
            title: "销售商机",
            flag: false,
            hasSetting: true,
            colseanimationflag: false,
            settingOperList: [{
                text: "恢复商机",
                authority: data.buttonAuth.length > 0 ? baseData.judgeButtonAuthority("恢复商机", data.buttonAuth) : 1,
                active: "recover",
                key: "restore"
            }, {
                text: "删除",
                authority: data.buttonAuth.length > 0 ? baseData.judgeButtonAuthority("删除", data.buttonAuth) : 1,
                active: "delete_thread",
                key: "delete"
            }, {
                text: "操作记录",
                authority: data.buttonAuth.length > 0 ? baseData.judgeButtonAuthority("操作记录", data.buttonAuth) : 1,
                active: "operating_record",
                key: "cancel"
            }, ],
            buttonList: [{
                    text: "分配",
                    // authority: 1,
                    authority: data.buttonAuth.length > 0 ? baseData.judgeButtonAuthority("分配", data.buttonAuth) : 1,
                    icon: "el-icon-edit-outline",
                    active: "allocation",
                    key: "distribution"
                }, {
                    text: "领取",
                    authority: 1,
                    // authority: data.buttonAuth.length > 0 ? baseData.judgeButtonAuthority("分配", data.buttonAuth) : 1,
                    icon: "el-icon-finished",
                    active: "to_receive",
                    key: "cancel"
                },

            ],
            data: {
                title: title,
                customer: data.customer.name, // 关联客户,与商机状态关联,(只有商机状态为成交才有值),其他时候为空字符串
                isDisable: false, // 是否只读
                modelList: [{
                        line: [{
                                horizontal: true,
                                isShow: true, // 是否显示
                                isDisable: false, // 是否只读
                                title: "跟进联系人",
                                key: "followName",
                                flag: "0", // 当前字段是否在其他字段中:0不在,1在
                                type: "select",
                                value: "180****8888",
                                options: [{
                                        label: "180****8888",
                                        value: "180****8888",
                                    },
                                    {
                                        label: "张三",
                                        value: "张三",
                                    },
                                    {
                                        label: "李四",
                                        value: "李四",
                                    },
                                    {
                                        label: "王五",
                                        value: "王五",
                                    },
                                ],
                            },
                            {
                                horizontal: true,
                                isShow: false,
                                isDisable: false,
                                title: "商机状态",
                                key: "threadStatus",
                                flag: "0", // 当前字段是否在其他字段中:0不在,1在
                                type: "text",
                                value: "跟进中",
                            },
                            {
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                title: "跟进阶段",
                                key: "followStage",
                                flag: "1", // 当前字段是否在其他字段中:0不在,1在
                                type: "text",
                                value: "",
                            },
                            {
                                horizontal: false,
                                isShow: true,
                                isDisable: false,
                                title: "片区",
                                flag: "1", // 当前字段是否在其他字段中:0不在,1在
                                type: "select",
                                value: "",
                                options: [],
                            },
                            {
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                marked: true,
                                title: "客户来源",
                                key: "customerSource",
                                flag: "1", // 当前字段是否在其他字段中:0不在,1在
                                type: "selects",
                                value: "",
                                options: [],
                            }, {
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                title: "商机创建人",
                                key: "createName",
                                flag: "0", // 当前字段是否在其他字段中:0不在,1在
                                type: "text",
                                value: "",
                            },
                        ],
                    },
                    {
                        line: [{
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            title: "商机提交人",
                            key: "createName",
                            flag: "0", // 当前字段是否在其他字段中:0不在,1在
                            type: "select",
                            value: "",
                            options: [{
                                    label: "180****8888",
                                    value: "180****8888",
                                },
                                {
                                    label: "张三",
                                    value: "张三",
                                },
                                {
                                    label: "李四",
                                    value: "李四",
                                },
                                {
                                    label: "王五",
                                    value: "王五",
                                },
                            ],
                        }, ]
                    },
                    {
                        line: [{
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            marked: true,
                            title: "",
                            flag: "1", // 当前字段是否在其他字段中:0不在,1在
                            type: "linkman",
                            value: [{
                                name: "张三",
                                phone: ["123****2211", "132****9099"]
                            }],
                        }, ]
                    },
                    {
                        line: [{
                                horizontal: false,
                                isShow: true,
                                isDisable: false,
                                title: "增值税类型",
                                key: "addedValueTaxTypes",
                                flag: "1", // 当前字段是否在其他字段中:0不在,1在
                                type: "select",
                                value: "",
                                options: [],
                            },
                            {
                                horizontal: false,
                                isShow: true,
                                isDisable: true,
                                title: "创建日期",
                                key: "createTime",
                                flag: "0", // 当前字段是否在其他字段中:0不在,1在
                                type: "time",
                                value: "",
                            },
                            {
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                title: "地区",
                                flag: "1", // 当前字段是否在其他字段中:0不在,1在
                                type: "area",
                                value: "180****8888",
                            },
                            {
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                title: "详细地址",
                                flag: "1", // 当前字段是否在其他字段中:0不在,1在
                                type: "input",
                                value: "跟进中",
                            },
                        ],
                    },
                    {
                        line: [{
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                title: "意向产品",
                                marked: true,
                                key: "intentionalProduct",
                                flag: "1", // 当前字段是否在其他字段中:0不在,1在
                                type: "tags",
                                value: [],
                            },
                            {
                                horizontal: true,
                                isShow: true,
                                title: "备注",
                                flag: "1", // 当前字段是否在其他字段中:0不在,1在
                                type: "textarea",
                                value: "180****8888",
                            },
                        ],
                    },
                    {
                        line: [{
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                title: "跟进记录",
                                type: "text",
                                value: "",
                            },
                            {
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                title: "",
                                flag: "0", // 当前字段是否在其他字段中:0不在,1在
                                type: "record", // 跟进记录
                                value: [{
                                    icon: "", // 当前操作人头像
                                    name: "qwere", // 当前操作人
                                    content: "223", // 跟进记录
                                    stage: "跟进阶段", // 跟进阶段
                                    time: "111", // 下次联系时间
                                    operTime: "222", // 当前记录操作时间
                                }],
                            },
                        ],
                    },
                ],
            },
        }
        // if (data.data.length > 0) {
        //     let index = 0
        //     for (let i = 0; i < myThreadDrawerData.data.modelList.length; i++) {
        //         let element = myThreadDrawerData.data.modelList[i].line;
        //         for (let j = 0; j < element.length; j++) {
        //             let e = element[j];
        //             if (e.title != "跟进记录") {
        //                 // if (e.title == "片区") {
        //                 //     e.value = data.data[index].value.length == 0 ? "" : data.data[index].value[0]
        //                 // } else
        //                 if (e.title == "客户来源") {
        //                     if (data.data[index].value.length == 0) {
        //                         e.value = ""
        //                     } else {
        //                         e.value = data.data[index].value[0]
        //                             // data.data[index].value.forEach(ee => {
        //                             //     e.value = e.value + ee.title
        //                             // });
        //                     }
        //                 } else {
        //                     e.value = data.data[index].value
        //                 }
        //                 e.isShow = data.data[index].isShow
        //                 e.isDisable = data.data[index].isDisable
        //                 e.flag = data.data[index].flag
        //                 if (data.data[index].key) {
        //                     e.key = data.data[index].key
        //                 }
        //                 index++
        //             }
        //         }
        //     }
    if (data.data.length > 0) {
        let index = 0
        for (let i = 0; i < myThreadDrawerData.data.modelList.length; i++) {
            let element = myThreadDrawerData.data.modelList[i].line;
            for (let j = 0; j < element.length; j++) {
                let e = element[j];
                if (e.title != "跟进记录") {
                    // if (e.title == "片区") {
                    //     e.value = data.data[index].value.length == 0 ? "" : data.data[index].value[0]
                    // } else
                    if (e.title == "客户来源") {
                        if (data.data[index].value.length == 0) {
                            e.value = ""
                        } else {
                            e.value = data.data[index].value[0]
                                // data.data[index].value.forEach(ee => {
                                //     e.value = e.value + ee.title
                                // });
                        }
                    } else if (e.type == "linkman") {
                        // 联系人
                        if (data.data[index].value.length == 0) {
                            let dd = {
                                name: "",
                                phone: ["", ""],
                            };
                            data.data[index].value.push(dd);

                        }
                        e.value = data.data[index].value
                    } else {
                        e.value = data.data[index].value
                    }
                    e.isShow = data.data[index].isShow
                    e.isDisable = data.data[index].isDisable
                    e.flag = data.data[index].flag
                    if (data.data[index].key) {
                        e.key = data.data[index].key
                    }
                    index++
                }
            }
        }
    }
    return myThreadDrawerData
}
/**
 * 获取回收站中的抽屉数据格式
 * @param {*} title 当前商机名称
 * @param {*} data 抽屉数据
 */
export function threadRecyleDrawerData(title, data) {

    let myThreadDrawerData = {
        title: "销售商机",
        flag: false,
        hasSetting: true,
        colseanimationflag: false,
        settingOperList: [{
            text: "彻底删除",
            authority: data.buttonAuth.length > 0 ? baseData.judgeButtonAuthority("删除", data.buttonAuth) : 1,
            active: "delete_thread",
            key: "shiftDelete"
        }, {
            text: "操作记录",
            authority: data.buttonAuth.length > 0 ? baseData.judgeButtonAuthority("操作记录", data.buttonAuth) : 1,
            active: "operating_record",
            ksy: "cancel"
        }, ],
        buttonList: [{
            text: "还原",
            authority: data.buttonAuth.length > 0 ? baseData.judgeButtonAuthority("还原", data.buttonAuth) : 1,
            icon: "el-icon-help",
            active: "recover",
            key: "reduction"
        }, ],
        data: {
            title: title,
            titleEdit: true,
            customer: data.customer.name, // 关联客户,与商机状态关联,(只有商机状态为成交才有值),其他时候为空字符串
            isDisable: false, // 是否只读
            modelList: [{
                    line: [{
                            horizontal: true,
                            isShow: true, // 是否显示
                            isDisable: false, // 是否只读
                            title: "跟进联系人",
                            key: "followName",
                            flag: "0", // 当前字段是否在其他字段中:0不在,1在
                            type: "select",
                            value: "180****8888",
                            options: [{
                                    label: "180****8888",
                                    value: "180****8888",
                                },
                                {
                                    label: "张三",
                                    value: "张三",
                                },
                                {
                                    label: "李四",
                                    value: "李四",
                                },
                                {
                                    label: "王五",
                                    value: "王五",
                                },
                            ],
                        },
                        {
                            horizontal: true,
                            isShow: false,
                            isDisable: false,
                            title: "商机状态",
                            key: "threadStatus",
                            flag: "0", // 当前字段是否在其他字段中:0不在,1在
                            type: "text",
                            value: "跟进中",
                        },
                        {
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            title: "跟进阶段",
                            key: "followStage",
                            flag: "1", // 当前字段是否在其他字段中:0不在,1在
                            type: "text",
                            value: "",
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "片区",
                            flag: "1", // 当前字段是否在其他字段中:0不在,1在
                            type: "select",
                            value: "",
                            options: [],
                        },
                        {
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            marked: true,
                            title: "客户来源",
                            key: "customerSource",
                            flag: "1", // 当前字段是否在其他字段中:0不在,1在
                            type: "selects",
                            value: "",
                            options: [],
                        }, {
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            title: "商机创建人",
                            key: "createName",
                            flag: "0", // 当前字段是否在其他字段中:0不在,1在
                            type: "text",
                            value: "",
                        },
                    ],
                },
                {
                    line: [{
                        horizontal: true,
                        isShow: true,
                        isDisable: false,
                        title: "商机提交人",
                        key: "createName",
                        flag: "0", // 当前字段是否在其他字段中:0不在,1在
                        type: "select",
                        value: "",
                        options: [{
                                label: "180****8888",
                                value: "180****8888",
                            },
                            {
                                label: "张三",
                                value: "张三",
                            },
                            {
                                label: "李四",
                                value: "李四",
                            },
                            {
                                label: "王五",
                                value: "王五",
                            },
                        ],
                    }, ]
                },
                {
                    line: [{
                        horizontal: true,
                        isShow: true,
                        isDisable: false,
                        marked: true,
                        title: "",
                        flag: "1", // 当前字段是否在其他字段中:0不在,1在
                        type: "linkman",
                        value: [{
                            name: "张三",
                            phone: ["", ""]
                        }],
                    }, ]
                },
                {
                    line: [{
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "增值税类型",
                            key: "addedValueTaxTypes",
                            flag: "1", // 当前字段是否在其他字段中:0不在,1在
                            type: "select",
                            value: "",
                            options: [],
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: true,
                            title: "创建日期",
                            key: "createTime",
                            flag: "0", // 当前字段是否在其他字段中:0不在,1在
                            type: "time",
                            value: "",
                        },
                        {
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            title: "地区",
                            flag: "1", // 当前字段是否在其他字段中:0不在,1在
                            type: "area",
                            value: [],
                        },
                        {
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            title: "详细地址",
                            flag: "1", // 当前字段是否在其他字段中:0不在,1在
                            type: "input",
                            value: "跟进中",
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "企业税号",
                            type: "input-but",
                            value: "--",
                            buts: [{
                                text: "工商信息",
                                active: "get_companyInfo"
                            }]
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "联系电话",
                            type: "input",
                            value: "--",
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "开户行",
                            type: "input",
                            value: "--",
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "银行账号",
                            type: "input",
                            value: "--",
                        },
                    ],
                },
                {
                    line: [{
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            title: "意向产品",
                            marked: true,
                            key: "intentionalProduct",
                            flag: "1", // 当前字段是否在其他字段中:0不在,1在
                            type: "tags",
                            value: [],
                        },
                        {
                            horizontal: true,
                            isShow: true,
                            title: "备注",
                            flag: "1", // 当前字段是否在其他字段中:0不在,1在
                            type: "textarea",
                            value: "180****8888",
                        },
                    ],
                },
                {
                    line: [{
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            title: "跟进记录",
                            type: "text",
                            value: "",
                        },
                        {
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            title: "",
                            flag: "0", // 当前字段是否在其他字段中:0不在,1在
                            type: "record", // 跟进记录
                            value: [{
                                icon: "", // 当前操作人头像
                                name: "qwere", // 当前操作人
                                content: "223", // 跟进记录
                                stage: "跟进阶段", // 跟进阶段
                                time: "111", // 下次联系时间
                                operTime: "222", // 当前记录操作时间
                            }],
                        },
                    ],
                },
            ],
        },
    }

    if (data.data.length > 0) {
        let index = 0
        for (let i = 0; i < myThreadDrawerData.data.modelList.length; i++) {
            let element = myThreadDrawerData.data.modelList[i].line;
            for (let j = 0; j < element.length; j++) {
                let e = element[j];
                if (e.title != "跟进记录") {
                    // if (e.title == "片区") {
                    //     e.value = data.data[index].value.length == 0 ? "" : data.data[index].value[0]
                    // } else
                    if (e.title == "客户来源") {
                        if (data.data[index].value.length == 0) {
                            e.value = ""
                        } else {
                            e.value = data.data[index].value[0]
                                // data.data[index].value.forEach(ee => {
                                //     e.value = e.value + ee.title
                                // });
                        }
                    } else if (e.type == "linkman") {
                        // 联系人
                        if (data.data[index].value.length == 0) {
                            let dd = {
                                name: "",
                                phone: ["", ""],
                            };
                            data.data[index].value.push(dd);

                        }
                        e.value = data.data[index].value
                    } else {
                        e.value = data.data[index].value
                    }
                    e.isShow = data.data[index].isShow
                    e.isDisable = data.data[index].isDisable
                    e.flag = data.data[index].flag
                    if (data.data[index].key) {
                        e.key = data.data[index].key
                    }
                    index++
                }
            }
        }
    }
    return myThreadDrawerData
}

export function getDrawerSubmitData(data) {
    let list = []
    let flag = true
    let returnData = {}
    for (let index = 0; index < data.length; index++) {
        let element = data[index].line;
        for (let j = 0; j < element.length; j++) {
            let e = element[j];
            if (e.title != "跟进记录") {
                let dd = {
                    value: e.title == "客户来源" ? e.value == "" ? [] : [{title: e.value, value: e.value}] : e.value,
                    isShow: e.isShow,
                    isDisable: e.isDisable,
                    flag: e.flag,
                    title: e.title == "" ? e.type == 'linkman' ? "联系人" : "跟进记录" : e.title
                }
                if (e.key) {
                    Vue.set(dd, "key", e.key)
                }
                if (e.type == 'linkman') {
                    // 联系人 判断联系人手机号校验
                    dd.value.forEach(contart => {
                        contart.phone.forEach(phone => {
                            if (phone != "" && !phoneCheck.mobilePhoneNumberCheck(phone)) {
                                flag = false
                            }
                        });
                    });
                }
                list.push(dd)
            }
        }
    }
    returnData = {
        list: list,
        flag: flag
    }
    return returnData
}

export function ThreadShareTableMenuData() {
    let menuData = [
        // 从数据库拿到的数据
        {
            key: "clientName",
            title: "客户全称",
            width: "250",
            isDisable: true, // 自定义表头是否显示
            fixed: true
        },
        {
            key: "type",
            title: "共享商机类型",
            width: "130",
            isDisable: true, // 自定义表头是否显示
        },
        {
            key: "submitUserName",
            title: "商机提交人",
            width: "150",
            isDisable: true, // 自定义表头是否显示
        },
        {
            key: "shareUserNames",
            title: "商机共享人",
            width: "150",
            isDisable: true, // 自定义表头是否显示
        },
        {
            key: "customerSource",
            title: "客户来源",
            width: "150",
            isDisable: true, // 自定义表头是否显示
        },
        {
            key: "followUserName",
            title: "跟进联系人",
            width: "150",
            isDisable: true, // 自定义表头是否显示
        },
        {
            key: "followStage",
            title: "跟进阶段",
            width: "150",
            isDisable: true, // 自定义表头是否显示
        },
        {
            key: "contactName",
            title: "联系人",
            width: "150",
            isDisable: true, // 自定义表头是否显示
        },
        {
            key: "phone",
            title: "手机号",
            width: "150",
            isDisable: true, // 自定义表头是否显示
        },
        {
            key: "followRecordsContent",
            title: "跟进记录",
            width: "150",
            isDisable: true, // 自定义表头是否显示
        },
        {
            key: "followRecordsTime",
            title: " 跟进时间(排序)",
            width: "150",
            isDisable: true, // 自定义表头是否显示
        },
        {
            key: "gmtCreate",
            title: "创建时间(排序)",
            width: "150",
            isDisable: true, // 自定义表头是否显示
        },
    ]
    return menuData
}

export function myThreadTableMenuData() {
    let menuData = [
        // 从数据库拿到的数据
        {
            key: "clientName",
            title: "客户全称",
            width: "130",
            isDisable: true, // 自定义表头是否显示
        },
        {
            key: "service",
            title: "服务项",
            width: "120",
            isDisable: true,
        },
        {
            key: "manager",
            title: "客户经理",
            width: "200",
            isDisable: true,
        },
        {
            key: "clientStatus",
            title: "客户状态",
            width: "130",
            isDisable: true,
        },
        {
            key: "addedValueTaxType",
            title: "增值税类型",
            width: "100",
            isDisable: true,
        },
        {
            key: "createTime",
            title: "创建时间",
            width: "100",
            isDisable: true,
        },
    ]
    return menuData
}



export function myThreadClientTableMenuData() {
    let menuData = [
        // 从数据库拿到的数据
        {
            key: "threadName",
            title: "客户全称",
            width: "270",
            isDisable: true, // 自定义表头是否显示
        },
        {
            key: "threadStatus",
            title: "状态",
            width: "100",
            isDisable: true,
        },
        {
            key: "createName",
            title: "创建人",
            width: "100",
            isDisable: true,
        },
        {
            key: "submitName",
            title: "商机提交人",
            width: "100",
            isDisable: true,
        },
        {
            key: "followName",
            title: "跟进人",
            width: "100",
            isDisable: true,
        },
        {
            key: "followUpRecords",
            title: "跟进记录",
            width: "100",
            isDisable: true,
        },
        {
            key: "submitName",
            title: "商机提交人",
            width: "100",
            isDisable: true,
        },
        {
            key: "intentionalProduct",
            title: "意向产品",
            width: "120",
            isDisable: true,
        },
        {
            key: "lastContactTime",
            title: "上次联系时间",
            width: "100",
            isDisable: true,
        },
        {
            key: "createTime",
            title: "创建时间",
            width: "100",
            isDisable: true,
        },
    ]
    return menuData
}

/**
 * 初始化表头数据
 */
export function initTableMenuData(data) {
    let list = []
    data.forEach(element => {
        if (element.key && element.isShow) {
            list.push(element)
        }
    });
    return list
}

// /**
//  * 初始化表格数据
//  */
// export function getInitTableData(array, menuArray) {
//     let data = []
//     array.forEach(thread => {
//         menuArray.forEach(menu => {
//             if (thread[menu.key].constructor === Array) {
//                 let dd = ""
//                 if (thread[menu.key].length > 0) {
//                     thread[menu.key].forEach(element => {
//                         if (menu.key == "intentionalProduct") {
//                             // 意向产品
//                             if (element.serve.length > 0) {
//                                 element.serve.forEach(el => {
//                                     if (dd == "") {
//                                         dd = el.name
//                                     } else {
//                                         dd = dd + "," + el.name
//                                     }
//                                 });
//                             }
//                         } else {
//                             if (dd == "") {
//                                 dd = element.title
//                             } else {
//                                 dd = dd + "," + element.title
//                             }
//                         }
//                     });
//                 }
//                 thread[menu.key] = dd
//             }
//         });
//     });
//     return array
// }

/**
 * 初始化表格数据
 */
export function regetInitTableData(array, menuArray) {
    let data = []
    array.forEach(thread => {
        menuArray.forEach(menu => {          
            if (thread[menu.key].constructor === Array) {
                let dd = ""
                if (thread[menu.key].length > 0) {
                    thread[menu.key].forEach(element => {
                        if (dd == "") {
                            dd = element.title
                        } else {
                            dd = dd + "," + element.title
                        }
                    });
                }
                thread[menu.key] = dd
            }
        });
    });
    return array
}

/**
 * 获取界面筛选数据
 * 
 */
export function myThreadSearchData() {
    let data = [{
            title: "商机状态",
            type: "tip",
            hasSearch: false,
            flag: "0",
            key: "state",
            value: [
                { text: "待跟进", is_active: false, id: "" },
                { text: "跟进中", is_active: false, id: "" },
                { text: "已成交", is_active: false, id: "" },
                { text: "待分配", is_active: false, id: "" },
                { text: "无效", is_active: false, id: "" },
            ],
        },
        {
            title: "客户来源",
            type: "select",
            flag: "1",
            hasSearch: false,
            key: "customerSource",
            value: "",
            options: [{ label: "来源一", value: "来源一" }],
        },
        {
            title: "所属部门",
            type: "tip",
            flag: "0",
            hasSearch: true,
            key: "department",
            search_value: "",
            value: [
                // { text: "工商部", is_active: false },
                // { text: "会计部", is_active: false },
            ],
            options: [
                { label: "工商部", value: "工商部" },
                { label: "会计部", value: "会计部" },
            ],
        },
        {
            title: "下次联系时间",
            type: "times",
            flag: "1",
            hasSearch: false,
            key: "nextContactTime",
            value: "",
        },
        {
            title: "上次联系时间",
            type: "times",
            flag: "1",
            hasSearch: false,
            key: "lastContactTime",
            value: "",
        },
        {
            title: "最近分配时间",
            type: "times",
            flag: "0",
            hasSearch: false,
            key: "allocateTime",
            value: "",
        },
        {
            title: "创建时间",
            type: "times",
            flag: "0",
            hasSearch: false,
            key: "createTime",
            value: "",
        },
        {
            title: "增值税类型",
            type: "tip",
            flag: "1",
            hasSearch: false,
            key: "addedValueTaxType",
            search_value: "",
            value: [
                // { text: "一般纳税人", is_active: false, id: "" },
                // { text: "小规模纳税人", is_active: false, id: "" },
            ],
        },
        {
            title: "跟进联系人",
            type: "tip",
            flag: "0",
            hasSearch: true,
            key: "followName",
            search_value: "",
            value: [
                // { text: "张三", is_active: false },
                // { text: "李四", is_active: false },
            ],
            options: [
                { label: "张三", value: "张三" },
                { label: "李四", value: "李四" },
                { label: "王五", value: "王五" },
            ],
        },
        {
            title: "商机提交人",
            type: "tip",
            flag: "0",
            hasSearch: true,
            key: "submitName",
            search_value: "",
            value: [
                // { text: "张三", is_active: false },
                // { text: "李四", is_active: false },
            ],
            options: [
                { label: "张三", value: "张三" },
                { label: "李四", value: "李四" },
                { label: "王五", value: "王五" },
            ],
        },
        {
            title: "商机创建人",
            type: "tip",
            flag: "0",
            hasSearch: true,
            key: "createName",
            search_value: "",
            value: [
                // { text: "张三", is_active: false },
                // { text: "李四", is_active: false },
            ],
            options: [
                { label: "张三", value: "张三" },
                { label: "李四", value: "李四" },
                { label: "王五", value: "王五" },
            ],
        },
        {
            title: "意向产品",
            type: "tip",
            hasSearch: false,
            flag: "0",
            key: "wantPro",
            value: [],
        },
    ]
    return data
}

/**
 * 获取界面筛选数据
 * 
 */
export function remyThreadSearchData() {
    let data = [{
            title: "商机状态",
            type: "tip",
            hasSearch: false,
            flag: "0",
            key: "state",
            value: [
                { text: "待跟进", is_active: false, id: "" },
                { text: "跟进中", is_active: false, id: "" },
                { text: "已成交", is_active: false, id: "" },
                { text: "待分配", is_active: false, id: "" },
                { text: "无效", is_active: false, id: "" },
                { text: "已流失", is_active: false, id: "" },
            ],
        },
        {
            title: "客户来源",
            type: "select",
            flag: "0",
            hasSearch: false,
            key: "customerSource",
            value: "",
            options: [{ label: "来源一", value: "来源一" }],
        },
        {
            title: "下次联系时间",
            type: "times",
            flag: "0",
            hasSearch: false,
            key: "nextContactTime",
            value: "",
        },
        {
            title: "上次联系时间",
            type: "times",
            flag: "0",
            hasSearch: false,
            key: "lastContactTime",
            value: "",
        },
        {
            title: "最近分配时间",
            type: "times",
            flag: "0",
            hasSearch: false,
            key: "allocateTime",
            value: "",
        },
        {
            title: "创建时间",
            type: "times",
            flag: "0",
            hasSearch: false,
            key: "createTime",
            value: "",
        },
        {
            title: "商机提交人",
            type: "tip",
            flag: "0",
            hasSearch: true,
            key: "submitName",
            search_value: "",
            value: [
                // { text: "张三", is_active: false },
                // { text: "李四", is_active: false },
            ],
            options: [
                { label: "张三", value: "张三" },
                { label: "李四", value: "李四" },
                { label: "王五", value: "王五" },
            ],
        },
        {
            title: "商机创建人",
            type: "tip",
            flag: "0",
            hasSearch: true,
            key: "createName",
            search_value: "",
            value: [
                // { text: "张三", is_active: false },
                // { text: "李四", is_active: false },
            ],
            options: [
                { label: "张三", value: "张三" },
                { label: "李四", value: "李四" },
                { label: "王五", value: "王五" },
            ],
        },
        {
            title: "意向产品",
            type: "tip",
            hasSearch: true,
            flag: "0",
            key: "wantPro",
            search_value: "",
            value: [],
            options: []
        },
    ]
    return data
}

/**
 * 获取界面筛选数据
 * 
 */
export function reThreadPoolSearchData() {
    let data = [{
            title: "商机状态",
            type: "tip",
            hasSearch: false,
            flag: "0",
            key: "state",
            value: [
                { text: "待跟进", is_active: false, id: "" },
                { text: "跟进中", is_active: false, id: "" },
                { text: "已成交", is_active: false, id: "" },
                { text: "待分配", is_active: false, id: "" },
                { text: "无效", is_active: false, id: "" },
                { text: "已流失", is_active: false, id: "" },
            ],
        },
        {
            title: "客户来源",
            type: "select",
            flag: "0",
            hasSearch: false,
            key: "customerSource",
            value: "",
            options: [{ label: "来源一", value: "来源一" }],
        },
        {
            title: "下次联系时间",
            type: "times",
            flag: "0",
            hasSearch: false,
            key: "nextContactTime",
            value: "",
        },
        {
            title: "上次联系时间",
            type: "times",
            flag: "0",
            hasSearch: false,
            key: "lastContactTime",
            value: "",
        },
        {
            title: "最近分配时间",
            type: "times",
            flag: "0",
            hasSearch: false,
            key: "allocateTime",
            value: "",
        },
        {
            title: "创建时间",
            type: "times",
            flag: "0",
            hasSearch: false,
            key: "createTime",
            value: "",
        },
        {
            title: "商机提交人",
            type: "tip",
            flag: "0",
            hasSearch: true,
            key: "submitName",
            search_value: "",
            value: [
                // { text: "张三", is_active: false },
                // { text: "李四", is_active: false },
            ],
            options: [
                { label: "张三", value: "张三" },
                { label: "李四", value: "李四" },
                { label: "王五", value: "王五" },
            ],
        },
        {
            title: "商机创建人",
            type: "tip",
            flag: "0",
            hasSearch: true,
            key: "createName",
            search_value: "",
            value: [
                // { text: "张三", is_active: false },
                // { text: "李四", is_active: false },
            ],
            options: [],
        },
        {
            title: "意向产品",
            type: "tip",
            hasSearch: true,
            flag: "0",
            key: "wantPro",
            search_value: "",
            value: [],
            options: []
        },
        {
            title: "弃用原因",
            type: "tip",
            hasSearch: true,
            flag: "0",
            key: "giveUpReason",
            search_value: "",
            value: [],
            options: [{
                    label: "号码错误",
                    value: "号码错误",
                },
                {
                    label: "同行电话",
                    value: "同行电话",
                },
                {
                    label: "态度恶劣",
                    value: "态度恶劣",
                },
                {
                    label: "没有意向",
                    value: "没有意向",
                }, {
                    label: "风险号码",
                    value: "风险号码"
                }
            ]
        },
    ]
    return data
}

/**
 * 获取添加跟进记录时的dialog数据
 */
export function getMyThreadsGenjinjiluData() {
    return [{
            type: "textarea",
            placeholder: "请输入跟进记录",
            value: "",
        },
        {
            title: "跟进阶段",
            type: "select",
            placeholder: "请选择阶段",
            value: "",
            options: [],
        },
        {
            title: "下次联系时间",
            type: "time",
            value: "",
        },
    ]
}

/**
 * 新增客户商机抽屉数据
 */
export function getNewThreadDrawerData() {
    let data = {
        title: "新增销售商机",
        flag: false,
        hasSetting: false,
        buttonList: [],
        data: {
            title: "新建商机",
            isDisable: false, // 是否只读
            modelList: [{
                line: [{
                    isShow: true, // 是否显示
                    isDisable: false, // 是否只读
                    title: "",
                    type: "create_thread", // 带建议的input框,必须要有list
                    value: {
                        threadName: "", // 商机名称
                        contact: "", // 联系人
                        phone: "", // 手机号

                        list: [],
                    },
                }]
            }]
        }
    }
    return data
}

/**
 * 获取片区的模拟数据
 */
export function getSimulatorAreaData() {
    return [{
        title: "蜀山区",
        value: "蜀山区"
    }, {
        title: "庐阳区",
        value: "庐阳区"
    }]
}

/**
 * 获取客户来源的模拟数据
 */
export function getSimulatorSourceData() {
    return [{
        title: "推广渠道",
        value: ["推广渠道1", "推广渠道2"]
    }, {
        title: "58同城",
        value: ["58同城", "啥啥啥"]
    }, ]
}

/**
 * 获取各种标签数据
 */
export function getTagList(arrayList, type, companyId, this_) {
    let requestData = {
        type: type,
        companyId: companyId
    };
    this_.$request(
        "post",
        "/getTagList",
        requestData,
        (data) => {
            if (data.length == 0) {
                // 初次获取该模块标签,读取配置文件
                arrayList.areaList = baseData.getTagList(
                    "片区",
                    "thread"
                ).value;
                arrayList.sourceList = baseData.getTagList(
                    "客户来源",
                    "thread"
                ).value;
                arrayList.followStageList = baseData.getTagList(
                    "跟进阶段",
                    "thread"
                ).value;
                arrayList.addedValueTaxTypeList = baseData.getTagList(
                    "增值税类型",
                    "thread"
                ).value;
                arrayList.accountOnBehalfList = baseData.getTagList(
                    "代账情况",
                    "thread"
                ).value;
                arrayList.intentionLevelList = baseData.getTagList(
                    "意向等级",
                    "thread"
                ).value;
                arrayList.threadShareTypeList = baseData.getTagList(
                    "共享商机类型",
                    "thread"
                ).value;
            } else {
                data.forEach((element) => {
                    if (element.type == "片区") {
                        arrayList.areaList = element.value;
                    }
                    if (element.type == "客户来源") {
                        arrayList.sourceList = element.value;
                    }
                    if (element.type == "跟进阶段") {
                        arrayList.followStageList = element.value;
                    }
                    if (element.type == "增值税类型") {
                        arrayList.addedValueTaxTypeList = element.value;
                    }
                    if (element.type == "代账情况") {
                        arrayList.accountOnBehalfList = element.value;
                    }
                    if (element.type == "意向等级") {
                        arrayList.intentionLevelList = element.value;
                    }
                    if (element.type == "共享商机类型") {
                        arrayList.threadShareTypeList = element.value;
                    }
                });
            }
            getPersonList(companyId, arrayList, this_)
            getDepartmentList(companyId, arrayList, this_)
        },
        (errorMessage) => {
            this_.$message.error(errorMessage);
        }
    );
}

/**
 * 获取所有员工列表
 */
export function getPersonList(companyId, arrayList, this_) {
    this_.$store.dispatch("getAllEmployeeList", this_);
    let employeeList = JSON.parse(
        JSON.stringify(this_.$store.getters.getAllEmployeeList)
    );
    arrayList.employeeList = [];
    employeeList.forEach((element) => {
        let dd = {
            label: element.userName,
            value: element.userId,
        };
        arrayList.employeeList.push(dd);
    });
}

/**
 * 获取部门列表
 */
export function getDepartmentList(companyId, arrayList, this_) {
    // let requestData = {
    //     companyId: companyId,
    // };
    // this_.$request(
    //     "post",
    //     "/department/getDepartmentList",
    //     requestData,
    //     (data) => {
    //         arrayList.departmentList = data;
    //     },
    //     (errorMessage) => {
    //         this_.$message.error(errorMessage);
    //     }
    // );
    this_.$store.dispatch("getAllDepartmentList", this_);
    let departmentList = JSON.parse(
        JSON.stringify(this_.$store.getters.getAllDepartmentList)
    );
    arrayList.departmentList = [];
    departmentList.forEach((element) => {
        let dd = {
            label: element.departmentName,
            value: element.departmentId,
        };
        arrayList.departmentList.push(dd);
    });
}

/**
 * 添加意向产品dialog数据
 */
export function getAddPurposeThreadDialogData(threadName, array) {
    let data = [{
        type: "input_disable",
        title: "商机名称",
        value: threadName
    }, {
        type: "input_carriage",
        title: "提交订单",
        placeholder: "搜索服务产品",
        value: "",
    }, {
        type: "select_array",
        title: "",
        value: array,
    }]
    return data
}

/**
 * 初始化意向产品dialog数据
 */
export function initPurposeArrayData(allList, array) {
    allList.forEach(element => {
        Vue.set(element, "flag", false)
        array.forEach(el => {
            if (el.id == element.id) {
                element.flag = true
            }
        });
    });
    return allList
}

/**
 * 确认提交选中的意向产品数据格式化
 */
export function formatPurposeArrayData(allList) {
    let data = []
    allList.forEach(element => {
        if (element.flag) {
            data.push(element)
        }
    });
    return data
}

/**
 * 获取当前选择的意向产品数量
 */
export function getPurposeNum(array) {
    let index = 0
    array.forEach(element => {
        if (element.flag) {
            index++
        }
    });
    return index
}

/**
 * 导入资源的dialog数据
 */
export function improtResourcesDialogData(this_) {
    return [{
        type: "improt_resources",
        value: {
            url: "",
            text: "",
            action: this_.$fileBaseurl + "/uploadImageByMultipartFile",
            uploadParameter: {
                systemName: "finance",
                title: "导入资源",
            },
            headers: {
                token: "95931b371eb64f4a9c862983ca8a5d15",
            },
            modelType: "resourcesMode"
        },

    }]
}

/**
 * 我的资源抽屉数据
 */
export function getMyResourcesDrawerData(data) {

    let value = data.data.contact.map(item => {
        return {
            error: [item.error[0], item.error[1]],
            name: item.name,
            phone: [item.phone[0], item.phone[1]]
        }
    })
    let myThreadDrawerData = {
        title: "资源详情",
        flag: false,
        hasSetting: true,
        colseanimationflag: false,
        settingOperList: [{
            text: "操作记录",
            authority: 1,
            active: "operating_record"
        }, {
            text: "删除资源",
            authority: 1,
            active: "delete_resource"
        }, ],
        buttonList: [],
        data: {
            title: data.data.name,
            titleEdit: true,
            customer: "", // 关联客户,与商机状态关联,(只有商机状态为成交才有值),其他时候为空字符串
            isDisable: false, // 是否只读
            modelList: [{
                    line: [{
                        horizontal: true,
                        isShow: true,
                        isDisable: false,
                        marked: true,
                        title: "",
                        flag: "1", // 当前字段是否在其他字段中:0不在,1在
                        type: "linkman",
                        value: value,
                    }, ]
                },
                {
                    line: [{
                        horizontal: true,
                        isShow: true,
                        title: "备注",
                        flag: "1", // 当前字段是否在其他字段中:0不在,1在
                        type: "textarea",
                        value: data.data.remark,
                    }, ],
                },
            ],
        },
    }
    if (data.length > 0) {
        let index = 0
        for (let i = 0; i < myThreadDrawerData.data.modelList.length; i++) {
            let element = myThreadDrawerData.data.modelList[i].line;
            for (let j = 0; j < element.length; j++) {
                let e = element[j];
                if (e.title == "客户来源") {
                    if (data.data[index].value.length == 0) {
                        e.value = ""
                    } else {
                        e.value = data.data[index].value[0]
                            // data.data[index].value.forEach(ee => {
                            //     e.value = e.value + ee.title
                            // });
                    }
                } else if (e.type == "linkman") {
                    // 联系人
                    if (data.data[index].value.length == 0) {
                        let dd = {
                            error: [false, false],
                            name: "",
                            phone: ["", ""],
                        };
                        data.data[index].value.push(dd);
                    }
                    e.value = data.data[index].value
                    
                } else {
                    e.value = data.data[index].value
                }
                e.isShow = data.data[index].isShow
                e.isDisable = data.data[index].isDisable
                e.flag = data.data[index].flag
                if (data.data[index].key) {
                    e.key = data.data[index].key
                }
                index++
            }
        }
    }
    return myThreadDrawerData
}

/**
 * 新增客户商机抽屉数据
 */
export function getNewResourceDrawerData() {
    let data = {
        title: "新建资源",
        flag: false,
        hasSetting: false,
        buttonList: [],
        data: {
            title: "新建资源",
            isDisable: false, // 是否只读
            modelList: [{
                line: [{
                    isShow: true, // 是否显示
                    isDisable: false, // 是否只读
                    title: "",
                    type: "create_resource", // 带建议的input框,必须要有list
                    value: "",
                }]
            }]
        }
    }
    return data
}