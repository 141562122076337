<template>
  <div id="organization-setting">
    <!-- 个人资料界面 -->
    <div class="table-content">
      <div class="person-info-content customScrollBar">
        <div class="person-info-content-title">
          机构设置
          <i
            class="el-icon-edit"
            v-if="disable"
            @click="edit"
            v-showFunction="{ active: 'edit', array: allFunctionButtons }"
          ></i>
          <i class="el-icon-finished" v-if="!disable" @click="saveValue"></i>
          <i class="el-icon-close" v-if="!disable" @click="cancelEdit"></i>
        </div>
        <div class="person-info-content-data">
          <div class="person-info-content-line">
            <div class="person-info-content-line-span">机构名称</div>
            <div class="person-info-content-line-value">
              {{ baseInfo.company.companyName }}
            </div>
          </div>
          <div class="person-info-content-line">
            <div class="person-info-content-line-span">机构LOGO</div>
            <div class="person-info-content-line-value">
              <img
                style="width: 140px; height: 40px"
                :src="logo"
                object-fit="contain"
              />
            </div>
          </div>
          <div class="person-info-content-line" v-if="!disable">
            <div class="person-info-content-line-span"></div>
            <div class="person-info-content-line-value textcolor">
              建议选择140 x 40px的图片
            </div>
          </div>
          <div class="person-info-content-line" v-if="!disable">
            <div class="person-info-content-line-span"></div>
            <div class="person-info-content-line-value">
              <el-upload
                class="avatar-uploader"
                :action="action"
                :show-file-list="false"
                :headers="headers"
                :data="uploadParameter"
                :on-success="handleAvatarSuccess"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </div>
          </div>
          <div class="person-info-content-line" v-if="whetherAgencyHead">
            <div class="person-info-content-line-span">电子合同二维码LOGO</div>
            <div class="person-info-content-line-value">
              <img
                style="width: 60px; height: 60px"
                :src="companyQrPng"
                object-fit="contain"
              />
            </div>
          </div>
          <div
            class="person-info-content-line"
            v-if="!disable && whetherAgencyHead"
          >
            <div class="person-info-content-line-span"></div>
            <div class="person-info-content-line-value textcolor">
              建议选择60 x 60px的图片
            </div>
          </div>
          <div
            class="person-info-content-line"
            v-if="!disable && whetherAgencyHead"
          >
            <div class="person-info-content-line-span"></div>
            <div class="person-info-content-line-value">
              <el-upload
                class="avatar-uploader"
                :action="action"
                :show-file-list="false"
                :headers="headers"
                :data="uploadParameter1"
                :on-success="handleAvatarSuccess1"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </div>
          </div>
          <div class="person-info-content-line">
            <div class="person-info-content-line-span">机构收款账号</div>
            <div class="person-info-content-line-value">
              <el-table :data="accountList" height="500" border>
                <el-table-column prop="account" label="账号"> </el-table-column>
                <el-table-column prop="type" label="支付方式">
                </el-table-column>
                <el-table-column prop="owner" label="账号所属者">
                </el-table-column>
                <el-table-column prop="clientName" label="公司名称">
                </el-table-column>
                <el-table-column label="操作" align="center">
                  <template slot="header">
                    <span>操作</span>
                    <el-button
                      type="primary"
                      size="mini"
                      plain
                      @click="accountClick({}, 2)"
                      icon="el-icon-plus"
                      v-showFunction="{
                        active: 'addPaymentAccount',
                        array: allFunctionButtons,
                      }"
                      circle
                    ></el-button>
                  </template>
                  <template slot-scope="scope">
                    <el-button
                      type="primary"
                      size="mini"
                      plain
                      @click="accountClick(scope.row, 0)"
                      v-showFunction="{
                        active: 'editPaymentAccount',
                        array: allFunctionButtons,
                      }"
                      >编辑</el-button
                    >
                    <el-button
                      type="danger"
                      size="mini"
                      plain
                      @click="accountClick(scope.row, 1)"
                      v-showFunction="{
                        active: 'deletePaymentAccount',
                        array: allFunctionButtons,
                      }"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import my_dialog from "@/components/dialog.vue";
export default {
  components: {
    my_dialog,
  },
  data() {
    return {
      baseInfo: {
        userId: "",
        company: {
          companyName: "",
        },
      },
      whetherAgencyHead: false,
      action: this.$fileBaseurl + "/uploadImageByMultipartFile",
      imgUrl:
        "http://114.55.138.13:8089/20210324/13:36:00_我的世界守则_1616564160719.jpg",
      headers: {
        token: "95931b371eb64f4a9c862983ca8a5d15",
      },
      uploadParameter: {
        //上传时携带的参数
        systemName: "finance",
        title: "机构LOGO",
      },
      uploadParameter1: {
        //上传时携带的参数
        systemName: "finance",
        title: "电子合同二维码LOGO",
      },
      disable: true,
      logo: "",
      companyQrPng: "",
      accountList: [],
      tempAccount: {},
      dialogData: {
        flag: false,
        width: "30%",
        title: "添加账号",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },
      flag: false,
      allFunctionButtons: this.$featureData.getAllFunctionButtons("company"),
      clientList: [],
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "company",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          // this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },

    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getAccountList();
      });
    },
    /**
     * 获取客户名称列表
     */
    getClientList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/client/getAllOptions",
        requestData,
        (data) => {
          this.clientList = data;
        },
        (errormessage) => {
          this.$message.error(errormessage);
        }
      );
    },
    edit() {
      if (this.flag) {
        this.disable = false;
      } else {
        this.$message({
          message: this.$errorMessage.getErrorMessageInfo("authority"),
          type: "error",
        });
      }
    },
    handleAvatarSuccess(response, file, fileList) {
      this.logo = response.result.path;
    },
    handleAvatarSuccess1(response, file, fileList) {
      this.companyQrPng = response.result.path;
    },
    saveValue() {
      let url = "";
      if (this.$keyType.keyType(this.logo) != "Object") {
        url = {
          psth: this.logo,
          title: "机构logo",
        };
      } else {
        url = this.logo;
      }
      let requestData = {
        userId: this.baseInfo.userId, // 修改机构的用户
        companyId: this.baseInfo.company.companyId, // 机构ID
        logo: url, // 机构logo
      };
      this.$request(
        "post",
        "/company/updateCompanyLOGO",
        requestData,
        (data) => {
          this.$store.dispatch("setCompanyLogo", this.logo);
          this.updateCompanyQrPng();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 修改电子合同二维码LOGO
     */
    updateCompanyQrPng() {
      let url = "";
      if (this.$keyType.keyType(this.companyQrPng) != "Object") {
        url = {
          psth: this.companyQrPng,
          title: "电子合同二维码logo",
        };
      } else {
        url = this.companyQrPng;
      }
      let requestData = {
        userId: this.baseInfo.userId, // 修改机构的用户
        companyId: this.baseInfo.company.companyId, // 机构ID
        qrPng: url, // 电子合同二维码LOGO
      };
      this.$request(
        "post",
        "/company/updateCompanyQrPng",
        requestData,
        (data) => {
          this.disable = true;
          this.$message({
            message: "修改成功!",
            type: "success",
            duration: 1000,
          });
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取该公司所有收款账号
     */
    getAccountList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId, // 机构ID
      };
      this.$request(
        "post",
        "/companyAccount/getAccountList",
        requestData,
        (data) => {
          this.accountList = [];
          let dd = {};
          data.forEach((element) => {
            element.accountList.forEach((ele) => {
              dd = {
                id: ele.id,
                owner: element.owner,
                account: ele.account,
                type: ele.type,
                clientName: ele.clientName,
              };
              this.accountList.push(dd);
            });
          });
          this.getCompanyQrPng();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取机构二维码图片
     */
    getCompanyQrPng() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/company/getCompanyQrPng",
        requestData,
        (data) => {
          this.companyQrPng = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    accountClick(data, flag) {
      if (this.flag) {
        this.tempAccount = data;
        switch (flag) {
          case 0:
            // 编辑账号
            this.dialogData.title = "编辑收款账号";
            this.dialogData.buttonArray = [
              {
                text: "确定",
                active: "edit",
              },
              {
                text: "取消",
                active: "cancel",
              },
            ];
            this.dialogData.data = [
              {
                type: "input",
                title: "账号",
                value: this.tempAccount.account,
              },
              {
                type: "select",
                title: "支付方式",
                value: this.tempAccount.type,
                options: this.$paymentTermList,
              },
              {
                type: "input",
                title: "账号所属者",
                value: this.tempAccount.owner,
              },
              {
                type: "select",
                title: "公司名称",
                value: this.tempAccount.clientName,
                options: this.clientList,
              },
            ];
            this.dialogData.flag = true;

            break;
          case 1:
            // 删除账号
            this.$confirm("此操作将永久删除该账号, 是否继续?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.$request(
                  "post",
                  "/companyAccount/delAccount",
                  { id: this.tempAccount.id },
                  (data) => {
                    this.$message({
                      message: "删除成功!",
                      type: "success",
                      duration: 1000,
                    });
                    this.getAccountList();
                  },
                  (errorMessage) => {
                    this.$message.error(errorMessage);
                  }
                );
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消删除",
                  duration: 1000,
                });
              });

            break;
          case 2:
            // 新增账号
            this.dialogData.title = "新增收款账号";
            this.dialogData.buttonArray = [
              {
                text: "确定",
                active: "add",
              },
              {
                text: "取消",
                active: "cancel",
              },
            ];
            this.dialogData.data = [
              {
                type: "input",
                title: "账号",
                value: "",
              },
              {
                type: "select",
                title: "支付方式",
                value: "",
                options: this.$paymentTermList,
              },
              {
                type: "input",
                title: "账号所属者",
                value: "",
              },
              {
                type: "select",
                title: "公司名称",
                value: "",
                options: this.clientList,
              },
            ];
            this.dialogData.flag = true;

            break;
          default:
            break;
        }
      } else {
        this.$message({
          message: this.$errorMessage.getErrorMessageInfo("authority"),
          type: "error",
        });
      }
    },
    drawerOper(active) {
      let requestData = {
        companyId: this.baseInfo.company.companyId, // 当前公司Id
        id: this.tempAccount == {} ? "" : this.tempAccount.id,
        account: this.dialogData.data[0].value, // 账号
        type: this.dialogData.data[1].value, // 类型
        owner: this.dialogData.data[2].value, // 所有者
        clientId: this.dialogData.data[3].value, // 公司名称
      };
      if (requestData.account == "") {
        this.$message({
          message: "收款账号不能为空!",
          type: "error",
          duration: 1000,
        });
        return;
      }
      switch (active) {
        case "cancel":
          this.dialogData.flag = false;
          break;
        case "add":
          this.$request(
            "post",
            "/companyAccount/addAccount",
            requestData,
            (data) => {
              this.$message({
                message: "账号添加成功!",
                type: "success",
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.getAccountList();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );

          break;
        case "edit":
          this.$request(
            "post",
            "/companyAccount/updateAccount",
            requestData,
            (data) => {
              this.$message({
                message: "账号修改成功!",
                type: "success",
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.getAccountList();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );

          break;
        default:
          break;
      }
    },
    cancelEdit() {
      this.disable = true;
      this.isCharge();
    },
    colseDialog() {
      this.dialogData.flag = false;
    },
  },
  mounted() {
    this.isCharge();
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    console.log("lllll", this.baseInfo);
    this.logo = this.$store.getters.getCompanyLogo;

    this.baseInfo.company.companyAdmins.forEach((element) => {
      if (element.companyAdminId == this.baseInfo.userId) {
        this.flag = true;
      }
    });
    this.getModelFeatureAuthority();
    this.getClientList();
  },
};
</script>

<style lang="less" scoped>
#organization-setting {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .person-info-content {
    width: 98%;
    height: 95%;
    max-width: 90vw;
    max-height: 95%;
    overflow-y: auto;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    .person-info-content-title {
      width: 90%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: 40px;
      font-size: 17px;
      i {
        margin-left: 20px;
        transition: 0.3s;
        &:hover {
          color: rgb(51, 253, 10);
          cursor: pointer;
        }
      }
    }
    .person-info-content-data {
      width: 80%;
      margin-left: 80px;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      .person-info-content-line {
        display: flex;
        width: 100%;
        min-height: 40px;
        height: auto;
        margin-top: 20px;
        .person-info-content-line-span {
          width: 150px;
          height: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-end;
          margin-right: 10px;
        }
        .person-info-content-line-value {
          flex: 1;
          height: 100%;
          display: flex;
          align-items: flex-start;
          margin-left: 20px;
          .person-info-content-line-value-but {
            width: 100px;
            height: 30px;
            border: 1px solid @activeColor;
            color: @activeColor;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.3s;
          }
          .person-info-content-line-value-but:hover {
            cursor: pointer;
            background-color: @activeColor;
            color: #fff;
          }
          .el-input {
            width: 220px !important;
          }
          .person-info-content-line-value-text {
            width: 50px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
          .el-image {
            border: 1px solid @boderColor;
            box-sizing: border-box;
          }
        }
      }
    }
  }
  .textcolor {
    color: @activeColor;
    font-size: 12px;
  }
  .el-button {
    margin-left: 10px !important;
  }
}
</style>
