var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"thread-setting"}},[_c('div',{staticClass:"screen"},[_c('div',{staticClass:"screen-line"},[_c('div',{staticClass:"recyle-setting-screen-tabs"},[_vm._l((_vm.tabList),function(item,index){return [_c('div',{directives:[{name:"showFunction",rawName:"v-showFunction",value:({
              active: item.active,
              array: _vm.allFunctionButtons,
            }),expression:"{\n              active: item.active,\n              array: allFunctionButtons,\n            }"}],key:index,class:{
              'recyle-setting-screen-tab': true,
              'is-active': _vm.tabIndex == item.text,
            },on:{"click":function($event){return _vm.changeTab(item, index)}}},[_vm._v(" "+_vm._s(item.text)+" ")])]})],2)])]),_c('div',{staticClass:"table-content"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"thread-table-card"},[(_vm.tabIndex == '选项值设置')?_c('div',{directives:[{name:"showFunction",rawName:"v-showFunction",value:({
          active: 'showOption',
          array: _vm.allFunctionButtons,
        }),expression:"{\n          active: 'showOption',\n          array: allFunctionButtons,\n        }"}],staticClass:"thread-table-card-content"},[_c('div',{staticClass:"thread-table-card-content-tags-setting"},[_c('div',{staticClass:"thread-table-card-content-tags-setting-left"},_vm._l((_vm.optionValueList1),function(item,index){return _c('div',{key:index,class:{
                'thread-table-card-content-tags-setting-left-line': true,
                'is-active': index == _vm.tagsIndex,
              },on:{"click":function($event){_vm.tagsIndex = index}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('div',{staticClass:"thread-table-card-content-tags-setting-right"},[_c('div',{directives:[{name:"showFunction",rawName:"v-showFunction",value:({
                active: 'editOption',
                array: _vm.allFunctionButtons,
              }),expression:"{\n                active: 'editOption',\n                array: allFunctionButtons,\n              }"}],staticClass:"thread-table-card-content-tags-setting-right-add"},[(_vm.optionValueList1[_vm.tagsIndex].name != '客户来源')?_c('div',{staticClass:"butt",on:{"click":function($event){return _vm.addTags(1, -1)}}},[_vm._v(" 添加选项 ")]):_c('div',{staticClass:"butt",on:{"click":function($event){return _vm.addTags(2, -1)}}},[_vm._v("添加来源")])]),(_vm.optionValueList1[_vm.tagsIndex].value.length == 0)?_c('div',{staticClass:"thread-table-card-content-tags-setting-right-list"},[_vm._v(" 没有相关数据! ")]):_vm._e(),(
                _vm.optionValueList1[_vm.tagsIndex].name != '客户来源' &&
                  _vm.optionValueList1[_vm.tagsIndex].value.length > 0
              )?_c('div',{staticClass:"thread-table-card-content-tags-setting-right-list"},_vm._l((_vm.optionValueList1[_vm.tagsIndex].value),function(item,index){return _c('div',{key:index,staticClass:"thread-table-card-content-tags-setting-right-line"},[(item.flag)?_c('div',{on:{"click":function($event){_vm.tabList[0].flag == 2 || !_vm.optionFlag
                      ? ''
                      : (item.flag = !item.flag)}}},[_vm._v(" "+_vm._s(index + 1)+"、"+_vm._s(item.name)+" ")]):_c('div',[_vm._v(" "+_vm._s(index + 1)+"、 "),_c('el-input',{attrs:{"placeholder":"请输入内容","disabled":_vm.tabList[0].flag == 2 || !_vm.optionFlag},on:{"blur":function($event){return _vm.addTags(3, index)}},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})],1)])}),0):_vm._e(),(
                _vm.optionValueList1[_vm.tagsIndex].name == '客户来源' &&
                  _vm.optionValueList1[_vm.tagsIndex].value.length > 0
              )?_c('div',{staticClass:"thread-table-card-content-tags-setting-right-list"},[_vm._m(0),_vm._l((_vm.optionValueList1[_vm.tagsIndex].value),function(item,index){return _c('div',{key:index,staticClass:"thread-table-card-content-tags-setting-right-line"},[_c('div',[_vm._v(_vm._s(index + 1)+"、")]),(item.flag)?_c('div',{on:{"click":function($event){_vm.tabList[0].flag == 2 || !_vm.optionFlag
                      ? ''
                      : (item.flag = !item.flag)}}},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('div',[_c('el-input',{attrs:{"placeholder":"请输入内容","disabled":_vm.tabList[0].flag == 2},on:{"blur":function($event){return _vm.addTags(4, index)}},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})],1),(item.flag)?_c('div',[_c('el-input',{attrs:{"placeholder":"请输入内容","disabled":_vm.tabList[0].flag == 2 || !_vm.optionFlag},on:{"blur":function($event){return _vm.addTags(5, index)}},model:{value:(item.value[1]),callback:function ($$v) {_vm.$set(item.value, 1, $$v)},expression:"item.value[1]"}})],1):_c('div',[_vm._v(_vm._s(item.value[1]))]),_c('div',[_c('el-switch',{attrs:{"disabled":_vm.tabList[0].flag == 2 || !_vm.optionFlag},on:{"change":function($event){return _vm.addTags(5, index)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})],1),_c('div',[_c('el-button',{attrs:{"type":"danger","plain":"","size":"small","disabled":_vm.tabList[0].flag == 2 || !_vm.optionFlag},on:{"click":function($event){return _vm.addTags(6, index)}}},[_c('i',{staticClass:"el-icon-delete"}),_vm._v("删除")])],1)])})],2):_vm._e()])])]):_vm._e(),(_vm.tabIndex == '商机保护')?_c('div',{directives:[{name:"showFunction",rawName:"v-showFunction",value:({ active: 'showProtect', array: _vm.allFunctionButtons }),expression:"{ active: 'showProtect', array: allFunctionButtons }"}],staticClass:"thread-table-card-content"},[_c('div',{staticClass:"thread-table-card-content-con"},[_vm._m(1),_c('div',{staticClass:"thread-table-card-content-con_value"},[_c('el-input',{attrs:{"placeholder":"请输入保护天数"},model:{value:(_vm.preserve),callback:function ($$v) {_vm.preserve=$$v},expression:"preserve"}}),_c('span',[_vm._v("天")])],1),_c('div',{directives:[{name:"showFunction",rawName:"v-showFunction",value:({
              active: 'editProtect',
              array: _vm.allFunctionButtons,
            }),expression:"{\n              active: 'editProtect',\n              array: allFunctionButtons,\n            }"}],staticClass:"thread-table-card-content-con_buttons"},[_c('div',{staticClass:"thread-table-card-content-con_button",on:{"click":_vm.updateProtectDay}},[_vm._v(" 确定 ")]),_c('div',{staticClass:"thread-table-card-content-con_button",on:{"click":function($event){return _vm.getProtectDay(_vm.flase)}}},[_vm._v(" 取消 ")])])]),_c('div',{staticClass:"thread-table-card-content-con"},[_vm._m(2),_c('div',{staticClass:"thread-table-card-content-con_value"},[_c('el-input',{attrs:{"placeholder":"请输入保护阈值"},model:{value:(_vm.threshold),callback:function ($$v) {_vm.threshold=$$v},expression:"threshold"}}),_c('span',[_vm._v("条")])],1),_c('div',{directives:[{name:"showFunction",rawName:"v-showFunction",value:({
              active: 'editProtect',
              array: _vm.allFunctionButtons,
            }),expression:"{\n              active: 'editProtect',\n              array: allFunctionButtons,\n            }"}],staticClass:"thread-table-card-content-con_buttons"},[_c('div',{staticClass:"thread-table-card-content-con_button",on:{"click":_vm.updateMaxThread}},[_vm._v(" 确定 ")]),_c('div',{staticClass:"thread-table-card-content-con_button",on:{"click":_vm.getMaxThread}},[_vm._v(" 取消 ")])])])]):_vm._e(),(_vm.tabIndex == '异常电话')?_c('div',{directives:[{name:"showFunction",rawName:"v-showFunction",value:({ active: 'showPhone', array: _vm.allFunctionButtons }),expression:"{ active: 'showPhone', array: allFunctionButtons }"}],staticClass:"thread-table-card-content"},[_c('div',{staticClass:"thread-table-card-content-search"},[_c('div',{staticClass:"line"},[_c('el-input',{attrs:{"placeholder":"异常电话"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getPeerPhoneList($event)}},model:{value:(_vm.searchData.input),callback:function ($$v) {_vm.$set(_vm.searchData, "input", $$v)},expression:"searchData.input"}},[_c('el-button',{attrs:{"slot":"append","icon":"el-icon-search"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.getPeerPhoneList($event)}},slot:"append"})],1)],1),_c('div',{staticClass:"line"}),_c('div',{staticClass:"line"}),_c('div',{staticClass:"line"},[_c('div',{directives:[{name:"showFunction",rawName:"v-showFunction",value:({
                active: 'importPhone',
                array: _vm.allFunctionButtons,
              }),expression:"{\n                active: 'importPhone',\n                array: allFunctionButtons,\n              }"}],staticClass:"but",on:{"click":function($event){return _vm.handleClick({}, 'import')}}},[_vm._v(" 导入 ")]),_c('div',{directives:[{name:"showFunction",rawName:"v-showFunction",value:({
                active: 'exportPhone',
                array: _vm.allFunctionButtons,
              }),expression:"{\n                active: 'exportPhone',\n                array: allFunctionButtons,\n              }"}],staticClass:"but",on:{"click":function($event){return _vm.handleClick({}, 'export')}}},[_vm._v(" 导出 ")]),_c('div',{directives:[{name:"showFunction",rawName:"v-showFunction",value:({
                active: 'addPhone',
                array: _vm.allFunctionButtons,
              }),expression:"{\n                active: 'addPhone',\n                array: allFunctionButtons,\n              }"}],staticClass:"but",on:{"click":function($event){return _vm.handleClick({}, 'add')}}},[_vm._v(" 添加 ")])])]),_c('el-table',{staticStyle:{"width":"98%"},attrs:{"data":_vm.tableData,"border":"","height":"650","highlight-current-row":""},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"35","fixed":""}}),_c('el-table-column',{attrs:{"prop":"phone","label":"电话","width":"250"}}),_c('el-table-column',{attrs:{"prop":"userName","label":"操作人","width":"180"}}),_c('el-table-column',{attrs:{"prop":"time","label":"操作时间","width":"180"}}),_c('el-table-column',{attrs:{"prop":"remark","label":"备注"}}),_c('el-table-column',{attrs:{"fixed":"right","label":"操作","width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{directives:[{name:"showFunction",rawName:"v-showFunction",value:({
                  active: 'editPhone',
                  array: _vm.allFunctionButtons,
                }),expression:"{\n                  active: 'editPhone',\n                  array: allFunctionButtons,\n                }"}],attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.handleClick(scope.row, 'edit')}}},[_vm._v("编辑")]),_c('el-button',{directives:[{name:"showFunction",rawName:"v-showFunction",value:({
                  active: 'deletePhone',
                  array: _vm.allFunctionButtons,
                }),expression:"{\n                  active: 'deletePhone',\n                  array: allFunctionButtons,\n                }"}],staticStyle:{"color":"red"},attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.handleClick(scope.row, 'delete')}}},[_vm._v("删除")])]}}],null,false,2045960345)})],1),_c('div',{staticClass:"thread-table-card-content-page"},[_c('el-pagination',{attrs:{"current-page":_vm.pageData.currentPage,"page-size":_vm.pageData.pageSize,"layout":"total,sizes, prev, pager, next,jumper","total":_vm.pageData.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){return _vm.$set(_vm.pageData, "currentPage", $event)},"update:current-page":function($event){return _vm.$set(_vm.pageData, "currentPage", $event)}}})],1)],1):_vm._e()])]),(_vm.dialogData.flag)?_c('div',{attrs:{"id":"my-dialog"}},[_c('my_dialog',{attrs:{"width":_vm.dialogData.width,"title":_vm.dialogData.title,"buttonArray":_vm.dialogData.buttonArray,"data":_vm.dialogData.data,"allFunctionButtons":_vm.allFunctionButtons},on:{"colseDialog":_vm.colseDialog,"dialogOper":_vm.drawerOper}})],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"thread-table-card-content-tags-setting-right-line"},[_c('div'),_c('div',[_vm._v("来源")]),_c('div',[_vm._v("二级来源")]),_c('div',[_vm._v("启动")]),_c('div',[_vm._v("操作")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"thread-table-card-content-con_title"},[_vm._v(" 循环机制 "),_c('div',{staticClass:"thread-table-card-content-con_title_tip"},[_vm._v(" 即商机保护时间,每次添加商机的跟进记录时,以最新的跟进时间为准(若无跟进记录,则以商机添加时间为准),每条商机最多保护时间,超过该时间,则该条商机会释放至商机池。 ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"thread-table-card-content-con_title"},[_vm._v(" 商机阈值 "),_c('div',{staticClass:"thread-table-card-content-con_title_tip"},[_vm._v(" 每个人同时跟进商机的最大值。 ")])])}]

export { render, staticRenderFns }