<template>
  <div id="authority-tips">
    <div class="line">
      <div class="title">人员选择</div>
      <div class="div-select">
        <el-select
          v-model="data.value"
          placeholder="请选择"
          value-key="userId"
          filterable
          @change="changeHandel"
        >
          <el-option
            v-for="item in data.userList"
            :key="item.userId"
            :label="item.userName"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="line">
      <el-tag
        v-for="tag in data.userAuthprityList"
        :key="tag.userId"
        closable
        @close="handleClose(tag)"
      >
        {{ tag.userName }}
      </el-tag>
    </div>
  </div>
</template>

<script>
export default {
  props: ["authorityTipsData"],
  data() {
    return {
      data: this.authorityTipsData,
    };
  },
  watch: {
    authorityTipsData() {
      //父组件值变化->>子组件也变化
      this.data = this.authorityTipsData;
    },
    data(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {
    changeHandel(data) {
      if (
        !this.data.userAuthprityList.some((item) => {
          if (item.userId == data.userId) {
            return true;
          }
        })
      ) {
        let dd = {
          userId: data.userId,
          userName: data.userName,
        };
        this.data.userAuthprityList.push(dd);
      }
    },
    handleClose(data) {
      this.data.userAuthprityList.splice(
        this.data.userAuthprityList.indexOf(data),
        1
      );
    },
  },
};
</script>

<style lang="less" scoped>
#authority-tips {
  width: 100%;
  height: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .line {
    width: 90%;
    height: auto;
    min-height: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .title {
      width: 20%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-right: 5px;
    }
    .div-select {
      flex: 1;
      height: 100%;
    }
    .el-tag {
      margin-left: 10px !important;
    }
  }
}
</style>