import * as systemBaseData from "./baseData.js"
/**
 * 获取添加/编辑服务项时的dialog数据
 * @param {*} value 
 * @param {*} list 
 */

export function getServeItemDialogData(value, list) {
    let options = []
    list.forEach(element => {
        let dd = {
            label: element.typeName,
            value: element.id
        }
        options.push(dd)
    });
    let data = [{
            type: "input",
            title: "服务项名称",
            value: "",
        }, {
            type: "select",
            title: "服务项类别",
            value: "",
            options: options
        }, {
            type: "input",
            title: "图标简写",
            value: "",
        }, {
            type: "input-number",
            title: "服务项单价",
            value: "",
        }, {
            type: "select",
            title: "计价单位",
            value: "",
            options: [{
                label: "次",
                value: "次"
            }, {
                label: "年",
                value: "年"
            }, {
                label: "月",
                value: "月"
            },
            {
                label: "个",
                value: "个"
            }]
        },
        // {
        // type: "tips_select",
        // title: "任务派工类型",
        // value: [],
        // options: systemBaseData.getWorkOrderType()
        // },
    ]

    if (value != "") {
        data[0].value = value.name
        data[1].value = value.type.id
        data[2].value = value.icon.text
        data[3].value = value.price
        data[4].value = value.unit
            // data[5].value = value.workType
    }

    return data
}


/**
 * 获取添加/编辑产品时的dialog数据
 * @param {*} value 
 */
export function getProductDialogData(value) {
    let data = [{
        type: "product-model",
        title: "",
        value: {
            id: "", // 服务产品ID
            name: "", // 服务产品名称
            des: "", // 服务产品说明
            price: "",
            contractType: "周期服务", // 合同类型 (周期服务/单次服务)
            automaticRenewal: "0", // 是否自动续签
            serviceType: "", // 服务类型
            servicesItem: [], // 服务项列表
        },
    }]

    if (value != "") {
        data[0].value.id = value.id
        data[0].value.name = value.name
        data[0].value.price = value.price
        data[0].value.des = value.productExplain
        data[0].value.contractType = value.type
        data[0].value.automaticRenewal = value.autoRenew
            // data[0].value.serviceType = value.serviceType
        if (value.servicesItem.length > 0) {
            data[0].value.serviceType = value.servicesItem[0].type
            value.servicesItem.forEach(element => {
                let dd = {
                    data: JSON.parse(JSON.stringify(element)),
                    price: element.price,
                    unit: element.unit,
                    discounts: element.discounts.mitigate == "" ? "0" : element.discounts.mitigate,
                    dunning: {
                        flag: element.dunning.flag == "" ? "1" : element.dunning.flag,
                        day: element.dunning.day == "" ? "0" : element.dunning.day
                    }
                }
                data[0].value.servicesItem.push(dd)
            });
        }
    }
    return data
}