<template>
  <!-- 客户档案 -->
  <div id="archives-info">
    <div class="archives-info-screen">
      <div class="archives-info-screen-line">
        <div class="archives-info-screen-line-fiftrate">
          <el-popover
            placement="bottom-start"
            width="250"
            trigger="manual"
            :visible-arrow="false"
            v-model="popoData.popoVisible"
          >
            <div class="popover-search">
              <popo_search
                :list="popoData.list"
                @cancel="popoCanael"
                @reset="popoReset"
                @makeSure="popoMakeSure"
              ></popo_search>
            </div>
            <div slot="reference" @click="popoverOpen">
              筛选 <i class="el-icon-s-operation"></i>
            </div>
          </el-popover>
        </div>
        <div class="archives-info-screen-search">
          <el-input
            v-model="searchData.input"
            placeholder="请输入客户全称/编号/税号"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="archives-info-screen-search">
          <el-cascader
            v-model="searchData.categoryId"
            placeholder="请选择物品"
            :options="categoryList"
            collapse-tags
            :props="{
              multiple: true,
              value: 'id',
              label: 'name',
              emitPath: false,
            }"
            @change="initTableData"
            clearable
            filterable
          ></el-cascader>
        </div>

        <div class="archives-info-screen-search">
          <el-cascader
            v-model="searchData.locationId"
            placeholder="请选择位置"
            :options="locationList"
            collapse-tags
            :props="{
              multiple: true,
              value: 'id',
              label: 'name',
              emitPath: false,
            }"
            @change="initTableData"
            clearable
            filterable
          ></el-cascader>
        </div>
        <div class="archives-info-screen-search">
          <el-select
            v-model="searchData.createId"
            @change="selectInitTableData"
            filterable
          >
            <el-option
              v-for="item in searchData.allUserList"
              :key="item.userId"
              :label="item.userName"
              :value="item.userId"
            ></el-option>
          </el-select>
        </div>
        <div class="archives-info-screen-active"></div>

        <div>
          <el-dropdown>
            <el-button type="small" class="drop-button">
              批量操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                @click.native="handleClick('', 1, true)"
                v-showFunction="{
                  active: 'getBatch',
                  array: allFunctionButtons,
                }"
                >批量领用</el-dropdown-item
              >
              <el-dropdown-item
                @click.native="handleClick('', 2, true)"
                v-showFunction="{
                  active: 'saveBatch',
                  array: allFunctionButtons,
                }"
                >批量归还</el-dropdown-item
              >
              <el-dropdown-item
                @click.native="handleClick('', 3, true)"
                v-showFunction="{
                  active: 'returnBatch',
                  array: allFunctionButtons,
                }"
                >批量交付</el-dropdown-item
              >
              <el-dropdown-item
                @click.native="handleClick('', 4, true)"
                v-showFunction="{
                  active: 'moveBatch',
                  array: allFunctionButtons,
                }"
                >批量转移</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div
          class="archives-screen-but-select"
          @click="handleClick('', 0, false)"
          v-showFunction="{
            active: 'add',
            array: allFunctionButtons,
          }"
        >
          新增档案
        </div>
      </div>
      <div
        class="archives-info-screen-line"
        v-if="searchData.screenList.length > 0"
      >
        <div class="title">筛选条件:</div>
        <el-tag
          v-for="(tag, index) in searchData.screenList"
          :key="index"
          closable
          size="small"
          @close="closeScreen(index)"
        >
          {{ tag.name }}
        </el-tag>
      </div>
    </div>

    <div class="table-content">
      <div class="archives-info-table-card">
        <div class="archives-info-content">
          <el-table
            :data="tableData"
            highlight-current-row
            style="width: 100%, overflow-x:auto"
            height="98%"
            v-loading="loading"
            row-key="id"
            :row-style="{ height: '60px' }"
            :tree-props="{
              children: 'archivesList',
            }"
            :cell-class-name="cellStyle"
            @selection-change="handleSelectionChange"
            ref="tableRef"
          >
            <el-table-column type="selection" width="30"></el-table-column>
            <el-table-column type="index" width="40" fixed> </el-table-column>
            <el-table-column
              prop="clientName"
              label="客户全称"
              show-overflow-tooltip
              width="220px"
            >
            </el-table-column>
            <el-table-column prop="clientNum" label="客户编号">
            </el-table-column>
            <el-table-column
              prop="name"
              label="录入物品"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="location"
              label="录入位置"
              width="100"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="createName"
              label="录入人"
              width="100"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column label="状态" show-overflow-tooltip>
              <template slot-scope="scope">
                <span :style="{ color: scope.row.color }">
                  {{ scope.row.status }}</span
                ></template
              ></el-table-column
            >
            <el-table-column prop="gmtModified" label="操作时间" width="150">
            </el-table-column>
            <el-table-column label="操作" width="350" fixed="right">
              <template slot-scope="scope" v-if="!scope.row.archivesList">
                <el-button
                  v-if="scope.row.status == '未领用'"
                  type="primary"
                  plain
                  size="mini"
                  icon="el-icon-edit-outline"
                  @click.native="handleClick(scope.row, 1, false)"
                  v-showFunction="{
                    active: 'get',
                    array: allFunctionButtons,
                  }"
                  >领用</el-button
                >
                <el-button
                  v-if="scope.row.recipient == baseInfo.userId"
                  type="warning"
                  plain
                  size="mini"
                  icon="el-icon-edit"
                  @click="handleClick(scope.row, 2, false)"
                  v-showFunction="{
                    active: 'save',
                    array: allFunctionButtons,
                  }"
                  >归还</el-button
                >
                <el-button
                  v-if="scope.row.status == '未领用'"
                  type="info"
                  plain
                  size="mini"
                  icon="el-icon-share"
                  @click="handleClick(scope.row, 4, false)"
                  v-showFunction="{
                    active: 'move',
                    array: allFunctionButtons,
                  }"
                  >转移</el-button
                >
                <el-button
                  v-if="
                    scope.row.status == '未领用' ||
                      scope.row.recipient == baseInfo.userId
                  "
                  type="success"
                  plain
                  size="mini"
                  icon="el-icon-check"
                  @click="handleClick(scope.row, 3, false)"
                  v-showFunction="{
                    active: 'return',
                    array: allFunctionButtons,
                  }"
                  >交付</el-button
                >
                <el-button
                  type="primary"
                  plain
                  size="mini"
                  @click="handleClick(scope.row, 5, false)"
                  v-showFunction="{
                    active: 'record',
                    array: allFunctionButtons,
                  }"
                  >操作记录</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="archives-info-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import * as archivesData from "./js/archives";
import * as expressageData from "@/views/expressage/js/expressage.js";
import my_drawer from "@/components/my-drawer.vue";
import my_dialog from "@/components/dialog.vue";
import popo_search from "@/components/popo-search.vue";
export default {
  components: {
    my_drawer,
    my_dialog,
    popo_search,
  },
  data() {
    return {
      baseInfo: {},
      whetherAgencyHead: false,
      loading: false,
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "archivesInfo"
      ),
      tableData: [],
      searchData: {
        input: "", // 客户名称,编号
        createId: "", // 录入人
        categoryId: [], // 档案类别
        locationId: [], // 档案位置
        allUserList: [], // 所有员工列表
        screenList: [],
      },
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "新增档案",
        buttonArray: [
          {
            text: "确认",
            active: "confirm",
          },
        ],
        data: [],
      },
      tempArchives: {},
      categoryList: [],
      locationList: [],
      clientList: [],
      addArchives: {
        flag: true,
      },
      addArchives: {
        flag: false,
      },
      multipleSelection: [],
      popoData: {
        popoVisible: false,
        list: archivesData.archiveInfoSearchData(),
      },
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "archivesInfo",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.isCharge();

          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getClientList();
        this.getEmployeeListForDownBox();
      });
    },
    /**
     * 获取客户名称列表
     */
    getClientList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/client/getAllOptions",
        requestData,
        (data) => {
          this.clientList = data;
        },
        (errormessage) => {
          this.$message.error(errormessage);
        }
      );
    },
    /**
     * 获取所有人员列表
     */
    getEmployeeListForDownBox() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/user/getEmployeeListForDownBox",
        requestData,
        (data) => {
          this.searchData.allUserList = data;
          this.searchData.allUserList.splice(0, 0, {
            userId: "",
            userName: "全部人员",
          });
          this.selectInitTableData(this.baseInfo.userId);
        }
      );
    },
    /**
     * 获取表格界面数据
     */
    initTableData() {
      this.loading = true;
      let requestData = {
        companyId: this.baseInfo.company.companyId, // 当前公司ID
        currentPage: this.pageData.currentPage, // 当前页数 20210907 改
        pageSize: this.pageData.pageSize, // 每页条数 20210907 改
        nameOrNum: this.searchData.input, // 客户名称或客户编号
        categoryIds: this.searchData.categoryId, // 档案类别 id
        locationIds: this.searchData.locationId, // 档案位置 id
        createId: this.searchData.createId, // 录入人
        screenList: this.searchData.screenList, // 筛选条件
      };
      this.$request(
        "post",
        "/archivesInfo/pageByCondition",
        requestData,
        (data) => {
          this.pageData.total = data.total;
          this.tableData = archivesData.getArchivesInfoTableData(data.records);
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    /**
     * 界面操作
     * batch 是否为批量操作
     */
    handleClick(data, flag, batch) {
      this.tempArchives = data;
      let archivesStatus = "";
      if (!this.multipleSelection.length && flag !== 0 && !data) {
        this.$message.error("请选择要操作的档案!");
        return;
      }
      if (!batch && this.multipleSelection.length >= 2 && flag !== 0) {
        this.$message.error("同时选择多个档案请点击批量操作!");
        return;
      }
      // 判断是领取 还是 批量领取
      if (data) {
        this.multipleSelection = [data];
      }
      switch (flag) {
        case 0:
          // 新增档案
          this.dialogData.width = "700px";
          this.dialogData.title = "新增档案";
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "submit",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          (this.dialogData.data = [
            {
              title: "",
              type: "add_archives",
              data: archivesData.addArchivesDialogData(
                this.clientList,
                this.categoryList,
                this.locationList,
                true
              ),
            },
          ]),
            console.log("新增档案dialog显示:", this.dialogData.data);
          this.dialogData.flag = true;

          break;
        case 1:
          // 领用 批量领用
          archivesStatus = this.multipleSelection.every(
            (item) => item.status == "未领用"
          );
          if (!archivesStatus) {
            this.$message.error("只能领用状态为未领用的档案!");
            return;
          }

          this.dialogData.width = "25%";
          this.dialogData.title = "领用档案";
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "sure_get",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];

          (this.dialogData.data = [
            {
              title: "",
              type: "get_archives",
              data: archivesData.getArchivesDialogData(this.multipleSelection),
            },
          ]),
            console.log("领用档案dialog显示:", this.dialogData.data);
          this.dialogData.flag = true;
          break;
        case 2:
          // 归还 批量归还
          archivesStatus = this.multipleSelection.every(
            (item) => item.status == "已领用"
          );
          if (!archivesStatus) {
            this.$message.error("只能归还状态为已领用的档案!");
            return;
          }
          let recipient = this.multipleSelection.every(
            (item) => item.recipient == this.baseInfo.userId
          );
          if (!recipient) {
            this.$message.error("只能归还本人领用的档案!");
            return;
          }
          this.dialogData.width = "25%";
          this.dialogData.title = "归还档案";
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "sure_deposit",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          (this.dialogData.data = [
            {
              title: "归还位置",
              type: "cascader",
              locationId: "",
              placeholder: "请选择归还位置",
              flag: false,
              options: this.locationList,
              props: { multiple: false, value: "id", label: "name" }, // 是否可多选
              collapse_tags: false, // 多选时是否折叠面板
            },
          ]),
            console.log("归还档案dialog显示:", this.dialogData.data);
          this.dialogData.flag = true;
          break;
        case 3:
          // 交付 批量交付
          archivesStatus = this.multipleSelection.every(
            (item) => item.status == "未领用" || item.status == "已领用"
          );
          if (!archivesStatus) {
            this.$message.error("只能交付状态为未领用或已领用的档案!");
            return;
          }
          this.dialogData.width = "25%";
          this.dialogData.title = "交付档案";
          this.dialogData.buttonArray = [
            {
              text: "自取",
              active: "sure_ziqu",
            },
            {
              text: "快递",
              active: "sure_youji",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          (this.dialogData.data = [
            {
              title: "",
              type: "get_archives",
              data: archivesData.getArchivesDialogData(this.multipleSelection),
            },
          ]),
            console.log("交付档案dialog显示:", this.dialogData.data);
          this.dialogData.flag = true;
          break;
        case 4:
          // 批量转移
          archivesStatus = this.multipleSelection.every(
            (item) => item.status == "未领用"
          );
          if (!archivesStatus) {
            this.$message.error("只能转移状态为未领用的档案!");
            return;
          }
          this.dialogData.width = "25%";
          this.dialogData.title = "转移档案";
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "sure_move",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          (this.dialogData.data = [
            {
              title: "转移位置",
              type: "cascader",
              locationId: "",
              placeholder: "请选择转移位置",
              flag: false,
              options: this.locationList,
              props: { multiple: false, value: "id", label: "name" }, // 是否可多选
              collapse_tags: false, // 多选时是否折叠面板
            },
          ]),
            console.log("转移档案dialog显示:", this.dialogData.data);
          this.dialogData.flag = true;
          break;
        case 5:
          // 操作记录
          this.$operationRecords.showOperationRecords(
            this.tempArchives.id,
            "archives",
            this
          );
        default:
          break;
      }
    },
    /**
     * 抽屉数据或dialog数据操作事件
     */
    drawerOper(active) {
      let requestData = {};
      let ids = [];
      this.multipleSelection.forEach((item) => {
        ids.push(item.id);
      }); // 档案 ids
      switch (active) {
        case "submit":
          // 确认新增
          let categoryIdList = [];
          this.dialogData.data[0].data.categoryList.forEach((item) => {
            categoryIdList.push(...item.categoryIdList);
          });
          requestData = {
            clientId: this.dialogData.data[0].data.clientId,
            categoryIdList: categoryIdList,
            locationId: this.dialogData.data[0].data.locationId,
            createId: this.baseInfo.userId,
          };

          if (requestData.clientId == "") {
            this.$message.error("请选择客户名称");
            return;
          }
          if (requestData.locationId == "") {
            this.$message.error("请选择录入位置");
            return;
          }
          if (requestData.categoryIdList.length == 0) {
            this.$message.error("请选择录入物品");
            return;
          }

          this.$request(
            "post",
            "/archivesInfo/add",
            requestData,
            (data) => {
              this.$message.success("录入成功");
              this.dialogData.flag = false;
              this.initTableData();
            },
            (errormessage) => {
              this.$message.error(errormessage);
            }
          );
          break;
        case "sure_get":
          // 确认领用
          requestData = {
            ids, // 档案ids
            status: 1, // 档案状态：0-未领用 1-已领用 2-已交付
            locationId: "", // 档案位置 id
            recipientId: this.baseInfo.userId, // 领取人 id
            operatorId: this.baseInfo.userId, // 操作人 id
          };
          this.$request(
            "post",
            "/archivesInfo/edit",
            requestData,
            (data) => {
              this.$message.success("领用成功");
              this.dialogData.flag = false;
              this.initTableData();
            },
            (errormessage) => {
              this.$message.error(errormessage);
            }
          );
          break;
        case "sure_deposit":
          // 确认归还
          requestData = {
            ids, // 档案idsE
            status: 0, // 档案状态：0-未领用 1-已领用 2-已交付
            locationId: this.dialogData.data[0].locationId[
              this.dialogData.data[0].locationId.length - 1
            ], // 档案位置 id
            recipientId: "", // 领取人 id
            operatorId: this.baseInfo.userId, // 操作人 id
          };
          if (!requestData.locationId) {
            this.$message.error("请选择归还位置!");
            return;
          }
          this.$request(
            "post",
            "/archivesInfo/edit",
            requestData,
            (data) => {
              this.$message.success("归还成功");
              this.dialogData.flag = false;
              this.initTableData();
            },
            (errormessage) => {
              this.$message.error(errormessage);
            }
          );
          break;
        case "sure_move":
          // 确认转移
          requestData = {
            ids, // 档案ids
            status: 0, // 档案状态：0-未领用 1-已领用 2-已交付
            locationId: this.dialogData.data[0].locationId[
              this.dialogData.data[0].locationId.length - 1
            ], // 档案位置 id
            operatorId: this.baseInfo.userId, // 操作人 id
          };
          if (!requestData.locationId) {
            this.$message.error("请选择转移位置!");
            return;
          }
          this.$request(
            "post",
            "/archivesInfo/edit",
            requestData,
            (data) => {
              this.$message.success("转移成功");
              this.dialogData.flag = false;
              this.initTableData();
            },
            (errormessage) => {
              this.$message.error(errormessage);
            }
          );
          break;
        case "sure_ziqu":
          // 自取
          requestData = {
            ids, // 档案ids
            status: 2, // 档案状态：0-未领用 1-已领用 2-已交付
            locationId: "", // 档案位置 id
            operatorId: this.baseInfo.userId, // 操作人 id
          };

          this.$request(
            "post",
            "/archivesInfo/edit",
            requestData,
            (data) => {
              this.$message.success("交付成功");
              this.dialogData.flag = false;
              this.initTableData();
            },
            (errormessage) => {
              this.$message.error(errormessage);
            }
          );
          break;
        case "sure_youji":
          // 快递
          this.dialogData.flag = false;
          this.dialogData.width = "1000px";
          this.dialogData.title = "寄件";
          this.dialogData.buttonArray = [];
          this.$request(
            "get",
            `/expressAddress/getSendAddress?userId=${this.baseInfo.userId}`,
            {},
            (data) => {
              this.$request(
                "post",
                "/archivesInfo/getDeliverable",
                {
                  clientId: this.tempArchives.clientId,
                  userId: this.baseInfo.userId,
                },
                (archivesData) => {
                  (this.dialogData.data = [
                    {
                      title: "",
                      type: "mail",
                      data: expressageData.mailDialog(
                        this.clientList,
                        data,
                        archivesData,
                        this.tempArchives
                      ),
                    },
                  ]),
                    console.log("寄件dialog显示:", this.dialogData.data);
                  this.dialogData.flag = true;
                },
                (errormessage) => {
                  this.$message.error(errormessage);
                }
              );
            },
            (errormessage) => {
              this.$message.error(errormessage);
            }
          );
          break;
        case "cancel":
          // 取消
          this.dialogData.flag = false;
          break;
        default:
          break;
      }
    },
    /**
     * 获取档案物品列表
     */
    getTreeListByCategory() {
      this.$request(
        "post",
        "/archivesCategory/getTreeList",
        { flag: false },
        (data) => {
          this.categoryList = data;
        },
        (errormessage) => {
          this.$message.error(errormessage);
        }
      );
    },
    /**
     * 获取档案柜位置列表
     */
    getTreeListByLocation() {
      this.$request(
        "post",
        "/archivesLocation/getTreeList",
        { flag: false },
        (data) => {
          this.locationList = data;
        },
        (errormessage) => {
          this.$message.error(errormessage);
        }
      );
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
      this.isEdit = false;
      this.isCopy = false;
    },
    /**
     * 表格多选事件
     */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * 筛选弹出框关闭事件
     */
    popoCanael() {
      this.popoData.popoVisible = false;
    },
    /**
     * 筛选弹出框重置
     */
    popoReset() {
      this.$systemBaseData.popoReset(this.popoData.list);
      this.searchData.screenList = [];
    },
    /**
     * 筛选弹出框显示
     */
    popoverOpen() {
      this.popoData.list[0].options = this.searchData.allUserList.map(
        (item, index) => {
          return { label: item.userName, value: item.userId };
        }
      ); // 领用人
      this.popoData.popoVisible = !this.popoData.popoVisible;
    },
    /**
     * 筛选弹出框确认事件
     */
    popoMakeSure() {
      this.searchData.screenList = this.$systemBaseData.remakesureSearchData(
        this.popoData.list
      );
      this.popoData.popoVisible = false;
      this.initTableData();
    },
    /**
     * 关闭筛选标签事件
     */
    closeScreen(index) {
      this.searchData.screenList.splice(index, 1);
      this.initTableData();
    },

    cellStyle(row) {
      if (row.row.archivesList) {
        return "myCellStyle";
      }
    },
    /**
     * 员工选择
     */
    selectInitTableData(val) {
      if (val == "") {
        this.searchData.createId = "";
      } else {
        this.searchData.createId = val;
      }
      this.initTableData();
    },
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
    this.getTreeListByLocation();
    this.getTreeListByCategory();
    // 下单
    this.$bus.$off("order").$on("order", () => {
      console.log("事件总线 order");
      let nowData = this.dialogData.data[0].data;
      if (!nowData.sender.name) {
        this.$message.error("请输入寄件人姓名!");
        return;
      }
      if (!nowData.sender.phone) {
        this.$message.error("请输入寄件人电话!");
        return;
      }
      if (nowData.sender.code.length == 0) {
        this.$message.error("请输入寄件人省市区!");
        return;
      }
      if (!nowData.sender.address) {
        this.$message.error("请输入寄件人详细地址!");
        return;
      }
      if (!nowData.receiver.name) {
        this.$message.error("请输入收件人姓名!");
        return;
      }
      if (!nowData.receiver.phone) {
        this.$message.error("请输入收件人电话!");
        return;
      }
      if (nowData.receiver.code.length == 0) {
        this.$message.error("请输入收件人省市区!");
        return;
      }
      if (!nowData.receiver.address) {
        this.$message.error("请输入收件人详细地址!");
        return;
      }
      if (!nowData.payMethod) {
        this.$message.error("请输入支付方式!");
        return;
      }
      if (!nowData.preWeight) {
        this.$message.error("请输入货物重量!");
        return;
      }
      if (!nowData.sendStartTime) {
        this.$message.error("请输入预计送达时间!");
        return;
      }

      // if (new Date(nowData.startTime).getTime() < new Date()) {
      //   this.$message.error("预计送达时间要大于当前时间!");
      //   return;
      // }
      let flag1 = true;
      if (!this.$phoneCheck.mobilePhoneNumberCheck(nowData.sender.phone)) {
        // 做手机号格式校验
        flag1 = false;
      }
      if (!flag1) {
        this.$message({
          message: "寄件人手机号格式不正确,请确认填入正确的手机号!",
          type: "error",
          duration: 1000,
        });
        return;
      }
      let flag2 = true;
      if (!this.$phoneCheck.mobilePhoneNumberCheck(nowData.receiver.phone)) {
        // 做手机号格式校验
        flag2 = false;
      }
      if (!flag2) {
        this.$message({
          message: "收件人手机号格式不正确,请确认填入正确的手机号!",
          type: "error",
          duration: 1000,
        });
        return;
      }
      if (!nowData.serviceAgreement) {
        this.$message.error("请先勾选我已同意");
        return;
      }
      if (nowData.goods == "") {
        nowData.goods = "文件";
      }
      let senderCode = expressageData.transformCode(nowData.sender.code);
      let receiverCode = expressageData.transformCode(nowData.receiver.code);
      let activeArchives = JSON.stringify(nowData.activeArchives);
      let requestData = {
        companyId: this.baseInfo.company.companyId, // 公司 id
        createId: this.baseInfo.userId, // 下单人 id
        supplierName: nowData.supplierName, // 快递服务商
        goods: nowData.goods, // 物品
        expressType: nowData.expressType, // 时效产品 1-顺丰特快 2-顺丰标快
        preWeight: nowData.preWeight, // 下单重量
        isOneseIfPickup: nowData.isOneseIfPickup, // 寄件方式 1-客户同意快件自取 0-客户不同意快件自取
        sendStartTime: nowData.sendStartTime, // 期望上门时间
        payMethod: nowData.payMethod, // 支付方式
        preFreight: nowData.preFreight, // 预估运费
        deliverTime: nowData.deliverTime, // 预计送达时间
        discounts: nowData.discounts, // 预计送达时间
        remark: nowData.remark, // 留言
        memo: nowData.memo, // 备注
        sender: nowData.sender, // 寄件人信息
        receiver: nowData.receiver, // 收件人信息
        saveOrUpdateReceiver: nowData.saveOrUpdateReceiver, // 收件人信息
        archivesInfoIds: activeArchives, // 跟档案模块联动的字段，记录交付的档案 ids
      };
      requestData.sender.userId = this.baseInfo.userId; // 寄件人省份编码
      requestData.sender.createId = this.baseInfo.userId; // 寄件人省份编码
      requestData.sender.provinceCode = senderCode.provinceCode; // 寄件人省份编码
      requestData.sender.cityCode = senderCode.cityCode; // 寄件人城市编码
      requestData.sender.countyCode = senderCode.countyCode; // 寄件人区县编码
      requestData.sender.area = senderCode.area; // 寄件人省市区
      requestData.receiver.createId = this.baseInfo.userId; // 收件人省份编码
      requestData.receiver.provinceCode = receiverCode.provinceCode; // 收件人省份编码
      requestData.receiver.cityCode = receiverCode.cityCode; // 收件人城市编码
      requestData.receiver.countyCode = receiverCode.countyCode; // 收件人区县编码
      requestData.receiver.area = receiverCode.area; // 收件人省市区
      this.$request(
        "post",
        "/expressOrder/send",
        requestData,
        (data) => {
          this.$message.success("下单成功!");
          this.initTableData();
          this.dialogData.flag = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    });
  },
};
</script>

<style lang="less" scoped>
#archives-info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .myCellStyle /deep/.el-checkbox__input {
    display: none;
  }
  .archives-info-screen {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    .archives-info-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      > .title {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 0 10px;
      }
      > .el-tag {
        margin: 0 5px;
      }
      .archives-info-screen-line-fiftrate {
        width: 65px;
        min-width: 65px;
        height: 30px;
        border: 1px solid @boderColor;
        box-sizing: border-box;
        color: @boderActiveColor;
        font-size: @contentFontSize;
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: 0.3s;
        i {
          margin: 0 4px;
        }
      }
      .archives-info-screen-line-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }
      .archives-info-screen-search {
        width: 210px;
        min-width: 210px;
        height: 30px;
        margin-left: 10px;
      }
      .archives-info-screen-active {
        flex: 1;
      }
      .drop-button {
        background-color: #ecf5ff;
        color: #409eff;
        transition: 0.5s;
        &:hover {
          background-color: #abbed3;
        }
      }
      .archives-screen-but-select {
        padding: 0px 52px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
        cursor: pointer;
      }
    }
  }

  .table-content {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;

    .archives-info-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .archives-info-content {
        width: 100%;
        height: 95%;
        max-width: 90vw;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
      }
      .archives-info-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
