<template>
  <div id="get_archives">
    <div class="get_archives" v-for="item in value.data" :key="item.id">
      <div class="get-archives-line">
        <div class="get-archives-line-title">客户名称:</div>
        <div class="get-archives-line-value">{{ item.clientName }}</div>
      </div>
      <div class="get-archives-line">
        <div class="get-archives-line-title">领取物品:</div>
        <div class="get-archives-line-value">
          {{ item.name.join("、") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    archivesData: {
      type: Object,
      default: function() {
        return;
      },
    },
  },
  data() {
    return {
      value: this.archivesData,
    };
  },
  created() {
    console.log("this.value", this.value);
  },
};
</script>

<style lang="less" scoped>
#get_archives {
  display: flex;
  flex-direction: column;
  .get_archives {
    margin-bottom: 10px;
    .get-archives-line {
      display: flex;
      margin: 0 0 10px 5px;
      .get-archives-line-title {
        margin-right: 5px;
        width: auto;
        min-width: 70px;
      }
      .get-archives-line-value {
        display: flex;
        flex-wrap: wrap;
        text-align: left;
      }
    }
  }
}
</style>
