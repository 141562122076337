/**
 * 保存表头数据
 */
export function saveTableMenuData(menuData, model, _this) {
    let requestData = {
        userId: _this.baseInfo.userId,
        model: model,
        menuData: menuData,
    };
    _this.$request(
        "post",
        "/featureRoleRel/saveMenuData",
        requestData,
        (data) => {},
        (errorMessage) => {
            _this.loading = false;
            _this.$message.error(errorMessage);
        }
    );
}

/**
 * 获取表头数据
 */
export function getModelMenuAndAuthority(model, attribute, _this, del) {
    let key = []
    for (let aindex = 0; aindex < attribute.length; aindex++) {
        let element = attribute[aindex];
        key.push(element.key)
    }
    let requestData = {
        userId: _this.baseInfo.userId,
        companyId: _this.baseInfo.company.companyId,
        model: model,
        key: key
    }
    _this.$request(
        "post",
        "/featureRoleRel/getModelMenuAndAuthority",
        requestData,
        (data) => {
            let menu = []
            for (let index = 0; index < data.menu.length; index++) {
                let element = data.menu[index];
                for (let i = 0; i < attribute.length; i++) {
                    let ele = attribute[i];
                    if (element == ele.key) {
                        ele.authority = data[element]
                        menu.push(ele)
                    }
                }
            }
            del(menu)
        },
        (errorMessage) => {
            _this.$message.error(errorMessage);
        }
    );
}