/**
 * 话单列表表格数据处理
 * @param {} data 
 * @returns 
 */
export function tableData(data) {
    data.forEach(item => {
        if (item.status == '1') {
            item.color = '#67C23A'
        } else if (item.status == '0') {
            item.color = '#F56C6C'
        }
        let seconds = item.duration;
        let minutes = Math.floor(seconds / 60);
        let remainingSeconds = seconds % 60;
        if(minutes == 0) {
            item.duration = remainingSeconds + "秒"
        } else{
            item.duration = minutes + "分钟" + remainingSeconds + "秒"

        }
    });
    return data 
}

 /**
 * 获取界面筛选数据
 * 
 */
  export function callListSearchData() {
    let data = [
        {
            title: "通话结束时间",
            type: "times",
            flag: "0",
            hasSearch: false,
            key: "endTime",
            value: "",
        },
        {
            title: "通话状态",
            type: "select",
            hasSearch: false,
            flag: "0",
            key: "callStatus",
            value: "",
            options: [
                { label: "接通", value: "1" },
                { label: "未接通", value: "0" },
            ],
        },
        {
            title: "通话时长",
            type: "select",
            hasSearch: false,
            flag: "0",
            key: "callTime",
            value: "",
            options: [
                { label: "全部", value: "1" },
                { label: "1-15秒", value: "2" },
                { label: "16-30秒", value: "3" },
                { label: "31-60秒", value: "4" },
                { label: "60秒以上", value: "5" },
            ],
        },
        
    ]
    return data
}

/**
 * 失败列表模块筛选项
 */
export function getFailList() {
    let data = [
        {
            label: '我的资源',
            value: '我的资源'
        },
        // {
        //     label: '商机池',
        //     value: '商机池'
        // },
        // {
        //     label: '财务收款',
        //     value: '财务收款'
        // },
        // {
        //     label: '税务统计',
        //     value: '税务统计'
        // },
        // {
        //     label: '社保统计',
        //     value: '社保统计'
        // },
        // {
        //     label: '我的报销',
        //     value: '我的报销'
        // },
        // {
        //     label: '地址维护',
        //     value: '地址维护'
        // },
        // {
        //     label: '工资单',
        //     value: '工资单'
        // },
    ]
    return data
}

/**
 * 我的资源表头数据
 */
export function myResourcesTabMenuData() {
    let data = [
        {
            prop: 'name',
            label: '资源名称'
        },
        {
            prop: 'contactName',
            label: '联系人'
        },
        {
            prop: 'phoneNumber1',
            label: '手机号1'
        },
        {
            prop: 'phoneNumber2',
            label: '手机号2'
        },
        {
            prop: 'address',
            label: '详细地址'
        },
        {
            prop: 'remark',
            label: '备注'
        },
        {
            prop: 'errMsg',
            label: '错误信息'
        }
    ]
    return data
}