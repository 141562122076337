<template>
  <div id="business-role">
    <el-table :data="value" height="300" border style="width: 95%">
      <el-table-column prop="authorityName" label="角色名称"> </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot="header">
          <el-button
            size="mini"
            type="primary"
            @click="handleEdit('add', {})"
            icon="el-icon-plus"
            plain
            >添加角色</el-button
          >
        </template>
        <template slot-scope="scope">
          <el-button
            size="mini"
            @click="handleEdit('edit', scope.row)"
            type="success"
            icon="el-icon-edit"
            plain
            :disabled="scope.row.flag"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleEdit('delete', scope.row)"
            icon="el-icon-delete"
            plain
            :disabled="scope.row.flag"
            >删除</el-button
          >
        </template></el-table-column
      >
    </el-table>

    <el-dialog
      :title="dialogData.title"
      :visible.sync="dialogData.flag"
      width="35%"
      :modal="false"
      :before-close="handleClose"
    >
      <el-input v-model="dialogData.data" placeholder="请输入内容"></el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="makeSureBusinessRole"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["businessRole"],
  data() {
    return {
      baseInfo: {},
      value: this.businessRole,

      dialogData: {
        flag: false,
        title: "",
        width: "30%",
        data: "",
        id: "",
      },
    };
  },
  watch: {
    businessRole() {
      //父组件值变化->>子组件也变化
      this.value = this.businessRole;
    },
    value(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {
    handleEdit(active, data) {
      switch (active) {
        case "edit":
          this.dialogData.title = "编辑业务角色";
          this.dialogData.data = data.authorityName;
          this.dialogData.id = data.authorityId;
          this.dialogData.flag = true;
          break;
        case "add":
          this.dialogData.title = "添加业务角色";
          this.dialogData.data = "";
          this.dialogData.id = "";
          this.dialogData.flag = true;
          break;
        case "delete":
          this.dialogData.title = "删除业务角色";
          this.dialogData.id = data.authorityId;
          this.makeSureBusinessRole();
          break;

        default:
          break;
      }
    },
    makeSureBusinessRole() {
      this.$emit("makeSureBusinessRole", this.dialogData);
    },
    handleClose() {
      this.dialogData.flag = false;
    },
  },
  mounted() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
  },
};
</script>

<style lang="less" scoped>
#business-role {
  width: 100%;
  height: 100%;
  max-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>