<template>
  <!-- 抽屉 税务 -->
  <div id="company-drawer-table-tax">
    <div class="company-drawer-table-tax-search">
      <div class="company-drawer-table-tax-search-item">
        <el-date-picker
          v-model="searchData.date"
          type="monthrange"
          range-separator="至"
          start-placeholder="开始月份"
          end-placeholder="结束月份"
          value-format="yyyy-MM"
          :clearable="false"
          @change="initTableData"
        >
        </el-date-picker>
      </div>
    </div>
    <div class="company-drawer-table-tax-content">
      <el-table
        :data="tableData"
        highlight-current-row
        style="width: 100%"
        height="98%"
        size="small"
        :row-style="{ height: '60px' }"
        v-loading="loading"
        show-summary
      >
        <el-table-column type="index" width="50" fixed> </el-table-column>
        <el-table-column prop="dataDate" label="时间"> </el-table-column>
        <el-table-column
          prop="invoicingAmount"
          label="发票收入"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="totalTaxAmount"
          label="全部税额"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="valueAddedTax"
          label="增值税及附加"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="socialSecurity"
          label="社保"
          show-overflow-tooltip
        >
        </el-table-column>
      </el-table>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :clientName="dialogData.clientName"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @handleAvatarSuccess="handleAvatarSuccess"
        @deleteItem="deleteItem"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import * as taxData from "@/views/tax/js/tax.js";
import my_dialog from "@/components/dialog.vue";
export default {
  components: {
    my_dialog,
  },
  props: {
    tempClient: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      tabIndex: 0,
      loading: false,
      baseInfo: {},
      tableData: [],
      searchData: {
        date: [
          this.$formatTime(new Date().setMonth(0), "YYYY-MM"),
          this.$formatTime(new Date().setMonth(11), "YYYY-MM"),
        ],
      },
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "socialCheck"
      ),

      dialogData: {
        flag: false,
        width: "30%",
        title: "运单详情",
        buttonArray: [
          {
            text: "确认",
            active: "confirm",
          },
        ],
        data: [],
      },
      temSocialCheck: {},
      whetherAgencyHead: false,
      tabIndex: "-1",
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "taxInfo",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.initTableData();
      });
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      this.tableData = [];
      this.loading = true;
      let requestData = {
        clientId: this.tempClient.id,
        date: this.searchData.date, // 日期筛选项
      };
      this.$request(
        "post",
        "/taxInfo/queryByClient",
        requestData,
        (data) => {
          this.tableData = data;
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },

    /**
     * 抽屉数据或dialog数据操作事件
     */
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "cancel":
          this.colseDialog();
          break;
        default:
          break;
      }
    },

    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 社保审核图片上传成功
     */
    handleAvatarSuccess(response) {
      this.$message({
        message: "上传成功!",
        type: "success",
        duration: 1000,
      });
      this.dialogData.data[0].value.value.push(response.result.path);
    },
    deleteItem(index) {
      this.dialogData.data[0].value.value.splice(index, 1);
    },
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#company-drawer-table-tax {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .company-drawer-table-tax-search {
    width: 100%;
    height: auto;
    display: flex;
    margin-bottom: 10px;
    .company-drawer-table-tax-search-item {
      width: 220px;
      min-width: 220px;
      height: 30px;
      margin-right: 10px;
      display: flex;
      align-items: center;
    }
  }
  .company-drawer-table-tax-content {
    width: 100%;
    height: 100%;
    border-top: 1px solid #e9ebed;
    .table-image {
      width: 40px;
      height: 40px;
    }
    .editRemark {
      color: #409eff;
      cursor: pointer;
    }
    /deep/ .el-table--scrollable-x .el-table__body-wrapper {
      z-index: 1;
      &::-webkit-scrollbar {
        height: 6px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.2);
      }
    }
    /deep/ .el-table--scrollable-y .el-table__body-wrapper {
      x &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
  .company-drawer-table-tax-pagination {
    width: 100%;
    height: auto;
  }
}
</style>
