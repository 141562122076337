export function tableData(data) {
    let tableData = []
    let currentTime = Date.now()
    for (let i = 0; i < data.length; i++) {
        if (currentTime >= Date.parse(data[i].expectTime) && currentTime <= Date.parse(data[i].endTime) + 86400000 ) {
            // 当前时间在期望时间和结束时间之间
            data[i].flag = true
        } else {
            // 当前时间不在期望时间和结束时间之间
            data[i].flag = false
          }
    }
    tableData = data
    return tableData
}