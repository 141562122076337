<template>
  <div id="diy-area-select">
    <div class="diy-area-select-item">
      <!-- 省 -->
      <el-select
        v-model="value[0]"
        placeholder="请选择省"
        filterable
        @change="getSecondLevel"
      >
        <el-option
          v-for="item in provinceOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="diy-area-select-item">
      <!-- 市 -->
      <el-select
        v-model="value[1]"
        placeholder="请选择市"
        filterable
        @change="getThreeLevel"
      >
        <el-option
          v-for="item in cityOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="diy-area-select-item">
      <!-- 区 -->
      <el-select v-model="value[2]" placeholder="请选择区" filterable>
        <el-option
          v-for="item in areaOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      provinceOptions: [],
      cityOptions: [],
      areaOptions: [],
    };
  },
  methods: {
    /**
     * 获取一级数据
     */
    getFirstLevel() {
      this.$request(
        "get",
        "/invoiceArea/getFirstLevel",
        {},
        (data) => {
          this.provinceOptions = data.map((item) => {
            return {
              label: item,
              value: item,
            };
          });
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取二级数据
     */
    getSecondLevel() {
      this.$request(
        "get",
        "/invoiceArea/getSecondLevel?firstLevel=" + this.value[0],
        {},
        (data) => {
          this.$request(
            "get",
            `/invoiceArea/getThreeLevel?firstLevel=${this.value[0]}&secondLevel=${data[0]}`,
            {},
            (data2) => {
              this.value[1] = "";
              this.value[2] = "";
              this.cityOptions = data.map((item) => {
                return {
                  label: item,
                  value: item,
                };
              });
              this.areaOptions = data2.map((item) => {
                return {
                  label: item,
                  value: item,
                };
              });
              if (this.cityOptions.length > 0) {
                this.value[1] = this.cityOptions[0].value;
              }
              if (this.areaOptions.length > 0) {
                this.value[2] = this.areaOptions[0].value;
              }
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取三级级数据
     */
    getThreeLevel() {
      this.$request(
        "get",
        `/invoiceArea/getThreeLevel?firstLevel=${this.value[0]}&secondLevel=${this.value[1]}`,
        {},
        (data) => {
          this.value[2] = "";
          this.areaOptions = data.map((item) => {
            return {
              label: item,
              value: item,
            };
          });
          if (this.areaOptions.length > 0) {
            this.value[2] = this.areaOptions[0].value;
          }
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
  },
  created() {
    this.getFirstLevel();
  },
};
</script>

<style lang="less" scoped>
#diy-area-select {
  display: flex;
  width: 100%;
  justify-content: space-between;
  .diy-area-select-item {
    display: flex;
    width: 30%;
  }
}
</style>
