<template>
  <div id="knowledge_setting">
    <!-- 知识库 - 设置 -->
    <div class="screen">
      <div class="screen-line">
        <div class="recyle-setting-screen-tabs">
          <div
            :class="{
              'recyle-setting-screen-tab': true,
              'is-active': tabIndex == item.text,
            }"
            v-for="(item, index) in tabList"
            :key="index"
            @click="changeTab(item, index)"
          >
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
    <div class="table-content">
      <div class="knowledge-table-card" v-loading="loading">
        <div
          class="knowledge-table-card-content"
          v-if="tabIndex == '选项值设置'"
        >
          <div class="knowledge-table-card-content-tags-setting">
            <div class="knowledge-table-card-content-tags-setting-left">
              <div
                :class="{
                  'knowledge-table-card-content-tags-setting-left-line': true,
                  'is-active': index == tagsIndex,
                }"
                v-for="(item, index) in optionValueList1"
                :key="index"
                @click="tagsIndex = index"
              >
                {{ item.name }}
              </div>
            </div>
            <div class="knowledge-table-card-content-tags-setting-right">
              <div class="knowledge-table-card-content-tags-setting-right-add">
                <div
                  class="butt"
                  @click="addTags(1, -1)"
                  v-showFunction="{
                    active: 'add',
                    array: allFunctionButtons,
                  }"
                >
                  添加选项
                </div>
              </div>
              <div
                class="knowledge-table-card-content-tags-setting-right-list"
                v-if="optionValueList1[tagsIndex].value.length == 0"
              >
                没有相关数据!
              </div>
              <div class="knowledge-table-card-content-tags-setting-right-list">
                <div
                  class="knowledge-table-card-content-tags-setting-right-line"
                  v-for="(item, index) in optionValueList1[tagsIndex].value"
                  :key="index"
                >
                  <div
                    v-if="item.flag"
                    @click="
                      tabList[0].flag == 2 ? '' : (item.flag = !item.flag)
                    "
                  >
                    {{ index + 1 }}、{{ item.name }}
                  </div>
                  <div v-else>
                    {{ index + 1 }}、
                    <el-input
                      v-model="item.name"
                      placeholder="请输入内容"
                      @blur="addTags(3, index)"
                      :disabled="tabList[0].flag == 2"
                    ></el-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as baseData from "@/assets/js/baseData.js";
import * as systemData from "@/assets/js/systemAuthorityData.js";

export default {
  data() {
    return {
      baseInfo: {},
      loading: true,
      tabIndex: "选项值设置",
      tagsIndex: 0,
      tabList: [
        {
          text: "选项值设置",
          flag: 1,
        },
      ],
      optionValueList1: [{ name: "", value: [{ name: "", value: "" }] }], // 选项值设置
      tempTagList: [],
      preserve: "15", // 商机保护天数
      threshold: "30", // 商机阈值
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "knowledgeSetting"
      ),
    };
  },
  methods: {
    /**
     * 初始化界面
     */
    initPage() {
      this.$authority.getModelFeatureAuthority(
        this,
        "knowledgeSetting",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.getTagList();
        }
      );
    },
    /**
     * 获取选项值标签
     */
    getTagList() {
      let requestData = {
        // model: "knowledge",
        type: ["知识库分类"],
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/getTagList",
        requestData,
        (data) => {
          this.tempTagList = [];
          this.optionValueList1 = [];
          console.log("pppppppppp", data, data.length);
          if (data.length == 0) {
            // 初次获取该模块标签,读取配置文件
            this.tempTagList.push(
              baseData.getTagList("知识库分类", "knowledgeBase")
            );
            console.log("sssssss" + this.tempTagList);
            baseData.initTagList(this.tempTagList, this.optionValueList1);
          } else {
            this.tempTagList = data;
            if (data.length != requestData.type.length) {
              baseData
                .initTagListPart(data, requestData.type)
                .forEach((element) => {
                  this.tempTagList.push(
                    baseData.getTagList(element, "knowledgeBase")
                  );
                });
            }
            baseData.initTagList(this.tempTagList, this.optionValueList1);
          }

          console.log("当前模块标签:", this.optionValueList1);
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 修改标签
     */
    updateTagList(array) {
      let requestData = {
        list: array,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/updateTagList",
        requestData,
        (data) => {
          this.getTagList();
          this.$message({
            message: "修改成功!",
            type: "success",
            duration: 1000,
          });
        },
        (errorMessage) => {
          this.getTagList();
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 添加标签选项
     */
    addTags(flag, index) {
      if (this.tabList[0].flag == 1) {
        switch (flag) {
          case 1:
            //  添加一般选项,即添加一个输入框
            let dd = {
              name: "",
              value: "",
              flag: false,
            };
            this.optionValueList1[this.tagsIndex].value.push(dd);
            break;
          case 3:
            //  输入框失去焦点事件
            this.optionValueList1[this.tagsIndex].value[index].flag = !this
              .optionValueList1[this.tagsIndex].value[index].flag;
            if (this.optionValueList1[this.tagsIndex].value[index].name != "") {
              this.optionValueList1[this.tagsIndex].value[
                index
              ].value = this.optionValueList1[this.tagsIndex].value[index].name;
            } else {
              this.optionValueList1[this.tagsIndex].value.splice(index, 1);
            }
            this.updateTagList(this.tempTagList);
            break;
          default:
            break;
        }
      } else {
        this.$message.error(
          this.$errorMessage.getErrorMessageInfo("authority")
        );
      }
    },
    /**
     * 切换tab
     */
    changeTab(data, index) {
      if (this.tabList[index].flag < 3) {
        this.loading = true;
        this.tabIndex = data.text;
        switch (index) {
          case 0:
            // 选项值设置
            this.Loading();
            this.getTagList();
            break;

          default:
            break;
        }
      } else {
        this.$message.error(
          this.$errorMessage.getErrorMessageInfo("authority")
        );
      }
    },
    handleClose(tag, array) {
      array.splice(array.indexOf(tag), 1);
    },

    showInput(index, array) {
      this.optionValueList1[index].flag = true;
    },
    /**
     * 页面加载动画
     */
    Loading() {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
  },
  mounted() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.initPage();
    this.loading = false;
  },
};
</script>

<style lang="less" scoped>
#knowledge_setting {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .recyle-setting-screen-tabs {
    width: 90%;
    height: 100%;
    display: flex;
    font-size: 14px;
    margin-left: 20px;
    .recyle-setting-screen-tab {
      width: 100px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      transition: 0.3s;
    }
    .recyle-setting-screen-tab:hover {
      cursor: pointer;
      color: @activeColor;
    }
    .is-active {
      color: @activeColor;
      border-bottom: 4px solid @activeColor;
    }
  }
  .knowledge-table-card {
    width: 98%;
    height: 98%;
    max-height: 830px;
    max-width: 90vw;
    overflow-y: auto;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    border-radius: 5px;

    &::-webkit-scrollbar {
      width: 1px;
    }
    .knowledge-table-card-content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      .knowledge-table-card-content-line,
      .knowledge-table-card-content-line2 {
        width: 100%;
        flex: 1;
        // min-height: 200px;
        height: 200px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        flex-direction: column;
        overflow: auto;
        &::-webkit-scrollbar {
          width: 1px;
        }
        .knowledge-table-card-content-line-title {
          width: 98%;
          height: 40px;
          margin-left: 20px;
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          span {
            height: 60%;
            display: flex;
            align-items: flex-end;
            color: @tipColor;
            font-size: @contentFontSize;
            margin-left: 10px;
          }
          .knowledge-table-card-content-line-title-save {
            width: auto;
            height: 30px;
            // margin-right: 20px;
            padding: 0 10px;
            border: 1px solid @activeColor3;
            background-color: @activeBackground;
            border-radius: 5px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.3s;
            font-size: 12px;
            &:hover {
              cursor: pointer;
              // transform: scale(1.02);
              background-color: @activeColor;
              color: #fff;
            }
          }
        }
        .knowledge-table-card-content-line-value {
          width: 95%;
          height: 90%;
          display: flex;
          margin-left: 40px;
          .el-tag + .el-tag {
            margin-left: 20px;
          }
          .button-new-tag {
            margin-left: 20px;
            height: 32px;
            line-height: 30px;
            padding-top: 0;
            padding-bottom: 0;
          }
          .input-new-tag {
            width: 90px;
            margin-left: 10px;
            vertical-align: bottom;
          }
          .div-tags {
            height: auto;
            width: 96%;
            display: flex;
            flex-wrap: wrap;
            & > div {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              & > span {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
              }
            }
            .div-tag {
              width: auto;
              min-width: 30px;
              height: 25px;
              margin: 10px;
              padding: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid @boderActiveColor;
              transition: 0.5s;
              font-size: @contentFontSize;
            }
            .div-tag:hover {
              cursor: pointer;
              border: 1px dashed @activeColor;
              background-color: @activeBackground;
              color: @activeColor;
            }
          }
          .knowledge-table-card-content-line-value-button {
            width: 98%;
            flex: 1;
            height: 0;
            // overflow: auto;
            display: flex;
            .knowledge-table-card-content-line-value-button-title {
              width: 15%;
              height: 98%;
            }
            .knowledge-table-card-content-line-value-button-values {
              flex: 1;
              height: 98%;
              overflow: auto;
              display: flex;
              flex-wrap: wrap;
              min-height: 190px;

              &::-webkit-scrollbar {
                width: 1px;
              }
            }
          }
          .knowledge-table-card-content-line-value-button:nth-child(1) {
            // height: 120px;
            border-bottom: 1px solid @boderColor;
            margin-bottom: 10px;
            padding-bottom: 10px;
          }
        }
        .div-flex-column {
          flex-direction: column;
        }
        .div-flex-space-between {
          justify-content: space-between;
        }
      }
      .knowledge-table-card-content-line2 {
        max-height: 400px;
      }
      .knowledge-table-card-content-line {
        min-height: 200px;
      }

      .knowledge-table-card-content-tags-setting {
        width: 100%;
        height: 100%;
        display: flex;
        .knowledge-table-card-content-tags-setting-left {
          width: 8vw;
          height: 100%;
          display: flex;
          flex-direction: column;
          border-right: 1px solid @activeColor3;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
          .knowledge-table-card-content-tags-setting-left-line {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: @contentFontSize;
            transition: 0.3s;
            color: #000;
            &:hover {
              cursor: pointer;
              color: @activeColor;
            }
          }
          .is-active {
            color: @activeColor;
          }
        }
        .knowledge-table-card-content-tags-setting-right {
          flex: 1;
          width: 0;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          .knowledge-table-card-content-tags-setting-right-add {
            width: 100%;
            height: 8vh;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 20px;
            .butt {
              width: 100px;
              height: 40px;
              border: 1px solid @activeColor;
              background-color: @activeColor;
              color: #fff;
              font-size: @contentFontSize;
              display: flex;
              align-items: center;
              border-radius: 10px;
              justify-content: center;
              transition: 0.3s;
              &:hover {
                cursor: pointer;
                background-color: @activeColor2;
              }
            }
          }
          .knowledge-table-card-content-tags-setting-right-list {
            flex: 1;
            height: 0;
            width: 97%;
            display: flex;
            flex-direction: column;
            overflow-y: auto;

            &::-webkit-scrollbar {
              width: 1px;
            }
            .knowledge-table-card-content-tags-setting-right-line {
              height: 50px;
              min-height: 50px;
              width: 100%;
              display: flex;
              align-items: center;
              padding-left: 10px;
              box-sizing: border-box;
              border-bottom: 1px solid @boderColor;
              justify-content: space-between;
              & > div {
                display: flex;
                width: 100%;
              }
            }
          }
        }
      }
      .knowledge-table-card-content-con {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        margin: 50px;
        .knowledge-table-card-content-con_title {
          width: 100%;
          height: 50px;
          display: flex;
          align-items: center;
          font-size: 18px;
          font-weight: bold;
          .knowledge-table-card-content-con_title_tip {
            color: rgb(250, 92, 0);
            font-size: 12px;
            font-weight: normal;
            margin-left: 10px;
            margin-top: 5px;
          }
        }
        .knowledge-table-card-content-con_value {
          width: 100%;
          height: 50px;
          display: flex;
          align-items: center;
          .el-input {
            width: 220px !important;
            .el-input__inne {
              width: 100% !important;
            }
          }
          span {
            margin-left: 10px;
          }
        }
        .knowledge-table-card-content-con_buttons {
          width: 60%;
          height: auto;
          display: flex;
          margin-top: 10px;
          .knowledge-table-card-content-con_button {
            width: 90px;
            height: 30px;
            margin-left: 20px;
            background-color: rgb(53, 162, 250);
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            transition: 0.3s;
            &:hover {
              background-color: rgb(46, 137, 211);
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>