import Vue from 'vue'
import Vuex from 'vuex'

//挂载Vuex
Vue.use(Vuex)
import cretestate from 'vuex-persistedstate'
//创建VueX对象
let store = new Vuex.Store({
    plugins: [cretestate()],
    state: {
        //存放的键值对就是所要管理的状态
        productList: [],
        employeeList: [],
        departmentList: [],
        serviceTypeList: [],
        serviceItemList: [],
        clientList: [],
        professionList: [],
        companyLogo: "",
        icon: "",
        accountList: [],
        // pictLoading: false,
    },
    mutations: {
        setPictLoading(state,payload) {
            console.log('payload', payload)
            state.pictLoading = payload
        },
        // mutations是操作state数据的方法的集合，比如对该数据的修改、增加、删除等等。
        /**
         * 修改公司LOGO
         * @param {*} state 
         * @param {*} payload 
         */
        setCompanyLogo(state, payload) {
            if (payload == "" || payload == null) {
                payload = "https://crm.hfjunling.com/20210907/16-49-59_company_logo_1631004599291.png"
            }
            sessionStorage.setItem("LOGO", payload)
                // state.companyLogo = payload

        },
        /**
         * 修改图像
         * @param {*} state 
         * @param {*} payload 
         */
        setIcon(state, payload) {
            // if (payload == "" || payload == null) {
            //     payload = "http://114.55.138.13:8089/20210514/14:42:46_tuxiang_1620974566155.png"
            // }
            sessionStorage.setItem("icon", payload)
                // state.icon = payload
        },
        /**
         * 获取所有产品列表
         * @param {*} state 相当于this.state
         * @param {*} payload 形参
         */
        getAllProductList(state, payload) {
            state.productList = []
            let requestData = {
                companyId: payload.baseInfo.company.companyId,
                name: "", // 服务产品名称 模糊查询
                type: "",
                currentPage: 1, // 当前页数
                pageSize: 1000, // 每页条数
            }
            payload.$request(
                "post",
                "/product/getAllProduct",
                requestData,
                (data) => {
                    state.productList = data.tableData;
                    sessionStorage.setItem("productList", JSON.stringify(state.productList))
                        // payload.getServicesItem();
                },
                (errorMessage) => {
                    payload.$message.error(errorMessage);
                }
            );
        },
        /**
         * 获取所有员工列表
         * @param {*} state 
         * @param {*} payload 
         */
        getAllEmployeeList(state, payload) {
            state.employeeList = []
            let requestData = {
                companyId: payload.baseInfo.company.companyId,
                userId: payload.baseInfo.userId,
                isDiMission: false,
                search: ""
            }
            payload.$request(
                "post",
                "/user/getEmployeeListForDownBox",
                requestData,
                (data) => {
                    state.employeeList = data;
                    sessionStorage.setItem("employeeList", JSON.stringify(state.employeeList))
                },
                (errorMessage) => {
                    payload.$message.error(errorMessage);
                }
            );

        },
        /**
         * 获取所有部门列表
         * @param {*} state 
         * @param {*} payload 
         */
        getAllDepartmentList(state, payload) {
            state.departmentList = []
            let requestData = {
                companyId: payload.baseInfo.company.companyId,
            }
            payload.$request(
                "post",
                "/department/getDepartmentList",
                requestData,
                (data) => {
                    state.departmentList = data;
                    sessionStorage.setItem("departmentList", JSON.stringify(state.departmentList))
                },
                (errorMessage) => {
                    payload.$message.error(errorMessage);
                }
            );
        },
        /**
         * 获取所有服务类列表
         * @param {*} state 
         * @param {*} payload 
         */
        getAllServiceTypeList(state, payload) {
            state.serviceTypeList = []
            let requestData = {
                companyId: payload.baseInfo.company.companyId,
            }
            payload.$request(
                "post",
                "/serveType/getAllServeType",
                requestData,
                (data) => {
                    state.serviceTypeList = data;
                    sessionStorage.setItem("serviceTypeList", JSON.stringify(state.serviceTypeList))
                },
                (errorMessage) => {
                    payload.$message.error(errorMessage);
                }
            );
        },
        /**
         * 获取所有服务项列表
         * @param {*} state 
         * @param {*} payload 
         */
        getAllServicesItemList(state, payload) {
            state.serviceItemList = []
            let requestData = {
                companyId: payload.baseInfo.company.companyId,
                status: "1",
                name: "", // 服务产品名称 模糊查询
                type: "",
            }
            payload.$request(
                "post",
                "/servicesItem/getServicesItem",
                requestData,
                (data) => {
                    state.serviceItemList = data;
                    sessionStorage.setItem("serviceItemList", JSON.stringify(state.serviceItemList))
                },
                (errorMessage) => {
                    payload.$message.error(errorMessage);
                }
            );
        },
        /**
         * 获取所有客户列表
         * @param {*} state 
         * @param {*} payload 
         */
        getAllClientByUserId(state, payload) {
            state.clientList = []
            let requestData = {
                companyId: payload.baseInfo.company.companyId,
                userId: payload.baseInfo.userId,
                type: "全部客户", // 与业务角色对应(对应关系暂定 20210618)
                hasLeave: "0", // 是否包含流失,"0"为否,"1"为是
                currentPage: 1, // 当前页数
                pageSize: 1000, // 每页条数
                search: "",
            }
            payload.$request(
                "post",
                "/client/getAllClientByCompany",
                requestData,
                (data) => {
                    state.clientList = data;
                    sessionStorage.setItem("clientList", JSON.stringify(state.clientList))
                },
                (errorMessage) => {
                    payload.$message.error(errorMessage);
                }
            );
        },
        /**
         * 获取所有服务项列表
         * @param {*} state 
         * @param {*} payload 
         */
        getAllProfessionList(state, payload) {
            state.professionList = []
            let requestData = {
                companyId: payload.baseInfo.company.companyId,
                // departmentId: "all",
            }
            payload.$request(
                "post",
                "/profession/getProfessionList",
                requestData,
                (data) => {
                    state.professionList = data;
                    sessionStorage.setItem("professionList", JSON.stringify(state.professionList))
                },
                (errorMessage) => {
                    payload.$message.error(errorMessage);
                }
            );
        },
        /**
         * 获取所有公司账号列表
         * @param {*} state 
         * @param {*} payload 
         */
        getCompantAccountList(state, payload) {
            state.employeeList = []
            let requestData = {
                companyId: payload.baseInfo.company.companyId
            }
            payload.$request(
                "post",
                "/companyAccount/getAccountList",
                requestData,
                (data) => {
                    state.accountList = data;
                    sessionStorage.setItem("accountList", JSON.stringify(state.accountList))
                },
                (errorMessage) => {
                    payload.$message.error(errorMessage);
                }
            );

        },

    },
    getters: {
        // 可以对state中的成员加工后传递给外界
        getCompanyLogo(state) {
            return sessionStorage.getItem("LOGO")
        },
        getIcon(state) {
            return sessionStorage.getItem("icon")
        },
        getAllProductList(state) {
            state.productList = JSON.parse(sessionStorage.getItem("productList"))
            return state.productList
        },
        getAllEmployeeList(state) {
            state.employeeList = JSON.parse(sessionStorage.getItem("employeeList"))
            return state.employeeList
        },
        getAllDepartmentList(state) {
            state.departmentList = JSON.parse(sessionStorage.getItem("departmentList"))
            return state.departmentList
        },
        getAllServiceTypeList(state) {
            state.serviceTypeList = JSON.parse(sessionStorage.getItem("serviceTypeList"))
            return state.serviceTypeList
        },
        getAllServicesItemList(state) {
            state.serviceItemList = JSON.parse(sessionStorage.getItem("serviceItemList"))
            return state.serviceItemList
        },
        getAllClientByUserId(state) {
            state.clientList = JSON.parse(sessionStorage.getItem("clientList"))
            return state.clientList
        },
        getAllProfessionList(state) {
            state.professionList = JSON.parse(sessionStorage.getItem("professionList"))
            return state.professionList
        },
        getCompantAccountList(state) {
            state.accountList = JSON.parse(sessionStorage.getItem("accountList"))
            return state.accountList
        }
    },
    actions: {
        // 由于直接在mutation方法中进行异步操作，将会引起数据失效。所以提供了Actions来专门进行异步操作，最终提交mutation方法
        setCompanyLogo(context, payload) {
            context.commit('setCompanyLogo', payload)
        },
        setIcon(context, payload) {
            context.commit('setIcon', payload)
        },
        getAllProductList(context, payload) {
            context.commit('getAllProductList', payload)
        },
        getAllEmployeeList(context, payload) {
            context.commit('getAllEmployeeList', payload)
        },
        getAllDepartmentList(context, payload) {
            context.commit('getAllDepartmentList', payload)
        },
        getAllServiceTypeList(context, payload) {
            context.commit('getAllServiceTypeList', payload)
        },
        getAllServicesItemList(context, payload) {
            context.commit('getAllServicesItemList', payload)
        },
        getAllClientByUserId(context, payload) {
            context.commit('getAllClientByUserId', payload)
        },
        getAllProfessionList(context, payload) {
            context.commit('getAllProfessionList', payload)
        },
        getCompantAccountList(context, payload) {
            context.commit('getCompantAccountList', payload)
        }
    }
})

export default store