<template>
  <div id="client-check">
    <!-- 流失审核 -->
    <div class="screen">
      <div class="screen-line">
        <div class="client-check-screen-fiftrate">
          <el-popover
            placement="bottom-start"
            width="250"
            trigger="manual"
            :visible-arrow="false"
            v-model="popoData.popoVisible"
          >
            <div class="popover-search">
              <popo_search
                :list="popoData.list"
                @cancel="popoCanael"
                @reset="popoReset"
                @makeSure="popoMakeSure"
              ></popo_search>
            </div>
            <div slot="reference" @click="popoverOpen">
              筛选 <i class="el-icon-s-operation"></i>
            </div>
          </el-popover>
        </div>
        <div class="client-check-screen-search">
          <el-input
            placeholder="请输入客户全称/编号/税号"
            v-model="searchData.input"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="client-check-screen-search">
          <el-select
            v-model="searchData.lossReason"
            placeholder="请选择流失原因"
            @change="initTableData"
            clearable
          >
            <el-option
              v-for="item in searchData.lossReasonList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="client-check-screen-search">
          <el-date-picker
            v-model="searchData.time"
            type="daterange"
            range-separator="至"
            start-placeholder="审核开始日期"
            end-placeholder="审核结束日期"
            value-format="yyyy-MM-dd"
            @change="initTableData"
          >
          </el-date-picker>
        </div>
        <div class="client-check-screen-checked">
          <!-- <el-checkbox v-model="searchData.checked">包含流失</el-checkbox> -->
        </div>
        <div class="client-check-screen-active"></div>
        <div
          class="client-check-screen-but-select"
          @click="handleClick({}, 0)"
          v-showFunction="{ active: 'check', array: allFunctionButtons }"
        >
          批量审核
        </div>
      </div>
      <div class="screen-line" v-if="searchData.screenList.length > 0">
        <div class="title">筛选条件:</div>
        <el-tag
          v-for="(tag, index) in searchData.screenList"
          :key="index"
          closable
          size="small"
          @close="closeScreen(index)"
        >
          {{ tag.name }}
        </el-tag>
      </div>
    </div>
    <div class="table-content">
      <div class="client-check-content">
        <div class="client-check-table-card-content">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            @row-click="rowClick"
            style="width: 100%"
            height="98%"
            @selection-change="handleSelectionChange"
            :row-style="rowStyle"
            v-loading="loading"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column type="index" min-width="55" fixed>
            </el-table-column>
            <el-table-column
              label="客户全称"
              property="clientName"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <div class="hri">
                  <i
                    class="el-icon-notebook-2"
                    @click.stop="showGenjin(scope.row)"
                  ></i
                  >{{ scope.row.clientName }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="客户编号" property="clientNum">
            </el-table-column>
            <el-table-column label="流失服务类别">
              <template slot-scope="scope">
                <div class="hri">
                  {{ scope.row.lossServiceType.typeName }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="流失原因" property="lossReason">
            </el-table-column>
            <el-table-column label="客户类型" property="type">
            </el-table-column>
            <el-table-column label="客户状态" property="clientStatus">
            </el-table-column>
            <el-table-column label="提交人" property="lossSubmitUser">
            </el-table-column>
            <el-table-column label="操作" width="120" fixed="right">
              <template slot-scope="scope">
                <div
                  v-for="(item, index) in tableButtons"
                  :key="index"
                  class="hri"
                >
                  <el-button
                    type="text"
                    :style="{
                      color: item.color,
                    }"
                    @click="handleClick(scope.row, item.active)"
                    v-showFunction="{
                      active: item.trueActive,
                      array: allFunctionButtons,
                    }"
                    >{{ item.text }}</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="client-check-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import my_dialog from "@/components/dialog.vue";
import popo_search from "@/components/popo-search.vue";
import * as clientInfoData from "@/assets/js/clientInfoData.js";
import * as contractDrawData from "@/assets/js/contractDraw.js";
import * as allClientData from "./js/clientData.js";
export default {
  components: {
    my_dialog,
    popo_search,
  },
  data() {
    return {
      rowStyle: this.$rowStyle1,
      baseInfo: {},
      loading: true,
      whetherAgencyHead: false,
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      searchData: {
        input: "",
        checked: false, // 是否包含流失
        time: [],
        screenButton_add: 1, // 新增客户
        screenButton_improt: 1, // 导入
        screenButton_export: 1, // 导出
        screenList: [],
        lossReason: "",
        lossReasonList: [],
      },
      popoData: {
        popoVisible: false,
        list: clientInfoData.clientInfoSearchData({}, {}, []),
      },
      tableButtons: [
        {
          text: "驳回",
          active: 1,
          flag: 1,
          trueActive: "reject",
          color: "#ff0000",
        },
        {
          text: "待审核",
          active: 2,
          flag: 1,
          trueActive: "check",
          color: "#05c0f2",
        },
      ],
      dialogData: {
        flag: false,
        width: "30%",
        title: "添加跟进记录",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },
      multipleSelection: [],
      tabMenu: [],
      tableMenuData: {
        selectMenuData: [], // 真正显示的表头数据
        chooseableMenuData: [], // 隐藏的表头
      },
      roleList: [],
      tableData: [],
      buttonAuth: [],
      productList: [], // 所有合同模板/产品数据
      employeeList: [], // 所有员工
      departmentList: [], // 所有部门
      tempThreadId: "", // 临时存放商机ID
      serviceItemList: [],
      tempServices: [],
      tempClientName: "", // 新建客户的临时名称
      tempClientId: "", // 临时客户ID
      allRoleList: [],

      tipsData: {
        clientTipsList: [], // 客户标签
        pianquList: [], // 片区标签
        clientLevelList: [], // 客户等级
        customerSourceList: [], // 客户来源
      },

      tempClient: {},
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "clientCheck"
      ),
      clientDrawerOptions: this.$tagData.getDrawerOptionsList("clientCheck"), // 抽屉中的下拉框配置项
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "clientCheck",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          // this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.initMenu();
      });
    },
    initMenu() {
      this.$featureData.getAttributeData(
        "clientCheck",
        true,
        [],
        (attributeData) => {
          this.$menuData.getModelMenuAndAuthority(
            "clientCheck",
            attributeData,
            this,
            (data) => {
              this.tabMenu = data;
              this.getEmployeeList();

              this.$tagData.getTagList(
                this.clientDrawerOptions,
                ["片区", "客户来源", "增值税类型", "客户等级", "客户标签"],
                this.baseInfo.company.companyId,
                this
              );
              this.initTableData();
            }
          );
        }
      );
    },
    /**
     * 获取所有员工列表
     */
    getEmployeeList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.employeeList = [];
          data.forEach((element) => {
            let dd = {
              label: element.userName,
              value: element.userId,
            };
            this.employeeList.push(dd);
          });
          this.getDepartmentList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有部门列表
     */
    getDepartmentList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/department/getDepartmentList",
        requestData,
        (data) => {
          this.departmentList = [];
          data.forEach((element) => {
            let dd = {
              label: element.departmentName,
              value: element.departmentId,
            };
            this.departmentList.push(dd);
          });
          this.getAllServeType();
          // this.getAllProductList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有产品类别表
     */
    getAllServeType() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/serveType/getAllServeType",
        requestData,
        (data) => {
          this.tabList = [];
          this.tabList.push({
            text: "全部客户",
            id: "全部客户",
          });
          data.forEach((element) => {
            let tt = {
              text: element.typeName,
              id: element.id,
            };
            this.tabList.push(tt);
          });
          // this.productList = data.tableData;
          this.getAllProductList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有合同模板/产品列表
     */
    getAllProductList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        name: "", // 服务产品名称 模糊查询
        type: "",
        currentPage: 1, // 当前页数
        pageSize: 1000, // 每页条数
      };
      this.$request(
        "post",
        "/product/getAllProduct",
        requestData,
        (data) => {
          this.productList = data.tableData;
          this.getServicesItem();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },

    /**
     * 获取所有服务项列表
     */
    getServicesItem() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        status: "1",
        name: "", // 服务产品名称 模糊查询
        type: "",
      };
      this.$request(
        "post",
        "/servicesItem/getServicesItem",
        requestData,
        (data) => {
          this.serviceItemList = data;
          this.getBusinessAuthorityList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有业务角色
     */
    getBusinessAuthorityList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/role/getBusinessAuthorityList",
        requestData,
        (data) => {
          this.allRoleList = [];
          data.forEach((element) => {
            let dd = {
              label: element.authorityName,
              value: element.authorityId,
            };
            this.allRoleList.push(dd);
          });
          // this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
        time: this.searchData.time, // 审核时间
        search: this.searchData.input, // 客户全称/编号/税号筛选客户 20210824 加
        lossReason: this.searchData.lossReason, // 流失原因
        screenList: this.searchData.screenList,
        pageSize: this.pageData.pageSize, // 每页条数
        currentPage: this.pageData.currentPage, // 当前页数
      };
      this.$request(
        "post",
        "/client/getForLossAuditClients",
        requestData,
        (data) => {
          this.pageData.total = data.total;
          this.tableData = data.tableData;
          this.$nextTick(() => {
            this.$refs.singleTable.doLayout();
          });
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 行点击事件
     */
    rowClick(row, column, event) {},
    /**
     * 多选事件
     */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     *
     */
    handleClick(data, flag) {
      this.tempClient = data;
      let lossList = [];
      let ll = {};
      switch (flag) {
        case 0:
          // 批量审核
          if (this.multipleSelection.length == 0) {
            this.$message({
              message: "请先选择流失客户!",
              duration: 1000,
              type: "error",
            });
            return;
          }
          this.multipleSelection.forEach((element) => {
            ll = {
              clientId: element.id,
              serviceTypeId: element.lossServiceType.id,
            };
            lossList.push(ll);
            this.reviewCustomerChurn(lossList);
          });
          break;
        case 1:
          // 驳回
          this.$confirm(
            "此操作会将该客户的状态还原至流失前状态, 是否继续?",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              let requestData = {
                clientId: data.id,
                serviceTypeId: data.lossServiceType.id,
                userId: this.baseInfo.userId,
              };
              this.$request(
                "post",
                "/client/customerChurnReject",
                requestData,
                (data) => {
                  this.$operationRecords.saveOperationRecords(
                    requestData.clientId,
                    "流失驳回!",
                    this.baseInfo,
                    "client",
                    this,
                    (data2) => {
                      this.$message.success("驳回成功!");
                      this.initTableData();
                    }
                  );
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {});
          break;
        case 2:
          // 审核
          ll = {
            clientId: data.id,
            serviceTypeId: data.lossServiceType.id,
          };
          lossList.push(ll);
          this.reviewCustomerChurn(lossList);
          break;

        default:
          break;
      }
    },
    /**
     * 批量审核
     */
    reviewCustomerChurn(array) {
      let requestData = {
        userId: this.baseInfo.userId,
        value: array,
      };
      this.$confirm(
        "此操作会导致客户的该服务类下所有合同全部流失, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.$request(
            "post",
            "/client/reviewCustomerChurn",
            requestData,
            (redata) => {
              this.$message({
                type: "success",
                message: "操作成功!",
                duration: 1000,
              });
              array.forEach((element) => {
                this.$operationRecords.saveOperationRecords(
                  element.clientId,
                  "确认客户流失!",
                  this.baseInfo,
                  "client",
                  this,
                  (data2) => {
                    this.initTableData();
                  }
                );
              });
            },
            (errorMessage) => {
              // this.$message.error(errorMessage);
              this.$message({
                dangerouslyUseHTMLString: true,
                type: "error",
                message: errorMessage,
              });
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
            duration: 1000,
          });
        });
    },
    /**
     * dialog操作事件
     */
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "cancel":
          this.colseDialog();
          break;

        default:
          break;
      }
    },
    /**
     * 查看跟进记录
     */
    showGenjin(clientData) {
      let requestData = {
        clientId: clientData.id,
        threadId: "",
      };
      this.$request(
        "post",
        "/followRecord/showFollowRecord",
        requestData,
        (data) => {
          this.dialogData.title = "跟进记录-" + clientData.clientName;
          this.dialogData.buttonArray = [];
          this.dialogData.data = [
            {
              type: "genjinjilu",
              value: {
                hasContent: false,
                content: "",
                time: "",
                list: data,
                hasTarget: false,
                target: "",
                hasPhase: false,
                phase: "",
              },
            },
          ];
          this.dialogData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 筛选弹出框显示
     */
    popoverOpen() {
      let roleList = this.allRoleList;
      let companyData = {
        employeeList: this.employeeList, // 员工列表
        departmentList: this.departmentList, // 部门列表
      };
      let tipsData = {
        clientTipsList: this.tipsData.clientTipsList, // 客户标签
        pianquList: this.tipsData.pianquList, // 片区标签
        clientLevelList: this.tipsData.levelList, // 客户等级
        customerSourceList: this.tipsData.sourceList, // 客户来源
      };
      this.popoData.list = clientInfoData.clientInfoSearchData(
        companyData,
        tipsData,
        roleList
      );
      this.popoData.popoVisible = !this.popoData.popoVisible;
    },
    /**
     * 筛选框确认事件
     */
    popoMakeSure() {
      // this.searchData.screenList = this.$systemBaseData.remakesureSearchData(
      //   this.popoData.list
      // );
      // let flag = true;
      // this.searchData.screenList.forEach((element) => {
      //   if (
      //     element.type == "phone" &&
      //     !this.$phoneCheck.mobilePhoneNumberCheck(element.value)
      //   ) {
      //     // 做手机号格式校验
      //     flag = false;
      //   }
      // });
      // if (!flag) {
      //   this.$message({
      //     message: "联系人手机号格式不正确,请确认填入正确的手机号!",
      //     type: "error",
      //     duration: 1000,
      //   });
      // } else {
      //   this.popoData.popoVisible = false;
      //   this.initTableData();
      // }
    },
    /**
     * 筛选弹出框关闭事件
     */
    popoCanael() {
      this.popoData.popoVisible = false;
    },
    /**
     * 筛选弹出框重置
     */
    popoReset() {
      this.$systemBaseData.popoReset(this.popoData.list);
      this.searchData.screenList = [];
    },
    /**
     * 关闭筛选标签事件
     */
    closeScreen(index) {
      this.searchData.screenList.splice(index, 1);
      this.initTableData();
    },
    /**
     * 分页
     */
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.initTableData();
    },
    handleCurrentChange(val) {
      this.initTableData();
    },
    getLossReason() {
      let requestData = {
        type: ["流失原因"],
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/getTagList",
        requestData,
        (data) => {
          this.searchData.lossReasonList = data[0].value;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
  },
  mounted() {
    this.loading = false;
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.isCharge();
    this.getLossReason();
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#client-check {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .client-check-screen-fiftrate {
    width: 65px;
    min-width: 65px;
    height: 30px;
    border: 1px solid @boderColor;
    box-sizing: border-box;
    color: @boderActiveColor;
    font-size: @contentFontSize;
    margin: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    transition: 0.3s;
    i {
      margin: 0 4px;
    }
  }
  .client-check-screen-fiftrate:hover {
    cursor: pointer;
    border-color: @boderActiveColor;
  }
  .client-check-screen-search {
    width: 210px;
    min-width: 210px;
    height: 30px;
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
  .client-check-screen-checked {
    width: 140px;
    height: 30px;
    min-width: 140px;
    font-size: @contentFontSize;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .client-check-screen-active {
    flex: 1;
  }
  .client-check-screen-but-select {
    width: 140px;
    min-width: 160px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @activeColor;
    font-size: @contentFontSize;
    color: #fff;
    margin: 0 5px;
    border-radius: 4px;
    transition: 0.5s;
    cursor: pointer;
    .client-check-screen-but-select_but {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.5s;
    }
    .client-check-screen-but-select_but:hover {
      background-color: @activeColor2;
    }
    .client-check-screen-but-select_select {
      width: 30px;
      height: 100%;
      border-left: 1px solid @activeColor3;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .client-check-screen-but {
    width: 100px;
    min-width: 100px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @activeColor;
    font-size: @contentFontSize;
    color: #fff;
    margin: 0 5px;
    border-radius: 4px;
    transition: 0.5s;
  }
  .client-check-screen-but:hover {
    cursor: pointer;
    background-color: @activeColor2;
  }
  .client-check-screen-but-boder {
    width: 50px;
    min-width: 50px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid @activeColor;
    font-size: @contentFontSize;
    background-color: @activeBackground;
    color: @activeColor;
    margin: 0 5px;
    margin-right: 10px;
    border-radius: 4px;
    transition: 0.5s;
  }
  .client-check-screen-but-boder:hover {
    cursor: pointer;
    background-color: @activeBackground2;
  }
  .table-content {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    .client-check-content {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .client-check-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .client-check-table-card-tab {
          width: auto;
          min-width: 100px;
          height: 100%;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .client-check-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .client-check-table-card-active {
          flex: 1;
        }
        .client-check-table-card-edit {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: @editColor;
          cursor: pointer;
        }
      }
      .client-check-table-card-content {
        flex: 1;
        width: 98%;
      }
      .client-check-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }

  .hri {
    display: flex;
    margin-left: 5px;
    i {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 3px;
      font-size: 14px;
      color: @activeColor;
    }
  }
}
</style>
