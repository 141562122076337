<template>
  <div id="invoice-gather">
    <!-- 进销项采集 -->
    <div class="screen">
      <div class="screen-line">
        <div class="invoice-gather-screen-fiftrate">
          筛选 <i class="el-icon-s-operation"></i>
        </div>
        <div class="invoice-gather-screen-search">
          <el-input
            placeholder="请输入客户全称/编号/税号"
            v-model="searchData.input"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="invoice-gather-screen-active"></div>
      </div>
    </div>

    <div class="invoice-table-content">
      <div class="invoice-gather-table-card">
        <div class="invoice-gather-table-card-tabs">
          <div
            :class="{
              'invoice-gather-table-card-tab': true,
              'is-active': tabindex == 0,
            }"
            @click="changeTab(0)"
          >
            一般纳税人
          </div>
          <div
            :class="{
              'invoice-gather-table-card-tab': true,
              'is-active': tabindex == 1,
            }"
            @click="changeTab(1)"
          >
            小规模纳税人
          </div>
          <div class="invoice-gather-table-card-active">
            <div class="textArea">
              <!-- 在开票机安装提票工具,实时自动提取销项发票,无需在手工提取。 -->
            </div>
          </div>
        </div>
        <div class="invoice-gather-content">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            height="98%"
            @row-click="tableClick"
            v-loading="loading"
          >
            <el-table-column type="index" width="55" fixed align="center">
            </el-table-column>
            <el-table-column prop="clientName" label="客户全称" align="center">
            </el-table-column>
            <el-table-column prop="clientNum" label="客户编号" align="center">
            </el-table-column>
            <el-table-column label="操作" width="55" align="center">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click.native.stop="tableOper(scope.row, 0)"
                  v-showFunction="{
                    active: 'create',
                    array: allFunctionButtons,
                  }"
                  >录票</el-button
                >
              </template>
            </el-table-column>
            <el-table-column label="销项发票" align="center"
              ><el-table-column
                prop="outputNum"
                label="张数"
                width="85"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="outputMoney"
                label="金额"
                width="85"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="outputTax"
                label="税额"
                width="85"
                align="center"
              >
              </el-table-column>
            </el-table-column>

            <el-table-column label="进项发票" align="center">
              <el-table-column label="专票" align="center"
                ><el-table-column
                  prop="incomeDedicatedNum"
                  align="center"
                  label="张数"
                  width="85"
                >
                </el-table-column>
                <el-table-column
                  prop="incomeDedicatedMoney"
                  align="center"
                  label="金额"
                  width="85"
                >
                </el-table-column>
                <el-table-column
                  prop="incomeDedicatedTax"
                  align="center"
                  label="税额"
                  width="85"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column label="普票" align="center"
                ><el-table-column
                  prop="incomeOrdinaryNum"
                  align="center"
                  label="张数"
                  width="85"
                >
                </el-table-column>
                <el-table-column
                  prop="incomeOrdinaryMoney"
                  align="center"
                  label="金额"
                  width="85"
                >
                </el-table-column>
                <el-table-column
                  prop="incomeOrdinaryTax"
                  align="center"
                  label="税额"
                  width="85"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column label="费用票" align="center"
                ><el-table-column
                  prop="incomeCostNum"
                  label="张数"
                  width="85"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="incomeCostMoney"
                  label="金额"
                  width="85"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="incomeCostTax"
                  label="税额"
                  width="85"
                  align="center"
                >
                </el-table-column>
              </el-table-column>
            </el-table-column>

            <el-table-column label="个税" align="center">
              <el-table-column
                prop="personalTaxNum"
                label="人数"
                width="85"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="personalTaxMoney"
                align="center"
                label="金额"
                width="85"
              >
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>

        <div class="invoice-gather-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>

    <!-- 右边抽屉 -->
    <div id="my-drawer" v-if="invoiceInfoDrawerData.flag">
      <my_drawer
        :drawerData="invoiceInfoDrawerData"
        :allFunctionButtons="allFunctionButtons"
        :model="'InvoiceGather'"
        @show="showDrawer"
        @closeUpdateTableDrawer="closeUpdateTableDrawer"
        @drawerOper="drawerOper"
        @linkNow="linkNow"
        @saveDrawerData="saveDrawerData"
      ></my_drawer>
    </div>
  </div>
</template>

<script>
import * as invoiceData from "@/assets/js/invoiceData.js";
import my_dialog from "@/components/dialog.vue";
import my_drawer from "@/components/my-drawer.vue";
export default {
  components: {
    my_drawer,
    my_dialog,
  },
  data() {
    return {
      baseInfo: {},
      loading: true,
      whetherAgencyHead: false,
      searchData: {
        input: "",
        checked: false,
        screenButton_add: 0,
        screenButton_improt: 0,
      },
      tabindex: 0,
      tableData: invoiceData.getInvoiceGatherTableData([]),
      dialogData: {
        flag: false,
        width: "30%",
        title: "添加跟进记录",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },
      invoiceInfoDrawerData: invoiceData.getInComeInvoiceDrawerData([], "", {
        num: "0",
        money: "0.00",
      }),
      disableList: {
        // 开票禁用
        flag1: false,
        flag2: false,
        flag3: false,
        flag4: false,
      },

      tempClient: {},
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "InvoiceGather"
      ),
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "InvoiceGather",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          // this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.initTableData();
      });
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      this.loading = true;
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        type: this.tabindex == 0 ? "一般纳税人" : "小规模纳税人", // 一般纳税人  小规模纳税人
        currentPage: this.pageData.currentPage, // 当前页数 20210907 改
        pageSize: this.pageData.pageSize, // 每页条数 20210907 改
        search: this.searchData.input,
      };
      this.$request(
        "post",
        "/invoice/getInvoiceGatherTbaleData",
        requestData,
        (data) => {
          this.pageData.total = data.total;
          this.tableData = invoiceData.getInvoiceGatherTableData(
            data.tableData
          );
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 切换卡片
     */
    changeTab(index) {
      this.tabindex = index;
      this.initTableData();
    },
    handleClick(data, flag) {},
    /**
     * 表格的行点击事件
     */
    tableClick(row, column, event) {
      console.log("行点击事件", row);
      this.tempClient = row;
      this.openDrawer();
    },
    /**
     * 打开抽屉事件
     */
    openDrawer() {
      let requestData = {
        clientId: this.tempClient.clientId,
        inOrOut: "1",
      };
      this.$request(
        "post",
        "/invoiceInfo/getInvoiceListByClientId",
        requestData,
        (data) => {
          let tt = {
            num: this.tempClient.personalTaxNum,
            money: this.tempClient.personalTaxMoney,
          };
          this.invoiceInfoDrawerData = invoiceData.getInComeInvoiceDrawerData(
            data,
            this.tempClient.clientName,
            tt
          );
          this.invoiceInfoDrawerData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 发票信息预览
     */
    linkNow(data) {
      console.log("发票详细信息:", data);
      let requestData = {
        id: data.item.id, // 具体发票的ID
      };
      this.$request(
        "post",
        "/invoice/getInvoiceDetails",
        requestData,
        (data) => {
          this.dialogData.title = "发票预览";
          this.dialogData.width = "1300px";
          this.dialogData.buttonArray = [
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              type: "invoice",
              title: "",
              data: invoiceData.getInvoiveDetails(data),
            },
          ];
          this.dialogData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 表格操作
     */
    tableOper(data, flag) {
      let requestData = {};
      switch (flag) {
        case 0:
          // 录票
          requestData = {
            id: data.clientId,
          };
          this.$request(
            "post",
            "/client/getClientInfoByClientID",
            requestData,
            (clientData) => {
              this.dialogData.width = "1300px";
              this.dialogData.title = "进项发票录入";
              this.dialogData.buttonArray = [
                {
                  text: "确定",
                  active: "makesure",
                },
                {
                  text: "取消",
                  active: "cancel",
                },
              ];
              this.$set(clientData, "clientId", data.clientId);
              this.dialogData.data = [
                {
                  type: "invoice_income_dialog",
                  title: "",
                  data: invoiceData.getInvoiceDialogData(
                    clientData,
                    this.baseInfo.company.companyId,
                    "",
                    false
                  ),
                },
              ];
              this.dialogData.flag = true;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;

        default:
          break;
      }
    },
    /**
     * 弹出框操作事件
     */
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "makesure":
          // 确定录入进项发票
          console.log("发票录入成功:", this.dialogData.data[0].data);
          this.saveInvoiceDetails();
          break;
        case "cancel":
          // 关闭
          this.dialogData.flag = false;
          break;

        default:
          break;
      }
    },

    /**
     * 保存发票
     */
    saveInvoiceDetails() {
      let requestData = this.dialogData.data[0].data;
      this.$request(
        "post",
        "/invoice/saveInvoiceDetails",
        requestData,
        (data) => {
          // this.tempInvoiceId = data.id;
          this.dialogData.flag = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 保存抽屉数据
     */
    saveDrawerData(drawerTitle) {
      let requestData = {
        clientId: this.tempClient.clientId, // 客户ID
        num:
          this.invoiceInfoDrawerData.data.modelList[0].line[0].type == "input"
            ? this.invoiceInfoDrawerData.data.modelList[0].line[0].value
            : "", // 个税人数
        money:
          this.invoiceInfoDrawerData.data.modelList[0].line[0].type == "input"
            ? this.invoiceInfoDrawerData.data.modelList[0].line[1].value
            : "", // 个税金额
      };

      console.log("保存抽屉数据:", requestData);
      this.$request(
        "post",
        "/invoice/saveIndividualTaxDataByClientId",
        requestData,
        (data) => {
          this.$message({
            message: "操作成功!",
            type: "success",
            duration: 1000,
          });
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 弹出框关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 右边抽屉父子组件连锁事件
     */
    showDrawer(value) {
      this.invoiceInfoDrawerData = value;
    },
    /**
     * 右边抽屉关闭事件
     */
    closeUpdateTableDrawer() {
      this.invoiceInfoDrawerData.flag = false;
      // this.initTableData();
      // this.saveDrawerData();
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
  },
  mounted() {
    this.loading = true;
    this.isCharge();
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#invoice-gather {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .invoice-gather-screen-fiftrate {
    width: 65px;
    min-width: 65px;
    height: 30px;
    border: 1px solid @boderColor;
    box-sizing: border-box;
    color: @boderActiveColor;
    font-size: @contentFontSize;
    margin: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    transition: 0.3s;
    i {
      margin: 0 4px;
    }
  }
  .invoice-gather-screen-fiftrate:hover {
    cursor: pointer;
    border-color: @boderActiveColor;
  }
  .invoice-gather-screen-search {
    width: 210px;
    min-width: 210px;
    height: 30px;
  }
  .invoice-gather-screen-checked {
    width: 140px;
    height: 30px;
    min-width: 140px;
    font-size: @contentFontSize;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .invoice-gather-screen-active {
    flex: 1;
  }
  .invoice-gather-screen-but-select {
    width: 140px;
    min-width: 160px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @activeColor;
    font-size: @contentFontSize;
    color: #fff;
    margin: 0 5px;
    border-radius: 4px;
    transition: 0.5s;
    cursor: pointer;
    .invoice-gather-screen-but-select_but {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.5s;
    }
    .invoice-gather-screen-but-select_but:hover {
      background-color: @activeColor2;
    }
    .invoice-gather-screen-but-select_select {
      width: 30px;
      height: 100%;
      border-left: 1px solid @activeColor3;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .invoice-gather-screen-but {
    width: 100px;
    min-width: 100px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @activeColor;
    font-size: @contentFontSize;
    color: #fff;
    margin: 0 5px;
    border-radius: 4px;
    transition: 0.5s;
  }
  .invoice-gather-screen-but:hover {
    cursor: pointer;
    background-color: @activeColor2;
  }
  .invoice-gather-screen-but-boder {
    width: 50px;
    min-width: 50px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid @activeColor;
    font-size: @contentFontSize;
    background-color: @activeBackground;
    color: @activeColor;
    margin: 0 5px;
    margin-right: 10px;
    border-radius: 4px;
    transition: 0.5s;
  }
  .invoice-gather-screen-but-boder:hover {
    cursor: pointer;
    background-color: @activeBackground2;
  }
  .invoice-table-content {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;

    .invoice-gather-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;

      .invoice-gather-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .invoice-gather-table-card-tab {
          width: 120px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .invoice-gather-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .invoice-gather-table-card-active {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: @contentFontSize;
          .textArea {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: @activeColor;
            cursor: pointer;
            &:hover {
              text-decoration: underline; // 下划线
            }
          }
          .numArea {
            height: 100%;
            padding-top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            color: @activeColor;
          }
        }
        .invoice-gather-table-card-edit {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: @editColor;
          cursor: pointer;
        }
      }
      .invoice-gather-content {
        flex: 1;
        width: 98%;
      }

      .invoice-gather-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }
}
</style>
