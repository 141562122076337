<template>
  <div id="expressage-setting">
    设置
  </div>
</template>

<script>
export default {};
</script>

<style></style>
