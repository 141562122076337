var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"knowledge_setting"}},[_c('div',{staticClass:"screen"},[_c('div',{staticClass:"screen-line"},[_c('div',{staticClass:"recyle-setting-screen-tabs"},_vm._l((_vm.tabList),function(item,index){return _c('div',{key:index,class:{
            'recyle-setting-screen-tab': true,
            'is-active': _vm.tabIndex == item.text,
          },on:{"click":function($event){return _vm.changeTab(item, index)}}},[_vm._v(" "+_vm._s(item.text)+" ")])}),0)])]),_c('div',{staticClass:"table-content"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"knowledge-table-card"},[(_vm.tabIndex == '选项值设置')?_c('div',{staticClass:"knowledge-table-card-content"},[_c('div',{staticClass:"knowledge-table-card-content-tags-setting"},[_c('div',{staticClass:"knowledge-table-card-content-tags-setting-left"},_vm._l((_vm.optionValueList1),function(item,index){return _c('div',{key:index,class:{
                'knowledge-table-card-content-tags-setting-left-line': true,
                'is-active': index == _vm.tagsIndex,
              },on:{"click":function($event){_vm.tagsIndex = index}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('div',{staticClass:"knowledge-table-card-content-tags-setting-right"},[_c('div',{staticClass:"knowledge-table-card-content-tags-setting-right-add"},[_c('div',{directives:[{name:"showFunction",rawName:"v-showFunction",value:({
                  active: 'add',
                  array: _vm.allFunctionButtons,
                }),expression:"{\n                  active: 'add',\n                  array: allFunctionButtons,\n                }"}],staticClass:"butt",on:{"click":function($event){return _vm.addTags(1, -1)}}},[_vm._v(" 添加选项 ")])]),(_vm.optionValueList1[_vm.tagsIndex].value.length == 0)?_c('div',{staticClass:"knowledge-table-card-content-tags-setting-right-list"},[_vm._v(" 没有相关数据! ")]):_vm._e(),_c('div',{staticClass:"knowledge-table-card-content-tags-setting-right-list"},_vm._l((_vm.optionValueList1[_vm.tagsIndex].value),function(item,index){return _c('div',{key:index,staticClass:"knowledge-table-card-content-tags-setting-right-line"},[(item.flag)?_c('div',{on:{"click":function($event){_vm.tabList[0].flag == 2 ? '' : (item.flag = !item.flag)}}},[_vm._v(" "+_vm._s(index + 1)+"、"+_vm._s(item.name)+" ")]):_c('div',[_vm._v(" "+_vm._s(index + 1)+"、 "),_c('el-input',{attrs:{"placeholder":"请输入内容","disabled":_vm.tabList[0].flag == 2},on:{"blur":function($event){return _vm.addTags(3, index)}},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})],1)])}),0)])])]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }