<template>
  <div id="role-tree">
    <div class="div-select">
      <el-select
        v-model="company"
        placeholder="请选择"
        value-key="companyId"
        filterable
        @change="handeChange"
      >
        <el-option
          v-for="item in data.options"
          :key="item.companyId"
          :label="item.companyName"
          :value="item"
        >
        </el-option>
      </el-select>
    </div>
    <div class="chose-role">
      <el-tag
        v-for="tag in data.tags"
        :key="tag.id"
        closable
        @close="handleClose(tag)"
        >{{ tag.text }}</el-tag
      >
    </div>
    <div class="content">
      <div class="content-tree">
        <div class="tree-title">
          部门设置
          <span>右击查看角色</span>
        </div>
        <div class="div-tree">
          <div id="left_tree" ref="left_tree"></div>
        </div>
      </div>
      <div class="content-tree">
        <div class="tree-title">
          角色设置
          <span>右击选择角色</span>
        </div>
        <div class="div-tree">
          <div id="right_tree" ref="right_tree"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as systemAuthorityData from "../assets/js/systemAuthorityData.js";
export default {
  props: ["roleTreeData"],
  data() {
    return {
      baseInfo: {},
      data: this.roleTreeData,
      left_tree: "",
      right_tree: "",
      departmentTreeData: [],
      authorityTreeData: [],
      tempNode: {}, //点击节点时临时记录的节点内容
      tempDepartment: {}, //当前点击的部门
      headNode: false, //点击节点时,判断是否是头结点
      tempCompany: {
        id: "",
        tags: [],
      }, // 选择角色时存储的临时公司
      company: {},
    };
  },
  watch: {
    roleTreeData() {
      //父组件值变化->>子组件也变化
      this.data = this.roleTreeData;
    },
    data(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {
    /**
     * 获取部门树
     */
    handeChange(val) {
      this.tempCompany.id = val.companyId;
      this.tempCompany.tags = [];
      this.getDepartmentTreeData(val.companyId);
    },
    /**
     * 获取部门树数据
     */
    getDepartmentTreeData(companyId) {
      let requestData = {
        companyId: companyId,
      };
      this.$request(
        "post",
        "/setting/getDepartmentTree",
        requestData,
        (data) => {
          this.departmentTreeData = [data];
          this.showDepartmentTree(this.departmentTreeData);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取角色树
     */
    getAuthorityTreeData(departmentId) {
      let requestData = {
        userId: this.baseInfo.userId,
        departmentId: departmentId,
      };
      this.$request(
        "post",
        "/setting/getAuthorityTree",
        requestData,
        (data) => {
          this.authorityTreeData = [data];
          this.showAuthorityTree(this.authorityTreeData);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 显示左边部门树
     */
    showDepartmentTree(treeData) {
      this.left_tree = this.$echarts.init(this.$refs.left_tree);
      let options = systemAuthorityData.getTreeOptions(treeData);
      this.left_tree.clear();
      this.left_tree.setOption(options, true);
      let myChartContainer = document.getElementById("left_tree");
      let _this = this;
      this.left_tree.on("contextmenu", function (params) {
        event.preventDefault();
        _this.tempNode = params.data;
        _this.tempDepartment = params.data;
        if (params.dataIndex == 1) {
          _this.headNode = true;
        } else {
          _this.headNode = false;
          _this.getAuthorityTreeData(params.data.id);
        }
      });
    },
    /**
     * 显示右边角色树
     */
    showAuthorityTree(treeData) {
      this.right_tree = this.$echarts.init(this.$refs.right_tree);
      let options = systemAuthorityData.getTreeOptions(treeData);
      this.right_tree.clear();
      this.right_tree.setOption(options, true);
      let myChartContainer = document.getElementById("right_tree");
      let _this = this;
      this.right_tree.on("contextmenu", function (params) {
        event.preventDefault();
        _this.tempNode = params.data;
        if (params.dataIndex == 1) {
          _this.headNode = true;
        } else {
          _this.headNode = false;
          let dd = {
            id: params.data.id,
            text: params.data.name,
          };
          if (
            !_this.data.tags.some((item) => {
              if (item.id == dd.id) {
                return true;
              }
            })
          ) {
            _this.data.tags.push(dd);
            let cc = {
              companyId: _this.tempCompany.id,
              roleId: dd.id,
            };
            _this.data.company.push(JSON.parse(JSON.stringify(cc)));
          }
        }
      });
    },
    handleClose(tag) {
      this.data.tags.splice(this.data.tags.indexOf(tag), 1);
      let index = -1;
      this.data.company.forEach((element, i) => {
        if (element.roleId == tag.id) {
          index = i;
        }
      });
      this.data.company.splice(index, 1);
    },
  },
  mounted() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    // this.getDepartmentTreeData();
  },
};
</script>

<style lang="less" scoped>
#role-tree {
  width: 100%;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  .div-select {
    width: 100%;
    height: 30px;
    margin-bottom: 3px;
  }
  .chose-role {
    width: 100%;
    height: 60px;
    display: flex;
    flex-wrap: wrap;
    .el-tag {
      margin: 3px;
    }
  }
  .content {
    width: 98%;
    height: 0;
    flex: 1;
    display: flex;
    .content-tree {
      flex: 1;
      width: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      // overflow: hidden;
      &:nth-child(1) {
        // background-color: antiquewhite;
        border-right: 3px solid @contentBackground;
      }
      &:nth-child(2) {
        // background-color: rgb(161, 206, 0);
      }
      .tree-title {
        width: 100%;
        display: flex;
        align-items: center;
        height: 50px;
        background-color: #fff;
        font-size: @contentFontSize3;
        padding-left: 20px;
        box-sizing: border-box;
        border-bottom: 1px solid @activeBackground2;
        span {
          height: 50%;
          display: flex;
          align-items: flex-end;
          color: @tipColor;
          font-size: @contentFontSize;
          margin-left: 10px;
        }
      }
      .div-tree {
        flex: 1;
        height: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        // overflow: auto;
        // background-color: aquamarine;
        // &::-webkit-scrollbar {
        //   width: 1px;
        // }
        #left_tree,
        #right_tree {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
</style>