<template>
  <div id="human_setting">
    <!-- 人才设置界面 -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped>
#human_setting {
  width: 100%;
  height: 100%;
}
</style>
