<template>
  <!-- 项目记录 -->
  <div id="project-record">
    <div class="project-record-title">
      项目记录
    </div>
    <div class="project-record-textarea">
      <textarea
        class="textarea"
        v-model="textarea"
        rows="5"
        :maxlength="200"
        placeholder="请在此处添加记录"
      ></textarea>
      <div class="project-record-button" @click="addProjectRecord">
        添加
      </div>
    </div>
    <div class="project-record-list">
      <div
        class="project-record-item"
        v-for="(item, index) in projectRecordData"
        :key="index"
      >
        <div class="project-record-item-title">
          <div class="icon">
            <img :src="item.userIcon" alt="" />
            <div>{{ item.userName }}</div>
          </div>
          <div>
            {{ item.gmtCreate }}
          </div>
        </div>
        <div class="project-record-item-content">
          {{ item.content }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["projectRecordData"],
  data() {
    return {
      textarea: "",
    };
  },
  methods: {
    addProjectRecord() {
      this.$emit("addProjectRecord", this.textarea);
      this.textarea = "";
    },
  },
  mounted() {
    console.log("this.projectRecordData", this.projectRecordData);
  },
};
</script>

<style lang="less" scoped>
#project-record {
  width: 100%;
  .project-record-title {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    font-size: 16px;
    border-bottom: 1px solid #d1d3d8;
    padding-left: 15px;
    box-sizing: border-box;
  }
  .project-record-textarea {
    position: relative;
    width: 100%;
    height: 25%;
    padding: 10px;
    box-sizing: border-box;
    .textarea {
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
      border: 1px solid #d1d3d8;
      outline-color: #d1d3d8; // 鼠标聚焦边框颜色
      resize: none;
    }

    .project-record-button {
      position: absolute;
      bottom: 20px;
      right: 20px;
      padding: 5px 20px;
      font-size: 12px;
      border-radius: 3px;
      color: @baseColor;
      border: 1px solid @baseColor;
      &:hover {
        background-color: #f2f7ff;
        cursor: pointer;
      }
    }
  }
  .project-record-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    box-sizing: border-box;
    .project-record-item {
      margin-bottom: 10px;
      border-bottom: 1px solid #d1d3d8;
      .project-record-item-title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .icon {
          display: flex;
          img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin-right: 10px;
          }
        }
      }
      .project-record-item-content {
        width: 85%;
        display: flex;
        padding: 5px 40px;
        box-sizing: border-box;
        text-align: left;
      }
    }
  }
}
</style>
