<template>
  <div id="follow-up-records">
    <div class="list">
      <div class="line" v-for="(item, index) in list" :key="index">
        <div class="icon">
          <img
            :src="item.icon"
            alt=""
            :style="{ width: '50px', height: '50px' }"
          />
        </div>
        <div class="content">
          <div class="name">{{ item.name }}</div>
          <div class="text">{{ item.content }}</div>
          <div class="stage" v-if="item.stage">
            <span>跟进阶段:</span>{{ item.stage }}
          </div>

          <div
            class="time"
            v-if="item.model == 'archives' || item.model == 'tax'"
          >
            <span>操作时间:</span>{{ item.time }}
          </div>
          <div class="time" v-else>
            <span>下次联系时间:</span>{{ item.time }}
          </div>
        </div>
        <div class="time">
          {{ item.operTime }}
        </div>
      </div>
    </div>
    <div class="input_data" v-if="hasContent">
      <div class="line">
        <el-input
          type="textarea"
          :rows="3"
          placeholder="请输入跟进内容"
          v-model="activeContent"
        >
        </el-input>
      </div>
      <div class="line" v-if="hasPhase">
        <div class="title">跟进阶段:</div>
        <div class="value">
          <el-select
            v-model="activePhase"
            filterable
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in phaseList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="line">
        <div class="title">下次联系时间:</div>
        <div class="value">
          <el-date-picker
            v-model="activeTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择下次联系时间"
            clearable
          >
          </el-date-picker>
        </div>
      </div>
      <div class="line" v-if="hasTarget">
        <div class="title">领取目标:</div>
        <div class="value">
          <el-radio-group v-model="activeTarget">
            <el-radio
              v-for="(item, index) in targetList"
              :key="index"
              :label="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </div>
      </div>
      <div class="line" v-if="hasTarget">
        <div class="title">微信待加:</div>
        <div class="value">
          <el-radio-group v-model="activeWeChatFriend">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>
    <div class="buts" v-if="hasContent">
      <div
        class="but"
        @click="addFollowRecord"
        v-showFunction="{ active: 'cancel', array: allFunctionButtons }"
      >
        <!-- v-showFunction="{ active: 'followUp', array: allFunctionButtons }" -->
        确定
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    allFunctionButtons: {
      type: Array,
      default: function() {
        return [];
      },
    },
    hasContent: {
      type: Boolean,
      defalut: function() {
        return true;
      },
    },
    content: {
      // 本次跟进内容
      type: String,
      default: function() {
        return "";
      },
    },
    time: {
      // 下次跟进时间
      type: String,
      default: function() {
        return;
      },
    },
    list: {
      // 跟进列表
      type: Array,
      defailt: function() {
        return [];
      },
    },
    hasTarget: {
      // 是否有目标(在资源界面跟进时使用)
      type: Boolean,
      default: function() {
        return false;
      },
    },
    target: {
      // 目标 默认商机池  选项值:商机池 我的商机
      type: String,
      default: function() {
        return "商机池";
      },
    },
    hasPhase: {
      // 是否有跟进阶段 在商机模块使用
      type: Boolean,
      default: function() {
        return false;
      },
    },
    phase: {
      // 跟进阶段
      type: String,
      default: function() {
        return "";
      },
    },
    addWeChatFriend: {
      // 跟进阶段
      type: Boolean,
      default: function() {
        return false;
      },
    },
  },
  data() {
    return {
      phaseList: [], // 跟进阶段列表
      baseInfo: {},
      targetList: [
        {
          label: "号码错误",
          value: "号码错误",
        },
        {
          label: "同行电话",
          value: "同行电话",
        },
        {
          label: "态度恶劣",
          value: "态度恶劣",
        },
        {
          label: "风险号码",
          value: "风险号码",
        },
        {
          label: "没有意向",
          value: "没有意向",
        },
        {
          label: "我的商机",
          value: "我的商机",
        },
      ],
      activeContent: this.content,
      activeTime: this.time,
      activeTarget: this.target,
      activePhase: this.phase,
      activeWeChatFriend: this.addWeChatFriend,
    };
  },
  watch: {
    content() {
      this.activeContent = this.content;
    },
    time() {
      this.activeTime = this.time;
    },
    target() {
      this.activeTarget = this.target;
    },
    phase() {
      this.activePhase = this.phase;
    },
    addWeChatFriend() {
      this.activeWeChatFriend = this.addWeChatFriend;
    },
  },
  methods: {
    // 获取跟进记录标签
    getTagList() {
      this.phaseList = [];
      let requestData = {
        type: ["跟进阶段"],
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/getTagList",
        requestData,
        (data) => {
          if (data.length > 0) {
            this.phaseList = data[0].value;
          }
        },
        (err) => {
          this.$message.error(err);
        }
      );
    },
    /**
     * 确定添加跟进记录
     */
    addFollowRecord() {
      let data = {
        content: this.activeContent,
        time: this.activeTime,
        target: this.activeTarget,
        phase: this.activePhase,
        weChatFriend: this.activeWeChatFriend,
      };
      this.$emit("addFollowRecord", JSON.parse(JSON.stringify(data)));
      this.activePhase = "";
      this.activeContent = "";
      this.activeTime = "";
      this.activeTarget = "商机池";
      this.activeWeChatFriend = 0;
    },
  },
  mounted() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getTagList();
  },
};
</script>

<style lang="less" scoped>
#follow-up-records {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  > .list {
    width: 100%;
    height: auto;
    max-height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    > .line {
      width: 100%;
      height: auto;
      // min-height: 60px;
      // max-height: none;
      display: flex;
      margin-bottom: 15px;
      > .icon {
        width: 60px;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding-top: 5px;
        box-sizing: border-box;
        > img {
          width: 50px;
          height: 50px;
          border-radius: 25px;
        }
      }
      > .content {
        width: 0;
        flex: 1;
        height: auto;
        display: flex;
        flex-direction: column;
        > .name {
          width: 100%;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 14px;
          color: #000;
          font-weight: bold;
        }
        > .text {
          width: 100%;
          height: auto;
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          justify-content: flex-start;
          font-size: 13px;
          color: rgb(49, 49, 49);
          text-align: left;
        }
        > .stage,
        > .time {
          width: 100%;
          height: 28px;
          display: flex;
          align-items: baseline;
          justify-content: flex-start;
          font-size: 13px;
          color: rgb(105, 105, 105);
          > span {
            font-weight: bold;
            color: rgb(49, 49, 49);
          }
        }
      }
      > .time {
        width: auto;
        height: 100%;
        display: flex;
        align-items: flex-start;
        font-size: 13px;
        color: #000;
        vertical-align: middle;
      }
    }
  }
  > .input_data {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    box-sizing: border-box;
    > .line {
      width: 100%;
      height: auto;
      padding: 5px 10px;
      display: flex;
      box-sizing: border-box;
      > .title {
        width: 20%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        padding-right: 5px;
        box-sizing: border-box;
        font-size: 13px;
      }
      > .value {
        width: 0;
        flex: 1;
        height: 100%;
        display: flex;
        align-items: flex-start;
      }
    }
  }
  > .buts {
    width: 100%;
    height: 30px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    > .but {
      width: auto;
      height: auto;
      padding: 5px 20px;
      border-radius: 5px;
      background-color: rgb(55, 169, 235);
      color: #ffffff;
      font-size: 13px;
      box-sizing: border-box;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        background-color: rgb(17, 104, 155);
      }
    }
  }
  .el-radio-group {
    display: flex;
    flex-wrap: wrap;
    .el-radio {
      height: 25px;
      margin-top: 3px;
    }
  }
}
</style>
