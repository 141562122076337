// 地址自动匹配
export function addressAutomaticallyMatch(address) {
    var arr = []
    var reg = /.+?(省|市|自治区|自治州|县|区|區)/g

    if (address.indexOf("台湾") !== -1) {
        // 台湾地区只有一级地址 台湾省
        arr = ["台湾省"]
        return arr
    }

    arr = address.match(reg)
    console.log(arr, 'ppppppppppp');

    if (arr != null && arr.length > 0) {
        // 北京,天津,上海直辖市,第二级为市辖区
        if (arr[0].indexOf("北京" !== -1 || arr[0].indexOf("天津") !== -1 || arr[0].indexOf("上海") !== -1)) {
            if (arr.length > 1) {
                var temp = arr[1]
                console.log(arr, temp);
                if (temp.indexOf("北京") !== -1 || temp.indexOf("天津") !== -1 || temp.indexOf("上海") !== -1) {
                    arr[1] = "市辖区"
                } else if (temp.indexOf("市辖区") !== -1) {
                    if (arr.length > 2) {
                        arr[1] = "市辖区"
                        arr[2] = temp
                        console.log(arr, temp);
                    }
                }
            }
        }

        // 重庆 第二级是 区或者县
        if (arr[0].indexOf("重庆") !== -1) {
            arr = address.match(/.+?(省|市|乡|县|镇|街道|区)/g)
        }
    }
    console.log(arr + "'''''''''''''");
    return arr
}