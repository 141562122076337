<template>
  <div id="bind-client-applet">
    <div class="bind-client-applet-context" ref="applet_context">
      <div
        class="bind-client-applet-line"
        v-for="(item, index) in value"
        :key="index"
      >
        <div class="bind-client-applet-line-span">账号{{ index + 1 }}</div>
        <div class="bind-client-applet-line-input">
          <el-input v-model="item.name" placeholder="姓名"></el-input>
        </div>
        <div class="bind-client-applet-line-input">
          <el-input v-model="item.phone" placeholder="手机号"></el-input>
        </div>
      </div>
    </div>
    <div class="bind-client-applet-line">
      <div class="bind-client-applet-line-but" @click="addCount">
        + 添加账号
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["bindClientAppletData"],
  data() {
    return {
      value: this.bindClientAppletData,
      //   value: [
      //     {
      //       phone: "12345",
      //       name: "张三",
      //     },
      //   ],
    };
  },
  watch: {
    bindClientAppletData() {
      //父组件值变化->>子组件也变化
      this.value = this.bindClientAppletData;
    },
    value(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {
    addCount() {
      this.value.push({
        name: "",
        phone: "",
      });
      //   自动定位到滚动条底部
      this.$nextTick(() => {
        this.$refs.applet_context.scrollTop = this.$refs.applet_context.scrollHeight;
      });
    },
  },
  mounted() {
    while (this.value.length < 3) {
      let dd = {
        phone: "",
        name: "",
      };
      this.value.push(dd);
    }
    console.log("初始化value", this.value);
  },
};
</script>

<style lang="less" scoped>
#bind-client-applet {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  //   max-height: 300px;
  //   overflow-y: auto;
  .bind-client-applet-context {
    width: 100%;
    height: auto;
    display: flex;
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0px;
    }
  }
  .bind-client-applet-line {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    min-height: 40px;
    .bind-client-applet-line-span {
      width: 50px;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 13px;
    }
    .bind-client-applet-line-input {
      width: 0;
      height: 100%;
      flex: 1;
      margin: 0 3px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .bind-client-applet-line-but {
      width: 150px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      color: @activeColor;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
</style>