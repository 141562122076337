<template>
  <!-- 话单列表 -->
  <div id="call-list">
    <div class="call-list-screen">
      <div class="call-list-screen-line">
        <div class="call-list-screen-line-fiftrate">
          <el-popover
            placement="bottom-start"
            width="250"
            trigger="manual"
            :visible-arrow="false"
            v-model="popoData.popoVisible"
          >
            <div class="popover-search">
              <popo_search
                :list="popoData.list"
                @cancel="popoCanael"
                @reset="popoReset"
                @makeSure="popoMakeSure"
              ></popo_search>
            </div>
            <div slot="reference" @click="popoverOpen">
              筛选 <i class="el-icon-s-operation"></i>
            </div>
          </el-popover>
        </div>
        <div class="call-list-screen-search">
          <el-cascader
            v-model="searchData.department"
            placeholder="请选择部门"
            :options="searchData.departTreeData"
            @change="initTableData"
            clearable
            :show-all-levels="false"
            :props="{ checkStrictly: true, value: 'id' }"
            filterable
          ></el-cascader>
        </div>
        <div class="call-list-screen-search">
          <el-select
            v-model="searchData.userId"
            @change="selectInitTableData"
            filterable
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="call-list-screen-search">
          <el-input
            v-model="searchData.bindId"
            placeholder="请输入绑定ID"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="call-list-screen-search">
          <el-input
            v-model="searchData.TelA"
            placeholder="请输入TelA"
            @change="initTableData"
          ></el-input>
        </div>
        <div class="call-list-screen-search">
          <el-input
            v-model="searchData.TelB"
            placeholder="请输入TelB"
            @change="initTableData"
          ></el-input>
        </div>
        <div class="call-list-screen-active"></div>
        <div style="background-color: #ECF5FF;  margin: 0 15px;">
          <el-dropdown>
            <el-button type="small" class="drop-button">
              批量操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item @click.native="handleClick('', 1)"
                >批量下载录音</el-dropdown-item
              > -->
              <el-dropdown-item @click.native="handleClick('', 2)"
                >批量下载话单列表</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div
        class="call-list-screen-line"
        v-if="searchData.screenList.length > 0"
      >
        <div class="title">筛选条件:</div>
        <el-tag
          v-for="(tag, index) in searchData.screenList"
          :key="index"
          closable
          size="small"
          @close="closeScreen(index)"
        >
          {{ tag.name }}
        </el-tag>
      </div>
    </div>

    <div class="table-content">
      <div class="call-list-table-card">
        <div class="call-list-content">
          <el-table
            :data="tableData"
            highlight-current-row
            style="width: 100%, overflow-x:auto"
            height="98%"
            v-loading="loading"
            row-key="id"
            :row-style="{ height: '60px' }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="30"></el-table-column>
            <el-table-column type="index" width="40"></el-table-column>
            <el-table-column
              prop="bindId"
              label="话单ID"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column prop="name" label="用户"></el-table-column>
            <!-- <el-table-column prop="" label="通话类型"></el-table-column> -->
            <el-table-column width="200" label="号码">
              <template slot-scope="scope">
                <el-row>
                  <el-col>TelA: {{ scope.row.telA }} </el-col>
                  <el-col>TelX: {{ scope.row.telX }} </el-col>
                  <el-col>TelB: {{ scope.row.telB }} </el-col>
                </el-row>
              </template>
            </el-table-column>
            <el-table-column width="250" label="通话时间">
              <template slot-scope="scope">
                <el-row>
                  <el-col>开始时间: {{ scope.row.beginTime }} </el-col>
                  <el-col>结束时间: {{ scope.row.endTime }} </el-col>
                </el-row>
              </template>
            </el-table-column>
            <el-table-column label="通话时长">
              <template slot-scope="scope">
                {{ scope.row.duration }}
              </template>
            </el-table-column>
            <el-table-column prop="status" label="通话状态">
              <template slot-scope="scope">
                <div :style="{ color: scope.row.color }">
                  {{ scope.row.status == "1" ? "接通" : "未接通" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="" label="录音" width="200">
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.recordFileUrl"
                  type="primary"
                  size="mini"
                  @click="handleClick(scope.$index, 0)"
                  >播放</el-button
                >
                <span v-else>无录音</span>
                <audio
                  style="width: 260px"
                  :src="scope.row.recordFileUrl"
                  :ref="'audio' + scope.$index"
                ></audio>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="call-list-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import * as report from "./js/report";
import my_drawer from "@/components/my-drawer.vue";
import my_dialog from "@/components/dialog.vue";
import popo_search from "@/components/popo-search.vue";
export default {
  components: {
    my_drawer,
    my_dialog,
    popo_search,
  },
  data() {
    return {
      baseInfo: {},
      whetherAgencyHead: false,
      loading: false,
      allFunctionButtons: this.$featureData.getAllFunctionButtons("callList"),
      tableData: [],
      searchData: {
        userList: [],
        userIdList: [],
        userId: "",
        screenList: [],
        time: [],
        bindId: "",
        TelA: "",
        TelB: "",
        tempUserId: [],
        department: "",
        departTreeData: [],
      },
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "新增档案",
        buttonArray: [
          {
            text: "确认",
            active: "confirm",
          },
        ],
        data: [],
      },
      popoData: {
        popoVisible: false,
        list: report.callListSearchData(),
      },
      tempIds: [],
      multipleSelection: [],
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "callList",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getDepartmentTreeByUserId();
      });
    },

    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.searchData.userList = [];
          data.forEach((element) => {
            let dd = {
              name: element.userName,
              id: element.userId,
            };
            this.searchData.userIdList.push(element.userId);
            this.searchData.userList.push(dd);
          });
          this.searchData.userList.splice(0, 0, {
            id: "all",
            name: "全部人员",
          });
          this.searchData.userId = "all";
          this.selectInitTableData("all");
        },

        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取部门
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
          this.getEmployeeList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取表格界面数据
     */
    initTableData() {
      this.loading = true;
      let requestData = {
        currentPage: this.pageData.currentPage, // 当前页
        pageSize: this.pageData.pageSize, // 每页条数
        bindId: this.searchData.bindId, // 绑定ID
        TelA: this.searchData.TelA, // TelA
        TelB: this.searchData.TelB, // TelB
        // callStatus: this.searchData.callStatus, // 通话状态
        screenList: this.searchData.screenList,
        userIdList: this.searchData.tempUserId, // 员工id集合
        department:
          this.searchData.department.length > 0
            ? this.searchData.department[this.searchData.department.length - 1]
            : "",
      };
      this.$request(
        "post",
        "/telBill/pageByCondition",
        requestData,
        (data) => {
          this.pageData.total = data.total;
          this.tableData = report.tableData(data.records);
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    /**
     * 界面操作
     */
    handleClick(data, flag) {
      let requestData = {};
      switch (flag) {
        case 0:
          // 播放录音
          if (this.$refs["audio" + data].paused) {
            // 播放
            this.$refs["audio" + data].play();
            // this.$refs["audio" + data].load();
            // window.location.href = this.$refs["audio" + data].src;
          } else {
            // 暂停
            this.$refs["audio" + data].pause();
          }
          break;

        case 1:
          // 批量下载录音
          break;

        case 2:
          // 批量下载话单列表
          if (this.multipleSelection.length == 0) {
            this.$message.error("请先选择要下载的话单!");
            return;
          }
          window.location.href =
            this.$baseurl +
            "/telBill/exportExcel?ids=" +
            this.tempIds.toString();
          break;

        default:
          break;
      }
    },
    /**
     * 抽屉数据或dialog数据操作事件
     */
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        default:
          break;
      }
    },

    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
      this.isEdit = false;
      this.isCopy = false;
    },

    /**
     * 筛选弹出框关闭事件
     */
    popoCanael() {
      this.popoData.popoVisible = false;
    },
    /**
     * 筛选弹出框重置
     */
    popoReset() {
      this.$systemBaseData.popoReset(this.popoData.list);
      this.searchData.screenList = [];
    },
    /**
     * 筛选弹出框显示
     */
    popoverOpen() {
      // console.log("所有数组数据:", this.arrayList);
      // this.popoData.list[0].options = []; // 领用人
      // this.popoData.list[0].options = this.searchData.allUserList.map(
      //   (item, index) => {
      //     return { label: item.userName, value: item.userId };
      //   }
      // );
      this.popoData.popoVisible = !this.popoData.popoVisible;
    },
    /**
     * 筛选弹出框确认事件
     */
    popoMakeSure() {
      this.searchData.screenList = this.$systemBaseData.remakesureSearchData(
        this.popoData.list
      );
      this.popoData.popoVisible = false;
      this.initTableData();
    },
    /**
     * 关闭筛选标签事件
     */
    closeScreen(index) {
      this.searchData.screenList.splice(index, 1);
      this.initTableData();
    },
    /**
     * 员工选择
     */
    selectInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.searchData.userIdList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.initTableData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.tempIds = this.multipleSelection.map((item) => item.id);
    },
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#call-list {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .call-list-screen {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    .call-list-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      > .title {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 0 10px;
      }
      > .el-tag {
        margin: 0 5px;
      }
      .call-list-screen-line-fiftrate {
        width: 65px;
        min-width: 65px;
        height: 30px;
        border: 1px solid @boderColor;
        box-sizing: border-box;
        color: @boderActiveColor;
        font-size: @contentFontSize;
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: 0.3s;
        i {
          margin: 0 4px;
        }
      }
      .call-list-screen-line-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }
      .call-list-screen-search {
        width: 210px;
        min-width: 210px;
        height: 30px;
        margin-left: 10px;
      }
      .call-list-screen-active {
        flex: 1;
      }
      .drop-button {
        background-color: #ecf5ff;
        color: #409eff;
        transition: 0.5s;

        &:hover {
          background-color: #abbed3;
        }
      }
      .archives-screen-but-select {
        padding: 0px 52px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
        cursor: pointer;
      }
    }
  }

  .table-content {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;

    .call-list-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .call-list-content {
        width: 100%;
        height: 95%;
        max-width: 90vw;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
      }
      .call-list-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
