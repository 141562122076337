<template>
  <div id="advances-details"></div>
</template>

<script>
export default {
  props: ["advancesDetailsData"],
  data() {
    return {
      value: this.advancesDetailsData,
    };
  },
  watch: {
    advancesItemData() {
      //父组件值变化->>子组件也变化
      this.value = this.advancesItemData;
    },
    value(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("showAdvances", newvalue);
    },
  },
};
</script>

<style lang="less" scoped>
#advances-details {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>