
/**
 * 获取抽屉 对应 tab 数据
 * @returns 
 */
export function getCompanyDrawerTabData(tab) {
    let data = {}
    switch (tab) {
        case '待办':
            data = {
                tableMenu: [
                    {
                        prop: "name",
                        label: "待办名称",
                    },
                    {
                        prop: "address",
                        label: "待办地点",
                    },
                    {
                        prop: "time",
                        label: "待办时间",
                    },
                ],
                tableData:[
                    {
                        name: "待办1",
                        address: "待办地点1",
                        time: "待办时间1"
                    },
                    {
                        name: "待办1",
                        address: "待办地点1",
                        time: "待办时间1"
                    }
                ],
                tableButton: [
                    {
                        name: "查看",
                        type: 'primary'
                    },
                    {
                        name: "编辑",
                        type: 'success'
                    }
                ]
            }
          break;
        case '发票':
            data = {
                searchData: [
                    {
                        label: "购买方名称",
                        placeholder: '请输入购买方名称',
                        key: 'buyerName',
                        type: "input",
                        value: ""
                    },
                    {
                        label: "发票类型",
                        placeholder: '请选择发票类型',
                        key: 'type',
                        type: "select",
                        value: "",
                        options: [
                            {
                                label: "数电普票",
                                value: "82",
                            },
                            { 
                              label: "数电专票",
                              value: "81",
                            },
                            {
                              label: "数电纸普",
                              value: "86",
                            },
                            {
                              label: "数电纸专",
                              value: "85",
                            },
                        ]
                    },
                ],
                tableMenu: [
                    {
                        prop: "buyerName",
                        label: "购买方名称",
                        width: '200'
                    },
                    {
                        prop: "status",
                        label: "状态",
                        width: '100'
                    },
                    {
                        prop: "type",
                        label: "种类",
                        width: '100'
                    },
                    {
                        prop: "totalAmount",
                        label: "金额",
                        width: '100'
                    },
                    {
                        prop: "createUserName",
                        label: "创建人",
                        width: '100'
                    },
                    {
                        prop: "gmtCreate",
                        label: "创建日期",
                        width: '150'
                    },
                   
                ],
                tableData:[]
            }
          break;
        default:
          break;
    }
    data.pageData = {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 30, // 每页条数
    }
    return data
}

export function getCompanyDrawerTabList() {
    return [
        {
          label: "沟通记录",
          name: "沟通记录",
        },
        {
          label: "待办",
          name: "待办",
        },
        {
          label: "发票",
          name: "发票",
        },
        {
          label: "社保",
          name: "社保",
        },
        {
          label: "税收",
          name: "税收",
        },
        {
          label: "收入",
          name: "收入",
        },
        {
          label: "成本",
          name: "成本",
        },
      ]
}

export function getCompanyDrawerTabListStatus() {
    return [
        {
          label: "未处理",
          value: 0,
        },
        {
          label: "待办",
          value: 1,
        },
        {
          label: "发票",
          value: 2,
        },
        {
          label: "社保",
          value: 3,
        },
      ]
}