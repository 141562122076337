<template>
  <div id="create_thread_retrieve">
    <div class="create_thread_retrieve_input_line">
      <div class="create_thread_retrieve_input_line_span">商机名称:</div>
      <div class="create_thread_retrieve_input_line_value">
        <el-input v-model="data.threadName" placeholder="请输入名称"></el-input>
      </div>
      <div
        class="create_thread_retrieve_input_line_but1"
        @click="searchChangeThread"
      >
        检索
      </div>
    </div>

    <div
      class="create_thread_retrieve_input_line colum_line"
      v-if="data.list.length > 0"
    >
      <el-table
        :data="data.list"
        border
        style="width: 100%"
        height="80vh"
        :row-style="changeRowTextColor"
      >
        <el-table-column prop="threadName" label="商机名称">
          <template slot-scope="scope">
            <div class="hri" v-if="scope.row.clientFlag">
              <el-tooltip
                class="item"
                effect="light"
                content="已签约"
                placement="top"
              >
                <i class="el-icon-star-on"></i>
              </el-tooltip>
              {{ scope.row.threadName }}
            </div>
            <div v-else>
              {{ scope.row.threadName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="expirationTime" label="过期时间" width="160">
        </el-table-column>
        <el-table-column prop="followName" label="跟进人" width="160">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="70">
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row)"
              type="text"
              size="small"
              :disabled="scope.row.flag == '0'"
              >申领</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="create_thread_retrieve_input_line">
      <div
        class="create_thread_retrieve_input_line_but"
        @click="changeOrCreateThread"
      >
        商机创建
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["createThreadRetrieveData"],
  data() {
    return {
      data: this.createThreadRetrieveData,
      flag: false,
      // data: {
      //   threadName: "", // 商机名称
      //   contact: "", // 联系人
      //   phone: "", // 手机号

      //   tip: {
      //     // 检索出来的提示文字
      //     text: "该商机已存在与系统中!相关信息如下所示:", // 提示文字 必有
      //     clientName: "安徽梵文建设有限公司", // 已有的客户/商机名称
      //     owner: "汪志军", // 商机跟进联系人/客户经理/客户创建者
      //     contact: [
      //       {
      //         name: "何飞", // 联系人
      //         phone: "13805513099", // 手机号
      //       },
      //       {
      //         name: "何飞", // 联系人
      //         phone: "13805513099", // 手机号
      //       },
      //     ],
      //   },
      // },
    };
  },
  watch: {
    createThreadRetrieveData() {
      //父组件值变化->>子组件也变化
      this.data = this.createThreadRetrieveData;
    },
    data(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {
    changeOrCreateThread() {
      if (this.flag) {
        this.flag = false;
        this.$emit("changeOrCreateThread");
      } else {
        this.$message({
          message: "请先进行商机名称检索!",
          duration: 1000,
          type: "error",
        });
        return;
      }
    },
    searchChangeThread() {
      this.flag = true;
      this.$emit("searchChangeThread");
    },
    /**
     * 根据商机状态,修改行颜色
     */
    changeRowTextColor(data) {
      if (data.row.flag == 1) {
        return {
          color: "red",
          height: "65px",
        };
      } else {
        return {
          height: "65px",
        };
      }
    },
    /**
     * 表格操作事件
     */
    handleClick(data) {
      this.$emit("claimsFor", data);
    },
  },
};
</script>

<style lang="less" scoped>
#create_thread_retrieve {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  .create_thread_retrieve_input_line {
    width: 100%;
    height: auto;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    .create_thread_retrieve_input_line_span {
      width: 15%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: rgb(90, 90, 90);
    }
    .create_thread_retrieve_input_line_value {
      width: 0;
      height: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      //   justify-content: center;
      padding-left: 10px;
      .el-input,
      .el-input__inner {
        width: 320px !important;
      }
    }
    .create_thread_retrieve_input_line_but,
    .create_thread_retrieve_input_line_but1 {
      width: 90%;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      background-color: rgb(49, 188, 252);
      border-radius: 5px;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        background-color: rgb(42, 159, 212);
      }
    }
    .create_thread_retrieve_input_line_but1 {
      width: auto;
      height: auto;
      padding: 3px 10px;
      margin-right: 50px;
    }
    .create_thread_retrieve_input_line_tip {
      width: 100%;
      height: auto;
      line-height: 33px;
      display: flex;
      align-items: center;
      color: #ff0000;
      font-size: 12px;
    }
    .create_thread_retrieve_input_line_tip_line {
      width: 100%;
      height: auto;
      min-height: 40px;
      display: flex;
      align-items: center;
      font-size: 12px;
      .create_thread_retrieve_input_line_tip_line_span {
        width: 100px;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
      }
      .create_thread_retrieve_input_line_tip_line_value {
        width: 0;
        flex: 1;
        height: auto;
        min-height: 40px;
        display: flex;
        align-items: flex-start;
        .create_thread_retrieve_input_line_tip_line_value_contact {
          width: 100%;
          height: 45px;
          display: flex;
          align-items: flex-start;
          .create_thread_retrieve_input_line_tip_line_value_contact_value {
            flex: 1;
            width: 0;
            height: 100%;
            display: flex;
            align-items: flex-start;
            .create_thread_retrieve_input_line_tip_line_value_contact_span {
              width: 50px;
              height: 100%;
              display: flex;
              align-items: flex-start;
              justify-content: center;
            }
            .create_thread_retrieve_input_line_tip_line_value_contact_son_value {
              flex: 1;
              width: 0;
              height: 100%;
              display: flex;
              align-items: flex-start;
            }
          }
        }
      }
    }
  }
  .colum_line {
    flex-direction: column;
    padding: 10px;
    height: 70vh;
    overflow-y: auto;
    box-sizing: border-box;
  }
  .hri {
    display: flex;
    i {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 3px;
      font-size: 14px;
      // color: @activeColor;
    }
  }
}
</style>
