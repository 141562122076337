<template>
  <!-- 寄件 -->
  <div id="mail">
    <div class="mail-content">
      <div class="mail-row">
        <div class="mail-col-12">
          <div class="mail-col-title">
            <div class="mail-col-title-icon">
              <div class="mail-border-icon"></div>
              寄件人
              <span class="info"
                >下单后，系统将默认保存您上次填写的寄件人信息</span
              >
            </div>
          </div>
          <div class="mail-col-contain">
            <div class="mail-col-line">
              <div class="mail-col-line-title">
                <div class="delivery_detail-title-red">*</div>
                姓名
              </div>
              <div class="mail-col-line-value">
                <el-input
                  v-model="value.sender.name"
                  placeholder="请填写真实姓名"
                ></el-input>
              </div>
            </div>
            <div class="mail-col-line">
              <div class="mail-col-line-title">
                <div class="delivery_detail-title-red">*</div>
                电话
              </div>
              <div class="mail-col-line-value">
                <el-input
                  v-model="value.sender.phone"
                  placeholder="请输入电话号码"
                ></el-input>
              </div>
            </div>
            <div class="mail-col-line">
              <div class="mail-col-line-title">
                <div class="delivery_detail-title-red">*</div>
                省市区
              </div>
              <div class="mail-col-line-value">
                <area-select
                  v-model="value.sender.code"
                  type="all"
                  :level="2"
                  :data="pcaa"
                  placeholder="请选择地区"
                  @change="getPreFreightAndDeliverTime"
                ></area-select>
              </div>
            </div>
            <div class="mail-col-line">
              <div class="mail-col-line-title" style=" align-items: start;">
                <div class="delivery_detail-title-red">*</div>
                详细地址
              </div>
              <div class="mail-col-line-value">
                <el-input
                  v-model="value.sender.address"
                  type="textarea"
                  placeholder="请输入详细地址"
                  @change="getPreFreightAndDeliverTime"
                ></el-input>
              </div>
            </div>
            <div class="mail-col-line">
              <div class="mail-col-line-title">公司名称</div>
              <div class="mail-col-line-value">
                <el-input
                  v-model="value.sender.companyName"
                  placeholder="选填, 请填写公司名称"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
        <div class="mail-col-12">
          <div class="mail-col-title">
            <div class="mail-col-title-icon">
              <div class="mail-border-icon"></div>
              收件人
            </div>
            <div class="mail-col-title-address">
              <div @click="addressBook">地址薄</div>
              <span></span>
              <div @click="autoAddress">智能地址</div>
            </div>
          </div>

          <div class="mail-col-contain">
            <div class="mail-col-line">
              <div class="mail-col-line-title">
                <div class="delivery_detail-title-red">*</div>
                姓名
              </div>
              <div class="mail-col-line-value">
                <el-input
                  v-model="value.receiver.name"
                  placeholder="请填写真实姓名"
                ></el-input>
              </div>
            </div>
            <div class="mail-col-line">
              <div class="mail-col-line-title">
                <div class="delivery_detail-title-red">*</div>
                电话
              </div>
              <div class="mail-col-line-value">
                <el-input
                  v-model="value.receiver.phone"
                  placeholder="请输入电话号码"
                ></el-input>
              </div>
            </div>
            <div class="mail-col-line">
              <div class="mail-col-line-title">
                <div class="delivery_detail-title-red">*</div>
                省市区
              </div>
              <div class="mail-col-line-value">
                <area-select
                  v-if="flag"
                  v-model="value.receiver.code"
                  type="all"
                  :level="2"
                  :data="pcaa"
                  placeholder="请选择地区"
                  @change="getPreFreightAndDeliverTime"
                ></area-select>
              </div>
            </div>
            <div class="mail-col-line">
              <div class="mail-col-line-title" style=" align-items: start;">
                <div class="delivery_detail-title-red">*</div>
                详细地址
              </div>
              <div class="mail-col-line-value">
                <el-input
                  v-model="value.receiver.address"
                  type="textarea"
                  placeholder="请输入详细地址"
                  @change="getPreFreightAndDeliverTime"
                ></el-input>
              </div>
            </div>
            <div class="mail-col-line">
              <div class="mail-col-line-title">关联客户</div>
              <div class="mail-col-line-value">
                <el-select
                  v-model="value.receiver.clientId"
                  placeholder="选填, 请选择关联客户"
                  filterable
                  @change="changeClient"
                >
                  <el-option
                    v-for="item in value.clientList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option
                ></el-select>
              </div>
            </div>
            <div class="mail-col-line" v-if="value.saveOrUpdateReceiver">
              <div class="mail-col-line-title">收件人公司</div>
              <div class="mail-col-line-value">
                <el-input
                  v-model="value.receiver.companyName"
                  placeholder="选填, 请填写公司名称"
                ></el-input>
              </div>
            </div>
            <div class="mail-col-line">
              <div class="mail-col-line-title" style="margin-right: 0">
                <el-checkbox v-model="value.saveOrUpdateReceiver"></el-checkbox>
              </div>
              <div
                class="mail-col-line-value"
                style="font-size: 12px; margin-left: 15px;margin-top: 1px;"
              >
                下单后，保存/更新上述收件人信息至地址维护菜单
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mail-row">
        <div class="mail-col-12">
          <div class="mail-col-title">
            <div class="mail-col-title-icon">
              <div class="mail-border-icon"></div>
              运单信息
            </div>
          </div>
          <div class="mail-col-contain">
            <div class="mail-col-line">
              <div class="mail-col-line-title">
                快递服务商
              </div>
              <div class="mail-col-line-value">
                <el-select v-model="value.supplierName" style="width: 215px;">
                  <el-option
                    v-for="item in value.supplierList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option
                ></el-select>
              </div>
            </div>
            <div class="mail-col-line">
              <div class="mail-col-line-title">
                时效产品
              </div>
              <div class="mail-col-line-value">
                <el-select
                  v-model="value.expressType"
                  style="width: 215px;"
                  @change="getPreFreightAndDeliverTime"
                >
                  <el-option
                    v-for="item in value.expressTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option
                ></el-select>
              </div>
            </div>
            <div class="mail-col-line">
              <div class="mail-col-line-title">
                寄件方式
              </div>
              <div class="mail-col-line-value">
                上门取件
              </div>
            </div>
            <div class="mail-col-line">
              <div class="mail-col-line-title">
                <div class="delivery_detail-title-red">*</div>
                支付方式
              </div>
              <div class="mail-col-line-value">
                <el-select
                  style="width: 150px;"
                  v-model="value.payMethod"
                  @change="getPreFreightAndDeliverTime"
                >
                  <el-option
                    v-for="item in value.payMethodList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option
                ></el-select>
              </div>
            </div>
            <div class="mail-col-line" style="align-items: baseline;">
              <div class="mail-col-line-title">留言</div>
              <div class="mail-col-line-value">
                <el-input
                  v-model="value.remark"
                  placeholder="给快递员留言"
                  maxlength="20"
                ></el-input>
                <div class="mail-col-line-value-remark-list">
                  <div
                    :class="{
                      active: isActive == index,
                      'mail-col-line-value-remark': true,
                    }"
                    v-for="(item, index) in remarkList"
                    :key="index"
                    @click="AddRemark(item, index)"
                  >
                    {{ item }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mail-col-12">
          <div class="mail-col-title"></div>
          <div class="mail-col-contain">
            <div class="mail-col-line" style="align-items: start;">
              <div class="mail-col-line-title">
                物品
              </div>
              <div class="mail-col-line-value">
                <el-input
                  v-model="value.goods"
                  placeholder="请输入要快递的物品 (不填写默认为文件) "
                ></el-input>
                <div
                  v-if="value.archivesData"
                  ref="div"
                  style="height: 35px; overflow: hidden;"
                >
                  <el-checkbox-group v-model="value.activeArchives" size="mini">
                    <el-checkbox
                      @change="change($event, item)"
                      v-for="item in value.archivesData"
                      :key="item.id"
                      :label="item.id"
                      border
                      >{{ item.name }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
              </div>
              <div
                class="more"
                v-if="value.archivesData && isMore"
                @click="handleClick('', 0)"
              >
                展示更多
              </div>
              <div
                class="more"
                v-if="value.archivesData && !isMore"
                @click="handleClick('', 1)"
              >
                隐藏更多
              </div>
            </div>
            <div class="mail-col-line" style="align-items: baseline;">
              <div class="mail-col-line-title">
                <div class="delivery_detail-title-red">*</div>
                重量
              </div>
              <div class="mail-col-line-value">
                <el-input
                  :onkeyup="
                    (value.preWeight = value.preWeight.replace(/[^0-9.]/g, ''))
                  "
                  v-model="value.preWeight"
                  style="width: 80px;"
                >
                </el-input>
                <span style="font-size: 12px;"> KG </span>
                <div class="popover">
                  轻抛系数 ( 体积比 ) : 9000
                  <el-popover
                    placement="bottom-end"
                    width="200"
                    trigger="hover"
                    content="托寄物的计费重量取体积重量与实际重量两者之间的较大值；体积重量的计算方法为：体积重量=长(cm)×宽(cm)×高(cm)÷轻抛系数; 例, 体积为16000cm³的物品, 轻抛系数为8000时, 按2kg计算。"
                  >
                    <div slot="reference">?</div>
                  </el-popover>
                </div>
              </div>
            </div>
            <div class="mail-col-line" style="align-items: baseline;">
              <div class="mail-col-line-title">
                期望上门时间
              </div>
              <div class="mail-col-line-value">
                <el-date-picker
                  v-model="value.sendStartTime"
                  type="datetime"
                  placeholder="选择日期时间"
                  format="yyyy-MM-dd HH:mm"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  popper-class="tpc"
                  :picker-options="editPickerOptions"
                  :clearable="false"
                >
                </el-date-picker>
                <div class="info">
                  上门时间范围: 期望上门时间至期望上门时间后一小时
                </div>
                <div class="info">因地区差异, 超过19点可能会导致下单失败</div>
              </div>
            </div>
            <div class="mail-col-line" style="align-items: center;">
              <div class="mail-col-line-title">
                预计送达时间
              </div>
              <div class="mail-col-line-value">
                {{ value.deliverTime || "- -" }}
              </div>
            </div>
            <div class="mail-col-line" style="align-items: start;">
              <div class="mail-col-line-title">添加备注</div>
              <div class="mail-col-line-value">
                <el-input v-model="value.memo" type="textarea"></el-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mail-row">
        <div class="mail-row-left">
          <el-checkbox v-model="value.serviceAgreement"></el-checkbox>
          我已同意并阅读<span class="info">《服务协议》</span>
          <!-- <span
            class="waring"
            v-if="!value.serviceAgreement"
            @click="value.serviceAgreement = true"
          >
            请先勾选我同意
          </span> -->
        </div>
        <div class="mail-row-right">
          <div class="mail-row-right-price">
            <div class="mail-row-right-freFreight">
              预估运费:
              <span style="font-size: 24px; color: #ff8040"
                >¥ {{ value.preFreight || "- -" }}</span
              >
            </div>
            <div class="mail-row-right-discount">
              <span v-if="value.discounts"
                >顺丰优惠 {{ value.discounts }} 元,</span
              >
              <span> 优惠后约: ¥ {{ value.discountsMoney || "- -" }}</span>
            </div>
            <div class="mail-row-right-text">
              最终价格以快递服务商实际扣费为准
            </div>
          </div>
          <div class="mail-row-right-order">
            <div class="mail-row-right-but" @click="order">下单</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog1
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog1>
    </div>
  </div>
</template>

<script>
import { pca, pcaa } from "area-data";
import * as expressageData from "@/views/expressage/js/expressage.js";
export default {
  props: {
    mailData: {
      type: Object,
      default: function() {
        return;
      },
    },
  },
  components: {
    my_dialog1: () => import("@/components/dialog.vue"),
  },
  data() {
    return {
      value: {},
      checked: true,
      select: [],
      pcaa: pcaa,
      dialogData: {
        flag: false,
        width: "40%",
        title: "添加跟进记录",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },
      editPickerOptions: {
        //只能选择今天和明天
        disabledDate(time) {
          let curDate = new Date().getTime();
          let oneDay = 1 * 24 * 3600 * 1000;
          let threeMonths = curDate + oneDay;
          return (
            time.getTime() < Date.now() - 8.64e7 || time.getTime() > threeMonths
          );
        },
        // selectableRange: new Date() + "- 23:59:00",
        selectableRange: "09:00:00 - 20:00:00",
      },
      remarkList: ["带文件夹", "带纸箱", "带防水袋"],
      isActive: -1,
      isMore: true,
      flag: true,
    };
  },
  watch: {
    "value.sendStartTime"() {
      // 获取当前时间戳
      let now = new Date(this.value.sendStartTime).getTime() / 1000;
      // 获取当前日期
      let today = new Date();
      today.setHours(23, 59, 59); // 将小时、分钟和秒数设置为零
      let todayTimestamp = today.getTime() / 1000; // 将日期转换为时间戳
      // 判断当前日期是否为当天
      if (now <= todayTimestamp) {
        // console.log("当前时间属于当天");
        var d = new Date(); // 获取时间戳
        var n = d.getHours(); // 获取小时
        if (parseInt(n) < 9) {
          this.editPickerOptions.selectableRange = "09:00:00 - 20:00:00";
        } else if (parseInt(n) > 21) {
          this.editPickerOptions.selectableRange = "00:00:00 - 00:00:00";
        } else {
          this.editPickerOptions.selectableRange = n + ":00:00 - 20:00:00";
        }
      } else {
        // console.log("当前时间不属于当天");
        this.editPickerOptions.selectableRange = "09:00:00 - 20:00:00";
      }
    },
  },
  methods: {
    open() {
      window.open(this.$baseurl + "/express-agree.html", "_blank");
    },
    /**
     * 地址薄
     */
    addressBook() {
      // 打开地址薄;
      this.dialogData.width = "700px";
      this.dialogData.title = "选择收件人";
      this.dialogData.buttonArray = [];
      (this.dialogData.data = [
        {
          title: "",
          type: "selectRecipient",
          data: [],
        },
      ]),
        console.log("选择收件人dialog显示:", this.dialogData.data);
      this.dialogData.flag = true;
    },
    /**
     * 智能地址
     */
    autoAddress() {
      // 打开智能地址;
      this.dialogData.width = "480px";
      this.dialogData.title = "智能写入";
      this.dialogData.buttonArray = [
        {
          text: "确认",
          active: "Intelligent_address",
        },
      ];
      (this.dialogData.data = [
        {
          title: "",
          type: "textarea_auto",
          value: "",
          placeholder:
            "示例: 张**, 139****5789, 安徽省合肥市蜀山区樊洼路1号乐彩中心(蓝光禹洲城)8号办公楼1711室",
        },
      ]),
        console.log("智能地址dialog显示:", this.dialogData.data);
      this.dialogData.flag = true;
    },
    drawerOper(active) {
      switch (active) {
        case "Intelligent_address":
          // 智能地址
          this.$request(
            "get",
            `/expressAddress/apiAddress?addressText=${this.dialogData.data[0].value}`,
            {},
            (data) => {
              let newData = JSON.parse(data);
              this.flag = false;
              this.value.receiver.name = newData.person; // 收件人姓名
              this.value.receiver.phone = newData.phonenum; // 收件人电话
              this.value.receiver.provinceCode = newData.province_code; // 收件人省code
              this.value.receiver.cityCode = newData.city_code; // 收件人市code
              this.value.receiver.countyCode = newData.county_code; // 收件人区code
              this.value.receiver.area =
                newData.province + newData.city + newData.county; // 收件人省市区
              this.value.receiver.code = [
                newData.province_code,
                newData.city_code,
                newData.county_code,
              ];
              this.value.receiver.address = newData.detail; // 收件人地址
              this.$nextTick(() => {
                this.flag = true;
              });
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );

        case "make_sure":
          this.colseDialog();
          break;
        case "cancel":
          this.colseDialog();
          break;
        default:
          break;
      }
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 下单
     */
    order() {
      this.$bus.$emit("order");
    },
    /**
     * 添加留言
     */
    AddRemark(item, index) {
      this.isActive = index;
      if (this.value.remark.length > 20) {
        this.$message.warning("留言不能超过20个字");
        return;
      }
      this.value.remark += item + ", ";
      this.value.remark = this.value.remark.replace(/,([^,]*)$/, "$1");
    },
    handleClick(data, flag) {
      switch (flag) {
        case 0:
          this.isMore = !this.isMore;
          this.$refs.div.style = "height: auto;";
          break;
        case 1:
          this.isMore = !this.isMore;
          this.$refs.div.style = "height: 35px; overflow: hidden;";
          break;
        default:
          break;
      }
    },
    change(val, item) {
      if (val) {
        this.value.goods += item.name + ", ";
        this.value.goods = this.value.goods.replace(/,([^,]*)$/, "$1");
      } else {
        this.value.goods = this.value.goods.replace(item.name, "").trim();
      }
    },
    /**
     * 获取预估运费和预计时间
     */
    getPreFreightAndDeliverTime(val) {
      if (val == "231") {
        // 不支持 顺丰陆运 到付方式
        this.value.payMethod = "1";
      }
      if (this.value.payMethod == "2") {
        // 不支持 顺丰陆运 到付方式
        this.value.expressType = "2";
      }

      let senderInfo = expressageData.transformCode2(this.value.sender.code);
      let receiverInfo = expressageData.transformCode2(
        this.value.receiver.code
      );
      let requestData = {
        createId: this.baseInfo.userId, // 下单人 id
        supplierName: this.value.supplierName, // 快递服务商
        goods: this.value.goods, // 物品
        expressType: this.value.expressType, // 时效产品 1-顺丰特快 2-顺丰标快
        preWeight: this.value.preWeight, // 下单重量
        isOneseIfPickup: this.value.isOneseIfPickup, // 寄件方式 1-客户同意快件自取 0-客户不同意快件自取
        sendStartTime: this.value.sendStartTime, // 期望上门时间
        payMethod: this.value.payMethod, // 支付方式
        sender: this.value.sender, // 寄件人信息
        receiver: this.value.receiver, // 收件人信息
      };
      requestData.sender.provinceCode = senderInfo.provinceCode; // 寄方省code
      requestData.sender.cityCode = senderInfo.cityCode; // 寄方市code
      requestData.sender.countyCode = senderInfo.countyCode; // 寄方区code

      requestData.receiver.provinceCode = receiverInfo.provinceCode; // 收方省code
      requestData.receiver.cityCode = receiverInfo.cityCode; // 收方省code
      requestData.receiver.countyCode = receiverInfo.countyCode; // 收方省code

      requestData.sender.provinceName = senderInfo.provinceName; // 寄方省地址
      requestData.sender.cityName = senderInfo.cityName; // 寄方市地址
      requestData.sender.countyName = senderInfo.countyName; // 寄方区地址

      requestData.receiver.provinceName = receiverInfo.provinceName; // 收方省地址
      requestData.receiver.cityName = receiverInfo.cityName; // 收方市地址
      requestData.receiver.countyName = receiverInfo.countyName; // 收方区地址
      if (
        requestData.sender.countyCode &&
        requestData.sender.countyCode !== "0" &&
        requestData.receiver.countyCode &&
        requestData.receiver.countyCode !== "0" &&
        requestData.sendStartTime &&
        requestData.payMethod &&
        this.value.sender.address &&
        this.value.receiver.address &&
        this.value.preWeight
      ) {
        this.$request(
          "post",
          "/expressOrder/getPreFreightAndDeliverTime",
          requestData,
          (data) => {
            if (this.value.payMethod == "2") {
              // 到付
              this.value.discounts = "";
              this.$cutOutNum.cutOutNum(data.fee + 1, this, (money) => {
                this.value.preFreight = money; // 预估运费
                this.value.discountsMoney = money; // 优惠后金额
              });
              this.value.deliverTime = data.deliverTime.split(",")[0]; // 预计送达时间
              return;
            }
            this.value.discounts = 1;
            this.$cutOutNum.cutOutNum(data.fee + 1, this, (money) => {
              this.value.preFreight = money; // 预估运费
            });
            this.$cutOutNum.cutOutNum(data.fee, this, (money) => {
              this.value.discountsMoney = money; // 优惠后金额
            });
            this.value.deliverTime = data.deliverTime.split(",")[0]; // 预计送达时间
          },
          (errorMessage) => {
            this.$message.error(errorMessage);
          }
        );
      }
    },
    // /**
    //  * 改变支付方式
    //  */
    // changePayMethod(val) {},
    /**
     * 选择管理客户修改档案物品
     */
    changeClient(val) {
      // 是否为档案模块打开
      this.value.activeArchives = [];
      this.value.goods = "";
      this.$request(
        "post",
        "/archivesInfo/getDeliverable",
        {
          clientId: val,
          userId: this.baseInfo.userId,
        },
        (data) => {
          this.value.archivesData = data;
        },
        (errormessage) => {
          this.$message.error(errormessage);
        }
      );
    },
  },
  created() {
    this.value = this.mailData;
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    // 1. 在created中订阅
    // 2. 回调函数需要写成箭头函数
    // this.$bus.$on("事件名", 事件回调函数);
    this.$bus.$off("rowClick").$on("rowClick", (row) => {
      this.flag = false;
      this.value.receiver.id = row.id;
      this.value.receiver.code = [
        row.provinceCode,
        row.cityCode,
        row.countyCode,
      ];
      this.value.receiver.name = row.name;
      this.value.receiver.phone = row.phone;
      this.value.receiver.address = row.address;
      this.value.receiver.area = row.area;
      this.value.receiver.clientId = row.clientId;
      this.value.receiver.companyName = row.companyName;
      if (row.clientId) {
        this.changeClient(row.clientId);
      }
      this.$nextTick(() => {
        this.flag = true;
      });
      this.colseDialog();
      // this.$bus.$off("rowClick");
    });
  },
};
</script>

<style lang="less" scoped>
#mail {
  width: 100%;
  .mail-content {
    padding: 0 20px;
    .mail-row {
      display: flex;
      justify-content: space-between;
      min-height: 21px;
      margin-top: 10px;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #d1d3d8;
      font-size: 14px;
      &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
        margin-bottom: 0;
      }
      .mail-row-left {
        .info {
          color: #ff8040;
          cursor: pointer;
        }
      }
      .mail-row-right {
        display: flex;
        align-items: center;
        font-size: 12px;
        .mail-row-right-price {
          border-right: 1px solid #e5e5e5;
          margin-right: 20px;
          padding-right: 20px;
          text-align: right;
          .mail-row-right-freFreight {
            font-size: 17px;
            margin-bottom: 5px;
          }
          .mail-row-right-discount {
            color: #ff8040;
            margin-bottom: 5px;
          }
          .mail-row-right-text {
            color: #999;
          }
        }
        .mail-row-right-order {
          .mail-row-right-but {
            width: 66px;
            min-width: 66px;
            height: 33px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: @activeColor;
            font-size: @contentFontSize;
            color: #fff;
            margin: 0 5px;
            border-radius: 4px;
            transition: 0.5s;
          }
          .mail-row-right-but:hover {
            cursor: pointer;
            background-color: @activeColor2;
          }
        }
      }
      .mail-col-12 {
        width: 50%;
        display: flex;
        flex-direction: column;
        .mail-col-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 16px;
          height: 21px;
          .mail-col-title-icon {
            display: flex;
            align-items: center;

            .mail-border-icon {
              height: 16px;
              width: 6px;
              background: @baseColor;
              border-radius: 3px;
              margin-top: 1px;
              margin-right: 10px;
            }
          }
          .mail-col-title-address {
            display: flex;
            text-align: right;
            font-size: 14px;
            font-weight: 700;
            color: @baseColor;

            margin-right: 30px;
            div {
              cursor: pointer;
            }
            span {
              font-size: 14px;
              background-color: #c6c8cd;
              margin: 0 5px;
              height: 16px;
              width: 2px;
              cursor: auto;
            }
          }
        }
        .mail-col-contain {
          display: flex;
          flex-direction: column;
          .mail-col-line {
            position: relative;
            display: flex;
            margin-top: 20px;
            min-height: 21px;
            .mail-col-line-title {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              width: 20%;
              text-align: right;
              margin-right: 15px;
              .delivery_detail-title-red {
                color: red;
                margin-top: 5px;
                margin-right: 5px;
              }
            }
            .mail-col-line-value {
              width: 65%;
              padding-right: 40px;
              text-align: left;
              .mail-col-line-value-remark-list {
                display: flex;
                margin-top: 4px;
                .mail-col-line-value-remark {
                  padding: 2px 5px;
                  border-radius: 3px;
                  border: 1px dashed #999;
                  margin-right: 5px;
                  cursor: pointer;
                  &:hover {
                    background-color: #ecf5ff;
                    transition: all 0.3s;
                  }
                }
                .active {
                  background-color: #fff;
                  border-color: #0e77ca;
                  color: #0e77ca;
                }
              }
              /deep/ .area-select-wrap {
                display: flex;
                .area-select {
                  width: 100px;
                  margin-left: 8px;
                  box-sizing: border-box;
                  &:hover {
                    transition: 1s;
                  }
                  &:first-child {
                    margin-left: 0;
                  }
                  .area-selected-trigger {
                    padding: 7px 20px 7px 10px;
                    text-align: left;
                    font-weight: 500;
                    &:hover {
                      padding: 7px 20px 7px 10px;
                    }
                  }
                  .area-select-option.selected {
                    color: @activeColor;
                  }
                }
              }
              /deep/ .el-checkbox {
                margin: 5px 10px 5px 0;
              }
              /deep/ .el-checkbox.is-bordered + .el-checkbox.is-bordered {
                margin-left: 0;
              }
            }
            .more {
              position: absolute;
              top: 40px;
              right: -4px;
              font-size: 12px;
              color: @baseColor;
              cursor: pointer;
            }
          }
        }
      }
      .info {
        font-size: 12px;
        color: @baseColor;
        margin-top: 4px;
      }
      .waring {
        color: red;
        cursor: pointer;
      }
      .popover {
        display: flex;
        align-items: center;
        color: #999;
        div {
          margin-left: 5px;
          border: 1px solid #999;
          border-radius: 50%;
          display: block;
          height: 14px;
          line-height: 14px;
          text-align: center;
          width: 14px;
        }
      }
    }
  }
}
</style>

<style>
.tpc .el-scrollbar:nth-of-type(2) {
  .el-time-spinner__item:not(:first-child) {
    display: none;
  }
}
</style>
