import * as baseData from "./baseData.js"
/**
 * 周期合同界面tableMenu数据
 */
export function contractInfotableMenuData() {
    let menuData = [
        // 从数据库拿到的数据
        {
            key: "contractNum",
            title: "合同编号",
            width: "100",
            isDisable: true, // 自定义表头是否显示
        },
        {
            key: "abbreviation",
            title: "客户全称",
            width: "200",
            isDisable: true,
        },
        {
            key: "customerNum",
            title: "客户编号",
            width: "120",
            isDisable: true,
        },
        {
            key: "hasChargedData",
            title: "已收费至",
            width: "100",
            isDisable: true,
        },
        {
            key: "serviceList",
            title: "服务项",
            width: "120",
            isDisable: true,
        },
        {
            key: "status",
            title: "合同状态",
            width: "100",
            isDisable: true,
        },
        {
            key: "allMoney",
            title: "合同金额",
            width: "100",
            isDisable: true,
        },
        {
            key: "registeredIncome",
            title: "已登记收入",
            width: "150",
            isDisable: true,
        },
        {
            key: "examineIncome",
            title: "已审核收入",
            width: "100",
            isDisable: true,
        },
        {
            key: "pay",
            title: "支出金额",
            width: "120",
            isDisable: true,
        },
        {
            key: "profit",
            title: "利润",
            width: "100",
            isDisable: true,
        },
        {
            key: "deadTime",
            title: "到期时间",
            width: "100",
            isDisable: true,
        },
        {
            key: "productName",
            title: "服务产品",
            width: "100",
            isDisable: true,
        },
        {
            key: "cycle",
            title: "收费周期",
            width: "100",
            isDisable: true,
        },
        {
            key: "firstUrgeTime",
            title: "首次收费时间",
            width: "100",
            isDisable: true,
        }, {
            key: "area",
            title: "片区",
            width: "100",
            isDisable: true,
        }, {
            key: "department",
            title: "所属部门",
            width: "100",
            isDisable: true,
        }, {
            key: "level",
            title: "客户等级",
            width: "100",
            isDisable: true,
        }, {
            key: "renewalStatus",
            title: "续签状态",
            width: "100",
            isDisable: true,
        }, {
            key: "autoRenewal",
            title: "自动续签",
            width: "100",
            isDisable: true,
        }, {
            key: "adviser",
            title: "服务顾问",
            width: "100",
            isDisable: true,
        }, {
            key: "charge",
            title: "收费负责人",
            width: "100",
            isDisable: true,
        }, {
            key: "signBillPerson",
            title: "签单人",
            width: "100",
            isDisable: true,
        }, {
            key: "signBillTime",
            title: "签单日期",
            width: "100",
            isDisable: true,
        }, {
            key: "startTime",
            title: "开始日期",
            width: "100",
            isDisable: true,
        }, {
            key: "introductor",
            title: "推荐人",
            width: "100",
            isDisable: true,
        }, {
            key: "signBillPersonDepartment",
            title: "签单人部门",
            width: "100",
            isDisable: true,
        }, {
            key: "source",
            title: "来源",
            width: "100",
            isDisable: true,
        },
    ]
    return menuData
}

/**
 * 初始化表格数据处理
 * @param {*} array 所有表格数据
 * @param {*} menuArray 当前显示的表头数组
 */
export function getInitTableData(array, menuArray, this_) {
    array.forEach(element => {
        let color = ""
        if (element.auditFlag) {
            switch (element.auditFlag) {
                case "0":
                    // 没有收款记录
                    color = "#111" // 查账按钮黑色显示
                    break;
                case "1":
                    // 有未审核的收款记录
                    color = "#e03131" // 查账按钮红色显示
                    break;
                case "2":
                    // 未全部收款
                    color = "#66B1FF" // 查账按钮蓝色显示
                    break;
                case "3":
                    // 全部收款
                    color = "#82c91e" // 查账按钮绿色显示
                    break;
                default:
                    color = "#ced4da" // 查账按钮灰色显示
                    break;
            }
        } else {
            if (parseFloat(element.money) >= 0) {
                // 合同总金额大于0
                if (element.registeredIncome == "0.00" && element.auditedIncome == "0.00") {
                    // 已登记收入为0  
                    color = "#111" // 查账按钮黑色显示 
                } else if ((parseFloat(element.registeredIncome) > parseFloat(element.auditedIncome)) || (parseFloat(element.registeredIncome) < parseFloat(element.auditedIncome) && (parseFloat(element.registeredIncome) >= 0))) {
                    // 已登记收入大于已审核金额
                    color = "#e03131" // 查账按钮红色显示
                } else if (parseFloat(element.registeredIncome) == parseFloat(element.auditedIncome) && parseFloat(element.auditedIncome) < parseFloat(element.money)) {
                    // 已登记收入等于已审核金额,小于合同总金额
                    color = "#66B1FF" // 查账按钮蓝色显示
                } else if (parseFloat(element.auditedIncome) == parseFloat(element.money)) {
                    // 已审核收入等于合同总金额
                    color = "#82c91e" // 查账按钮绿色显示
                } else {
                    // 出错
                    color = "#ced4da" // 查账按钮灰色显示
                }
            } else {
                if (element.registeredIncome == "0.00" && element.auditedIncome == "0.00") {
                    // 已登记收入为0
                    color = "#111" // 查账按钮黑色显示
                } else if (parseFloat(element.registeredIncome) < parseFloat(element.auditedIncome)) {
                    // 已登记收入小于已审核金额
                    color = "#e03131" // 查账按钮红色显示
                } else if (parseFloat(element.registeredIncome) == parseFloat(element.auditedIncome) && parseFloat(element.auditedIncome) > parseFloat(element.money)) {
                    // 已登记收入等于已审核金额,小于合同总金额
                    color = "#66B1FF" // 查账按钮蓝色显示
                } else if (parseFloat(element.auditedIncome) == parseFloat(element.money)) {
                    // 已审核收入等于合同总金额
                    color = "#82c91e" // 查账按钮绿色显示
                } else {
                    // 出错
                    color = "#ced4da" // 查账按钮灰色显示
                }
            }
            console.log("color:", color);
        }


        this_.$set(element, "color", color)
    });
    return array
}

/**
 * 新增客户抽屉数据
 */
export function getNewClientDrawerData(list) {
    let data = {
        title: "新增合同－选择客户",
        flag: false,
        hasSetting: false,
        buttonList: [],
        data: {
            isDisable: false, // 是否只读
            list: list,
            modelList: [{
                line: [{
                    isShow: true, // 是否显示
                    isDisable: false, // 是否只读
                    title: "",
                    type: "input-select-filterable-tooltip", // 带建议的input框,必须要有list
                    value: "",
                    message: "",
                    tooltipf_flag: true
                }, {
                    isShow: true, // 是否显示
                    isDisable: false, // 是否只读
                    title: "",
                    type: "text-color",
                    value: [{
                        text: "您可以输入客户名称后,选择需要的客户",
                        color: "#aaa"
                    }, {
                        text: "进行创建",
                        color: "#aaa"
                    }],
                }]
            }]
        }
    }
    if (list.length == 0) {
        data.data.modelList[0].line[1].value = [{
            text: "暂无客户,请先至客户模块新增客户!",
            color: "#aaa"
        }]
    }
    return data
}

export function getNewClientTableDrawerData(list, title) {
    let data = {
        title: "新增合同－选择客户",
        flag: false,
        hasSetting: false,
        buttonList: [],
        data: {
            isDisable: false, // 是否只读
            title: title,
            modelList: [{
                line: [{
                    isShow: true, // 是否显示
                    isDisable: false, // 是否只读
                    title: "",
                    type: "table", // 带建议的input框,必须要有list
                    tableData: list,
                    menu: [{
                        value: "服务产品",
                        key: "product",
                        type: "text",
                    }, {
                        value: "到期时间",
                        key: "dateDue",
                        type: "text",
                    }, {
                        value: "服务项",
                        key: "services",
                        type: "array",
                    }, {
                        value: "状态",
                        key: "status",
                        type: "text",
                    }, {
                        value: "金额",
                        key: "money",
                        type: "text",
                    }],
                    summary: false
                }, {
                    isShow: true, // 是否显示
                    isDisable: false, // 是否只读
                    title: "",
                    type: "text-button",
                    text: "新建合同",
                    active: "create-contract",
                    roleKey: "cancel",
                    value: "签订的新业务,直接创建新合同",
                }]
            }]
        }
    }
    return data
}

/**
 * 合同信息抽屉json数据
 * @param {*} title 
 * @param {*} data 
 */
export function contractInfoDrawerData(title, data, buttonAuth, butText, serviceList) {
    let item_data = [{
            horizontal: false,
            isShow: true,
            isDisable: false,
            title: "服务名称",
            type: "select",
            value: "会计部",
            options: serviceList,
        },
        {
            horizontal: false,
            isShow: true,
            isDisable: false,
            title: "付费周期",
            type: "select",
            value: "每年",
            options: [{
                    label: "每年",
                    value: "每年",
                    img: "",
                },
                {
                    label: "每月",
                    value: "每月",
                    img: "",
                },
                {
                    label: "每季度",
                    value: "每季度",
                    img: "",
                },
            ],
        },
        {
            horizontal: false,
            isShow: true,
            isDisable: true,
            title: "催费时间",
            type: "收费提醒选择",
            radio: "提前",
            beforeDay: "",
            lateDay: "",
            value: "",
        },
        {
            horizontal: false,
            isShow: true,
            isDisable: false,
            title: "优惠",
            type: "discounts",
            value: {},
        },
        {
            horizontal: false,
            isShow: true,
            isDisable: false,
            title: "首次催费时间",
            type: "time",
            value: "",
        },
        {
            horizontal: false,
            isShow: true,
            isDisable: false,
            title: "服务总价",
            type: "input",
            value: "180****8888",
        },
        {
            horizontal: false,
            isShow: true,
            isDisable: false,
            title: "服务时间",
            type: "timeToTime",
            value: [],
        },
    ];
    let contractDrawerData = {
        title: "合同",
        flag: false,
        CardList: [{
                text: "合同信息",
                active: "info",
                flag: 0
            }, {
                text: "收款记录",
                active: "huikuan",
                flag: 0
            },
            {
                text: "收费情况",
                active: "shoufei",
                flag: 0
            }, {
                text: "合同支出",
                active: "zhichu",
                flag: 0
            },
            {
                text: "合同附件",
                active: "fujian",
                flag: 0
            },
        ],
        cardIndex: 0,
        hasSetting: true,
        settingOperList: [{
            text: "删除合同",
            active: "delete_contract",
            authority: 1
        }, {
            text: "手动结束",
            active: "end_the_manual",
            authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("手动结束", buttonAuth) : 3

        }, {
            text: "操作记录",
            active: "operating_record_contract",
            authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("操作记录", buttonAuth) : 1
        }, ],
        buttonList: [{
            text: "回款",
            icon: "el-icon-money",
            active: "add_huikuan",
            authority: 3
                // authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("回款", buttonAuth) : 3
        }, ],
        data: {
            title: title,
            isLink: true,
            butArray: [{
                text: butText.text,
                active: butText.active,
                authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority(butText.text, buttonAuth) : 1
            }],
            customer: 0, // 关联客户,与商机状态关联,(只有商机状态为成交才有值),其他时候为空字符串
            isDisable: false, // 是否只读
            modelList: [{
                    line: [{
                            horizontal: true,
                            isShow: false,
                            isDisable: false,
                            title: "合同状态",
                            type: "text",
                            value: "执行中",
                        }, {
                            horizontal: true,
                            isShow: true, // 是否显示
                            isDisable: false, // 是否只读
                            title: "跟进人",
                            type: "selects-obj",
                            value: "",
                            options: [],
                        },
                        {
                            horizontal: true,
                            isShow: true, // 是否显示
                            isDisable: false, // 是否只读
                            title: "签单人",
                            type: "text",
                            value: "",
                            options: [],
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: true,
                            title: "签单日期",
                            type: "time",
                            value: "",
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "来源",
                            type: "select",
                            value: "会计部",
                            options: [{
                                    label: "会计部",
                                    value: "会计部",
                                },
                                {
                                    label: "公关部",
                                    value: "公关部",
                                },
                                {
                                    label: "财务部",
                                    value: "财务部",
                                },
                            ],
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            marked: false,
                            title: "标准金额",
                            type: "text",
                            value: "",
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            marked: true,
                            title: "成本",
                            type: "input",
                            value: "",
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "是否优惠",
                            type: "radioChange",
                            value: "N",
                            options: [{
                                label: "Y",
                                text: "是",
                            }, {
                                label: "N",
                                text: "否",
                            }, ],
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            marked: false,
                            title: "优惠金额",
                            type: "input",
                            value: "0",
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            marked: false,
                            title: "合同金额",
                            type: "text",
                            value: "",
                        },
                    ],
                },
                {
                    line: [{
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "产品名称",
                            type: "select",
                            value: "",
                            options: []
                        }, {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "合同编号",
                            type: "input",
                            value: "180****8888",
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "续签状态",
                            type: "text",
                            value: "会计部",
                        },
                        {
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            monthDisable: false,
                            title: "签约期限",
                            type: "select-input-but",
                            value: ["1", "0"],
                            buts: [
                                // {
                                // text: "续签",
                                // active: "renew",
                                // authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("续签", buttonAuth) : 1
                                // },
                            ]
                        },
                        {
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            title: "合同有效期",
                            type: "time-text",
                            value: "2021-03-30",
                            textValue: "2022-03-29"
                        },
                        {
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            title: "自动续签",
                            type: "radio",
                            value: "Y",
                            options: [{
                                label: "Y",
                                text: "是"
                            }, {
                                label: "N",
                                text: "否"
                            }, ]
                        },
                        {
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            title: "是否赠送服务",
                            type: "radioChange",
                            value: "N",
                            options: [{
                                label: "Y",
                                text: "是",
                            }, {
                                label: "N",
                                text: "否",
                            }, ],
                        },
                    ],
                },
                {
                    line: [{ // 服务项目
                        horizontal: false,
                        isShow: false,
                        isDisable: false,
                        title: "",
                        tabletext: "服务项目",
                        type: "htservice",
                        contactMoney: "2200.00", // 合同金额
                        value: [],
                        buttons: [
                            //     {
                            //     text: "变更服务时间",
                            //     active: "",
                            //     authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("合同变更", buttonAuth) : 1
                            // }, {
                            //     text: "调整价格",
                            //     active: "",
                            //     authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("合同变更", buttonAuth) : 1
                            // }, {
                            //     text: "整单折扣",
                            //     active: "",
                            //     authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("合同变更", buttonAuth) : 1
                            //     },
                            {
                                text: "添加服务项",
                                active: "add_serves",
                                // authority: 1
                                authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("添加服务项目", buttonAuth) : 1
                            },
                        ]
                    }, ]
                },
                {
                    line: [{
                        horizontal: false,
                        isShow: true,
                        isDisable: false,
                        title: "",
                        tabletext: "赠送的服务项目",
                        type: "give-service-item",
                        contactMoney: "2200.00", // 合同金额
                        value: [],
                        buttons: [{
                            text: "添加服务项",
                            active: "add_free_serves",
                            authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("添加服务项目", buttonAuth) : 1
                        }, ]
                    }, ]
                }, {
                    line: [{
                        horizontal: false,
                        isShow: false,
                        isDisable: false,
                        title: "",
                        tabletext: "预收款",
                        type: "advances_received",
                        contactMoney: "2200.00", // 总预收金额金额
                        value: [],
                        buttons: [{
                            text: "添加预收款",
                            active: ""
                        }, ]
                    }, ]
                }, {
                    line: [{
                        horizontal: false,
                        isShow: true,
                        isDisable: false,
                        title: "合同备注",
                        type: "textarea",
                        value: "",
                    }, ]
                }
            ],
        },
    }
    let index = 0
    if (data.length > 0) {
        for (let i = 0; i < contractDrawerData.data.modelList.length; i++) {
            let element = contractDrawerData.data.modelList[i].line;
            for (let j = 0; j < element.length; j++) {
                let e = element[j];
                if (e.type == "htservice") {
                    e.contactMoney = data[index].contactMoney // 合同金额
                        // e.isShow = data[index].isShow // 显示权限
                        // e.isDisable = data[index].isDisable // 显示权限
                        // e.value = []
                    data[index].value.forEach((el) => {
                        // 拿到数据库中的每一项服务项
                        item_data.forEach((it, ii) => {
                            // 界面详情数据的每一行数据
                            it.isShow = el.item[ii].isShow // 详情每一行显示权限
                            it.isDisable = el.item[ii].isDisable // 详情每一行显示权限
                            if (it.type == '收费提醒选择') {
                                it.radio = el.item[ii].value.label == "" ? "0" : el.item[ii].value.label
                                it.value = el.item[ii].value.value
                            } else if (it.type == 'timeToTime') {
                                it.value = el.item[ii].value

                            } else if (it.type == 'discounts') {
                                // 优惠
                                it.value = el.item[ii].value
                                it.value.totalPrice = el.tableData.data[0].price
                            } else {
                                it.value = el.item[ii].value
                            }
                        });
                        item_data[0].value = el.tableData.name
                        let dd = {
                            flag: false,
                            tableData: JSON.parse(JSON.stringify(el.tableData)),
                            item: JSON.parse(JSON.stringify(item_data))
                        }
                        e.value.push(JSON.parse(JSON.stringify(dd)))
                    });
                } else if (e.type == 'give-service-item') {
                    e.isShow = data[16].value == "N" ? false : data[index].isShow // 显示权限
                    e.isDisable = data[index].isDisable // 显示权限
                    if (data[index].value != null && data[index].value.length > 0) {
                        data[index].value.forEach(el => {
                            let dd = {
                                isEdit: false,
                                flag: false,
                                serviceItem: el.serviceItem,
                                serviceTime: el.serviceTime
                            }
                            e.value.push(JSON.parse(JSON.stringify(dd)))
                        });
                    } else {
                        e.value = []
                    }
                } else if (e.type == 'radioChange') {
                    // 是否赠送
                    e.isShow = data[index].isShow
                    e.isDisable = data[index].isDisable
                    e.value = data[index].value == "" ? "N" : data[index].value
                } else if (e.type == 'time-text') {
                    e.value1 = data[index].value.time1
                    e.value2 = data[index].value.time2
                    e.isShow = data[index].isShow
                    e.isDisable = data[index].isDisable
                } else if (e.type == "advances_received") {
                    e.contactMoney = data[index].contactMoney
                    e.isShow = data[index].isShow
                    e.isDisable = data[index].isDisable
                    if (data[index].value == null || data[index].value || data[index].value == "") {
                        e.value = []
                    } else {
                        data[index].value.forEach(el => {
                            let dd = {
                                flag: false,
                                tableData: el.tableData,
                                item: el.item
                            }
                            e.value.push(JSON.parse(JSON.stringify(dd)))
                        });
                    }
                } else if (e.type == "select-input-but") {
                    // 签约期限
                    e.value = data[index].value
                    e.isShow = data[index].isShow
                    e.isDisable = data[index].isDisable
                    if (data[17].value[0].tableData.cycle == "每年收") {
                        e.monthDisable = true
                    } else {
                        e.monthDisable = data[index].isDisable
                    }
                } else {
                    if (e.title == "产品名称") {
                        e.value = data[index].value ? data[index].value : ""
                    } else {
                        e.value = data[index].value
                    }
                    e.isShow = data[index].isShow
                    e.isDisable = data[index].isDisable
                }
                index++
            }
        }
    }
    contractDrawerData.data.modelList[4].line[0].isShow = false
    if (contractDrawerData.data.modelList[0].line[7].value == "Y") {
        contractDrawerData.data.modelList[0].line[8].isShow = true
    } else {
        contractDrawerData.data.modelList[0].line[8].isShow = false
    }
    console.log("合同具体信息:", contractDrawerData);
    return contractDrawerData
}

export function contractWorkDrawerData(title, data, buttonAuth) {
    let contractDrawerData = {
        title: "合同派工",
        flag: false,
        hasSetting: true,
        settingOperList: [{
            text: "撤销派工提交",
            active: "",
            authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("撤销派工提交", buttonAuth) : 1
        }, {
            text: "撤销派工审核",
            active: "",
            authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("撤销派工审核", buttonAuth) : 1

        }, {
            text: "操作记录",
            active: "operating_record_contract",
            authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("操作记录", buttonAuth) : 1
        }, ],
        buttonList: [],
        data: {
            title: title,
            butArray: [],
            customer: 0, // 关联客户,与商机状态关联,(只有商机状态为成交才有值),其他时候为空字符串
            isDisable: false, // 是否只读
            modelList: [{
                line: [{
                    horizontal: true,
                    isShow: false,
                    isDisable: false,
                    title: "合同编号",
                    type: "text",
                    value: "",
                    key: "contractNum"
                }, {
                    horizontal: true,
                    isShow: false,
                    isDisable: false,
                    title: "客户编号",
                    type: "text",
                    value: "",
                    key: "clientNum"
                }, 
                
                {
                    horizontal: true,
                    isShow: false,
                    isDisable: false,
                    border: true,
                    title: "服务项",
                    type: "text-color",
                    value: "",
                    key: "services"
                }, 
                {
                    horizontal: true,
                    isShow: false,
                    isDisable: false,
                    title: "提交人",
                    type: "text",
                    value: "",
                    key: "submitter"
                }, 
                {
                    horizontal: true,
                    isShow: false,
                    isDisable: false,
                    title: "合同状态",
                    type: "text",
                    value: "",
                    key: "status"
                }, 
                
                {
                    horizontal: true,
                    isShow: false,
                    isDisable: false,
                    title: "服务顾问",
                    type: "textArray",
                    value: "",
                    key: "serviceConsultant"
                }, 
                
                {
                    horizontal: true,
                    isShow: false,
                    isDisable: false,
                    title: "签单时间",
                    type: "text",
                    value: "",
                    key: "signTime"
                }, 
                {
                    horizontal: true,
                    isShow: false,
                    isDisable: false,
                    title: "任务起始日",
                    type: "text",
                    value: "",
                    key: "orderTime"
                }, 
               
            ],
            },
            {
                line: [{ // 服务项目
                    horizontal: false,
                    isShow: true,
                    isDisable: false,
                    title: "",
                    tabletext: "项目步骤",
                    type: "step",
                    value: [],
                    key: "projectStep"
                }, ]
            },
            {
                line: [{
                    horizontal: false,
                    isShow: true,
                    isDisable: false,
                    title: "",
                    tabletext: "赠送的服务项目",
                    type: "give-service-item",
                    contactMoney: "2200.00", // 合同金额
                    value: [],
                    key: "give",
                    // buttons: [{
                    //     text: "添加服务项",
                    //     active: "add_free_serves",
                    //     // authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("添加服务项目", buttonAuth) : 1
                    // }, ]
                }, ]
            },
            {
                line: [{
                    horizontal: false,
                    isShow: true,
                    isDisable: false,
                    title: "业务类型名称",
                    type: "text",
                    value: "",
                    key: "professionName"
                }, {
                    horizontal: false,
                    isShow: true,
                    isDisable: false,
                    title: "业务类型备注",
                    type: "textarea",
                    value: "",
                    key: "professionDes"
                }],
            },
            {
                line: [{
                    horizontal: false,
                    isShow: true,
                    isDisable: false,
                    title: "项目要求",
                    type: "textarea",
                    value: "",
                    key: "projectRequirement"
                }],
            },
            {
                line: [{
                    horizontal: false,
                    isShow: true,
                    isDisable: false,
                    title: "项目所需资料",
                    type: "textarea",
                    value: "",
                    key: "projectRequiredInformation"
                }],
            },
            {
                line: [{
                    horizontal: false,
                    isShow: true,
                    isDisable: false,
                    title: "项目成果",
                    type: "textarea",
                    value: "",
                    key: "projectResults"
                }],
            },
        ],
            
        },
    }
    let index = 0
    if (data.length > 0) {
        for (let i = 0; i < contractDrawerData.data.modelList.length; i++) {
            let element = contractDrawerData.data.modelList[i].line;
            for (let j = 0; j < element.length; j++) {
                let e = element[j];
                e.value = data[index].value == null ? "" : data[index].value
                e.isShow = data[index].isShow
                e.isDisable = data[index].isDisable
                index++
            }
        }

    }
    return contractDrawerData
}

/**
 * 合同信息抽屉数据提交前格式化
 * @param {*} data 
 */
export function formatContractInfoDrawerData(data) {
    let list = []
    data[0].line.forEach(element => {
        let dd = {
            title: element.title,
            value: element.value,
            isShow: element.isShow,
            isDisable: element.isDisable,
            key: element.key
        }
        list.push(dd)
    });
    data[1].line.forEach(element => {
        let dd = {}
        if (element.title == '合同有效期') {
            dd = {
                title: element.title,
                value1: element.value.time1,
                value2: element.value.time2,
                isShow: element.isShow,
                isDisable: element.isDisable,
                key: element.key
            }
        } else {
            dd = {
                title: element.title,
                value: element.value,
                isShow: element.isShow,
                isDisable: element.isDisable,
                key: element.key
            }
        }
        list.push(dd)
    });

    let serves2 = [] // 赠送的服务项
    for (let index = 0; index < data[2].line[0].value.length; index++) {
        let element = data[2].line[0].value[index];
        let serve = {
            serviceItem: element.serviceItem, // 服务项名称
            serviceTime: element.serviceTime, // 服务项周期
        }
        serves2.push(serve)
    }
    list.push({
        title: "赠送的服务项目",
        isShow: data[2].line[0].isShow,
        isDisable: data[2].line[0].isDisable,
        value: serves2,
        key: data[2].line[0].key
    })

    if (data[3].line[0].key === 'proNum') {
        data[3].line.forEach(element => {
            let cc = {
                title: element.tabletext,
                value: element.value,
                isShow: element.isShow,
                isDisable: element.isDisable,
                key: element.key
            }
            list.push(cc)
        });
    
        let des = {
            title: "备注",
            value: data[4].line[0].value,
            isShow: data[4].line[0].isShow,
            isDisable: data[4].line[0].isDisable,
            key: data[4].line[0].key
        }
        list.push(des)
    } else {
        let des = {
            title: "备注",
            value: data[3].line[0].value,
            isShow: data[3].line[0].isShow,
            isDisable: data[3].line[0].isDisable,
            key: data[3].line[0].key
        }
        list.push(des)
    }

    return list
}

/**
 * 合同回款信息抽屉json数据
 * @param {*} title 
 * @param {*} data 
 */
export function contractReturnedMoneyDrawerData(title, data, buttonAuth) {
    let item_data = {
        line: [{
            horizontal: true,
            isShow: true,
            isDisable: false,
            title: "",
            type: "table",
            summary: false,
            // tableTitle: "2021-03-01回款计划",
            menu: [{
                key: "voucherNo",
                value: "收款单号",
                type: "text",
                width: "",
            }, {
                key: "money",
                value: "收款金额",
                type: "text",
                width: "80",
            }, {
                key: "auditedIncome",
                value: "已收款",
                type: "text",
                width: "80",
            }, {
                key: "accruedAssets",
                value: "未收款",
                type: "text",
                width: "80",
            }, {
                key: "addTime",
                value: "收款时间",
                width: "80",
                type: "text",
            }, {
                key: "status",
                value: "收款状态",
                type: "text",
                width: "80",
            }, {
                key: "payee",
                value: "收款人",
                type: "text",
                width: "100",
            }, {
                key: "",
                value: "附件",
                type: "file",
                width: "100",
            }, ],
            tableData: data
        }]
    }
    let contractDrawerData = {
            title: "合同",
            flag: true,
            CardList: [{
                    text: "合同信息",
                    active: "info",
                }, {
                    text: "收款记录",
                    active: "huikuan",
                },
                {
                    text: "收费情况",
                    active: "shoufei",
                }, {
                    text: "合同支出",
                    active: "zhichu",
                },
                {
                    text: "合同附件",
                    active: "fujian",
                },
            ],
            cardIndex: 1,
            hasSetting: true,
            settingOperList: [{
                text: "删除合同",
                active: "delete_contract",
                authority: 1
            }, {
                text: "手动结束",
                active: "end_the_manual",
                authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("手动结束", buttonAuth) : 3

            }, {
                text: "操作记录",
                active: "operating_record_contract",
                authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("操作记录", buttonAuth) : 1
            }, ],
            buttonList: [{
                text: "回款",
                icon: "el-icon-money",
                active: "huikuan",
                authority: 3
            }, ],
            data: {
                title: title,
                isLink: true,
                customer: 0, // 关联客户,与商机状态关联,(只有商机状态为成交才有值),其他时候为空字符串
                isDisable: false, // 是否只读
                butArray: [{
                    text: "修改计划",
                    active: ""
                }],
                modelList: [item_data]
            }
        }
        // if (data.length > 0) {
        //     contractDrawerData.data.modelList = []
        //     for (let i = 0; i < data.length; i++) {
        //         let element = data[i];
        //         // item_data.line[0].tableTitle = element.tableTitle
        //         item_data.line[0].tableData = element
        //     }
        // }
        //         contractDrawerData.data.modelList.push(JSON.parse(JSON.stringify(item_data)))

    return contractDrawerData
}

/**
 * 合同收费信息抽屉json数据
 * @param {*} title 
 * @param {*} data 
 */
export function contractShouFeiDrawerData(title, data, buttonAuth) {
    let item_data = {
        line: [{
            horizontal: true,
            isShow: true,
            isDisable: false,
            title: "",
            type: "table",
            summary: true,
            menu: [{
                key: "serviceList",
                value: "服务项",
                type: "text",
                width: "",
            }, {
                key: "realMoney",
                value: "总应收",
                width: "150",
                type: "text",
            }, {
                key: "received",
                value: "已收",
                type: "text",
                width: "150",
            }, {
                key: "unreceived",
                value: "未收",
                type: "text",
                width: "150",
            }, ],
            tableData: []
        }]
    }
    let contractDrawerData = {
        title: "合同",
        flag: true,
        CardList: [{
                text: "合同信息",
                active: "info",
            }, {
                text: "收款记录",
                active: "huikuan",
            },
            {
                text: "收费情况",
                active: "shoufei",
            }, {
                text: "合同支出",
                active: "zhichu",
            },
            {
                text: "合同附件",
                active: "fujian",
            },
        ],
        cardIndex: 2,
        hasSetting: true,
        settingOperList: [{
            text: "删除合同",
            active: "delete_contract",
            authority: 1
        }, {
            text: "手动结束",
            active: "end_the_manual",
            authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("手动结束", buttonAuth) : 3

        }, {
            text: "操作记录",
            active: "operating_record_contract",
            authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("操作记录", buttonAuth) : 1
        }, ],
        buttonList: [{
            text: "回款",
            icon: "el-icon-money",
            active: "add_huikuan",
            authority: 3
        }, ],
        data: {
            title: title,
            isLink: true,
            customer: 0, // 关联客户,与商机状态关联,(只有商机状态为成交才有值),其他时候为空字符串
            isDisable: false, // 是否只读
            modelList: []
        }
    }
    if (data.length > 0) {
        contractDrawerData.data.modelList = []
        item_data.line[0].tableData = data
        contractDrawerData.data.modelList.push(JSON.parse(JSON.stringify(item_data)))

    }

    return contractDrawerData
}

/**
 * 获取合同支出情况列表
 * @param {*} title 
 * @param {*} data 
 */
export function contractExpendDrawerData(title, data, buttonAuth) {
    let item_data = {
        line: [{
            horizontal: true,
            isShow: true,
            isDisable: false,
            title: "",
            type: "expend",
            value: {}
        }]
    }
    let contractDrawerData = {
        title: "合同",
        flag: true,
        CardList: [{
                text: "合同信息",
                active: "info",
                flag: 0
            }, {
                text: "收款记录",
                active: "huikuan",
                flag: 0
            },
            {
                text: "收费情况",
                active: "shoufei",
                flag: 0
            }, {
                text: "合同支出",
                active: "zhichu",
                flag: 0
            },
            {
                text: "合同附件",
                active: "fujian",
                flag: 0
            },
        ],
        cardIndex: 3,
        hasSetting: true,
        settingOperList: [{
            text: "删除合同",
            active: "delete_contract",
            authority: 1
        }, {
            text: "手动结束",
            active: "end_the_manual",
            authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("手动结束", buttonAuth) : 3

        }, {
            text: "操作记录",
            active: "operating_record_contract",
            authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("操作记录", buttonAuth) : 1
        }, ],
        buttonList: [{
            text: "回款",
            icon: "el-icon-money",
            active: "add_huikuan",
            authority: 3
                // authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("回款", buttonAuth) : 1
        }, ],
        data: {
            title: title,
            isLink: true,
            customer: 0, // 关联客户,与商机状态关联,(只有商机状态为成交才有值),其他时候为空字符串
            isDisable: false, // 是否只读
            modelList: []
        }
    }

    let dd = {
        button_add: 1,
        money: "",
        data: []
    }
    if (data != '') {
        dd = {
            button_add: 1,
            money: data.money,
            data: data.tableData
        }
    }
    item_data.line[0].value = dd
    contractDrawerData.data.modelList.push(item_data)

    return contractDrawerData
}

/**
 * 获取合同附件情况列表
 * @param {*} title 
 * @param {*} data 
 */
export function contractEttachmentDrawerData(title, data, buttonAuth, this_) {
    let item_data = {
        line: [{
            horizontal: true,
            isShow: true,
            isDisable: false,
            title: "",
            type: "fujian",
            value: {
                data: data,
                action: this_.$fileBaseurl + "/uploadImageByMultipartFile",
                headers: {
                    token: "95931b371eb64f4a9c862983ca8a5d15",
                },
                uploadParameter: {
                    systemName: "finance",
                    title: "合同附件",
                },
            }
        }]
    }
    let contractDrawerData = {
        title: "合同",
        flag: true,
        CardList: [{
                text: "合同信息",
                active: "info",
                flag: 0
            }, {
                text: "收款记录",
                active: "huikuan",
                flag: 0
            },
            {
                text: "收费情况",
                active: "shoufei",
                flag: 0
            }, {
                text: "合同支出",
                active: "zhichu",
                flag: 0
            },
            {
                text: "合同附件",
                active: "fujian",
                flag: 0
            },
        ],
        cardIndex: 4,
        hasSetting: true,
        settingOperList: [{
            text: "删除合同",
            active: "delete_contract",
            authority: 1
        }, {
            text: "手动结束",
            active: "end_the_manual",
            authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("手动结束", buttonAuth) : 3

        }, {
            text: "操作记录",
            active: "operating_record_contract",
            authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("操作记录", buttonAuth) : 1
        }, ],
        buttonList: [{
            text: "回款",
            icon: "el-icon-money",
            active: "add_huikuan",
            authority: 3
                // authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("回款", buttonAuth) : 1
        }, ],
        data: {
            title: title,
            isLink: true,
            customer: 0, // 关联客户,与商机状态关联,(只有商机状态为成交才有值),其他时候为空字符串
            isDisable: false, // 是否只读
            modelList: []
        }
    }
    contractDrawerData.data.modelList.push(item_data)

    return contractDrawerData
}

/**
 * 获取合同信息假数据
 */
export function getContractInfoData() {
    let data = [{
        isShow: true,
        isDisable: false,
        value: "执行中", // 合同状态 
    }, {
        isShow: true,
        isDisable: false,
        value: "李四", // 签单人
    }, {
        isShow: true,
        isDisable: false,
        value: "2021-03-31", // 签单日期 date类型
    }, {
        isShow: true,
        isDisable: false,
        value: "财务部", // 来源
    }, {
        isShow: true,
        isDisable: false,
        value: "A", // 产品名称
    }, {
        isShow: true,
        isDisable: false,
        value: "HT20210331", // 合同编号
    }, {
        isShow: true,
        isDisable: false,
        value: "未续签", // 续签状态
    }, {
        isShow: true,
        isDisable: false,
        value: "１年０月", // 签约期限 格式为:XX年XX月
    }, {
        isShow: true,
        isDisable: false,
        value: { // 合同有效期 2021-03-30至2022-02-29 格式为:time1 至 time2
            time1: "2021-03-30",
            time2: "2022-02-29"
        },

    }, {
        isShow: true,
        isDisable: false,
        value: "N", // 自动续签 是->Y,否->N
    }, {
        isShow: true,
        isDisable: false,
        value: "快递", // 凭证交接
    }, {
        isShow: true,
        isDisable: false,
        contactMoney: "2500.00", // 合同金额
        value: [{ // 服务项目
            tableData: {
                name: "记账报税",
                cycle: "每季度",
                data: [{
                    serviceTime: "2020.01-2020.02",
                    price: "10000元/月"
                }],
            },
            item: [{
                isShow: true,
                isDisable: false,
                value: "账本费", //服务名称
            }, {
                isShow: true,
                isDisable: false,
                value: "每年收", // 付费周期
            }, {
                isShow: true,
                isDisable: false,
                value: {
                    label: "提前", // 提前或延后
                    value: "5" // 提前或延后几天
                }, // 催费时间
            }, {
                isShow: true,
                isDisable: false,
                value: "2021-04-01", // 首次催费时间 date格式
            }, {
                isShow: true,
                isDisable: false,
                value: "1200", // 服务项总价
            }, {
                isShow: true,
                isDisable: false,
                value: { // 服务时间 2021-03-30至2022-02-29 格式为:time1 至 time2
                    time1: "2021-03-30",
                    time2: "2022-02-29"
                }
            }, ]
        }, ]
    }, {
        isShow: true,
        isDisable: false,
        contactMoney: "2500.00", // 合同金额
        value: []
    }, {
        isShow: true,
        isDisable: false,
        contactMoney: "2500.00", // 合同金额
        value: [{ // 预收款
            tableData: [{
                time: "2021-06", // 支付时间
                money: "220", //支付金额
                account: "银行转账", // 收款账户
                status: "待审核", // 收款状态
            }],
            item: {
                table: [{
                    serviceName: {
                        name: "",
                        text: "",
                        color: ""
                    },
                    uncollected: "", // 未收金额
                    value: "" // 预收金额
                }],
                money: "", // 总预收金额
                account: "", // 收款账户
                time: "", // 支付时间
                des: "", // 收费备注
                filePath: "", // 上传文件路径
            }
        }, ]
    }, {
        isShow: true,
        isDisable: false,
        value: "N", // 合同备注
    }, ]
    return data
}

/**
 * 获取合同回款计划假数据
 */
export function getContractReturnedMoneyData() {
    let data = [{
        tableTitle: "2021-03-01回款计划", // 按月或其他条件分类
        tableData: [{
            id: "",
            serviceList: "账本费", // 服务项
            serviceTime: "2021-03-30", // 服务时间
            realMoney: "200.00", // 实际应收
            received: "0.00", // 已收 
            unreceived: "200.00" // 未收
        }, {
            id: "",
            serviceList: "账本费",
            serviceTime: "2021-03-30",
            realMoney: "200.00",
            received: "0.00",
            unreceived: "200.00"
        }, {
            id: "",
            serviceList: "账本费",
            serviceTime: "2021-03-30",
            realMoney: "200.00",
            received: "0.00",
            unreceived: "200.00"
        }, ]
    }, {
        tableTitle: "2021-04-01回款计划", // 按月或其他条件分类
        tableData: [{
            id: "",
            serviceList: "账本费", // 服务项
            serviceTime: "2021-03-30", // 服务时间
            realMoney: "200.00", // 实际应收
            discounts: "0.00", // 临时优惠
            received: "0.00", // 已收 
            unreceived: "200.00" // 未收
        }, {
            id: "",
            serviceList: "账本费",
            serviceTime: "2021-03-30",
            realMoney: "200.00",
            discounts: "0.00",
            received: "0.00",
            unreceived: "200.00"
        }, {
            id: "",
            serviceList: "账本费",
            serviceTime: "2021-03-30",
            realMoney: "200.00",
            discounts: "0.00",
            received: "0.00",
            unreceived: "200.00"
        }, ]
    }, ]
    return data
}

/**
 * 获取收费情况假数据
 */
// export function getContractShouFeiData() {
//     let data = [{
//         id: "",
//         serviceList: "账本费", // 服务项
//         realMoney: "200.60", // 总应收
//         received: "0.00", // 已收 
//         unreceived: "200.00" // 未收
//     }, {
//         id: "",
//         serviceList: "账本费",
//         realMoney: "200.00",
//         discounts: "0.00",
//         received: "0.00",
//         unreceived: "200.00"
//     }, {
//         id: "",
//         serviceList: "账本费",
//         realMoney: "200.00",
//         discounts: "0.00",
//         received: "0.00",
//         unreceived: "200.00"
//     }, ]
//     return data
// }

/**
 * 获取合同支出假数据
*/
// export function getContractExpendData() {
//     let data = {
//         money: "33", // 支出总金额
//         tableData: [{
//             expendNum: "333", // 支出编号
//             expendType: "wev", // 支出类型
//             expendStatus: "待提交", // 支出状态  待提交  待审核  完成  审核不通过
//             time: "123", // 确认支出时间  当状态为完成时自动填充
//             receiptAccount: "vdsd", //收款账户
//             receiptName: "das", // 收款人姓名
//             blank: "ass", // 开户银行  如徽商银行等
//             des: "fsa", // 支出备注
//             file: "sf", // 附件
//             services: [{ // 服务项  该合同的所有服务项  包括赠送的
//                 name: "sf", //服务项名称
//                 money: "32", // 服务项金额
//                 expendMoney: "22", // 服务项支出金额
//                 des: "" // 备注
//             }]
//         }, ]
//     }
//     return data
// }

/**
 * 获取各种标签数据
 */
export function getTagList(arrayList, type, companyId, this_) {
    let requestData = {
        type: type,
        companyId: companyId
    };
    this_.$request(
        "post",
        "/getTagList",
        requestData,
        (data) => {
            if (data.length == 0) {
                // 初次获取该模块标签,读取配置文件
                arrayList.clientTipsList = baseData.getTagList(
                    "客户标签",
                    "client"
                ).value;
                arrayList.pianquList = baseData.getTagList(
                    "片区",
                    "client"
                ).value;
                arrayList.clientLevelList = baseData.getTagList(
                    "客户等级",
                    "client"
                ).value;
                arrayList.customerSourceList = baseData.getTagList(
                    "客户来源",
                    "client"
                ).value;
            } else {
                data.forEach((element) => {
                    if (element.type == "片区") {
                        arrayList.pianquList = []
                        element.value.forEach(element => {
                            let dd = {
                                label: element.name,
                                value: element.value,
                            }
                            arrayList.pianquList.push(dd)
                        });
                    }
                    if (element.type == "客户来源") {
                        arrayList.sourceList = [];
                        element.value.forEach(element => {
                            let dd = {
                                label: element.name,
                                value: element.value,
                            }
                            arrayList.sourceList.push(dd)
                        });
                    }
                    if (element.type == "客户等级") {
                        arrayList.levelList = [];
                        element.value.forEach(element => {
                            let dd = {
                                label: element.name,
                                value: element.value,
                            }
                            arrayList.levelList.push(dd)
                        });
                    }
                    if (element.type == "客户标签") {
                        arrayList.clientTipsList = [];
                        element.value.forEach(element => {
                            let dd = {
                                label: element.name,
                                value: element.value,
                            }
                            arrayList.clientTipsList.push(dd)

                        });
                    }
                });
            }
        },
        (errorMessage) => {
            this_.$message.error(errorMessage);
        }
    );
}

/**
 * 获取界面筛选数据
 * [{label:"",value:""}]
 * @param {*} productList 服务产品
 * @param {*} serviceItemList 服务项目
 * @param {*} companyData  {employeeList:员工列表,departmentList:部门列表} 
 * @param {*} tipsData {clientTipsList:客户标签,pianquList:片区标签,clientLevelList:客户等级,customerSourceList:客户来源}
 * @param {*} industryTypeList  行业分类
 */
export function contractInfoSearchData(productList, serviceItemList, companyData, tipsData, industryTypeList) {
    if (companyData == "") {
        companyData = {
            employeeList: [],
            departmentList: [],
        }
    }
    if (tipsData == "") {
        tipsData = {
            clientTipsList: [],
            pianquList: [],
            clientLevelList: [],
            customerSourceList: [],
        }
    }
    let data = [{
            title: "合同状态",
            type: "tip",
            hasSearch: false,
            flag: "0",
            key: "status",
            value: [
                { text: "待提交", is_active: false, id: "" },
                { text: "待合同审核", is_active: false, id: "" },
                { text: "合同审核不通过", is_active: false, id: "" },
                { text: "待财务审核", is_active: false, id: "" },
                { text: "财务审核不通过", is_active: false, id: "" },
                { text: "执行中", is_active: false, id: "" },
                { text: "待下单", is_active: false, id: "" },
                { text: "已结束", is_active: false, id: "" },
                { text: "服务中", is_active: false, id: "" },
            ],
        }, {
            title: "客户状态",
            type: "tip",
            hasSearch: false,
            flag: "0",
            key: "clientState",
            value: [
                { text: "正常", is_active: false, id: "" },
                { text: "暂停", is_active: false, id: "" },
                { text: "流失", is_active: false, id: "" },
            ],
        }, {
            title: "到期时间",
            type: "tip",
            hasSearch: false,
            flag: "0",
            key: "dateDue",
            value: [
                { text: "3个月内到期", is_active: false, id: "" },
                { text: "2个月内到期", is_active: false, id: "" },
                { text: "本月到期", is_active: false, id: "" },
                { text: "已到期", is_active: false, id: "" },
            ],
        }, {
            title: "续签状态",
            type: "tip",
            hasSearch: false,
            flag: "0",
            key: "renewStatus",
            value: [
                { text: "未续签", is_active: false, id: "" },
                // { text: "原合同续签", is_active: false, id: "" },
                { text: "已建续签合同", is_active: false, id: "" },
            ],
        },
        {
            title: "服务产品",
            type: "tip",
            flag: "0",
            hasSearch: true,
            key: "product",
            search_value: "",
            value: [],
            options: productList,
        },
        {
            title: "服务项目",
            type: "tip",
            flag: "0",
            hasSearch: true,
            key: "serviceItem",
            search_value: "",
            value: [],
            options: serviceItemList,
        },
        // {
        //     title: "收费负责人",
        //     type: "tip",
        //     flag: "0",
        //     hasSearch: true,
        //     key: "charge",
        //     search_value: "",
        //     value: [],
        //     options: companyData.employeeList,
        // },
        {
            title: "跟进人",
            type: "tip",
            flag: "0",
            hasSearch: true,
            key: "single",
            search_value: "",
            value: [],
            options: companyData.employeeList,
        },
        {
            title: "跟进人部门",
            type: "tip",
            flag: "0",
            hasSearch: true,
            key: "signDepartment",
            search_value: "",
            value: [],
            options: companyData.departmentList,
        },
        {
            title: "签单时间",
            type: "times",
            flag: "1",
            hasSearch: false,
            key: "signTime",
            value: "",
        },
        {
            title: "客户标签",
            type: "tip",
            flag: "0",
            hasSearch: true,
            key: "clientTips",
            search_value: "",
            value: [],
            options: tipsData.clientTipsList,
        },
        {
            title: "行业分类",
            type: "tip",
            flag: "0",
            hasSearch: true,
            key: "industryType",
            search_value: "",
            value: [],
            options: industryTypeList,
        },
        {
            title: "收费周期",
            type: "tip",
            hasSearch: false,
            flag: "0",
            key: "unit",
            value: [
                { text: "每月收", is_active: false, id: "" },
                { text: "3个月收", is_active: false, id: "" },
                { text: "半年收", is_active: false, id: "" },
                { text: "一年收", is_active: false, id: "" },
                { text: "一次性收", is_active: false, id: "" },
            ],
        },
        {
            title: "首次收费时间",
            type: "times",
            flag: "1",
            hasSearch: false,
            key: "firstChargeTime",
            value: "",
        },
        {
            title: "片区",
            type: "tip",
            flag: "0",
            hasSearch: true,
            key: "pianqu",
            search_value: "",
            value: [],
            options: tipsData.pianquList,
        },
        {
            title: "客户等级",
            type: "tip",
            flag: "0",
            hasSearch: true,
            key: "clientLevel",
            search_value: "",
            value: [],
            options: tipsData.clientLevelList,
        },
        {
            title: "来源",
            type: "select",
            flag: "1",
            hasSearch: false,
            key: "customerSource",
            value: "",
            options: tipsData.customerSourceList,
        }
    ]
    return data
}

/**
 * 获取派工服务列表表头
 */
export function getContractWorkTableMenuData() {
    let data = [
        {
            key: "clientName",
            name: "客户全称",
            width: "230",
            isDisable: false, // 自定义表头是否显示
            authority: "1"
        }, 
        {
            key: "clientNum",
            name: "客户编号",
            width: "100",
            isDisable: false, // 自定义表头是否显示
            authority: "1"
        }, 
        {
        key: "contractNum",
        name: "合同编号",
        width: "120",
        isDisable: false, // 自定义表头是否显示
        authority: "1"
    }, {
        key: "services",
        name: "服务项",
        width: "100",
        isDisable: false, // 自定义表头是否显示
        authority: "1"
    }, {
        key: "serviceConsultant",
        name: "服务顾问",
        width: "130",
        isDisable: false, // 自定义表头是否显示
        authority: "1"
    }, {
        key: "single",
        name: "签单人",
        width: "130",
        isDisable: false, // 自定义表头是否显示
        authority: "1"
    }, {
        key: "professionName",
        name: "业务类型名称",
        width: "130",
        isDisable: false, // 自定义表头是否显示
        authority: "1"
    }, {
        key: "progress", 
        name: "派工进度",
        width: "130",
        isDisable: false, // 自定义表头是否显示
        authority: "1"
    }, {
        key: "step",
        name: "当前步骤",
        width: "110",
        isDisable: false, // 自定义表头是否显示
        authority: "1"
    }, {
        key: "stepExecute",
        name: "当前步骤执行人员",
        isDisable: false, // 自定义表头是否显示
        authority: "1"
    }, {
        key: "lastStepDoneTime",
        name: "更新时间",
        width: "160",
        isDisable: false, // 自定义表头是否显示
        authority: "1"
    }, {
        key: "orderTime",
        name: "下单时间",
        width: "160",
        isDisable: false, // 自定义表头是否显示
        authority: "1"
    }, ]

    return data
}

/**
 * 获取派工服务列表表头 待办抽屉
 */
export function getContractWorkTableMenuDataByDrawer() {
    let data = [
    {
        key: "contractNum",
        name: "合同编号",
        width: "120",
        isDisable: false, // 自定义表头是否显示
        authority: "1"
    }, {
        key: "services",
        name: "服务项",
        width: "100",
        isDisable: false, // 自定义表头是否显示
        authority: "1"
    }, {
        key: "professionName",
        name: "业务类型名称",
        width: "100",
        isDisable: false, // 自定义表头是否显示
        authority: "1"
    }, {
        key: "status",
        name: "合同状态",
        width: "80",
        isDisable: false, // 自定义表头是否显示
        authority: "1"
    }, {
        key: "dateDue",
        name: "到期日期",
        width: "100",
        isDisable: false, // 自定义表头是否显示
        authority: "1"
    }, {
        key: "serviceConsultant",
        name: "服务顾问",
        width: "100",
        isDisable: false, // 自定义表头是否显示
        authority: "1"
    }, {
        key: "single",
        name: "签单人",
        width: "100",
        isDisable: false, // 自定义表头是否显示
        authority: "1"
    }, {
        key: "signTime",
        name: "签单时间",
        width: "100",
        isDisable: false, // 自定义表头是否显示
        authority: "1"
    }, {
        key: "contractStartTime",
        name: "合同开始时间",
        width: "100",
        isDisable: false, // 自定义表头是否显示
        authority: "1"
    }, {
        key: "step",
        name: "当前步骤",
        width: "100",
        isDisable: false, // 自定义表头是否显示
        authority: "1"
    }, {
        key: "stepExecute",
        name: "当前步骤执行人员",
        width: "120",
        isDisable: false, // 自定义表头是否显示
        authority: "1"
    }, ]

    return data
}


/**
 * 获取派工服务列表表格模拟数据
*/
// export function getContractWorkTableData() {
//     let data = [{
//         id: "",
//         contractNum: "222", // 合同编号
//         clientNum: "333", // 客户编号
//         clientName: "撒", // 客户全称
//         services: [{ text: "大", tip: "服务项全称", color: "" }], // 服务项
//         professionName: "的顺丰到付", // 业务类型名称
//         status: "点的", // 合同状态
//         dateDue: "2021-06-23", // 到期日期
//         serviceConsultant: "的地方", // 服务顾问
//         single: "是飒飒", // 签单人
//         signTime: "2021-02-23", // 签单时间
//         contractStartTime: "2021-02-23", // 合同开始时间
//         step: "点的", // 当前步骤
//         stepExecute: "捱三顶五多", // 当前步骤执行人员
//     }]

//     return data
// }

/**
 * 
 * 合同派工服务抽屉数据
 * @param {*} title  客户全称
 * @param {*} list  合同的具体数据
 * @param {*} buttonAuth  操作按钮权限
*/
// export function getContractWorkDrawerData(title, list, buttonAuth) {
//     let contractDrawerData = {
//         title: "派工服务",
//         flag: false,
//         hasSetting: true,
//         settingOperList: [{
//             text: "正常结束",
//             active: "finish",
//             authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("正常结束", buttonAuth) : 1

//         }, {
//             text: "异常终止",
//             active: "",
//             authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("异常终止", buttonAuth) : 1

//         }, {
//             text: "恢复终止",
//             active: "",
//             authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("恢复终止", buttonAuth) : 1

//         }, {
//             text: "操作记录",
//             active: "operating_record_contract",
//             authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("操作记录", buttonAuth) : 1
//         }, ],
//         buttonList: [{
//             text: "正常结束",
//             icon: "el-icon-money",
//             active: "finish",
//             authority: 3
//                 // authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("回款", buttonAuth) : 1
//         }, ],
//         data: {
//             title: title,
//             customer: 0, // 关联客户,与商机状态关联,(只有商机状态为成交才有值),其他时候为空字符串
//             isDisable: false, // 是否只读
//             modelList: [{
//                 line: [{
//                     horizontal: true,
//                     isShow: false,
//                     isDisable: false,
//                     title: "合同编号",
//                     type: "text",
//                     value: "执行中",
//                 }, 
//                 // {
//                 //     horizontal: true,
//                 //     isShow: false,
//                 //     isDisable: false,
//                 //     title: "客户全称",
//                 //     type: "text",
//                 //     value: "执行中",
//                 // }, 
//                 {
//                     horizontal: true,
//                     isShow: false,
//                     isDisable: false,
//                     title: "客户编号",
//                     type: "text",
//                     value: "执行中",
//                 }, {
//                     horizontal: true,
//                     isShow: false,
//                     isDisable: false,
//                     title: "服务项",
//                     type: "text",
//                     value: "执行中",
//                 }, {
//                     horizontal: true,
//                     isShow: false,
//                     isDisable: false,
//                     title: "合同状态",
//                     type: "text",
//                     value: "执行中",
//                 }, {
//                     horizontal: true,
//                     isShow: false,
//                     isDisable: false,
//                     title: "到期日期",
//                     type: "text",
//                     value: "执行中",
//                 }, {
//                     horizontal: true,
//                     isShow: false,
//                     isDisable: false,
//                     title: "服务顾问",
//                     type: "text",
//                     value: "执行中",
//                 }, {
//                     horizontal: true,
//                     isShow: false,
//                     isDisable: false,
//                     title: "签单人",
//                     type: "text",
//                     value: "执行中",
//                 }, {
//                     horizontal: true,
//                     isShow: false,
//                     isDisable: false,
//                     title: "签单时间",
//                     type: "text",
//                     value: "执行中",
//                 }, {
//                     horizontal: true,
//                     isShow: false,
//                     isDisable: false,
//                     title: "合同开始时间",
//                     type: "text",
//                     value: "执行中",
//                 }, ]
//             }, {
//                 line: [{
//                     horizontal: true,
//                     isShow: false,
//                     isDisable: false,
//                     title: "业务类型名称",
//                     type: "text",
//                     value: "执行中",
//                 }, {
//                     horizontal: true,
//                     isShow: false,
//                     isDisable: false,
//                     title: "业务类型备注",
//                     type: "text",
//                     value: "执行中",
//                 }, ]
//             }, {
//                 line: [{
//                     horizontal: true,
//                     isShow: false,
//                     isDisable: false,
//                     title: "项目要求",
//                     type: "textarea",
//                     value: "执行中",
//                 }, ]
//             }, {
//                 line: [{
//                     horizontal: true,
//                     isShow: false,
//                     isDisable: false,
//                     title: "所需资料",
//                     type: "textarea",
//                     value: "执行中",
//                 }, {
//                     horizontal: true,
//                     isShow: false,
//                     isDisable: false,
//                     title: "", // 文件附件
//                     type: "file",
//                     value: [],
//                 }, ]
//             }, {
//                 line: [{
//                     horizontal: true,
//                     isShow: false,
//                     isDisable: false,
//                     title: "", // 项目步骤
//                     type: "step",
//                     value: [],
//                 }, ]
//             }, {
//                 line: [{
//                     horizontal: true,
//                     isShow: false,
//                     isDisable: false,
//                     title: "项目成果",
//                     type: "textarea",
//                     value: "",
//                 }, ]
//             }, ]
//         }
//     }

//     let index = 0

//     if (list.length > 0) {
//         contractDrawerData.data.modelList.forEach(el_line => {
//             let line = el_line.line
//             line.forEach(element => {
//                 element.isShow = list[index].isShow
//                 element.isDisable = list[index].isDisable
//                 element.value = list[index].value
//                 index++
//             });
//         });
//     }
//     return contractDrawerData
// }

/**
 * 格式化合同派工数据
 * @param {*} data 
 */
export function formatContractWorkDrawerData(data) {
    let list = []
    data.forEach(e => {
        let line = e.line
        line.forEach(element => {
            let dd = {
                title: element.title,
                isShow: element.isShow,
                isDisable: element.isDisable,
                value: element.value,
                key: element.key,
            }
            list.push(dd)
        });
    });

    return list
}