<template>
  <div id="my-drawer">
    <div class="my-drawer-title">
      <div class="my-drawer-title-text">{{ data.title }}</div>
      <div class="my-drawer-title-opers">
        <div
          class="my-drawer-title-oper"
          v-for="(item, index) in data.buttonList"
          :key="index"
          @click="drawerOper(item.active)"
        >
          <i :class="item.icon"></i>{{ item.text }}
        </div>
      </div>
      <div class="my-drawer-title-setting" v-if="data.hasSetting">
        <i class="el-icon-setting"></i>
      </div>
      <div class="my-drawer-title-close" @click="closeUpdateTableDrawer">
        <i class="el-icon-close"></i>
      </div>
    </div>
    <div class="my-drawer-content">
      <div class="my-drawer-content-title" v-if="data.data.title != ''">
        <i class="el-icon-office-building"></i>
        <div class="my-drawer-content-title-name">{{ data.data.title }}</div>
        <div
          class="my-drawer-content-title-customer"
          v-if="data.data.customer != ''"
        >
          <div class="my-drawer-content-title-customer-text">
            <span>关联客户:</span>
            {{ data.data.customer }}
          </div>
          <div
            class="my-drawer-content-title-customer-show"
            @click="drawerOper('showDetail')"
          >
            查看
          </div>
        </div>
      </div>
      <div
        class="my-drawer-content-model"
        v-for="(item, index) in data.data.modelList"
        :key="index"
      >
        <div
          :class="{
            'my-drawer-content-model-line': it.isShow,
          }"
          v-for="(it, ii) in item.line"
          :key="ii"
        >
          <div
            class="my-drawer-content-model-line-key"
            v-if="it.type != 'record' && it.title != '' && it.isShow"
          >
            {{ it.title }}
          </div>
          <div
            class="my-drawer-content-model-line-value"
            v-if="it.type == 'text' && it.isShow"
          >
            {{ it.value }}
          </div>
          <!-- <div
            class="my-drawer-content-model-line-value"
            v-if="it.type == 'textColor' && it.isShow"
          >
            {{ it.value }}
            <div
              v-for="(ida, i) in it.value"
              :key="i"
              :style="{ color: ida.color }"
            >
              {{ ida.text }}
            </div> 
          </div> -->

          <div
            class="my-drawer-content-model-line-value"
            v-if="it.type == 'textArray' && it.isShow"
          >
            <div
              class="my-drawer-content-model-line-value-text"
              v-for="(ida, i) in it.value"
              :key="i"
            >
              {{ ida }}
            </div>
          </div>
          <div
            class="my-drawer-content-model-line-value"
            v-if="it.type == 'input' && it.isShow"
          >
            <el-input
              v-model="it.value"
              :disabled="it.isDisable"
              placeholder="请输入内容"
              @blur="saveDrawerData"
            ></el-input>
          </div>
          <div
            class="my-drawer-content-model-line-value"
            v-if="it.type == 'textarea' && it.isShow"
          >
            <el-input
              type="textarea"
              :rows="2"
              v-model="it.value"
              :disabled="it.isDisable"
              placeholder="请输入内容"
              @blur="saveDrawerData"
            ></el-input>
          </div>
          <div
            class="my-drawer-content-model-line-value"
            v-if="it.type == 'select' && it.isShow"
          >
            <el-select
              v-model="it.value"
              placeholder="请选择"
              filterable
              :disabled="it.isDisable"
              @change="saveDrawerData"
            >
              <el-option
                v-for="tt in it.options"
                :key="tt.value"
                :label="tt.label"
                :value="tt.value"
              >
              </el-option>
            </el-select>
          </div>
          <div
            class="my-drawer-content-model-line-value"
            v-if="it.type == 'time' && it.isShow"
          >
            <el-date-picker
              v-model="it.value"
              type="date"
              :disabled="it.isDisable"
              placeholder="选择日期"
              @blur="saveDrawerData"
              @change="saveDrawerData"
            >
            </el-date-picker>
          </div>
          <div
            class="my-drawer-content-model-line-value"
            v-if="it.type == 'tags' && it.isShow"
          >
            <el-tag
              :key="tag"
              v-for="tag in it.value"
              closable
              :disable-transitions="false"
              @close="handleClose(tag, ii, index)"
              effect="plain"
            >
              {{ tag }}
            </el-tag>
            <el-button
              class="button-new-tag"
              size="small"
              @click="addTags(index, ii)"
              >+ 添加</el-button
            >
          </div>
          <div
            class="my-drawer-content-model-line-value-column"
            v-if="it.type == 'record' && it.isShow"
          >
            <div
              class="my-drawer-content-model-line-value-record"
              v-for="(record, i) in it.value"
              :key="i"
            >
              <div class="my-drawer-content-model-line-value-record-icon">
                <el-image
                  style="width: 50px; height: 50px"
                  :src="record.icon"
                  fit="cover"
                ></el-image>
              </div>
              <div class="my-drawer-content-model-line-value-record-cont">
                <div class="my-drawer-content-model-line-value-record-cont-n">
                  {{ record.name }}
                </div>
                <div class="my-drawer-content-model-line-value-record-cont-l">
                  {{ record.content }}
                </div>
                <div class="my-drawer-content-model-line-value-record-cont-l">
                  跟进阶段:{{ record.stage }}
                </div>
                <div class="my-drawer-content-model-line-value-record-cont-l">
                  下次联系时间:{{ record.time }}
                </div>
              </div>
              <div class="my-drawer-content-model-line-value-record-time">
                {{ record.operTime }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["drawerData"],
  data() {
    return {
      data: this.drawerData,
    };
  },
  watch: {
    drawerData() {
      //父组件值变化->>子组件也变化
      this.data = this.drawerData;
    },
    data(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {
    closeUpdateTableDrawer() {
      this.$emit("closeUpdateTableDrawer");
    },
    saveDrawerData() {
      this.$emit("saveDrawerData");
    },
    handleClose(tag, j, i) {
      this.$emit("tagsHandleClose", { tag, i, j });
    },
    addTags(i, j) {
      this.$emit("addTags", { i, j });
    },
    drawerOper(active) {
      this.$emit("drawerOper", active);
    },
  },
};
</script>

<style lang="less" scoped>
.my-drawer-title {
  width: 100%;
  height: 45px;
  display: flex;
  // position: fixed;
  border-bottom: 1px solid @boderColor;
  .my-drawer-title-text {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 20px;
    font-size: 15px;
  }
  .my-drawer-title-opers {
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
    .my-drawer-title-oper {
      width: auto;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      i {
        margin-right: 2px;
      }
    }
    .my-drawer-title-oper:hover {
      cursor: pointer;
      color: @activeColor;
    }
  }
  .my-drawer-title-setting {
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #868686;
    border-left: 1px solid @boderColor;
    border-right: 1px solid @boderColor;
  }
  .my-drawer-title-setting:hover {
    cursor: pointer;
    color: @activeColor;
  }
  .my-drawer-title-close {
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .my-drawer-title-close:hover {
    color: @closeColor;
  }
}
.my-drawer-content {
  width: 98%;
  flex: 1;
  max-height: 880px;
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  // margin-top: 45px;
  margin-bottom: 40px;
  overflow-y: auto;
  .my-drawer-content-title {
    width: 98%;
    height: 40px;
    margin: 10px;
    display: flex;
    align-items: center;
    color: rgb(143, 147, 150);
    .my-drawer-content-title-name {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 15px;
      margin-left: 5px;
    }
    .my-drawer-content-title-customer {
      width: 260px;
      height: 100%;
      display: flex;
      align-items: center;
      margin-right: 10px;
      font-size: @contentFontSize;
      .my-drawer-content-title-customer-text {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        color: #333;
        span {
          margin-right: 5px;
        }
      }
      .my-drawer-content-title-customer-show {
        width: 50px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: @activeColor3;
        cursor: pointer;
      }
    }
  }
  .my-drawer-content-model {
    width: 657px;
    height: auto;
    border: 1px solid @boderColor;
    border-bottom: none;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    margin: 10px;
    .my-drawer-content-model-line {
      // width: 327px;
      width: 658px;
      height: auto;
      min-height: 40px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid @boderColor;
      box-sizing: border-box;
      font-size: @contentFontSize;
      .my-drawer-content-model-line-key {
        width: 100px;
        color: rgb(155, 161, 167);
      }
      .my-drawer-content-model-line-value {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 5px;
        .my-drawer-content-model-line-value-text {
          width: auto;
          margin: 0 5px;
        }
      }
      .my-drawer-content-model-line-value-column {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        .my-drawer-content-model-line-value-record {
          width: 100%;
          height: 90px;
          display: flex;
          border-bottom: 1px solid @boderColor;
          margin: 5px 0;
          font-size: 12px;
          .my-drawer-content-model-line-value-record-icon,
          .my-drawer-content-model-line-value-record-time {
            height: 100%;
            width: 60px;
            display: flex;
            justify-content: center;
          }
          .my-drawer-content-model-line-value-record-cont {
            display: flex;
            flex-direction: column;
            flex: 1;
            height: 100%;
            .my-drawer-content-model-line-value-record-cont-n,
            .my-drawer-content-model-line-value-record-cont-l {
              width: 100%;
              margin-left: 10px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              height: 20px;
              color: #333;
            }
            .my-drawer-content-model-line-value-record-cont-l {
              color: rgb(171, 179, 196);
            }
          }
        }
        .my-drawer-content-model-line-value-record:nth-last-child(1) {
          border-bottom: none;
          margin-bottom: 10px;
        }
      }
    }
  }

  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
