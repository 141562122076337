<template>
  <div id="role-authority" class="customScrollBar">
    <!-- 权限属性数据 -->
    <div class="line" v-for="(item, index) in value" :key="index">
      <div class="title">{{ item.name }}</div>
      <div class="value">
        <el-radio-group v-model="item.authority">
          <el-radio :label="'1'">读写</el-radio>
          <el-radio :label="'2'">只读</el-radio>
          <el-radio :label="'3'">不可见</el-radio>
        </el-radio-group>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["roleAuthority"],
  data() {
    return {
      value: this.roleAuthority,
    };
  },
  watch: {
    roleAuthority() {
      //父组件值变化->>子组件也变化
      this.value = this.roleAuthority;
    },
    value(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {},
};
</script>

<style lang="less" scoped>
#role-authority {
  width: 100%;
  height: 500px;
  min-height: 500px;
  max-height: 500px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  > .line {
    width: 100%;
    height: 45px;
    min-height: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-bottom: 1px solid @borderColor;
    padding: 0 10px;
    > .title {
      width: 40%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 15px;
    }
    > .value {
      width: 60%;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}
</style>