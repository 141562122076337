<template>
  <div id="human_mine">
    <!-- 我的人才界面 -->
    <div class="screen">
      <div class="screen-line">
        <div class="screen-fiftrate">
          <el-popover
            placement="bottom-start"
            width="250"
            trigger="manual"
            :visible-arrow="false"
            v-model="popoData.popoVisible"
          >
            <div class="popover-search">
              <popo_search
                :list="popoData.list"
                @cancel="popoCanael"
                @reset="popoReset"
                @makeSure="popoMakeSure"
              ></popo_search>
            </div>
            <div slot="reference" @click="popoverOpen">
              筛选 <i class="el-icon-s-operation"></i>
            </div>
          </el-popover>
        </div>
        <div class="screen-search">
          <el-select
            v-model="searchData.userId"
            placeholder="请选择商机跟进人"
            @change="seleteInitTableData"
            filterable
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.userId"
              :label="item.userName"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-search">
          <el-cascader
            v-model="searchData.department"
            placeholder="请选择部门"
            :options="searchData.departTreeData"
            @change="initTableData"
            clearable
            :show-all-levels="false"
            :props="{ checkStrictly: true, value: 'id' }"
            filterable
          ></el-cascader>
        </div>
        <div class="screen-search">
          <el-input
            placeholder="商机名称/联系人/手机号"
            v-model="searchData.input"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="screen-active"></div>
      </div>
      <div class="screen-line" v-if="searchData.screenList.length > 0">
        <div class="title">筛选条件:</div>
        <el-tag
          v-for="(tag, index) in searchData.screenList"
          :key="index"
          closable
          size="small"
          @close="closeScreen(index)"
        >
          {{ tag.name }}
        </el-tag>
      </div>
    </div>
    <div class="table-content">
      <div class="content">
        <div class="tabs">
          <div
            :class="{ tab: true, 'is-active': tabindex == index }"
            v-for="(item, index) in tabList"
            :key="index"
          >
            {{ item.text }}
          </div>
        </div>
        <div class="tables">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            @row-click="rowClick"
            style="width: 100%"
            height="98%"
            @selection-change="handleSelectionChange"
            :row-style="changeRowTextColor"
            :default-sort="defaultSort"
            @sort-change="sortChange"
            v-loading="loading"
          >
            <el-table-column
              type="selection"
              width="35"
              fixed
            ></el-table-column>
            <el-table-column type="index" width="55" fixed> </el-table-column>
            <template v-for="(item, index) in tabMenu">
              <el-table-column
                :key="index"
                :property="item.key"
                :fixed="index == 0"
                :label="item.name"
                v-if="item.authority == 1"
                :sortable="item.key == 'latestContactTime' ? 'custom' : false"
                :min-width="item.key == 'name' ? 200 : 110"
                :show-overflow-tooltip="true"
              >
                <template slot-scope="scope">
                  <div>
                    {{ scope.row[item.key] }}
                  </div>
                </template>
              </el-table-column>
            </template>
          </el-table>
        </div>
        <div class="bottom">
          <div class="thread-table-card-bottom">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="pageData.currentPage"
              :page-size="pageData.pageSize"
              layout="total,sizes, prev, pager, next,jumper"
              :total="pageData.total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as humanData from "./js/humanData.js";
import popo_search from "@/components/popo-search.vue";
import my_drawer from "@/components/my-drawer.vue";
import my_dialog from "@/components/dialog.vue";
export default {
  components: {
    my_drawer,
    popo_search,
    my_dialog,
  },
  data() {
    return {
      tabindex: 0,
      loading: false,
      tabList: [{ text: "我的人才" }, { text: "全部人才" }],
      tableMenu: [],
      searchData: {
        input: "",
        checked: false,
        screenButton_improt: 1, // 导出客户商机按钮的权限 1->读写;2->只读;3->不可见
        userId: "", //
        userList: [],
        tempUserId: "",
        departTreeData: [],
        department: "",
        screenList: [],
      },
      popoData: {
        popoVisible: false,
        list: [],
      },
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      defaultSort: { prop: "", order: "" },
      tabMenu: humanData.getTableMenu("humanMine"),
      tableData: [],
      multipleSelection: [],
    };
  },
  methods: {
    /**
     * 选择人员时对all的单独处理
     */
    seleteInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.tempAllEmpolyeeList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.initTableData();
    },
    /**
     * 初始化表格数据
     */
    initTableData() {},
    /**
     * 表格行点击事件
     */
    rowClick(row, column, event) {},
    /**
     * 筛选
     */
    popoCanael() {},
    popoReset() {},
    popoMakeSure() {},
    popoverOpen() {},

    /**
     * 表格排序事件
     */
    sortChange(data) {
      // console.log("表格排序事件", data.prop, data.order);
      this.defaultSort.prop = data.prop;
      this.defaultSort.order = data.order == null ? "" : data.order;
      // console.log("表格排序事件", this.defaultSort);
      this.initTableData();
    },
    /**
     * 多选框事件
     */
    handleSelectionChange(val) {
      this.multipleSelection = [];
      val.forEach((element) => {
        this.multipleSelection.push(element.id);
      });
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },

    /**
     * 根据商机状态,修改行颜色
     */
    changeRowTextColor(data) {
      if (data.row.threadStatus == "已成交" || data.row.flag == 1) {
        return {
          color: "red",
          height: "71.5px",
        };
      } else {
        return {
          height: "71.5px",
        };
      }
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
#human_mine {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .screen-fiftrate {
    width: 65px;
    min-width: 65px;
    height: 30px;
    border: 1px solid @boderColor;
    box-sizing: border-box;
    color: @boderActiveColor;
    font-size: @contentFontSize;
    margin: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    transition: 0.3s;
    i {
      margin: 0 4px;
    }
    &:hover {
      cursor: pointer;
      border-color: @boderActiveColor;
    }
  }
  .screen-search {
    width: 210px;
    min-width: 210px;
    height: 30px;
    margin-right: 5px;
  }
  .screen-active {
    flex: 1;
  }
  .table-content {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    > .content {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      > .tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        > .tab {
          width: auto;
          min-width: 100px;
          height: 100%;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
          &:hover {
            cursor: pointer;
            color: @activeColor;
          }
        }
        > .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
      }
      > .tables {
        flex: 1;
        width: 98%;
      }
      > .bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }
}
</style>