<template>
  <div id="invoice-registerll">
    <!-- 理票登记表格 -->
    <div class="invoice-register-content" v-if="value.flag">
      <!-- 输入框状态 -->
      <div class="table-line head">
        <div class="cell">票据类型</div>
        <div class="cell">数量</div>
        <div class="cell">金额</div>
        <div class="cell1">备注</div>
      </div>
      <div class="table-line" v-for="(item, index) in value.data" :key="index">
        <div class="cell">{{ item.name }}</div>
        <div class="cell">
          <el-input v-model="item.num" @change="save"></el-input>
        </div>
        <div class="cell">
          <el-input v-model="item.money" @change="save"></el-input>
        </div>
        <div class="cell1">
          <el-input v-model="item.des" @change="save"></el-input>
        </div>
      </div>
      <div class="table-line">
        <div class="cell">合计</div>
        <div class="cell2">{{ value.total }}</div>
      </div>
    </div>
    <div class="invoice-register-content" v-else>
      <!-- 文本状态 -->
      <div class="table-line head">
        <div class="cell">票据类型</div>
        <div class="cell">数量</div>
        <div class="cell">金额</div>
        <div class="cell1">备注</div>
      </div>
      <div class="table-line" v-for="(item, index) in value.data" :key="index">
        <div class="cell">{{ item.name }}</div>
        <div class="cell">{{ item.num }}</div>
        <div class="cell">{{ item.money }}</div>
        <div class="cell1">{{ item.des }}</div>
      </div>
      <div class="table-line">
        <div class="cell">合计</div>
        <div class="cell2">{{ value.total }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["invoiceRegisterDrawerData"],
  data() {
    return {
      value: this.invoiceRegisterDrawerData,
      //   value: {
      //     flag: true,
      //     data: [
      //       {
      //         name: "销售专票",
      //         num: "32",
      //         money: "42313",
      //         des: "asfvbf",
      //       },
      //     ],
      //     total:""
      //   },
    };
  },
  watch: {
    invoiceRegisterDrawerData() {
      //父组件值变化->>子组件也变化
      this.data = this.invoiceRegisterDrawerData;
    },
    data(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {
    save() {
      this.$emit("save");
    },
  },
};
</script>

<style lang="less" scoped>
#invoice-registerll {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  .invoice-register-content {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    .table-line {
      height: 40px;
      width: 100%;
      display: flex;
      align-items: center;
      border-bottom: 1px solid @boderColor;
      &:nth-last-child(1) {
        border: none;
      }
      .cell {
        width: 0;
        height: 100%;
        flex: 1;
        display: flex;
        align-items: center;
        padding-left: 5px;
        box-sizing: border-box;
      }
      .cell1 {
        width: 0;
        height: 100%;
        flex: 2;
        display: flex;
        align-items: center;
      }
      .cell2 {
        width: 0;
        flex: 4;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
    .head {
      background-color: @activeBackground;
      .cell,
      .cell1 {
        justify-content: center;
      }
    }
  }
}
</style>