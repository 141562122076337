<template>
  <!-- 派工池 -->
  <div id="dispatchPool">
    <!-- 我的任务 -->
    <div class="dispatchPool-screen">
      <div class="dispatchPool-screen-line">
        <div class="dispatchPool-screen-search">
          <el-input
            v-model="searchData.input"
            placeholder="请输入客户全称/运单号/收件人"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="dispatchPool-screen-search">
          <el-select
            placeholder="请选择派工人"
            v-model="searchData.launcher"
            @change="selectInitTableData"
            filterable
          >
            <el-option
              v-for="item in searchData.allEmployeeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="dispatchPool-screen-search">
          <el-cascader
            v-model="searchData.location"
            placeholder="请选择办事地点"
            :options="list"
            collapse-tags
            :props="{
              multiple: true,
              value: 'id',
              label: 'name',
              emitPath: false,
            }"
            @change="initTableData"
            clearable
          ></el-cascader>
        </div>
        <div class="dispatchPool-screen-search">
          <el-date-picker
            v-model="searchData.time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="initTableData"
          >
          </el-date-picker>
        </div>
        <div class="dispatchPool-screen-active"></div>
        <div
          class="dispatchPool-screen-but"
          @click="handleClick('', 0)"
          v-showFunction="{
            active: 'batchDispatch',
            array: allFunctionButtons,
          }"
        >
          批量派工
        </div>
      </div>
    </div>

    <div class="table-content">
      <div class="dispatchPool-table-card">
        <div class="dispatchPool-table-card-tabs">
          <div
            :class="{
              'dispatchPool-table-card-tab': true,
              'is-active': tabIndex == 0,
            }"
            @click="changeTab('0')"
          >
            全部派工
          </div>
          <div
            :class="{
              'dispatchPool-table-card-tab2': true,
              'is-active2': tabIndex == 3,
            }"
            @click="changeTab('3')"
          >
            今天
          </div>
          <div
            :class="{
              'dispatchPool-table-card-tab2': true,
              'is-active2': tabIndex == 4,
            }"
            @click="changeTab('4')"
          >
            明天
          </div>
          <div
            :class="{
              'dispatchPool-table-card-tab2': true,
              'is-active2': tabIndex == 5,
            }"
            @click="changeTab('5')"
          >
            后天
          </div>
          <div
            :class="{
              'dispatchPool-table-card-tab2': true,
              'is-active2': tabIndex == 6,
            }"
            @click="changeTab('6')"
          >
            三天内
          </div>
          <div
            :class="{
              'dispatchPool-table-card-tab2': true,
              'is-active2': tabIndex == 7,
            }"
            @click="changeTab('7')"
          >
            七天内
          </div>
          <div
            :class="{
              'dispatchPool-table-card-tab2': true,
              'is-active2': tabIndex == 2,
            }"
            @click="changeTab('2')"
          >
            已过期
          </div>
        </div>
        <div class="dispatchPool-content">
          <el-table
            :data="tableData"
            highlight-current-row
            :row-style="{ height: '60px' }"
            style="width: 100%"
            height="98%"
            v-loading="loading"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column type="index" width="40" fixed> </el-table-column>
            <el-table-column
              prop="clientName"
              label="公司名称"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="clientNum" label="客户编号">
            </el-table-column>
            <el-table-column prop="name" label="派工内容">
              <template slot-scope="scope">
                <i v-if="scope.row.isUrgent" class="iconfont icon-jiaji"></i>
                {{ scope.row.name }}
              </template>
            </el-table-column>
            <el-table-column prop="launchTime" label="派工时间">
            </el-table-column>
            <el-table-column prop="" label="待办时间" width="200">
              <template slot-scope="scope">
                {{ scope.row.expectTime + " - " + scope.row.endTime }}
              </template>
            </el-table-column>
            <el-table-column prop="locationName" label="办事地点">
              <template slot-scope="scope">
                <span
                  @click.stop="handleClick(scope.row, 2)"
                  style="color: #40a3ff; cursor: pointer;"
                  >{{ scope.row.locationName }}</span
                >
              </template>
            </el-table-column>
            <el-table-column prop="launchName" label="派工人">
            </el-table-column>

            <el-table-column prop="remark" label="备注"></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  type="success"
                  size="mini"
                  plain
                  icon="el-icon-check"
                  @click="handleClick(scope.row, 1)"
                  v-showFunction="{
                    active: 'dispatch',
                    array: allFunctionButtons,
                  }"
                  >派工</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="dispatchPool-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @handleAvatarSuccess="handleAvatarSuccess"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import my_dialog from "@/components/dialog.vue";
import { waitDealLocation } from "@/minixs.js";
export default {
  components: {
    my_dialog,
  },
  data() {
    return {
      loading: false,
      tabIndex: 0,
      searchData: {
        input: "",
        allUserList: [],
        allEmployeeList: [],
        allEmployeeIdList: [],
        time: [],
        status: 0,
        tabFlag: null,
        launcher: "",
        userIdList: [],
        userList: [],
        launchList: [],
      },
      tableData: [],
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      list: [],
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "dispatchPool"
      ),
      dialogData: {
        flag: false,
        width: "30%",
        title: "运单详情",
        buttonArray: [
          {
            text: "确认",
            active: "confirm",
          },
        ],
        data: [],
      },
      temDispatchPool: "",
      multipleSelection: [],
      allEmployeeList: [],
    };
  },
  mixins: [waitDealLocation],
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "dispatchPool",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;

        this.getEmployeeListForDownBox();
      });
    },
    /**
     * 获取所有人员列表
     */
    getEmployeeListForDownBox() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/user/getEmployeeListForDownBox",
        requestData,
        (data) => {
          this.allEmployeeList = [];
          this.searchData.allEmployeeList = [];
          data.forEach((element) => {
            let dd = {
              label: element.userName,
              value: element.userId,
            };
            this.allEmployeeList.push(dd);
            this.searchData.allEmployeeIdList.push(element.userId);
            this.searchData.allEmployeeList.push(dd);
          });
          this.searchData.allEmployeeList.splice(0, 0, {
            value: "all",
            label: "全部人员",
          });
          this.searchData.launcher = "全部人员";
          this.selectInitTableData("all");
        }
      );
    },

    initTableData() {
      let requestData = {
        clientNameAndNum: this.searchData.input, // 客户名称和客户编号
        userId: this.baseInfo.userId,
        executorId: "", // 执行人
        launch: this.searchData.launchList, // 派工人
        locationIds: this.searchData.location, // 办事地点
        status: this.searchData.status, // 状态
        tabFlag: this.searchData.tabFlag, // tab 栏查询标识
        time: this.searchData.time, // 待办时间
        // companyId: this.baseInfo.company.companyId, // 公司id
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
      };
      this.$request(
        "post",
        "/waitDeal/pageToDispatchPool",
        requestData,
        (data) => {
          this.tableData = data.records;
          this.pageData.total = data.total;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    handleClick(data, flag) {
      this.temDispatchPool = data;
      switch (flag) {
        case 0:
          //  批量派工
          if (this.multipleSelection.length == 0) {
            this.$message.error("请选择派工任务!");
            return;
          }
          this.dialogData.title = "派工";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "batch_dispatch",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              title: "执行人",
              type: "select-object",
              placeholder: "请选择执行人",
              options: this.allEmployeeList,
              value: "",
            },
          ];
          this.dialogData.flag = true;
          break;
        case 1:
          this.dialogData.title = "派工";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "sure_dispatch",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              title: "执行人",
              type: "select-object",
              placeholder: "请选择执行人",
              options: this.allEmployeeList,
              value: "",
            },
          ];
          this.dialogData.flag = true;
          break;
        case 2:
          this.dialogData.title = "地址详情";
          this.dialogData.buttonArray = [];
          this.dialogData.data = [
            {
              title: "地址详情",
              type: "textarea_black",
              value: data.locationDetail,
              rows: 5,
              disabled: true,
            },
          ];
          this.dialogData.flag = true;
        default:
          break;
      }
    },
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "sure_dispatch":
          let executor = [];
          executor = this.dialogData.data[0].value.map((item) => item.value);
          let ids = [];
          ids.push(this.temDispatchPool.id);
          requestData = {
            ids: ids, // ids
            executor: executor, // 执行人 id 集合
            launch: this.baseInfo.userId, // 派工人
          };
          this.$request(
            "post",
            "/waitDeal/dispatchBatch",
            requestData,
            (data) => {
              this.$message.success("派工成功!");
              this.initTableData();
              this.dialogData.flag = false;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "batch_dispatch":
          let ids2 = [];
          ids2 = this.multipleSelection.map((item) => item.id);
          let executor2 = [];
          executor2 = this.dialogData.data[0].value.map((item) => item.value);
          requestData = {
            ids: ids2, // ids
            executor: executor2, // 执行人 id 集合
            launch: this.baseInfo.userId, // 派工人
          };
          this.$request(
            "post",
            "/waitDeal/dispatchBatch",
            requestData,
            (data) => {
              this.$message.success("派工成功!");
              this.initTableData();
              this.dialogData.flag = false;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "cancel":
          this.colseDialog();
          break;
        default:
          break;
      }
    },
    /**
     * tab切换事件
     */
    changeTab(index) {
      this.tabIndex = index;
      if (index == 0) {
        this.searchData.tabFlag = null;
        this.searchData.status = index;
      } else {
        this.searchData.status = 0;
        index == 2
          ? // 过期
            (this.searchData.tabFlag = -1)
          : index == 3
          ? // 今天
            (this.searchData.tabFlag = 0)
          : index == 4
          ? // 明天
            (this.searchData.tabFlag = 1)
          : index == 5
          ? // 后天
            (this.searchData.tabFlag = 2)
          : index == 6
          ? // 三天内
            (this.searchData.tabFlag = 3)
          : // 七天内
            (this.searchData.tabFlag = 7);
      }
      this.initTableData();
    },

    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },

    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
      this.initTableData();
    },
    /**
     * 附件上传
     */
    handleAvatarSuccess(data) {},
    /**
     * 表格多选事件
     */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * 员工选择
     */

    selectInitTableData(val) {
      if (val == "all") {
        this.searchData.launchList = this.searchData.allEmployeeIdList;
      } else {
        this.searchData.launchList = [val];
      }
      this.initTableData();
    },
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
    this.waitDealLocation();
  },
};
</script>

<style lang="less" scoped>
#dispatchPool {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .dispatchPool-screen {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    .dispatchPool-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      > .title {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 0 10px;
      }
      > .el-tag {
        margin: 0 5px;
      }
      .dispatchPool-screen-fiftrate {
        width: 65px;
        min-width: 65px;
        height: 30px;
        border: 1px solid @boderColor;
        box-sizing: border-box;
        color: @boderActiveColor;
        font-size: @contentFontSize;
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: 0.3s;
        i {
          margin: 0 4px;
        }
      }
      .dispatchPool-screen-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }
      .dispatchPool-screen-search {
        width: 210px;
        min-width: 210px;
        height: 30px;
        margin-left: 10px;
        display: flex;
        align-items: center;
      }
      .dispatchPool-screen-active {
        flex: 1;
      }
      .dispatchPool-screen-but {
        width: 140px;
        min-width: 140px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;

        &:hover {
          cursor: pointer;
          background-color: #2c7db3;
        }
      }
      .drop-button {
        background-color: #ecf5ff;
        color: #409eff;
        &:hover {
          background-color: #abbed3;
        }
      }
      .dispatchPool-screen-but-boder {
        width: 50px;
        min-width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        font-size: @contentFontSize;
        background-color: @activeBackground;
        color: @activeColor;
        margin: 0 5px;
        margin-right: 10px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .dispatchPool-screen-but-boder:hover {
        cursor: pointer;
        background-color: @activeBackground2;
      }
    }
  }

  .table-content {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;

    .dispatchPool-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .dispatchPool-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .dispatchPool-table-card-tab {
          width: 80px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .dispatchPool-table-card-tab2 {
          width: 80px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }

        .dispatchPool-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .dispatchPool-table-card-tab2:hover {
          cursor: pointer;
          color: #67c23a;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .is-active2 {
          color: #67c23a;
          border-bottom: 2px solid #67c23a;
        }
        .dispatchPool-table-card-active {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: @contentFontSize;
          .textArea {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: @activeColor;
            cursor: pointer;
            &:hover {
              text-decoration: underline; // 下划线
            }
          }
          .numArea {
            height: 100%;
            padding-top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            color: @activeColor;
          }
        }
        .dispatchPool-table-card-edit {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: @editColor;
          cursor: pointer;
        }
      }
      .dispatchPool-content {
        width: 100%;
        height: 95%;
        max-width: 90vw;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
      }
      .dispatchPool-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
