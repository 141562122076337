<template>
  <div id="report-follow">
    <!-- 所有客户跟进记录报表 -->
    <div class="report-follow-screen">
      <div class="report-follow-screen-search">
        <el-date-picker
          v-model="searchData.month"
          type="month"
          value-format="yyyy-MM"
          placeholder="选择月"
          @change="initTableData"
        >
        </el-date-picker>
      </div>
      <div class="report-follow-screen-search">
        <el-select
          v-model="searchData.userId"
          placeholder="请选择员工"
          @change="seleteInitTableData"
          filterable
        >
          <el-option
            v-for="item in searchData.userList"
            :key="item.userId"
            :label="item.userName"
            :value="item.userId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="report-follow-screen-search">
        <el-cascader
          v-model="searchData.department"
          placeholder="请选择部门"
          :options="searchData.departTreeData"
          @change="initTableData"
          clearable
          :show-all-levels="false"
          :props="{ checkStrictly: true, value: 'id' }"
          filterable
        ></el-cascader>
      </div>
      <div class="report-follow-screen-search">
        <el-select
          v-model="searchData.hasLeave"
          @change="initTableData"
          placeholder="请选择跟进状态"
          :clearable="false"
        >
          <el-option label="未跟进" value="0"></el-option>
          <el-option label="含全部" value="1"></el-option>
        </el-select>
      </div>
      <!-- <div class="report-follow-screen-checked">
        <el-checkbox v-model="searchData.checked" @change="initTableData"
          >包含未跟进客户</el-checkbox
        >
      </div> -->
      <div class="report-follow-screen-active"></div>
      <div
        class="report-follow-screen-but-boder"
        v-if="whetherAgencyHead"
        @click="handleClick({}, 1)"
      >
        导出
      </div>
    </div>
    <div class="report-follow-table">
      <div class="report-follow-table-card">
        <div class="report-follow-table-card-tabs">
          <div
            :class="{
              'report-follow-table-card-tab': true,
              'is-active': tabindex == 'thread',
            }"
            @click="changeTab('thread')"
          >
            商机
          </div>
          <div
            :class="{
              'report-follow-table-card-tab': true,
              'is-active': tabindex == 'client',
            }"
            @click="changeTab('client')"
          >
            客户
          </div>
          <div class="report-follow-table-card-active"></div>
        </div>
        <div class="report-follow-table-card-content">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            height="98%"
            :row-style="rowStyle"
            v-loading="loading"
          >
            <el-table-column type="index" width="55" fixed> </el-table-column>
            <el-table-column
              v-for="(item, index) in tableMenuData"
              :key="index"
              :property="item.key"
              :fixed="index == 0"
              :label="item.title"
              :min-width="item.width"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <div>
                  {{ scope.row[item.key] }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="report-follow-table-card-bottom">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as reportData from "@/assets/js/report.js";
export default {
  data() {
    return {
      baseInfo: {},
      rowStyle: this.$rowStyle,
      whetherAgencyHead: false, // 是否是机构负责人
      loading: true,
      searchData: {
        input: "",
        hasLeave: "0",
        screenButton_export: 1, // 导出
        userId: "", //
        userList: [],
        tempUserId: [],
        departTreeData: [],
        department: "",
        productList: [],
        productId: "",
        month: this.$formatTime(new Date(), "YYYY-MM"),
      },
      tabindex: "thread",

      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      tableMenuData: reportData.getReportFollowMenuData(),
      tableData: [],
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "reportFollow"
      ),
    };
  },
  methods: {
    getModelFeatureAuthority() {
      // this.$authority.getModelFeatureAuthority(
      //   this,
      //   "reportCollection",
      //   this.allFunctionButtons,
      //   (data) => {
      //     this.allFunctionButtons = data;
      //     console.log(this.allFunctionButtons, "ooooooooo");
      //     this.getEmployeeListByUserId();
      //   }
      // );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getEmployeeListByUserId();
      });
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeListByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.searchData.userList = data;
          this.tempAllEmpolyeeList = [];
          data.forEach((element) => {
            this.tempAllEmpolyeeList.push(element.userId);
          });
          this.searchData.tempUserId = this.tempAllEmpolyeeList;
          this.searchData.userList.splice(0, 0, {
            userId: "all",
            userName: "全部人员",
          });
          this.initTableData();
          this.getDepartmentTreeByUserId();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        // "/setting/getDepartmentTreeByUserId",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
          this.getAllProduct();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有产品
     */
    getAllProduct() {
      this.searchData.productList = [];
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        currentPage: 1,
        name: "",
        pageSize: 1000,
        type: "全部产品类型",
      };
      this.$request("post", "/product/getAllProduct", requestData, (data) => {
        this.searchData.productList.push({ id: "", name: "全部" });
        data.tableData.forEach((element) => {
          let dd = {
            id: element.id,
            name: element.name,
          };
          this.searchData.productList.push(dd);
        });
      });
    },
    handleClick(data, index) {
      switch (index) {
        case 1:
          // 导出跟进记录报表
          let requestData = {
            department:
              this.searchData.department.length > 0
                ? this.searchData.department[
                    this.searchData.department.length - 1
                  ]
                : "",
            user: this.searchData.tempUserId,
            currentPage: this.pageData.currentPage, // 当前页数
            pageSize: this.pageData.pageSize, // 每页条数
            time: this.searchData.month,
            companyId: this.baseInfo.company.companyId,
            hasLeave: this.searchData.hasLeave == "0" ? "0" : "1", // 是否包含已成交、无效,"0"为否,"1"为是
            type: this.tabindex,
          };

          window.location.href =
            this.$baseurl +
            "/report/exportFollowReport?data=" +
            JSON.stringify(requestData);

          break;

        default:
          break;
      }
    },
    initTableData() {
      this.loading = true;
      let url = "/report/getAllClientFollow";
      let requestData = {
        department:
          this.searchData.department.length > 0
            ? this.searchData.department[this.searchData.department.length - 1]
            : "",
        user: this.searchData.tempUserId,
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
        time: this.searchData.month,
        companyId: this.baseInfo.company.companyId,
        hasLeave: this.searchData.hasLeave, // 0 为跟进 1 全部
        type: this.tabindex,
      };
      this.$request(
        "post",
        url,
        requestData,
        (data) => {
          this.pageData.total = data.total;
          this.tableData = data.tableData;
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },

    seleteInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.tempAllEmpolyeeList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.initTableData();
    },
    changeTab(index) {
      this.tabindex = index;
      this.initTableData();
    },
    handleCurrentChange(val) {
      this.initTableData();
    },
  },
  mounted() {
    this.loading = true;
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.searchData.userId = "all";
    // this.searchData.tempUserId = [this.baseInfo.userId];
    // this.$store.dispatch("getCompantAccountList", this);
    this.isCharge();
  },
};
</script>

<style lang="less" scoped>
#report-follow {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .report-follow-screen {
    width: 100%;
    height: 45px;
    border-bottom: 1px solid @boderColor;
    display: flex;
    align-items: center;
    .report-follow-screen-fiftrate:hover {
      cursor: pointer;
      border-color: @boderActiveColor;
    }
    .report-follow-screen-search {
      width: 210px;
      min-width: 210px;
      height: 30px;
      margin-left: 10px;
    }

    .report-follow-screen-checked {
      width: 140px;
      height: 30px;
      min-width: 140px;
      font-size: @contentFontSize;
      margin: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .report-follow-screen-active {
      flex: 1;
    }
    .report-follow-screen-but-select {
      width: 140px;
      min-width: 160px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: @activeColor;
      font-size: @contentFontSize;
      color: #fff;
      margin: 0 5px;
      border-radius: 4px;
      transition: 0.5s;
      cursor: pointer;
      .report-follow-screen-but-select_but {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.5s;
      }
      .report-follow-screen-but-select_but:hover {
        background-color: @activeColor2;
      }
      .report-follow-screen-but-select_select {
        width: 30px;
        height: 100%;
        border-left: 1px solid @activeColor3;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .report-follow-screen-but {
      width: 100px;
      min-width: 100px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: @activeColor;
      font-size: @contentFontSize;
      color: #fff;
      margin: 0 5px;
      border-radius: 4px;
      transition: 0.5s;
    }
    .report-follow-screen-but:hover {
      cursor: pointer;
      background-color: @activeColor2;
    }
    .report-follow-screen-but-boder {
      width: 50px;
      min-width: 50px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid @activeColor;
      font-size: @contentFontSize;
      background-color: @activeBackground;
      color: @activeColor;
      margin: 0 5px;
      margin-right: 10px;
      border-radius: 4px;
      transition: 0.5s;
    }
    .report-follow-screen-but-boder:hover {
      cursor: pointer;
      background-color: @activeBackground2;
    }
  }
  .report-follow-table {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    .report-follow-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .report-follow-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .report-follow-table-card-tab {
          width: 130px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .report-follow-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .report-follow-table-card-active {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: @contentFontSize;
          .textArea {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .numArea {
            height: 100%;
            padding-top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            color: @activeColor;
          }
        }
        .report-follow-table-card-edit {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: @editColor;
          cursor: pointer;
        }
      }
      .report-follow-table-card-content {
        flex: 1;
        width: 98%;
        .report-follow-table-card-content_text {
          width: auto;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 0px;
          box-sizing: border-box;
          color: rgb(99, 211, 34);
          font-size: 12px;
        }
      }
      .report-follow-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }

  .table-tip {
    height: 30px;
    width: auto;
    padding: 2px 5px;
    margin: 0 3px;
    border: 1px solid #333;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hri {
    display: flex;
    i {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 3px;
      font-size: 14px;
      color: @activeColor;
    }
  }
  .link {
    color: @activeColor;
    transition: 0.3s;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 210px !important;
  }
}
</style>
