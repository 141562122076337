<template>
  <div id="product-list" v-loading="loading">
    <div class="product-screen">
      <div class="product-screen-search">
        <el-select
          v-model="searchData.select_value"
          placeholder="请选择"
          filterable
          @change="initTableData"
        >
          <el-option
            v-for="item in searchData.options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="product-screen-search">
        <el-input
          placeholder="请输入服务产品名称"
          v-model="searchData.input"
          @keyup.enter.native="initTableData"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click.native.stop="initTableData"
          ></el-button>
        </el-input>
      </div>
      <div class="product-screen-active"></div>
      <div
        class="product-screen-but-boder"
        v-showFunction="{ active: 'add', array: allFunctionButtons }"
        @click="handleClick({}, 0)"
      >
        添加服务产品
      </div>
    </div>

    <div class="product-table">
      <div class="product-table-card">
        <div class="product-table-card-content">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            height="98%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              width="35"
              fixed
            ></el-table-column>
            <el-table-column type="index" width="55" fixed> </el-table-column>
            <el-table-column prop="name" label="服务产品名称">
            </el-table-column>
            <el-table-column prop="type" label="服务产品类型">
            </el-table-column>
            <el-table-column prop="price" label="合同价格(元)(1年或1次)">
            </el-table-column>
            <el-table-column prop="serves_list" label="包含服务项">
              <template slot-scope="scope">
                <div
                  class="table-tip"
                  v-for="(it, ii) in scope.row.servicesItem"
                  :key="ii"
                  :style="{ borderColor: it.icon.color, color: it.icon.color }"
                >
                  {{ it.icon.text }}
                </div>
                <div
                  class="table-tip"
                  v-for="(it, ii) in scope.row.presenterServesList"
                  :key="ii"
                  :style="{ borderColor: it.icon.color, color: it.icon.color }"
                >
                  {{ it.icon.text }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="productExplain" label="说明">
            </el-table-column>
            <el-table-column fixed="right" label="操作">
              <template slot-scope="scope">
                <el-button
                  @click="handleClick(scope.row, 1)"
                  type="text"
                  v-showFunction="{ active: 'edit', array: allFunctionButtons }"
                  size="small"
                  >编辑</el-button
                >
                <el-button
                  @click="handleClick(scope.row, 2)"
                  v-showFunction="{ active: 'stop', array: allFunctionButtons }"
                  type="text"
                  size="small"
                  >停用</el-button
                >
                <el-button
                  @click="handleClick(scope.row, 3)"
                  v-showFunction="{
                    active: 'delete',
                    array: allFunctionButtons,
                  }"
                  type="text"
                  size="small"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="product-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="10"
            layout="total, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import my_dialog from "@/components/dialog.vue";
import * as serveItemData from "@/assets/js/serveItemData.js";
export default {
  components: {
    my_dialog,
  },
  data() {
    return {
      baseInfo: {},
      loading: true,
      searchData: {
        input: "",
        select_value: "全部产品类型",
        options: [
          { label: "全部产品类型", value: "全部产品类型" },
          { label: "周期服务", value: "周期服务" },
          { label: "单次服务", value: "单次服务" },
        ],
        screenButton_improt: 1, // 导出客户商机按钮的权限 1->读写;2->只读;3->不可见
      },
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      tableData: [
        {
          id: "",
          name: "工商注册",
          type: "单次服务",
          price: "500",
          serves_list: [
            { icon: { text: "注", color: "#F9A437" } },
            { icon: { text: "代", color: "#35C07D" } },
          ],
          explain: "产品说明!!!!",
        },
      ],
      servicesItemList: [], // 所有服务项列表
      multipleSelection: [],
      dialogData: {
        flag: false,
        width: "30%",
        title: "添加跟进记录",
        id: "",
        price: "", // 临时存储价格
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },
      buttonAuth: {
        add: 3,
        edit: 3,
        oper: 3,
        dele: 3,
      },

      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "productList"
      ),
      whetherAgencyHead: false, // 是否是机构负责人
    };
  },
  methods: {
    getModelFeatureAuthority() {
      this.$authority.getModelFeatureAuthority(
        this,
        "productList",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.isCharge();
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getServicesItem();
      });
    },
    /**
     * 获取所有服务项数据
     */
    getServicesItem() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        status: "1", // 0为停用;1为启用;2为全部
        name: "", // 模糊查询
        type: "", // 服务项类别ID 获取全部数据时,该项为空
      };
      this.$request(
        "post",
        "/servicesItem/getServicesItem",
        requestData,
        (data) => {
          this.loading = false;
          this.servicesItemList = data;
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      this.tableData = [];
      this.loading = true;
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        name: this.searchData.input, // 服务产品名称 模糊查询
        type: this.searchData.select_value,
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
      };
      this.$request(
        "post",
        "/product/getAllProduct",
        requestData,
        (data) => {
          this.pageData.total = data.total; // 获取总条目数
          this.tableData = data.tableData;
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    /**
     * 表格中的按钮操作事件
     */
    handleClick(data, flag) {
      switch (flag) {
        case 0:
          //   添加服务产品
          this.dialogData.title = "添加服务项";
          this.dialogData.width = "60%";
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "submit_add",
            },
            {
              text: "取消",
              active: "calcel",
            },
          ];
          this.dialogData.data = serveItemData.getProductDialogData("");
          this.dialogData.flag = true;

          break;
        case 1:
          //   编辑
          console.log("打开编辑数据", data);
          this.dialogData.title = "编辑服务项";
          this.dialogData.width = "60%";
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "submit_edit",
            },
            {
              text: "取消",
              active: "calcel",
            },
          ];
          this.dialogData.data = serveItemData.getProductDialogData(data);
          // this.dialogData.id = data.id;
          // this.dialogData.price = data.price;
          this.dialogData.flag = true;
          console.log("aaaaaaaaaa", this.dialogData);

          break;
        case 2:
          //   停用
          let requestData = {
            id: data.id,
            name: data.name, // 服务产品名称
            explain: data.productExplain, // 服务产品说明
            price: data.price, // 合同价格
            type: data.type, // 产品类别
            serves_list: data.servicesItem, // 包含的服务项 根据选择的服务项ID,将对应的服务项数据存储在这儿
            presenter_serves_list: data.presenterServesList, // 赠送服务项
            status: "0", // 状态  0为禁用;1为启用
            auto_renew: data.autoRenew, // 是否自动续签:0不续签;1续签
          };
          this.$request(
            "post",
            "/product/updateProduct",
            requestData,
            (data) => {
              this.$message({
                type: "success",
                message: this.$errorMessage.getErrorMessageInfo("update"),
                duration: 1000,
              });
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
              this.loading = false;
            }
          );

          break;
        case 3:
          //   删除
          this.$confirm("此操作将永久删除该服务项, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$request(
                "post",
                "/product/deleteProduct",
                { id: data.id },
                (data) => {
                  this.$message({
                    type: "success",
                    message: "删除成功",
                    duration: 1000,
                  });
                  this.getAllServeType();
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                  this.loading = false;
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });

          break;

        default:
          break;
      }
    },
    /**
     * 弹出框中的按钮操作事件
     */
    drawerOper(active) {
      let requestData = {};
      let serves_list = [];
      let presenter_serves_list = [];
      switch (active) {
        case "submit_add":
          // 确定添加服务产品
          serves_list = [];
          presenter_serves_list = [];
          // console.log("确定添加服务产品", this.dialogData);
          this.dialogData.data[0].value.servicesItem.forEach((element) => {
            // 包含的服务项
            serves_list.push(element.data);
          });
          // this.dialogData.data[5].data.data.forEach((element) => {
          //   // 包含的服务项
          //   presenter_serves_list.push(element.data);
          // });
          requestData = {
            companyId: this.baseInfo.company.companyId,
            name: this.dialogData.data[0].value.name, // 服务产品名称
            explain: this.dialogData.data[0].value.des, // 服务产品说明
            price: "", // 合同价格
            type: this.dialogData.data[0].value.contractType, // 产品类别
            serves_list: serves_list, // 包含的服务项 根据选择的服务项ID,将对应的服务项数据存储在这儿
            // presenter_serves_list: presenter_serves_list, // 赠送服务项
            status: "1", // 状态  0为禁用;1为启用
            auto_renew: this.dialogData.data[0].value.automaticRenewal, // 是否自动续签:0不续签;1续签
            serviceType: this.dialogData.data[0].value.serviceType, // 服务类型
            sign_deadline:
              this.dialogData.data[0].value.contractType == "周期服务"
                ? "12"
                : "a", // 签约期限
            voucher: "上门取", // 凭证交接
          };
          if (requestData.name == "") {
            this.$message({
              message: "服务产品名称不能为空!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          if (requestData.type == "") {
            this.$message({
              message: "产品类别不能为空!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          if (requestData.serves_list.length == 0) {
            this.$message({
              message: "包含的服务项不能为空!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          if (requestData.serviceType == "") {
            this.$message({
              message: "服务类型不能为空!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          this.$request(
            "post",
            "/product/addProduct",
            requestData,
            (data) => {
              this.$message({
                type: "success",
                message: this.$errorMessage.getErrorMessageInfo("add"),
                duration: 1000,
              });
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
              this.loading = false;
            }
          );
          break;
        case "submit_edit":
          // 确定添加服务产品
          console.log("确定添加服务产品", this.dialogData);
          serves_list = [];
          presenter_serves_list = [];
          this.dialogData.data[0].value.servicesItem.forEach((element) => {
            // 包含的服务项
            serves_list.push(element.data);
          });
          requestData = {
            id: this.dialogData.data[0].value.id,
            name: this.dialogData.data[0].value.name, // 服务产品名称
            explain: this.dialogData.data[0].value.des, // 服务产品说明
            price: this.dialogData.data[0].value.price, // 合同价格
            type: this.dialogData.data[0].value.contractType, // 产品类别
            serviceType: this.dialogData.data[0].value.serviceType, // 产品类别
            serves_list: serves_list, // 包含的服务项 根据选择的服务项ID,将对应的服务项数据存储在这儿
            // presenter_serves_list: presenter_serves_list, // 赠送服务项
            status: "1", // 状态  0为禁用;1为启用
            auto_renew: this.dialogData.data[0].value.automaticRenewal, // 是否自动续签:0不续签;1续签
          };
          if (requestData.name == "") {
            this.$message({
              message: "服务产品名称不能为空!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          if (requestData.type == "") {
            this.$message({
              message: "产品类别不能为空!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          if (requestData.serves_list.length == 0) {
            this.$message({
              message: "包含的服务项不能为空!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          if (requestData.serviceType == "") {
            this.$message({
              message: "服务类型不能为空!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          this.$request(
            "post",
            "/product/updateProduct",
            requestData,
            (data) => {
              this.$message({
                type: "success",
                message: this.$errorMessage.getErrorMessageInfo("update"),
                duration: 1000,
              });
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
              this.loading = false;
            }
          );
          break;
        case "cancel":
          break;

        default:
          break;
      }
      this.colseDialog();
    },

    /**
     * 多选框事件
     */
    handleSelectionChange(val) {
      this.multipleSelection = [];
      val.forEach((element) => {
        this.multipleSelection.push(element.id);
      });
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    /**
     * 弹出框关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
  },
  mounted() {
    this.loading = true;
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#product-list {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .product-screen {
    width: 100%;
    height: 45px;
    border-bottom: 1px solid @boderColor;
    display: flex;
    align-items: center;
    .product-screen-search {
      width: 210px;
      min-width: 210px;
      height: 30px;
      margin: 0 10px;
    }
    .product-screen-active {
      flex: 1;
      height: 100%;
    }
    .product-screen-but-select {
      width: 140px;
      min-width: 160px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: @activeColor;
      font-size: @contentFontSize;
      color: #fff;
      margin: 0 5px;
      border-radius: 4px;
      transition: 0.5s;
      cursor: pointer;
      .product-screen-but-select_but {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.5s;
      }
      .product-screen-but-select_but:hover {
        background-color: @activeColor2;
      }
      .product-screen-but-select_select {
        width: 30px;
        height: 100%;
        border-left: 1px solid @activeColor3;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .product-screen-but-boder {
      width: 150px;
      min-width: 50px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid @activeColor;
      font-size: @contentFontSize;
      background-color: @activeBackground;
      color: @activeColor;
      margin: 0 5px;
      margin-right: 10px;
      border-radius: 4px;
      transition: 0.5s;
    }
    .product-screen-but-boder:hover {
      cursor: pointer;
      background-color: @activeBackground2;
    }
  }
  .product-table {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    .product-table-card {
      width: 98%;
      height: 95%;
      background-color: #fff;
      max-width: 90vw;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .product-table-card-content {
        flex: 1;
        width: 98%;
        height: 0;
        display: flex;
        align-content: center;
        justify-content: center;
        font-size: @contentFontSize;
        color: @closeColor;
        .table-tip {
          height: 30px;
          width: auto;
          padding: 2px 5px;
          margin: 0 3px;
          border: 1px solid #333;
          border-radius: 5px;
          box-sizing: border-box;
        }
      }
      .product-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }
}
</style>
