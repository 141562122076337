/**
 * 根据待办类型,跳转到对应页面
 * @param {*} type 
 * @param {*} flag  区分是首页跳转还是模块跳转,首页跳转跳到待办模块
 */
export function routingHop(type, flag) {
    let path = ""
    if (flag == 1) {
        // 待办界面跳转
        switch (type) {
            case "0":
                // 待跟进商机
                path = "/main/thread/my-thread"
                sessionStorage.setItem("menuActiveIndex", "thread");
                sessionStorage.setItem("menuSecondActiveIndex", "my-thread")
                break;
            case "1":
                // 待合同审核
                path = "/main/contract/contract-check"
                sessionStorage.setItem("menuActiveIndex", "contract");
                sessionStorage.setItem("menuSecondActiveIndex", "contract-check")
                break;
            case "2":
                // 待财务审核
                path = "/main/contract/contract-check"
                sessionStorage.setItem("menuActiveIndex", "contract");
                sessionStorage.setItem("menuSecondActiveIndex", "contract-check")
                break;
            case "3":
                // 带财务审核回款
                path = "/main/contract/contract-check"
                sessionStorage.setItem("menuActiveIndex", "contract");
                sessionStorage.setItem("menuSecondActiveIndex", "contract-check")
                break;
            case "4":
                // 待下单
                path = "/main/contract/contract-info"
                sessionStorage.setItem("menuActiveIndex", "contract");
                sessionStorage.setItem("menuSecondActiveIndex", "contract-info")
                break;
            case "5":
                // 待执行
                path = "/main/contract/contract-work"
                sessionStorage.setItem("menuActiveIndex", "contract");
                sessionStorage.setItem("menuSecondActiveIndex", "contract-work")
                break;
            case "6":
                // 待续签
                path = "/main/contract/contract-renew"
                sessionStorage.setItem("menuActiveIndex", "contract");
                sessionStorage.setItem("menuSecondActiveIndex", "contract-renew")
                break;
            case "7":
                // 待流失审核客户
                path = "/main/client/client-check"
                sessionStorage.setItem("menuActiveIndex", "client");
                sessionStorage.setItem("menuSecondActiveIndex", "client-check")
                break;
            case "8":
                // 待开票
                path = "/main/invoice/invoice-check"
                sessionStorage.setItem("menuActiveIndex", "invoice");
                sessionStorage.setItem("menuSecondActiveIndex", "invoice-check")
                break;
            default:
                break;
        }
    }
    if (flag == 0) {
        // 首页待办跳转
        switch (type) {
            case "0":
                // 待跟进商机
                path = "/main/waite/waite-thread"
                break;
            case "1":
                // 待合同审核
                path = "/main/waite/waite-contract"
                break;
            case "2":
                // 待财务审核
                path = "/main/waite/waite-contract"
                break;
            case "3":
                // 带财务审核回款
                path = "/main/waite/waite-contract"
                break;
            case "4":
                // 待下单
                path = "/main/waite/waite-contract"
                break;
            case "contract_execut":
                // 5
                path = "/main/waite/waite-contract"
                break;
            case "6":
                // 待续签
                path = "/main/waite/waite-contract"
                break;
            case "7":
                // 待流失审核客户
                path = "/main/waite/waite-client"
                break;
            case "8":
                // 待开票
                path = "/main/waite/waite-invoice"
                break;
            default:
                break;
        }
    }
    return path
}