<template>
  <div id="create_resource_retrieve">
    <div class="create_resource_retrieve_input_line">
      <div class="create_resource_retrieve_input_line_span">资源名称:</div>
      <div class="create_resource_retrieve_input_line_value">
        <el-input v-model="data" placeholder="请输入名称"></el-input>
      </div>
    </div>
    <div class="create_resource_retrieve_input_line">
      <div
        class="create_resource_retrieve_input_line_but"
        @click="changeOrCreateResource"
      >
        商机创建
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    createResourceRetrieveData: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data() {
    return {
      data: this.createResourceRetrieveData,
      flag: false,
    };
  },
  methods: {
    changeOrCreateResource() {
      // console.log(this.data);
      this.$emit("changeOrCreateResource", this.data);
    },
  },
};
</script>

<style lang="less" scoped>
#create_resource_retrieve {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  .create_resource_retrieve_input_line {
    width: 100%;
    height: auto;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    .create_resource_retrieve_input_line_span {
      width: 15%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: rgb(90, 90, 90);
    }
    .create_resource_retrieve_input_line_value {
      width: 0;
      height: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      //   justify-content: center;
      padding-left: 10px;
      .el-input,
      .el-input__inner {
        width: 320px !important;
      }
    }
    .create_resource_retrieve_input_line_but,
    .create_resource_retrieve_input_line_but1 {
      width: 90%;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      background-color: rgb(49, 188, 252);
      border-radius: 5px;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        background-color: rgb(42, 159, 212);
      }
    }
    .create_resource_retrieve_input_line_but1 {
      width: auto;
      height: auto;
      padding: 3px 10px;
      margin-right: 50px;
    }
    .create_resource_retrieve_input_line_tip {
      width: 100%;
      height: auto;
      line-height: 33px;
      display: flex;
      align-items: center;
      color: #ff0000;
      font-size: 12px;
    }
    .create_resource_retrieve_input_line_tip_line {
      width: 100%;
      height: auto;
      min-height: 40px;
      display: flex;
      align-items: center;
      font-size: 12px;
      .create_resource_retrieve_input_line_tip_line_span {
        width: 100px;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
      }
      .create_resource_retrieve_input_line_tip_line_value {
        width: 0;
        flex: 1;
        height: auto;
        min-height: 40px;
        display: flex;
        align-items: flex-start;
        .create_resource_retrieve_input_line_tip_line_value_contact {
          width: 100%;
          height: 45px;
          display: flex;
          align-items: flex-start;
          .create_resource_retrieve_input_line_tip_line_value_contact_value {
            flex: 1;
            width: 0;
            height: 100%;
            display: flex;
            align-items: flex-start;
            .create_resource_retrieve_input_line_tip_line_value_contact_span {
              width: 50px;
              height: 100%;
              display: flex;
              align-items: flex-start;
              justify-content: center;
            }
            .create_resource_retrieve_input_line_tip_line_value_contact_son_value {
              flex: 1;
              width: 0;
              height: 100%;
              display: flex;
              align-items: flex-start;
            }
          }
        }
      }
    }
  }
  .colum_line {
    flex-direction: column;
    padding: 10px;
    height: 70vh;
    overflow-y: auto;
    box-sizing: border-box;
  }
  .hri {
    display: flex;
    i {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 3px;
      font-size: 14px;
      // color: @activeColor;
    }
  }
}
</style>