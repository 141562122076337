<template>
  <div id="recyle-thread">
    <!-- 全部商机 -->
    <div class="thread-screen">
      <div class="thread-screen-line">
        <div class="thread-screen-fiftrate">
          <el-popover
            placement="bottom-start"
            width="250"
            trigger="manual"
            :visible-arrow="false"
            v-model="popoData.popoVisible"
          >
            <div class="popover-search">
              <popo_search
                :list="popoData.list"
                @cancel="popoCanael"
                @reset="popoReset"
                @makeSure="popoMakeSure"
              ></popo_search>
            </div>
            <div slot="reference" @click="popoverOpen">
              筛选 <i class="el-icon-s-operation"></i>
            </div>
          </el-popover>
        </div>
        <div class="thread-screen-search">
          <el-input
            placeholder="商机名称/联系人/手机号"
            v-model="searchData.input"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="thread-screen-active"></div>
      </div>
      <div class="thread-screen-line" v-if="searchData.screenList.length > 0">
        <div class="title">筛选条件:</div>
        <el-tag
          v-for="(tag, index) in searchData.screenList"
          :key="index"
          closable
          size="small"
          @close="closeScreen(index)"
        >
          {{ tag.name }}
        </el-tag>
      </div>
    </div>
    <div class="thread-table">
      <div class="thread-table-card">
        <div class="thread-table-card-content">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            @row-click="rowClick"
            style="width: 100%"
            height="98%"
            v-loading="loading"
            :row-style="{ height: '71.5px' }"
            :default-sort="defaultSort"
            @sort-change="sortChange"
          >
            <el-table-column type="index" width="55" fixed> </el-table-column>
            <template v-for="(item, index) in tabMenu">
              <el-table-column
                :key="index"
                :property="item.key"
                :fixed="index == 0"
                :label="item.name"
                :min-width="
                  item.key == 'threadName'
                    ? 270
                    : item.key == 'followName'
                    ? 100
                    : item.key == 'lastContactTime'
                    ? 130
                    : 110
                "
                :sortable="
                  item.key == 'lastContactTime' ||
                  item.key == 'createTime' ||
                  item.key == 'protectTime'
                    ? 'custom'
                    : false
                "
                :show-overflow-tooltip="true"
              >
                <template slot-scope="scope">
                  <div class="hri" v-if="item.key == 'threadName'">
                    <i
                      class="el-icon-notebook-2"
                      @click.stop="showGenjin(scope.row)"
                    ></i>
                    <el-tooltip
                      class="item"
                      effect="light"
                      content="已签约"
                      placement="top"
                    >
                      <i
                        class="el-icon-star-on"
                        v-if="scope.row.clientFlag"
                      ></i> </el-tooltip
                    >{{ scope.row[item.key] }}
                  </div>
                  <div
                    class="hri"
                    v-else-if="
                      item.key == 'customerSource' ||
                        item.key == 'intentionalProduct'
                    "
                  >
                    <!-- 客户来源或意向产品为数组格式,循环遍历显示 -->
                    <div v-for="(it, ii) in scope.row[item.key]" :key="ii">
                      {{ ii == 0 ? it.title : "," + it.title }}
                    </div>
                  </div>
                  <div v-else>
                    {{ scope.row[item.key] }}
                  </div>
                </template>
              </el-table-column></template
            >
          </el-table>
        </div>
        <div class="thread-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @choseProductTipOne="choseProductTipOne"
      ></my_dialog>
    </div>

    <!-- 右边抽屉 -->
    <div id="my-drawer" v-if="threadDrawerData.flag">
      <my_drawer
        :drawerData="threadDrawerData"
        :allFunctionButtons="allFunctionButtons"
        :model="'threadRecycle'"
        @show="showDrawer"
        @closeUpdateTableDrawer="closeUpdateTableDrawer"
        @saveDrawerData="saveDrawerData"
        @tagsHandleClose="tagsHandleClose"
        @addTags="addTags"
        @drawerOper="drawerOper"
      ></my_drawer>
    </div>
  </div>
</template>

<script>
import drager from "@/components/drager.vue";
import my_drawer from "@/components/my-drawer.vue";
import popo_search from "@/components/popo-search.vue";
import my_dialog from "@/components/dialog.vue";
import * as myThreadData from "@/assets/js/myThread.js";
import * as clientInfoData from "@/assets/js/clientInfoData.js";
import * as systemData from "@/assets/js/systemAuthorityData.js";
import * as employeeData from "@/assets/js/employeeData.js";
import * as threadData from "./js/threadData.js";
export default {
  components: {
    drager,
    my_drawer,
    popo_search,
    my_dialog,
  },
  inject: ["reload"],
  data() {
    return {
      baseInfo: {},
      loading: true,
      whetherAgencyHead: true, // 是否是机构负责人
      searchData: {
        input: "",
        checked: false,
        screenButton_add: 1, // 新增客户商机按钮的权限 1->读写;2->只读;3->不可见
        screenButton_improt: 1, // 导出客户商机按钮的权限 1->读写;2->只读;3->不可见
        screenList: [],
      },
      tabindex: "0",
      defaultSort: { prop: "", order: "" },
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      popoData: {
        popoVisible: false,
        list: myThreadData.remyThreadSearchData(),
      },
      tabMenu: myThreadData.myThreadTableMenuData(),
      tableMenuData: {
        selectMenuData: [], // 真正显示的表头数据
        chooseableMenuData: [], // 隐藏的表头
      },
      tableData: [],
      tragerDialogData: {
        title: "自定义列",
        flag: false,
        data: {},
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "添加跟进记录",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: myThreadData.getMyThreadsGenjinjiluData(),
      },
      threadDrawerData: myThreadData.threadRecyleDrawerData("商机", {
        buttonAuth: [],
        data: [],
        customer: { name: "", id: "" },
      }),
      tempTags: [],
      tempThread: {
        id: "",
        name: "",
      },
      arrayList: {
        employeeList: [], // 所有员工列表
        departmentList: [], // 所有部门列表
        areaList: [], // 所有片区列表
        sourceList: [], // 所有客户来源列表
        followStageList: [], // 跟进阶段列表
        addedValueTaxTypeList: [], // 增值税类型列表
        intentionalProductList: [], // 所有意向产品列表
      },
      buttonAuth: [],
      productList: [],

      ttempClient: {},
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "threadRecycle"
      ),
      drawerOptions: this.$tagData.getDrawerOptionsList("threadRecycle"), // 抽屉中的下拉框配置项
      drawerAuthorityData: [],
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "threadRecycle",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.initMenu();
      });
    },
    initMenu() {
      this.$featureData.getAttributeData(
        "threadRecycle",
        true,
        [],
        (attributeData) => {
          this.$menuData.getModelMenuAndAuthority(
            "threadRecycle",
            attributeData,
            this,
            (data) => {
              this.tabMenu = data;
              console.log(this.tabMenu);
              this.tragerDialogData.data = JSON.parse(
                JSON.stringify(this.tabMenu)
              );
              this.getEmployeeList();

              this.$tagData.getTagList(
                this.drawerOptions,
                ["片区", "客户来源", "增值税类型"],
                this.baseInfo.company.companyId,
                this
              );
            }
          );
        }
      );
    },
    /**
     * 获取所有员工列表
     */
    getEmployeeList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/user/getEmployeeListForDownBox",
        requestData,
        (data) => {
          this.employeeList = [];
          data.forEach((element) => {
            let dd = {
              label: element.userName,
              value: element.userId,
            };
            this.employeeList.push(dd);
          });
          this.drawerOptions.allUser = this.employeeList;
          this.getAllProductList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有合同模板/产品列表
     */
    getAllProductList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        name: "", // 服务产品名称 模糊查询
        type: "",
        currentPage: 1, // 当前页数
        pageSize: 1000, // 每页条数
      };
      this.$request(
        "post",
        "/product/getAllProduct",
        requestData,
        (data) => {
          this.productList = data.tableData;
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 表格排序事件
     */
    sortChange(data) {
      // console.log("表格排序事件", data.prop, data.order);
      this.defaultSort.prop = data.prop;
      this.defaultSort.order = data.order == null ? "" : data.order;
      // console.log("表格排序事件", this.defaultSort);
      this.initTableData();
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      this.tableData = [];
      this.loading = true;
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId, // 当前公司ID
        haveBargain: "0", // 是否包含已成交、无效,"0"为否,"1"为是
        state: "4", // 商机状态:"0"->全部商机;"1"->待首次联系商机;"2"->待联系商机
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
        search: this.searchData.input,
        sortProp: this.defaultSort.prop, // 手动排序项
        sortOrder: this.defaultSort.order, // 手动排序规则 ascending descending ""
        screenList: this.searchData.screenList,
      };
      this.$request(
        "post",
        // "/thread/getMyThread",
        "/reThread/threadRecycle",
        requestData,
        (data) => {
          this.pageData.total = data.total; // 获取总条目数
          this.tableData = data.tableData;
          //  myThreadData.regetInitTableData(
          //   data.tableData,
          //   this.tabMenu
          // );
          this.$nextTick(() => {
            this.$refs.singleTable.doLayout();
          });
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },

    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },

    /**
     * 筛选弹出框显示
     */
    popoverOpen() {
      console.log("所有数组数据:", this.arrayList);
      // this.popoData.list[0].value = this.arrayList.employeeList// 商机状态
      this.popoData.list[1].options = []; // 客户来源
      this.popoData.list[7].options = []; // 商机创建人
      this.popoData.list[6].options = []; // 商机创建人
      this.popoData.list[8].options = []; // 意向产品
      this.arrayList.sourceList.forEach((element) => {
        if (element.active) {
          let dd = {
            label: element.name,
            value: element.value[0],
          };
          this.popoData.list[1].options.push(dd);
        }
      });

      this.popoData.list[6].options = this.employeeList;
      this.popoData.list[7].options = this.employeeList;
      this.productList.forEach((element) => {
        let dd = {
          label: element.name,
          value: element.id,
        };
        this.popoData.list[8].options.push(dd);
      });

      this.popoData.popoVisible = !this.popoData.popoVisible;
    },
    /**
     * 筛选弹出框确认事件
     */
    popoMakeSure() {
      this.searchData.screenList = this.$systemBaseData.remakesureSearchData(
        this.popoData.list
      );
      // console.log(this.popoData.list);
      this.popoData.popoVisible = false;
      this.initTableData();
    },
    /**
     * 关闭筛选标签事件
     */
    closeScreen(index) {
      this.searchData.screenList.splice(index, 1);
      this.initTableData();
    },
    /**
     * 筛选弹出框重置
     */
    popoReset() {
      this.$systemBaseData.popoReset(this.popoData.list);
      this.searchData.screenList = [];
    },
    /**
     * 筛选弹出框关闭事件
     */
    popoCanael() {
      this.popoData.popoVisible = false;
    },

    /**
     *右击改变表头显示与否事件
     */
    changeStatus(data) {
      if (data.index == 0) {
        data.item.isDisable = true;
        this.tragerDialogData.data.selectMenuData.splice(
          this.tragerDialogData.data.selectMenuData.indexOf(data.item),
          1
        );
        this.tragerDialogData.data.chooseableMenuData.push(data.item);
      } else {
        data.item.isDisable = false;
        this.tragerDialogData.data.chooseableMenuData.splice(
          this.tragerDialogData.data.chooseableMenuData.indexOf(data.item),
          1
        );
        this.tragerDialogData.data.selectMenuData.push(data.item);
      }
    },
    /**
     * 修改表头顺序dialog打开
     */
    updateTableMenu() {
      this.tragerDialogData.flag = true;
    },
    /**
     * 确定修改表头数据
     */
    markSureUpdateTableMenu() {
      console.log("确定修改表头数据:", this.tragerDialogData.data);
      this.tableMenuData = JSON.parse(
        JSON.stringify(this.tragerDialogData.data)
      );
      let data = [];
      this.tableMenuData.selectMenuData.forEach((element) => {
        data.push(element);
      });
      this.tableMenuData.chooseableMenuData.forEach((element) => {
        data.push(element);
      });
      this.tragerDialogData.flag = false;
      this.$systemBaseData.saveTableMenuData(
        data,
        ["thread", "threadRecycle"],
        "explicitData",
        this
      );
    },

    /**
     * 表格单行点击事件
     */
    rowClick(row, column, event) {
      console.log("表格行点击事件:", row);
      if (!this.popoData.popoVisible) {
        if (!this.threadDrawerData.flag) {
          //防止重复点击
          this.tempThread.id = row.id;
          this.tempThread.name = row.threadName;
          this.ttempClient = row;
          this.$featureData.getAttributeData(
            "myThread",
            false,
            threadData.returnDrawerKeys(),
            (authorityData) => {
              this.drawerAuthorityData = authorityData;
              // 2.拿对应属性的权限
              this.$authority.getModelFeatureAuthority(
                this,
                "myThread",
                authorityData,
                (authorityList) => {
                  // 3.将权限数据对应到界面
                  this.threadDrawerData = threadData.myThreadDrawerData(
                    this.tempThread.name,
                    authorityList,
                    this.whetherAgencyHead, // 是否是机构负责人
                    this.drawerOptions
                  );
                  // 4.拿具体的值
                  this.getMyThreadDetailsByThreadId();
                }
              );
            }
          );
        } else {
          this.closeUpdateTableDrawer();
        }
      } else {
        this.popoCanael();
      }
    },
    /**
     * 获取商机的具体信息
     */
    getMyThreadDetailsByThreadId() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId, // 当前公司ID
        threadId: this.tempThread.id, // 当前线索ID
        authorityData: this.$initDrawerAuthorityData.getKeysByAuthorityList(
          this.drawerAuthorityData
        ), // 模块具体权限key数组
      };
      this.$request(
        "post",
        "/reThread/getMyThreadDetailsByThreadId",
        requestData,
        (data) => {
          // 5.将具体的值匹配到界面
          // 保证联系人有数据
          if (data.contact.length == 0) {
            data.contact = [{ name: "", phone: ["", ""] }];
          }
          // 单独处理客户来源  将数组格式转换成字符串
          if (data.customerSource.length > 0) {
            data.customerSource = data.customerSource[0];
          } else {
            data.customerSource = "";
          }
          this.threadDrawerData = this.$initDrawerAuthorityData.initDrawerData(
            this.threadDrawerData,
            data
          );
          // 6.在权限的基础上叠加状态
          this.threadDrawerData.status = data.status;
          this.threadDrawerData.flag = true;
        }
      );
    },

    /**
     * 抽屉中tag移除事件
     * data(tag,i,j):tag->移除的tag,i为第几个model,j为第几line
     */
    tagsHandleClose(data) {
      this.threadDrawerData.data.modelList[data.i].line[data.j].value.splice(
        this.threadDrawerData.data.modelList[data.i].line[data.j].value.indexOf(
          data.tag
        ),
        1
      );
      this.saveDrawerData(
        this.threadDrawerData.data.modelList[data.i].line[data.j].title
      );
    },
    /**
     * 抽屉中添加tag事件
     * data(i,j):i为第几个model,j为第几line
     */
    addTags(data) {
      this.tempTags = this.threadDrawerData.data.modelList[data.i].line[
        data.j
      ].value;
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.dialogData.title = "选择产品";
      this.dialogData.buttonArray = [];
      this.dialogData.buttonArray.push({
        text: "确定",
        active: "makesure_addPurposeThread",
      });
      this.dialogData.buttonArray.push({
        text: "取消",
        active: "cancel",
      });
      let tempProductList = [];
      this.productList.forEach((element) => {
        let dd = {
          id: element.id,
          title: element.name,
          serve: element.servicesItem,
        };
        tempProductList.push(dd);
      });
      console.log("当前数据", this.tempTags);
      this.dialogData.data = [
        {
          type: "intention_the_product",
          title: "",
          value: {
            clientName: this.tempThread.name,
            chooseProduct: JSON.parse(JSON.stringify(this.tempTags)),
          },
        },
      ];
      this.dialogData.flag = true;
    },
    /**
     * 抽屉组件中按钮操作事件(主要是显示弹出框事件)
     */
    drawerOper(active) {
      switch (active) {
        case "delete_thread":
          // 彻底删除商机
          this.dialogData.title = "提示";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push({
            text: "确定",
            active: "makesure_delete",
          });
          this.dialogData.buttonArray.push({
            text: "取消",
            active: "cancel",
          });
          this.dialogData.data = [];
          this.dialogData.data.push({
            type: "reminder",
            text: "彻底删除",
            value: this.threadDrawerData.data.title,
          });
          this.dialogData.flag = true;
          break;
        case "makesure_delete":
          // 确认彻底删除
          this.shiftDeleteThread([this.tempThread.id]);
          break;
        case "recover":
          // 还原
          this.dialogData.title = "提示";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push({
            text: "确定",
            active: "makesure_recover",
          });
          this.dialogData.buttonArray.push({
            text: "取消",
            active: "cancel",
          });
          this.dialogData.data = [];
          this.dialogData.data.push({
            type: "reminder",
            text: "还原",
            value: this.threadDrawerData.data.title,
          });
          this.dialogData.flag = true;
          break;
        case "makesure_recover":
          // 确认还原商机
          this.restoreThread([this.tempThread.id]);
          break;
        case "zhuanchengjiao":
          // 转成交
          this.dialogData.title = "提示";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push({
            text: "确定",
            active: "makesure_zhuanchengjiao",
          });
          this.dialogData.buttonArray.push({
            text: "取消",
            active: "cancel",
          });
          this.dialogData.data = [];
          this.dialogData.data.push({
            type: "reminder",
            text: "转为客户",
            value: this.threadDrawerData.data.title,
          });
          this.dialogData.flag = true;
          break;
        case "makesure_zhuanchengjiao":
          // 确认转成交
          this.createContractDialogOpen(this.tempThread.name);
          break;
        case "operating_record":
          // 查看操作记录
          this.getOperationRecordsList();

          break;
        case "makesure_addPurposeThread":
          this.addPurposeThread();
          // 添加意向产品
          break;

        case "get_companyInfo":
          // 获取工商信息
          let requestData = {
            name: this.threadDrawerData.data.title,
          };
          this.$request(
            "post",
            "/client/getCreditCode",
            requestData,
            (data) => {
              this.threadDrawerData.data.modelList[3].line[4].value =
                data.taxNum == ""
                  ? this.threadDrawerData.data.modelList[3].line[4].value
                  : data.taxNum;
              this.threadDrawerData.data.modelList[3].line[5].value =
                data.tel == ""
                  ? this.threadDrawerData.data.modelList[3].line[5].value
                  : data.tel;
              this.threadDrawerData.data.modelList[3].line[6].value =
                data.bank == ""
                  ? this.threadDrawerData.data.modelList[3].line[6].value
                  : data.bank;
              this.threadDrawerData.data.modelList[3].line[7].value =
                data.bankAccount == ""
                  ? this.threadDrawerData.data.modelList[3].line[7].value
                  : data.bankAccount;
              this.threadDrawerData.data.modelList[3].line[3].value =
                data.address == ""
                  ? this.threadDrawerData.data.modelList[3].line[3].value
                  : data.address;

              this.threadDrawerData.data.modelList[3].line[2].value = [];
              this.threadDrawerData.data.modelList[3].line[2].value =
                data.address == ""
                  ? []
                  : this.$addressAutomaticallyMatch.addressAutomaticallyMatch(
                      data.address
                    );

              if (
                this.threadDrawerData.data.modelList[3].line[2].value == null ||
                this.threadDrawerData.data.modelList[3].line[2].value.length < 3
              ) {
                this.threadDrawerData.data.modelList[3].line[2].value = [];
              }
              this.saveDrawerData("工商信息");
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "cancel":
          // dialog关闭
          this.dialogData.flag = false;
          break;

        default:
          break;
      }
    },
    /**
     * 新建合同
     */
    createContractDialogOpen(title) {
      // this.clientInfoDrawerData.data.modelList[0].line[0].tooltipf_flag = true;
      this.dialogData.title = "选择产品";
      this.dialogData.buttonArray = [];
      this.dialogData.buttonArray.push({
        text: "取消",
        active: "cancel",
      });
      this.dialogData.data = [
        {
          type: "input_disable",
          title: "客户名称",
          value: title,
        },
        {
          type: "input_carriage",
          title: "请选择一个服务产品",
          placeholder: "搜索服务产品",
          value: "",
        },
        {
          type: "select_array_one",
          title: "",
          value: this.productList,
        },
      ];
      this.dialogData.flag = true;
    },
    /**
     * 选择产品创建客户
     */
    choseProductTipOne(data) {
      let requestData = {
        userId: this.baseInfo.userId,
        name: this.tempThread.name, // 商机名称
        companyId: this.baseInfo.company.companyId,
        productId: data.id, // 合同模板ID
        threadId: this.tempThread.id, // 商机ID
        clientFlag: false,
      };
      this.$request(
        "post",
        "/client/addClientDirect",
        requestData,
        (data) => {
          this.dialogData.flag = false;
          this.threadDrawerData.flag = false;
          // this.tempThreadId = ""; // 每次新增完,商机ID置空
          this.$message({
            message: "客户创建成功!",
            duration: 1000,
            type: "success",
          });
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 彻底删除商机
     */
    shiftDeleteThread(array) {
      let requestData = {
        threadId: array, // 商机ID
      };
      this.$request(
        "post",
        // "/thread/shiftDeleteThread",
        "/reThread/shiftDeleteThread",
        requestData,
        (data) => {
          this.dialogData.flag = false;
          this.threadDrawerData.flag = false;
          this.$message({
            message: "删除成功!",
            type: "success",
            duration: 1000,
          });
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 商机还原
     */
    restoreThread(array) {
      let requestData = {
        threadId: array, // 商机ID
      };
      this.$request(
        "post",
        // "/thread/restoreThread",
        "/reThread/restoreThread",
        requestData,
        (data) => {
          this.dialogData.flag = false;
          this.threadDrawerData.flag = false;
          array.forEach((element) => {
            this.$operationRecords.saveOperationRecords(
              element,
              "将商机【" + this.tempThread.name + "】还原!",
              this.baseInfo,
              "thread",
              this,
              (data) => {
                this.initTableData();
              }
            );
          });
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 商机转成交
     */
    makeBargainThread() {
      let requestData = {
        threadId: this.tempThread.id, // 商机ID
      };
      this.$request(
        "post",
        // "/thread/makeBargainThread",
        "/reThread/makeBargainThread",
        requestData,
        (data) => {
          this.dialogData.flag = false;
          this.$operationRecords.saveOperationRecords(
            this.tempThread.id,
            "将商机【" + this.tempThread.name + "】转成交!",
            this.baseInfo,
            "thread",
            this,
            (data) => {}
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 查看操作记录
     */
    getOperationRecordsList() {
      this.$operationRecords.showOperationRecords(
        this.tempThread.id,
        "thread",
        this
      );
    },
    /**
     * 确认添加意向产品
     */
    addPurposeThread() {
      console.log("确认添加意向产品", this.dialogData.data);
      let requestData = {
        threadId: this.tempThread.id, // 商机ID
        value: this.dialogData.data[0].value.chooseProduct,
      };
      if (requestData.value.length == 0) {
        this.$message({
          message: "产品不可为空!",
          type: "error",
          duration: 1000,
        });
        return;
      }
      this.$request(
        "post",
        // "/thread/addPurposeThread",
        "/reThread/addPurposeThread",
        requestData,
        (data) => {
          this.dialogData.flag = false;
          this.$operationRecords.saveOperationRecords(
            this.tempThread.id,
            "将商机【" + this.tempThread.name + "】添加意向产品!",
            this.baseInfo,
            "thread",
            this,
            (data) => {
              this.initTableData();
              this.getMyThreadDetailsByThreadId();
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },

    /**
     * 保存抽屉数据
     */
    saveDrawerData(drawerTitle) {
      if (
        !myThreadData.getDrawerSubmitData(this.threadDrawerData.data.modelList)
          .flag
      ) {
        this.$message({
          message: "联系人手机号格式不正确,请确认填入正确的手机号!",
          type: "error",
          duration: 1000,
        });
        // this.getMyThreadDetailsByThreadId();
        return;
      }
      let requestData = {
        threadId: this.tempThread.id,
        userId: this.baseInfo.userId,
        company: this.baseInfo.company.companyId,
        data: myThreadData.getDrawerSubmitData(
          this.threadDrawerData.data.modelList
        ).list,
      };
      requestData.data.push({
        value: this.threadDrawerData.data.title,
        isShow: true,
        isDisable: false,
        flag: "0",
        key: "threadName",
        title: "商机名称",
      });
      // console.log("保存数据:", this.threadDrawerData.data);
      this.$request(
        "post",
        // "/thread/updateThread",
        "/reThread/updateThread",
        requestData,
        (data) => {
          this.$operationRecords.saveOperationRecords(
            this.tempThread.id,
            "修改" + drawerTitle + "信息!",
            this.baseInfo,
            "thread",
            this,
            (data) => {
              this.getMyThreadDetailsByThreadId();
              this.initTableData();
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          // this.loading = false;
        }
      );
    },
    /**
     * 右边抽屉父子组件连锁事件
     */
    showDrawer(value) {
      this.threadDrawerData = value;
    },
    /**
     * 右边抽屉关闭事件
     */
    closeUpdateTableDrawer() {
      this.threadDrawerData.colseanimationflag = true;
      this.threadDrawerData.flag = false;
      // this.initTableData();
    },

    /**
     * 拖拽表头排序父子组件连锁事件
     */
    showDrager(value) {
      this.tableMenuData = value;
    },
    /**
     * 表头div拖动方法
     */
    dragging(value) {
      console.log("div拖动", value);
    },

    /**
     * 修改表头顺序dialog关闭
     */
    closeUpdateTableMenu() {
      this.tragerDialogData.flag = false;
    },
    colseDialog() {
      this.dialogData.flag = false;
    },

    /**
     * 查看跟进记录
     */
    showGenjin(clientData) {
      this.ttempClient = clientData;
      let requestData = {
        threadId: clientData.id,
        clientId: "",
      };
      this.$request(
        "post",
        "/followRecord/showFollowRecord",
        requestData,
        (data) => {
          this.dialogData.title = "跟进记录-" + clientData.threadName;
          this.dialogData.buttonArray = [];
          this.dialogData.data = [
            {
              type: "genjinjilu",
              value: {
                clientId: clientData.id,
                threadId: "",
                hasContent: false,
                content: "",
                time: "",
                list: data,
                hasTarget: false,
                target: "",
                hasPhase: true,
                phase: "",
              },
            },
          ];
          this.dialogData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
  },
  mounted() {
    this.loading = true;
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#recyle-thread {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .thread-screen {
    width: 100%;
    height: auto;
    border-bottom: 1px solid @boderColor;
    display: flex;
    flex-direction: column;
    align-items: center;
    .thread-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      > .title {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 0 10px;
      }
      > .el-tag {
        margin: 0 5px;
      }

      .thread-screen-fiftrate {
        width: 65px;
        min-width: 65px;
        height: 30px;
        border: 1px solid @boderColor;
        box-sizing: border-box;
        color: @boderActiveColor;
        font-size: @contentFontSize;
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: 0.3s;
        i {
          margin: 0 4px;
        }
      }
      .thread-screen-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }
      .thread-screen-search {
        width: 210px;
        min-width: 210px;
        height: 30px;
      }
      .thread-screen-checked {
        width: 140px;
        height: 30px;
        min-width: 140px;
        font-size: @contentFontSize;
        margin: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .thread-screen-active {
        flex: 1;
      }
      .thread-screen-but-select {
        width: 140px;
        min-width: 160px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
        cursor: pointer;
        .thread-screen-but-select_but {
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.5s;
        }
        .thread-screen-but-select_but:hover {
          background-color: @activeColor2;
        }
        .thread-screen-but-select_select {
          width: 30px;
          height: 100%;
          border-left: 1px solid @activeColor3;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .thread-screen-but {
        width: 100px;
        min-width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .thread-screen-but:hover {
        cursor: pointer;
        background-color: @activeColor2;
      }
      .thread-screen-but-boder {
        width: 50px;
        min-width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        font-size: @contentFontSize;
        background-color: @activeBackground;
        color: @activeColor;
        margin: 0 5px;
        margin-right: 10px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .thread-screen-but-boder:hover {
        cursor: pointer;
        background-color: @activeBackground2;
      }
    }
  }
  .thread-table {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    position: relative;
    .thread-table-card-content-tip {
      width: 98%;
      height: 4vh;
      position: absolute;
      top: 2vh;
      z-index: 99;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .thread-table-card-active {
        flex: 1;
      }
      .thread-table-card-edit {
        width: 30px;
        min-width: 30px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: @editColor;
        cursor: pointer;
      }
    }
    .thread-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .thread-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        .thread-table-card-tab {
          width: 80px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .thread-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .thread-table-card-active {
          flex: 1;
        }
        .thread-table-card-edit {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: @editColor;
          cursor: pointer;
        }
      }
      .thread-table-card-content {
        flex: 1;
        width: 98%;
        height: 0;
        display: flex;
        align-content: center;
        justify-content: center;
        font-size: @contentFontSize;
        color: @closeColor;
      }
      .thread-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }
  .hri {
    display: flex;
    i {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 3px;
      font-size: 14px;
      color: @activeColor;
    }
  }
}
</style>
