<template>
  <div id="step">
    <div class="table-line title">
      <div class="table-line-cell"></div>
      <div class="table-line-cell">步骤名称</div>
      <div class="table-line-cell">执行人</div>
      <div class="table-line-cell">执行状态</div>
      <div class="table-line-cell">操作</div>
      <div class="table-line-cell" style="color: #ff8040">派工</div>
    </div>
    <div class="table-line" v-for="(item, index) in value" :key="index">
      <div class="table-line-cell">步骤{{ index + 1 }}</div>
      <div class="table-line-cell">
        <el-tooltip
          class="item"
          effect="dark"
          :content="'步骤说明: ' + item.des"
          placement="top"
          :disabled="!item.des"
        >
          <el-input
            v-model="item.name"
            @change="handleClick(item, index, 0)"
          ></el-input>
        </el-tooltip>
      </div>

      <div class="table-line-cell" @click="item.flag = !item.flag">
        <template v-if="!item.flag">
          <div v-for="(it, ii) in item.users" :key="it.id">
            <span v-if="ii > 0">、</span>{{ it.label }}
          </div>
        </template>
        <el-select
          v-else
          v-focus
          v-model="item.users"
          multiple
          filterable
          value-key="value"
          :multiple-limit="2"
          @remove-tag="removeTag"
          @visible-change="visibleChange($event, item, index)"
        >
          <el-option
            v-for="itt in allEmployeeList"
            :key="itt.value"
            :label="itt.label"
            :value="itt"
          >
          </el-option>
        </el-select>
      </div>
      <div class="table-line-cell ">
        <!-- active yes 执行中 done 已完成 no 待执行 dispatch 已派工 -->
        <span
          class="done"
          v-if="item.active == 'yes'"
          @click="handleClick(item, index, 4)"
        >
          执行中</span
        >
        <span
          class="detail"
          v-else-if="item.active == 'done'"
          @click="handleClick(item, index, 5)"
          >完成详情</span
        >
        <span class="dispatch" v-else-if="item.active == 'dispatch'"
          >已派工</span
        >
        <span class="wait" v-else>待执行</span>
      </div>
      <div class="table-line-cell">
        <!-- <el-button
          type="text"
          v-if="item.active == 'no'"
          @click="handleClick(item, index, 0)"
          >编辑</el-button
        > -->
        <el-button
          v-if="item.active == 'yes'"
          type="text"
          @click="handleClick(item, index, 1)"
          >插入</el-button
        >
        <el-button v-if="item.active == 'done'" style="color: #ccc" type="text"
          >插入</el-button
        >
        <el-button
          v-if="item.active == 'yes'"
          type="text"
          @click="handleClick(item, index, 2)"
          >删除</el-button
        >
        <el-button v-if="item.active == 'done'" style="color: #ccc" type="text"
          >删除</el-button
        >
      </div>
      <div class="table-line-cell">
        <el-button
          style="color: #ff8040"
          type="text"
          v-if="item.active == 'yes'"
          @click="handleClick(item, index, 3)"
          >派工</el-button
        >
        <el-button v-if="item.active == 'done'" style="color: #ccc" type="text"
          >派工</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["stepData"],
  data() {
    return {
      value: this.stepData,
      allEmployeeList: [],
    };
  },
  watch: {
    stepData() {
      //父组件值变化->>子组件也变化
      this.value = this.stepData;
    },
    value(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("showExpend", newvalue);
    },
  },
  methods: {
    /**
     * 编辑步骤
     */

    handleClick(data, index, flag) {
      switch (flag) {
        case 0:
          this.$emit("editStep", { data, index });
          break;
        case 1:
          this.$emit("addStep", { data, index });
          break;
        case 2:
          this.$emit("deleteStep", { data, index });
          break;
        case 3:
          this.$emit("workOrder", { data, index });
          break;
        case 4:
          this.$emit("doneStep", { data, index });
          break;
        case 5:
          this.$emit("detailStep", { data, index });
          break;
        default:
          break;
      }
    },
    /**
     * 获取所有在职员工
     */
    getEmployeeListForDownBox() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/user/getEmployeeListForDownBox",
        requestData,
        (data) => {
          this.allEmployeeList = [];
          data.forEach((element) => {
            let dd = {
              label: element.userName,
              value: element.userId,
            };
            this.allEmployeeList.push(dd);
          });
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    removeTag() {},
    visibleChange(val, item, index) {
      console.log("visibleChangevisibleChange");
      if (!val) {
        this.handleClick(item, index, 0);
      }
    },
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getEmployeeListForDownBox();
  },
};
</script>

<style lang="less" scoped>
#step {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-size: @contentFontSize4;
  .table-line {
    width: 100%;
    height: 40px;
    display: flex;
    align-content: center;
    border-top: 1px solid @boderColor;
    &:nth-child(1) {
      border: none;
    }
    .table-line-cell {
      flex: 2;
      width: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      // border-left: 1px solid @boderColor;
      box-sizing: border-box;
      overflow: hidden;
      white-space: nowrap;
      padding: 0 5px;
      &:nth-child(1) {
        border: none;
        flex: 0.6;
      }
      &:nth-last-child(3) {
        flex: 1;
      }
      &:nth-last-child(2) {
        flex: 1;
      }
      &:nth-last-child(1) {
        flex: 1;
      }
      /deep/ .el-button {
        font-size: 12px;
        color: #2c3e50;
      }
      /deep/ .el-input__inner {
        width: 100% !important;
        &:hover {
          border: 1px solid transparent;
        }
        &:focus {
          border: 1px solid #ccc;
        }
      }
      /deep/ .el-select__tags {
        flex-wrap: nowrap;
      }
      .done {
        width: 60px;
        height: 20px;
        color: #67c23a;
        border: 1px solid #67c23a;
        border-radius: 3px;
        line-height: 20px;
        cursor: pointer;
      }
      .wait {
        width: 60px;
        height: 20px;
        color: @baseColor;
        border: 1px solid @baseColor;
        border-radius: 3px;
        line-height: 20px;
        cursor: pointer;
      }
      .detail {
        width: 60px;
        height: 20px;
        color: #909399;
        border: 1px solid #909399;
        border-radius: 3px;
        line-height: 20px;
        cursor: pointer;
      }
      .dispatch {
        width: 60px;
        height: 20px;
        line-height: 20px;
        color: #ff8040;
        border: 1px solid #ff8040;
        border-radius: 3px;
        cursor: pointer;
      }
    }
  }
  .title {
    background-color: rgba(167, 167, 167, 0.199);
  }
}
</style>
