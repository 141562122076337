<template>
  <!-- 抽屉 待办 -->
  <div id="company-drawer-table-assignment">
    <div class="company-drawer-table-assignment-mode">
      <div
        :class="{
          'company-drawer-table-assignment-mode-item': true,
          active: flag,
        }"
        @click="flag = true"
      >
        待办
      </div>
      <div class="company-drawer-table-assignment-mode-border"></div>
      <div
        :class="{
          'company-drawer-table-assignment-mode-item': true,
          active: !flag,
        }"
        @click="flag = false"
      >
        派工
      </div>
    </div>
    <div class="company-drawer-table-assignment-a" v-if="flag">
      <companyDrawerTableAssignmentWait
        :tempClient="tempClient"
        @editClient="editClient"
      ></companyDrawerTableAssignmentWait>
    </div>
    <div class="company-drawer-table-assignment-a" v-else>
      <companyDrawerTableAssignmentContract
        :tttempClient="tempClient"
        @editClient="editClient"
      ></companyDrawerTableAssignmentContract>
    </div>
  </div>
</template>

<script>
import companyDrawerTableAssignmentWait from "@/views/main-components/company-drawer-table-assignment-wait";
import companyDrawerTableAssignmentContract from "@/views/main-components/company-drawer-table-assignment-contract";

export default {
  components: {
    companyDrawerTableAssignmentWait,
    companyDrawerTableAssignmentContract,
  },
  props: {
    tempClient: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      flag: true,
    };
  },
  methods: {
    editClient(value) {
      this.$emit("editClient", value);
    },
  },
  created() {},
};
</script>

<style lang="less" scoped>
#company-drawer-table-assignment {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .company-drawer-table-assignment-mode {
    width: 30%;
    height: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 10px;
    // border-bottom: 1px solid #909399;
    .company-drawer-table-assignment-mode-item {
      font-size: 14px;
      cursor: pointer;
    }
    .company-drawer-table-assignment-mode-border {
      width: 2px;
      height: 15px;
      border-radius: 3px;
      background-color: #909399;
    }
    .active {
      color: @baseColor;
    }
  }
  .company-drawer-table-assignment-a {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .company-drawer-table-assignment-search {
      width: 100%;
      height: auto;
      display: flex;
      margin-bottom: 10px;
      .company-drawer-table-assignment-search-item {
        width: 170px;
        min-width: 170px;
        height: 30px;
        margin-right: 10px;
        display: flex;
        align-items: center;
      }
    }
    .company-drawer-table-assignment-content {
      width: 100%;
      height: 100%;
      border-top: 1px solid #e9ebed;
      .table-image {
        width: 40px;
        height: 40px;
      }
      .editRemark {
        color: #409eff;
        cursor: pointer;
      }
      /deep/ .el-table--scrollable-x .el-table__body-wrapper {
        z-index: 1;
        &::-webkit-scrollbar {
          height: 6px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: rgba(0, 0, 0, 0.2);
        }
      }
      /deep/ .el-table--scrollable-y .el-table__body-wrapper {
        x &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: rgba(0, 0, 0, 0.2);
        }
      }
    }
    .company-drawer-table-assignment-pagination {
      width: 100%;
      height: auto;
    }
  }
}
</style>
