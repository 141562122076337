<template>
  <div id="table-payroll">
    <div class="table-payroll-search">
      <div class="table-payroll-search-line">
        <el-date-picker
          v-if="data.type == 'date'"
          v-model="data.requestData.date"
          type="month"
          value-format="yyyy-MM"
          placeholder="选择月"
          :clearable="false"
          @change="resetPage"
        >
        </el-date-picker>
        <el-date-picker
          v-if="data.type == 'dataDate'"
          v-model="data.requestData.dataDate"
          type="month"
          value-format="yyyy-MM"
          placeholder="选择月"
          :clearable="false"
          @change="resetPage"
        >
        </el-date-picker>
      </div>
    </div>
    <div>
      <el-table
        ref="tableData"
        class="tableData-content"
        :data="tableData"
        :header-cell-style="{ background: '#F5F7FA', color: '#606266' }"
        style="width:1300px"
        height="500"
        :row-style="{ height: '60px' }"
        v-loading="loading"
        border
        :show-summary="data.showSummary"
        :summary-method="summaryMethods"
      >
        <el-table-column type="index" width="50" fixed> </el-table-column>
        <el-table-column
          v-for="(item, index) in data.tableMenuData"
          :key="index"
          :property="item.prop"
          :fixed="index == 0"
          :label="item.title"
          :width="item.prop == 'clientName' ? 170 : item.width"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div
              v-if="
                item.prop == 'royaltyRate' ||
                  item.prop == 'chargePersonRatio' ||
                  item.prop == 'serviceConsultantRatio' ||
                  item.prop == 'sponsorAccountingRatio' ||
                  item.prop == 'accountManagerRatio' ||
                  item.prop == 'divisionHeadRatio'
              "
            >
              <span> {{ scope.row[item.prop] }}%</span>
            </div>
            <span v-else>
              {{ scope.row[item.prop] }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import * as humanData from "../js/humanData";
export default {
  props: {
    data: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      loading: false,
      clientName: "",
      date: "",
      tableData: [],
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
    };
  },

  methods: {
    initTableData() {
      this.loading = true;
      let requestData = {
        ...this.data.requestData,
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
      };
      this.$request(
        "post",
        this.data.url,
        requestData,
        (data) => {
          this.tableData = data.records;
          this.pageData.total = data.total; // 获取总条目数
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    /**
     * 表格合计计算
     */
    summaryMethods(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index == 0) {
          sums[index] = "合计";
          return;
        }
        for (let i = 0; i < this.data.summary.length; i++) {
          const element = this.data.summary[i];
          if (index == element) {
            sums[index] = "";
            return;
          }
        }
        const values = data.map((item) => Number(item[column.property]));
        sums[index] = values.reduce((prev, curr) => {
          const value = Number(curr);
          let sum = Number(Number(prev) + Number(curr)).toFixed(2); //toFixed(2)数据项保留两位小数
          if (!isNaN(value)) {
            return sum;
          } else {
            return prev;
          }
        }, 0);
        sums[index];
      });
      return sums;
    },
    resetPage() {
      this.pageData.currentPage = 1;
      this.initTableData();
    },

    lazyLoading() {
      // 获取目标滚动元素
      let dom = this.$refs.tableData.bodyWrapper;
      dom.addEventListener("scroll", () => {
        const scrollDistance =
          dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        console.log("鼠标滑动-scrollDistance", scrollDistance);
        // if (scrollDistance <= 0) {  //分辨率问题，如果设置 100% ，滑倒最底部，scrollDistance的值 可能为 0.201 到 -0.201
        if (scrollDistance < 1) {
          if (this.tableData.length >= this.pageData.total) {
            // 到达底部 数据加载完毕
            return;
          }
          // 滑动到表格底部 可以请求接口
          this.loading = true;
          let requestData = {
            ...this.data.requestData,
            currentPage: ++this.pageData.currentPage, // 当前页数
            pageSize: this.pageData.pageSize, // 每页条数
          };
          this.$request(
            "post",
            this.data.url,
            requestData,
            (data) => {
              // 获取的数据追加到原来的数据中
              this.tableData = [...this.tableData, ...data.records];
              // this.waiteDataList = data.records;
              this.pageData.total = data.total;
              this.loading = false;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
        }
      });
    },
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.tableData.doLayout();
    });
  },
  mounted() {
    this.initTableData();
    this.lazyLoading();
  },
};
</script>

<style lang="less" scoped>
#table-payroll {
  height: 80%;
  display: flex;
  flex-direction: column;
  .table-payroll-search {
    height: 40px;
    display: flex;
    .table-payroll-search-line {
      width: 250px;
      margin-right: 5px;
      display: flex;
      align-items: center;
    }
  }
  .tableData-content {
    /deep/ .el-table {
      overflow: visible !important;
    }

    /deep/ .el-table__body-wrapper {
      &::-webkit-scrollbar {
        height: 8px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.2);
      }
    }
    .table-tip {
      height: 30px;
      width: auto;
      padding: 2px 5px;
      margin: 0 3px;
      border: 1px solid #333;
      border-radius: 5px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .social-security-table-card-bottom {
    width: 95%;
    height: 40px;
    border-top: 1px solid @boderColor;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
