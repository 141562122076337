/**
 * 获取部门详情测试数据
 */
export function getDepartmentBaseInfo(name) {
    return {
        id: "",
        name: name,
        parentName: "峻岭集团",
        parentId: "",
        manager: [], // 部门主管
        managerRoleId: "", // 部门主管角色ID
        staff: [], // 部门员工
        staffId: "", // 部门员工角色ID
        des: "", // 部门介绍
        type: 0, // 0普通部门 1公司 2分公司
        role: [{
                id: "11",
                name: "服务顾问",
                type: 0, // 0 无  1 自己  2 指定人员
                user: [],
            },
            {
                id: "22",
                name: "客户经理",
                type: 1, // 0 无  1 自己  2 指定人员
                user: [],
            },
            {
                id: "33",
                name: "收费负责人",
                type: 0, // 0 无  1 自己  2 指定人员
                user: [],
            },
            {
                id: "44",
                name: "开票员",
                type: 0, // 0 无  1 自己  2 指定人员
                user: [],
            },
            {
                id: "55",
                name: "合同审核员",
                type: 0, // 0 无  1 自己  2 指定人员
                user: [],
            },
            {
                id: "66",
                name: "出纳",
                type: 0, // 0 无  1 自己  2 指定人员
                user: [],
            },
            {
                id: "77",
                name: "分部负责人",
                type: 0, // 0 无  1 自己  2 指定人员
                user: [],
            },
            {
                id: "88",
                name: "主办会计",
                type: 0, // 0 无  1 自己  2 指定人员
                user: [],
            },
        ],
    }
}

/**
 * 角色功能模拟数据
 */
export function getDepartmentRoleInitData() {
    let data = [{
            name: "商机",
            id: "shangjiId",
            show: 1, // 当前模块功能key为show的authority
            model: "thread",
            children: [{
                    name: "我的资源",
                    show: 3, // 当前模块功能key为show的authority
                    model: "myResources",
                    id: "wodeziyuanId",
                    isAttribute: false, // 是否进行属性设置
                    feature: [
                        { name: "跟进", key: "followUp", authority: '1', id: "" },
                        { name: "编辑", key: "edit", authority: '1', id: "" },
                        { name: "删除", key: "delete", authority: '1', id: "" },
                        { name: "分配", key: "allocation", authority: '3', id: "" },
                        { name: "导入", key: "import", authority: '1', id: "" },
                        { name: "导出", key: "export", authority: '1', id: "" },
                        { name: "新建", key: "create", authority: '1', id: "" },
                    ],
                },
                {
                    name: "我的商机",
                    show: 3, // 当前模块功能key为show的authority
                    model: "myThread",
                    id: "wodeshangji",
                    isAttribute: true, // 是否进行属性设置
                    feature: [
                        { name: "新增", key: "create", authority: '1', id: "" },
                        { name: "导出", key: "export", authority: '1', id: "" },
                        { name: "跟进", key: "followUp", authority: '1', id: "" },
                        { name: "转成交", key: "turnover", authority: '1', id: "" },
                        { name: "置为无效", key: "invalid", authority: '1', id: "" },
                        { name: "置入公海", key: "openSea", authority: '1', id: "" },
                        { name: "删除", key: "delete", authority: '1', id: "" },
                        { name: "编辑", key: "edit", authority: '1', id: "" },
                    ],
                },
                {
                    name: "全部商机",
                    show: 1, // 当前模块功能key为show的authority
                    model: "allThread",
                    id: "quanbushangji",
                    isAttribute: true, // 是否进行属性设置
                    feature: [
                        { name: "编辑", key: "edit", authority: '1', id: "" },
                        { name: "导出", key: "export", authority: '1', id: "" },
                        { name: "跟进", key: "followUp", authority: '1', id: "" },
                        { name: "转成交", key: "turnover", authority: '1', id: "" },
                        { name: "置为无效", key: "invalid", authority: '1', id: "" },
                        { name: "置入公海", key: "openSea", authority: '1', id: "" },
                        { name: "删除", key: "delete", authority: '1', id: "" },
                    ],
                },
                {
                    name: "已签约客户",
                    show: 1, // 当前模块功能key为show的authority
                    model: "clientThread",
                    id: "yiqingyuejkehu",
                    isAttribute: false, // 是否进行属性设置
                    feature: [
                        { name: "调户", key: "adjustableIndoor", authority: '1', id: "" },
                        { name: "导出", key: "export", authority: '1', id: "" },
                    ],
                },
                {
                    name: "商机池",
                    show: 1, // 当前模块功能key为show的authority
                    model: "threadPool",
                    id: "shangjichi",
                    isAttribute: true, // 是否进行属性设置
                    feature: [
                        { name: "编辑", key: "edit", authority: '1', id: "" },
                        { name: "导出", key: "export", authority: '1', id: "" },
                        { name: "分配", key: "distribution", authority: '1', id: "" },
                        { name: "恢复", key: "restore", authority: '1', id: "" },
                        { name: "置为无效", key: "invalid", authority: '1', id: "" },
                        { name: "置入公海", key: "openSea", authority: '1', id: "" },
                        { name: "删除", key: "delete", authority: '1', id: "" },
                    ],
                },
                {
                    name: "回收站",
                    show: 1, // 当前模块功能key为show的authority
                    model: "threadRecycle",
                    id: "huishouzhan",
                    isAttribute: true, // 是否进行属性设置
                    feature: [
                        { name: "编辑", key: "edit", authority: '1', id: "" },
                        { name: "还原", key: "reduction", authority: '1', id: "" },
                        { name: "彻底删除", key: "shiftDelete", authority: '1', id: "" },
                    ],
                }, {
                    name: "设置",
                    show: 3, //  // 当前模块功能key为show的authority
                    model: "threadSetting",
                    id: "shangjishezhi",
                    children: [{
                            name: "选项值设置",
                            show: 1, // 当前模块功能key为show的authority
                            model: "threadOption",
                            id: "sjxuanxiangzhishezhi",
                            isAttribute: false, // 是否进行属性设置
                            feature: [
                                { name: "编辑", key: "edit", authority: '3', id: "" },
                            ],
                        },
                        {
                            name: "商机保护",
                            show: 1, // 当前模块功能key为show的authority
                            model: "threadProtect",
                            id: "shangjibaohu",
                            isAttribute: false, // 是否进行属性设置
                            feature: [
                                { name: "编辑", key: "edit", authority: '1', id: "" },
                            ],
                        },
                        {
                            name: "异常电话",
                            show: 1, // 当前模块功能key为show的authority
                            model: "abnormalPhone",
                            id: "yichangdianhua",
                            isAttribute: false, // 是否进行属性设置
                            feature: [
                                { name: "添加", key: "add", authority: '1', id: "" },
                                { name: "编辑", key: "edit", authority: '1', id: "" },
                                { name: "导入", key: "import", authority: '1', id: "" },
                                { name: "导出", key: "export", authority: '1', id: "" },
                                { name: "彻底删除", key: "delete", authority: '1', id: "" },
                            ],
                        },
                    ]
                }
            ],
        },
        {
            name: "客户",
            show: 1, // 当前模块功能key为show的authority
            model: "client",
            id: "kehuId",
            children: [{
                name: "客户资料",
                show: 1, // 当前模块功能key为show的authority
                model: "clientInfo",
                id: "kehuziliao",
                isAttribute: true, // 是否进行属性设置
                feature: [
                    { name: "新增", key: "create", authority: '1', id: "" },
                    { name: "导入", key: "import", authority: '1', id: "" },
                    { name: "跟进", key: "followUp", authority: '1', id: "" },
                ],
                children: [{
                    name: "客户信息",
                    show: 1, // 当前模块功能key为show的authority
                    model: "clientDetails",
                    id: "kehuxinxi",
                    isAttribute: true, // 是否进行属性设置
                    feature: [
                        { name: "跟进", key: "followUp", authority: '1', id: "" },
                        { name: "删除", key: "delete", authority: '1', id: "" },
                        { name: "申请流失", key: "lossOf", authority: '1', id: "" },
                        { name: "添加关联客户", key: "associat", authority: '1', id: "" },
                    ]
                }, {
                    name: "联系人",
                    show: 1, // 当前模块功能key为show的authority
                    model: "clientContact",
                    id: "lianxiren",
                    isAttribute: false, // 是否进行属性设置
                    feature: [
                        { name: "新增", key: "create", authority: '1', id: "" },
                        { name: "删除", key: "delete", authority: '1', id: "" },
                        { name: "编辑", key: "edit", authority: '1', id: "" },
                    ]
                }, {
                    name: "合同",
                    show: 3, // 当前模块功能key为show的authority
                    model: "clientContract",
                    id: "kehuhetong",
                    isAttribute: false, // 是否进行属性设置
                    feature: [
                        { name: "新增", key: "create", authority: '1', id: "" },
                        { name: "查看详情", key: "details", authority: '1', id: "" },
                    ]
                }, ],
            }, {
                name: "工作交接",
                show: 1, // 当前模块功能key为show的authority
                model: "clientConnect",
                id: "gongzuojiaojie",
                isAttribute: false, // 是否进行属性设置
                feature: [
                    { name: "分配", key: "assign", authority: '1', id: "" }
                ]
            }, {
                name: "流失审核",
                show: 1, // 当前模块功能key为show的authority
                model: "clientCheck",
                id: "liushishenhe",
                isAttribute: false, // 是否进行属性设置
                feature: [
                    { name: "审核", key: "check", authority: '1', id: "" }
                ]
            }, {
                name: "设置",
                show: 1, // 当前模块功能key为show的authority
                model: "clientSetting",
                id: "kehushezhi",
                children: [{
                    name: "选项值设置",
                    show: 1, // 当前模块功能key为show的authority
                    model: "clientOption",
                    id: "kehuxuanxiangzhi",
                    isAttribute: false, // 是否进行属性设置
                    feature: [
                        { name: "编辑", key: "edit", authority: '1', id: "" },
                    ],
                }, ]
            }]
        }, {
            name: "合同",
            show: 1, // 当前模块功能key为show的authority
            id: "hetongId",
            model: "contract",
            children: [{
                name: "全部合同",
                show: 1, // 当前模块功能key为show的authority
                model: "contractInfo",
                id: "quanbuhetong",
                isAttribute: true, // 是否进行属性设置
                feature: [
                    { name: "新增", key: "create", authority: '1', id: "" },
                    { name: "导出", key: "export", authority: '1', id: "" },
                    { name: "客户跟进", key: "followUp", authority: '1', id: "" },
                    { name: "查账/回款", key: "receivable", authority: '1', id: "" },
                    { name: "下单", key: "orders", authority: '1', id: "" },
                ],
                children: [{
                    name: "合同信息",
                    show: 1, // 当前模块功能key为show的authority
                    model: "contractDetails",
                    id: "hetongxinxi",
                    isAttribute: true, // 是否进行属性设置
                    feature: [
                        { name: "编辑", key: "edit", authority: '1', id: "" },
                        { name: "电子合同", key: "electronicContract", authority: '1', id: "" },
                        { name: "删除", key: "delete", authority: '1', id: "" },
                        { name: "手动结束", key: "finish", authority: '1', id: "" },
                        { name: "查看客户", key: "showClient", authority: '1', id: "" },
                    ]
                }, {
                    name: "收款记录",
                    show: 1, // 当前模块功能key为show的authority
                    model: "paymentRecords",
                    id: "shoukuanjilu",
                    isAttribute: true, // 是否进行属性设置
                    feature: [
                        { name: "查看附件", key: "showAttachment", authority: '1', id: "" },
                        { name: "查看客户", key: "showClient", authority: '1', id: "" },
                    ]
                }, {
                    name: "收费情况",
                    show: 1, // 当前模块功能key为show的authority
                    model: "chargeIs",
                    id: "shoufieqingkuang",
                    isAttribute: true, // 是否进行属性设置
                    feature: [
                        { name: "查看客户", key: "showClient", authority: '1', id: "" },
                    ]
                }, {
                    name: "合同支出",
                    show: 1, // 当前模块功能key为show的authority
                    model: "contractSpend",
                    id: "hetongzhichu",
                    isAttribute: true, // 是否进行属性设置
                    feature: [
                        { name: "查看客户", key: "showClient", authority: '1', id: "" },
                        { name: "查看附件", key: "showAttachment", authority: '1', id: "" },
                        { name: "添加支出", key: "showClient", authority: '1', id: "" },
                    ]
                }, {
                    name: "合同附件",
                    show: 1, // 当前模块功能key为show的authority
                    model: "contractAttachment",
                    id: "hetongfujian",
                    isAttribute: true, // 是否进行属性设置
                    feature: [
                        { name: "查看客户", key: "showClient", authority: '1', id: "" },
                        { name: "添加附件", key: "add", authority: '1', id: "" },
                        { name: "删除附件", key: "delete", authority: '1', id: "" },
                    ]
                }, ]
            }]
        }
    ]

    return data
}
/** */
export function initOthersRoleAuthority(roleList, otherList) {
    for (let index = 0; index < roleList.length; index++) {
        let tureRole = roleList[index];
        for (let i = 0; i < otherList.length; i++) {
            let otherRole = otherList[i];

        }
    }
}

/**
 * 角色功能界面显示时的数据格式化
 */
export function formattFunctionData(array, this_, flag) {
    for (let index = 0; index < array.length; index++) {
        let element = array[index];
        if (element.children && element.children.length > 0) {
            element.show = flag ? (element.show == '1' ? true : false) : flag
            formattFunctionData(element.children, this_, element.show)
        } else if (element.feature && element.feature.length > 0) {
            element.show = flag ? (element.show == '1' ? true : false) : flag
            formattFunctionData(element.feature, this_, !element.show)
        } else {
            if (element.hasOwnProperty('authority')) {
                element.authority = element.authority == '1' ? true : false
            } else {
                element.show = element.show == '1' ? true : false
            }
            this_.$set(element, "isd", flag)
        }
    }

    return array
}


/**
 * 角色功能界面提交时的数据格式化
 */
export function formattSubmitFunctionData(array, this_) {
    for (let index = 0; index < array.length; index++) {
        let element = array[index];
        if (element.children && element.children.length > 0) {
            element.show = element.show == true ? '1' : '3'
            formattSubmitFunctionData(element.children, this_)
        } else if (element.feature && element.feature.length > 0) {
            element.show = element.show == true ? '1' : '3'
            formattSubmitFunctionData(element.feature, this_)
        } else {
            if (element.hasOwnProperty('authority')) {
                element.authority = element.authority == true ? '1' : '3'
            } else {
                element.show = element.show == true ? '1' : '3'
            }
            this_.$delete(element, "isd")
        }
    }

    return array
}

/**
 * 数组转对象
 * @param {*} array 
 */
export function turnArrayObject(array, returnArray) {
    for (let index = 0; index < array.length; index++) {
        let element = array[index];
        if (element.children && element.children.length > 0) {
            returnArray.push({ key: "show", authority: element.show, name: element.name, id: element.id })
           
            turnArrayObject(element.children, returnArray)
        } else if (element.feature && element.feature.length > 0) {
            
            returnArray.push({ key: "show", authority: element.show, name: element.name, id: element.id })
            turnArrayObject(element.feature, returnArray)

        } else if (element.children && element.children.length == 0 && element.feature && element.feature.length == 0) {
            returnArray.push({ key: "show", authority: element.show, name: element.name, id: element.id })
        } else {
            returnArray.push({ key: element.key, authority: element.authority, name: element.name, id: element.id })
        }
    }
    return returnArray
}

/**
 * 关闭父模块的功能,同步关闭子模块,同时,禁用最里层子模块的功能
 */
export function firefoxSync(array) {
    for (let index = 0; index < array.length; index++) {
        let element = array[index];
        if (element.children && element.children.length > 0) {
            element.show = false
            firefoxSync(element.children)
        } else if (element.feature && element.feature.length > 0) {
            element.show = false
            firefoxSync(element.feature)
        } else {
            element.isd = true
        }
    }

    return array
}

/**
 * 开启子模块权限,同时开启该子模块的直属父级模块权限
 */
export function synchronizationTheParent(node) {
    node.data.show = true
    if (node.level == 1 || node.level == 0) {
        // 最高级别
        return node
    } else {
        synchronizationTheParent(node.parent)
    }
}

/**
 * 获取模块的基本属性
 */
export function getOptionsForRoleModel(id) {
    let data = []
    switch (id) {
        case "wodeshangji":
            // 我的商机
        case "quanbushangji":
            // 全部商机
        case "shangjichi":
            // 商机池
        case "huishouzhan":
            // 回收站
            data = [
                { id: "", name: "商机名称", key: "threadName", authority: '1' },
                { id: "", name: "跟进联系人", key: "createTime", authority: '1' },
                { id: "", name: "商机状态", key: "createName", authority: '1' },
                { id: "", name: "跟进阶段", key: "contact", authority: '1' },
                { id: "", name: "商机创建人", key: "phone", authority: '1' },
                { id: "", name: "商机提交人", key: "remark", authority: '1' },
                { id: "", name: "客户来源", key: "remark", authority: '1' },
                { id: "", name: "增值税类型", key: "remark", authority: '1' },
                { id: "", name: "创建时间", key: "remark", authority: '1' },
                { id: "", name: "意向产品", key: "remark", authority: '1' },
                { id: "", name: "上次联系时间", key: "remark", authority: '1' },
                { id: "", name: "下次联系时间", key: "remark", authority: '1' },
                { id: "", name: "最近分配时间", key: "remark", authority: '1' },
                { id: "", name: "跟进记录", key: "remark", authority: '1' },
                { id: "", name: "保护时间", key: "remark", authority: '1' },
                { id: "", name: "联系人", key: "remark", authority: '1' },
                { id: "", name: "手机", key: "remark", authority: '1' },
                { id: "", name: "片区", key: "remark", authority: '1' },
                { id: "", name: "地区", key: "remark", authority: '1' },
                { id: "", name: "详细地址", key: "remark", authority: '1' },
                { id: "", name: "企业税号", key: "remark", authority: '1' },
                { id: "", name: "企业电话", key: "remark", authority: '1' },
                { id: "", name: "开户行", key: "remark", authority: '1' },
                { id: "", name: "银行账号", key: "remark", authority: '1' },
                { id: "", name: "备注", key: "remark", authority: '1' },
            ]
            break;
        case "kehuziliao":
            // 客户资料  表格数据
            data = [
                { id: "", name: "客户全称", key: "remark", authority: '1' },
                { id: "", name: "创建时间", key: "remark", authority: '1' },
                { id: "", name: "客户类型", key: "remark", authority: '1' },
                { id: "", name: "服务项", key: "remark", authority: '1' },
                { id: "", name: "客户经理", key: "remark", authority: '1' },
                { id: "", name: "服务顾问", key: "remark", authority: '1' },
                { id: "", name: "客户状态", key: "remark", authority: '1' },
                { id: "", name: "客户等级", key: "remark", authority: '1' },
                { id: "", name: "所属片区", key: "remark", authority: '1' },
                { id: "", name: "增值税", key: "remark", authority: '1' },
                { id: "", name: "客户编号", key: "remark", authority: '1' },
                { id: "", name: "成立日期", key: "remark", authority: '1' },
                { id: "", name: "详细地址", key: "remark", authority: '1' },
                { id: "", name: "所属地区", key: "remark", authority: '1' },
                { id: "", name: "客户标签", key: "remark", authority: '1' },
                { id: "", name: "行业分类", key: "remark", authority: '1' },
                { id: "", name: "创建人", key: "remark", authority: '1' },
                { id: "", name: "来源", key: "remark", authority: '1' },
                { id: "", name: "跟进时间", key: "remark", authority: '1' },
            ]
            break;
        case "kehuxinxi":
            // 客户信息  抽屉数据
            data = [
                { id: "", name: "客户类型", key: "remark", authority: '1' },
                { id: "", name: "客户等级", key: "remark", authority: '1' },
                { id: "", name: "所属片区", key: "remark", authority: '1' },
                { id: "", name: "来源", key: "remark", authority: '1' },
                { id: "", name: "客户标签", key: "remark", authority: '1' },
                { id: "", name: "客户全称", key: "remark", authority: '1' },
                { id: "", name: "客户编号", key: "remark", authority: '1' },
                { id: "", name: "增值税", key: "remark", authority: '1' },
                { id: "", name: "行业分类", key: "remark", authority: '1' },
                { id: "", name: "详细地址", key: "remark", authority: '1' },
                { id: "", name: "企业税号", key: "remark", authority: '1' },
                { id: "", name: "所在地区", key: "remark", authority: '1' },
                { id: "", name: "企业电话", key: "remark", authority: '1' },
                { id: "", name: "开户行", key: "remark", authority: '1' },
                { id: "", name: "银行账号", key: "remark", authority: '1' },
                { id: "", name: "开票客户", key: "remark", authority: '1' },
                { id: "", name: "业务角色", key: "remark", authority: '1' },
            ]
            break;

        default:
            break;
    }

    return data
}