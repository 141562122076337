<template>
  <div id="departure-setting">
    <!-- 离职设置 -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
#departure-setting {
  width: 100%;
  height: 100%;
}
</style>