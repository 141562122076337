<template>
  <div id="waite-contract">
    <div class="screen">
      <div class="client_info-screen-active"></div>
      <div class="client_info-screen-but" @click="handlClick({}, 'add')">
        自定义待办
      </div>
    </div>
    <div class="table-content">
      <div class="waite-contract_table-card">
        <div class="waite-contract-table-card-tabs">
          <div
            :class="{
              'waite-contract-table-card-tab': true,
              'is-active': tabindex == 0,
            }"
            @click="changeTab(0)"
          >
            待办
          </div>
          <div
            :class="{
              'waite-contract-table-card-tab': true,
              'is-active': tabindex == 1,
            }"
            @click="changeTab(1)"
          >
            历史待办
          </div>
          <div class="waite-contract-table-card-active"></div>
          <!-- <div class="waite-contract-table-card-edit">
            <i class="el-icon-edit-outline" @click="updateTableMenu"></i>
          </div> -->
        </div>
        <div class="waite-contract-table-card-content">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            @row-click="rowClick"
            style="width: 100%"
            height="98%"
          >
            <el-table-column type="index" width="55" fixed> </el-table-column>
            <el-table-column label="待办事项" prop="name"> </el-table-column>
            <el-table-column label="状态" prop="status" width="280">
            </el-table-column>
            <el-table-column label="时间" prop="des" width="180">
            </el-table-column>
            <el-table-column label="操作" width="280">
              <template slot-scope="scope">
                <div class="hri">
                  <el-button
                    type="primary"
                    size="mini"
                    v-if="scope.row.type == '9' && tabindex == 0"
                    @click.native.stop="handlClick(scope.row, 'finish')"
                    >完成</el-button
                  >
                  <el-button
                    type="primary"
                    size="mini"
                    v-if="tabindex == 1"
                    @click.native.stop="handlClick(scope.row, 'reduction')"
                    >还原</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="waite-contract-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="10"
            layout="total, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog7
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @showCustomToDoData="showCustomToDoData"
      ></my_dialog7>
    </div>
  </div>
</template>

<script>
import * as WaiteData from "@/assets/js/waiteData.js";
export default {
  data() {
    return {
      baseInfo: {},
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      tabindex: 0,
      tableData: [],
      type: "contract",
      employeeList: [],
      dialogData: {
        flag: false,
        width: "65%",
        title: "自定义待办事项",
        buttonArray: [
          {
            text: "提交",
            active: "submit",
          },
          {
            text: "取消",
            active: "cancel",
          },
        ],
        data: [],
      },
    };
  },
  methods: {
    /**
     * 初始化数据
     */
    initData() {
      let requestData = {
        type: this.type,
        userId: this.baseInfo.userId,
        flag: this.tabindex,
        companyId: this.baseInfo.company.companyId,
        currentPage: this.pageData.currentPage, // 当前页数  20210903 加
        pageSize: this.pageData.pageSize, // 每页条数  20210903 加
      };
      this.$request(
        "post",
        "/waitDeal/getAllWaitDealByUserId",
        requestData,
        (data) => {
          this.tableData = data.tableData;
          this.pageData.total = data.total;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * tab切换事件
     */
    changeTab(index) {
      this.tabindex = index;
      this.initData();
    },
    /**
     * 表格行点击事件
     */
    rowClick(row, colum, event) {
      if (this.tabindex == 0) {
        // 待办事项的点击事件
        let requestData = {
          userId: this.baseInfo.userId,
          id: row.id,
        };
        this.$request(
          "post",
          "/waitDeal/finishWaitDeal",
          requestData,
          (data) => {
            console.log("路由跳转:", WaiteData.routingHop(row.type, 1));
            this.$router.push(WaiteData.routingHop(row.type, 1));
          },
          (errorMessage) => {
            this.$message.error(errorMessage);
          }
        );
      } else {
        // 历史待办的点击事件
        // this.revokeWaitDeal(row.id);
      }
    },
    /**
     * 撤销历史待办事项
     */
    revokeWaitDeal(id) {
      let requestData = {
        userId: this.baseInfo.userId,
        id: id,
      };
      this.$request(
        "post",
        "/waitDeal/revokeWaitDeal",
        requestData,
        (data) => {
          this.initData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 具体事项操作事件
     */
    handlClick(data, flag) {
      let requestData = {};
      switch (flag) {
        case "finish":
          // 手动完成自定义事项
          // this.finishWaitDeal(data.id);
          let requestData = {
            userId: this.baseInfo.userId,
            id: data.id,
          };
          this.$request(
            "post",
            "/waitDeal/finishWaitDeal",
            requestData,
            (data) => {
              this.$message({
                message: "完成!",
                type: "success",
                duration: 1000,
              });
              this.initData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );

          break;
        case "reduction":
          // 还原历史事项
          this.revokeWaitDeal(data.id);
          break;
        case "add":
          // 添加自定义待办
          this.dialogData.data = [
            {
              type: "custom-to-do",
              title: "",
              value: {
                name: "",
                data: [],
              },
            },
          ];
          this.dialogData.flag = true;
          break;

        default:
          break;
      }
    },
    /**
     * dialog操作事件
     */
    drawerOper(active) {
      switch (active) {
        case "submit":
          // 确定添加自定义事项
          console.log("确定添加自定义待办:", this.dialogData.data);
          let data = [];
          let dd = {};
          this.dialogData.data[0].value.data.forEach((element) => {
            if (element.name == "") {
              this.$message.error("步骤描述不能为空!");
              return;
            }
            if (element.time == "") {
              this.$message.error("步骤预计执行时间不能为空!");
              return;
            }
            if (element.person.length == 0) {
              this.$message.error("步骤执行人不能为空!");
              return;
            }
            dd = {
              name: "", // 任务名称
              executor: [], // 执行人id列表
              expectTime: "",
            };
            dd.name = this.dialogData.data[0].value.name + "-" + element.name;
            dd.executor.push(this.baseInfo.userId);
            element.person.forEach((el) => {
              dd.executor.push(el.value);
            });
            dd.expectTime = element.time;
            data.push(dd);
          });

          let requestData = {
            userId: this.baseInfo.userId, // 发起人id
            companyId: this.baseInfo.company.companyId,
            data: data,
          };
          if (requestData.name == "") {
            this.$message.error("任务名称不能为空!");
            return;
          }
          this.$request(
            "post",
            "/waitDeal/addWaitDeal",
            requestData,
            (data) => {
              this.$message({
                showClose: true,
                message: "创建成功!",
                type: "success",
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.initData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "cancel":
          // dialog关闭
          this.colseDialog();
          break;

        default:
          break;
      }
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 自定义待办父子组件连锁事件
     */
    showCustomToDoData(val) {
      this.dialogData.data = val;
    },
  },
  mounted() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.initData();
    let employeeList = JSON.parse(
      JSON.stringify(this.$store.getters.getAllEmployeeList)
    );
    this.employeeList = [];
    employeeList.forEach((element) => {
      let dd = {
        label: element.userName,
        value: element.userId,
      };
      this.employeeList.push(dd);
    });
  },
};
</script>

<style lang="less" scoped>
#waite-contract {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .client_info-screen-active {
    flex: 1;
  }
  .client_info-screen-but {
    width: 100px;
    min-width: 100px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @activeColor;
    font-size: @contentFontSize;
    color: #fff;
    margin: 0 5px;
    border-radius: 4px;
    transition: 0.5s;
  }
  .client_info-screen-but:hover {
    cursor: pointer;
    background-color: @activeColor2;
  }
  .table-content {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    .waite-contract_table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .waite-contract-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .waite-contract-table-card-tab {
          width: auto;
          min-width: 100px;
          height: 100%;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .waite-contract-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .waite-contract-table-card-active {
          flex: 1;
        }
        .waite-contract-table-card-edit {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: @editColor;
          cursor: pointer;
        }
      }
      .waite-contract-table-card-content {
        flex: 1;
        width: 98%;
      }
      .waite-contract-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }

  .hri {
    display: flex;
    i {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 3px;
      font-size: 14px;
      color: @activeColor;
    }
  }
}
</style>