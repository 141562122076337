<template>
  <div id="system-authority" v-loading="loading">
    <!-- 系统设置 -> 权限管理 -->
    <div class="table-content">
      <div class="system-authority-content">
        <div class="content-tree">
          <div class="tree-title">
            部门设置
            <span>右击查看角色、对部门进行编辑</span>
          </div>
          <div class="div-tree">
            <div id="left_tree" ref="left_tree"></div>
          </div>
        </div>
        <div class="content-tree">
          <div class="tree-title">
            角色设置
            <span>右击新增角色、对角色进行编辑</span>
          </div>
          <div class="div-tree">
            <div id="right_tree" ref="right_tree"></div>
          </div>
        </div>
      </div>
    </div>

    <!--节点上的菜单-->
    <div class="tips" ref="tips" v-show="tipsData.tipsflag">
      <div
        v-for="(item, index) in tipsData.tipsList"
        :key="index"
        v-show="item.flag < 3"
        @click="tipsOper(item)"
      >
        {{ item.text }}
      </div>
    </div>

    <!-- dialog -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @authorityShow="authorityShow"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import * as systemAuthorityData from "../assets/js/systemAuthorityData.js";
import my_dialog from "../components/dialog.vue";
export default {
  components: {
    my_dialog,
  },
  data() {
    return {
      baseInfo: {},
      loading: true,
      isDisable: false,
      tipsData: {
        tipsflag: false,
        tipsList: [],
        leftTipsList: [
          {
            text: "新增部门",
            flag: 1,
            active: "addDepartment",
          },
          {
            text: "编辑部门",
            flag: 1,
            active: "updateDepartment",
          },
          {
            text: "删除部门",
            flag: 1,
            active: "deleteDepartment",
          },
          {
            text: "查看部门角色",
            flag: 1,
            active: "showAuthority",
          },
          {
            text: "取消",
            flag: 1,
            active: "cancel",
          },
        ],
        rightTipsList: [
          {
            text: "新增角色",
            flag: 1,
            active: "addAuthority",
          },
          {
            text: "编辑角色",
            flag: 1,
            active: "updateAuthority",
          },
          {
            text: "编辑角色权限",
            flag: 1,
            active: "editAuthority",
          },
          {
            text: "取消",
            flag: 1,
            active: "cancel",
          },
        ],
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "新增部门",
        buttonArray: [
          {
            text: "确认",
            active: "add_department",
          },
          {
            text: "取消",
            active: "cancel",
          },
        ],
        data: [],
      },
      contextMenuTarget: "",
      tempNode: {}, //点击节点时临时记录的节点内容
      tempDepartment: {}, //当前点击的部门
      headNode: false, //点击节点时,判断是否是头结点
      left_tree: "",
      right_tree: "",
      tempAuthorityUserList: [], // 临时存储给角色分配时,之前该角色所拥有的员工
      departmentTreeData: [],
      authorityTreeData: [],
      buttonAuth: {
        updateCompanyBut: 1, // 编辑公司按钮  1为读写  2为只读  3为不可见
        createDepartmentBut: 1, // 新增部门按钮  1为读写  2为只读  3为不可见
        updateDepartmentBut: 1, // 编辑部门按钮  1为读写  2为只读  3为不可见
        deleteDepartmentBut: 1, // 删除部门按钮  1为读写  2为只读  3为不可见
        showRoleBut: 1, // 查看部门角色按钮  1为读写  2为只读  3为不可见
        createRoleBut: 1, // 新增角色按钮  1为读写  2为只读  3为不可见
        updateRoleBut: 1, // 编辑角色按钮  1为读写  2为只读  3为不可见
        deleteRoleBut: 1, // 删除角色按钮  1为读写  2为只读  3为不可见
        editRoleBut: 1, // 编辑角色权限按钮
        allocationBut: 1, // 给角色分配人员
      },
    };
  },
  methods: {
    /**
     * 判断当前用户在改界面的权限
     */
    judgeAuthority() {
      let requestData = {
        userId: this.baseInfo.userId,
        model: "",
      };
      this.$systemBaseData.judgeAuthority(
        "department-system",
        "system",
        this,
        (data) => {
          if (this.baseInfo.userId == "super") {
            // 判断是不是系统管理员
            this.buttonAuth.updateCompanyBut = 2;
            this.buttonAuth.createDepartmentBut = 2;
            this.buttonAuth.updateDepartmentBut = 2;
            this.buttonAuth.deleteDepartmentBut = 2;
            this.buttonAuth.showRoleBut = 2;
            this.buttonAuth.createRoleBut = 2;
            this.buttonAuth.updateRoleBut = 2; // 编辑角色按钮  1为读写  2为只读  3为不可见
            this.buttonAuth.deleteRoleBut = 2; // 删除角色按钮  1为读写  2为只读  3为不可见
            this.buttonAuth.editRoleBut = 2; // 编辑角色权限按钮
            this.buttonAuth.allocationBut = 2; // 给角色分配人员
            return;
          }
          if (data.isAdmin) {
            // 判断是不是机构负责人
            this.buttonAuth.updateCompanyBut = 1;
            this.buttonAuth.createDepartmentBut = 1;
            this.buttonAuth.updateDepartmentBut = 1;
            this.buttonAuth.deleteDepartmentBut = 1;
            this.buttonAuth.showRoleBut = 1;
            this.buttonAuth.createRoleBut = 1;
            this.buttonAuth.updateRoleBut = 1; // 编辑角色按钮  1为读写  2为只读  3为不可见
            this.buttonAuth.deleteRoleBut = 1; // 删除角色按钮  1为读写  2为只读  3为不可见
            this.buttonAuth.editRoleBut = 1; // 编辑角色权限按钮
            this.buttonAuth.allocationBut = 1; // 给角色分配人员
            this.getDepartmentTreeData();
            return;
          }
          if (!data.isShow) {
            this.$message.error(
              "您没有权限查看该信息,请联系管理员或机构负责人!"
            );
            return;
          }
          this.buttonAuth.updateCompanyBut = this.$systemBaseData.judgeButtonAuthority(
            "编辑公司信息",
            data.buttonAuth
          );
          this.buttonAuth.createDepartmentBut = this.$systemBaseData.judgeButtonAuthority(
            "新增部门",
            data.buttonAuth
          );
          this.buttonAuth.updateDepartmentBut = this.$systemBaseData.judgeButtonAuthority(
            "编辑部门",
            data.buttonAuth
          );
          this.buttonAuth.deleteDepartmentBut = this.$systemBaseData.judgeButtonAuthority(
            "删除部门",
            data.buttonAuth
          );
          this.buttonAuth.showRoleBut = this.$systemBaseData.judgeButtonAuthority(
            "查看部门角色",
            data.buttonAuth
          );
          this.buttonAuth.createRoleBut = this.$systemBaseData.judgeButtonAuthority(
            "新增角色",
            data.buttonAuth
          );
          this.buttonAuth.updateRoleBut = this.$systemBaseData.judgeButtonAuthority(
            "编辑角色",
            data.buttonAuth
          );
          this.buttonAuth.deleteRoleBut = this.$systemBaseData.judgeButtonAuthority(
            "删除角色",
            data.buttonAuth
          );
          this.buttonAuth.editRoleBut = this.$systemBaseData.judgeButtonAuthority(
            "编辑角色权限",
            data.buttonAuth
          );
          this.buttonAuth.allocationBut = this.$systemBaseData.judgeButtonAuthority(
            "角色分配",
            data.buttonAuth
          );
          this.getDepartmentTreeData();
        }
      );
    },
    /**
     * 获取部门树数据
     */
    getDepartmentTreeData() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/setting/getDepartmentTree",
        requestData,
        (data) => {
          this.Loading();
          this.departmentTreeData = [data];
          this.showDepartmentTree(this.departmentTreeData);
          if (
            this.authorityTreeData.length > 0 &&
            this.authorityTreeData[0].id == this.tempNode.id
          ) {
            console.log("清空角色树");
            this.getAuthorityTreeData(this.tempDepartment.id);
          }
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取角色树
     */
    getAuthorityTreeData(departmentId) {
      let requestData = {
        userId: this.baseInfo.userId,
        departmentId: departmentId,
      };
      this.$request(
        "post",
        "/setting/getAuthorityTree",
        requestData,
        (data) => {
          this.Loading();
          this.authorityTreeData = [data];
          this.showAuthorityTree(this.authorityTreeData);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 显示左边部门树
     */
    showDepartmentTree(treeData) {
      this.left_tree = this.$echarts.init(this.$refs.left_tree);
      let options = systemAuthorityData.getTreeOptions(treeData);
      this.left_tree.clear();
      this.left_tree.setOption(options, true);
      let myChartContainer = document.getElementById("left_tree");
      let _this = this;
      this.contextMenuTarget = myChartContainer;
      this.left_tree.on("contextmenu", function (params) {
        _this.tipsData.tipsList = _this.tipsData.leftTipsList;
        event.preventDefault();
        _this.$refs.tips.style.top =
          event.offsetY > 520
            ? event.offsetY - 150 + "px"
            : event.offsetY + "px";
        _this.$refs.tips.style.left = event.offsetX + 16 + "px";
        _this.tipsData.tipsflag = true;
        console.log("右击节点", params);
        _this.tempNode = params.data;
        _this.tempDepartment = params.data;
        if (params.dataIndex == 1) {
          _this.headNode = true;
          _this.tipsData.tipsList = [
            {
              text: "新增部门",
              flag: _this.buttonAuth.createDepartmentBut,
              active: "addDepartment",
            },
            {
              text: "编辑公司信息",
              flag: _this.buttonAuth.updateCompanyBut,
              active: "updateCompany",
            },
            {
              text: "取消",
              flag: 1,
              active: "cancel",
            },
          ];
        } else {
          _this.headNode = false;
          _this.tipsData.tipsList = [
            {
              text: "新增部门",
              flag: _this.buttonAuth.createDepartmentBut,
              active: "addDepartment",
            },
            {
              text: "编辑部门",
              flag: _this.buttonAuth.updateDepartmentBut,
              active: "updateDepartment",
            },
            {
              text: "删除部门",
              flag: _this.buttonAuth.deleteDepartmentBut,
              active: "deleteDepartment",
            },
            {
              text: "查看部门角色",
              flag: _this.buttonAuth.showRoleBut,
              active: "showAuthority",
            },
            {
              text: "取消",
              flag: 1,
              active: "cancel",
            },
          ];
        }
      });
    },
    /**
     * 显示右边角色树
     */
    showAuthorityTree(treeData) {
      this.right_tree = this.$echarts.init(this.$refs.right_tree);
      let options = systemAuthorityData.getTreeOptions(treeData);
      this.right_tree.clear();
      this.right_tree.setOption(options, true);
      let myChartContainer = document.getElementById("right_tree");
      let _this = this;
      this.contextMenuTarget = myChartContainer;
      this.right_tree.on("contextmenu", function (params) {
        _this.tipsData.tipsList = _this.tipsData.rightTipsList;
        event.preventDefault();
        _this.$refs.tips.style.top =
          event.offsetY > 520
            ? event.offsetY - 180 + "px"
            : event.offsetY + "px";
        _this.$refs.tips.style.left =
          event.offsetX + 876 > 1575
            ? event.offsetX + 776 + "px"
            : event.offsetX + 876 + "px";
        _this.tipsData.tipsflag = true;
        console.log("右击节点", event.offsetY);
        _this.tempNode = params.data;
        if (params.dataIndex == 1) {
          _this.headNode = true;
          _this.tipsData.tipsList = [
            {
              text: "新增角色",
              flag: _this.buttonAuth.createRoleBut,
              active: "addAuthority",
            },
            {
              text: "编辑部门",
              flag: _this.buttonAuth.updateDepartmentBut,
              active: "updateDepartment",
            },
            {
              text: "取消",
              flag: 1,
              active: "cancel",
            },
          ];
        } else {
          _this.headNode = false;
          _this.tipsData.tipsList = [
            {
              text: "新增角色",
              flag: _this.buttonAuth.createRoleBut,
              active: "addAuthority",
            },
            {
              text: "编辑角色",
              flag: _this.buttonAuth.updateRoleBut,
              active: "updateAuthority",
            },
            {
              text: "删除角色",
              flag: _this.buttonAuth.deleteRoleBut,
              active: "deleteAuthority",
            },
            {
              text: "编辑角色权限",
              flag: _this.buttonAuth.editRoleBut,
              active: "editAuthority",
            },
            {
              text: "角色分配",
              flag: _this.buttonAuth.allocationBut,
              active: "roleAssignment",
            },
            {
              text: "取消",
              flag: 1,
              active: "cancel",
            },
          ];
        }
      });
    },
    /**
     * 右击时菜单的操作事件
     */
    tipsOper(data) {
      this.tipsData.tipsflag = false;
      console.log("菜单的单击事件", data, this.buttonAuth);
      switch (data.active) {
        case "addDepartment":
          // 新增部门
          if (this.buttonAuth.createDepartmentBut == 1) {
            this.dialogData.width = "30%";
            this.dialogData.title = "新增部门";
            this.dialogData.buttonArray = [
              {
                text: "确认",
                active: "add_department",
              },
              {
                text: "取消",
                active: "cancel",
              },
            ];
            this.dialogData.data = [
              { title: "部门名称", type: "input", value: "" },
            ];
            this.dialogData.flag = true;
          } else {
            this.$message.error(" 您没有权限操作,请联系管理员或机构负责人!");
          }
          break;
        case "updateDepartment":
          // 编辑部门
          if (this.buttonAuth.updateDepartmentBut == 1) {
            this.dialogData.width = "30%";
            this.dialogData.title = "部门信息编辑";
            this.dialogData.buttonArray = [
              {
                text: "确认",
                active: "update_department",
              },
              {
                text: "取消",
                active: "cancel",
              },
            ];
            this.dialogData.data = [
              { title: "部门名称", type: "input", value: this.tempNode.name },
            ];
            this.dialogData.flag = true;
          } else {
            this.$message.error(" 您没有权限操作,请联系管理员或机构负责人!");
          }

          break;
        case "updateCompany":
          // 编辑公司信息
          if (this.buttonAuth.updateCompanyBut == 1) {
            this.dialogData.width = "30%";
            this.dialogData.title = "公司信息编辑";
            this.dialogData.buttonArray = [
              {
                text: "确认",
                active: "update_company",
              },
              {
                text: "取消",
                active: "cancel",
              },
            ];
            this.dialogData.data = [
              { title: "公司名称", type: "input", value: this.tempNode.name },
            ];
            this.dialogData.flag = true;
          } else {
            this.$message.error(" 您没有权限操作,请联系管理员或机构负责人!");
          }

          break;
        case "deleteDepartment":
          // 删除部门
          if (this.buttonAuth.deleteDepartmentBut == 1) {
            this.$confirm(
              "此操作将永久删除该部门及下级所有部门,以及相应部门所有角色, 是否继续?",
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {
                console.log("点击确定删除按钮");
                let requestData = {
                  departmentId: this.tempNode.id,
                };
                this.$request(
                  "post",
                  "/setting/deleteDepartment",
                  requestData,
                  (data) => {
                    console.log("右边角色树", this.authorityTreeData);
                    //  this.authorityTreeData[0].id == this.tempNode.id
                    if (
                      this.authorityTreeData.length > 0 &&
                      systemAuthorityData.findNodeById(
                        this.authorityTreeData[0].id,
                        this.tempNode
                      )
                    ) {
                      console.log("清空角色树");
                      this.authorityTreeData = [];
                      this.showAuthorityTree(this.authorityTreeData);
                    }
                    this.$message({
                      type: "success",
                      message: "部门删除成功!",
                      duration: 1000,
                    });
                    this.getDepartmentTreeData();
                  },
                  (errorMessage) => {
                    this.$message.error(errorMessage);
                    this.dialogData.flag = false;
                  }
                );
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消删除",
                });
              });
          } else {
            this.$message.error(" 您没有权限操作,请联系管理员或机构负责人!");
          }
          break;
        case "showAuthority":
          // 查看部门角色
          if (this.buttonAuth.showRoleBut == 1) {
            this.getAuthorityTreeData(this.tempDepartment.id);
          } else {
            this.$message.error(" 您没有权限操作,请联系管理员或机构负责人!");
          }
          break;
        case "addAuthority":
          // 新增角色
          if (this.buttonAuth.createRoleBut == 1) {
            this.dialogData.width = "30%";
            this.dialogData.title = "新增角色";
            this.dialogData.buttonArray = [
              {
                text: "确认",
                active: "add_authority",
              },
              {
                text: "取消",
                active: "cancel",
              },
            ];
            this.dialogData.data = [
              { title: "角色名称", type: "input", value: "" },
            ];
            this.dialogData.flag = true;
          } else {
            this.$message.error(" 您没有权限操作,请联系管理员或机构负责人!");
          }

          break;
        case "updateAuthority":
          // 编辑角色
          if (this.buttonAuth.updateRoleBut == 1) {
            this.dialogData.width = "30%";
            this.dialogData.title = "角色信息修改";
            this.dialogData.buttonArray = [
              {
                text: "确认",
                active: "update_authority",
              },
              {
                text: "取消",
                active: "cancel",
              },
            ];
            this.dialogData.data = [
              { title: "角色名称", type: "input", value: this.tempNode.name },
            ];
            this.dialogData.flag = true;
          } else {
            this.$message.error(" 您没有权限操作,请联系管理员或机构负责人!");
          }

          break;
        case "deleteAuthority":
          // 删除部门
          if (this.buttonAuth.deleteRoleBut == 1) {
            this.$confirm(
              "此操作将永久删除该角色及下级所有角色, 是否继续?",
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {
                console.log("点击确定删除按钮");
                let requestData = {
                  authorityId: this.tempNode.id,
                };
                this.$request(
                  "post",
                  "/setting/deleteAuthority",
                  requestData,
                  (data) => {
                    this.$message({
                      type: "success",
                      message: "角色删除成功!",
                      duration: 1000,
                    });
                    this.getAuthorityTreeData(this.tempDepartment.id);
                  },
                  (errorMessage) => {
                    this.$message.error(errorMessage);
                    this.dialogData.flag = false;
                  }
                );
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消删除",
                });
              });
          } else {
            this.$message.error(" 您没有权限操作,请联系管理员或机构负责人!");
          }
          break;
        case "editAuthority":
          // 编辑角色权限
          if (this.buttonAuth.editRoleBut == 1) {
            this.dialogData.width = "30%";
            this.dialogData.title = "角色权限编辑";
            this.dialogData.buttonArray = [
              {
                text: "确认",
                active: "edit_authority_choseModel",
              },
              {
                text: "取消",
                active: "cancel",
              },
            ];
            this.dialogData.data = [
              {
                title: "模块",
                type: "select",
                value: "",
                options: this.$systemBaseData.modelListToModelOptions(),
              },
            ];
            this.dialogData.flag = true;
          } else {
            this.$message.error(" 您没有权限操作,请联系管理员或机构负责人!");
          }

          break;
        case "roleAssignment":
          // 角色分配
          if (this.buttonAuth.allocationBut == 1) {
            let requestData = {
              authorityId: this.tempNode.id,
            };
            this.$request(
              "post",
              "/setting/getUserListByAuthorityId",
              requestData,
              (authorityUserList) => {
                // 获取当前角色下的员工列表
                let redata = {
                  userId: this.baseInfo.userId,
                  companyId: this.baseInfo.company.companyId,
                  isDiMission: false,
                  search: "",
                };
                this.$request(
                  "post",
                  "/user/isCharge",
                  redata,
                  (allUserList) => {
                    // 获取当前公司所有员工列表
                    this.dialogData.width = "30%";
                    this.dialogData.title = "角色分配";
                    this.dialogData.buttonArray = [
                      {
                        text: "确认",
                        active: "role_assignment",
                      },
                      {
                        text: "取消",
                        active: "cancel",
                      },
                    ];
                    let userList = [];
                    allUserList.forEach((element) => {
                      if (!element.isAdmin) {
                        userList.push(element);
                      }
                    });
                    let authorityTipsData = {
                      value: "",
                      userList: userList,
                      userAuthprityList: authorityUserList,
                    };
                    this.dialogData.data = [
                      { type: "authority_tips", data: authorityTipsData },
                    ];
                    this.tempAuthorityUserList = JSON.parse(
                      JSON.stringify(authorityUserList)
                    );
                    this.dialogData.flag = true;
                  },
                  (errorMessage) => {
                    this.$message.error(errorMessage);
                  }
                );
              },
              (errorMessage) => {
                this.$message.error(errorMessage);
              }
            );
          } else {
            this.$message.error(" 您没有权限操作,请联系管理员或机构负责人!");
          }
          break;
        case "cancel":
          // 取消

          break;

        default:
          break;
      }
    },
    /**
     * dialog中的按钮操作事件
     */
    drawerOper(active) {
      let requestData = {};
      let dd = {};
      let node = {};
      let showData = {};
      switch (active) {
        case "add_department":
          // 确认添加部门
          requestData = {
            fatherId: this.tempNode.id, // 上级部门的ID
            departmentName: this.dialogData.data[0].value, // 要添加的部门名称
            companyId: this.baseInfo.company.companyId, // 当前系统所选公司ID
          };
          this.$request(
            "post",
            "/setting/addDepartment",
            requestData,
            (data) => {
              this.$message({
                type: "success",
                message: "部门添加成功!",
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.getDepartmentTreeData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
              this.dialogData.flag = false;
            }
          );
          break;
        case "update_department":
          // 确认编辑部门信息
          requestData = {
            departmentId: this.tempNode.id,
            departmentName: this.dialogData.data[0].value, // 要添加的部门名称
          };
          this.$request(
            "post",
            "/setting/updateDepartment",
            requestData,
            (data) => {
              this.$message({
                type: "success",
                message: "部门信息修改成功!",
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.getDepartmentTreeData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
              this.dialogData.flag = false;
            }
          );
          break;
        case "update_company":
          // 确认编辑公司信息
          requestData = {
            companyId: this.tempNode.id,
            companyName: this.dialogData.data[0].value, // 要修改的公司名称
          };
          this.$request(
            "post",
            "/setting/updateCompany",
            requestData,
            (data) => {
              this.$message({
                type: "success",
                message: "公司信息修改成功!",
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.getDepartmentTreeData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
              this.dialogData.flag = false;
            }
          );
          break;
        case "add_authority":
          // 确认添加角色
          requestData = {
            fatherId: this.tempNode.id, // 上级角色的ID或者是部门ID
            authorityName: this.dialogData.data[0].value, // 要添加的角色名称
            companyId: this.baseInfo.company.companyId, // 当前系统所选公司ID
            departmentId: this.tempDepartment.id, //部门id
            authority: this.$authority.allAuthorityConfiguration(),
          };
          this.$request(
            "post",
            "/setting/addAuthority",
            requestData,
            (data) => {
              this.$message({
                type: "success",
                message: "角色添加成功!",
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.getAuthorityTreeData(this.tempDepartment.id);
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
              this.dialogData.flag = false;
            }
          );

          break;
        case "update_authority":
          // 确认编辑角色信息
          dd = {
            id: this.tempNode.id,
            name: this.dialogData.data[0].value,
          };
          requestData = {
            authorityId: this.tempNode.id, // 上级角色的ID或者是部门ID
            authorityName: this.dialogData.data[0].value, // 要添加的角色名称
          };
          this.$request(
            "post",
            "/setting/updateAuthority",
            requestData,
            (data) => {
              this.$message({
                type: "success",
                message: "角色修改成功!",
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.getAuthorityTreeData(this.tempDepartment.id);
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
              this.dialogData.flag = false;
            }
          );
          break;
        case "edit_authority_choseModel":
          // 角色编辑前模块选择
          this.dialogData.title = "修改角色权限";
          this.dialogData.width = "720px";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "edit_authority",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          showData = {};
          let authorityDialogData = {
            model: "",
            modelName: "", // 模块
            modelShow: 0, // 当前模块的可见性
            modelMenuAuthor: [], // 当前模块的子菜单的可见性
            authorData: [], // 数据的权限
          };
          this.$authority.getModelAuthority(
            [""],
            "topMenu",
            this,
            false,
            (topMenu) => {
              switch (this.dialogData.data[0].value) {
                case "thread":
                  // 选择商机模块
                  authorityDialogData.model = "thread";
                  authorityDialogData.modelName = "商机";
                  authorityDialogData.modelShow = this.$systemBaseData.judgeButtonAuthority(
                    "商机",
                    topMenu
                  );
                  this.$authority.getModelAuthority(
                    ["thread"],
                    "modelChileMenu",
                    this,
                    false,
                    (modelChileMenu) => {
                      // 左侧子菜单
                      let dd = {
                        menu: "",
                        model: "",
                        isShow: "",
                      };
                    }
                  );
                  break;
                case "contract":
                  // 选择合同模块
                  authorityDialogData.flag = this.$systemBaseData.judgeButtonAuthority(
                    "合同",
                    topMenu
                  );
                  break;
                case "client":
                  // 选择客户模块
                  authorityDialogData.flag = this.$systemBaseData.judgeButtonAuthority(
                    "合同",
                    topMenu
                  );

                  break;
                case "invoice":
                  // 选择发票模块
                  authorityDialogData.flag = this.$systemBaseData.judgeButtonAuthority(
                    "合同",
                    topMenu
                  );

                  break;
                case "system":
                  // 选择系统模块

                  break;
                default:
                  break;
              }
            }
          );
          break;
        case "edit_authority":
          // 确认编辑角色权限
          this.dialogData.data[0].data.authorData.forEach((element) => {
            element.buttonAuth = systemAuthorityData.formatValueData(
              element.buttonAuth
            );
            element.data = systemAuthorityData.formatValueData(element.data);
          });
          console.log("确认编辑角色权限", this.dialogData.data[0].data);
          requestData = {
            userId: this.baseInfo.userId,
            authorityId: this.tempNode.id,
            model: this.dialogData.model, // 当前所选的模块
            list: this.dialogData.data[0].data,
            authorityDataOld: {},
          };
          this.$request(
            "post",
            "/setting/saveAuthorityList",
            requestData,
            (data) => {
              this.$message({
                type: "success",
                message: "角色权限设置成功!",
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.getAuthorityTreeData(this.tempDepartment.id);
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "role_assignment":
          // 确认角色分配
          console.log("当前数据:", this.dialogData);
          let originalEmployeeList = [];
          this.tempAuthorityUserList.forEach((element) => {
            originalEmployeeList.push(element.userId);
          });
          let employeeList = [];
          this.dialogData.data[0].data.userAuthprityList.forEach((element) => {
            employeeList.push(element.userId);
          });
          requestData = {
            authorityId: this.tempNode.id,
            originalEmployeeList: originalEmployeeList,
            employeeList: employeeList,
          };
          this.$request(
            "post",
            "/setting/roleAssignment",
            requestData,
            (data) => {
              // 获取当前公司所有员工列表
              this.$message({
                type: "success",
                message: "角色分配成功!",
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.getAuthorityTreeData(this.tempDepartment.id);
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "cancel":
          this.dialogData.flag = false;
          break;

        default:
          break;
      }
    },
    /**
     * dialog父子组件连锁事件
     */
    authorityShow(data) {
      if (this.dialogData.data.length > 0) {
        for (let index = 0; index < this.dialogData.data.length; index++) {
          let element = this.dialogData.data[index];
          if (
            element.type == "authority_dialog" ||
            element.type == "authority_tips"
          ) {
            element.data = data;
          }
        }
      }
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 页面加载动画
     */
    Loading() {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
  },
  mounted() {
    this.loading = true;
    console.log("路由传值", this.$route.query.isDisable);
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getDepartmentTreeData();
    // this.judgeAuthority();
  },
};
</script>

<style lang="less" scoped>
#system-authority {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  .system-authority-content {
    max-width: 90vw;
    width: 98%;
    height: 97%;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
    display: flex;
    .content-tree {
      flex: 1;
      width: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      // overflow: hidden;
      &:nth-child(1) {
        // background-color: antiquewhite;
        border-right: 3px solid @contentBackground;
      }
      &:nth-child(2) {
        // background-color: rgb(161, 206, 0);
      }
      .tree-title {
        width: 100%;
        display: flex;
        align-items: center;
        height: 50px;
        background-color: #fff;
        font-size: @contentFontSize3;
        padding-left: 20px;
        box-sizing: border-box;
        border-bottom: 1px solid @activeBackground2;
        span {
          height: 50%;
          display: flex;
          align-items: flex-end;
          color: @tipColor;
          font-size: @contentFontSize;
          margin-left: 10px;
        }
      }
      .div-tree {
        flex: 1;
        height: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        // overflow: auto;
        // background-color: aquamarine;
        // &::-webkit-scrollbar {
        //   width: 1px;
        // }
        #left_tree,
        #right_tree {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .tips {
    width: 150px;
    height: auto;
    display: flex;
    flex-direction: column;
    position: absolute;
    float: left;
    left: 50px;
    top: 50px;
    z-index: 99;
    border: solid 20px;
    border-image: url("../assets/img/tips.png") 20 20 20 20;
    // background-color: #fff;
    div {
      flex: 1;
      width: 100%;
      min-height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: @activeColor;
      margin-bottom: 1px;
      border-bottom: 1px solid @boderColor;
      background-color: #fff;
      // background-color: #38dfeb6e;
    }
    div:nth-last-child(1) {
      border: none;
    }
    div:hover {
      // background-color: #38dfebb6;
      cursor: pointer;
      color: @activeColor2;
    }
  }
}
</style>