<template>
  <div id="human-note">
    <!-- 记单合同 -->
    <div class="contract-screen">
      <div class="contract-screen-search">
        <el-input
          placeholder="客户全称/编号/合同编号"
          v-model="searchData.input"
          @keyup.enter.native="resetPage"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click.native.stop="resetPage"
          ></el-button>
        </el-input>
      </div>
      <div class="contract-screen-search">
        <el-select
          v-model="searchData.userId"
          placeholder="请选择签单人"
          @change="seleteInitTableData"
          filterable
        >
          <el-option
            v-for="item in searchData.userList"
            :key="item.userId"
            :label="item.userName"
            :value="item.userId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="contract-screen-search">
        <el-cascader
          v-model="searchData.department"
          placeholder="请选择部门"
          :options="searchData.departTreeData"
          @change="resetPage"
          clearable
          :show-all-levels="false"
          :props="{ checkStrictly: true, value: 'id' }"
          filterable
        ></el-cascader>
      </div>
      <div class="contract-screen-search">
        <el-date-picker
          v-model="searchData.month"
          type="month"
          value-format="yyyy-MM"
          placeholder="选择月"
          :clearable="false"
          @change="resetPage"
        >
        </el-date-picker>
      </div>
      <div class="contract-screen-search">
        <el-cascader
          v-model="searchData.productId"
          :options="searchData.productList"
          placeholder="请选择服务项目"
          @change="resetPage"
          :props="{ checkStrictly: true, label: 'name', value: 'id' }"
          clearable
          filterable
        ></el-cascader>
      </div>
      <div class="contract-screen-checked"></div>
      <div class="contract-screen-active"></div>
      <!-- <div
        class="contract-screen-but-boder"
        v-showFunction="{ active: 'export', array: allFunctionButtons }"
        @click="handleClick({}, 1)"
      >
        导出
      </div> -->
    </div>

    <div class="contract-table">
      <div class="contract-table-card">
        <div class="contract-table-card-tabs">
          <div
            :class="{
              'contract-table-card-tab': true,
              'is-active': type == '新签',
            }"
            @click="changeTab('新签')"
          >
            新签记单合同
          </div>
          <div
            :class="{
              'contract-table-card-tab': true,
              'is-active': type == '续签',
            }"
            @click="changeTab('续签')"
          >
            续签记单合同
          </div>
          <div
            :class="{
              'contract-table-card-tab': true,
              'is-active': type == '',
            }"
            @click="changeTab('')"
          >
            全部记单合同
          </div>
          <div
            :class="{
              'contract-table-card-tab': true,
              'is-active': type == '统计',
            }"
            @click="changeTab('统计')"
          >
            记单统计
          </div>
          <div class="contract-table-card-active">
            <div class="textArea">总计单:</div>
            <div class="numArea">{{ tempAllNote }}</div>
          </div>
        </div>
        <div class="contract-table-card-content" v-if="type !== '统计'">
          <el-table
            ref="singleTable"
            :key="table"
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            height="98%"
            :row-style="rowStyle"
            v-loading="loading"
            show-summary
            :summary-method="getSummaries"
          >
            <el-table-column type="index" width="60" fixed> </el-table-column>
            <el-table-column
              prop="userName"
              label="签单人"
              width="80"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="clientName"
              label="客户名称"
              width="220"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="serviceName"
              label="服务项目"
            ></el-table-column>
            <el-table-column prop="money" label="合同金额"></el-table-column>
            <el-table-column prop="profits" label="利润">
              <template slot-scope="scope">
                <span style="color: #67C23A;">{{ scope.row.profits }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="refund" label="回款金额"></el-table-column>
            <el-table-column prop="note" label="记单"></el-table-column>
            <el-table-column
              prop="time"
              label="签约时间"
              width="160"
            ></el-table-column>
            <el-table-column label="操作" width="120" fixed="right">
              <template slot-scope="scope">
                <el-button
                  @click="handleClick(scope.row, 3)"
                  type="text"
                  v-showFunction="{ active: 'edit', array: allFunctionButtons }"
                  size="small"
                  >记单修改</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="contract-table-card-content" v-else>
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            height="98%"
            :row-style="rowStyle"
            v-loading="loading"
            show-summary
            :summary-method="getSummaries"
          >
            <el-table-column type="index" width="60" fixed> </el-table-column>
            <el-table-column prop="userName" label="签单人"></el-table-column>
            <el-table-column prop="money" label="合同金额"></el-table-column>
            <el-table-column prop="refund" label="回款金额"></el-table-column>
            <el-table-column prop="newlySignedProfits" label="新签利润">
              <template slot-scope="scope">
                <span style="color: #67C23A;">{{
                  scope.row.newlySignedProfits
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="renewalProfits" label="续签利润">
              <template slot-scope="scope">
                <span style="color: #67C23A;">{{
                  scope.row.renewalProfits
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="note" label="记单"></el-table-column>
            <el-table-column prop="noteOfEntry" label="备注">
              <template slot-scope="scope">
                <span
                  style="color: #409EFF; cursor: pointer;"
                  @click="handleClick(scope.row, 2)"
                  >{{ scope.row.noteOfEntry || "点击编辑" }}</span
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="contract-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import my_drawer from "@/components/my-drawer.vue";
import my_dialog from "@/components/dialog.vue";
import * as contractDrawData from "@/assets/js/contractDraw.js";
import * as clientInfoData from "@/assets/js/clientInfoData.js";
import * as allContractData from "@/views/contract/js/contractData.js";
export default {
  components: {
    my_drawer,
    my_dialog,
  },
  data() {
    return {
      table: true,
      baseInfo: {},
      rowStyle: this.$rowStyle,
      loading: true,
      whetherAgencyHead: false,
      searchData: {
        input: "",
        checked: false,
        screenButton_edit: 1, // 编辑
        screenButton_export: 1, // 导出
        userId: "", //
        userList: [],
        tempUserId: "",
        departTreeData: [],
        department: "",
        month: this.$formatTime(new Date(), "YYYY-MM"),
        productList: [],
        productId: [],
      },
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      tempAllEmpolyeeList: [],
      tableData: [],
      tempAllNote: "0", // 总记单

      tempData: {},

      dialogData: {
        flag: false,
        width: "30%",
        title: "新增员工",
        buttonArray: [
          {
            text: "确认",
            active: "add_employee",
          },
          {
            text: "取消",
            active: "cancel",
          },
        ],
        data: [],
      },
      type: "新签",
      buttonAuth: [],
      productList: [], // 所有合同模板/产品数据
      professionList: [], // 所有合同执行模板
      serviceItemList: [], // 所有服务项数据
      serviceTypeList: [], // 所有服务类别数据
      employeeList: [], // 所有员工
      departmentList: [], // 所有员工
      clientList: [], // 所有客户
      accountList: [], // 收款账号
      tipsData: {
        clientTipsList: [], // 客户标签
        pianquList: [], // 片区标签
        levelList: [], // 客户等级
        sourceList: [], // 客户来源
      },
      allFunctionButtons: this.$featureData.getAllFunctionButtons("humanNote"),
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "humanNote",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getEmployeeListByUserId();
        // this.initTableData();
        //  获取各种标签数据
        contractDrawData.getTagList(
          this.tipsData,
          ["客户标签", "片区", "客户来源", "客户等级"],
          this.baseInfo.company.companyId,
          this
        );
        this.getGlobalVariable();
      });
    },
    getGlobalVariable() {
      let employeeList = JSON.parse(
        JSON.stringify(this.$store.getters.getAllEmployeeList)
      );
      this.employeeList = [];
      employeeList.forEach((element) => {
        let dd = {
          label: element.userName,
          value: element.userId,
        };
        this.employeeList.push(dd);
      });

      let productList = JSON.parse(
        JSON.stringify(this.$store.getters.getAllProductList)
      );
      this.productList = productList;

      let departmentList = JSON.parse(
        JSON.stringify(this.$store.getters.getAllDepartmentList)
      );
      this.departmentList = [];
      departmentList.forEach((element) => {
        let dd = {
          label: element.departmentName,
          value: element.departmentId,
        };
        this.departmentList.push(dd);
      });

      let serviceTypeList = JSON.parse(
        JSON.stringify(this.$store.getters.getAllServiceTypeList)
      );
      this.serviceTypeList = serviceTypeList;

      let serviceItemList = JSON.parse(
        JSON.stringify(this.$store.getters.getAllServicesItemList)
      );
      this.serviceItemList = serviceItemList;

      let accountList = JSON.parse(
        JSON.stringify(this.$store.getters.getCompantAccountList)
      );
      this.accountList = accountList;

      let clientList = JSON.parse(
        JSON.stringify(this.$store.getters.getAllClientByUserId)
      );
      this.clientList = [];
      clientList.forEach((element) => {
        let dd = {
          id: element.clientId,
          clientName: element.clientName,
        };
        this.clientList.push(dd);
      });

      let professionList = JSON.parse(
        JSON.stringify(this.$store.getters.getAllProfessionList)
      );
      this.professionList = professionList;
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeListByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.searchData.userList = data;
          this.searchData.userId = "all";
          this.tempAllEmpolyeeList = [];
          data.forEach((element) => {
            this.tempAllEmpolyeeList.push(element.userId);
          });
          this.searchData.userList.splice(0, 0, {
            userId: "all",
            userName: "全部",
          });
          this.seleteInitTableData("all");
          this.getDepartmentTreeByUserId();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        // "/setting/getDepartmentTreeByUserId",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
          this.getAllProduct();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有产品
     */
    getAllProduct() {
      this.searchData.productList = [];
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/servicesItem/getServeTypeAndItem",
        requestData,
        (data) => {
          this.searchData.productList = data;
        }
      );
    },
    initTableData() {
      this.tableData = [];
      let url = "";
      this.loading = true;
      if (this.type == "统计") {
        url = "/contract/getAllContractNoteStatistics";
      } else {
        url = "/contract/getAllContractNote";
      }
      let requestData = {
        userId: this.searchData.tempUserId,
        companyId: this.baseInfo.company.companyId, // 当前公司ID
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
        search: this.searchData.input, // 客户全称/编号/合同编号筛选客户
        department:
          this.searchData.department.length > 0
            ? this.searchData.department[this.searchData.department.length - 1]
            : "",
        time: this.searchData.month,
        renewalStatusForReport: this.type, // 新签 续签 ''
        productId:
          this.searchData.productId.length > 0
            ? this.searchData.productId[this.searchData.productId.length - 1]
            : "", // 产品
      };
      this.$request(
        "post",
        url,
        requestData,
        (data) => {
          this.pageData.total = data.total; // 获取总条目数
          this.tempAllNote = data.allNote; // 总计单
          this.tableData = data.tableData;
          this.loading = false;
          this.$forceUpdate();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    changeTab(type) {
      this.type = type;
      this.resetPage();
    },
    seleteInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.tempAllEmpolyeeList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.resetPage();
    },
    /**
     * 记单修改
     */
    handleClick(data, flag) {
      this.tempData = data;
      console.log("记单修改", this.tempData.note);
      switch (flag) {
        case 2:
          // 记单修改
          this.dialogData.width = "30%";
          this.dialogData.title = "备注修改";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "makesure_edit",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            { title: "备注", type: "textarea", value: data.noteOfEntry },
          ];
          this.dialogData.flag = true;
          break;
        case 3:
          // 记单修改
          this.dialogData.width = "30%";
          this.dialogData.title = "员工记单修改";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "makesure_results_point_to_modify",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            { title: "记单调整", type: "input", value: this.tempData.note },
          ];
          this.dialogData.flag = true;
          break;
        default:
          break;
      }
    },

    drawerOper(active) {
      let role = [];
      let company = [];
      let new_company = [];
      let requestData = {};
      switch (active) {
        case "makesure_results_point_to_modify":
          requestData = {
            userId: this.tempData.userId,
            contractId: this.tempData.id,
            note: this.dialogData.data[0].value,
          };
          if (requestData.note == "") {
            this.$message.error("记单金额必填!");
            return;
          }
          if (!this.$formatMathDecimal(requestData.note)) {
            this.$message.error("记单金额格式不对,只能是数值!");
            return;
          }

          this.$request(
            "post",
            "/contract/updateAllContractNote",
            requestData,
            (data) => {
              this.$message({
                type: "success",
                message: "记单修改成功!",
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.$operationRecords.saveOperationRecords(
                this.tempData.id,
                "修改合同记单!",
                this.baseInfo,
                "contract",
                this,
                (data2) => {
                  this.initTableData();
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
              this.dialogData.flag = false;
            }
          );
          break;
        case "makesure_edit":
          requestData = {
            id: this.tempData.single,
            noteOfEntry: this.dialogData.data[0].value,
          };
          this.$request(
            "post",
            "/user/update",
            requestData,
            (data) => {
              this.$message.success("编辑成功!");
              this.initTableData();
              this.dialogData.flag = false;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "cancel":
          this.dialogData.flag = false;
          break;
        default:
          break;
      }
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    colseDialog() {
      this.dialogData.flag = false;
    },
    getSummaries(param) {
      const { columns, data } = param;

      const sums = [];
      columns.forEach((column, index) => {
        if (index == 0) {
          sums[index] = "合计";
          return;
        }
        if (this.type !== "统计") {
          if (
            index == 0 ||
            index == 1 ||
            index == 2 ||
            index == 3 ||
            index == 8 ||
            index == 9
          ) {
            sums[index] = "";
            return;
          }
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            let sum = Number(Number(prev) + Number(curr)).toFixed(2); //toFixed(2)数据项保留两位小数
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = Math.round(sums[index] * 100) / 100;
        } else {
          sums[index] = "N/A";
        }
      });

      return sums;
    },
    resetPage() {
      this.pageData.currentPage = 1;
      this.initTableData();
    },
  },
  mounted() {
    this.loading = true;
    this.isCharge();
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.$store.dispatch("getCompantAccountList", this);
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#human-note {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .contract-screen {
    width: 100%;
    height: 45px;
    border-bottom: 1px solid @boderColor;
    display: flex;
    align-items: center;
    .contract-screen-fiftrate {
      width: 65px;
      min-width: 65px;
      height: 30px;
      border: 1px solid @boderColor;
      box-sizing: border-box;
      color: @boderActiveColor;
      font-size: @contentFontSize;
      margin: 0 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      transition: 0.3s;
      i {
        margin: 0 4px;
      }
    }
    .contract-screen-fiftrate:hover {
      cursor: pointer;
      border-color: @boderActiveColor;
    }
    .contract-screen-search {
      width: 210px;
      min-width: 210px;
      height: 30px;
      margin-left: 10px;
      display: flex;
    }
    .contract-screen-checked {
      width: 140px;
      height: 30px;
      min-width: 140px;
      font-size: @contentFontSize;
      margin: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .contract-screen-active {
      flex: 1;
    }
    .contract-screen-but-select {
      width: 140px;
      min-width: 160px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: @activeColor;
      font-size: @contentFontSize;
      color: #fff;
      margin: 0 5px;
      border-radius: 4px;
      transition: 0.5s;
      cursor: pointer;
      .contract-screen-but-select_but {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.5s;
      }
      .contract-screen-but-select_but:hover {
        background-color: @activeColor2;
      }
      .contract-screen-but-select_select {
        width: 30px;
        height: 100%;
        border-left: 1px solid @activeColor3;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .contract-screen-but {
      width: 100px;
      min-width: 100px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: @activeColor;
      font-size: @contentFontSize;
      color: #fff;
      margin: 0 5px;
      border-radius: 4px;
      transition: 0.5s;
    }
    .contract-screen-but:hover {
      cursor: pointer;
      background-color: @activeColor2;
    }
    .contract-screen-but-boder {
      width: 50px;
      min-width: 50px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid @activeColor;
      font-size: @contentFontSize;
      background-color: @activeBackground;
      color: @activeColor;
      margin: 0 5px;
      margin-right: 10px;
      border-radius: 4px;
      transition: 0.5s;
    }
    .contract-screen-but-boder:hover {
      cursor: pointer;
      background-color: @activeBackground2;
    }
  }

  .contract-table {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    .contract-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .contract-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .contract-table-card-tab {
          width: 130px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .contract-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .contract-table-card-active {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: @contentFontSize;
          .textArea {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .numArea {
            height: 100%;
            padding-top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            color: @activeColor;
          }
        }
        .contract-table-card-edit {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: @editColor;
          cursor: pointer;
        }
      }
      .contract-table-card-content {
        flex: 1;
        width: 98%;
      }
      .contract-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }

  .table-tip {
    height: 30px;
    width: auto;
    padding: 2px 5px;
    margin: 0 3px;
    border: 1px solid #333;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hri {
    display: flex;
    i {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 3px;
      font-size: 14px;
      color: @activeColor;
    }
  }
  .link {
    color: @activeColor;
    transition: 0.3s;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
</style>
