/**
 * 保存操作记录
 * @param {*} clientId 目标ID
 * @param {*} operation 具体操作
 * @param {*} user 谁
 * @param {*} model 模块
 * @param {*} this_ VUE
 * @param {*} deal 回调函数
 */
export function saveOperationRecords(clientId, operation, user, model, this_, deal) {
    let requestData = {
        clientId: clientId,
        operation: operation,
        userId: user.userId,
        model: model, // contract  client  thread  invoice  
    }

    this_.$request(
        "post",
        "/operation/saveOperationRecords",
        requestData,
        (data) => {
            deal(data)
        },
        (errorMessage) => {
            this_.$message.error("保存操作记录失败!");
        }
    )
}

/**
 * 查看操作记录
 * @param {*} clientId 目标ID
 * @param {*} model 
 * @param {*} this_ 
 * @param {*} deal 
 */
export function showOperationRecords(clientId, model, this_) {
    let requestData = {
        clientId: clientId,
        model: model, // contract  client  thread  invoice archives 
    }

    this_.$request("post", "/operation/showOperationRecords", requestData, (data) => {
        if (data.length > 0) {
            this_.dialogData.title = "操作记录"
            this_.dialogData.width = "30%"
            this_.dialogData.buttonArray = []
            this_.dialogData.data = []
            let list = []
            data.forEach(element => {
                let dd = {
                    icon: element.icon,
                    name: element.userName,
                    content: element.operation,
                    time: element.time,
                    model: model,
                }
                list.push(dd)
            });
            let dd = {
                type: "genjinjilu",
                value: {
                    hasContent: false,
                    content: "",
                    time: "",
                    list: list,
                    hasTarget: false,
                    target: "",
                    hasPhase: false,
                    phase: "",
                },
                title: "",
            };
            this_.dialogData.data.push(dd);
            this_.dialogData.flag = true;
        } else {
            this_.$message.error("暂无操作记录!");
        }
    }, (errorMessage) => {
        this_.$message.error("操作记录查看失败!");
    })
}