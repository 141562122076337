var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"company-drawer-right-center-exchange"},[_c('div',{staticClass:"company-drawer-right-top-search"},[_c('el-input',{staticStyle:{"width":"200px"},attrs:{"placeholder":"搜索沟通记录"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.resetSearchData($event)}},model:{value:(_vm.searchData.input),callback:function ($$v) {_vm.$set(_vm.searchData, "input", $$v)},expression:"searchData.input"}},[_c('el-button',{attrs:{"slot":"append","icon":"el-icon-search"},on:{"click":_vm.resetSearchData},slot:"append"})],1),_c('el-checkbox',{staticStyle:{"margin-left":"20px"},attrs:{"true-label":"1","false-labe":"''"},on:{"change":_vm.resetSearchData},model:{value:(_vm.searchData.status),callback:function ($$v) {_vm.$set(_vm.searchData, "status", $$v)},expression:"searchData.status"}},[_vm._v("只看未确认")])],1),_c('div',{staticClass:"exchange-content"},[_c('div',{staticClass:"exchange-content-list"},_vm._l((_vm.tableData),function(item,index){return _c('div',{key:item.id,staticClass:"exchange-content-list-item",style:({
          'align-items':
            item.sendUserId == _vm.baseInfo.userId ? 'flex-end' : 'flex-start',
        })},[_c('div',{staticClass:"exchange-content-list-item-time",style:({
            'flex-direction':
              item.sendUserId == _vm.baseInfo.userId ? 'row-reverse' : '',
          })},[_c('span',[_vm._v(_vm._s(item.sendUserName))]),_c('span',[_vm._v(_vm._s(item.sendDate))])]),_c('div',{staticClass:"exchange-content-list-item-message",style:({
            'background-color':
              item.sendUserId == _vm.baseInfo.userId ? '#E8E8F7' : '#fff',
          }),on:{"contextmenu":function($event){$event.preventDefault();return _vm.onContextmenu($event, item, index)}}},[(item.status == '1' && item.visibleUserIds)?_c('span',{staticClass:"exchange-content-list-item-status"},[_vm._v(" 未确认 ")]):_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":item.confirmUserName + ' ' + item.confirmDate,"placement":"top-start"}},[(item.status == '2' && item.visibleUserIds)?_c('span',{staticClass:"exchange-content-list-item-status2"},[_vm._v("已确认")]):_vm._e()]),_c('span',{domProps:{"innerHTML":_vm._s(item.content)}}),(item.status == '1' && item.visibleUserIds)?_c('i',{staticClass:"el-icon-circle-check",style:({
              left: item.sendUserId == _vm.baseInfo.userId ? '-20px' : '',
            }),on:{"click":function($event){return _vm.messageRecords(item, index, 'edit')}}}):_vm._e()],1)])}),0)]),_c('div',{staticClass:"exchange-input"},[_c('el-input',{staticClass:"textarea",attrs:{"placeholder":"请输入内容, 不超过500字","type":"textarea","resize":"none"},model:{value:(_vm.textarea),callback:function ($$v) {_vm.textarea=$$v},expression:"textarea"}}),_c('div',{staticClass:"exchange-input-button",on:{"click":_vm.selectUser}},[_vm._v(" @成员并发送 ")]),_c('div',{staticClass:"exchange-input-button2",on:{"click":function($event){return _vm.messageRecords('', '', 'add')}}},[_vm._v(" 发送 ")])],1),(_vm.dialogData.flag)?_c('div',{attrs:{"id":"my-dialog"}},[_c('my_dialog',{attrs:{"width":_vm.dialogData.width,"title":_vm.dialogData.title,"buttonArray":_vm.dialogData.buttonArray,"data":_vm.dialogData.data},on:{"colseDialog":_vm.colseDialog,"dialogOper":_vm.drawerOper}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }