<template>
  <div id="voucher_for">
    <el-table :data="value" border style="width: 99%" height="40vh">
      <el-table-column prop="voucherNo" label="交易单号"> </el-table-column>
      <el-table-column prop="money" label="收款总金额"> </el-table-column>
      <el-table-column prop="remainingAmount" label="剩余金额">
      </el-table-column>
      <el-table-column prop="haveReceivedAmount" label="已领金额">
      </el-table-column>
      <el-table-column prop="paymentTime" label="收款时间"> </el-table-column>
      <el-table-column prop="operationOf" label="付款人"> </el-table-column>
      <el-table-column prop="paymentAccount" label="收款账号">
      </el-table-column>
      <el-table-column prop="creater" label="创建人"> </el-table-column>
      <el-table-column prop="des" label="备注"> </el-table-column>
      <el-table-column prop="file" label="操作" align="center" width="120">
        <template slot-scope="scope">
          <el-button
            type="success"
            plain
            @click="voucherFor(scope.row)"
            size="mini"
            >领取</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: ["voucherForData"],
  data() {
    return {
      baseInfo: {},
      value: this.voucherForData,
      //   value: [],
    };
  },
  watch: {
    voucherForData() {
      //父组件值变化->>子组件也变化
      this.value = this.voucherForData;
    },
    value(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {
    voucherFor(data) {
      this.$emit("voucherFor", data);
    },
  },
  mounted() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
  },
};
</script>

<style lang="less" scoped>
#voucher_for {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .line {
    color: #019afc;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>