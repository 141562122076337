<template>
  <div id="thread-share">
    <!-- 我的商机 -->
    <div class="thread-screen">
      <div class="thread-screen-line">
        <!-- <div class="thread-screen-fiftrate">
          <el-popover
            placement="bottom-start"
            width="250"
            trigger="manual"
            :visible-arrow="false"
            v-model="popoData.popoVisible"
          >
            <div class="popover-search">
              <popo_search
                :list="popoData.list"
                @cancel="popoCanael"
                @reset="popoReset"
                @makeSure="popoMakeSure"
              ></popo_search>
            </div>
            <div slot="reference" @click="popoverOpen">
              筛选 <i class="el-icon-s-operation"></i>
            </div>
          </el-popover>
        </div> -->
        <div class="thread-screen-search">
          <el-input
            placeholder="商机名称"
            v-model="searchData.input"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="thread-screen-search">
          <el-cascader
            v-model="searchData.department"
            placeholder="请选择部门"
            :options="searchData.departTreeData"
            @change="initTableData"
            clearable
            :show-all-levels="false"
            :props="{ checkStrictly: true, value: 'id' }"
            filterable
          ></el-cascader>
        </div>
        <div class="thread-screen-search">
          <el-select
            v-model="searchData.userId"
            @change="selectInitTableData"
            filterable
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="thread-screen-search">
          <el-date-picker
            v-model="searchData.gmtCreate"
            type="daterange"
            range-separator="至"
            start-placeholder="创建时间"
            end-placeholder="创建时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            @change="initTableData"
          >
          </el-date-picker>
        </div>
        <div class="thread-screen-search">
          <el-date-picker
            v-model="searchData.accountDueTime"
            type="monthrange"
            range-separator="至"
            start-placeholder="到期时间"
            end-placeholder="到期时间"
            value-format="yyyy-MM"
            @change="initTableData"
          >
          </el-date-picker>
        </div>
        <div class="thread-screen-search">
          <el-select
            v-model="searchData.intentionLevel"
            @change="initTableData"
            filterable
            placeholder="意向等级"
            clearable
          >
            <el-option
              v-for="item in arrayList.intentionLevelList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="thread-screen-active"></div>
      </div>
      <div class="thread-screen-line">
        <div class="thread-screen-search">
          <el-select
            v-model="searchData.customerSource"
            @change="initTableData"
            filterable
            placeholder="客户来源"
            clearable
          >
            <el-option
              v-for="item in arrayList.sourceList"
              v-show="item.active"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="thread-screen-line" v-if="searchData.screenList.length > 0">
        <div class="title">筛选条件:</div>
        <el-tag
          v-for="(tag, index) in searchData.screenList"
          :key="index"
          closable
          size="small"
          @close="closeScreen(index)"
        >
          {{ tag.name }}
        </el-tag>
      </div>
    </div>
    <div class="thread-table">
      <div class="thread-table-card">
        <div class="thread-table-card-tabs">
          <div
            :class="{
              'thread-table-card-tab': true,
              'is-active': tabindex == '0',
            }"
            @click="changeTab('0')"
          >
            跟进中
          </div>
          <div
            :class="{
              'thread-table-card-tab': true,
              'is-active': tabindex == '1',
            }"
            @click="changeTab('1')"
          >
            已成交
          </div>
          <div
            :class="{
              'thread-table-card-tab': true,
              'is-active': tabindex == '2',
            }"
            @click="changeTab('2')"
          >
            无效
          </div>
          <div class="thread-table-card-active"></div>
          <div class="thread-table-card-edit">
            <i class="el-icon-edit-outline" @click="updateTableMenu"></i>
          </div>
        </div>
        <div class="thread-table-card-content">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            @row-click="rowClick"
            style="width: 100%"
            height="98%"
            v-loading="loading"
            @selection-change="handleSelectionChange"
            :default-sort="defaultSort"
            @sort-change="sortChange"
          >
            <el-table-column type="index" width="55" fixed> </el-table-column>
            <template v-for="(item, index) in tabMenu">
              <el-table-column
                :property="item.key"
                :key="index"
                :label="item.title"
                :show-overflow-tooltip="true"
                :fixed="item.fixed"
                :width="item.width"
                :sortable="
                  item.key == 'followRecordsTime' || item.key == 'gmtCreate'
                    ? 'custom'
                    : false
                "
              >
                <template slot-scope="scope">
                  <div class="hri" v-if="item.key == 'clientName'" style="">
                    <i
                      class="el-icon-notebook-2"
                      :style="{
                        color: scope.row.flag != 1 ? '#40a3ff' : 'red',
                      }"
                      @click.stop="showGenjin(scope.row)"
                    ></i>
                    {{ scope.row[item.key] }}
                  </div>
                  <div v-else>
                    {{ scope.row[item.key] }}
                    <span
                      v-if="item.key == 'phone'"
                      @click.stop="handleClick(scope.row, 0)"
                    >
                      <i class="el-icon-phone-outline phone"></i>
                    </span>
                  </div>
                </template>
              </el-table-column>
            </template>
          </el-table>
        </div>
        <div class="thread-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 自定义列 -->
    <div id="my-dialog" v-if="tragerDialogData.flag">
      <div class="my-dialog-cc">
        <div class="my-dialog-title">
          <div class="my-dialog-title-text">{{ tragerDialogData.title }}</div>
          <div class="my-dialog-title-active"></div>
          <div class="my-dialog-title-close" @click="closeUpdateTableMenu">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <el-divider></el-divider>
        <div class="my-dialog-content">
          <new_drager
            :dragerData="tragerDialogData.data"
            @show="showDrager"
            @dragging="dragging"
            @closeUpdateTableMenu="closeUpdateTableMenu"
            @markSureUpdateTableMenu="markSureUpdateTableMenu"
          ></new_drager>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @buttonOper="drawerOper"
        @addFollowRecord="followRecord"
      ></my_dialog>
    </div>
    <!-- 右边抽屉 -->
    <div id="my-drawer" v-if="threadDrawerData.flag">
      <my_drawer
        :drawerData="threadDrawerData"
        :allFunctionButtons="allFunctionButtons"
        :model="'threadShare'"
        @show="showDrawer"
        @closeUpdateTableDrawer="closeUpdateTableDrawer"
        @saveDrawerData="saveDrawerData"
        @drawerOper="drawerOper"
        @radioChange="radioChange"
      ></my_drawer>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import drager from "@/components/drager.vue";
import my_drawer from "@/components/my-drawer.vue";
import popo_search from "@/components/popo-search.vue";
import my_dialog from "@/components/dialog.vue";
import * as myThreadData from "@/assets/js/myThread.js";
import * as clientInfoData from "@/assets/js/clientInfoData.js";
import * as systemData from "@/assets/js/systemAuthorityData.js";
import * as employeeData from "@/assets/js/employeeData.js";
import * as productData from "@/assets/js/productData.js";
import * as contractDrawData from "@/assets/js/contractDraw.js";
import * as threadData from "./js/threadData.js";
import * as allContractData from "../contract/js/contractData.js";
export default {
  components: {
    drager,
    my_drawer,
    popo_search,
    my_dialog,
  },
  inject: ["reload"],
  data() {
    return {
      baseInfo: {},
      loading: true,
      whetherAgencyHead: true, // 是否是机构负责人
      searchData: {
        input: "",
        checked: false,
        screenList: [],
        department: "",
        departTreeData: [],
        userId: "",
        userIdList: [],
        userList: [],
        gmtCreate: [],
        accountDueTime: [],
        intentionLevel: "",
        customerSource: "",
      },
      popoData: {
        popoVisible: false,
        list: myThreadData.remyThreadSearchData(),
      },
      defaultSort: { prop: "", order: "" },
      tabindex: "0",
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 100, // 每页条数
      },
      tabMenu: myThreadData.ThreadShareTableMenuData(),
      tableMenuData: {
        selectMenuData: [], // 真正显示的表头数据
        chooseableMenuData: [], // 隐藏的表头
      },
      tableData: [],
      multipleSelection: [],
      tragerDialogData: {
        title: "自定义列",
        flag: false,
        data: {},
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "添加跟进记录",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: myThreadData.getMyThreadsGenjinjiluData(),
      },
      threadDrawerData: threadData.threadShareDrawerData("商机", [], false, {}),
      tempTags: [],
      tempThread: {
        id: "",
        phone: "",
      },
      arrayList: {
        employeeList: [], // 所有员工列表
        departmentList: [], // 所有部门列表
        areaList: [], // 所有片区列表
        sourceList: [], // 所有客户来源列表
        followStageList: [], // 跟进阶段列表
        addedValueTaxTypeList: [], // 增值税类型列表
        intentionalProductList: [], // 所有意向产品列表
        accountOnBehalfList: [], // 代账情况
        intentionLevelList: [], // 意向等级
        threadShareTypeList: [], // 共享商机类型
      },
      buttonAuth: [],
      tempContract: { id: "", name: "" }, // 临时合同
      tempClient: {},
      tempServices: [], // 该合同所包含的所有服务
      professionList: [], // 所有合同执行模板
      employeeList: [], // 所有员工
      departmentList: [], // 所有员工
      contractSettingButtonList: [],
      contractDrawerData: {}, // 合同预览数据
      accountList: [], // 收款账号
      contractId: "", // 预览的合同ID
      createThreadFlag: false, // 创建商机时的检索flag
      ttempClient: {},
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "threadShare"
      ),
      drawerOptions: this.$tagData.getDrawerOptionsList("myThread"), // 抽屉中的下拉框配置项
      drawerAuthorityData: [],
      tempData: "",
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "threadShare",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getEmployeeList();
        myThreadData.getTagList(
          this.arrayList,
          ["代账情况", "意向等级", "共享商机类型", "客户来源"],
          this.baseInfo.company.companyId,
          this
        );
      });
    },
    /**
     * 获取所有员工列表
     */
    getEmployeeList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/user/getEmployeeListForDownBox",
        requestData,
        (data) => {
          this.employeeList = [];
          data.forEach((element) => {
            let dd = {
              label: element.userName,
              value: element.userId,
            };
            this.employeeList.push(dd);
          });
          this.drawerOptions.allUser = this.employeeList;
          this.getDepartmentTreeByUserId();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取部门
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
          this.getEmployeeListByUserId();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeListByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.searchData.userList = [];
          data.forEach((element) => {
            let dd = {
              name: element.userName,
              id: element.userId,
            };
            this.searchData.userIdList.push(element.userId);
            this.searchData.userList.push(dd);
          });
          this.searchData.userList.splice(0, 0, {
            id: "all",
            name: "全部人员",
          });
          this.searchData.userId = this.baseInfo.userId;
          this.selectInitTableData(this.baseInfo.userId);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      this.tableData = [];
      this.loading = true;
      let requestData = {
        userId: this.baseInfo.userId, // 当前登录人
        status: this.tabindex, // 商机状态:"0"->跟进中; "1"->已成交;
        department:
          this.searchData.department.length > 0
            ? this.searchData.department[this.searchData.department.length - 1]
            : "", // 部门 id
        name: this.searchData.input, // 商机名称
        customerSource: this.searchData.customerSource, // 筛选项：客户来源
        userIdList: this.searchData.tempUserId, // 员工 id 集合
        gmtCreate: this.searchData.gmtCreate, // 筛选项：共享商机创建时间，年月日时分秒
        accountDueTime: this.searchData.accountDueTime, // 筛选项：到期时间，年月
        intentionLevel: this.searchData.intentionLevel, // 筛选项：意向等级
        sortProp: this.defaultSort.prop, // 手动排序项
        sortOrder: this.defaultSort.order, // 手动排序规则 ascending descending ""
        // screenList: this.searchData.screenList,
        pageSize: this.pageData.pageSize, // 每页条数
        currentPage: this.pageData.currentPage, // 当前页数
      };
      this.$request(
        "post",
        "/reThreadShare/page",
        requestData,
        (data) => {
          this.pageData.total = data.total; // 获取总条目数
          this.tableData = data.records;
          this.tableData.forEach((item) => {
            if (item.contacts) {
              let contacts = JSON.parse(item.contacts);
              item.phone = contacts[0].phone[0];
            }
            if (this.baseInfo.userId == item.createUserId) {
              item.shareUserNames = item.shareUserNames.join(",");
            } else {
              item.shareUserNames = this.baseInfo.userName;
            }
          });
          this.$nextTick(() => {
            this.$refs.singleTable.doLayout();
          });
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    /**
     * tab切换事件
     * index:0 -> 全部; 1->待首次跟进; 2->待联系
     */
    changeTab(index) {
      this.tabindex = index;
      this.initTableData();
    },
    handleClick(row, flag) {
      let requestData = {};
      this.tempThread.id = row.id;
      this.tempThread.phone = row.phone;
      switch (flag) {
        case 0:
          this.callNumber();
          break;
        default:
          break;
      }
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },

    /**
     * 多选框事件
     */
    handleSelectionChange(val) {
      this.multipleSelection = [];
      val.forEach((element) => {
        this.multipleSelection.push(element.id);
      });
    },
    /**
     * 表格排序事件
     */
    sortChange(data) {
      this.defaultSort.prop = data.prop;
      this.defaultSort.order = data.order == null ? "" : data.order;
      this.initTableData();
    },
    /**
     * 筛选弹出框显示
     */
    popoverOpen() {
      console.log("所有数组数据:", this.arrayList);
      this.popoData.list[1].options = []; // 客户来源
      this.popoData.list[7].options = []; // 商机创建人
      this.popoData.list[6].options = []; // 商机创建人
      this.popoData.list[8].options = []; // 意向产品
      this.arrayList.sourceList.forEach((element) => {
        if (element.active) {
          let dd = {
            label: element.name,
            value: element.value[0],
          };
          this.popoData.list[1].options.push(dd);
        }
      });

      this.popoData.list[6].options = this.employeeList;
      this.popoData.list[7].options = this.employeeList;

      this.popoData.popoVisible = !this.popoData.popoVisible;
    },
    /**
     * 筛选弹出框确认事件
     */
    popoMakeSure() {
      this.searchData.screenList = this.$systemBaseData.remakesureSearchData(
        this.popoData.list
      );
      // console.log(this.popoData.list);
      this.popoData.popoVisible = false;
      this.initTableData();
    },
    /**
     * 筛选弹出框重置
     */
    popoReset() {
      this.$systemBaseData.popoReset(this.popoData.list);
      this.searchData.screenList = [];
    },
    /**
     * 筛选弹出框关闭事件
     */
    popoCanael() {
      this.popoData.popoVisible = false;
    },
    /**
     * 关闭筛选标签事件
     */
    closeScreen(index) {
      this.searchData.screenList.splice(index, 1);
      this.initTableData();
    },
    /**
     * 修改表头顺序dialog打开
     */
    updateTableMenu() {
      this.tragerDialogData.data = JSON.parse(JSON.stringify(this.tabMenu));
      this.tragerDialogData.flag = true;
    },
    /**
     * 确定修改表头数据
     */
    markSureUpdateTableMenu(resultData) {
      console.log("确定修改表头数据:", resultData);
      this.tabMenu = JSON.parse(JSON.stringify(resultData));
      this.tragerDialogData.flag = false;
      this.initTableData();
      this.$menuData.saveTableMenuData(resultData, "threadShare", this);
    },

    /**
     * 表格单行点击事件
     */
    rowClick(row, column, event) {
      console.log("表格行点击事件:", row);
      this.tempThread.id = row.id;
      this.tempThread.phone = row.phone;
      if (!this.popoData.popoVisible) {
        if (!this.threadDrawerData.flag) {
          //防止重复点击
          this.tempData = row;
          this.$featureData.getAttributeData(
            "threadShare",
            false,
            threadData.returnDrawerKeysByThreadShare(),
            (authorityData) => {
              this.drawerAuthorityData = authorityData;
              // 2.拿对应属性的权限
              this.$authority.getModelFeatureAuthority(
                this,
                "threadShare",
                authorityData,
                (authorityList) => {
                  // 3.将权限数据对应到界面
                  this.threadDrawerData = threadData.threadShareDrawerData(
                    this.tempData.clientName,
                    authorityList,
                    this.whetherAgencyHead, // 是否是机构负责人
                    this.arrayList
                  );
                  // 4.拿具体的值
                  this.getMyThreadDetailsByThreadId();
                }
              );
            }
          );
        } else {
          this.closeUpdateTableDrawer();
        }
      } else {
        this.popoCanael();
      }
    },
    /**
     * 获取商机的具体信息
     */
    getMyThreadDetailsByThreadId() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId, // 当前公司ID
        id: this.tempData.id, // 当前线索ID
        authorityData: this.$initDrawerAuthorityData.getKeysByAuthorityList(
          this.drawerAuthorityData
        ), // 模块具体权限key数组
      };
      console.log("authorityData", requestData.authorityData);
      this.$request(
        "post",
        "/reThreadShare/getInfo",
        requestData,
        (data) => {
          // 5.将具体的值匹配到界面
          this.threadDrawerData = this.$initDrawerAuthorityData.initDrawerData(
            this.threadDrawerData,
            data
          );
          console.log("this.threadDrawerData", this.threadDrawerData);
          // 6.在权限的基础上叠加状态
          this.threadDrawerData.status = data.status;
          this.threadDrawerData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },

    /**
     * 抽屉组件中按钮操作事件(主要是显示弹出框事件)
     */
    drawerOper(active) {
      let dd = {};
      let requestData = {};
      switch (active) {
        case "showDetail":
          // 查看关联客户
          this.dialogData.title = "";
          this.dialogData.width = "720px";
          this.dialogData.buttonArray = [];
          dd = {
            type: "drawer",
            data: clientInfoData.clientInfoDrawerData(
              "商机1",
              clientInfoData.getClientInfoData()
            ),
          };
          this.dialogData.data = [];
          this.dialogData.data.push(dd);
          this.dialogData.flag = true;
          break;
        case "genjin":
          // 添加跟进记录
          if (this.threadDrawerData.data.modelList[0].line[5].value == "") {
            this.$message.error("客户来源不能为空!");
            break;
          }
          if (
            this.threadDrawerData.data.modelList[2].line[0].value.length == 1 && // 只有一组联系人
            (this.threadDrawerData.data.modelList[2].line[0].value[0].name == // 假数据 联系人姓名为空
              "" ||
              (this.threadDrawerData.data.modelList[2].line[0].value[0] // 假数据  联系人两组手机号中有一个为空
                .phone[0] == "" &&
                this.threadDrawerData.data.modelList[2].line[0].value[0]
                  .phone[1] == ""))
          ) {
            this.$message.error("联系人或手机号不能为空!");
            break;
          }
          if (
            this.threadDrawerData.data.modelList[4].line[0].value.length == 0
          ) {
            this.$message.error("意向产品不能为空!");
            break;
          }

          this.showGenjin(this.ttempClient);
          break;
        case "zhuanchengjiao":
          // 商机转成交

          if (this.threadDrawerData.data.modelList[0].line[5].value == "") {
            this.$message.error("客户来源不能为空!");
            break;
          }
          if (
            this.threadDrawerData.data.modelList[2].line[0].value.length == 1 && // 只有一组联系人
            (this.threadDrawerData.data.modelList[2].line[0].value[0].name == // 假数据 联系人姓名为空
              "" ||
              (this.threadDrawerData.data.modelList[2].line[0].value[0] // 假数据  联系人两组手机号中有一个为空
                .phone[0] == "" &&
                this.threadDrawerData.data.modelList[2].line[0].value[0]
                  .phone[1] == ""))
          ) {
            this.$message.error("联系人或手机号不能为空!");
            break;
          }
          if (
            this.threadDrawerData.data.modelList[4].line[0].value.length == 0
          ) {
            this.$message.error("意向产品不能为空!");
            break;
          }
          this.dialogData.title = "提示";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push({
            text: "确定",
            active: "makesure_zhuanchengjiao",
            key: "cancel",
          });
          this.dialogData.buttonArray.push({
            text: "取消",
            active: "cancel",
            key: "cancel",
          });
          this.dialogData.data = [];
          this.dialogData.data.push({
            type: "reminder",
            text: "转为客户",
            value: this.threadDrawerData.data.title,
          });
          this.dialogData.flag = true;
          break;
        case "zhiweiwuxiao":
          // 置为无效
          this.dialogData.title = "提示";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push({
            text: "确定",
            active: "makesure_zhiweiwuxiao",
            key: "cancel",
          });
          this.dialogData.buttonArray.push({
            text: "取消",
            active: "cancel",
            key: "cancel",
          });
          this.dialogData.data = [];
          this.dialogData.data.push({
            type: "reminder",
            text: "置为无效",
            value: this.threadDrawerData.data.title,
          });
          this.dialogData.flag = true;
          break;
          break;
        case "shifang":
          // 释放至商机池
          this.dialogData.title = "提示";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push({
            text: "确定",
            active: "makesure_shifang",
            key: "cancel",
          });
          this.dialogData.buttonArray.push({
            text: "取消",
            active: "cancel",
            key: "cancel",
          });
          this.dialogData.data = [];
          this.dialogData.data.push({
            type: "reminder",
            text: "释放至商机池",
            value: this.threadDrawerData.data.title,
          });
          this.dialogData.flag = true;
          break;

        case "delete_thread":
          // 删除商机
          this.dialogData.title = "提示";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push({
            text: "确定",
            active: "makesure_delete_thread",
            key: "cancel",
          });
          this.dialogData.buttonArray.push({
            text: "取消",
            active: "cancel",
            key: "cancel",
          });
          this.dialogData.data = [];
          this.dialogData.data.push({
            type: "reminder",
            text: "删除",
            value: this.threadDrawerData.data.title,
          });
          this.dialogData.flag = true;
          break;
          break;
        case "operating_record":
          // 查看操作记录
          this.getOperationRecordsList();
          break;
        case "get_companyInfo":
          // 获取工商信息
          requestData = {
            name: this.threadDrawerData.data.title,
          };
          this.$request(
            "post",
            "/client/getCreditCode",
            requestData,
            (data) => {
              this.threadDrawerData.data.modelList[3].line[4].value =
                data.taxNum == ""
                  ? this.threadDrawerData.data.modelList[3].line[4].value
                  : data.taxNum;
              this.threadDrawerData.data.modelList[3].line[5].value =
                data.tel == ""
                  ? this.threadDrawerData.data.modelList[3].line[5].value
                  : data.tel;
              this.threadDrawerData.data.modelList[3].line[6].value =
                data.bank == ""
                  ? this.threadDrawerData.data.modelList[3].line[6].value
                  : data.bank;
              this.threadDrawerData.data.modelList[3].line[7].value =
                data.bankAccount == ""
                  ? this.threadDrawerData.data.modelList[3].line[7].value
                  : data.bankAccount;
              this.threadDrawerData.data.modelList[3].line[3].value =
                data.address == ""
                  ? this.threadDrawerData.data.modelList[3].line[3].value
                  : data.address;

              this.threadDrawerData.data.modelList[3].line[2].value = [];
              this.threadDrawerData.data.modelList[3].line[2].value =
                data.address == ""
                  ? []
                  : this.$addressAutomaticallyMatch.addressAutomaticallyMatch(
                      data.address
                    );

              if (
                this.threadDrawerData.data.modelList[3].line[2].value == null ||
                this.threadDrawerData.data.modelList[3].line[2].value.length < 3
              ) {
                this.threadDrawerData.data.modelList[3].line[2].value = [];
              }
              this.saveDrawerData("工商信息");
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;

        //  合同抽屉
        case "info":
          // 合同信息
          this.showContractInfoByID();
          break;
        case "end_the_manual":
          // 手动结束合同
          this.$confirm("该合同未到期或未全部回款, 是否继续结束?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$request(
                "post",
                "/contract/setContractFinish",
                { contractId: this.tempContract.id },
                (data) => {
                  this.$message({
                    type: "success",
                    message: "操作成功!",
                    duration: 1000,
                  });
                  this.threadDrawerData.flag = false;
                  this.$operationRecords.saveOperationRecords(
                    this.tempContract.id,
                    "手动结束合同!",
                    this.baseInfo,
                    "contract",
                    this,
                    (data2) => {
                      this.initTableData();
                    }
                  );
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                  this.dialogData.flag = false;
                }
              );
            })
            .catch(() => {});
          break;
        case "huikuan":
          // 合同抽屉中的收款记录
          this.getReceiptRecord();
          break;
        case "fujian":
          // 合同抽屉中的合同附件
          break;
        case "submit":
          // 合同提交
          this.$confirm("确定合同提交?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.submitContract();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消提交",
                duration: 1000,
              });
            });

          break;

        case "add_serves":
          // 合同抽屉中的添加服务项
          dd = {
            flag: true,
            tableData: {},
            item: [
              {
                horizontal: false,
                isShow: true,
                isDisable: false,
                title: "服务名称",
                type: "select",
                value: "",
                options: this.serviceItemList,
              },
              {
                horizontal: false,
                isShow: true,
                isDisable: false,
                title: "付费周期",
                type: "select",
                value: "每年",
                options: [
                  {
                    label: "每年",
                    value: "每年",
                    img: "",
                  },
                  {
                    label: "每月",
                    value: "每月",
                    img: "",
                  },
                  {
                    label: "每季度",
                    value: "每季度",
                    img: "",
                  },
                ],
              },
              {
                horizontal: false,
                isShow: true,
                isDisable: true,
                title: "催费时间",
                type: "收费提醒选择",
                radio: "提前",
                beforeDay: "",
                lateDay: "",
                value: "",
              },
              {
                horizontal: false,
                isShow: true,
                isDisable: false,
                title: "优惠",
                type: "discounts",
                value: {
                  discount: "",
                  flag: "",
                  mitigate: "",
                  totalPrice: "",
                },
              },
              {
                horizontal: false,
                isShow: true,
                isDisable: false,
                title: "首次催费时间",
                type: "time",
                value: "",
              },
              {
                horizontal: false,
                isShow: true,
                isDisable: false,
                title: "服务总价",
                type: "input",
                value: "",
              },
              {
                horizontal: false,
                isShow: true,
                isDisable: false,
                title: "服务时间",
                type: "timeToTime",
                value: [
                  {
                    discounts: "",
                    money: "",
                    price: "",
                    time1: "2021-07",
                    time2: "2022-07",
                  },
                ],
              },
            ],
          };
          this.threadDrawerData.data.modelList[2].line[0].value.push(dd);
          break;
        case "add_free_serves":
          // 合同抽屉中的添加赠送的服务项
          if (!this.threadDrawerData.data.modelList[3].line[0].isDisable) {
            dd = {
              isEdit: true, // 是否是编辑状态
              flag: true, // 是否是新增
              serviceItem: {}, //服务项
              serviceTime: "", // 服务时间
            };
            this.threadDrawerData.data.modelList[3].line[0].value.push(dd);
          } else {
            this.$message({
              message: "当前合同状态不可编辑!",
              duration: 1000,
              type: "error",
            });
          }
          break;
        case "operating_record_contract":
          // 查看合同的操作记录
          this.$operationRecords.showOperationRecords(
            this.contractId,
            "contract",
            this
          );
          break;
        case "cancel":
          // dialog关闭
          this.dialogData.flag = false;
          break;
        default:
          break;
      }
    },

    /**
     * 获取收款记录
     */
    getReceiptRecord() {
      let requestData = {
        contractId: this.tempContract.id,
      };
      this.$request(
        "post",
        "/contract/getReceiptRecord",
        requestData,
        (data) => {
          this.threadDrawerData = contractDrawData.contractReturnedMoneyDrawerData(
            this.tempThread.name,
            data,
            this.contractSettingButtonList
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },

    /**
     * 是否赠送服务项单选框选择事件
     */
    radioChange(data) {
      console.log("是否赠送服务项单选框选择事件", data);

      if (data.item.title == "是否赠送服务") {
        if (data.val == "Y") {
          this.contractDrawerData.data.modelList[3].line[0].isShow = true;
        } else {
          this.contractDrawerData.data.modelList[3].line[0].isShow = false;
          this.contractDrawerData.data.modelList[3].line[0].value = [];
        }
      }
      if (data.item.title == "是否优惠") {
        if (data.val == "Y") {
          this.contractDrawerData.data.modelList[0].line[8].isShow = true;
          this.clientInfoDrawerData.data.modelList[0].line[8].value = "0.00";
        } else {
          this.contractDrawerData.data.modelList[0].line[8].isShow = false;
          this.contractDrawerData.data.modelList[0].line[8].value = "";
        }
      }
      this.saveDrawerData("赠送服务");
    },
    /**
     * 查看操作记录
     */
    getOperationRecordsList() {
      this.$operationRecords.showOperationRecords(
        this.tempThread.id,
        "thread",
        this
      );
    },
    /**
     * 保存抽屉数据
     */
    saveDrawerData(drawerTitle) {
      if (this.threadDrawerData.title == "销售商机") {
        // 保存商机
        if (
          !myThreadData.getDrawerSubmitData(
            this.threadDrawerData.data.modelList
          ).flag
        ) {
          this.$message({
            message: "联系人手机号格式不正确,请确认填入正确的手机号!",
            type: "error",
            duration: 1000,
          });
          // this.getMyThreadDetailsByThreadId();
          return;
        }
        console.log(
          "this.threadDrawerData.data.modelList",
          this.threadDrawerData.data.modelList
        );
        let requestData = {
          id: this.tempData.id,
        };
        for (
          let index = 0;
          index < this.threadDrawerData.data.modelList.length;
          index++
        ) {
          let element = this.threadDrawerData.data.modelList[index].line;
          for (let j = 0; j < element.length; j++) {
            let e = element[j];
            requestData[e.key] = e.value;
          }
        }
        // console.log("保存数据:", this.threadDrawerData.data);
        this.$request(
          "post",
          "/reThreadShare/update",
          requestData,
          (data) => {
            this.$operationRecords.saveOperationRecords(
              this.tempThread.id,
              "修改" + drawerTitle + "信息!",
              this.baseInfo,
              "thread",
              this,
              (data) => {
                this.$message.success("修改成功!");
                this.getMyThreadDetailsByThreadId();
                this.initTableData();
              }
            );
          },
          (errorMessage) => {
            this.$message.error(errorMessage);
            // this.loading = false;
          }
        );
      } else if (this.threadDrawerData.title == "合同") {
        // 保存合同数据
        if (
          contractDrawData.formatContractInfoDrawerData(
            this.threadDrawerData.data.modelList
          )
        ) {
          let requestData = {
            contractId: this.tempContract.id,
            userId: this.baseInfo.userId,
            companyId: this.baseInfo.company.companyId,
            data: contractDrawData.formatContractInfoDrawerData(
              this.threadDrawerData.data.modelList
            ),
            isRenew: false,
          };
          this.$request(
            "post",
            "/contract/updateContractInfoByID",
            requestData,
            (data) => {
              this.$message({
                message: "保存成功!",
                type: "success",
                duration: 1000,
              });
              this.showContractInfoByID();
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
        } else {
          this.$message.error("服务项名称不能为空!");
        }
      }
    },

    /**
     * 拖拽表头排序父子组件连锁事件
     */
    showDrager(value) {
      this.tableMenuData = value;
    },
    /**
     * 表头div拖动方法
     */
    dragging(value) {
      console.log("div拖动", value);
    },
    /**
     * 右边抽屉父子组件连锁事件
     */
    showDrawer(value) {
      this.threadDrawerData = value;
    },
    /**
     * 右边抽屉关闭事件
     */
    closeUpdateTableDrawer() {
      this.threadDrawerData.colseanimationflag = true;
      this.threadDrawerData.flag = false;
      // this.initTableData();
      // this.saveDrawerData();
    },
    /**
     * 弹出框关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
      this.dialogData.data.forEach((element) => {
        if (element.value) {
          element.value = "";
        }
      });
    },
    /**
     * 修改表头顺序dialog关闭
     */
    closeUpdateTableMenu() {
      this.tragerDialogData.flag = false;
    },
    /**
     * 查看跟进记录
     * 商机创建人 和 共享人
     */
    showGenjin(clientData) {
      this.ttempClient = clientData;
      console.log("this.ttempClient", this.ttempClient);
      let requestData = {
        userIds: [this.baseInfo.userId, this.ttempClient.createUserId], // 商机创建人 和 共享人 id
        threadId: clientData.reThreadId, // 商机 id
        clientId: "",
      };
      if (this.baseInfo.userId == clientData.createUserId) {
        requestData = {
          threadId: clientData.reThreadId, // 商机 id
          clientId: "",
        };
      }
      this.$request(
        "post",
        "/followRecord/showFollowRecord",
        requestData,
        (data) => {
          this.dialogData.title = "跟进记录-" + clientData.clientName;
          this.dialogData.buttonArray = [];
          this.dialogData.data = [
            {
              type: "genjinjilu",
              value: {
                clientId: clientData.id,
                threadId: "",
                hasContent: true,
                content: "",
                time: "",
                list: data,
                hasTarget: false,
                target: "",
                hasPhase: true,
                phase: "",
              },
            },
          ];
          this.dialogData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 确定添加跟进记录
     */
    followRecord(recordData) {
      console.log("跟进记录:", recordData);
      let requestData = {
        clientId: "", // 商机ID
        threadId: this.ttempClient.reThreadId, // 商机ID
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId, // 操作人ID
        content: recordData.content, // 当前操作
        time: recordData.time, // 下次跟进时间
        stage: recordData.phase,
      };
      if (requestData.content == "") {
        this.$message.error("跟进内容不能为空!");
        return;
      }
      this.$request(
        "post",
        "/followRecord/addFollowRecord",
        requestData,
        (data) => {
          this.$message({
            message: "跟进记录添加成功!",
            type: "success",
            duration: 1000,
          });
          this.$operationRecords.saveOperationRecords(
            this.ttempClient.id,
            "添加跟进记录:" + recordData.content + "!",
            this.baseInfo,
            "client",
            this,
            (data2) => {
              this.initTableData();
              this.showGenjin(this.ttempClient);
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 员工选择
     */
    selectInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.searchData.userIdList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.initTableData();
    },
    /**
     * 拨打电话 好销云
     */
    callNumber: _.throttle(function() {
      let requestData = {
        userId: this.baseInfo.userId, // 操作人
        TelB: this.tempThread.phone, // 被叫号码号码
      };
      this.$request(
        "post",
        "/haoXiaoYun/bind",
        requestData,
        (data2) => {
          this.$message.success("绑定成功, 请耐心等待呼叫!");
        },
        (errormessage2) => {
          this.$message.error(errormessage2);
        }
      );
    }, 2000),
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch("getCompantAccountList", this);
    let accountList = JSON.parse(
      JSON.stringify(this.$store.getters.getCompantAccountList)
    );
    this.accountList = accountList;
    this.$store.dispatch("getAllEmployeeList", this);
    let employeeList = JSON.parse(
      JSON.stringify(this.$store.getters.getAllEmployeeList)
    );
    this.arrayList.employeeList = [];
    employeeList.forEach((element) => {
      let dd = {
        label: element.userName,
        value: element.userId,
      };
      this.arrayList.employeeList.push(dd);
    });
    this.isCharge();
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
  beforeDestroy() {
    // 在组件销毁前取消防抖
    this.callNumber.cancel();
  },
};
</script>

<style lang="less" scoped>
#thread-share {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .thread-screen {
    width: 100%;
    height: auto;
    border-bottom: 1px solid @boderColor;
    display: flex;
    flex-direction: column;
    align-items: center;
    .thread-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      > .title {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 0 10px;
      }
      > .el-tag {
        margin: 0 5px;
      }
      .thread-screen-fiftrate {
        width: 65px;
        min-width: 65px;
        height: 30px;
        border: 1px solid @boderColor;
        box-sizing: border-box;
        color: @boderActiveColor;
        font-size: @contentFontSize;
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: 0.3s;
        i {
          margin: 0 4px;
        }
      }
      .thread-screen-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }
      .thread-screen-search {
        width: 210px;
        min-width: 210px;
        height: 30px;
        margin-left: 10px;
        display: flex;
        align-items: center;
      }
      .thread-screen-checked {
        width: 140px;
        height: 30px;
        min-width: 140px;
        font-size: @contentFontSize;
        margin: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .thread-screen-active {
        flex: 1;
      }
      .thread-screen-but-select {
        width: 140px;
        min-width: 160px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
        cursor: pointer;
        .thread-screen-but-select_but {
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.5s;
        }
        .thread-screen-but-select_but:hover {
          background-color: @activeColor2;
        }
        .thread-screen-but-select_select {
          width: 30px;
          height: 100%;
          border-left: 1px solid @activeColor3;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .thread-screen-but {
        width: 100px;
        min-width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .thread-screen-but:hover {
        cursor: pointer;
        background-color: @activeColor2;
      }
      .thread-screen-but-boder {
        width: 70px;
        min-width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        font-size: @contentFontSize;
        background-color: @activeBackground;
        color: @activeColor;
        margin: 0 5px;
        margin-right: 10px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .thread-screen-but-boder:hover {
        cursor: pointer;
        background-color: @activeBackground2;
      }
    }
  }
  .thread-table {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;

    .thread-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .thread-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .thread-table-card-tab {
          width: 80px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .thread-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .thread-table-card-active {
          flex: 1;
        }
        .thread-table-card-edit {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: @editColor;
          cursor: pointer;
        }
      }
      .thread-table-card-content {
        flex: 1;
        width: 98%;
        height: 0;
        display: flex;
        align-content: center;
        justify-content: center;
        font-size: @contentFontSize;
        color: @closeColor;
        .phone {
          color: red;
          transform: rotateY(180deg);
          cursor: pointer;
        }
      }
      .thread-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }
  .hri {
    display: flex;
    i {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 3px;
      font-size: 14px;
      cursor: pointer;
      // color: @activeColor;
    }
  }
}
.popover-search {
  // 筛选的pop弹出框
  max-height: 800px;
}
</style>
