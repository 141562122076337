<template>
  <div id="wechat-invoice-income">
    <div class="wechat-invoice-income-line">
      <div class="wechat-invoice-income-line-title">发票类型:</div>
      <div class="wechat-invoice-income-line-value">
        <el-radio-group
          v-model="value.type"
          @change="changeTitle"
        >
          <el-radio :label="'0'">电子普通发票</el-radio>
          <el-radio :label="'1'">电子专用发票</el-radio>
          <el-radio :label="'2'">纸质普通发票</el-radio>
          <el-radio :label="'3'">纸质专用发票</el-radio>
        </el-radio-group>
      </div>
    </div>

    <div class="wechat-invoice-income-line">
      <div class="wechat-invoice-income-line-title">购买方名称:</div>
      <div class="wechat-invoice-income-line-value">
        <el-input
          v-model="value.buyerName"
          placeholder="请输入内容"
        ></el-input>
      </div>
    </div>

    <div class="wechat-invoice-income-line">
      <div class="wechat-invoice-income-line-title">购买方税号:</div>
      <div class="wechat-invoice-income-line-value">
        <el-input
          v-model="value.buyerTaxpayerNum"
          placeholder="请输入内容"
        ></el-input>
      </div>
    </div>

    <div class="wechat-invoice-income-line">
      <div class="wechat-invoice-income-line-title">购买方地址:</div>
      <div class="wechat-invoice-income-line-value">
        <el-input
          v-model="value.buyerAddress"
          placeholder="请输入内容"
        ></el-input>
      </div>
    </div>

    <div class="wechat-invoice-income-line">
      <div class="wechat-invoice-income-line-title">购买方电话:</div>
      <div class="wechat-invoice-income-line-value">
        <el-input
          v-model="value.buyerTel"
          placeholder="请输入内容"
        ></el-input>
      </div>
    </div>

    <div class="wechat-invoice-income-line">
      <div class="wechat-invoice-income-line-title">购买方开户行:</div>
      <div class="wechat-invoice-income-line-value">
        <el-input
          v-model="value.buyerBankName"
          placeholder="请输入内容"
        ></el-input>
      </div>
    </div>

    <div class="wechat-invoice-income-line">
      <div class="wechat-invoice-income-line-title">购买方账号:</div>
      <div class="wechat-invoice-income-line-value">
        <el-input
          v-model="value.buyerBankAccount"
          placeholder="请输入内容"
        ></el-input>
      </div>
    </div>

    <el-divider></el-divider>

    <div class="wechat-invoice-income-line">
      <div class="wechat-invoice-income-line-title">是否含税:</div>
      <div class="wechat-invoice-income-line-value">
        <el-switch
          v-model="value.includeTaxFlag"
          @change="changeTaxs"
        >
        </el-switch>
      </div>
    </div>

    <div class="wechat-invoice-income-line">
      <div class="wechat-invoice-income-line-table">
        <div class="wechat-invoice-income-line-table-tr head">
          <div class="cell">货物或应税劳务、服务名称</div>
          <!-- <div class="cell">规格型号</div>
          <div class="cell">单位</div>
          <div class="cell">单价</div> -->
          <div class="cell">数量</div>
          <div class="cell">金额</div>
          <div class="cell">税率</div>
          <div class="cell">税额</div>
          <div class="cell">操作</div>
        </div>
        <div
          class="wechat-invoice-income-line-table-tr"
          v-for="(item, index) in value.itemList"
          :key="index"
        >
          <div class="cell">
            <el-input
              placeholder="请输入货物或应税劳务、服务名称"
              v-if="item.flag"
              v-model="item.goodsName"
              @blur="changeCode(item, index)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="searchCode(item, index)"
              ></el-button>
            </el-input>
            <span
              v-else
              @click="item.flag = !item.flag"
            >*{{ item.goodsType }}*{{ item.goodsName }}*</span>
          </div>
          <div class="cell">
            <el-input
              placeholder="请输入数量"
              v-model="item.quantity"
              @blur="changeAmount(item)"
            >
            </el-input>
          </div>
          <div class="cell">
            <el-input
              placeholder="请输入金额"
              v-model="item.invoiceAmount"
              @blur="changeTax(item)"
            >
            </el-input>
          </div>
          <div class="cell">
            <el-select
              v-model="item.taxRateValue"
              filterable
              placeholder="请选择"
              @change="changeTax(item)"
            >
              <el-option
                v-for="it in taxRateValueList"
                :key="it.value"
                :label="it.label"
                :value="it.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="cell">
            <el-input
              placeholder="请输入税额"
              v-model="item.taxRateAmount"
            >
            </el-input>
          </div>
          <div class="cell">
            <i
              class="el-icon-help"
              @click="settingGoods(item, index)"
            ></i>
            <i
              class="el-icon-remove-outline"
              @click="deleteItem(index)"
            ></i>
          </div>
        </div>
      </div>
    </div>

    <el-divider></el-divider>

    <div
      class="wechat-invoice-income-line"
      v-if="
        value.type == '0' ||
          value.type == 0 ||
          value.type == '1' ||
          value.type == 1
      "
    >
      <div class="wechat-invoice-income-line-title">收票人手机:</div>
      <div class="wechat-invoice-income-line-value">
        <el-input
          v-model="value.takerTel"
          placeholder="请输入内容"
        ></el-input>
      </div>
    </div>

    <div
      class="wechat-invoice-income-line"
      v-if="
        value.type == '0' ||
          value.type == 0 ||
          value.type == '1' ||
          value.type == 1
      "
    >
      <div class="wechat-invoice-income-line-title">收票人邮箱:</div>
      <div class="wechat-invoice-income-line-value">
        <el-input
          v-model="value.takerEmail"
          placeholder="请输入内容"
        ></el-input>
      </div>
    </div>

    <el-divider v-if="
        value.type == '0' ||
          value.type == 0 ||
          value.type == '1' ||
          value.type == 1
      "></el-divider>

    <div class="wechat-invoice-income-line">
      <div class="wechat-invoice-income-line-title">收款人:</div>
      <div class="wechat-invoice-income-line-value">
        <el-select
          v-model="value.casherName"
          filterable
          placeholder="请选择"
          value-key="id"
        >
          <el-option
            v-for="item in value.employeeList"
            :key="item.id"
            :label="item.name"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="wechat-invoice-income-line">
      <div class="wechat-invoice-income-line-title">复核人:</div>
      <div class="wechat-invoice-income-line-value">
        <el-select
          v-model="value.reviewerName"
          filterable
          placeholder="请选择"
          value-key="id"
        >
          <el-option
            v-for="item in value.employeeList"
            :key="item.id"
            :label="item.name"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="wechat-invoice-income-line">
      <div class="wechat-invoice-income-line-title">开票员:</div>
      <div class="wechat-invoice-income-line-value">
        <el-select
          v-model="value.drawerName"
          filterable
          placeholder="请选择"
          value-key="id"
        >
          <el-option
            v-for="item in value.employeeList"
            :key="item.id"
            :label="item.name"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="wechat-invoice-income-buts">
      <div
        class="wechat-invoice-income-line-but"
        @click="makeOutAnInvoice"
      >
        开票
      </div>
      <div
        class="wechat-invoice-income-line-but"
        @click="cancellationMakeOutAnInvoice"
      >
        取消
      </div>
    </div>
  </div>
</template>

<script>
import * as invoiceData from "../assets/js/invoiceData";
export default {
  props: ["wechatInvoiceIncomeData"],
  data() {
    return {
      value: this.wechatInvoiceIncomeData,
      // value: {
      //   type: "0",
      //   title: "安徽增值税电子普通发票", // 发票标题
      //   allMoney: {
      //     // 价税合计
      //     num: "15000.00", // 小写
      //     text: "壹万伍仟圆整", // 大写
      //   },
      //   buyerName: "", // 购买方名称
      //   buyerTaxpayerNum: "", // 购买方税号
      //   buyerAddress: "", // 购买方地址
      //   buyerTel: "", // 购买方电话
      //   buyerBankName: "", // 购买方开户行
      //   buyerBankAccount: "", // 购买方账号

      //   includeTaxFlag: true, // 是否含税  默认含税
      //   itemList: [
      //     {
      //       flag: true,
      //       goodsType: "", // 项目类别
      //       goodsName: "服务费", // 项目名称  等价于itemName
      //       taxClassificationCode: "", // 对应税收分类编码
      //       specificationModel: "", // 对应规格型号
      //       meteringUnit: "", // 单位
      //       quantity: "1", // 数量
      //       unitPrice: "7500.00", // 单价
      //       invoiceAmount: "7500.00", // 金额
      //       taxRateValue: "0", // 税率
      //       taxRateAmount: "0", // 税额 (税额 = 含税金额/(1+税率)*税率)

      //       discountAmount: "", // 折扣金额
      //       discountTaxRateAmount: "", // 折扣税额

      //       deductionAmount: "", // 差额开票抵扣金额
      //       preferentialPolicyFlag: "", // 优惠政策标识
      //       zeroTaxFlag: "", // 零税率标识
      //       vatSpecialManage: "", // 增值税特殊管理
      //     },
      //   ],

      //   takerTel: "", // 收票人手机
      //   takerEmail: "", // 收票人邮箱

      //   casherName: {}, // 收款人
      //   casherName: {}, // 复核人
      //   drawerName: {}, // 开票员

      //   employeeList: [],
      // },
      taxRateValueList: this.$taxRateValueList,
    };
  },
  watch: {
    wechatInvoiceIncomeData() {
      //父组件值变化->>子组件也变化
      this.value = this.wechatInvoiceIncomeData;
    },
    value(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {
    /**
     * 切换发票类型
     */
    changeTitle(e) {
      this.value.title = invoiceData.changeTitle(e);
      switch (e) {
        case "026":
          this.value.LimitAmount = this.value.commonTicketElectronicMoney; // 电子普票
          break;
        case "028":
          this.value.LimitAmount = this.value.specialTicketElectronicMoney; // 电子专票
          break;
        case "007":
          this.value.LimitAmount = this.value.commonTicketPaperMoney; // 纸质普票
          break;
        case "004":
          this.value.LimitAmount = this.value.specialTicketPaperMoney; //纸质专票
          break;
        default:
          break;
      }
    },
    /**
     * 是否含税选择框改变事件
     */
    changeTaxs(val) {
      this.value.itemList.forEach((element) => {
        this.changeAmountTax(element);
      });
    },
    /**
     * 保持税额不变,修改金额
     */
    changeAmountTax(data) {
      let nn = 0;
      if (this.value.includeTaxFlag) {
        nn =
          ((parseFloat(data.taxRateValue) + 1) *
            parseFloat(data.taxRateAmount)) /
          parseFloat(data.taxRateValue);
      } else {
        nn = parseFloat(data.taxRateAmount) / parseFloat(data.taxRateValue);
      }
      this.$cutOutNum.cutOutNum(nn, this, (num) => {
        data.invoiceAmount = num;
        this.sumAllMoney();
      });
    },
    /**
     * 选择货物编码
     */
    changeCode(data, index) {},
    /**
     * 搜索货物编码
     */
    searchCode(data, index) {},
    /**
     * 数量改变
     */
    changeAmount(data) {},
    /**
     * 金额或税率改变
     */
    changeTax(data) {
      let nn = 0;
      if (this.value.includeTaxFlag) {
        // 含税
        nn =
          (parseFloat(data.invoiceAmount) /
            (1 + parseFloat(data.taxRateValue))) *
          parseFloat(data.taxRateValue);
      } else {
        nn = parseFloat(data.invoiceAmount) * parseFloat(data.taxRateValue);
      }
      this.$cutOutNum.cutOutNum(nn, this, (num) => {
        data.taxRateAmount = num;
        this.sumAllMoney();
      });
    },
    /**
     * 计算价税合计总金额
     */
    sumAllMoney() {
      let money = 0;
      let tax = 0;
      this.value.itemList.forEach((element) => {
        money += parseFloat(element.invoiceAmount);
        tax += parseFloat(element.taxRateAmount);
      });
      this.$cutOutNum.cutOutNum(money, this, (num) => {
        this.allMoney = num;
        this.$cutOutNum.cutOutNum(tax, this, (tax) => {
          this.allTax = tax;
          // 计算价税合计
          if (this.value.includeTaxFlag) {
            // 含税
            // 价税总额 = 总金额
            this.value.allMoney.num = this.allMoney;
            this.value.allMoney.text = this.$money.required(this.allMoney);
          } else {
            // 不含税
            // 价税总额 = 总金额 + 总税额
            this.$cutOutNum.cutOutNum(
              parseFloat(this.allMoney) + parseFloat(this.allTax),
              this,
              (allNum) => {
                this.value.allMoney.num = allNum;
                this.value.allMoney.text = this.$money.required(allNum);
              }
            );
          }
        });
      });
    },
    /**
     *
     */
    settingGoods(data, index) {},
    /**
     * 删除货物
     */
    deleteItem(index) {},
    /**
     * 确认开票
     */
    makeOutAnInvoice() {
      this.$emit("makeOutAnInvoice");
    },
    /**
     * 取消开票
     */
    cancellationMakeOutAnInvoice() {
      this.$emit("cancellationMakeOutAnInvoice");
    },
  },
};
</script>

<style lang="less" scoped>
#wechat-invoice-income {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .wechat-invoice-income-line,
  .wechat-invoice-income-buts {
    width: 100%;
    height: auto;
    min-height: 30px;
    display: flex;
    align-items: center;
    margin-top: 5px;
    .wechat-invoice-income-line-title {
      width: 22%;
      height: 100%;
      font-size: 2.5vw;
    }
    .wechat-invoice-income-line-value {
      width: 0;
      flex: 1;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-right: 15px;
      .el-radio-group {
        display: flex;
        flex-wrap: wrap;
        font-size: 2.5vw;
        .el-radio {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: 30px;
          font-size: 2.5vw !important;
          .el-radio__label {
            font-size: 2.5vw !important;
          }
        }
      }
    }
    .wechat-invoice-income-line-table {
      width: 100%;
      height: auto;
      max-height: 160px;
      display: flex;
      flex-direction: column;
      font-size: 2vw; // 货物表格字号
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 1px;
      }
      .wechat-invoice-income-line-table-tr {
        width: 100%;
        height: 25px;
        min-height: 25px;
        // height: auto;
        display: flex;
        align-items: center;
        border-bottom: 0.3px solid @boderColor;
        border-left: 0.3px solid @boderColor;
        border-right: 0.3px solid @boderColor;
        box-sizing: border-box;
        .cell {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          font-size: 2vw; // 货物表格字号

          border-left: 0.3px solid @boderColor;
          box-sizing: border-box;
          &:nth-child(1) {
            flex: 2;
            border: none;
          }
          i {
            margin-left: 3px;
            cursor: pointer;
            transition: 0.3s;
            &:hover {
              color: @activeColor;
            }
          }
        }
      }
      .head {
        background-color: @activeBackground;
        border-top: 0.3px solid @boderColor;
      }
    }
  }
  .wechat-invoice-income-buts {
    justify-content: center;
    margin: 10px 0;
    .wechat-invoice-income-line-but {
      width: auto;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 15px;
      box-sizing: border-box;
      border-radius: 5px;
      margin: 0 10px;
      &:hover {
        cursor: pointer;
      }
      &:nth-child(1) {
        background-color: @activeColor;
        color: #fff;
      }
      &:nth-child(2) {
        background-color: @activeBackground3;
        color: @activeColor;
      }
    }
  }
}
</style>
