import Vue from 'vue'
/**
 * 获取人员列表
 */
export function getTableData() {
    let data = [{
        userId: "",
        userName: "",
        phone: "13211112222", // 手机号
        department: [ // 所属部门,没有返回空数组
            { departmentId: "", departmentName: "会计一部" },
            { departmentId: "", departmentName: "会计二部" },
        ],
        role: [ // 所拥有的角色,没有返回空数组
            { roleId: "", roleName: "角色一" },
            { roleId: "", roleName: "角色二" },
        ],
    }]
    return data
}

/**
 * 新建人员dialog数据
 */
export function createDialogData(departmentList) {
    let data = [
        { title: "姓名", type: "input", value: "", key: "userName" },
        {
            title: "部门",
            type: "cascader",
            locationId: "",
            options: departmentList,
            props: { checkStrictly: true, value: 'id', emitPath: false },
            key: "departmentId"
        },
        { title: "职位", type: "input", value: "", key: "position" },
        { title: "手机号", type: "input", value: "", key: "phone" },
        { title: "入职时间", type: "time", value: "", key: "hiredate" },
        { title: "身份证", type: "input", value: "", key: "identity" },
        {
            title: "状态",
            type: "select",
            value: "1",
            options: [{ label: "在职", value: "1" }],
            key: "state"
        },
        {
            title: "学历",
            type: "select",
            value: "1",
            options: [{ label: "本科以上学历", value: "0" },
                { label: "本科", value: "1" },
                { label: "大专", value: "2" },
                { label: "中专或高中", value: "3" },
                { label: "其他", value: "4" },
            ],
            key: "education"
        },
        {
            title: "合同附件",
            type: "upload",
            value: {
                value: "",
                action: Vue.prototype.$fileBaseurl + "/uploadImageByMultipartFile",
                headers: {
                    token: "95931b371eb64f4a9c862983ca8a5d15",
                },
                uploadParameter: {
                    systemName: "finance",
                    title: "合同附件",
                }
            },
            key: "contractFile"
        },
        {
            title: "合同类型",
            type: "radio",
            value: "1",
            options: [{ label: "正式合同", value: "1" },
                { label: "实习合同", value: "0" },
            ],
            key: "contractType"
        },
        { title: "合同期限", type: "input", value: "", key: "time" },
        { title: "基本工资", type: "input", value: "", key: "basicSalary" },
    ]
    return data
}
/**
 * 修改员工信息dialog数据
 */
export function uodateDialogData() {
    let data = [
        { title: "姓名", type: "input", value: "", key: "userName" },
        { title: "职位", type: "input", value: "", key: "position" },
        { title: "手机号", type: "input", value: "", key: "phone" },
        { title: "主叫号", type: "input", value: "", key: "callNumber" },
        { title: "中间号", type: "input", value: "", key: "middleNumber" },
        { title: "入职时间", type: "time", value: "", key: "hiredate" },
        { title: "身份证", type: "input", value: "", key: "identity" },
        {
            title: "状态",
            type: "select",
            value: 0,
            options: [
                { label: "在职", value: 1 }
        ],
            key: "state"
        },
        {
            title: "学历",
            type: "select",
            value: "1",
            options: [{ label: "本科以上学历", value: "0" },
                { label: "本科", value: "1" },
                { label: "大专", value: "2" },
                { label: "中专或高中", value: "3" },
                { label: "其他", value: "4" },
            ],
            key: "education"
        }
    ]
    return data
}

/**
 * 抽屉数据
 */
export function employeeDrawerData() {
    let data = {
            title: "用户资料",
            flag: false,
            // buttonList: [{
            //     text: "记单修改",
            //     icon: "el-icon-s-ticket",
            //     active: "results_point_to_modify",
            //     authority: 1,
            //     key: "updateRecorder"
            // }, ],
            hasSetting: true,
            status: "在职",
            settingOperList: [{
                text: "离职并交接",
                active: "departure-transfer",
                authority: 1,
                key: "departure"
            }, ],
            data: {
                title: "用户资料",
                butArray: [{
                    text: "删除",
                    active: "delete",
                    authority: 1,
                    key: "delete"
                }],
                customer: 0, // 关联客户,与商机状态关联,(只有商机状态为成交才有值),其他时候为空字符串
                isDisable: true, // 是否只读
                modelList: [{
                        line: [{
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                title: "姓名",
                                type: "text",
                                value: "",
                                key: "userName"
                            },
                            {
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                title: "手机号",
                                type: "text",
                                value: "",
                                key: "phone"
                            },
                            {
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                title: "主叫号",
                                type: "text",
                                value: "",
                                key: "callNumber"
                            },
                            {
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                title: "中间号",
                                type: "text",
                                value: "",
                                key: "middleNumber"
                            },
                            {
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                title: "性别",
                                type: "radio",
                                value: "男",
                                options: [
                                    { label: "男", text: "男" },
                                    { label: "女", text: "女" },
                                ],
                                key: "userName"
                            },
                            {
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                title: "身份证号",
                                type: "text",
                                value: "",
                                key: "identity"
                            },
                            {
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                title: "所属部门",
                                type: "text",
                                value: "",
                                key: "department"
                            },
                            {
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                title: "角色",
                                type: "text",
                                value: "",
                                key: "role"
                            },
                            {
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                title: "学历",
                                type: "text",
                                value: "",
                                key: "education"
                            },
                            {
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                title: "状态",
                                type: "text",
                                value: "",
                                key: "state"
                            },
                            {
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                title: "本月记单",
                                type: "text",
                                value: "",
                                key: "achievement"
                            },
                            {
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                title: "入职时间",
                                type: "time",
                                value: "",
                                key: "hiredate"
                            },
                            {
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                title: "离职时间",
                                type: "text",
                                value: "",
                                key: "dimission"
                            },
                        ],
                    },
                    //     {
                    //     line: [{
                    //         horizontal: true,
                    //         isShow: true,
                    //         isDisable: false,
                    //         title: "合同附件",
                    //         type: "file",
                    //         value: "",
                    //         key: "file"
                    //     }]
                    // }
                ],
            },
        }
        // data = Vue.prototype.$initDrawerAuthorityData.initDrawerAuthorityData(data, authorityData, isHead)
    return data
}

export function returnDrawerKeys() {
    let keys = []
    let drawerData = employeeDrawerData().data.modelList
    for (let index = 0; index < drawerData.length; index++) {
        let element = drawerData[index].line;
        for (let i = 0; i < element.length; i++) {
            let ele = element[i];
            if (ele.hasOwnProperty('key')) {
                let dd = {
                    name: ele.text,
                    key: ele.key,
                    authority: "1",
                    sequence: 1,
                    id: ""
                }
                keys.push(JSON.parse(JSON.stringify(dd)))
            }
        }

    }
    return keys
}

/**
 * 格式化学历
 * @param {*} array 
 */
export function formatRecordFormalSchool(str) {
    let education = ""
    switch (str) {
        case 0:
        case "0":
            education = "本科以上学历"
            break;
        case 1:
        case "1":
            education = "本科"
            break;
        case 2:
        case "2":
            education = "大专"
            break;
        case 3:
        case "3":
            education = "中专或高中"
            break;
        case 4:
        case "4":
            education = "其他"
            break;

        default:
            break;
    }
    return education
}

/**
 * 筛选掉离职员工
 */
export function filtrateDimissionEmployeeList(array) {
    let data = []
    array.forEach(element => {
        if (element.state == "1" || element.state == 1) {
            data.push(element)
        }
    });
    return data
}