<template>
  <div id="client-setting">
    <!-- 客户设置 -->
    <div class="screen">
      <div class="screen-line">
        <div class="client-setting-screen-tabs">
          <template v-for="(item, index) in tabList">
            <div
              :class="{
                'client-setting-screen-tab': true,
                'is-active': tabIndex == item.text,
              }"
              :key="index"
              v-showFunction="{
                active: item.active,
                array: allFunctionButtons,
              }"
              @click="changeTab(item, index)"
            >
              {{ item.text }}
            </div>
          </template>

          <div class="client-setting-screen-search">
            <el-select
              v-model="searchData.userId"
              placeholder="请选择客户负责人"
              @change="seleteInitTableData"
              filterable
            >
              <el-option
                v-for="item in searchData.userList"
                :key="item.userId"
                :label="item.userName"
                :value="item.userId"
              >
              </el-option>
            </el-select>
          </div>
          <div class="client-setting-screen-search">
            <el-cascader
              v-model="searchData.department"
              placeholder="请选择部门"
              :options="searchData.departTreeData"
              @change="changeSearch"
              clearable
              :show-all-levels="false"
              :props="{ checkStrictly: true, value: 'id', emitPath: false }"
              filterable
            ></el-cascader>
          </div>
          <div class="client-setting-screen-search">
            <el-input
              placeholder="请输入客户全称/编号/税号"
              v-model="searchData.input"
              @keyup.enter.native="changeSearch"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click.native.stop="changeSearch"
              ></el-button>
            </el-input>
          </div>
        </div>
      </div>
    </div>
    <div class="table-content">
      <div class="client-table-card" v-loading="loading">
        <div class="client-table-card-content" v-if="tabIndex == '选项值设置'">
          <div class="client-table-card-content-tags-setting">
            <div class="client-table-card-content-tags-setting-left">
              <div
                :class="{
                  'client-table-card-content-tags-setting-left-line': true,
                  'is-active': index == tagsIndex,
                }"
                v-for="(item, index) in optionValueList1"
                :key="index"
                @click="tagsIndex = index"
              >
                {{ item.name }}
              </div>
            </div>
            <div
              class="client-table-card-content-tags-setting-right"
              v-if="optionValueList1"
            >
              <div class="client-table-card-content-tags-setting-right-add">
                <div
                  class="butt"
                  @click="addTags(1, -1)"
                  v-if="
                    optionValueList1[tagsIndex].name !== '客户来源' &&
                      optionValueList1[tagsIndex].name !== '联系人权限'
                  "
                  v-showFunction="{
                    active: 'addOption',
                    array: allFunctionButtons,
                  }"
                >
                  添加选项
                </div>
                <div
                  class="butt"
                  @click="addTags(2, -1)"
                  v-if="optionValueList1[tagsIndex].name == '客户来源'"
                  v-showFunction="{
                    active: 'addSource',
                    array: allFunctionButtons,
                  }"
                >
                  添加来源
                </div>
              </div>
              <div
                class="client-table-card-content-tags-setting-right-contacts"
                v-if="optionValueList1[tagsIndex].name == '联系人权限'"
              >
                <div class="client-table-card-content-tags-setting-right-line">
                  <div>业务角色</div>
                  <div style="width: 30%">启用</div>
                </div>
                <div
                  class="client-table-card-content-tags-setting-right-line"
                  v-for="item in systemInfo"
                  :key="item.label"
                >
                  <div>{{ item.label }}</div>
                  <div style="width: 30%">
                    <el-switch
                      v-model="item.value"
                      @change="changeSwitch(item)"
                    ></el-switch>
                  </div>
                </div>
              </div>
              <div
                class="client-table-card-content-tags-setting-right-list"
                v-if="
                  optionValueList1[tagsIndex].value.length == 0 &&
                    optionValueList1[tagsIndex].name !== '联系人权限'
                "
              >
                没有相关数据!
              </div>
              <div
                class="client-table-card-content-tags-setting-right-list"
                v-if="
                  optionValueList1[tagsIndex].name != '客户来源' &&
                    optionValueList1[tagsIndex].value.length > 0
                "
              >
                <div
                  class="client-table-card-content-tags-setting-right-line"
                  v-for="(item, index) in optionValueList1[tagsIndex].value"
                  :key="index"
                >
                  <div
                    v-if="item.flag"
                    @click="
                      tabList[0].flag == 2 ? '' : (item.flag = !item.flag)
                    "
                  >
                    {{ index + 1 }}、{{ item.name }}
                  </div>
                  <div v-else>
                    {{ index + 1 }}、
                    <el-input
                      v-model="item.name"
                      placeholder="请输入内容"
                      @blur="addTags(3, index)"
                      @keyup.enter.native="addTags(7, index)"
                      :disabled="tabList[0].flag == 2"
                    ></el-input>
                  </div>
                </div>
              </div>
              <div
                class="client-table-card-content-tags-setting-right-list"
                v-if="
                  optionValueList1[tagsIndex].name == '客户来源' &&
                    optionValueList1[tagsIndex].value.length > 0
                "
              >
                <div class="client-table-card-content-tags-setting-right-line">
                  <div></div>
                  <div>来源</div>
                  <div>二级来源</div>
                  <div>启动</div>
                  <div>操作</div>
                </div>
                <div
                  class="client-table-card-content-tags-setting-right-line"
                  v-for="(item, index) in optionValueList1[tagsIndex].value"
                  :key="index"
                >
                  <div>{{ index + 1 }}、</div>
                  <div
                    v-if="item.flag"
                    @click="
                      tabList[0].flag == 2 ? '' : (item.flag = !item.flag)
                    "
                  >
                    {{ item.name }}
                  </div>
                  <div v-else>
                    <el-input
                      v-model="item.name"
                      placeholder="请输入内容"
                      @blur="addTags(4, index)"
                      @keyup.enter.native="addTags(8, index)"
                      :disabled="tabList[0].flag == 2"
                    ></el-input>
                  </div>
                  <div v-if="item.flag">
                    <el-input
                      v-model="item.value[1]"
                      placeholder="请输入内容"
                      @blur="addTags(5, index)"
                      @keyup.enter.native="addTags(9, index)"
                      :disabled="tabList[0].flag == 2"
                    ></el-input>
                  </div>
                  <div v-else>{{ item.value[1] }}</div>
                  <div>
                    <el-switch
                      v-model="item.active"
                      @change="addTags(5, index)"
                      :disabled="tabList[0].flag == 2"
                    >
                    </el-switch>
                  </div>
                  <div>
                    <el-button
                      type="danger"
                      plain
                      size="small"
                      @click="addTags(6, index)"
                      :disabled="tabList[0].flag == 2"
                      ><i class="el-icon-delete"></i>删除</el-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="client-table-card-content"
          v-else-if="tabIndex == '客户编号设置'"
          v-showFunction="{
            active: 'settingMobile',
            array: allFunctionButtons,
          }"
        >
          <el-table
            v-if="clientList3"
            :data="clientList3"
            border
            style="width: 100%"
          >
            <el-table-column align="center" type="index" width="55">
            </el-table-column>
            <el-table-column prop="label" label="客户编号" align="center">
            </el-table-column>
            <el-table-column prop="status" label="状态" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.enable == '0'" class="dangerColor"
                  >禁用</span
                >
                <span v-if="scope.row.enable == '1'" class="brandColor"
                  >启用</span
                >
              </template>
            </el-table-column>

            <el-table-column label="操作" align="center" width="200">
              <template slot="header">
                <el-button
                  type="text"
                  size="small"
                  icon="el-icon-circle-plus-outline"
                  @click="handleClick('', '1')"
                  v-showFunction="{
                    active: 'addClientNumber',
                    array: allFunctionButtons,
                  }"
                  >添加</el-button
                >
              </template>
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.enable == '0'"
                  type="primary"
                  size="mini"
                  @click="handleClick(scope.row, '2')"
                  v-showFunction="{
                    active: 'enableClientNumber',
                    array: allFunctionButtons,
                  }"
                  >启用</el-button
                >
                <el-button
                  v-if="scope.row.enable == '1'"
                  type="primary"
                  size="mini"
                  @click="handleClick(scope.row, '3')"
                  v-showFunction="{
                    active: 'disableClientNumber',
                    array: allFunctionButtons,
                  }"
                  >禁用</el-button
                >
                <el-button
                  type="danger"
                  size="mini"
                  @click="handleClick(scope.row, '4')"
                  v-showFunction="{
                    active: 'deleteClientNumber',
                    array: allFunctionButtons,
                  }"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div
          class="client-table-card-content"
          v-else-if="tabIndex == '绑定企业微信客户'"
        >
          <el-table
            v-if="clientList1"
            :data="clientList1"
            border
            style="width: 100%"
          >
            <el-table-column align="center" type="index" width="55">
            </el-table-column>
            <el-table-column prop="clientName" label="客户全称" align="center">
            </el-table-column>
            <el-table-column prop="clientNum" label="客户编号" align="center">
            </el-table-column>
            <el-table-column prop="manager" label="客户经理" align="center">
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  @click="bindWechatClient(scope.row, 0)"
                  v-showFunction="{
                    active: 'bindingClient',
                    array: allFunctionButtons,
                  }"
                  >绑定客户</el-button
                >
                <el-button
                  type="primary"
                  size="mini"
                  @click="bindWechatClient(scope.row, 2)"
                  v-showFunction="{
                    active: 'bindingGroup',
                    array: allFunctionButtons,
                  }"
                  >绑定群</el-button
                >
                <el-button
                  type="danger"
                  size="mini"
                  @click="bindWechatClient(scope.row, 1)"
                  v-showFunction="{
                    active: 'removeClient',
                    array: allFunctionButtons,
                  }"
                  >客户解绑</el-button
                >
                <el-button
                  type="danger"
                  size="mini"
                  @click="bindWechatClient(scope.row, 3)"
                  v-showFunction="{
                    active: 'removeGroup',
                    array: allFunctionButtons,
                  }"
                  >群解绑</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div
          class="client-table-card-content"
          v-else-if="tabIndex == '开通移动端'"
          v-showFunction="{
            active: 'settingMobile',
            array: allFunctionButtons,
          }"
        >
          <el-table
            v-if="clientList2"
            :data="clientList2"
            border
            style="width: 100%"
          >
            <el-table-column align="center" type="index" width="55">
            </el-table-column>
            <el-table-column prop="clientName" label="客户全称" align="center">
            </el-table-column>
            <el-table-column label="开通人" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.account && scope.row.account.length > 0">
                  {{ scope.row.account[0].name }}
                </div>
                <div v-else>---</div>
              </template>
            </el-table-column>
            <el-table-column label="开通时间" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.account && scope.row.account.length > 0">
                  {{ scope.row.account[0].time }}
                </div>
                <div v-else>---</div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  @click="bindWechatClient(scope.row, 4)"
                  v-showFunction="{
                    active: 'open',
                    array: allFunctionButtons,
                  }"
                  >开通</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div
          class="client_connect-table-card-bottom"
          v-if="
            tabIndex == '客户编号设置' ||
              tabIndex == '绑定企业微信客户' ||
              tabIndex == '开通移动端'
          "
        >
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="10"
            layout="total, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @bindWechatClient="bindWechatClientDialog"
        @searchData="searchWechatData"
        @bindWechatGroupClient="bindWechatGroupClient"
        @showBindClient="showBindClient"
        @showBindClientGroup="showBindClientGroup"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import * as baseData from "@/assets/js/baseData.js";
import * as systemData from "@/assets/js/systemAuthorityData.js";
import my_dialog from "@/components/dialog.vue";
import * as allClientData from "./js/clientData.js";
export default {
  components: {
    draggable,
    my_dialog,
  },
  data() {
    return {
      systemInfo: [],
      baseInfo: {},
      loading: true,
      tabIndex: "选项值设置",
      whetherAgencyHead: false,
      tagsIndex: 0,
      searchData: {
        input: "",
        userId: "", //
        userList: [],
        tempUserId: "",
        departTreeData: [],
        department: "",
      },
      tabList: [],
      optionValueList1: [{ name: "", value: [{ name: "", value: "" }] }], // 选项值设置
      optionValueList2: [{ name: "", value: [{ name: "", value: "" }] }], // 流程设置
      optionValueList3: [{ name: "", value: [{ name: "", value: "" }] }], // 权限设置
      tempTagList: [],
      dialogData: {
        flag: false,
        width: "30%",
        title: "添加跟进记录",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },
      clientList1: [],
      clientList2: [],
      clientList3: [],
      wechatClientList: [],
      wechatClientGroupList: [],
      tempClient: {},
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      tempAllEmpolyeeList: [],

      search_client: "",
      search_client_group: "",
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "clientSetting"
      ),
      contactSettingId: "", // 联系人设置id
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "clientSetting",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.tabList = [
            {
              text: "选项值设置",
              active: "settingOption",
              flag: 0,
            },
            {
              text: "客户编号设置",
              active: "clientNumber",
              flag: 0,
            },
            {
              text: "绑定企业微信客户",
              active: "settingweixin",
              flag: 0,
            },
            {
              text: "开通移动端",
              active: "settingMobile",
              flag: 0,
            },
          ];
          this.getSystemInfo();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getEmployeeListByUserId();
        this.getTagList();
      });
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeListByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.searchData.userList = data;
          this.tempAllEmpolyeeList = [];
          data.forEach((element) => {
            this.tempAllEmpolyeeList.push(element.userId);
          });
          this.searchData.userList.splice(0, 0, {
            userId: "all",
            userName: "全部",
          });
          this.getDepartmentTreeByUserId();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        // "/setting/getDepartmentTreeByUserId",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
          // this.getBusinessAuthorityList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取当前用户的所有企业微信客户
     */
    getALlWechatClientList() {
      if (this.dialogData.flag) {
        this.dialogData.data[0].value.tableData = [];
      }
      let requestData = {
        userId: this.baseInfo.userId,
        search: this.search_client,
      };
      this.$request(
        "post",
        "/client/getALlWechatClientList",
        requestData,
        (data) => {
          this.wechatClientList = data;
          if (this.dialogData.flag) {
            this.dialogData.data[0].value.tableData = data;
          }
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },

    /**
     * 获取当前用户的所有企业微信客户
     */
    getALlWechatGroupClientList() {
      if (this.dialogData.flag) {
        this.dialogData.data[0].value.tableData = [];
      }
      let requestData = {
        userId: this.baseInfo.userId,
        search: this.search_client_group,
      };
      this.$request(
        "post",
        "/client/getALlWechatGroupClientList",
        requestData,
        (data) => {
          this.wechatClientGroupList = data;
          if (this.dialogData.flag) {
            this.dialogData.data[0].value.tableData = data;
          }
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    showBindClient(val) {
      this.dialogData.data[0].value.tableData = val;
    },
    showBindClientGroup(val) {
      this.dialogData.data[0].value.tableData = val;
    },
    searchWechatData(data) {
      console.log("企业微信模糊搜索data:", data);
      let that = this;
      switch (data.flag) {
        case 0:
          // 企业微信客户搜索
          this.search_client = data.value;
          this.getALlWechatClientList();
          break;
        case 1:
          // 企业微信客户群搜索
          this.search_client_group = data.value;
          this.getALlWechatGroupClientList();
          break;

        default:
          break;
      }
    },
    /**
     * 获取选项值标签
     */
    getTagList() {
      let requestData = {
        // model: "client",
        type: [
          "客户标签",
          "片区",
          "客户等级",
          "流失原因",
          "客户来源",
          "资质类型",
          "联系人权限",
        ],
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/getTagList",
        requestData,
        (data) => {
          this.tempTagList = [];
          this.optionValueList1 = [];

          if (data.length == 0) {
            // 初次获取该模块标签,读取配置文件
            requestData.type.forEach((element) => {
              this.tempTagList.push(baseData.getTagList(element, "client"));
            });
            // this.optionValueList1 = this.tempTagList;
            baseData.initTagList(this.tempTagList, this.optionValueList1);
            // this.updateTagList(this.tempTagList);
          } else {
            this.tempTagList = data;
            if (data.length != requestData.type.length) {
              baseData
                .initTagListPart(data, requestData.type)
                .forEach((element) => {
                  this.tempTagList.push(baseData.getTagList(element, "client"));
                });
            }
            baseData.initTagList(this.tempTagList, this.optionValueList1);
          }

          console.log("当前模块标签:", this.optionValueList1);
          this.getALlWechatClientList();
          this.getALlWechatGroupClientList();
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 修改标签
     */
    updateTagList(array) {
      let requestData = {
        list: array,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/updateTagList",
        requestData,
        (data) => {
          this.getTagList();
          this.$message({
            message: "修改成功!",
            type: "success",
            duration: 1000,
          });
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.getTagList();
        }
      );
    },
    /**
     * 添加标签选项
     */
    addTags(flag, index) {
      switch (flag) {
        case 1:
          //  添加一般选项,即添加一个输入框
          let dd = {
            name: "",
            value: "",
            flag: false,
          };
          this.optionValueList1[this.tagsIndex].value.push(dd);
          break;
        case 2:
          //  添加表格选项,即添加一行表格数据
          console.log("添加选项", this.optionValueList1[this.tagsIndex]);
          let d = {
            name: "",
            value: ["", ""],
            flag: false,
            active: false,
          };
          this.optionValueList1[this.tagsIndex].value.push(d);
          break;
        case 3:
          //  输入框失去焦点事件
          console.log("添加选项", this.optionValueList1[this.tagsIndex]);
          this.optionValueList1[this.tagsIndex].value[index].flag = true;
          if (this.optionValueList1[this.tagsIndex].value[index].name != "") {
            this.optionValueList1[this.tagsIndex].value[
              index
            ].value = this.optionValueList1[this.tagsIndex].value[index].name;
          } else {
            this.optionValueList1[this.tagsIndex].value.splice(index, 1);
          }
          this.updateTagList(this.tempTagList);
          break;
        case 7:
          //  输入框失去焦点事件
          console.log("添加选项", this.optionValueList1[this.tagsIndex]);
          this.optionValueList1[this.tagsIndex].value[index].flag = true;

          break;
        case 4:
          console.log("添加来源", this.optionValueList1[this.tagsIndex]);
          if (this.optionValueList1[this.tagsIndex].value[index].name != "") {
            this.optionValueList1[this.tagsIndex].value[
              index
            ].value[0] = this.optionValueList1[this.tagsIndex].value[
              index
            ].name;
            this.optionValueList1[this.tagsIndex].value[index].flag = true;
            this.updateTagList(this.tempTagList);
          } else {
            this.$message.error("一级来源不能为空!");
          }
          break;
        case 8:
          console.log("添加来源", this.optionValueList1[this.tagsIndex]);

          this.optionValueList1[this.tagsIndex].value[index].flag = true;

          break;
        case 5:
          this.updateTagList(this.tempTagList);
          break;
        case 9:
          // this.updateTagList(this.tempTagList);
          break;
        case 6:
          this.$confirm("此操作将永久删除该来源, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.optionValueList1[this.tagsIndex].value.splice(index, 1);
              this.updateTagList(this.tempTagList);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
          break;
        default:
          break;
      }
    },
    /**
     * 切换tab
     */
    changeTab(data, index) {
      this.loading = true;
      this.tabIndex = data.text;
      this.clientList1 = [];
      this.clientList2 = [];
      this.pageData.currentPage = 1;
      console.log("this.allFunctionButtons", this.allFunctionButtons);
      switch (index) {
        case 0:
          // 选项值设置
          this.Loading();
          this.getTagList();
          break;
        case 1:
          // 客户编号设置
          this.Loading();
          this.getClientNumberList();
          break;
        case 2:
          // 企业微信客户设置
          this.Loading();
          this.getAllClientByUserId();
          break;
        case 3:
          // 流程设置
          this.Loading();
          // this.clientList2 = [
          //   {
          //     id: "",
          //     clientName: "aa", // 客户名称
          //     account: [
          //       {
          //         // 该客户的所有开通人
          //         name: "aa", // 开通人姓名
          //         time: "aa", // 开通时间
          //       },
          //     ],
          //   },
          //   {
          //     id: "",
          //     clientName: "aa", // 客户名称
          //     account: [],
          //   },
          // ];
          this.getAllClientsAppletAccount();
          break;
        case 4:
          // 联系人设置
          this.Loading();
          // 权限设置
          // this.tabIndex = "选项值设置";
          // this.$message.error("功能尚未开放!");
          // this.changeTab({ text: "选项值设置" }, 0);
          break;
        default:
          break;
      }
    },
    /**
     * 获取当前员工对应所有客户
     */
    getAllClientByUserId() {
      this.clientList1 = [];
      let requestData = {
        userId: this.searchData.tempUserId,
        companyId: this.baseInfo.company.companyId,
        type: "全部客户", // 与业务角色对应(对应关系暂定 20210618)
        hasLeave: "0", // 是否包含流失,"0"为否,"1"为是
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
        search: this.searchData.input, // 客户全称/编号/税号筛选客户
        department: this.searchData.department, // 部门id
        sortProp: "followTime",
        sortOrder: "descending",
        screenList: [],
      };
      this.$request(
        "post",
        "/client/getAllClientByUserId",
        requestData,
        (data) => {
          this.pageData.total = data.total;
          this.clientList1 = data.tableData;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有客户,用于开通移动端
     */
    getAllClientsAppletAccount() {
      this.clientList2 = [];
      let requestData = {
        userId: this.searchData.tempUserId,
        companyId: this.baseInfo.company.companyId,
        type: "全部客户", // 与业务角色对应(对应关系暂定 20210618)
        hasLeave: "0", // 是否包含流失,"0"为否,"1"为是
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
        search: this.searchData.input, // 客户全称/编号/税号筛选客户
        department: this.searchData.department, // 部门id
      };
      this.$request(
        "post",
        "/applet/getAllClientsAppletAccount",
        requestData,
        (data) => {
          this.pageData.total = data.total;
          this.clientList2 = data.tableData;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取客户编号列表
     */
    getClientNumberList() {
      let requestData = {
        search: this.searchData.input, // 客户全称/编号/税号筛选客户
        mark: "clientNumber",
        enable: "",
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
      };

      this.$request(
        "post",
        "/dataDictionary/page",
        requestData,
        (data) => {
          this.pageData.total = data.total;
          this.clientList3 = data.records;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    seleteInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.tempAllEmpolyeeList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.changeSearch();
    },
    changeSearch() {
      if (this.tabIndex == "客户编号设置") {
        this.getClientNumberList();
      }
      if (this.tabIndex == "绑定企业微信客户") {
        this.getAllClientByUserId();
      }
      if (this.tabIndex == "开通移动端") {
        this.getAllClientsAppletAccount();
      }
    },
    /**
     * 给客户绑定企业微信客户
     */
    bindWechatClient(data, flag) {
      this.tempClient = JSON.parse(JSON.stringify(data));
      switch (flag) {
        case 0:
          // 给客户绑定企业微信
          this.dialogData.width = "40%";
          this.dialogData.title = "绑定企业微信客户";
          this.dialogData.buttonArray = [
            // {
            //   text: "确定",
            //   active: "sure_bind",
            // },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              title: "",
              type: "bind_client_table",
              value: {
                clientName: data.clientName,
                tableData: this.wechatClientList,
              },
            },
          ];
          this.dialogData.flag = true;
          break;
        case 2:
          // 给客户绑定企业微信群
          this.dialogData.width = "40%";
          this.dialogData.title = "绑定企业微信客户群";
          this.dialogData.buttonArray = [
            // {
            //   text: "确定",
            //   active: "sure_bind_group",
            // },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              title: "",
              type: "bind_client_group_table",
              value: {
                clientName: data.clientName,
                tableData: this.wechatClientGroupList,
              },
            },
          ];
          this.dialogData.flag = true;
          break;
        case 1:
          // 解除企业微信的绑定
          this.$confirm("此操作将彻底解除绑定企业微信客户, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$request(
                "post",
                "/client/unbundlClientWeChat",
                { clientId: data.id },
                (data) => {
                  this.$message({
                    message: "客户企业微信解绑成功!",
                    type: "success",
                    duration: 1000,
                  });
                  this.getALlWechatClientList();
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消解绑",
                duration: 1000,
              });
            });

          break;
        case 3:
          // 解除企业微信的绑定
          this.$confirm(
            "此操作将彻底解除绑定企业微信客户群, 是否继续?",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              this.$request(
                "post",
                "/client/unbundlClientWeChatGroup",
                { clientId: data.id },
                (data) => {
                  this.$message({
                    message: "客户企业微信群解绑成功!",
                    type: "success",
                    duration: 1000,
                  });
                  this.getALlWechatGroupClientList();
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消解绑",
                duration: 1000,
              });
            });

          break;
        case 4:
          // 开通客户移动端
          this.dialogData.width = "40%";
          this.dialogData.title = "开通移动端小程序-" + data.clientName;
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "sure_bind_applet",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              title: "",
              type: "bind_client_applet",
              value: this.tempClient.account,
            },
          ];
          this.dialogData.flag = true;
          break;
        default:
          break;
      }
    },
    bindWechatClientDialog(data) {
      let requestData = {
        userId: this.baseInfo.userId, // 当前登录者ID
        clientId: this.tempClient.id,
        wechatUserId: data.externalUserId,
        wechatEmployeeId: data.wechatEmployeeId,
      };
      this.$request(
        "post",
        "/client/bindClientWeChat",
        requestData,
        (data) => {
          this.dialogData.flag = false;
          this.getALlWechatClientList();
          this.$message({
            message: "客户企业微信绑定成功!",
            type: "success",
            duration: 1000,
          });
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 绑定企业微信群
     */
    bindWechatGroupClient(data) {
      let requestData = {
        userId: this.baseInfo.userId, // 当前登录者ID
        clientId: this.tempClient.id,
        wechatGroupId: data.id,
      };
      this.$request(
        "post",
        "/client/bindClientWechatGroup",
        requestData,
        (data) => {
          this.dialogData.flag = false;
          this.getALlWechatGroupClientList();
          this.$message({
            message: "客户企业微信绑定成功!",
            type: "success",
            duration: 1000,
          });
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * dialog操作事件
     */
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "sure_bind_applet":
          // 确定开通小程序
          console.log("确定开通小程序-处理前", this.dialogData.data[0].value);
          let account = [];
          this.dialogData.data[0].value.forEach((element) => {
            if (element.phone != "" || element.name != "") {
              account.push(element);
            }
          });
          console.log("确定开通小程序-处理后", account);
          if (account.length > 0) {
            this.$confirm("此操作将清除该客户原开通记录, 是否继续?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                requestData = {
                  clientId: this.tempClient.id,
                  account: account,
                };
                this.$request(
                  "post",
                  "/applet/assignedAccountByClient",
                  requestData,
                  (data) => {
                    this.$message({
                      type: "success",
                      message: "开通成功!",
                      duration: 1000,
                    });
                    this.dialogData.flag = false;
                    this.getAllClientsAppletAccount();
                  },
                  (errorMessage) => {
                    this.$message.error(errorMessage);
                  }
                );
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消操作",
                  duration: 1000,
                });
              });
          } else {
            this.$message({
              type: "error",
              message: "请至少填写一组开通人数据!",
              duration: 1000,
            });
          }
          break;
        case "sure_add":
          // 确认添加客户编号
          console.log("this.dialogData.data[0]", this.dialogData.data[0]);
          requestData = {
            prefix: this.dialogData.data[0].value.prefix, // 前缀
            suffix: this.dialogData.data[0].value.suffix, // 后缀
            start: this.dialogData.data[0].value.start, // 起始值
            end: this.dialogData.data[0].value.end, // 结束值
          };
          this.$request(
            "post",
            "/dataDictionary/generateClientNumber",
            requestData,
            (data) => {
              this.$message.success("添加成功!");
              this.dialogData.flag = false;
              this.Loading();
              this.getClientNumberList();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "cancel":
          // dialog关闭
          this.dialogData.flag = false;
          break;
        default:
          break;
      }
    },

    handleClose(tag, array) {
      array.splice(array.indexOf(tag), 1);
    },

    showInput(index, array) {
      this.optionValueList1[index].flag = true;
    },

    handleInputConfirm(value, index, array) {
      let inputValue = value;
      if (inputValue) {
        array.push(inputValue);
      }
      this.optionValueList1[index].flag = false;
      this.optionValueList1[index].value = "";
    },
    // 拖拽开始事件
    onStart() {},
    // 拖拽结束事件
    onEnd() {
      console.log("拖拽结束");
    },
    /**
     * 页面加载动画
     */
    Loading() {
      setTimeout(() => {
        this.loading = false;
      }, 800);
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },

    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      switch (this.tabIndex) {
        case "客户编号设置":
          this.getClientNumberList();
          break;
        case "绑定企业微信客户":
          this.getAllClientByUserId();
          break;
        case "开通移动端":
          this.getAllClientsAppletAccount();

          break;

        default:
          break;
      }
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      switch (this.tabIndex) {
        case "客户编号设置":
          this.getClientNumberList();
          break;
        case "绑定企业微信客户":
          this.getAllClientByUserId();
          break;
        case "开通移动端":
          this.getAllClientsAppletAccount();

          break;

        default:
          break;
      }
    },
    getSystemInfo() {
      this.$request(
        "get",
        "/getSystemInfo",
        {},
        (data) => {
          this.contactSettingId = data.id;
          this.systemInfo = JSON.parse(data.clientContactDisplayRules);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 修改联系人权限
     */
    changeSwitch() {
      let requestData = {
        id: this.contactSettingId,
        clientContactDisplayRules: JSON.stringify(this.systemInfo), // 客户联系人是否显示规则，JSON数组
      };
      this.$request(
        "post",
        "/updateSystemInfo",
        requestData,
        (data) => {
          this.$message.success("修改成功!");
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 添加客户编号
     */
    handleClick(item, flag) {
      let requestData = {};
      switch (flag) {
        case "1":
          this.dialogData.title = "添加客户编号";
          this.dialogData.width = "800px";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "sure_add",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              type: "client-number",
              value: {
                prefix: "", // 前缀
                suffix: "", // 后缀
                start: "", // 起始值
                end: "", // 结束值
              },
            },
          ];

          this.dialogData.flag = true;
          break;
        case "2":
          // 启用
          requestData = {
            id: item.id,
            mark: item.mark, // 标识
            label: item.label, // 属性标签
            value: item.value, // 属性值
            enable: item.enable == "0" ? "1" : "0", // '0' - 禁用；'1' - 启用
          };
          this.$request(
            "post",
            "/dataDictionary/update",
            requestData,
            (data) => {
              this.$message.success("启用成功!");
              this.getClientNumberList();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "3":
          // 禁用
          this.$request(
            "get",
            "/client/countByClientNum?clientNum=" + item.label,
            requestData,
            (data) => {
              if (data > 0) {
                this.$message.error("该客户编号已被使用，无法禁用!");
              } else {
                requestData = {
                  id: item.id,
                  mark: item.mark, // 标识
                  label: item.label, // 属性标签
                  value: item.value, // 属性值
                  enable: item.enable == "0" ? "1" : "0", // '0' - 禁用；'1' - 启用
                };
                this.$request(
                  "post",
                  "/dataDictionary/update",
                  requestData,
                  (data) => {
                    this.$message.success("禁用成功!");
                    this.getClientNumberList();
                  },
                  (errorMessage) => {
                    this.$message.error(errorMessage);
                  }
                );
              }
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );

          break;
        case "4":
          // 删除
          this.$confirm("此操作将永久删除该客户编号, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$request(
                "get",
                "/client/countByClientNum?clientNum=" + item.label,
                requestData,
                (data) => {
                  if (data > 0) {
                    this.$message.error("该客户编号已被使用，无法删除!");
                  } else {
                    this.$request(
                      "get",
                      "/dataDictionary/delete?id=" + item.id,
                      {},
                      (data) => {
                        this.$message.success("删除成功!");
                        this.getClientNumberList();
                      },
                      (errorMessage) => {
                        this.$message.error(errorMessage);
                      }
                    );
                  }
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {});
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    this.loading = false;
    this.isCharge();
    setTimeout(() => {
      this.getModelFeatureAuthority();
    }, 500);
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.searchData.userId = this.baseInfo.userId;
    this.searchData.tempUserId = [this.baseInfo.userId];
  },
};
</script>

<style lang="less" scoped>
#client-setting {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .client-setting-screen-tabs {
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-left: 20px;
    .client-setting-screen-tab {
      width: 130px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      transition: 0.3s;
    }
    .client-setting-screen-tab:hover {
      cursor: pointer;
      color: @activeColor;
    }
    .is-active {
      color: @activeColor;
      border-bottom: 4px solid @activeColor;
    }

    .client-setting-screen-search {
      width: 210px;
      min-width: 210px;
      height: 30px;
      margin-left: 10px;
      display: flex;
    }
  }
  .table-content {
    flex: 1;
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    .client-table-card {
      width: 98%;
      height: 95%;
      max-height: 830px;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      &::-webkit-scrollbar {
        width: 1px;
      }
      .client-table-card-content {
        width: 100%;
        height: 0;
        flex: 1;
        display: flex;
        flex-direction: column;
        .client-table-card-content-line,
        .client-table-card-content-line2 {
          width: 100%;
          flex: 1;
          // min-height: 200px;
          height: 200px;
          border-bottom: 1px solid @boderColor;
          display: flex;
          flex-direction: column;
          overflow: auto;
          &::-webkit-scrollbar {
            width: 1px;
          }
          .client-table-card-content-line-title {
            width: 98%;
            height: 40px;
            margin-left: 20px;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            span {
              height: 60%;
              display: flex;
              align-items: flex-end;
              color: @tipColor;
              font-size: @contentFontSize;
              margin-left: 10px;
            }
            .client-table-card-content-line-title-save {
              width: auto;
              height: 30px;
              // margin-right: 20px;
              padding: 0 10px;
              border: 1px solid @activeColor3;
              background-color: @activeBackground;
              border-radius: 5px;
              box-sizing: border-box;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: 0.3s;
              font-size: 12px;
              &:hover {
                cursor: pointer;
                // transform: scale(1.02);
                background-color: @activeColor;
                color: #fff;
              }
            }
          }
          .client-table-card-content-line-value {
            width: 95%;
            height: 90%;
            display: flex;
            margin-left: 40px;
            .el-tag + .el-tag {
              margin-left: 20px;
            }
            .button-new-tag {
              margin-left: 20px;
              height: 32px;
              line-height: 30px;
              padding-top: 0;
              padding-bottom: 0;
            }
            .input-new-tag {
              width: 90px;
              margin-left: 10px;
              vertical-align: bottom;
            }
            .div-tags {
              height: auto;
              width: 96%;
              display: flex;
              flex-wrap: wrap;
              & > div {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                & > span {
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                }
              }
              .div-tag {
                width: auto;
                min-width: 30px;
                height: 25px;
                margin: 10px;
                padding: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid @boderActiveColor;
                transition: 0.5s;
                font-size: @contentFontSize;
              }
              .div-tag:hover {
                cursor: pointer;
                border: 1px dashed @activeColor;
                background-color: @activeBackground;
                color: @activeColor;
              }
            }
            .client-table-card-content-line-value-button {
              width: 98%;
              flex: 1;
              height: 0;
              // overflow: auto;
              display: flex;
              .client-table-card-content-line-value-button-title {
                width: 15%;
                height: 98%;
              }
              .client-table-card-content-line-value-button-values {
                flex: 1;
                height: 98%;
                overflow: auto;
                display: flex;
                flex-wrap: wrap;
                min-height: 190px;

                &::-webkit-scrollbar {
                  width: 1px;
                }
              }
            }
            .client-table-card-content-line-value-button:nth-child(1) {
              // height: 120px;
              border-bottom: 1px solid @boderColor;
              margin-bottom: 10px;
              padding-bottom: 10px;
            }
          }
          .div-flex-column {
            flex-direction: column;
          }
          .div-flex-space-between {
            justify-content: space-between;
          }
        }
        .client-table-card-content-line2 {
          max-height: 400px;
        }
        .client-table-card-content-line {
          min-height: 200px;
        }

        .client-table-card-content-tags-setting {
          width: 100%;
          height: 100%;
          display: flex;
          .client-table-card-content-tags-setting-left {
            width: 8vw;
            height: 100%;
            display: flex;
            flex-direction: column;
            border-right: 1px solid @activeColor3;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12),
              0 0 6px rgba(0, 0, 0, 0.04);
            .client-table-card-content-tags-setting-left-line {
              width: 100%;
              height: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: @contentFontSize;
              transition: 0.3s;
              color: #000;
              &:hover {
                cursor: pointer;
                color: @activeColor;
              }
            }
            .is-active {
              color: @activeColor;
            }
          }
          .client-table-card-content-tags-setting-right {
            flex: 1;
            width: 0;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .client-table-card-content-tags-setting-right-add {
              width: 100%;
              height: 8vh;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              padding-left: 20px;
              .butt {
                width: 100px;
                height: 40px;
                border: 1px solid @activeColor;
                background-color: @activeColor;
                color: #fff;
                font-size: @contentFontSize;
                display: flex;
                align-items: center;
                border-radius: 10px;
                justify-content: center;
                transition: 0.3s;
                &:hover {
                  cursor: pointer;
                  background-color: @activeColor2;
                }
              }
            }
            .client-table-card-content-tags-setting-right-contacts {
              width: 100%;
              height: 100%;
              .client-table-card-content-tags-setting-right-line {
                height: 50px;
                min-height: 50px;
                width: 100%;
                display: flex;
                align-items: center;
                padding: 0 20px;
                box-sizing: border-box;
                border-bottom: 1px solid @boderColor;
                justify-content: space-between;
                & > div {
                  display: flex;
                }
              }
            }
            .client-table-card-content-tags-setting-right-list {
              flex: 1;
              height: 0;
              width: 97%;
              display: flex;
              flex-direction: column;
              overflow-y: auto;

              &::-webkit-scrollbar {
                width: 1px;
              }
              .client-table-card-content-tags-setting-right-line {
                height: 50px;
                min-height: 50px;
                width: 100%;
                display: flex;
                align-items: center;
                padding-left: 10px;
                box-sizing: border-box;
                border-bottom: 1px solid @boderColor;
                justify-content: space-between;
                & > div {
                  display: flex;
                  width: 100%;
                }
              }
            }
          }
        }
        .client-table-card-content-clientNumber-setting {
          width: 100%;
          height: 100%;
        }
      }
      .client_connect-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
        .el-pagination {
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
      }
    }
  }
}
</style>
