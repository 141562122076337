export function getErrorMessageInfo(code) {
    let info = ""
    switch (code) {
        case "authority":
            info = "您没有权限,请联系系统管理员或机构负责人!"
            break;
        case "add":
            info = "添加成功!"
            break;
        case "update":
            info = "修改成功!"
            break;
        case "save":
            info = "保存成功!"
            break;
        case "system":
            info = "系统暂未配置成功!"
            break;

        default:
            break;
    }
    return info
}