import * as statusData from "./status.js"

export default {
    bind(el, bindings) {
     
        let array = bindings.value.array // 所有按钮或模块的权限
        let ele = bindings.value.active // 当前按钮或模块
        let status = bindings.value.status // 当前状态
        let model = bindings.value.model // 所有显示的状态
            // console.log("当前按钮或模块", bindings.value);
        setTimeout(() => {
            // 延时渲染  保证先创建dom
            let flag = false
            if (ele != "cancel" && ele != "" && ele != null) {
                for (let index = 0; index < array.length; index++) {
                    let element = array[index];
                    if (element.key == ele && element.authority == "1") {
                        if (status != "" && status != null) {
                            let statusList = statusData.getStatus(status, model)
                            if (statusList.indexOf(ele) >= 0) {
                                // 叠加显示状态
                                flag = true
                            }
                        } else {
                            // 不存在状态叠加
                            flag = true
                        }
                    }
                }
                if (!flag) {
                    el.parentNode.removeChild(el)
                }
            }
        }, 100)
    },
    // update(el, bindings) {
    //     console.log("自定义指令修改");
    //     let array = bindings.value.array // 所有按钮或模块的权限
    //     let ele = bindings.value.active // 当前按钮或模块
    //     let status = bindings.value.status // 当前状态
    //     let model = bindings.value.model // 所有显示的状态
    //     console.log("当前按钮或模块", bindings.value);
    //     setTimeout(() => {
    //         // 延时渲染  保证先创建dom
    //         let flag = false
    //         if (ele != "cancel" && ele != "" && ele != null) {
    //             for (let index = 0; index < array.length; index++) {
    //                 let element = array[index];
    //                 if (element.key == ele && element.authority == "1") {
    //                     if (status != "" && status != null) {
    //                         let statusList = statusData.getStatus(status, model)
    //                         if (statusList.indexOf(ele) >= 0) {
    //                             // 叠加显示状态
    //                             flag = true
    //                         }
    //                     } else {
    //                         // 不存在状态叠加
    //                         flag = true
    //                     }
    //                 }
    //             }
    //             console.log("自定义指令更新", bindings.value, el, el.parentNode, flag);
    //             if (!flag && el.parentNode != null) {
    //                 el.parentNode.removeChild(el)
    //             }
    //         }
    //     }, 100)
    // },
}