<template>
  <div id="their-tickets">
    <!-- 发票 -- 领票设置 -->
    <div class="screen">
      <div class="screen-line">
        <div class="invoice-setting-screen-search">
          <el-input
            placeholder="请输入客户全称/编号/税号"
            v-model="searchData.input"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            >
            </el-button>
          </el-input>
        </div>
        <div class="invoice-setting-screen-search">
          <el-select
            v-model="searchData.roleId"
            placeholder="请选择角色"
            @change="initTableData"
          >
            <el-option
              v-for="item in searchData.roleList"
              :key="item.authorityId"
              :label="item.authorityName"
              :value="item.authorityId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="invoice-setting-screen-search">
          <el-select
            v-model="searchData.userId"
            placeholder="请选择员工"
            filterable
            @change="selectInitTableData"
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="invoice-setting-screen-checked"></div>
        <div class="invoice-setting-screen-active"></div>
      </div>
    </div>
    <div class="table-content">
      <div class="their-tickets-table-card">
        <div class="invoice-setting-content">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            height="98%"
            @selection-change="handleSelectionChange"
            v-loading="loading"
          >
            <el-table-column type="selection" width="55" fixed>
            </el-table-column>

            <el-table-column
              type="index"
              width="55"
              fixed
              align="center"
            ></el-table-column>
            <el-table-column
              prop="clientName"
              label="客户全称"
              fixed
            ></el-table-column>
            <el-table-column
              prop="addedValueTaxType"
              label="企业类型"
              fixed
            ></el-table-column>
            <el-table-column
              prop="enterpriseNumber"
              label="企业税号"
              fixed
            ></el-table-column>
            <el-table-column label="操作" fixed>
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="handleClick(scope.row, 4)"
                  v-showFunction="{
                    active: 'setting',
                    array: allFunctionButtons,
                  }"
                  >领票设置</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="their-tickets-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @showCustomer="showCustomer"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import * as invoiceData from "@/assets/js/invoiceData.js";
import my_dialog from "@/components/dialog.vue";
export default {
  components: {
    my_dialog,
  },
  data() {
    return {
      curInvoiceTypeQuota: [], // 当前发票类型信息
      curType: "", // 当前发票类型
      templateData: {},
      baseInfo: {},
      loading: true,
      whetherAgencyHead: false,
      searchData: {
        input: "",
        checked: false,
        screenButton_add: 0,
        screenButton_improt: 0,
        roleId: "", // 角色
        roleList: [], // 角色列表
        userId: "", // 员工id
        userList: [], // 员工列表
        userIdList: [], // 全部员工id列表
        tempUserId: [], // 筛选员工id列表
      },
      tableData: [],
      buttonAuth: [],
      multipleSelection: [],
      tempClient: {},
      dialogData: {
        flag: false,
        width: "30%",
        title: "添加跟进记录",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },

      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "theirTickets"
      ),
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "theirTickets",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          // this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getEmployeeList();
        this.initTableData();
      });
    },
    /**
     * 获取所有业务角色列表
     */
    getBusinessAuthorityList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/role/getBusinessAuthorityList",
        requestData,
        (data) => {
          this.searchData.roleList = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.searchData.userList = [];
          data.forEach((element) => {
            let dd = {
              name: element.userName,
              id: element.userId,
            };
            this.searchData.userIdList.push(element.userId);
            this.searchData.userList.push(dd);
          });
          this.searchData.userList.splice(0, 0, {
            id: "all",
            name: "全部人员",
          });
          this.searchData.userId = "all";
          this.selectInitTableData("all");
          this.getBusinessAuthorityList();
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      this.loading = true;
      let requestData = {
        companyId: this.baseInfo.company.companyId, // 机构ID
        userId: this.baseInfo.userId,
        currentPage: this.pageData.currentPage, // 当前页数 20210907 改
        pageSize: this.pageData.pageSize, // 每页条数 20210907 改
        search: this.searchData.input,
        roleId: this.searchData.roleId, // 角色 id
        userIdList: this.searchData.tempUserId, // 员工id集合
      };
      this.$request(
        "post",
        "/invoiceConf/getClientSettable",
        requestData,
        (data) => {
          this.pageData.total = data.total;
          this.tableData = data.records;
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * dialog点击事件
     */
    drawerOper(active, data) {
      switch (active) {
        case "add":
          this.dialogData.data[0].value.value.curInvoiceTypeQuota.push({
            code: "", // 发票代码
            startNo: "", // 起始号码
            endNo: "", // 终止号码
            type: this.curType, // 发票类型
          });

          break;
        case "get_client_info":
          // 获取企业工商信息
          this.getStatisticsInvoice();
          break;
        case "changeQuota":
          let requestData2 = {
            clientId: this.tempClient.id, // 客户id,
            quota: this.dialogData.data[0].value.quota, // 发票限额（万元）
            type: this.curType, // 发票类型
          };
          this.$request(
            "post",
            "/invoiceConf/updateQuota",
            requestData2,
            (reData) => {
              this.$message.success("修改成功");
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "make_sure_add":
          let requestData = {
            ...data,
            quota: this.dialogData.data[0].value.quota, // 限额
            clientId: this.tempClient.id, // 客户id
            createId: this.baseInfo.userId, // 创建人 id
          };

          if (requestData.startNo == "" || requestData.endNo == "") {
            this.$message.error("发票号码必填!");
            return;
          }
          if (requestData.code == "") {
            this.$message.error("发票代码必填!");
            return;
          }
          if (!(+requestData.endNo >= +requestData.startNo)) {
            this.$message.error("发票号码必须大于零!");
            return;
          }
          this.$request(
            "post",
            "/invoiceConf/addInvoiceConf",
            requestData,
            (reData) => {
              this.$message.success("添加成功");
              this.handleClick(this.tempClient, 4, data.type);
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "edit":
          break;
        case "delete":
          this.$confirm("确认要删除该领票信息吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$request(
                "get",
                `/invoiceConf/delInvoiceConf?id=${data.id}`,
                {},
                (reData) => {
                  this.$message.success("删除成功");
                  this.handleClick(this.tempClient, 4, data.type);
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {});

          break;
        case "cancel":
          // 取消
          this.colseDialog();
          break;
        case "change":
          this.curType = data.name;
          this.handleClick(this.tempClient, 4, data.name);
          break;

        default:
          break;
      }
    },
    /**
     * 界面点击事件
     */
    handleClick(data, flag, type) {
      let that = this;
      switch (flag) {
        case 4:
          // 库存设置
          that.tempClient = JSON.parse(JSON.stringify(data));
          that.getConfByClient(type).then(function(resolve) {
            that.dialogData.title = "客户库存设置";
            that.dialogData.width = "50%";
            that.dialogData.buttonArray = [
              {
                text: "添加",
                active: "add",
              },
              {
                text: "取消",
                active: "cancel",
              },
            ];
            that.dialogData.data = [
              {
                title: "",
                type: "invoice-stock",
                value: {
                  activeName: type ? type : "026", // 发票类型
                  menu: [
                    {
                      name: "026",
                      label: "电子普票",
                    },
                    {
                      name: "028",
                      label: "电子专票",
                    },
                    {
                      name: "007",
                      label: "纸质普票",
                    },
                    {
                      name: "004",
                      label: "纸质专票",
                    },
                  ],
                  value: {
                    curInvoiceTypeQuota:
                      that.curInvoiceTypeQuota.length == 0
                        ? [
                            {
                              code: "", // 发票代码
                              startNo: "", // 起始号码
                              endNo: "", // 终止号码
                              type: type ? type : "026", // 发票类型
                              flag: false, // 是否可编辑 false 可编辑 true 不可编剧
                            },
                          ]
                        : [...that.curInvoiceTypeQuota],
                  },
                  quota:
                    that.curInvoiceTypeQuota.length == 0
                      ? 1
                      : that.curInvoiceTypeQuota[0].quota, // 限额
                  template: [
                    {
                      label: "万元版",
                      value: 1,
                    },
                    {
                      label: "十万元版",
                      value: 10,
                    },
                    {
                      label: "百万元版",
                      value: 100,
                    },
                  ],
                },
              },
            ];
            that.dialogData.flag = true;
          });

          break;
        default:
          break;
      }
    },
    /**
     * 获取不同发票类型限额
     */
    getConfByClient(type) {
      let that = this;
      let p = new Promise(function(resolve, reject) {
        let requestData = {
          clientId: that.tempClient.id, // 客户id
          type: type ? type : "026", // 发票类型
        };
        that.$request(
          "post",
          "/invoiceConf/getConfByClient",
          requestData,
          (data) => {
            data.forEach((item) => {
              item.flag = true;
            });
            that.curInvoiceTypeQuota = data;
            resolve(true);
          },
          (errorMessage) => {
            reject(errorMessage);
            that.$message.error(errorMessage);
          }
        );
      });
      return p;
    },

    /**
     * 获取企业工商信息  调企查查
     */
    getStatisticsInvoice() {
      let requestData = {
        name: this.dialogData.data[0].value,
      };
      this.$request(
        "post",
        "/invoice/getStatisticsInvoice",
        requestData,
        (data) => {
          this.dialogData.data[1].value = data.enterpriseNumber; // 企业税号
          this.tempClient.addedValueTaxType = data.addedValueTaxType; // 增值税类型
          this.tempClient.industryType = data.industryType; // 行业分类
          this.tempClient.area = data.area; // 所在地区
          this.tempClient.address = data.address; // 详细地址
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },

    /**
     * 多选选择事件
     */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
      this.initTableData();
    },
    showCustomer(data) {
      this.dialogData.data[0].data = data;
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    /**
     * 员工选择
     */
    selectInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.searchData.userIdList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.initTableData();
    },
  },
  mounted() {
    this.loading = true;
    this.isCharge();
    // this.initTableData();
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
    this.curType = "026";
  },
};
</script>

<style lang="less" scoped>
#their-tickets {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .invoice-setting-screen-fiftrate {
    width: 65px;
    min-width: 65px;
    height: 30px;
    border: 1px solid @boderColor;
    box-sizing: border-box;
    color: @boderActiveColor;
    font-size: @contentFontSize;
    margin: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    transition: 0.3s;
    i {
      margin: 0 4px;
    }
  }
  .invoice-setting-screen-fiftrate:hover {
    cursor: pointer;
    border-color: @boderActiveColor;
  }
  .invoice-setting-screen-search {
    width: 210px;
    min-width: 210px;
    height: 30px;
    margin-left: 10px;
  }
  .invoice-setting-screen-checked {
    width: 140px;
    height: 30px;
    min-width: 140px;
    font-size: @contentFontSize;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .invoice-setting-screen-active {
    flex: 1;
  }
  .invoice-setting-screen-but-select {
    width: 140px;
    min-width: 160px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @activeColor;
    font-size: @contentFontSize;
    color: #fff;
    margin: 0 5px;
    border-radius: 4px;
    transition: 0.5s;
    cursor: pointer;
    .invoice-setting-screen-but-select_but {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.5s;
    }
    .invoice-setting-screen-but-select_but:hover {
      background-color: @activeColor2;
    }
    .invoice-setting-screen-but-select_select {
      width: 30px;
      height: 100%;
      border-left: 1px solid @activeColor3;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .invoice-setting-screen-but {
    width: 100px;
    min-width: 100px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @activeColor;
    font-size: @contentFontSize;
    color: #fff;
    margin: 0 5px;
    border-radius: 4px;
    transition: 0.5s;
  }
  .invoice-setting-screen-but:hover {
    cursor: pointer;
    background-color: @activeColor2;
  }
  .invoice-setting-screen-but-boder {
    width: 50px;
    min-width: 50px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid @activeColor;
    font-size: @contentFontSize;
    background-color: @activeBackground;
    color: @activeColor;
    margin: 0 5px;
    margin-right: 10px;
    border-radius: 4px;
    transition: 0.5s;
  }
  .invoice-setting-screen-but-boder:hover {
    cursor: pointer;
    background-color: @activeBackground2;
  }
  .table-content {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    .their-tickets-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .invoice-setting-content {
        width: 98%;
        height: 95%;
        max-width: 90vw;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        .line-cell {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          cursor: pointer;
          i {
            margin: 5px;
          }
        }
        .gray {
          color: rgb(146, 146, 146, 0.637);
        }
        .orange {
          color: rgba(255, 115, 0, 0.637);
        }
        .green {
          color: rgb(9, 255, 0, 0.637);
        }
      }
      .their-tickets-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }
}
</style>
