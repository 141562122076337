<template>
  <div id="employee">
    <div class="screen">
      <div class="screen-line">
        <div class="employee-screen-search">
          <el-input
            placeholder="搜索公司名称"
            suffix-icon="el-icon-search"
            v-model="searchData.input"
            @keyup.enter.native="initTableData"
          >
          </el-input>
        </div>
        <div class="employee-screen-active"></div>
        <div
          class="employee-screen-but"
          v-if="buttonAuth.createBut < 3"
          @click="openDialog({}, 'create-company')"
        >
          新建机构
        </div>
      </div>
    </div>
    <div class="table-content">
      <div class="employee-content">
        <el-table
          ref="singleTable"
          :data="tableData"
          highlight-current-row
          style="width: 100%"
          height="98%"
          v-loading="isLoading"
        >
          <el-table-column type="index" width="55" fixed> </el-table-column>
          <el-table-column property="companyName" label="公司名称" width="450">
          </el-table-column>
          <el-table-column property="companyAdmins" label="机构负责人">
            <template slot-scope="scope">
              <div v-for="(it, ii) in scope.row.companyAdmins" :key="ii">
                <span v-if="ii > 0">,</span> {{ it.companyAdminName }}
              </div>
              <div v-if="scope.row.companyAdmins.length == 0">---</div>
            </template>
          </el-table-column>
          <el-table-column property="role" label="操作" width="350">
            <template slot-scope="scope">
              <el-button
                type="success"
                @click.native.stop="openDialog(scope.row, 'update')"
                size="mini"
                v-if="buttonAuth.updateBut < 3"
                >修改</el-button
              >
              <el-button
                type="danger"
                @click.native.stop="openDialog(scope.row, 'delete')"
                size="mini"
                v-if="buttonAuth.deleteBut < 3"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- dialog -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>

    <div id="my-dialog" v-if="adminDialogData.flag">
      <my_dialog
        :width="adminDialogData.width"
        :title="adminDialogData.title"
        :buttonArray="adminDialogData.buttonArray"
        :data="adminDialogData.data"
        @colseDialog="drawerOper('cancelAdmin')"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import my_dialog from "../components/dialog.vue";
import * as companyData from "../assets/js/companyData.js";
export default {
  components: {
    my_dialog,
  },
  data() {
    return {
      baseInfo: {},
      isLoading: true,
      isDisable: false,
      adminDialogData: {
        flag: false,
        width: "40%",
        title: "新增机构负责人",
        buttonArray: [
          {
            text: "确认",
            active: "createAdmin",
          },
          {
            text: "取消",
            active: "cancelAdmin",
          },
        ],
        data: [],
      },
      searchData: {
        input: "",
        checked1: false,
        checked2: false,
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "新增机构",
        empolyeeId: "",
        buttonArray: [
          {
            text: "确认",
            active: "add_company",
          },
          {
            text: "取消",
            active: "cancel",
          },
        ],
        data: [],
      },
      tableData: [],
      tempAdmins: [],
      buttonAuth: {
        createBut: 1, // 新建机构按钮  1为读写  2为只读  3为不可见
        updateBut: 1, // 修改信息按钮
        deleteBut: 1, // 删除按钮
      },
      companyAdminId: [],
    };
  },
  methods: {
    /**
     * 获取部门设置的按钮权限
     */
    getModelAuthority() {
      this.$authority.getModelAuthority(
        ["system", "company"],
        "modelChildMenu",
        this,
        false,
        (data) => {
          if (data.length > 0) {
            this.buttonAuth.createBut = this.$systemBaseData.judgeButtonAuthority(
              "新建机构",
              data
            );
            this.buttonAuth.updateBut = this.$systemBaseData.judgeButtonAuthority(
              "修改机构信息",
              data
            );
            this.buttonAuth.deleteBut = this.$systemBaseData.judgeButtonAuthority(
              "删除机构",
              data
            );
          }
          this.getAllCompanyList();
        }
      );
    },
    /**
     * 获取所有公司列表
     */
    getAllCompanyList() {
      this.isLoading = true;
      let requestData = {
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/company/getCompanyList",
        requestData,
        (data) => {
          this.tableData = data;
          this.isLoading = false;
        },
        (errorMessage) => {
          this.Loading();
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * dialog打开
     */
    openDialog(data, active) {
      let requestData = {};
      let tips = [];
      switch (active) {
        case "create-company":
          // 新建机构
          if (this.buttonAuth.createBut == 1) {
            this.dialogData.width = "30%";
            this.dialogData.title = "新增机构";
            this.dialogData.buttonArray = [
              {
                text: "确认",
                active: "add_company",
              },
              {
                text: "取消",
                active: "cancel",
              },
            ];
            this.dialogData.data = companyData.createCompanyDialogData();
            this.getAllCompanyAdminList();
            this.dialogData.flag = true;
          } else {
            this.$message.error(" 您没有权限操作,请联系管理员或机构负责人!");
          }
          break;
        case "update":
          // 机构基本信息修改
          if (this.buttonAuth.updateBut == 1) {
            this.dialogData.width = "30%";
            this.dialogData.title = "机构信息修改";
            this.dialogData.companyId = data.companyId;
            this.dialogData.buttonArray = [
              {
                text: "确认",
                active: "update_company",
              },
              {
                text: "取消",
                active: "cancel",
              },
            ];
            tips = [];
            data.companyAdmins.forEach((element) => {
              let dd = {
                label: element.companyAdminName,
                value: element.companyAdminId,
              };
              tips.push(dd);
            });
            this.dialogData.data = companyData.createCompanyDialogData();
            console.log("员工信息:", data);
            this.dialogData.data[0].value = data.companyName;
            this.dialogData.data[2].value = tips;
            this.tempAdmins = JSON.parse(JSON.stringify(tips));
            this.getAllCompanyAdminList();
            this.dialogData.flag = true;
          } else {
            this.$message.error(" 您没有权限操作,请联系管理员或机构负责人!");
          }
          break;
        case "delete":
          // 员工删除
          if (
            this.buttonAuth.deleteBut == 1 &&
            this.baseInfo.userId == "super"
          ) {
            this.$confirm("此操作将永久删除该机构, 是否继续?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                requestData = {
                  companyId: data.companyId,
                };
                this.$request(
                  "post",
                  "/company/deleteCompany",
                  requestData,
                  (data) => {
                    this.$message({
                      type: "success",
                      message: "删除成功!",
                    });
                    this.getAllCompanyList();
                  },
                  (errorMessage) => {
                    this.$message.error(errorMessage);
                  }
                );
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消删除",
                });
              });
          } else {
            this.$message.error(" 您没有权限操作,请联系管理员或机构负责人!");
          }
          break;
        default:
          break;
      }
    },
    /**
     * 获取所有机构负责人列表
     */
    getAllCompanyAdminList() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeList",
        requestData,
        (data) => {
          this.Loading();
          this.dialogData.data[1].options = [];
          if (data.length > 0) {
            data.forEach((element) => {
              let dd = {
                label: element.userName,
                value: element.userId,
              };
              this.dialogData.data[1].options.push(dd);
            });
          }
          this.dialogData.flag = true;
        },
        (errorMessage) => {
          this.Loading();
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * dialog事件
     */
    drawerOper(active) {
      let role = [];
      let requestData = {};
      this.companyAdminId = [];
      switch (active) {
        case "add_company":
          // console.log("确定创建:", this.dialogData.data[2].value);
          this.dialogData.data[2].value.forEach((element) => {
            if (element.value != "") {
              this.companyAdminId.push({ id: element.value });
            } else {
              // 新建机构负责人数据
              this.companyAdminId.push(element.data);
            }
          });
          requestData = {
            companyName: this.dialogData.data[0].value,
            companyAdminId: this.companyAdminId, // 手机号
            authority: this.$authority.allAuthorityConfiguration(),
            logo: this.$baseLogo,
          };
          console.log("创建公司", requestData);
          this.$request(
            "post",
            "/company/createCompany",
            requestData,
            (data) => {
              this.$message({
                message: "机构添加成功!",
                type: "success",
              });
              this.colseDialog();
              this.getAllCompanyList();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "update_company":
          let originalAdminIds = [];
          this.tempAdmins.forEach((element) => {
            originalAdminIds.push(element.value);
          });
          this.dialogData.data[2].value.forEach((element) => {
            this.companyAdminId.push(element.value);
          });
          let newCompanyAdmin = [];
          this.dialogData.data[2].value.forEach((element) => {
            if (element.value != "") {
              // this.companyAdminId.push({ id: element.value });
            } else {
              // 新建机构负责人数据
              newCompanyAdmin.push(element.data);
            }
          });
          requestData = {
            companyId: this.dialogData.companyId, // 公司ID
            companyName: this.dialogData.data[0].value, // 公司名称
            newCompanyAdmin: newCompanyAdmin,
            companyAdminId: this.companyAdminId, // 公司负责人ID
            originalAdminIds: originalAdminIds,
          };
          this.$request(
            "post",
            "/company/updateCompany",
            requestData,
            (data) => {
              this.$message({
                message: "机构信息修改成功!",
                type: "success",
              });
              this.colseDialog();
              this.getAllCompanyList();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "add-companyAdmin":
          // 新建机构负责人dialog打开
          console.log("新建机构负责人", this.baseInfo.userId);
          if (this.baseInfo.userId == "super") {
            this.adminDialogData.data = companyData.createCompanyAdminDialogData();
            this.adminDialogData.flag = true;
          } else {
            this.$message.error(" 您没有权限操作,请联系管理员或机构负责人!");
          }
          break;
        case "createAdmin":
          // 确认新建负责人
          console.log("角色选择", this.adminDialogData);
          requestData = {
            id: "",
            userName: this.adminDialogData.data[0].value,
            phone: this.adminDialogData.data[1].value, // 手机号
            psw: "admin", // 默认初始密码
            icon: this.$baseimgurl, // 默认图像
            hiredate: this.adminDialogData.data[2].value, // 入职时间,
            identity: this.adminDialogData.data[3].value, // 身份证号
            state: this.adminDialogData.data[4].value, // 状态:1-> 在职员工(默认状态);0->离职员工
          };
          this.dialogData.data[1].value = this.adminDialogData.data[0].value;
          this.dialogData.data[2].value.push({
            label: this.adminDialogData.data[0].value,
            value: "",
            data: requestData,
          });
          // this.companyAdminId.push(requestData);
          this.adminDialogData.flag = false;
          // this.$request(
          //   "post",
          //   "/company/createCompanyUser",
          //   requestData,
          //   (data) => {
          //     this.$message({
          //       message: "机构负责人创建成功!",
          //       type: "success",
          //     });
          //     this.adminDialogData.flag = false;
          //     this.getAllCompanyAdminList();
          //   },
          //   (errorMessage) => {
          //     this.$message.error(errorMessage);
          //   }
          // );
          break;
        case "cancel":
          this.colseDialog();
          break;
        case "cancelAdmin":
          this.adminDialogData.flag = false;
          break;

        default:
          break;
      }
    },
    /**
     * dialog父子组件连锁事件
     */
    authorityShow(data) {
      if (this.dialogData.data.length > 0) {
        for (let index = 0; index < this.dialogData.data.length; index++) {
          let element = this.dialogData.data[index];
          if (element.type == "role_tree") {
            element.data = data;
          }
        }
      }
    },
    /**
     * 右边抽屉关闭事件
     */
    closeUpdateTableDrawer() {
      this.employeeDrawerData.flag = false;
    },
    /**
     * dialog关闭
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 页面加载动画
     */
    Loading() {
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    },
  },
  mounted() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    console.log("当前用户基本信息:", this.baseInfo);
    this.getModelAuthority();
  },
};
</script>

<style lang="less" scoped>
#employee {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .employee-screen-search {
    width: 210px;
    min-width: 210px;
    height: 30px;
    margin-left: 10px;
  }

  .employee-screen-checked {
    width: 140px;
    height: 30px;
    min-width: 140px;
    font-size: @contentFontSize;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .employee-screen-active {
    flex: 1;
  }
  .employee-screen-but {
    width: 120px;
    min-width: 120px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @activeColor;
    font-size: @contentFontSize;
    color: #fff;
    margin: 0 5px;
    border-radius: 4px;
    transition: 0.5s;
    margin-right: 20px;
  }
  .employee-screen-but:hover {
    cursor: pointer;
    background-color: @activeColor2;
  }
  .employee-content {
    width: 98%;
    height: 95%;
    max-width: 90vw;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    display: flex;
    flex-direction: column;
  }
}
</style>