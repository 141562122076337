<template>
  <div id="invoice-register">
    <!-- 理票登记 -->
    <div class="screen">
      <div class="screen-line">
        <div class="invoice-register-screen-fiftrate">
          筛选 <i class="el-icon-s-operation"></i>
        </div>
        <div class="invoice-register-screen-search">
          <el-date-picker
            v-model="searchData.time"
            type="year"
            placeholder="选择年"
            @change="initTableData"
          >
          </el-date-picker>
        </div>
        <div class="invoice-register-screen-search">
          <el-input
            placeholder="请输入客户全称/编号/税号"
            v-model="searchData.input"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="invoice-register-screen-checked">
          <el-checkbox v-model="searchData.checked" @change="initTableData"
            >包含流失</el-checkbox
          >
        </div>
        <div class="invoice-register-screen-active"></div>
      </div>
    </div>

    <div class="table-content">
      <div class="invoice-register-table-card">
        <div class="invoice-register-content">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            height="98%"
            v-loading="loading"
          >
            <el-table-column type="index" width="55" fixed> </el-table-column>
            <el-table-column prop="clientName" label="客户全称">
            </el-table-column>
            <el-table-column prop="clientNum" label="客户编号">
            </el-table-column>
            <el-table-column
              v-for="(item, index) in 12"
              :key="index"
              width="100"
            >
              <template slot="header"> {{ index + 1 }}月 </template>
              <template slot-scope="scope">
                <div
                  class="line-cell"
                  v-if="
                    scope.row.data[index].invoice == '0' ||
                    scope.row.data[index].invoice == 0
                  "
                >
                  <!-- 没有  即当前还没有到月份 -->
                </div>
                <div
                  class="line-cell gray"
                  v-if="
                    scope.row.data[index].invoice == '1' ||
                    scope.row.data[index].invoice == 1
                  "
                >
                  <i
                    class="el-icon-printer"
                    :style="{
                      color:
                        scope.row.data[index].flag == '0' ||
                        scope.row.data[index].flag == 0
                          ? 'rgba(146, 146, 146, 0.637)'
                          : 'rgb(9, 255, 0, 0.637)',
                    }"
                    v-showFunction="{
                      active: 'print',
                      array: allFunctionButtons,
                    }"
                    @click="showDetails(scope.row, index, 1)"
                  ></i>
                  <!-- 未整理  显示灰色 - -->
                  <i
                    class="el-icon-remove-outline"
                    @click="showDetails(scope.row, index, 0)"
                    v-showFunction="{
                      active: 'setting',
                      array: allFunctionButtons,
                    }"
                  ></i>
                </div>
                <div
                  class="line-cell orange"
                  v-if="
                    scope.row.data[index].invoice == '2' ||
                    scope.row.data[index].invoice == 2
                  "
                >
                  <i
                    class="el-icon-printer"
                    :style="{
                      color:
                        scope.row.data[index].flag == '0' ||
                        scope.row.data[index].flag == 0
                          ? 'rgba(146, 146, 146, 0.637)'
                          : 'rgb(9, 255, 0, 0.637)',
                    }"
                    v-showFunction="{
                      active: 'print',
                      array: allFunctionButtons,
                    }"
                    @click="showDetails(scope.row, index, 1)"
                  ></i>
                  <!-- 整理中  显示橙色 时间 -->
                  <i
                    class="el-icon-time"
                    @click="showDetails(scope.row, index, 0)"
                    v-showFunction="{
                      active: 'setting',
                      array: allFunctionButtons,
                    }"
                  ></i>
                </div>
                <div
                  class="line-cell green"
                  v-if="
                    scope.row.data[index].invoice == '3' ||
                    scope.row.data[index].invoice == 3
                  "
                >
                  <i
                    class="el-icon-printer"
                    :style="{
                      color:
                        scope.row.data[index].flag == '0' ||
                        scope.row.data[index].flag == 0
                          ? 'rgba(146, 146, 146, 0.637)'
                          : 'rgb(9, 255, 0, 0.637)',
                    }"
                    v-showFunction="{
                      active: 'print',
                      array: allFunctionButtons,
                    }"
                    @click="showDetails(scope.row, index, 1)"
                  ></i>
                  <!-- 已完成  显示绿色 √ -->
                  <i
                    class="el-icon-circle-check"
                    @click="showDetails(scope.row, index, 0)"
                    v-showFunction="{
                      active: 'setting',
                      array: allFunctionButtons,
                    }"
                  ></i>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="invoice-register-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 右边抽屉 -->
    <div id="my-drawer" v-if="invoiceRegisterDrawerData.flag">
      <my_drawer
        :drawerData="invoiceRegisterDrawerData"
        @show="showDrawer"
        @closeUpdateTableDrawer="closeUpdateTableDrawer"
        @saveDrawerData="saveDrawerData"
        @drawerOper="drawerOper"
      ></my_drawer>
    </div>
  </div>
</template>

<script>
import * as invoiceData from "@/assets/js/invoiceData.js";
import my_drawer from "@/components/my-drawer.vue";
import my_dialog from "@/components/dialog.vue";
export default {
  components: {
    my_drawer,
    my_dialog,
  },
  data() {
    return {
      baseInfo: {},
      loading: true,
      whetherAgencyHead: false,
      searchData: {
        input: "",
        time: "2021",
        checked: false,
        screenButton_add: 0,
        screenButton_improt: 0,
      },
      tableData: invoiceData.getInvoiceTableData(),
      invoiceRegisterDrawerData: invoiceData.getInvoiceRegisterDrawerData(
        "",
        ""
      ),

      tempClient: {},
      tempInvoiceRegister: {},
      tempMonth: 0, // 临时月份
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "invoiceRegister"
      ),
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "invoiceRegister",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          // this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.initTableData();
      });
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      this.loading = true;
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        year: this.searchData.time, // 年份
        userId: this.baseInfo.userId,
        currentPage: this.pageData.currentPage, // 当前页数 20210907 改
        pageSize: this.pageData.pageSize, // 每页条数 20210907 改
        search: this.searchData.input,
      };
      this.$request(
        "post",
        "/invoice/getInvoiceRegisterByClient",
        requestData,
        (data) => {
          this.pageData.total = data.total;
          this.tableData = data.tableData;
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 展示当前月份的详细信息
     * id:客户ID
     * index:月份-1
     * flag:0为展示理票信息;1为银行流水信息
     */
    showDetails(data, index, flag) {
      console.log("行点击事件:", data);
      this.tempClient = data;
      switch (flag) {
        case 0:
          // 展示理票信息
          this.openRegister(index + 1);
          break;
        case 1:
          // 展示银行流水信息
          // data.data[index].flag = data.data[index].flag == "0" ? "1" : "0";
          let requestData = {
            clientId: this.tempClient.clientId, // 客户Id
            year: this.searchData.time, // 年份
            month: index + 1, // 月份
            flag: data.data[index].flag == "0" ? "1" : "0",
          };
          this.$request(
            "post",
            "/invoice/setInvoiceRegisterBlankPrintStatus",
            requestData,
            (redata) => {
              this.$message({
                message: "操作成功!",
                type: "success",
                duration: 1000,
              });
              this.initTable();
              this.saveInvoiceRegisterOperation(
                "修改" +
                  this.searchData.time +
                  "-" +
                  this.tempMonth +
                  "银行流水打印状态",
                redata.id
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;

        default:
          break;
      }
    },
    /**
     * 理票登记具体信息展示
     */
    openRegister(month) {
      this.tempMonth = month;
      let requestData = {
        clientId: this.tempClient.clientId, // 客户Id
        year: this.searchData.time, // 年份
        month: month, // 月份
      };
      this.$request(
        "post",
        "/invoice/getMonthInvoiceRegisterByClientId",
        requestData,
        (data) => {
          this.tempInvoiceRegister = data;
          this.invoiceRegisterDrawerData = invoiceData.getInvoiceRegisterDrawerData(
            data,
            this.tempClient.clientName
          );
          console.log("当前抽屉数据:", this.invoiceRegisterDrawerData);
          this.invoiceRegisterDrawerData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    handleClick(data, flag) {},
    /**
     * 抽屉或dialog操作事件
     */
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "register":
          // 理票登记
          this.openRegister(this.tempMonth);
          break;
        case "operation":
          // 操作记录
          this.getInvoiceRegisterOperation();
          break;
        case "cancel":
          // 关闭

          break;

        default:
          break;
      }
    },
    /**
     * 抽屉保存事件
     */
    saveDrawerData() {
      console.log("抽屉数据:", this.invoiceRegisterDrawerData);
      let clientData = {
        id: this.tempClient.clientId, // 客户Id
        year: this.searchData.time, // 年份
        month: this.tempMonth, // 月份
      };
      let requestData = invoiceData.formatSaveInvoiceRegisterDrawerData(
        this.invoiceRegisterDrawerData.data.modelList,
        clientData,
        this.tempInvoiceRegister.id == null ? "" : this.tempInvoiceRegister.id
      );
      this.$request(
        "post",
        "/invoice/addClearInvoice",
        requestData,
        (data) => {
          this.$message({
            message: "操作成功!",
            type: "success",
            duration: 1000,
          });
          // this.openRegister(this.tempMonth);
          this.saveInvoiceRegisterOperation(
            "修改" + this.searchData.time + "-" + this.tempMonth + "理票记录",
            data.id
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },

    /**
     * 保存操作记录
     */
    saveInvoiceRegisterOperation(operation, id) {
      let requestData = {
        id: id,
        userId: this.baseInfo.userId, // 当前操作人
        operation: operation, // 当前具体操作
      };
      this.$request(
        "post",
        "/invoice/saveInvoiceRegisterOperation",
        requestData,
        (data) => {
          this.initTable();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },

    /**
     * 获取当前客户的所有理票操作记录
     */
    getInvoiceRegisterOperation() {
      let requestData = {
        clientId: this.tempClient.clientId,
      };
      this.$request(
        "post",
        "/invoice/getInvoiceRegisterOperation",
        requestData,
        (data) => {
          this.invoiceRegisterDrawerData = invoiceData.getInvoiceRegisterOperationDrawerData(
            data,
            this.tempClient.clientName
          );
          this.invoiceRegisterDrawerData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },

    /**
     * 右边抽屉父子组件连锁事件
     */
    showDrawer(value) {
      this.invoiceRegisterDrawerData = value;
    },
    /**
     * 右边抽屉关闭事件
     */
    closeUpdateTableDrawer() {
      this.invoiceRegisterDrawerData.flag = false;
      // this.initTableData();
      // this.saveDrawerData();
    },
    /**
     * 弹出框关闭事件
     */
    colseDialog() {
      // this.dialogData.flag = false;
      // this.dialogData.data.forEach((element) => {
      //   if (element.value) {
      //     element.value = "";
      //   }
      // });
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
  },
  mounted() {
    this.loading = true;
    this.isCharge();
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#invoice-register {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .invoice-register-screen-fiftrate {
    width: 65px;
    min-width: 65px;
    height: 30px;
    border: 1px solid @boderColor;
    box-sizing: border-box;
    color: @boderActiveColor;
    font-size: @contentFontSize;
    margin: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    transition: 0.3s;
    i {
      margin: 0 4px;
    }
  }
  .invoice-register-screen-fiftrate:hover {
    cursor: pointer;
    border-color: @boderActiveColor;
  }
  .invoice-register-screen-search {
    width: 210px;
    min-width: 210px;
    height: 30px;
  }
  .invoice-register-screen-checked {
    width: 140px;
    height: 30px;
    min-width: 140px;
    font-size: @contentFontSize;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .invoice-register-screen-active {
    flex: 1;
  }
  .invoice-register-screen-but-select {
    width: 140px;
    min-width: 160px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @activeColor;
    font-size: @contentFontSize;
    color: #fff;
    margin: 0 5px;
    border-radius: 4px;
    transition: 0.5s;
    cursor: pointer;
    .invoice-register-screen-but-select_but {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.5s;
    }
    .invoice-register-screen-but-select_but:hover {
      background-color: @activeColor2;
    }
    .invoice-register-screen-but-select_select {
      width: 30px;
      height: 100%;
      border-left: 1px solid @activeColor3;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .invoice-register-screen-but {
    width: 100px;
    min-width: 100px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @activeColor;
    font-size: @contentFontSize;
    color: #fff;
    margin: 0 5px;
    border-radius: 4px;
    transition: 0.5s;
  }
  .invoice-register-screen-but:hover {
    cursor: pointer;
    background-color: @activeColor2;
  }
  .invoice-register-screen-but-boder {
    width: 50px;
    min-width: 50px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid @activeColor;
    font-size: @contentFontSize;
    background-color: @activeBackground;
    color: @activeColor;
    margin: 0 5px;
    margin-right: 10px;
    border-radius: 4px;
    transition: 0.5s;
  }
  .invoice-register-screen-but-boder:hover {
    cursor: pointer;
    background-color: @activeBackground2;
  }
  .table-content {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    .invoice-register-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .invoice-register-content {
        width: 98%;
        height: 95%;
        max-width: 90vw;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        .line-cell {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          cursor: pointer;
          i {
            margin: 5px;
          }
        }
        .gray {
          color: rgb(146, 146, 146, 0.637);
        }
        .orange {
          color: rgba(255, 115, 0, 0.637);
        }
        .green {
          color: rgb(9, 255, 0, 0.637);
        }
      }
      .invoice-register-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }
}
</style>