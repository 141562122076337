<template>
  <div id="tax-park">
    <!-- 企业园区 -->
    <div class="tax-park-screen">
      <div class="tax-park-screen-line">
        <div class="tax-park-screen-search">
          <el-input
            v-model="searchData.input"
            placeholder="请输入客户全称"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="tax-park-screen-search">
          <el-cascader
            v-model="searchData.department"
            placeholder="请选择部门"
            :options="searchData.departTreeData"
            @change="initTableData"
            clearable
            :show-all-levels="false"
            :props="{ checkStrictly: true, value: 'id' }"
            filterable
          ></el-cascader>
        </div>
        <div class="tax-park-screen-search">
          <el-select
            v-model="searchData.userId"
            @change="selectInitTableData"
            filterable
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>

        <div class="tax-park-screen-search">
          <el-date-picker
            v-model="searchData.year"
            type="year"
            placeholder="选择年"
            :clearable="false"
            value-format="yyyy"
            @change="initTableData"
          >
          </el-date-picker>
        </div>
        <div class="tax-park-screen-active"></div>
        <div
          class="tax-park-screen-but-boder"
          @click="handleClick('', 0)"
          v-showFunction="{
            active: 'setting',
            array: allFunctionButtons,
          }"
        >
          设置项
        </div>
      </div>
      <div class="tax-park-screen-line">
        <div class="tax-park-screen-search">
          <el-select
            v-model="searchData.fieldName"
            @change="initTableData"
            filterable
            clearable
            placeholder="请选择月份异常数据"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="tax-park-screen-search">
          <el-select
            v-model="searchData.localPark"
            @change="initTableData"
            filterable
            clearable
            placeholder="请选择所在园区"
          >
            <el-option
              v-for="item in parkList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="tax-park-screen-active"></div>
      </div>
    </div>
    <div class="tax-park">
      <div class="tax-park-table-card">
        <div class="tax-park-content">
          <el-table
            :data="tableData"
            :header-cell-style="{ background: '#F5F7FA', color: '#606266' }"
            highlight-current-row
            style="width: 100%"
            height="98%"
            border
            v-loading="loading"
            :row-style="{ height: '60px' }"
            @sort-change="sortChange"
          >
            <el-table-column type="index" width="60" fixed></el-table-column>
            <el-table-column
              v-for="(item, index) in tableMenu"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              :fixed="item.fixed"
              :width="item.width"
              align="center"
              show-overflow-tooltip
              :sortable="item.type == 'select-salary' ? 'custom' : false"
            >
              <template slot="header" v-if="item.type == 'select-salary'">
                <el-row>
                  <el-col>{{ item.label }}</el-col>
                  <el-col>{{ "(所属" + item.index + ")" }}</el-col>
                </el-row>
              </template>
              <template slot-scope="scope">
                <el-select
                  v-if="item.type == 'select-park'"
                  v-model="scope.row[item.prop]"
                  :name="scope.row[item.prop]"
                  :disabled="editFlag"
                  @change="changeItem($event, scope.row, item.prop)"
                >
                  <el-option
                    v-for="it in parkList"
                    :key="it.value"
                    :value="it.value"
                    :label="it.label"
                  >
                  </el-option>
                </el-select>
                <el-popover
                  trigger="click"
                  placement="top"
                  v-else-if="
                    item.type == 'select-salary' && scope.row[item.prop]
                  "
                >
                  <span
                    v-for="(it, ind) in scope.row[item.prop].list"
                    :key="ind"
                  >
                    <p v-if="it.name && it.value">
                      {{ it.name + ":" + it.value }}
                    </p>
                  </span>
                  <div slot="reference" class="name-wrapper">
                    <el-input
                      v-model="scope.row[item.prop].value"
                      :class="scope.row[item.prop].color"
                      :disabled="editFlag"
                      @keyup.enter.native="
                        changeItem(
                          scope.row[item.prop].value,
                          scope.row,
                          item.prop,
                          scope.$index
                        )
                      "
                    ></el-input>
                  </div>
                </el-popover>
                <span v-else>{{ scope.row[item.prop] }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="tax-park-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-sizes="[20, 50, 100, 200]"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :clientName="dialogData.clientName"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import * as taxData from "@/views/tax/js/tax.js";
import my_dialog from "@/components/dialog.vue";

export default {
  components: {
    my_dialog,
  },
  data() {
    return {
      pictLoading: false,
      tabIndex: 0,
      loading: false,
      baseInfo: {},
      tableData: [],
      searchData: {
        input: "",
        department: "",
        departTreeData: [],
        userId: "",
        userList: [],
        userIdList: [],
        tempUserId: [],
        year: this.$formatTime(new Date(), "YYYY"),
        companyType: "",
        fieldName: "",
        localPark: "",
      },
      allFunctionButtons: this.$featureData.getAllFunctionButtons("taxPark"),
      tableMenu: taxData.taxParkTableMenu(),
      tableData: [],
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 20, // 每页条数
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "运单详情",
        buttonArray: [
          {
            text: "确认",
            active: "confirm",
          },
        ],
        data: [],
      },
      tempData: {},
      whetherAgencyHead: false,
      parkList: [],
      editFlag: true,
      value: [],
      options: this.$systemBaseData.getMonthOptions(),
      monthList: this.$systemBaseData.getMonthList(),
      defaultSort: {
        prop: "",
        order: "",
      },
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "taxPark",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.editFlag = !this.allFunctionButtons.some(
            (item) => item.key == "edit" && item.authority == "1"
          );
          this.isCharge();
          console.log("当前显示的按钮权限", data);
          console.log("this.allFunctionButtons", this.allFunctionButtons);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getDepartmentTreeByUserId();
      });
    },
    /**
     * 获取部门
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
          this.getEmployeeList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.searchData.userList = [];
          data.forEach((element) => {
            let dd = {
              name: element.userName,
              id: element.userId,
            };
            this.searchData.userIdList.push(element.userId);
            this.searchData.userList.push(dd);
          });
          this.searchData.userList.splice(0, 0, {
            id: "all",
            name: "全部人员",
          });
          this.searchData.userId = this.baseInfo.userId;
          this.selectInitTableData(this.baseInfo.userId);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取流水设置项
     */
    queryByMark() {
      this.$request(
        "get",
        "/dataDictionary/queryByMark?mark=taxPark",
        {},
        (data) => {
          this.parkList = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      this.tableData = [];
      this.loading = true;
      let requestData = {
        clientName: this.searchData.input, // 客户简称
        year: this.searchData.year, // 日期
        fieldName: this.searchData.fieldName, // 异常月份
        localPark: this.searchData.localPark, // 园区搜索
        department:
          this.searchData.department.length > 0
            ? this.searchData.department[this.searchData.department.length - 1]
            : "", // 部门 id
        userIdList: this.searchData.tempUserId, // 员工 id 集合
        sortProp: this.defaultSort.prop, // 手动排序项
        sortOrder: this.defaultSort.order, // 手动排序规则 ascending descending ""
        pageSize: this.pageData.pageSize, // 每页条数
        currentPage: this.pageData.currentPage, // 当前页数
      };
      if (this.value.length > 0) {
        requestData[this.value[0]] = this.value[1];
      }
      this.$request(
        "post",
        "/taxPark/page",
        requestData,
        (data) => {
          this.tableData = data.records;
          this.tableData.forEach((item) => {
            item.host = item.host.join(",");
            item.consultant = item.consultant.join(",");
            this.monthList.forEach((monthItem) => {
              item[monthItem].flag = false;
              // 判断是否都有值
              if (item[monthItem].value == "异常") {
                // 异常为 红色
                item[monthItem].color = "red";
              } else {
                let list = item[monthItem].list.filter((item) => item.value);
                if (list.length > 1) {
                  // 多人编辑为 绿色
                  item[monthItem].color = "green";
                } else {
                  item[monthItem].color = "";
                }
                // 没填 或者 只有一个人编辑 颜色不变
              }
            });
          });
          console.log("this.tableData", this.tableData);
          this.pageData.total = data.total; // 获取总条目数
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    /**
     * 界面操作
     */
    handleClick(data, flag) {
      this.tempData = data;
      let requestData = {};
      switch (flag) {
        case 0:
          // 设置项
          this.$request(
            "get",
            "/dataDictionary/queryByMark?mark=taxPark",
            requestData,
            (data) => {
              this.dialogData.title = "设置项编辑";
              this.dialogData.width = "30%";
              this.dialogData.buttonArray = [
                {
                  text: "取消",
                  active: "cancel",
                },
              ];
              this.dialogData.data = [
                {
                  title: "",
                  type: "tax-drawer",
                  value: data,
                },
              ];
              this.dialogData.data[0].value = {
                title: "园区配置项",
                list: data,
              };
              this.dialogData.flag = true;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        default:
          break;
      }
    },
    /**
     * 编辑银行授权状态 和 流水状态
     */
    changeItem(value, row, prop, index) {
      let requestData = {
        clientId: row.clientId, // 客户id
        userId: this.baseInfo.userId, // 用户 id
        year: this.searchData.year, // 年份
      };
      requestData[prop] = value;
      let flag = this.$numberReg(value);
      if (prop !== "localPark" && !flag) {
        this.$message.error("请填写数字再进行编辑");
        return;
      }
      this.$request(
        "post",
        "/taxPark/edit",
        requestData,
        (data) => {
          this.$message.success("编辑成功!");
          // this.initTableData();
          if (prop !== "localPark") {
            this.tableData[index][prop].list = data[prop].list;
            this.tableData[index][prop].value = data[prop].value;
            if (data[prop].value == "异常") {
              this.tableData[index][prop].color = "red";
            } else {
              let list = data[prop].list.filter((item) => item.value);
              if (list.length > 1) {
                // 多人编辑为 绿色
                this.tableData[index][prop].color = "green";
              } else {
                this.tableData[index][prop].color = "";
              }
            }
          }
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 抽屉数据或dialog数据操作事件
     */
    drawerOper(active, data) {
      let requestData = {};
      switch (active) {
        case "add":
          requestData = {
            mark: "taxPark",
            label: data,
            value: data,
          };
          this.$request(
            "post",
            "/dataDictionary/add",
            requestData,
            (data) => {
              this.$message.success("添加成功!");
              this.handleClick("", 0);
              this.queryByMark();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "del":
          requestData = {
            id: this.dialogData.data[0].value.list[data].id,
          };
          this.$request(
            "get",
            `/dataDictionary/delete?id=${requestData.id}`,
            requestData,
            (data) => {
              this.$message.success("删除成功!");
              this.handleClick("", 0);
              this.queryByMark();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "cancel":
          this.colseDialog();
          break;
        default:
          break;
      }
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    /**
     * 员工选择
     */
    selectInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.searchData.userIdList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.initTableData();
    },
    /**
     * 表格排序事件
     */
    sortChange(data) {
      const prop = this.$toLowerCase(data.prop);
      this.defaultSort.prop = data.order == null ? "" : prop;
      this.defaultSort.order = data.order == null ? "" : data.order;
      this.initTableData();
    },
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.queryByMark();
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#tax-park {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .tax-park-screen {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    .tax-park-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      > .title {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 0 10px;
      }
      > .el-tag {
        margin: 0 5px;
      }
      .tax-park-screen-fiftrate {
        width: 65px;
        min-width: 65px;
        height: 30px;
        border: 1px solid @boderColor;
        box-sizing: border-box;
        color: @boderActiveColor;
        font-size: @contentFontSize;
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: 0.3s;
        i {
          margin: 0 4px;
        }
      }
      .tax-park-screen-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }
      .tax-park-screen-search {
        width: 210px;
        min-width: 210px;
        height: 30px;
        margin-left: 10px;
        display: flex;
        align-items: center;
      }
      .tax-park-screen-active {
        flex: 1;
      }
      .drop-button {
        background-color: #ecf5ff;
        color: #409eff;
        &:hover {
          background-color: #abbed3;
        }
      }
      .tax-park-screen-but {
        width: 100px;
        min-width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .tax-park-screen-but:hover {
        cursor: pointer;
        background-color: @activeColor2;
      }
      .tax-park-screen-but-boder {
        min-width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        font-size: @contentFontSize;
        background-color: @activeBackground;
        color: @activeColor;
        margin: 0 5px;
        margin-right: 10px;
        border-radius: 4px;
        transition: 0.5s;
        padding: 0 15px;
      }
      .tax-park-screen-but-boder:hover {
        cursor: pointer;
        background-color: @activeBackground2;
      }
    }
  }

  .tax-park {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;

    .tax-park-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .tax-park-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .tax-park-table-card-tab {
          width: 120px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .tax-park-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
      }
      .tax-park-content {
        width: 100%;
        height: 95%;
        max-width: 90vw;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
        display: flex;
        flex-direction: column;

        /deep/ .green .el-input__inner {
          color: #67c23a;
        }
        /deep/ .red .el-input__inner {
          color: #f56c6c;
        }
        /deep/ .el-input__inner {
          border: 0;
          background-color: #fff;
        }
        /deep/ .el-input__suffix {
          display: none;
        }
        /deep/ .el-table--scrollable-y .el-table__body-wrapper {
          z-index: 1;
        }
      }
      .tax-park-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
