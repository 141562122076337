import Vue from 'vue'
/**
 * 商机的二级菜单数据
 */
export function getMenuData() {
    return [{
            title: "客户",
            icon: "el-icon-s-help",
            path: "1",
            flag: 1,
            children: [{
                    title: "客户资料",
                    path: "client-info",
                    active: "clientInfo",
                    flag: 1,
                },
                {
                    title: "工作交接",
                    path: "client-connect",
                    active: "clientConnect",
                    flag: 1,
                },
                {
                    title: "流失审核",
                    path: "client-check",
                    active: "clientCheck",
                    flag: 1,
                },
            ],
        },
        {
            title: "设置",
            icon: "el-icon-s-tools",
            path: "client-setting",
            active: "clientSetting",
            flag: 1,
            children: [],
        },
    ]
}

/**
 * 获取客户信息中的抽屉数据格式
 * @param {*} title  当前客户名称
 * @param {*} authorityData  权限数据
 * @param {*} isHead  是否是机构负责人
 * @param {*} options 所有下拉框数组
 * @param {*} flag 当前打开的子页面
 * 
 * options:{
 * allUser:[], // 所有用户
 * areaList:[], // 所有片区
 * sourceList:[], // 客户所有来源
 * addedValueTaxTypeList:[], // 增值税类型
 * levelList:"", // 客户等级
 * tipList:"", // 客户标签
 * }
 * 
 * {
 * label:"",
 * value:""
 * }
 */
export function clientInfoDrawerData(title, authorityData, isHead, options, flag, input) {

    let clientInfoDrawerData = getDrawerData(title, options, flag)
        // 拼接数据显示权限
    clientInfoDrawerData = Vue.prototype.$initDrawerAuthorityData.initDrawerAuthorityData(clientInfoDrawerData, authorityData, isHead)

    return clientInfoDrawerData
}

export function getDrawerData(title, options, flag, input, _this) {
    let clientInfoDrawerData = {
        data: {
            modelList: []
        } 
    }
    switch (flag) {
        case "客户信息":
            clientInfoDrawerData = {
                title: "客户",
                flag: false,
                CardList: [{
                        text: "客户信息",
                        active: "info",
                        key: "cancel",
                    }, {
                        text: "联系人",
                        active: "lianxiren",
                        key: "cancel",
                    },
                    {
                        text: "合同",
                        active: "hetong",
                        key: "cancel",
                    },
                    {
                        text: "资质",
                        active: "qualification",
                        key: "showQualification",
                    },
                    {
                        text: "档案",
                        active: "archives",
                        key: "archives",
                    },
                    {
                        text: "云盘",
                        active: "cloud",
                        key: "cloud",
                    },
                ],
                cardIndex: 0,
                buttonList: [{
                    text: "跟进",
                    icon: "el-icon-edit-outline",
                    active: "genjin",
                    key: "followUp"
                }, ],
                hasSetting: true,
                settingOperList: [{
                    text: "删除客户",
                    active: "delete_client",
                    key: "delete"
                }, {
                    text: "申请客户流失",
                    active: "run_off",
                    key: "lossOf"
                }, 
                {
                    text: "客户流失恢复",
                    active: "recover",
                    key: "recover"
                }, {
                    text: "添加关联客户",
                    authority: 1,
                    active: "adding_associated_customer",
                    key: "associat"
                }, {
                    text: "操作记录",
                    active: "operating_record",
                    key: "cancel"
                }, ],
                data: {
                    title: title,
                    customer: 3, // 关联客户,与商机状态关联,(只有商机状态为成交才有值),其他时候为空字符串
                    gridData: [], // 关联客户数组
                    isDisable: true, // 是否只读
                    modelList: [{
                            line: [
                                // {
                                //         horizontal: true,
                                //         isShow: true,
                                //         isDisable: false,
                                //         title: "客户类型",
                                //         key: "type",
                                //         type: "text",
                                //         value: "",
                                //     },
                                {
                                    horizontal: true,
                                    isShow: true,
                                    isDisable: false,
                                    title: "客户等级",
                                    type: "select",
                                    key: "clientLevel",
                                    value: "",
                                    options: options.levelList ? options.levelList : [],
                                },
                                {
                                    horizontal: false,
                                    isShow: true,
                                    isDisable: false,
                                    title: "片区",
                                    type: "select",
                                    key: "zone",
                                    value: "",
                                    options: options.areaList ? options.areaList : [],
                                },
                                {
                                    horizontal: false,
                                    isShow: true,
                                    isDisable: false,
                                    title: "来源",
                                    key: "source",
                                    type: "selects-label",
                                    value: "",
                                    options: options.sourceList ? options.sourceList : [],
                                },
                            ],
                        },
                        {
                            line: [{
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                isSelect: false,
                                title: "客户标签",
                                key: "tip",
                                type: "tags-select",
                                tempValue: "",
                                value: [],
                                options: options.tipList ? options.tipList : [],
                            }, ],
                        },
                        {
                            line: [{
                                    horizontal: false,
                                    isShow: true,
                                    isDisable: false,
                                    title: "企业全称",
                                    key: "clientName",
                                    type: "input-but",
                                    value: "",
                                    buts: [{
                                        text: "工商信息",
                                        active: "get_companyInfo"
                                    }]
                                },
                                {
                                    horizontal: false,
                                    isShow: true,
                                    isDisable: false,
                                    title: "企业税号",
                                    key: "enterpriseNumber",
                                    type: "input",
                                    value: "",
                                },
                                {
                                    horizontal: false,
                                    isShow: true,
                                    isDisable: false,
                                    title: "客户编号",
                                    key: "clientNum",
                                    type: "select-allow-create",
                                    value: "",
                                    options: options.clientNumberList ? options.clientNumberList : [],
                                },
                                {
                                    horizontal: false,
                                    isShow: true,
                                    isDisable: false,
                                    title: "增值税",
                                    key: "addedValueTaxType",
                                    type: "select",
                                    value: "",
                                    options: options.addedValueTaxTypeList ? options.addedValueTaxTypeList : [],
                                },
                                {
                                    horizontal: false,
                                    isShow: true,
                                    isDisable: false,
                                    title: "行业分类",
                                    key: "industryType",
                                    type: "input",
                                    value: "",
                                },
                                {
                                    horizontal: true,
                                    isShow: true,
                                    isDisable: false,
                                    title: "所在地区",
                                    key: "area",
                                    type: "area",
                                    value: [],
                                },
                                {
                                    horizontal: false,
                                    isShow: true,
                                    isDisable: false,
                                    title: "详细地址",
                                    key: "address",
                                    type: "input",
                                    value: "",
                                },
                                {
                                    horizontal: false,
                                    isShow: true,
                                    isDisable: false,
                                    title: "联系电话",
                                    key: "tel",
                                    type: "input",
                                    value: "",
                                },
                                {
                                    horizontal: false,
                                    isShow: true,
                                    isDisable: false,
                                    title: "开户行",
                                    key: "bankName",
                                    type: "input",
                                    value: "",
                                },
                                {
                                    horizontal: false,
                                    isShow: true,
                                    isDisable: false,
                                    title: "银行账号",
                                    key: "bankAccount",
                                    type: "input",
                                    value: "",
                                },
                                {
                                    horizontal: false,
                                    isShow: true,
                                    isDisable: false,
                                    title: "开票客户",
                                    key: "isInvoicing",
                                    type: "radio",
                                    value: "N",
                                    options: [{
                                        label: "Y",
                                        text: "是",
                                    }, {
                                        label: "N",
                                        text: "否",
                                    }, ],
                                },
                                {
                                    horizontal: false,
                                    isShow: true,
                                    isDisable: false,
                                    title: "包含社保",
                                    key: "isSocialSecurity",
                                    type: "radio",
                                    value: "N",
                                    options: [{
                                        label: "Y",
                                        text: "是",
                                    }, {
                                        label: "N",
                                        text: "否",
                                    }, ],
                                },
                                {
                                    horizontal: false,
                                    isShow: true,
                                    isDisable: false,
                                    title: "包含流水",
                                    key: "isStatement",
                                    type: "radio",
                                    value: "N",
                                    options: [{
                                        label: "Y",
                                        text: "是",
                                    }, {
                                        label: "N",
                                        text: "否",
                                    }, ],
                                },
                                {
                                    horizontal: false,
                                    isShow: true,
                                    isDisable: false,
                                    title: "包含园区",
                                    key: "isTaxPark",
                                    type: "radio",
                                    value: "N",
                                    options: [{
                                        label: "Y",
                                        text: "是",
                                    }, {
                                        label: "N",
                                        text: "否",
                                    }, ],
                                },
                                {
                                    horizontal: false,
                                    isShow: true,
                                    isDisable: false,
                                    title: "包含财务数据",
                                    key: "isFinancialData",
                                    type: "radio",
                                    value: "N",
                                    options: [{
                                        label: "Y",
                                        text: "是",
                                    }, {
                                        label: "N",
                                        text: "否",
                                    }, ],
                                },
                            ],
                        },
                        {
                            line: [{
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                isSelect: false,
                                tempValue: "",
                                title: "其他服务人员",
                                key: "role",
                                type: "tags-select-obj",
                                value: [],
                                options: options.allUser ? options.allUser : [],
                            }, ],
                        },
                    ],
                },
            }
            break;
        case "联系人":
            let item_data = {
                line: [{
                    horizontal: true,
                    isShow: true,
                    isDisable: false,
                    title: "姓名",
                    type: "input-buts",
                    key1: "name",
                    value: "新建联系人",
                    buts: [{
                        text: "删除",
                        active: "shanchu"
                    }, ]
                }, {
                    horizontal: true,
                    isShow: true,
                    isDisable: false,
                    title: "职位",
                    type: "select",
                    key1: "position",
                    value: "老板",
                    options: [{
                            label: "老板",
                            value: "老板",
                        }, {
                            label: "法人",
                            value: "法人",
                        },
                        {
                            label: "股东",
                            value: "股东",
                        },
                        {
                            label: "会计",
                            value: "会计",
                        },
                        {
                            label: "其他",
                            value: "其他",
                        },
                    ],
                }, {
                    horizontal: true,
                    isShow: true,
                    isDisable: false,
                    title: "电话",
                    key1: "phone",
                    type: "input",
                    value: "",
                }, {
                    horizontal: true,
                    isShow: true,
                    isDisable: false,
                    title: "手机",
                    key1: "mobile",
                    type: "input",
                    value: "",
                }, {
                    horizontal: true,
                    isShow: true,
                    isDisable: false,
                    title: "邮件",
                    key1: "email",
                    type: "input",
                    value: "",
                }, {
                    horizontal: true,
                    isShow: true,
                    isDisable: false,
                    title: "备注",
                    key1: "des",
                    type: "input",
                    value: "",
                }, ],
            }
            clientInfoDrawerData = {
                title: "客户",
                flag: false,
                CardList: [{
                        text: "客户信息",
                        active: "info",
                        key: "cancel",
                    }, {
                        text: "联系人",
                        active: "lianxiren",
                        key: "cancel",
                    },
                    {
                        text: "合同",
                        active: "hetong",
                        key: "cancel",
                    },
                    {
                        text: "资质",
                        active: "qualification",
                        key: "showQualification",
                    },
                    {
                        text: "档案",
                        active: "archives",
                        key: "archives",
                    },
                    {
                        text: "云盘",
                        active: "cloud",
                        key: "cloud",
                    },
                ],
                cardIndex: 1,
                buttonList: [{
                    text: "跟进",
                    icon: "el-icon-edit-outline",
                    active: "genjin",
                    key: "followUp"
                }, ],
                hasSetting: true,
                settingOperList: [{
                    text: "删除客户",
                    active: "delete_client",
                    key: "delete"
                }, {
                    text: "申请客户流失",
                    active: "run_off",
                    key: "lossOf"
                }, {
                    text: "添加关联客户",
                    authority: 1,
                    active: "adding_associated_customer",
                    key: "associat"
                }, {
                    text: "操作记录",
                    active: "operating_record",
                    key: "cancel"
                }, ],
                data: {
                    title: title,
                    customer: 0, // 关联客户,与商机状态关联,(只有商机状态为成交才有值),其他时候为空字符串
                    isDisable: true, // 是否只读
                    butArray: [{
                        text: "新建联系人",
                        active: "newContact",
                        authority: 1
                    }],
                    modelList: [item_data],
                },
            }
            break

        case "合同":
            clientInfoDrawerData = {
                title: "客户",
                flag: false,
                CardList: [{
                        text: "客户信息",
                        active: "info",
                        key: "cancel",
                    }, {
                        text: "联系人",
                        active: "lianxiren",
                        key: "cancel",
                    },
                    {
                        text: "合同",
                        active: "hetong",
                        key: "cancel",
                    },
                    {
                        text: "资质",
                        active: "qualification",
                        key: "showQualification",
                    },
                    {
                        text: "档案",
                        active: "archives",
                        key: "archives",
                    },
                    {
                        text: "云盘",
                        active: "cloud",
                        key: "cloud",
                    },
                ],
                cardIndex: 2,
                buttonList: [{
                    text: "跟进",
                    icon: "el-icon-edit-outline",
                    active: "genjin",
                    key: "followUp"
                }, ],
                hasSetting: true,
                settingOperList: [{
                    text: "删除客户",
                    active: "delete_client",
                    key: "delete"
                }, {
                    text: "申请客户流失",
                    active: "run_off",
                    key: "lossOf"
                }, {
                    text: "添加关联客户",
                    authority: 1,
                    active: "adding_associated_customer",
                    key: "associat"
                }, {
                    text: "操作记录",
                    active: "operating_record",
                    key: "cancel"
                }, ],
                data: {
                    title: title,
                    customer: 0, // 关联客户,与商机状态关联,(只有商机状态为成交才有值),其他时候为空字符串
                    isDisable: true, // 是否只读
                    butArray: [{
                        text: "新建合同",
                        active: "create_contract",
                        key: "createContract"
                    }],
                    status: "",
                    modelList: [{
                        line: [{
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            title: "",
                            type: "table",
                            summary: false,
                            menu: [{
                                key: "contractNumber",
                                value: "合同编号",
                                type: "link",
                                width: "100",
                            }, {
                                key: "services",
                                value: "服务项",
                                type: "array",
                                width: "150",
                            }, {
                                key: "status",
                                value: "合同状态",
                                width: "100",
                                type: "text",
                            }, {
                                key: "money",
                                value: "合同金额",
                                type: "text",
                                width: "100",
                            }, {
                                key: "auditedIncome",
                                value: "已收款",
                                type: "text",
                                width: "100",
                            }, {
                                key: "accruedAssets",
                                value: "未收款",
                                type: "text",
                                width: "100",
                            }, {
                                key: "signTime",
                                value: "签订时间",
                                type: "text",
                                width: "100",
                            }, {
                                key: "startToEnd",
                                value: "合同期限",
                                type: "text",
                                width: "150",
                            }],
                            tableData: []
                        }],
                    }],
                },
            }
            break;
        case "资质":
            clientInfoDrawerData = {
                title: "客户",
                flag: false,
                CardList: [{
                        text: "客户信息",
                        active: "info",
                        key: "cancel",
                    }, { 
                        text: "联系人",
                        active: "lianxiren",
                        key: "cancel",
                    },
                    {
                        text: "合同",
                        active: "hetong",
                        key: "cancel",
                    },
                    {
                        text: "资质",
                        active: "qualification",
                        key: "showQualification",
                    },
                    {
                        text: "档案",
                        active: "archives",
                        key: "archives",
                    },
                    {
                        text: "云盘",
                        active: "cloud",
                        key: "cloud",
                    },
                   
                ],
                cardIndex: 3,
                buttonList: [{
                    text: "跟进",
                    icon: "el-icon-edit-outline",
                    active: "genjin",
                    key: "followUp"
                }, ],
                hasSetting: true,
                settingOperList: [{
                    text: "删除客户",
                    active: "delete_client",
                    key: "delete"
                }, {
                    text: "申请客户流失",
                    active: "run_off",
                    key: "lossOf"
                }, {
                    text: "添加关联客户",
                    authority: 1,
                    active: "adding_associated_customer",
                    key: "associat"
                }, {
                    text: "操作记录",
                    active: "operating_record",
                    key: "cancel"
                }, ],
                data: {
                    title: title,
                    customer: 0, // 关联客户,与商机状态关联,(只有商机状态为成交才有值),其他时候为空字符串
                    isDisable: true, // 是否只读
                    butArray: [{
                        text: "新建资质",
                        active: "create_qualification",
                        key: "cancel"
                            // key: "addQualification"
                    }],
                    status: "",
                    modelList: [{
                        line: [{
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            title: "",
                            type: "table",
                            summary: false,
                            menu: [{
                                key: "name",
                                value: "资质名称",
                                type: "text",
                                width: "150",
                            }, {
                                key: "type",
                                value: "资质类型",
                                type: "text",
                                width: "100",
                            }, {
                                key: "startServiceDate",
                                value: "开始时间",
                                width: "100",
                                type: "text",
                            }, {
                                key: "endServiceDate",
                                value: "到期日期",
                                type: "text",
                                width: "100",
                            }, {
                                key: "certificate",
                                value: "查看附件",
                                type: "file",
                                width: "100",
                            }, {
                                key: "certificate",
                                value: "操作",
                                type: "buts",
                                width: "100",
                                buts: [
                                    { name: "编辑", active: "editQualification", icon: "el-icon-delete", key: "editQualification" },
                                    { name: "删除", active: "deleteQualification", icon: "el-icon-delete", key: "deleteQualification" },                                    
                                ]
                            }],
                            tableData: []
                        }],
                    }],
                },
            }
            break;
        case "档案":
            clientInfoDrawerData = {
                title: "客户",
                flag: false,
                CardList: [{
                        text: "客户信息",
                        active: "info",
                        key: "cancel",
                    }, { 
                        text: "联系人",
                        active: "lianxiren",
                        key: "cancel",
                    },
                    {
                        text: "合同",
                        active: "hetong",
                        key: "cancel",
                    },
                    {
                        text: "资质",
                        active: "qualification",
                        key: "showQualification",
                    },
                    {
                        text: "档案",
                        active: "archives",
                        key: "archives",
                    },
                    {
                        text: "云盘",
                        active: "cloud",
                        key: "cloud",
                    },
                   
                ],
                cardIndex: 4,
                buttonList: [{
                    text: "跟进",
                    icon: "el-icon-edit-outline",
                    active: "genjin",
                    key: "followUp"
                }, ],
                hasSetting: true,
                settingOperList: [{
                    text: "删除客户",
                    active: "delete_client",
                    key: "delete"
                }, {
                    text: "申请客户流失",
                    active: "run_off",
                    key: "lossOf"
                }, {
                    text: "添加关联客户",
                    authority: 1,
                    active: "adding_associated_customer",
                    key: "associat"
                }, {
                    text: "操作记录",
                    active: "operating_record",
                    key: "cancel"
                }, ],
                data: {
                    title: title,
                    customer: 0, // 关联客户,与商机状态关联,(只有商机状态为成交才有值),其他时候为空字符串
                    isDisable: true, // 是否只读
                    butArray: [{
                        text: "新增档案",
                        active: "create_archives",
                        key: "addArchives"
                    }],
                    status: "",
                    modelList: [{
                        line: [{
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            title: "",
                            type: "table",
                            summary: false,
                            menu: [
                                {
                                    key: "name",
                                    value: "录入物品",
                                    type: "text",
                                    width: "100",
                                }, {
                                    key: "location",
                                    value: "录入位置",
                                    type: "text",
                                    width: "110",
                                }, {
                                    key: "createName",
                                    value: "录入人",
                                    width: "90",
                                    type: "text",
                                }, 
                                {
                                    key: "status",
                                    value: "状态",
                                    type: "text-status",
                                    width: "60",
                                },
                                {
                                    key: "gmtModified",
                                    value: "操作时间",
                                    type: "text",
                                    width: "150",
                                },{
                                    key: "certificate",
                                    value: "操作记录",
                                    type: "operating",
                                    width: "80",
                                }, {
                                    key: "certificate",
                                    value: "操作",
                                    type: "buts-dropdown",
                                    width: "65",
                                    buts: [
                                        { name: "领用", active: "get",  key: "get",},
                                        { name: "归还", active: "return", key: "return",},
                                        { name: "转移", active: "move", key: "move",},
                                        { name: "交付", active: "jiaofu",key: "jiaofu",},
                                    ]
                                }
                            ],
                            tableData: []
                        }],
                    }],
                },
            }
            break;
        case "云盘":
            clientInfoDrawerData = {
                title: "客户",
                flag: false,
                CardList: [{
                        text: "客户信息",
                        active: "info",
                        key: "cancel",
                    }, { 
                        text: "联系人",
                        active: "lianxiren",
                        key: "cancel",
                    },
                    {
                        text: "合同",
                        active: "hetong",
                        key: "cancel",
                    },
                    {
                        text: "资质",
                        active: "qualification",
                        key: "showQualification",
                    },
                    {
                        text: "档案",
                        active: "archives",
                        key: "archives",
                    },
                    {
                        text: "云盘",
                        active: "cloud",
                        key: "cloud",
                    },
                ],
                cardIndex: 5,
                buttonList: [{
                    text: "跟进",
                    icon: "el-icon-edit-outline", 
                    active: "genjin",
                    key: "followUp"
                }, ],
                hasSetting: true,
                settingOperList: [{
                    text: "删除客户",
                    active: "delete_client",
                    key: "delete"
                }, {
                    text: "申请客户流失",
                    active: "run_off",
                    key: "lossOf"
                }, {
                    text: "添加关联客户",
                    authority: 1,
                    active: "adding_associated_customer",
                    key: "associat"
                }, {
                    text: "操作记录",
                    active: "operating_record",
                    key: "cancel"
                }, ],
                multipleSelection: [],
                data: {
                    title: title,
                    customer: 0, // 关联客户,与商机状态关联,(只有商机状态为成交才有值),其他时候为空字符串
                    isDisable: true, // 是否只读
                    fileOrder: [],
                    input: input || '', // 文件名称
                    topButArray: [
                        {
                            text: "点击上传文件",
                            active: "addFile",
                            key: "addFile",
                            isDisable: false,
                        },
                        {
                            text: "新建文件夹",
                            active: "addFolder",
                            key: "addFile",
                            isDisable: false,
                        },
                        {
                            text: "重命名",
                            active: "reNameFile",
                            key: "reNameFile",
                            isDisable: true,
                        },
                        {
                            text: "移动",
                            active: "moveFile",
                            key: "moveFile",
                            isDisable: true,
                        },
                        {
                            text: "删除",
                            active: "deleteFile",
                            key: "deleteFile",
                            isDisable: true,
                        },
                        {
                            text: "下载",
                            active: "downloadFile",
                            key: "downloadFile",
                            isDisable: true,
                        }
                    ],
                    status: "",
                    modelList: [{
                        line: [{
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            title: "",
                            type: "cloud",
                            menu: [
                                {
                                    key: "image",
                                    value: "",
                                    type: "image",
                                    width: '40'
                                }, 
                                {
                                    key: "name",
                                    value: "文件名",
                                    type: "link",
                                }, {
                                    key: "includeItemsNum",
                                    value: "包含项",
                                    type: "text",
                                    width: "90",
                                }, 
                                {
                                    key: "suitableSize",
                                    value: "大小",
                                    width: "90",
                                    type: "text",
                                }, 
                                {
                                    key: "renewUserName",
                                    value: "更新人",
                                    type: "text-status",
                                    width: "90",
                                },
                                {
                                    key: "gmtModified",
                                    value: "更新时间",
                                    type: "text",
                                    width: "150",
                                },
                                {
                                    authorityKey: "visibleFile",
                                    key: "clientAppShow",
                                    value: '客户可见',
                                    type: "switch",
                                    width: "80",
                                }
                            ],
                            tableData: []
                        }],
                    }],
                    upload: {
                        value: {
                          flag: false,
                          action: _this.$fileBaseurl + "/uploadImageByMultipartFile",
                          headers: {
                            token: "95931b371eb64f4a9c862983ca8a5d15",
                          },
                          uploadParameter: {
                            systemName: "finance",
                            title: "客户云盘信息",
                          },
                        },
                    },
                },
            }
            break;
        default:
            break;
    }

    return clientInfoDrawerData
}

export function returnDrawerKeys(flag) {
    let keys = []
    let drawerData = getDrawerData("", Vue.prototype.$tagData.getDrawerOptionsList("clientInfo"), flag).data.modelList
    for (let index = 0; index < drawerData.length; index++) {
        let element = drawerData[index].line;
        for (let i = 0; i < element.length; i++) {
            let ele = element[i];
            if (ele.hasOwnProperty('key')) {
                let dd = {
                    name: ele.text,
                    key: ele.key,
                    authority: "1",
                    sequence: 1,
                    id: ""
                }
                keys.push(JSON.parse(JSON.stringify(dd)))
            }
        }

    }
    console.log("拿到当前抽屉的所有keys", drawerData, keys);
    return keys
}