<template>
  <div id="invoice-setting">
    <!-- 客户设置 -->
    <div class="screen">
      <div class="screen-line">
        <div class="invoice-setting-screen-search">
          <el-input
            placeholder="请输入客户全称/编号/税号"
            v-model="searchData.input"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="invoice-setting-screen-search">
          <el-cascader
            v-model="searchData.department"
            placeholder="请选择部门"
            :options="searchData.departTreeData"
            @change="initTableData"
            clearable
            :show-all-levels="false"
            :props="{ checkStrictly: true, value: 'id' }"
            filterable
          ></el-cascader>
        </div>
        <div class="invoice-setting-screen-search">
          <el-select
            v-model="searchData.userId"
            placeholder="请选择员工"
            filterable
            @change="selectInitTableData"
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="invoice-setting-screen-checked"></div>
        <div class="invoice-setting-screen-active"></div>
      </div>
    </div>
    <div class="table-content">
      <div class="invoice-setting-table-card">
        <div class="invoice-setting-content">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            height="98%"
            v-loading="loading"
          >
            <el-table-column type="selection" width="55" fixed>
            </el-table-column>

            <el-table-column
              type="index"
              width="55"
              fixed
              align="center"
            ></el-table-column>
            <el-table-column
              prop="clientName"
              label="客户全称"
              fixed
            ></el-table-column>
            <el-table-column
              prop="companyType"
              label="企业类型"
              fixed
            ></el-table-column>
            <el-table-column
              prop="taxNum"
              label="企业税号"
              fixed
            ></el-table-column>
            <el-table-column label="操作" width="200">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="handleClick(scope.row, 0)"
                  v-showFunction="{
                    active: 'editCommonCustomer',
                    array: allFunctionButtons,
                  }"
                  >常用客户</el-button
                >
                <el-button
                  type="text"
                  @click="handleClick(scope.row, 1)"
                  v-showFunction="{
                    active: 'drawerSetting',
                    array: allFunctionButtons,
                  }"
                  >开票人设置</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="invoice-setting-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @showCustomer="showCustomer"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import * as invoiceData from "@/assets/js/invoiceData.js";
import my_dialog from "@/components/dialog.vue";
export default {
  components: {
    my_dialog,
  },
  data() {
    return {
      baseInfo: {},
      loading: true,
      whetherAgencyHead: false,
      searchData: {
        input: "",
        checked: false,
        screenButton_add: 0,
        screenButton_improt: 0,
        department: "",
        departTreeData: [],
        roleList: [],
        userId: "",
        userList: [],
        userIdList: [],
        tempUserId: [],
      },
      tableData: [],
      buttonAuth: [],
      multipleSelection: [],
      tempClient: {},
      templateData: {},
      dialogData: {
        flag: false,
        width: "30%",
        title: "添加跟进记录",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "InvoiceSetting"
      ),
      employeeList: [],
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "InvoiceSetting",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          // this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getEmployeeList();
      });
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.employeeList = [];
          this.searchData.userList = [];
          data.forEach((element) => {
            let dd = {
              name: element.userName,
              id: element.userId,
            };
            this.searchData.userIdList.push(element.userId);
            this.employeeList.push(dd);
            this.searchData.userList.push(dd);
          });
          this.searchData.userList.splice(0, 0, {
            id: "all",
            name: "全部人员",
          });
          this.searchData.userId = "all";
          this.selectInitTableData("all");
          this.getDepartmentTreeByUserId();
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取部门
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 员工选择
     */
    selectInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.searchData.userIdList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.initTableData();
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      this.loading = true;
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        empolyeeId: this.baseInfo.userId,
        addedValueTaxType: this.searchData.addedValueTaxType, // 企业类型  一般纳税人  小规模纳税人
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
        search: this.searchData.input,
        userIdList: this.searchData.tempUserId, // 员工id
        department:
          this.searchData.department.length > 0
            ? this.searchData.department[this.searchData.department.length - 1]
            : "",
      };
      this.$request(
        "post",
        "/invoice/queryInvoiceClientByCondition",
        requestData,
        (data) => {
          this.pageData.total = data.total;
          this.tableData = data.records;
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * dialog点击事件
     */
    drawerOper(active) {
      switch (active) {
        case "submit_update_info":
          // 确定更新工商信息
          this.updateStatisticsInvoice();
          this.colseDialog();
          break;
        case "binding_client":
          // 确定绑定机柜及端口号
          this.getAllClientCabinet();
          this.colseDialog();
          break;
        case "get_client_info":
          // 获取企业工商信息
          this.getStatisticsInvoice();
          break;
        case "cancel":
          // 取消
          this.colseDialog();
          break;
        case "confirm":
          this.colseDialog();
          break;
        case "delete":
          // 删除收款人
          let requestData = {
            clientId: this.templateData.id,
            ...this.dialogData.data[0].value,
          };
          this.$request(
            "post",
            "/client/updateDrawerList",
            requestData,
            (data) => {
              this.$message.success("删除成功");
            },
            (errorMessage) => [this.$message.error(errorMessage)]
          );
          break;
        case "add":
          // 添加收款人
          let requestData2 = {
            clientId: this.templateData.id,
            ...this.dialogData.data[0].value,
          };
          this.$request(
            "post",
            "/client/updateDrawerList",
            requestData2,
            (data) => {
              this.$message.success("添加成功");
            },
            (errorMessage) => [this.$message.error(errorMessage)]
          );
          break;

        default:
          break;
      }
    },
    /**
     * 界面点击事件
     */
    handleClick(data, flag) {
      switch (flag) {
        case 0:
          // 常用客户设置
          let requestData = {
            clientId: data.id,
          };
          this.$request(
            "post",
            "/activeCustomer/queryAllCustomers",
            requestData,
            (redata) => {
              this.tempClient = JSON.parse(JSON.stringify(data));
              this.dialogData.title = data.clientName + " -- 常用客户";
              this.dialogData.width = "70%";
              this.dialogData.buttonArray = [];
              this.dialogData.data = [
                {
                  title: "",
                  type: "dialog_customers",
                  data: {
                    clientId: this.tempClient.id,
                    // list: [],
                    list: redata,
                  },
                },
              ];
              this.dialogData.flag = true;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case 1:
          // 开票人设置
          this.templateData = data;
          let requestData2 = {
            clientId: data.id,
          };
          this.$request(
            "post",
            "/client/getDrawerList",
            requestData2,
            (data) => {
              this.dialogData.title = "人员设置";
              this.dialogData.width = "30%";
              this.dialogData.buttonArray = [
                {
                  text: "确定",
                  active: "confirm",
                },
                {
                  text: "取消",
                  active: "cancel",
                },
              ];
              this.dialogData.data = [
                {
                  title: "",
                  type: "invoice-drawer",
                  value: data,
                },
              ];
              this.dialogData.flag = true;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        default:
          break;
      }
    },
    /**
     * 给客户绑定机柜和端口号
     * 绑定机柜和端口的dialog中的确定按钮
     */
    getAllClientCabinet() {
      let requestData = {
        clientId: this.tempClient.id, // 客户ID
        quota: {
          // 限额
          commonTicketElectronicMoney: this.dialogData.data[0].value.quota
            .commonTicketElectronicMoney, //电子普票限额
          specialTicketPaperMoney: this.dialogData.data[0].value.quota
            .specialTicketPaperMoney, //纸质专票限额
          commonTicketPaperMoney: this.dialogData.data[0].value.quota
            .commonTicketPaperMoney, //纸质普票限额
          specialTicketElectronicMoney: this.dialogData.data[0].value.quota
            .specialTicketElectronicMoney, //电子专票限额
        },
        stock: {
          // 库存
          commonTicketElectronicNum: this.dialogData.data[0].value.stock
            .commonTicketElectronicNum, //电子普票库存
          specialTicketPaperNum: this.dialogData.data[0].value.stock
            .specialTicketPaperNum, //纸质专票库存
          commonTicketPaperNum: this.dialogData.data[0].value.stock
            .commonTicketPaperNum, //纸质普票库存
          specialTicketElectronicNum: this.dialogData.data[0].value.stock
            .specialTicketElectronicNum, //电子专票库存
        },
      };

      this.$request(
        "post",
        "/invoice/setClientStockAndQuota",
        requestData,
        (data) => {
          this.$message({
            message: "库存设置成功!",
            type: "success",
            duration: 1000,
          });
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 设置客户工商信息
     * 设置工商信息(主要企业税号)的dialog中的确定按钮
     */
    updateStatisticsInvoice() {
      let requestData = {
        taxDisc: this.dialogData.data[2].value, // 税盘编号
        clientId: this.tempClient.id, // 客户ID
        taxNum: this.dialogData.data[1].value, // 企业税号
        addedValueTaxType: this.tempClient.addedValueTaxType, // 增值税
        industryType: this.tempClient.industryType, // 行业分类
        area: this.tempClient.area, // 所在地区
        address: this.tempClient.address, // 详细地址
      };

      this.$request(
        "post",
        "/invoice/updateStatisticsInvoice",
        requestData,
        (data) => {
          this.$message({
            message: "设置成功!",
            type: "success",
            duration: 1000,
          });
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取企业工商信息  调企查查
     */
    getStatisticsInvoice() {
      let requestData = {
        name: this.dialogData.data[0].value,
      };
      this.$request(
        "post",
        "/invoice/getStatisticsInvoice",
        requestData,
        (data) => {
          this.dialogData.data[1].value = data.taxNum; // 企业税号
          this.tempClient.addedValueTaxType = data.addedValueTaxType; // 增值税类型
          this.tempClient.industryType = data.industryType; // 行业分类
          this.tempClient.area = data.area; // 所在地区
          this.tempClient.address = data.address; // 详细地址
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    // /**
    //  * 开通企业工商信息  调企查查
    //  */
    // updateStatisticsInvoice() {
    //   let requestData = {
    //     name: this.dialogData.data[0].value,
    //     taxNum: this.dialogData.data[1].value,
    //     addedValueTaxType: this.tempClient.addedValueTaxType,
    //     industryType: this.tempClient.industryType,
    //     area: this.tempClient.area,
    //     address: this.tempClient.address,
    //   };
    //   this.$request(
    //     "post",
    //     "/invoice/updateStatisticsInvoice",
    //     requestData,
    //     (data) => {
    //       this.$message({
    //         message: "设置成功!",
    //         type: "success",
    //         duration: 1000,
    //       });
    //     },
    //     (errorMessage) => {
    //       this.$message.error(errorMessage);
    //     }
    //   );
    // },
    /**
     * 多选选择事件
     */
    // handleSelectionChange(val) {
    //   this.multipleSelection = val;
    // },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
      this.initTableData();
    },
    showCustomer(data) {
      this.dialogData.data[0].data = data;
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
  },
  mounted() {
    this.loading = true;
    this.isCharge();
    // this.initTableData();
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.searchData.userId = "all";
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#invoice-setting {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .invoice-setting-screen-fiftrate {
    width: 65px;
    min-width: 65px;
    height: 30px;
    border: 1px solid @boderColor;
    box-sizing: border-box;
    color: @boderActiveColor;
    font-size: @contentFontSize;
    margin: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    transition: 0.3s;
    i {
      margin: 0 4px;
    }
  }
  .invoice-setting-screen-fiftrate:hover {
    cursor: pointer;
    border-color: @boderActiveColor;
  }
  .invoice-setting-screen-search {
    width: 210px;
    min-width: 210px;
    height: 30px;
    margin-left: 10px;
  }
  .invoice-setting-screen-checked {
    width: 140px;
    height: 30px;
    min-width: 140px;
    font-size: @contentFontSize;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .invoice-setting-screen-active {
    flex: 1;
  }
  .invoice-setting-screen-but-select {
    width: 140px;
    min-width: 160px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @activeColor;
    font-size: @contentFontSize;
    color: #fff;
    margin: 0 5px;
    border-radius: 4px;
    transition: 0.5s;
    cursor: pointer;
    .invoice-setting-screen-but-select_but {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.5s;
    }
    .invoice-setting-screen-but-select_but:hover {
      background-color: @activeColor2;
    }
    .invoice-setting-screen-but-select_select {
      width: 30px;
      height: 100%;
      border-left: 1px solid @activeColor3;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .invoice-setting-screen-but {
    width: 100px;
    min-width: 100px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @activeColor;
    font-size: @contentFontSize;
    color: #fff;
    margin: 0 5px;
    border-radius: 4px;
    transition: 0.5s;
  }
  .invoice-setting-screen-but:hover {
    cursor: pointer;
    background-color: @activeColor2;
  }
  .invoice-setting-screen-but-boder {
    width: 50px;
    min-width: 50px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid @activeColor;
    font-size: @contentFontSize;
    background-color: @activeBackground;
    color: @activeColor;
    margin: 0 5px;
    margin-right: 10px;
    border-radius: 4px;
    transition: 0.5s;
  }
  .invoice-setting-screen-but-boder:hover {
    cursor: pointer;
    background-color: @activeBackground2;
  }
  .table-content {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    .invoice-setting-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .invoice-setting-content {
        width: 100%;
        height: 95%;
        max-width: 90vw;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        .line-cell {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          cursor: pointer;
          i {
            margin: 5px;
          }
        }
        .gray {
          color: rgb(146, 146, 146, 0.637);
        }
        .orange {
          color: rgba(255, 115, 0, 0.637);
        }
        .green {
          color: rgb(9, 255, 0, 0.637);
        }
      }
      .invoice-setting-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }
}
</style>
