<template>
  <!-- 沟通记录 -->
  <div class="company-drawer-right-center-exchange">
    <div class="company-drawer-right-top-search">
      <el-input
        v-model="searchData.input"
        placeholder="搜索沟通记录"
        style="width: 200px;"
        @keyup.enter.native="resetSearchData"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="resetSearchData"
        ></el-button>
      </el-input>
      <el-checkbox
        v-model="searchData.status"
        style="margin-left: 20px"
        true-label="1"
        false-labe="''"
        @change="resetSearchData"
        >只看未确认</el-checkbox
      >
    </div>
    <div class="exchange-content">
      <div class="exchange-content-list">
        <div
          class="exchange-content-list-item"
          v-for="(item, index) in tableData"
          :key="item.id"
          :style="{
            'align-items':
              item.sendUserId == baseInfo.userId ? 'flex-end' : 'flex-start',
          }"
        >
          <div
            class="exchange-content-list-item-time"
            :style="{
              'flex-direction':
                item.sendUserId == baseInfo.userId ? 'row-reverse' : '',
            }"
          >
            <span>{{ item.sendUserName }}</span>
            <span>{{ item.sendDate }}</span>
          </div>
          <div
            class="exchange-content-list-item-message"
            :style="{
              'background-color':
                item.sendUserId == baseInfo.userId ? '#E8E8F7' : '#fff',
            }"
            @contextmenu.prevent="onContextmenu($event, item, index)"
          >
            <span
              class="exchange-content-list-item-status"
              v-if="item.status == '1' && item.visibleUserIds"
            >
              未确认
            </span>
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.confirmUserName + ' ' + item.confirmDate"
              placement="top-start"
              v-else
            >
              <span
                class="exchange-content-list-item-status2"
                v-if="item.status == '2' && item.visibleUserIds"
                >已确认</span
              >
            </el-tooltip>

            <span v-html="item.content"></span>
            <i
              class="el-icon-circle-check"
              :style="{
                left: item.sendUserId == baseInfo.userId ? '-20px' : '',
              }"
              v-if="item.status == '1' && item.visibleUserIds"
              @click="messageRecords(item, index, 'edit')"
            ></i>
          </div>
        </div>
      </div>
    </div>
    <div class="exchange-input">
      <el-input
        class="textarea"
        v-model="textarea"
        placeholder="请输入内容, 不超过500字"
        type="textarea"
        resize="none"
      />
      <div class="exchange-input-button" @click="selectUser">
        @成员并发送
      </div>
      <div
        class="exchange-input-button2"
        @click="messageRecords('', '', 'add')"
      >
        发送
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import my_dialog from "@/components/dialog.vue";
export default {
  props: {
    tempClient: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    tempClient: {
      handler(newVal) {
        if (newVal) {
          this.resetPageData();
        }
      },
      immediate: true,
    },
  },
  components: {
    my_dialog,
  },
  data() {
    return {
      user: "right", // 当前登录人信息靠右展示
      count: 0,
      baseInfo: {},
      whetherAgencyHead: false,
      dom: "",
      searchData: {
        input: "", // 沟通记录搜索框
        status: "", // 沟通记录搜状态 '1' - 未确认；'2' - 已确认
      },
      textarea: "",
      tempData: {},
      tempIndex: "",
      tableData: [],
      clientRoleList: [],
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "选择人员",
        buttonArray: [
          {
            text: "确认",
            active: "confirm",
          },
        ],
        data: [],
      },
    };
  },
  methods: {
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.queryMessageRecords();
      });
    },
    /**
     * 抽屉 查询客户沟通记录
     */
    queryMessageRecords() {
      // 查询 沟通记录d);
      let requestData = {
        clientId: this.tempClient.id, // 客户id
        content: this.searchData.input, // 筛选项：内容
        status: this.searchData.status, // 筛选项：状态，'1' - 未确认；'2' - 已确认
        pageSize: this.pageData.pageSize,
        currentPage: this.pageData.currentPage,
      };
      this.$request(
        "post",
        "/messageRecords/page",
        requestData,
        (data) => {
          this.tableData = data.records;
          this.pageData.total = data.total;
          if (this.tempClient.id) {
            this.getRoleOptions();
          }
          this.$nextTick(() => {
            this.rightLazyLoad();
          });
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    getRoleOptions() {
      this.clientRoleList = [];
      this.$request(
        "get",
        "/client/getRoleOptions?id=" + this.tempClient.id,
        {},
        (data) => {
          // 人员去重
          data.forEach((item) => {
            let index = this.clientRoleList.findIndex(
              (it) => it.value == item.value
            );
            if (index == -1) {
              this.clientRoleList.push(item);
            }
          });
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 抽屉 操作客户沟通记录
     */
    messageRecords(item, index, flag) {
      let requestData = {};
      this.tempData = item;
      this.tempIndex = index;
      switch (flag) {
        case "add":
          // 添加 客户沟通记录
          requestData = {
            status: "3", // 消息状态 '1' - 未确认；'2' - 已确认；'3' - 无需确认
            clientId: this.tempClient.id, // 客户id
            content: this.textTohtml(this.textarea), // 发送内容
            sendUserId: this.baseInfo.userId, // 发送用户 id
            sendDate: this.$formatTime(new Date(), "YYYY-MM-DD HH:mm:ss"), // 发送时间
            // visibleUserIds: '',
          };
          if (!requestData.content) {
            this.$message.error("请输入内容后进行发送!");
            return;
          }
          this.$request(
            "post",
            "/messageRecords/add",
            requestData,
            (data) => {
              this.resetPageData();
              this.dom.scrollTop = 0;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "edit":
          // 修改 客户沟通记录
          this.$confirm("是否确认已读该消息？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              requestData = {
                clientId: this.tempClient.id, // 客户id
                id: this.tempData.id, // 消息id
                confirmUserId: this.baseInfo.userId, // 确认用户
                confirmDate: this.$formatTime(
                  new Date(),
                  "YYYY-MM-DD HH:mm:ss"
                ), // 确认时间
                status: this.tempData.status == "1" ? "2" : "1", //  消息状态：'1' - 未确认；'2' - 已确认
              };
              this.$request(
                "post",
                "/messageRecords/update",
                requestData,
                (data) => {
                  this.$message.success("操作成功");
                  this.tableData[this.tempIndex].status = requestData.status;
                  this.tableData[
                    this.tempIndex
                  ].confirmUserName = this.baseInfo.userName;
                  this.tableData[this.tempIndex].confirmDate =
                    requestData.confirmDate;
                  this.tempData.visibleUserIds = JSON.parse(
                    this.tempData.visibleUserIds
                  );
                  let flag = this.tempData.visibleUserIds.some(
                    (item) => item == this.baseInfo.userId
                  );
                  if (flag) {
                    // 已读自己的消息
                    this.$emit("editClient", {
                      flag: "delete",
                      model: "record",
                    });
                  }
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {});
          break;
        case "withdraw":
          // 撤回 客户沟通记录
          this.$confirm("是否撤回该条消息?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              let ids = [this.tempData.id];
              this.$request(
                "post",
                "/messageRecords/del",
                ids,
                (data) => {
                  this.$message.success("撤回成功");
                  this.tableData.splice(this.tempIndex, 1);
                  this.$emit("editClient", {
                    flag: "delete",
                    model: "record",
                  });
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {});
          break;
        case "delete":
          // 删除 客户沟通记录
          this.$confirm("是否删除该条消息?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              let ids = [this.tempData.id];
              this.$request(
                "post",
                "/messageRecords/del",
                ids,
                (data) => {
                  this.$message.success("删除成功");
                  this.tableData.splice(this.tempIndex, 1);
                  this.$emit("editClient", {
                    flag: "delete",
                    model: "record",
                  });
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {});
          break;
        default:
          break;
      }
    },
    resetSearchData() {
      this.textarea = "";
      this.pageData = {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      };
      this.queryMessageRecords();
      // this.dom.scrollTop = 0;
    },
    resetPageData() {
      this.searchData.input = "";
      this.resetSearchData();
    },
    textTohtml(val) {
      return val
        .replace(/<(.+?)>/gi, "&lt;$1&gt;")
        .replace(/ /gi, "&nbsp;")
        .replace(/\n/gi, "<br/>");
    },
    /**
     * 向上滑动加载更多
     */
    rightLazyLoad() {
      this.dom = document.querySelector(".exchange-content-list");
      this.dom.addEventListener("scroll", (v) => {
        const scrollDistance =
          this.dom.scrollTop - this.dom.clientHeight + this.dom.scrollHeight;
        // if (scrollDistance <= 0) {  //分辨率问题，如果设置 100% ，滑倒最底部，scrollDistance的值 可能为 0.201 到 -0.201
        if (scrollDistance < 1) {
          if (this.tableData.length >= this.pageData.total) {
            // 到达底部 数据加载完毕
            return;
          }
          // 滑动到表格底部 可以请求接口
          if (this.count == 0) {
            this.count = 1;
            this.pageData.currentPage++;
            let requestData = {
              clientId: this.tempClient.id, // 客户id
              content: this.searchData.input, // 筛选项：内容
              status: this.searchData.status, // 筛选项：状态，'1' - 未确认；'2' - 已确认
              pageSize: this.pageData.pageSize,
              currentPage: this.pageData.currentPage,
            };
            this.$request(
              "post",
              "/messageRecords/page",
              requestData,
              (data) => {
                this.count = 0;
                this.tableData = [...this.tableData, ...data.records];
                this.pageData.total = data.total;
              },
              (errorMessage) => {
                this.$message.error(errorMessage);
              }
            );
          }
        }
      });
    },
    /**
     * 鼠标右键事件
     */
    onContextmenu(event, item, index) {
      this.tempData = item;
      this.tempIndex = index;
      // 右侧菜单内容
      let rightMenuData = [
        {
          label: "撤回",
          icon: "icon el-icon-refresh-left",
          onClick: () => {
            this.messageRecords(item, index, "withdraw");
          },
        },
        {
          label: "删除",
          icon: "icon el-icon-delete",
          onClick: () => {
            this.messageRecords(item, index, "delete");
          },
        },
      ];

      // 不是机构管理员没有删除消息权限
      if (!this.whetherAgencyHead) {
        rightMenuData.splice(1, 1);
      }

      // 消息发出时间大于三分钟 或者 不是自己创建的消息 禁止撤回
      let timeDifference = new Date() - new Date(this.tempData.sendDate);
      let time = timeDifference / 1000 / 60;
      if (time > 3 || this.baseInfo.userId !== this.tempData.sendUserId) {
        rightMenuData.splice(0, 1);
      }
      if (rightMenuData.length == 0) {
        return;
      }
      // 创建右键菜单
      this.$contextmenu({
        items: rightMenuData,
        event, // 鼠标事件信息
        customClass: "custom-class", // 自定义菜单 class
        zIndex: 200000, // 菜单样式 z-index
        minWidth: 80, // 主菜单最小宽度
      });
      return false;
    },
    /**
     * 选择 @ 的成员
     */
    selectUser() {
      this.dialogData.width = "500px";
      this.dialogData.title = "选择人员";
      this.dialogData.buttonArray = [
        {
          text: "取消",
          active: "cancel",
        },
        {
          text: "确定",
          active: "add_remind",
        },
      ];
      this.dialogData.data = [
        {
          type: "select-object",
          value: [],
          options: this.clientRoleList,
        },
      ];
      console.log("dialog数据", this.dialogData);
      this.dialogData.flag = true;
    },
    /**
     * 抽屉数据或dialog数据操作事件
     */
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "add_remind":
          if (!this.textarea) {
            this.$message.error("请输入内容后进行发送!");
            return;
          }
          let value = this.dialogData.data[0].value;
          let usersName = "";
          let usesId = [];
          for (let index = 0; index < value.length; index++) {
            const item = value[index];
            usersName = usersName + "@" + item.label + " ";
            usesId.push(item.value);
            if (item.value == "all") {
              // 如果选择全部人员 则选中所有人
              usersName = "@全部人员 ";
              usesId = this.clientRoleList.filter(
                (item) =>
                  item.value !== this.baseInfo.userId && item.value !== "all"
              );
              break;
            }
          }
          requestData = {
            clientId: this.tempClient.id, // 客户id
            content: usersName + this.textTohtml(this.textarea), // 发送内容
            sendUserId: this.baseInfo.userId, // 发送用户 id
            sendDate: this.$formatTime(new Date(), "YYYY-MM-DD HH:mm:ss"), // 发送时间
            visibleUserIds: JSON.stringify(usesId),
          };
          this.$request(
            "post",
            "/messageRecords/add",
            requestData,
            (data) => {
              this.resetPageData();
              this.dom.scrollTop = 0;
              this.$emit("editClient", {
                flag: "add",
                model: "record",
              });
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          this.dialogData.flag = false;
          break;
        case "cancel":
          this.dialogData.flag = false;
          break;
        default:
          break;
      }
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
  },
  mounted() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.isCharge();
  },
};
</script>

<style lang="less" scoped>
.company-drawer-right-center-exchange {
  height: 100%;
  display: flex;
  flex-direction: column;
  .company-drawer-right-top-search {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  .exchange-content {
    width: 100%;
    height: 75%;
    display: flex;
    flex-direction: column;
    background-color: #f3f5f8;
    .exchange-content-list {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column-reverse;
      overflow-y: auto;
      padding: 20px;
      box-sizing: border-box;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.2);
      }

      .exchange-content-list-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .exchange-content-list-item-time {
          display: flex;
          align-items: baseline;
          width: fit-content;
          color: #8f9396;
          font-size: 12px;
          margin: 10px 0;
          span {
            margin-left: 5px;
          }
        }
        .exchange-content-list-item-message {
          position: relative;
          width: fit-content;
          max-width: 350px;
          text-align: left;
          font-size: 14px;
          border-radius: 3px;
          padding: 8px;
          background-color: #ffffff;
          line-height: 1.5;
          letter-spacing: 0.1em;

          .exchange-content-list-item-status {
            width: 45px;
            padding: 2px 5px;
            font-size: 12px;
            color: #f56c6c;
            border-radius: 3px;
            background-color: #fef0f0;
            margin-right: 5px;
          }
          .exchange-content-list-item-status2 {
            width: 40px;
            padding: 2px 5px;
            font-size: 12px;
            color: #67c23a;
            border-radius: 3px;
            background-color: #eef8ec;
            margin-right: 5px;
          }
          .el-icon-circle-check {
            position: absolute;
            top: 50%;
            right: -20px;
            transform: translate(0, -50%);
            font-size: 15px;
            color: #67c23a;
            cursor: pointer;
          }
        }
      }
    }
  }
  .exchange-input {
    position: relative;
    width: 100%;
    height: 25%;
    padding: 10px 0 0 10px;
    box-sizing: border-box;
    /deep/ .el-textarea {
      height: 100%;
      .el-textarea__inner {
        height: 100%;
        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: rgba(0, 0, 0, 0.2);
        }
      }
    }
    .exchange-input-button {
      position: absolute;
      bottom: 10px;
      right: 100px;
      padding: 5px 25px;
      font-size: 12px;
      border-radius: 3px;
      color: #07c160;
      background-color: #e9e9e9;
      cursor: pointer;
      &:hover {
        background-color: #d2d2d2;
      }
    }
    .exchange-input-button2 {
      position: absolute;
      bottom: 10px;
      right: 20px;
      padding: 5px 25px;
      font-size: 12px;
      border-radius: 3px;
      color: #07c160;
      background-color: #e9e9e9;
      cursor: pointer;
      &:hover {
        background-color: #d2d2d2;
      }
    }
  }
}
</style>
