/**
 * 商机-->设置-->权限:我的商机界面抽屉的数据权限json
 */
export function getDrawerAuthorData() {
    let data = [{
        title: "销售商机",
        buttonAuth: [{ // 按钮的权限
            title: "跟进",
            value: 1, // 1为读写;2为只读;3为不可见
            isShow: true,
            isDisable: false
        }, {
            title: "转成交",
            value: 1, // 1为读写;2为只读;3为不可见
            isShow: true,
            isDisable: false
        }, {
            title: "查看客户",
            value: 1, // 1为读写;2为只读;3为不可见
            isShow: true,
            isDisable: false
        }, {
            title: "置为无效",
            value: 1, // 1为读写;2为只读;3为不可见
            isShow: true,
            isDisable: false
        }, {
            title: "释放至商机池",
            value: 1, // 1为读写;2为只读;3为不可见
            isShow: true,
            isDisable: false
        }, {
            title: "删除",
            value: 1, // 1为读写;2为只读;3为不可见
            isShow: true,
            isDisable: false
        }, {
            title: "操作记录",
            value: 1, // 1为读写;2为只读;3为不可见
            isShow: true,
            isDisable: false
        }],
        data: [{ // 数据的权限
            title: "跟进联系人",
            value: 1, // 1为读写;2为只读;3为不可见
            isShow: true,
            isDisable: false
        }, {
            title: "商机状态",
            value: 1, // 1为读写;2为只读;3为不可见
            isShow: true,
            isDisable: false
        }, {
            title: "跟进阶段",
            value: 1, // 1为读写;2为只读;3为不可见
            isShow: true,
            isDisable: false
        }, {
            title: "所属部门",
            value: 1, // 1为读写;2为只读;3为不可见
            isShow: true,
            isDisable: false
        }, {
            title: "片区",
            value: 1, // 1为读写;2为只读;3为不可见
            isShow: true,
            isDisable: false
        }, {
            title: "商机提交人",
            value: 1, // 1为读写;2为只读;3为不可见
            isShow: true,
            isDisable: false
        }, {
            title: "联系人",
            value: 1, // 1为读写;2为只读;3为不可见
            isShow: true,
            isDisable: false
        }, {
            title: "手机",
            value: 1, // 1为读写;2为只读;3为不可见
            isShow: true,
            isDisable: false
        }, {
            title: "增值税类型",
            value: 1, // 1为读写;2为只读;3为不可见
            isShow: true,
            isDisable: false
        }, {
            title: "成立日期",
            value: 1, // 1为读写;2为只读;3为不可见
            isShow: true,
            isDisable: false
        }, {
            title: "地区",
            value: 1, // 1为读写;2为只读;3为不可见
            isShow: true,
            isDisable: false
        }, {
            title: "详细地址",
            value: 1, // 1为读写;2为只读;3为不可见
            isShow: true,
            isDisable: false
        }, {
            title: "意向产品",
            value: 1, // 1为读写;2为只读;3为不可见
            isShow: true,
            isDisable: false
        }, {
            title: "备注",
            value: 1, // 1为读写;2为只读;3为不可见
            isShow: true,
            isDisable: false
        }]
    }]
    return data;
}

/**
 * 数据处理
 */
export function operData(data) {
    for (let index = 0; index < data.length; index++) {
        let element = data[index];
        if (element.value == 1) {
            //  读写
            element.isShow = true
            element.isDisable = false
        } else if (element.value == 2) {
            // 只读
            element.isShow = true
            element.isDisable = true
        } else if (element.value == 3) {
            // 不可见
            element.isShow = false
        }
    }
    return data
}

/**
 * 导入资源的dialog数据
 */
export function improtResourcesDialogData(this_) {
    return [{
        type: "improt_resources",
        value: {
            url: "",
            text: "",
            action: this_.$fileBaseurl + "/uploadImageByMultipartFile",
            uploadParameter: {
                systemName: "finance",
                user: "",
                type: "myResources",
                flag: "0",
                path: "",
            },
            headers: {
                token: "95931b371eb64f4a9c862983ca8a5d15",
            },
            modelType: "peerPhoneMode"
        },

    }]
}