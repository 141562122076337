<template>
  <div id="wechat-add-contract">
    <!-- 合同详细信息 -->
    <div class="wechat-add-contract-line">
      <div class="wechat-add-contract-line-title">合同编号:</div>
      <div class="wechat-add-contract-line-value">
        {{ value.contractNumber }}
      </div>
      <!-- <div class="wechat-add-contract-active"></div> -->
      <div
        class="wechat-add-contract-line-but"
        @click="Oper(but.active)"
        v-if="wechatAddContractData.status == '待提交'"
      >
        {{ but.text }}
      </div>
    </div>

    <div class="wechat-add-contract-line">
      <div class="wechat-add-contract-line-title">合同状态:</div>
      <div class="wechat-add-contract-line-value">
        {{ value.status }}
      </div>
    </div>

    <div class="wechat-add-contract-line">
      <div class="wechat-add-contract-line-title">签单人:</div>
      <div class="wechat-add-contract-line-value">
        <el-select
          filterable
          v-model="value.single"
          placeholder="请选择"
          value-key="id"
        >
          <el-option
            v-for="it in employeeList"
            :key="it.id"
            :label="it.userName"
            :value="it"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="wechat-add-contract-line">
      <div class="wechat-add-contract-line-title">签单日期:</div>
      <div class="wechat-add-contract-line-value">
        <el-date-picker
          v-model="value.signTime"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
        >
        </el-date-picker>
      </div>
    </div>

    <div class="wechat-add-contract-line">
      <div class="wechat-add-contract-line-title">合同成本:</div>
      <div class="wechat-add-contract-line-value">
        <el-input placeholder="请输入金额" v-model="value.cost"> </el-input>
      </div>
    </div>

    <el-divider></el-divider>

    <div class="wechat-add-contract-line">
      <div class="wechat-add-contract-line-title">产品名称:</div>
      <div class="wechat-add-contract-line-value">
        <el-select filterable v-model="value.productName" placeholder="请选择">
          <el-option
            v-for="it in productList"
            :key="it.id"
            :label="it.typeName"
            :value="it.id"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="wechat-add-contract-line">
      <div class="wechat-add-contract-line-title">续签状态:</div>
      <div class="wechat-add-contract-line-value">
        {{ value.renewStatus }}
      </div>
    </div>

    <div class="wechat-add-contract-line">
      <div class="wechat-add-contract-line-title">续签期限:</div>
      <div class="wechat-add-contract-line-value">
        {{ value.renewStatus }}
      </div>
    </div>

    <div class="wechat-add-contract-line">
      <div class="wechat-add-contract-line-title">开始时间:</div>
      <div class="wechat-add-contract-line-value">
        <el-date-picker
          v-model="value.contractStart"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
        >
        </el-date-picker>
      </div>
    </div>

    <div class="wechat-add-contract-line">
      <div class="wechat-add-contract-line-title">自动续签:</div>
      <div class="wechat-add-contract-line-value">
        <el-radio-group v-model="value.autoRenew">
          <el-radio label="Y">是</el-radio>
          <el-radio label="N">否</el-radio>
        </el-radio-group>
      </div>
    </div>

    <div class="wechat-add-contract-line">
      <div class="wechat-add-contract-line-title">赠送服务:</div>
      <div class="wechat-add-contract-line-value">
        <el-radio-group v-model="value.isFree">
          <el-radio label="Y">是</el-radio>
          <el-radio label="N">否</el-radio>
        </el-radio-group>
      </div>
    </div>

    <div class="wechat-add-contract-line">
      <div class="wechat-add-contract-line-title">是否优惠:</div>
      <div class="wechat-add-contract-line-value">
        <el-radio-group v-model="value.discounts.flag">
          <el-radio label="Y">是</el-radio>
          <el-radio label="N">否</el-radio>
        </el-radio-group>
      </div>
    </div>

    <div class="wechat-add-contract-line" v-if="value.discounts.flag == 'Y'">
      <div class="wechat-add-contract-line-title">优惠金额:</div>
      <div class="wechat-add-contract-line-value">
        <el-input placeholder="0.00" v-model="value.discounts.money">
        </el-input>
      </div>
    </div>

    <el-divider></el-divider>

    <div class="wechat-add-contract-line-service">
      <div class="wechat-add-contract-line-service-title">
        <div class="wechat-add-contract-line-service-title-text">
          服务项（{{ value.serviceItem.length }}）
        </div>
      </div>
      <div
        class="wechat-add-contract-line-service-list"
        v-if="value.serviceItem.length > 0"
      >
        <div
          class="wechat-add-contract-line-service-item"
          v-for="(item, index) in value.serviceItem"
          :key="index"
        >
          <!-- 详情 -->
          <service_details
            v-if="item.flag"
            :serviceDetailsData="item.item"
            @show="showServiceDetailsData"
            @but_click_sure="but_click_sure(index)"
            @but_click_canel="but_click_canel(index)"
          ></service_details>
          <!-- 表格 -->
          <service_item
            v-else
            :serviceItemData="item.tableData"
            @show="showServiceItemData"
            @editItem="editItem(index)"
          ></service_item>
        </div>
      </div>
    </div>

    <div class="wechat-add-contract-line-service" v-if="value.isFree == 'Y'">
      <div class="wechat-add-contract-line-service-title">
        <div class="wechat-add-contract-line-service-title-text">
          赠送服务项（{{ value.freeService.length }}）
        </div>
        <div class="wechat-add-contract-line-service-title-but">添加服务项</div>
      </div>
      <div
        class="wechat-add-contract-line-service-list"
        v-if="value.freeService.length > 0"
      >
        <div
          class="wechat-add-contract-line-service-item"
          v-for="(item, index) in value.freeService"
          :key="index"
        >
          <GiveServiceItem
            :giveServiceItem="item"
            @savePresentServiceItems="saveDrawerData"
            @cancelServiceItems="cancelServiceItems"
            @deleteService="deleteService(index)"
          ></GiveServiceItem>
        </div>
      </div>
    </div>

    <div class="wechat-add-contract-line-buts">
      <div
        class="wechat-add-contract-line-but"
        v-if="wechatAddContractData.status == '待提交'"
        @click="Oper('save')"
      >
        保存
      </div>
      <div class="wechat-add-contract-line-but" @click="Oper('cancel')">
        取消
      </div>
    </div>
  </div>
</template>

<script>
import service_item from "./serviceItem.vue";
import service_details from "./service-details.vue";
import GiveServiceItem from "./give-service-item.vue";
export default {
  components: {
    service_item,
    service_details,
    GiveServiceItem,
  },
  props: {
    wechatAddContractData: {
      type: Object,
      // required: true,
      default: function () {
        return {
          contractNumber: "DZ202107290004", // 合同编号
          status: "待提交", // 合同状态
          single: { id: "ZYY", userName: "ZYY" }, // 签单人
          signTime: "2021-07-03", // 签单日期
          customerSource: "", // 来源
          cost: "", // 成本

          productName: "", // 产品名称
          renewStatus: "未续签", // 续签状态
          contractLimit: ["1", "0"], // 合同期限
          contractStart: "", // 合同开始时间
          dateDue: "", // 到期时间

          autoRenew: "Y", // 是否自动续签
          isFree: "Y", // 是否赠送服务项

          serviceItem: [
            {
              // 服务项目
              tableData: {
                name: {
                  id: "eb001200567d473f94ed100d57f528a2",
                  name: "其他财税",
                  type: {
                    typeName: "其他财税服务",
                    id: "5f30f0dff19144589adc862a83b7e189",
                  },
                  workType: [],
                  icon: {
                    text: "其",
                    color: "#F9A437",
                  },
                },
                cycle: "每季度一次",
                data: [
                  {
                    serviceTime: "2020.01-2020.02", // 服务时间
                    price: "10000元/月", // 服务单价
                  },
                ],
              },
              item: [
                {
                  horizontal: false,
                  isShow: true,
                  isDisable: false,
                  title: "服务名称",
                  type: "select",
                  value: "会计部",
                  options: [],
                },
                {
                  horizontal: false,
                  isShow: true,
                  isDisable: false,
                  title: "付费周期",
                  type: "select",
                  value: "每年",
                  options: [
                    {
                      label: "每年",
                      value: "每年",
                      img: "",
                    },
                    {
                      label: "每月",
                      value: "每月",
                      img: "",
                    },
                    {
                      label: "每季度",
                      value: "每季度",
                      img: "",
                    },
                  ],
                },
                {
                  horizontal: false,
                  isShow: true,
                  isDisable: true,
                  title: "催费时间",
                  type: "收费提醒选择",
                  radio: "提前",
                  beforeDay: "",
                  lateDay: "",
                  value: "",
                },
                {
                  horizontal: false,
                  isShow: true,
                  isDisable: false,
                  title: "优惠",
                  type: "discounts",
                  value: {},
                },
                {
                  horizontal: false,
                  isShow: true,
                  isDisable: false,
                  title: "首次催费时间",
                  type: "time",
                  value: "",
                },
                {
                  horizontal: false,
                  isShow: true,
                  isDisable: false,
                  title: "服务总价",
                  type: "input",
                  value: "180****8888",
                },
                {
                  horizontal: false,
                  isShow: true,
                  isDisable: false,
                  title: "服务时间",
                  type: "timeToTime",
                  value: [],
                },
              ],
              flag: false,
            },
          ], // 服务项
          freeService: [], // 赠送的服务项
        };
      },
    },
    employeeList: {
      // 员工列表
      type: Array,
      default: function () {
        return [];
      },
    },
    productList: {
      // 产品列表
      type: Array,
      default: function () {
        return [];
      },
    },
    serviceItemList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  watch: {
    wechatAddContractData() {
      //父组件值变化->>子组件也变化
      this.value = this.wechatAddContractData;
    },
    value(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  data() {
    return {
      value: this.wechatAddContractData,
      but: {
        text: "提交",
        active: "submit",
      },
    };
  },
  methods: {
    /**
     * 添加服务项
     */
    addService() {
      let dd = {
        flag: true,
        tableData: {},
        item: [
          {
            horizontal: false,
            isShow: true,
            isDisable: false,
            title: "服务名称",
            type: "select",
            value: "",
            options: this.serviceItemList,
          },
          {
            horizontal: false,
            isShow: true,
            isDisable: false,
            title: "付费周期",
            type: "select",
            value: "每年",
            options: [
              {
                label: "每年",
                value: "每年",
                img: "",
              },
              {
                label: "每月",
                value: "每月",
                img: "",
              },
              {
                label: "每季度",
                value: "每季度",
                img: "",
              },
            ],
          },
          {
            horizontal: false,
            isShow: true,
            isDisable: true,
            title: "催费时间",
            type: "收费提醒选择",
            radio: "提前",
            beforeDay: "",
            lateDay: "",
            value: "",
          },
          {
            horizontal: false,
            isShow: true,
            isDisable: false,
            title: "优惠",
            type: "discounts",
            value: {
              discount: "",
              flag: "",
              mitigate: "",
              totalPrice: "",
            },
          },
          {
            horizontal: false,
            isShow: true,
            isDisable: false,
            title: "首次催费时间",
            type: "time",
            value: "",
          },
          {
            horizontal: false,
            isShow: true,
            isDisable: false,
            title: "服务总价",
            type: "input",
            value: "",
          },
          {
            horizontal: false,
            isShow: true,
            isDisable: false,
            title: "服务时间",
            type: "timeToTime",
            value: [
              {
                discounts: "",
                money: "",
                price: "",
                time1: "2021-07",
                time2: "2022-07",
              },
            ],
          },
        ],
      };
      this.value.serviceItem.push(dd);
    },
    editItem(i) {
      this.value.serviceItem[i].flag = true;
    },
    but_click_canel(i) {
      if (!this.value.serviceItem[i].tableData.name) {
        this.value.serviceItem.splice(i, 1);
        return;
      }
      this.value.serviceItem[i].flag = false;
    },
    but_click_sure(i) {
      let tableData = {
        cycle: this.value.serviceItem[i].item[1].value,
        name: JSON.parse(
          JSON.stringify(this.value.serviceItem[i].item[0].value)
        ),
        data: [],
      };
      this.value.serviceItem[i].item[6].value.forEach((element) => {
        let dd = {
          serviceTime: element.time1 + "-" + element.time2,
          price: element.price,
        };
        tableData.data.push(dd);
      });
      this.value.serviceItem[i].tableData = tableData;
      this.value.serviceItem[i].flag = false;
    },
    saveDrawerData() {},
    /**
     * 取消赠送服务项修改
     */
    cancelServiceItems() {
      this.$emit("cancelServiceItems");
    },
    /**
     * 删除具体赠送的服务项
     */
    deleteService(index) {
      this.$emit("deleteService", index);
    },

    showServiceItemData(data) {},
    showServiceDetailsData(data) {},
    /**
     * 操作
     */
    Oper(active) {
      this.$emit("Oper", active);
    },
  },
};
</script>

<style lang="less" scoped>
#wechat-add-contract {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .wechat-add-contract-line,
  .wechat-add-contract-line-buts {
    font-size: 10px;
    width: 98%;
    height: 30px;
    display: flex;
    align-items: center;
    margin-top: 5px;
    .wechat-add-contract-line-title {
      width: 20%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 3px;
      box-sizing: border-box;
      font-size: 11px;
    }
    .wechat-add-contract-line-value {
      width: auto;
      height: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .wechat-add-contract-line-but {
      width: auto;
      height: 25px;
      padding: 0 5px;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
      display: flex;
      border-radius: 5px;
      // background-color: @activeColor;
      border: 1px solid @activeColor;
      color: @activeColor;
      // color: #ffffff;
      margin-right: 10px;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        background-color: @activeColor;
        color: #fff;
      }
    }
  }
  .wechat-add-contract-line-buts {
    align-content: center;
    justify-content: center;
    margin-top: 10px;
  }
  .wechat-add-contract-active {
    flex: 1;
  }
  .wechat-add-contract-line-service {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    font-size: 10px;
    .wechat-add-contract-line-service-title {
      width: 100%;
      height: 30px;
      border-bottom: 1px solid @boderColor;
      box-sizing: border-box;
      display: flex;
      font-size: 14px;
      align-items: center;
      .wechat-add-contract-line-service-title-text {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 10px;
      }
      .wechat-add-contract-line-service-title-but {
        width: auto;
        height: 20px;
        border: 1px solid @activeColor;
        border-radius: 5px;
        color: @activeColor;
        margin: 0 5px;
        padding: 0 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s;
        &:hover {
          cursor: pointer;
          background-color: #41a3ff;
          color: rgb(255, 255, 255);
        }
      }
    }
    .wechat-add-contract-line-service-list {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid @boderColor;
      box-sizing: border-box;
      .wechat-add-contract-line-service-item {
        padding: 10px 5px;
      }
    }
  }
}
</style>