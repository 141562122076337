<template>
  <div id="line-row">
    <div class="line-row-item" v-for="item in data" :key="item.key">
      <div class="line-row-title">{{ item.title }}:</div>
      <div class="line-row-value">
        <el-input
          v-model="item.value"
          placeholder="请输入内容"
          v-if="item.type == 'input'"
        ></el-input>
        <el-select
          v-model="item.value"
          placeholder="请选择"
          v-else-if="item.type == 'select'"
        >
          <el-option
            v-for="it in item.options"
            :key="it.value"
            :label="it.label"
            :value="it.value"
          ></el-option>
        </el-select>
        <el-radio-group v-model="item.value" v-else-if="item.type == 'radio'">
          <el-radio
            v-for="(it, i) in item.options"
            :key="i"
            :label="it.value"
            >{{ it.text }}</el-radio
          >
        </el-radio-group>
        <el-input
          v-model="item.value"
          v-else-if="item.type == 'textarea'"
          type="textarea"
        ></el-input>
        <el-date-picker
          v-model="item.value"
          v-else-if="item.type == 'date'"
          :type="item.date_type"
          placeholder="选择月"
          :value-format="item.format"
        >
        </el-date-picker>
        <el-select
          v-model="item.value"
          v-else-if="item.type == 'select-multiple'"
          multiple
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="it in item.options"
            :key="it.value"
            :label="it.label"
            :value="it.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  mounted() {
    console.log("data", this.data);
  },
};
</script>

<style lang="less" scoped>
#line-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .line-row-item {
    width: 50%;
    margin: 12px 0;
    display: flex;
    align-items: baseline;
    justify-content: right;

    &:nth-child(n + 9) {
      align-items: flex-start;
    }
    .isRequired {
      color: red;
    }
    .line-row-title {
      font-size: 14px;
      margin-right: 8px;
      width: 30%;
      // min-width: 90px;
      // width: 90px;
      text-align: right;
    }
    .line-row-value {
      width: 70%;
      display: flex;
      > .icons {
        width: 0;
        height: auto;
        flex: 1;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        > .icon {
          width: 80px;
          height: 120px;
          border: 1px solid #cecece;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          color: #cecece;
          position: relative;
          transition: 0.3s;
          padding: 2px;
          margin: 2px;
          &:hover {
            cursor: pointer;
            color: #2873ff;
          }
          > i {
            margin-right: 5px;
          }
          > .dele {
            width: 100%;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 10px;
            font-size: 14px;
            color: #ff7919;
            position: absolute;
            top: 0;
            cursor: pointer;
          }
        }
      }
      /deep/ .el-select__tags {
        flex-wrap: nowrap;
        overflow: hidden;
      }
      /deep/ .el-date-editor.el-input,
      .el-date-editor.el-input__inner {
        width: 100%;
      }
    }
  }
}
</style>
