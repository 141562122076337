<template>
  <!-- 地址维护 -->
  <div id="delivery_detail">
    <div class="delivery_detail-contain">
      <div class="delivery_detail-line">
        <div class="delivery_detail-title">收件人公司</div>
        <div class="delivery_detail-value">
          <el-input
            v-model="value.companyName"
            placeholder="选填, 请输入公司名称"
          ></el-input>
        </div>
      </div>
      <div class="delivery_detail-line2">
        <div class="delivery_detail-line2-column">
          <div class="delivery_detail-title">
            <div class="delivery_detail-title-red">*</div>
            姓名
          </div>
          <div class="delivery_detail-value">
            <el-input
              v-model="value.name"
              placeholder="请填写真实姓名"
            ></el-input>
          </div>
        </div>
        <div class="delivery_detail-line2-column">
          <div class="delivery_detail-title">
            <div class="delivery_detail-title-red">*</div>
            电话
          </div>
          <div class="delivery_detail-value">
            <el-input
              :onkeyup="(value.phone = value.phone.replace(/\D/g, ''))"
              v-model="value.phone"
              placeholder="请输入电话号码"
            ></el-input>
          </div>
        </div>
      </div>

      <div class="delivery_detail-line3">
        <div class="delivery_detail-title">
          <div class="delivery_detail-title-red">*</div>
          省市区
        </div>

        <div class="delivery_detail-value">
          <area-select
            v-model="value.code"
            type="all"
            :level="2"
            :data="pcaa"
            placeholder="请选择地区"
          ></area-select>

          <!-- <el-select
            v-model="value.receiveProvinceCode"
            style="margin-right: 8px;"
            filterable
            @change="selectProvince"
          >
            <el-option
              v-for="item in provinceList"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option
          ></el-select>
          <el-select v-model="value.receiveCityCode" style="margin-right: 8px;">
            <el-option></el-option>
          </el-select>
          <el-select v-model="value.receiveCountyCode"
            ><el-option></el-option
          ></el-select> -->
        </div>
      </div>
      <div class="delivery_detail-line" style="align-items: flex-start;">
        <div class="delivery_detail-title">
          <div class="delivery_detail-title-red">*</div>
          详细地址
        </div>
        <div class="delivery_detail-value">
          <el-input
            v-model="value.address"
            type="textarea"
            placeholder="请输入详细地址"
          ></el-input>
        </div>
      </div>
      <div class="delivery_detail-line">
        <div class="delivery_detail-title">关联客户</div>
        <div class="delivery_detail-value">
          <el-select
            v-model="value.clientId"
            placeholder="请输入客户简称, 客户编号"
            filterable
            clearable
          >
            <el-option
              v-for="item in value.clientList"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option
          ></el-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { pca, pcaa } from "area-data";
export default {
  props: {
    DeliveryDetail: {
      type: Object,
      default: function() {
        return;
      },
    },
  },
  data() {
    return {
      value: {},
      pcaa: pcaa,
    };
  },
  methods: {},
  created() {
    this.value = this.DeliveryDetail;
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
#delivery_detail {
  width: 100%;
  display: flex;

  .delivery_detail-contain {
    width: 100%;
    display: flex;
    flex-direction: column;
    .delivery_detail-line {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      font-size: 14px;
      font-weight: 700;
      .delivery_detail-title {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        width: 15%;
        text-align: right;
        margin-right: 10px;
        .delivery_detail-title-red {
          color: red;
          margin: 0 5px;
        }
      }
      .delivery_detail-value {
        width: 85%;
      }
    }

    .delivery_detail-line2 {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      font-size: 14px;
      font-weight: 700;
      .delivery_detail-line2-column {
        width: 50%;
        display: flex;
        align-items: center;
        .delivery_detail-title {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          width: 35%;
          text-align: right;
          margin-right: 10px;
          .delivery_detail-title-red {
            color: red;
            margin: 0 5px;
          }
        }
        .delivery_detail-value {
          width: 80%;
        }
      }
    }
    .delivery_detail-line3 {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      font-size: 14px;
      font-weight: 700;
      .delivery_detail-title {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        width: 15%;
        text-align: right;
        margin-right: 10px;
        .delivery_detail-title-red {
          color: red;
          margin-top: 7px;
          margin-right: 5px;
        }
      }
      .delivery_detail-value {
        display: flex;
        width: 85%;
        /deep/ .area-select-wrap {
          display: flex;
          .area-select {
            width: 130px;
            margin-left: 8px;
            &:hover {
              transition: 1s;
            }
            &:first-child {
              margin-left: 0;
            }
            .area-selected-trigger {
              padding: 7px 40px 7px 0px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
</style>
