<template>
  <div id="serviceItem">
    <div class="topArea">
      <div class="name">
        <!-- <img style="width: 15px; height: 15px" :src="img" alt="" />
         -->
        <div
          class="icon"
          :style="{
            borderColor: value.name.icon.color,
            color: value.name.icon.color,
          }"
        >
          {{ value.name.icon.text }}
        </div>
        {{ value.name.name }}
      </div>
      <!-- <div class="cycle">收费周期:{{ value.cycle }}</div> -->
      <div class="operateArea">
        <el-button
          size="mini"
          type="primary"
          icon="el-icon-edit"
          circle
          @click="editItem"
        ></el-button>
      </div>
    </div>
    <div class="contendArea">
      <div class="contendArea1">
        <div class="menu1">服务时间</div>
        <div class="menu2">单价</div>
      </div>
      <div
        class="contendArea2"
        v-for="(item, index) in value.data"
        :key="index"
      >
        <div class="menu1">{{ value.cycle }}</div>
        <div class="menu2">{{ item.price }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["serviceItemData"],
  data() {
    return {
      value: this.serviceItemData,
      img: require("../assets/logo.png"),
    };
  },
  watch: {
    serviceItemData() {
      //父组件值变化->>子组件也变化
      this.value = this.serviceItemData;
    },
    value(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {
    editItem() {
      this.$emit("editItem");
    },
  },
};
</script>

<style lang="less">
#serviceItem {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .topArea {
    display: flex;
    height: 35px;
    align-items: center;
    justify-content: space-between;
    .name {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .icon {
        height: 25px;
        width: auto;
        padding: 2px 5px;
        margin: 0 5px;
        border: 1px solid #333;
        border-radius: 5px;
        box-sizing: border-box;
      }
    }
    .cycle {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .operateArea {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
  .contendArea {
    display: flex;
    flex-direction: column;
    border: 1px solid gray;
    .contendArea1 {
      display: flex;
      border-bottom: 1px solid gray;
      height: 30px;
      background-color: rgba(190, 212, 221, 0.438);
      .menu1 {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid gray;
      }
      .menu2 {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .contendArea2 {
      display: flex;
      height: 30px;
      border-bottom: 1px solid gray;
      &:nth-last-child(1) {
        border: none;
      }
      .menu1 {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid gray;
      }
      .menu2 {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
