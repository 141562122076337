<template>
  <div id="invoice-wechat-info">
    <div class="invoice-wechat-info-line">
      <div class="invoice-wechat-info-line-title">年份:</div>
      <div class="invoice-wechat-info-line-value">
        <el-date-picker
          v-model="year"
          type="year"
          placeholder="选择年"
          value-format="yyyy"
          @change="getInvoiceInfoByWechat"
        >
        </el-date-picker>
      </div>
    </div>
    <div class="invoice-wechat-info-line">
      <div class="invoice-wechat-info-line-title">季度:</div>
      <div class="invoice-wechat-info-line-value">
        <el-select
          v-model="quarter"
          filterable
          placeholder="请选择"
          @change="getInvoiceInfoByWechat"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="invoice-wechat-info-line">
      <div class="invoice-wechat-info-line-but" @click="copy">复制抬头</div>
      <div class="invoice-wechat-info-line-active"></div>
      <div class="invoice-wechat-info-line-but" @click="makeOutAnInvoice">
        我要开票
      </div>
    </div>

    <div class="invoice-wechat-info-content">
      <div
        class="invoice-wechat-info-content-tip"
        v-for="(item, index) in value.data"
        :key="index"
      >
        <div class="invoice-wechat-info-content-tip-title">
          {{ item.name }}
        </div>
        <div class="invoice-wechat-info-content-tip-line">
          <div class="invoice-wechat-info-content-tip-line-title">
            开票数量:
          </div>
          <div class="invoice-wechat-info-content-tip-line-value">
            {{ item.num }}
          </div>
        </div>
        <div class="invoice-wechat-info-content-tip-line">
          <div class="invoice-wechat-info-content-tip-line-title">
            开票金额:
          </div>
          <div class="invoice-wechat-info-content-tip-line-value">
            {{ item.money }}
          </div>
        </div>
        <div class="invoice-wechat-info-content-tip-line">
          <div class="invoice-wechat-info-content-tip-line-title">
            剩余数量:
          </div>
          <div class="invoice-wechat-info-content-tip-line-value">
            {{ item.remain }}
          </div>
        </div>
        <div class="invoice-wechat-info-content-tip-line">
          <div class="invoice-wechat-info-content-tip-line-title">限额:</div>
          <div class="invoice-wechat-info-content-tip-line-value">
            {{ item.limitMoney }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["invoiceInfoData"],
  data() {
    return {
      value: this.invoiceInfoData,
      // value: {
      //   clientId: "",

      //   data: [
      //     {
      //       name: "纸质普通发票",
      //       num: "123", // 已开票
      //       money: "222", // 金额
      //       remain: "22", // 剩余张数
      //       limitMoney: "22222", //  限额
      //     },
      //     {
      //       name: "纸质专用发票",
      //       num: "11111", // 已开票
      //       money: "1111", // 金额
      //       remain: "11", // 剩余张数
      //       limitMoney: "111", //  限额
      //     },
      //     {
      //       name: "电子纸质发票",
      //       num: "212121", // 已开票
      //       money: "1222121", // 金额
      //       remain: "122211", // 剩余张数
      //       limitMoney: "2112212", //  限额
      //     },
      //     {
      //       name: "电子专用发票",
      //       num: "3333", // 已开票
      //       money: "33", // 金额
      //       remain: "333", // 剩余张数
      //       limitMoney: "3333", //  限额
      //     },
      //   ],
      // },
      year: "2021", // 筛选时间  年份
      quarter: "第一季度", // 筛选时间  季度
      options: [
        {
          label: "第一季度",
          value: "一",
        },
        {
          label: "第二季度",
          value: "二",
        },
        {
          label: "第三季度",
          value: "三",
        },
        {
          label: "第四季度",
          value: "四",
        },
      ],
    };
  },
  methods: {
    /**
     * 获取界面数据
     */
    getInvoiceInfoByWechat() {
      console.log("发票数据ppppp", this.value);
      let requestData = {
        clientId: this.value.clientId,
        year: this.year,
        quarter: this.quarter,
      };
      this.$request(
        "post",
        "/wechat/getInvoiceInfoByWechat",
        requestData,
        (data) => {
          this.value.data[0].num = data.commonTicketPaperNum;
          this.value.data[0].money = data.commonTicketPaperMoney;
          this.value.data[0].remain = data.commonTicketPaperRemainNum;
          this.value.data[0].limitMoney = data.commonTicketPaperLimitMoney;

          this.value.data[1].num = data.specialTicketPaperNum;
          this.value.data[1].money = data.specialTicketPaperMoney;
          this.value.data[1].remain = data.specialTicketPaperRemainNum;
          this.value.data[1].limitMoney = data.specialTicketPaperLimitMoney;

          this.value.data[2].num = data.commonTicketElectronicNum;
          this.value.data[2].money = data.commonTicketElectronicMoney;
          this.value.data[2].remain = data.commonTicketElectronicRemainNum;
          this.value.data[2].limitMoney = data.commonTicketElectronicLimitMoney;

          this.value.data[3].num = data.specialTicketElectronicNum;
          this.value.data[3].money = data.specialTicketElectronicMoney;
          this.value.data[3].remain = data.specialTicketElectronicRemainNum;
          this.value.data[3].limitMoney =
            data.specialTicketElectronicLimitMoney;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    copy() {
      this.$emit("copy");
    },
    makeOutAnInvoice() {
      this.$emit("makeOutAnInvoice");
    },
  },
  mounted() {
    // this.getInvoiceInfoByWechat();
    console.log("发票数据", this.value);
  },
};
</script>

<style lang="less" scoped>
#invoice-wechat-info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  .invoice-wechat-info-line {
    width: 100%;
    height: 40px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    .invoice-wechat-info-line-title {
      width: 20%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 5px;
      box-sizing: border-box;
    }
    .invoice-wechat-info-line-value {
      flex: 1;
      width: 0;
      height: 100%;
      display: flex;
      align-items: center;
      // justify-content: center;
    }
    .invoice-wechat-info-line-but {
      width: auto;
      padding: 0 10px;
      margin: 0 10px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      border: 1px solid @activeColor;
      color: @activeColor;
      border-radius: 5px;
      &:hover {
        cursor: pointer;
      }
    }
    .invoice-wechat-info-line-active {
      flex: 1;
    }
  }

  .invoice-wechat-info-content {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .invoice-wechat-info-content-tip {
      width: 49%;
      height: 230px;
      background-color: @activeBackground;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 8px;
      .invoice-wechat-info-content-tip-title {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: @activeColor;
      }
      .invoice-wechat-info-content-tip-line {
        width: 98%;
        height: 30px;
        display: flex;
        align-items: center;
        margin-top: 8px;
        .invoice-wechat-info-content-tip-line-title {
          width: 37%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-right: 5px;
        }
        .invoice-wechat-info-content-tip-line-value {
          width: 0;
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
</style>