<template>
  <div id="commondity">
    <!-- 常用商品的货物选择 -->
    <div class="commondity-top">
      <div class="commondity-top-search">
        <el-input
          v-model="value.name"
          placeholder="请输入货物名称"
          @change="searchCommondity"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="searchCommondity"
          ></el-button>
        </el-input>
      </div>
    </div>
    <div class="commondity-content">
      <div class="commondity-left">
        <el-tree
          :data="value.treeData"
          :props="defaultProps"
          @node-click="handleNodeClick"
          lazy
          :load="loadNode"
          accordion
        >
        </el-tree>
      </div>

      <div class="commondity-right">
        <div
          class="commondity-right-line"
          style="border-top: 1px solid #d1d3d8"
        >
          <div class="commondity-right-line-cell">序号</div>
          <div class="commondity-right-line-cell">货物名称</div>
          <div class="commondity-right-line-cell">货物编码</div>
          <div class="commondity-right-line-cell">货物类型</div>
          <div class="commondity-right-line-cell">可用状态</div>
          <div class="commondity-right-line-cell">说明</div>
          <div class="commondity-right-line-cell">操作</div>
        </div>
        <div class="commondity-right-line-table">
          <div
            v-for="(item, index) in value.tableData"
            :key="index"
            class="commondity-right-line"
          >
            <div class="commondity-right-line-cell">{{ index + 1 }}</div>
            <div class="commondity-right-line-cell">{{ item.name }}</div>
            <div class="commondity-right-line-cell">{{ item.num }}</div>
            <div class="commondity-right-line-cell">{{ item.abb }}</div>
            <div class="commondity-right-line-cell">{{ item.usable }}</div>
            <div class="commondity-right-line-cell" style="text-align: left">
              {{ item.remark }}
            </div>
            <div class="commondity-right-line-cell">
              <el-button v-if="flag" type="text" @click="checkCode(item)"
                >选中</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["commondityData"],
  data() {
    return {
      value: this.commondityData,
      // value: {
      //   treeData: [],
      //   tableData: [],
      // },
      name: "",
      defaultProps: {
        children: "children",
        label: "name",
        isLeaf: "leaf",
      },
      flag: false,
    };
  },
  methods: {
    /**
     * 树的懒加载
     */
    loadNode(node, resolve) {
      let self = this;
      let requestData = {
        id: "",
      };
      if (node.level == 0) {
        //   根节点
        requestData.id = "0";
        // return resolve([
        //   {
        //     name: "全部货物",
        //     num: "1000000000000000000",
        //     leaf: false,
        //   },
        // ]);
      }
      if (node.level >= 1) {
        requestData.id = node.data.num;
      }
      self.$request(
        "post",
        "/commodityCode/queryCommodityById",
        requestData,
        (data) => {
          node.data.children = data;
          self.value.tableData = JSON.parse(JSON.stringify(data));
          return resolve(node.data.children);
        },
        (errorMessage) => {
          self.$message.error(errorMessage);
        }
      );
    },
    /**
     * 树的行点击事件
     */
    handleNodeClick(treedata, node, dd) {
      if (treedata.leaf) {
        this.flag = true;
      } else {
        this.flag = false;
      }
      let requestData = {
        id: treedata.num,
      };
      this.$request(
        "post",
        "/commodityCode/queryCommodityById",
        requestData,
        (data) => {
          this.value.tableData = JSON.parse(JSON.stringify(data));
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 根据货物名称
     */
    searchCommondity() {
      let requestData = {
        name: this.value.name,
      };
      this.$request(
        "post",
        "/commodityCode/queryLikeCommodityList",
        requestData,
        (data) => {
          this.flag = true;
          this.value.tableData = JSON.parse(JSON.stringify(data));
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 选择货物编号
     */
    checkCode(data) {
      this.$emit("checkCode", data);
    },
  },
  mounted() {
    this.searchCommondity();
  },
};
</script>

<style lang="less" scoped>
#commondity {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .commondity-top {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .commondity-top-search {
      width: 340px;
      margin-left: 20px;
    }
  }
  .commondity-content {
    width: 100%;
    height: 0;
    flex: 1;
    display: flex;
    .commondity-left {
      width: 200px;
      height: 100%;
      max-height: 800px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 1px;
      }
    }
    .commondity-right {
      width: 0;
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 10px;

      .commondity-right-line-table {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        max-height: 750px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 1px;
        }
      }
      .commondity-right-line {
        width: 100%;
        height: auto;
        display: flex;
        border-bottom: 1px solid @boderColor;
        border-right: 1px solid @boderColor;
        border-left: 1px solid @boderColor;
        box-sizing: border-box;
        align-items: center;
        font-size: 12px;
        // &:nth-child(1) {
        //   border-top: 1px solid @boderColor;
        // }
        &:nth-child(1) {
          border-top: none;
        }
        .commondity-right-line-cell {
          flex: 1;
          width: 0;
          min-height: 40px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-left: 1px solid @boderColor;
          padding: 5px 3px;
          box-sizing: border-box;
          &:nth-child(1) {
            border: none;
            width: 40px;
          }
        }
      }
    }
  }
}
</style>
