<template>
  <div id="table-social">
    <div class="table-social-search">
      <div class="table-social-search-line">
        <el-input
          v-model="name"
          placeholder="请输入姓名"
          @keyup.enter.native="resetPage"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click.native.stop="resetPage"
          ></el-button>
        </el-input>
      </div>
      <div class="table-social-search-line">
        <el-date-picker
          v-model="date"
          type="monthrange"
          range-separator="至"
          start-placeholder="开始月份"
          end-placeholder="结束月份"
          value-format="yyyy-MM"
          :clearable="false"
          @change="resetPage"
        >
        </el-date-picker>
      </div>
    </div>
    <div>
      <el-table
        ref="tableData"
        class="tableData-content"
        :data="tableData"
        :header-cell-style="{ background: '#F5F7FA', color: '#606266' }"
        highlight-current-row
        style="width:1300px"
        height="500"
        border
        :row-style="{ height: '60px' }"
        show-summary
        :summary-method="summaryMethods"
        :row-class-name="rowClassName"
      >
        <el-table-column type="index" width="50" fixed> </el-table-column>
        <el-table-column label="姓名" align="center" fixed width="80">
          <template slot-scope="scope">
            <span
              @click.stop="handleClick(scope.row, 0)"
              style="color: #40a3ff; cursor: pointer;"
              >{{ scope.row.name }}</span
            ></template
          >
        </el-table-column>
        <el-table-column
          prop="identityCard"
          label="社会保障号码"
          width="170"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="paymentDate"
          label="缴费月份"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="personalPaymentBase"
          label="缴费基数"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="totalPaymentAmount"
          label="单位+个人缴费"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="unitPaymentAmount"
          label="单位缴费"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="personalPaymentAmount"
          label="个人缴费"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="medicalAmountOfUnit"
          label="医疗单位"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="medicalAmountOfPersonal"
          label="医疗个人"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="retirementAmountOfUnit"
          label="养老单位"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="retirementAmountOfPersonal"
          label="养老个人"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="unemploymentAmountOfUnit"
          label="失业单位"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="unemploymentAmountOfPersonal"
          label="失业个人"
          width="120"
        ></el-table-column>
        <!-- 工伤单位 -->
        <el-table-column
          prop="industrialInjuryAmountOfUnit"
          label="工伤"
          width="120"
        ></el-table-column>
      </el-table>
      <!-- <div class="social-security-table-card-bottom">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="pageData.currentPage"
          :page-size="pageData.pageSize"
          layout="total,sizes, prev, pager, next,jumper"
          :total="pageData.total"
        >
        </el-pagination>
      </div> -->
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        @dialogOper="drawerOper"
        @colseDialog="colseDialog"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import * as taxData from "@/views/tax/js/tax.js";
import my_dialog from "@/components/dialog.vue";
export default {
  components: {
    my_dialog: () => import("@/components/dialog.vue"),
  },
  props: {
    data: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      date: [],
      name: "",
      clientId: "",
      date: [],
      tableData: [],
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "运单详情",
        buttonArray: [
          {
            text: "确认",
            active: "confirm",
          },
        ],
        data: [],
      },
      tempSocial: {},
    };
  },
  watch: {
    data() {
      //父组件值变化->>子组件也变化
      this.tableData = this.data.records;
      this.pageData.total = this.data.total;
      this.pageData.pageSize = this.data.size;
      this.pageData.currentPage = this.data.current;
      this.date = this.data.date;
      this.name = this.data.name;
    },
  },
  methods: {
    handleClick(data, flag) {
      let requestData = {};
      this.tempSocial = data;
      switch (flag) {
        case 0:
          // 个人社保详情
          // 获取今年的一月
          let january = this.$formatTime(new Date().setMonth(0), "YYYY-MM");
          // 获取今年的十二月
          let december = this.$formatTime(new Date().setMonth(11), "YYYY-MM");
          requestData = {
            date: [january, december],
            idCard: this.tempSocial.identityCard, // 身份证
          };
          this.$request(
            "post",
            "/socialSecurity/listByIdCard",
            requestData,
            (data) => {
              this.dialogData.width = "1300px";
              this.dialogData.title = "个人社保缴纳详情";
              this.dialogData.clientName = this.tempSocial.name;
              this.dialogData.buttonArray = [];
              this.dialogData.data = [];
              this.dialogData.data = [
                {
                  type: "social_person",
                  data: {
                    date: [january, december],
                    active: "monthRange",
                    tableData: data,
                    props: taxData.companySocialProps(),
                    height: "600",
                  },
                },
              ];
              this.$forceUpdate();
              console.log("dialog数据", this.dialogData);
              this.dialogData.flag = true;
            },
            (err) => {
              this.$message.error(err);
            }
          );

          break;
        case "cancel":
          this.colseDialog();
          break;
        default:
          break;
      }
    },
    /**
     * 抽屉数据或dialog数据操作事件
     */
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "monthRange":
          requestData = {
            idCard: this.tempSocial.identityCard, // 身份证
            date: this.dialogData.data[0].data.date, // 日期筛选项
          };
          this.$request(
            "post",
            "/socialSecurity/listByIdCard",
            requestData,
            (data) => {
              this.dialogData.data[0].data.tableData = data;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "export":
          // 导出个人社保信息
          requestData = {
            idCard: this.tempSocial.identityCard, // 身份证
            date: this.dialogData.data[0].data.date, // 日期筛选项
          };
          window.location.href =
            this.$baseurl +
            "/socialSecurity/exportEmployee?data=" +
            JSON.stringify(requestData);
          break;
        case "cancel":
          this.colseDialog();
          break;
        default:
          break;
      }
    },
    initTableData() {
      let requestData = {
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
        clientId: this.clientId, // 客户id
        date: this.date, // 日期筛选项
        name: this.name, // 姓名
      };
      this.$request(
        "post",
        "/socialSecurity/pageByClient",
        requestData,
        (data) => {
          this.tableData = data.records;
          this.pageData.total = data.total; // 获取总条目数
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    /**
     * 表格合计计算
     */
    summaryMethods(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index == 0) {
          sums[index] = "合计";
          return;
        }
        if (index == 1 || index == 2 || index == 3 || index == 4) {
          sums[index] = "";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        // console.log(values);
        sums[index] = values.reduce((prev, curr) => {
          const value = Number(curr);
          let sum = Number(Number(prev) + Number(curr)).toFixed(2); //toFixed(2)数据项保留两位小数
          if (!isNaN(value)) {
            return sum;
          } else {
            return prev;
          }
        }, 0);
        sums[index];
      });
      return sums;
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    rowClassName({ row, rowIndex }) {
      if (row.reductionSign === "0") {
        return "reduce";
      } else if (row.dataSources == "2") {
        return "add";
      }
    },
    lazyLoading() {
      // 获取目标滚动元素
      let dom = this.$refs.tableData.bodyWrapper;
      dom.addEventListener("scroll", () => {
        const scrollDistance =
          dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        console.log("鼠标滑动-scrollDistance", scrollDistance);
        // if (scrollDistance <= 0) {  //分辨率问题，如果设置 100% ，滑倒最底部，scrollDistance的值 可能为 0.201 到 -0.201
        if (scrollDistance < 1) {
          if (this.tableData.length >= this.pageData.total) {
            // 到达底部 数据加载完毕
            return;
          }
          // 滑动到表格底部 可以请求接口
          let requestData = {
            currentPage: ++this.pageData.currentPage, // 当前页数
            pageSize: this.pageData.pageSize, // 每页条数
            clientId: this.clientId, // 客户id
            date: this.date, // 日期筛选项
            name: this.name, // 姓名
          };
          this.$request(
            "post",
            "/socialSecurity/pageByClient",
            requestData,
            (data) => {
              // 获取的数据追加到原来的数据中
              this.tableData = [...this.tableData, ...data.records];
              // this.waiteDataList = data.records;
              this.pageData.total = data.total;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
        }
      });
    },
    resetPage() {
      this.pageData.currentPage = 1;
      this.initTableData();
    },
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.tableData.doLayout();
    });
  },
  created() {
    this.tableData = this.data.records;
    this.pageData.total = this.data.total;
    this.pageData.pageSize = this.data.size;
    this.pageData.currentPage = this.data.current;
    this.clientId = this.data.clientId;
    this.date = this.data.date;
    this.name = this.data.name;
  },
  mounted() {
    this.lazyLoading();
  },
};
</script>

<style lang="less" scoped>
#table-social {
  height: 80%;
  display: flex;
  flex-direction: column;
  .table-social-search {
    height: 40px;
    display: flex;
    .table-social-search-line {
      width: 250px;
      margin-right: 5px;
    }
  }
  .tableData-content {
    /deep/ .el-table {
      overflow: visible !important;
    }

    /deep/ .el-table__body-wrapper {
      &::-webkit-scrollbar {
        height: 8px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }

  .social-security-table-card-bottom {
    width: 95%;
    height: 40px;
    border-top: 1px solid @boderColor;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
